import { HttpClient } from 'services/httpClient';
import { ListRequestModel } from 'models/baseModel';
import { QuestionnaireSettingDataModel } from 'models/admin/questionnaireModel';

const httpClient = new HttpClient();

/** 取得問卷範本列表 */
const getQuestionnaireSettingList = (request: ListRequestModel) => {
  return httpClient.post(`QuestionnairesManage/setting/list`, request);
};

/** 編輯狀態表單 */
const editQuestionnaireSampleStatus = (request: {
  id: string;
  active: boolean;
}) => {
  return httpClient.post(`QuestionnairesManage/${request.id}/status`, {
    isActive: request.active,
  });
};

/** 取得問卷範本 */
const getQuestionnaireSettingData = (id: string) => {
  return httpClient.get(`QuestionnairesManage/${id}`);
};

/** 新增問卷範本 */
const addQuestionnaireSettingData = (data: FormData) => {
  return httpClient.post(`QuestionnairesManage/add`, data);
};

/** 儲存問卷範本 */
const saveQuestionnaireSettingData = (id: string, data: FormData) => {
  return httpClient.post(`QuestionnairesManage/${id}`, data);
};

/** 複製問卷範本 */
const copyQuestionnaireSettingData = (id: string) => {
  return httpClient.post(`QuestionnairesManage/copy?id=${id}`, null);
};

/** 刪除問卷範本 */
const deleteQuestionnaireSettingData = (data: string[]) => {
  return httpClient.post(`QuestionnairesManage/delete`, data);
};

/** 取得問卷回覆列表 */
const getQuestionnaireResponseList = (request: ListRequestModel) => {
  return httpClient.post(`QuestionnairesManage/reply/list`, request);
};

/** 取得問卷回覆資訊 */
const getQuestionnaireResponseData = (
  companyCode: string,
  sampleId: string
) => {
  return httpClient.get(
    `QuestionnairesManage/reply/${sampleId}?companyCode=${companyCode}`
  );
};

/** 匯出問卷回覆 */
const exportQuestionnaireResponseExcel = (year: string) => {
  return httpClient.postBlob(`QuestionnairesManage/${year}/export`, null);
};

const QuestionnaireService = {
  getQuestionnaireSettingList,
  editQuestionnaireSampleStatus,
  getQuestionnaireResponseList,
  getQuestionnaireSettingData,
  addQuestionnaireSettingData,
  saveQuestionnaireSettingData,
  getQuestionnaireResponseData,
  copyQuestionnaireSettingData,
  deleteQuestionnaireSettingData,
  exportQuestionnaireResponseExcel,
};

export default QuestionnaireService;
