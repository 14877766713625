//* ---------------------------- React start ---------------------------- *//
import { FC, useState, ReactElement } from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./BasicAccordion.module.scss";
//* ---------------------------- local end ---------------------------- *//

interface CustomToggleProps {
  eventKey: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  header: ReactElement;
  imgPath: string;
  customHeaderClassName: string;
}

const CustomToggle: FC<CustomToggleProps> = ({
  eventKey,
  isOpen,
  setIsOpen,
  header,
  imgPath,
  customHeaderClassName,
}) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {

    setIsOpen(!isOpen);
  });

  return (
    <div
      className={`toggle-header ${
        isOpen ? "isOpen" : "isClose"
      } ${customHeaderClassName}`}
    >
      <img
        className={`toggle-icon`}
        src={imgPath}
        alt="icon"
        onClick={decoratedOnClick}
      />
      {header}
    </div>
  );
};

interface BasicAccordionProps {
  defaultOpen?: boolean;
  header?: ReactElement;
  children?: ReactElement;
  imgPath?: string;
  customHeaderClassName?: string;
}

const BasicAccordion: FC<BasicAccordionProps> = (props) => {
  const [defaultOpen] = useState<string>(props.defaultOpen ? "0" : "");
  const [isOpen, setIsOpen] = useState<boolean>(props.defaultOpen ?? false);
  const [header] = useState<ReactElement>(
    props.header || <>Hello! I'm the header</>
  );
  const [children] = useState<ReactElement>(
    props.children || <>Hello! I'm the body</>
  );
  const [imgPath] = useState<string>(
    props.imgPath || "/assets/images/buttonIcon/dropdown-active-icon.svg"
  );
  const [customHeaderClassName] = useState<string>(
    props.customHeaderClassName || ""
  );


  return (
    <Accordion
      className={`${styles["triangle-accordion"]}`}
      defaultActiveKey={defaultOpen ? [defaultOpen] : []}
      alwaysOpen
    >
      <CustomToggle
        eventKey="0"
        header={header}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        imgPath={imgPath}
        customHeaderClassName={customHeaderClassName}
      />
      <Accordion.Collapse eventKey="0">{children}</Accordion.Collapse>
    </Accordion>
  );
};

export default BasicAccordion;
