import StorageName from "constants/storageName";
import { EmptyMessageType } from "models/baseModel";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

export interface EmptyMessageStore{
    /** 語言類型 */
    messageType: string;
    setMessageType: (type: string | null)=> void;
}

const initType = EmptyMessageType.NotLogin;

/** 設置訊息type */
const setMessageType = (newType:string | null)=>{
    if(!newType){
        return initType;
    }
    return newType;
}

const useEmptyMessageStore = create<EmptyMessageStore>()(
   persist(
    devtools((set) => ({
        messageType: initType,
        setMessageType: (newType) =>
          set((state) => {
            // immutably 方式給值，不改變state ，改變位址
            return {
              ...state,
              messageType: setMessageType(newType),
            };
          }),
      })),
      {
        name: StorageName.EmptyMessageType,
        storage: createJSONStorage(() => localStorage),
      
      }
   )    
);

export default useEmptyMessageStore;