import React, { FC } from "react";
import styles from "./InfoDisclosure.module.scss";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
import IndividualCompany from "./IndividualCompany/IndividualCompany";
import Summary from "./Summary/Summary";
import { NavBarItemModel } from "../../../models/baseModel";
import Navbar from "../../../components/layouts/Navbar/Navbar";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import { useTranslation } from "react-i18next";

const InfoDisclosureLayout: FC = () => {
  const {t} = useTranslation();

  const navItems: NavBarItemModel[] = [
    {
      name: t('INDIVIDUAL_COMPANY_INQUIRY'),
      url: "individual",
    },
    {
      name: t('SUMMARY_DATA_QUERY'),
      url: "summary",
    },
  ];
  return (
    <>
      {/* tab 資訊 */}
      <Navbar items={navItems} />
      <div className="nav-content-box">
        <Outlet></Outlet>
      </div>
    </>
  );
};

interface InfoDisclosureRouterProps {}

const InfoDisclosureRouter: FC<InfoDisclosureRouterProps> = () => {
  // 創建router
  const routers: RouteObject[] = [
    {
      path: "",
      element: <InfoDisclosureLayout />,
      children: [
        {
          path: "",
          element: <Navigate to={"individual"} />,
        },
        {
          path: "individual",
          element: (
            <CustomErrorBoundary>
              <IndividualCompany />
            </CustomErrorBoundary>
          ),
        },
        {
          path: "summary",
          element: (
            <CustomErrorBoundary>
              <Summary />
            </CustomErrorBoundary>
          ),
        },
      ],
    },
  ];
  // 相當於router
  let elements = useRoutes(routers);
  return <>{elements}</>;
};

export default InfoDisclosureRouter;
