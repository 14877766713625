export interface EsgDeclareDataModel {
    /** 申報動作 */
    action: DeclareDataAction;
    declareId: string;
    /** 指標表單id */
    sampleId?: string | null;
    /** 市場別id */
    indicatorMarketId?: string | null;
}

export interface EsgDeclareInfoModel {
    year: number;
    /** 申報狀態 */
    status: DeclareStatus;
    canEdit: boolean;
    isFinished: boolean;
    /** 市場別id */
    indicatorMarketId?: string | null;
}

export enum DeclareDataAction {
    /** 儲存 */
    Saved,
    /** 確認申報 */
    Declared,
    /** 更正申請 */
    CorrectionRequest,
    /** 申請開放 */
    DeclareRequest,
    /** 報告書更正版上傳 */
    RevisionUpload,
}

export enum DeclareStatus {
    /** 未申報 */
    UnDeclared,
    /** 申報中 */
    Declaring,
    /** 已申報 */
    Declared,
    /** 申請更正 */
    ApplyForCorrection,
    /** 更正中 */
    Correcting,
    /** 申報結束 */
    DeclareEnd,
    /** 申請開放 */
    ApplyForDeclare
}


export interface IndicatorDelareInfoListModel {
    declareId: string;
    year: number;
    /** 最後編輯日期 */
    lastEditTime: Date | null;
    /** 首次申報日期 */
    firstDeclareTime: Date | null;
    /** 更正申報日期 */
    modifiedDeclareTime: Date | null;
    /** 狀態 */
    status: DeclareStatus;
    /** 狀態名稱 */
    declareStatus: string | null;
    /** 是否可編輯 */
    canEdit: boolean;
    /** 是否完成 */
    isFinished: boolean;
    /** 前端使用(開始相關按鈕) */
    isOpen?: boolean;
}
