import React, { FC, useEffect, useState } from 'react';
import styles from './ExportDocument.module.scss';
import LinkIconButton from 'components/button/LinkIconButton/LinkIconButton';
import { useMutation } from '@tanstack/react-query';
import VisuallLoading from 'components/common/VisuallLoading/VisuallLoading';
import { ResponseCode } from 'models/responseCodeModel';
import { error2Alert, successAlert } from 'utils/otherToast';
import {
	EduPropagandaResourceFileModel,
	EduPropagandaResourceListRequestModel,
} from 'models/application/educationPropaganda/eduPropagandaResourceModel';
import EduPropagandaResourceService from 'services/application/EducationPropaganda/eduPropagandaResource';
import { CommonService } from 'services/common/commonService';
import FloatCalculateService from 'services/common/floatCalculateService';

//* ---------------------------- React start ---------------------------- *//
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import DeclarationService from 'services/admin/declarationService';
import { ExportCsvModel } from 'models/admin/declarationModel';
//* ---------------------------- local end ---------------------------- *//

interface ExportDocumentProps {}

const ExportDocument: FC<ExportDocumentProps> = () => {
	//* ---------------------------- state start ---------------------------- *//
	const [fileList, setFileList] = useState<ExportCsvModel[]>([]);
	//* ---------------------------- state end ---------------------------- *//
	//* ---------------------------- api start ---------------------------- *//
	//- getExportCsvList
	const { mutate: getExportCsvListMutate, isPending: getExportCsvListIsPending } = useMutation({
		mutationFn: () => DeclarationService.getExportCsvList(),
		onSuccess: (res) => {
			console.log('getExportCsvList', res);
			if (res.code === 200 && res.success) {
				// success2Alert(res.message);
				setFileList([...res.data]);
				// setFileList([
				// 	{
				// 		id: '4e55a421-1223-4aed-b945-3356b509612e',
				// 		fileName: 'ESG數位平台-企業 ESG 資訊揭露申報作業.pdf',
				// 		fileSize: 6121190,
				// 	},
				// 	{
				// 		id: '76add828-8251-4c4c-9e27-e919ab8a7e63',
				// 		fileName: 'ESG數位平台-溫室氣體排放及減量資訊申報作業.pdf',
				// 		fileSize: 2379396,
				// 	},
				// 	{
				// 		id: '43cf484b-79ae-406c-ac08-31fd7094c40b',
				// 		fileName: 'ESG數位平台-永續報告書申報作業.pdf',
				// 		fileSize: 3376240,
				// 	},
				// 	{
				// 		id: '1dd096f7-0b22-409d-813c-16e73c5fa48d',
				// 		fileName: 'ESG數位平台-永續經濟活動問卷申報.pdf',
				// 		fileSize: 2364924,
				// 	},
				// ]);
			} else {
				setFileList([]);
				error2Alert(res.message);
			}
		},
		onError: (err) => {
			console.log('getExportCsvList', err);
			setFileList([]);
		},
	});

	//- getExportCsvFile
	const { mutate: getExportCsvFileMutate, isPending: getExportCsvFileIsPending } = useMutation({
		mutationFn: (request: { fileMarketType: number; fileType: number }) => DeclarationService.getExportCsvFile(request),
		onSuccess: (res) => {
			if (res.status !== ResponseCode.ServerErrorInternal) {
				successAlert('下載成功');
				CommonService.downloadByStream(res);
			} else {
				error2Alert(res.data.message);
			}
		},
		onError: (err) => {
			console.log(err);
		},
	});
	//* ---------------------------- api end ---------------------------- *//
	//* ---------------------------- component start ---------------------------- *//
	//* ---------------------------- component end ---------------------------- *//
	//* ---------------------------- function start ---------------------------- *//
	// const donwload = (filename: string) => {
	//   getExportCsvFileMutate(filename);
	// };
	//* ---------------------------- function end ---------------------------- *//
	//* ---------------------------- hook start ---------------------------- *//
	//- useEffect init
	useEffect(() => {
		getExportCsvListMutate();
	}, []);
	//* ---------------------------- hook end ---------------------------- *//

	return (
		<div className='no-nav-content-box position-relative'>
			{getExportCsvFileIsPending ? <VisuallLoading /> : null}
			{getExportCsvListIsPending ? (
				<VisuallLoading />
			) : (
				<div className={styles['propaganda-box']}>
					{/* 內容 */}
					<div className={`${styles['propaganda-content-box']} scroll`}>
						{fileList.length ? (
							<div className={styles['propaganda-item-box']}>
								<div className={`${styles['file-box']} container-fluid  scroll`}>
									<div className='row no-gutters'>
										{fileList.map((file, fileIndex) => {
											return (
												<div
													key={`resource_file_${file.fileName}_${fileIndex}`}
													className='col-6 mb-20-p'
													onClick={() => {
														getExportCsvFileMutate({ fileMarketType: file.marketType, fileType: file.fileType });
													}}>
													<div className={styles['file-item']}>
														<div>
															<LinkIconButton imgName='download-icon.svg' text={file.fileName} onClick={() => {}} />
														</div>
														{/* 檔案大小 */}
														<div className={styles['file-size']}>
															({FloatCalculateService.formatFileSize(file.fileSize)})
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						) : (
							<div className={`${styles['no-data']}`}>沒有資料</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default ExportDocument;
