import React, { FC, ReactNode, useRef, useState } from "react";
import styles from "./BatchUploadModal.module.scss";
import { Modal } from "react-bootstrap";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { error2Alert } from "utils/otherToast";
import { AxiosResponse } from "axios";
import { CommonService } from "services/common/commonService";

export interface BatchUploadModalProps {
  show: boolean;
  handleClose?: () => void;
  /** 上傳 */
  handleUpload: (file: any) => void;
  title?: string | null;
  size?: "sm" | "lg" | "xl";
  /** 範本下載url */
  sampleDownloadUrl?: string | null;
  /** 範本下載stream */
  sampleDownloadStream?: AxiosResponse;
  /** 範本名稱 */
  sampleName?: string | null;
  /** 上傳loading */
  uploadLoading?: boolean;
  /** 提醒文字 */
  noteChildren?: ReactNode;
  /** warning 文字 */
  warningChildren?: ReactNode;
  /** 限制檔案大小 */
  limitFileSize?: number;
  /** 接受檔案格式 */
  accept?: string;
  /** 多檔案上傳 */
  isMultiple?: boolean;
  /** 範本url下載由外部操作 */
  isExternalSample?: boolean;
  /** 呼叫外部下載事件 */
  handleDownloadExternalSample?: () => void;
}

const BatchUploadModal: FC<BatchUploadModalProps> = ({
  accept = ".jpg,.png,.jpeg,.doc,.docx,.pdf,.xls,.csv,.xlsx,.pptx,.rar,.zip",
  uploadLoading = false,
  isMultiple = false,
  isExternalSample = false,
  ...props
}) => {
  const {
    show,
    handleClose,
    handleUpload,
    title,
    sampleName,
    sampleDownloadUrl,
    sampleDownloadStream,
    size,
    handleDownloadExternalSample
  } = props;
  /** excel資料 */
  const [excelData, setExcelData] = useState<any>();
  const [draging, setDraging] = useState(false);
  /** 隱藏上傳 */
  const hiddenFileInput = useRef<any>(null);
  /** 上傳 */
  const upload = (file: any) => {
    if (fileSizeValid(file.target.files)) {
      handleUpload(file.target.files);
    }
  };

  /** 預設事件 */
  const handleDefault = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setDraging(true);
  };

  /** 放下 */
  const handleDrop = (e: any) => {
    e.preventDefault();
    if (fileSizeValid(e.dataTransfer.files)) {
      handleUpload(e.dataTransfer.files);
    }
  };

  /** 離開 */
  const handleDropLeave = (e: any) => {
    e.preventDefault();
    setDraging(false);
  };
  /** 下載範本 */
  const downloadSample = () => {
    if(!isExternalSample){
      if (sampleDownloadUrl) {
        CommonService.downloadByUrl(sampleDownloadUrl);
      } else if (sampleDownloadStream) {
        CommonService.downloadByStream(sampleDownloadStream);
      }
    }else{
      // 呼叫外部的api進行下載
      if(handleDownloadExternalSample){
        handleDownloadExternalSample();
      }
    }
  };

  /** 上傳trigger */
  const uploadTrigger = () => {
    setExcelData(null);
    const element = hiddenFileInput.current;
    if (element) {
      element.value = null;
      element.click();
    }
  };

  /** 檔案大小確認 */
  const fileSizeValid = (files: any[]): boolean => {
    // 如果沒限制大小直接過
    if (props.limitFileSize !== null && props.limitFileSize !== undefined) {
      const limitBytes = props.limitFileSize * 1024 * 1024;
      let fileTotal = 0;
      for (const file of files) {
        fileTotal += file.size;
      }
      if (fileTotal > limitBytes) {
        error2Alert(
          `您上傳的檔案已超過檔案大小限制${props.limitFileSize}MB，請重新上傳`
        );
        return false;
      }
    }
    return true;
  };
  return (
    <Modal
      className={styles["batch-upload-modal-box"]}
      show={show}
      size={size}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      {uploadLoading && <VisuallLoading></VisuallLoading>}
      <Modal.Header closeButton>
        <Modal.Title>{title ? title : "批次匯入"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="batch-content-box">
          {(sampleDownloadUrl ||
           sampleDownloadStream ||
            isExternalSample === true
          ) && (
            <div className="sample-word" onClick={downloadSample}>
              {sampleName ? sampleName : "請先下載Excel範例格式"}
            </div>
          )}

          {/* warning 警示 */}
          {props.warningChildren && (
            <div className="warning-box">{props.warningChildren}</div>
          )}
          <div
            className={`upload-box 
            ${draging ? "active" : ""}`}
            onDragOver={($event) => handleDefault($event)}
            onDrop={($event) => handleDrop($event)}
            onDragLeave={($event) => handleDropLeave($event)}
            onClick={($event) => uploadTrigger()}
          >
            <input
              ref={hiddenFileInput}
              type="file"
              id="importExcelData"
              accept={accept}
              style={{ display: "none" }}
              multiple={isMultiple ?? "multiple"}
              onChange={($event) => upload($event)}
            />
            {(sampleDownloadUrl || sampleDownloadStream) && (
              <div>依造範例格式直接匯入EXCEL，即可批次建立資料</div>
            )}
            <div>
              <img
                alt="upload"
                src="/assets/images/buttonIcon/batch-upload-icon.svg"
              />
            </div>
            <div>可拖拉檔案至此處或點擊上傳</div>
          </div>
          <div className="upload-btn">
            <button className="default" onClick={($event) => uploadTrigger()}>
              選擇檔案
            </button>
          </div>
          {/* 有note文字 */}
          {props.noteChildren && (
            <div className="note">{props.noteChildren}</div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BatchUploadModal;
