import React, { FC, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import Wrapper from "./Wrapper";
import { SustainReportUserTreeNode } from "models/application/sustainReportDeclare/sustainReportProduceModel";

interface ContainerProps {
  group: SustainReportUserTreeNode;
  groupsIndex: number[];
  // setGroups: React.Dispatch<React.SetStateAction<Group[]>>;

  /** groupTree 根目錄設置陣列方法 */
  setList: (groupsIndex: number[], groups: SustainReportUserTreeNode[]) => void;
  onEnd: () => void;
  isRoot?: boolean;
  /**選擇Item 項目 */
  clickItem: (groupsIndex: number[], group: SustainReportUserTreeNode, type: string) => void;
}

const Container: FC<ContainerProps> = ({ isRoot = false, ...props }) => {
  const { group, groupsIndex, setList, onEnd, clickItem } = props;
  /** 基礎設置 */
  const [sortableOptions, setSortableOptions] = useState<any>({
    animation: 150,
    fallbackOnBody: true,
    swapThreshold: 0.65,
    // 平行層 才能拖曳 
    group: `shared_${group.parentId}`,
    handle: ".icon-move", // 可拖曳icon
    // forceFallback: true
  });

  return (
    <ReactSortable
      list={group.children ?? []}
      setList={(currentList:SustainReportUserTreeNode[], sortable, store) => {
        // 拖曳放下的時候觸發 & 值有改動時觸發
        if (
          store.dragging &&
          store.dragging.props &&
          JSON.stringify(store.dragging.props.list) !==
            JSON.stringify(currentList)
        ) {
          // 傳至外部 currentList 為更動的list
          setList(groupsIndex, currentList);
        }
      }}
      {...sortableOptions}
      onEnd={onEnd}
    >
      {(group.children ?? []).map((childBlock, index: number) => {
        return (
          <Wrapper
            key={childBlock.userChapterId}
            group={childBlock}
            groupsIndex={[...groupsIndex, index]}
            setList={setList}
            onEnd={onEnd}
            clickItem={clickItem}
          />
        );
      })}
    </ReactSortable>
  );
};

export default Container;
