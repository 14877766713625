import { Toaster } from "react-hot-toast";

const ToasterProvider = () => {
  return (
    <Toaster
      position="top-right"
      gutter={8}
      //   reverseOrder={false}
      containerStyle={{
        position: "relative",
        top: "5rem",
        marginRight: "1.5rem",
      }}
      toastOptions={{
        success: {
          duration: 2000,
          icon: (
            <img
              alt="toast"
              src="/assets/images/buttonIcon/toast-success1-icon.svg"
            />
          ),
          style: {
            backgroundColor: "#FFF",
            border: "1px solid #00B6E3",
            color: "#008DC1",
            fontWeight: "500",
            fontFamily: "Noto Sans",
            fontSize: "14px",
          },
        },
        error: {
          duration: 2000,
          icon: (
            <img
              alt="toast"
              src="/assets/images/buttonIcon/toast-error1-icon.svg"
            />
          ),
          style: {
            backgroundColor: "#FFF",
            border: "1px solid #E19B85",
            color: "#C2370B",
            fontWeight: "500",
            fontFamily: "Noto Sans",
            fontSize: "14px",
          },
        },
        custom:{
          
        }
      }}
    />
  );
};

export default ToasterProvider;
