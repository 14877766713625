import { HttpClient } from 'services/httpClient';
import {
  RoleListRequestModel,
  RoleSaveModel,
} from 'models/admin/rolePermissionModel';

const httpClient = new HttpClient();

// #region 清單 List

/** 查詢角色列表 */
const getRoleList = (request: RoleListRequestModel) => {
  return httpClient.post(`Role/list`, request);
};

/** 取得權限樹 */
const getPermissionTree = () => {
  return httpClient.get(`Permission/permission`);
};

/** 取得 user List */
const getUserList = () => {
  return httpClient.get(`Role/userOption`);
};

/** 新增角色權限 */
const addRolePermission = (request: RoleSaveModel) => {
  return httpClient.post(`Role/add`, request);
};

/** 取得角色資訊 */
const getUserInfo = (id: string) => {
  return httpClient.get(`Role/${id}`);
};

/** 儲存角色權限 */
const saveRolePermission = (request: RoleSaveModel) => {
  return httpClient.post(`Role/save`, request);
};

/** 複製角色 */
const copyRolePermission = (id: string) => {
  return httpClient.post(`Role/copy/${id}`, null);
};

/** 刪除角色 */
const deleteRoles = (idList: string[]) => {
  return httpClient.post(`Role/delete`, idList);
};

// #endregion 清單 List

const RolePermissionService = {
  getRoleList,
  getPermissionTree,
  getUserList,
  addRolePermission,
  getUserInfo,
  saveRolePermission,
  copyRolePermission,
  deleteRoles,
};

export default RolePermissionService;
