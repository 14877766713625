import { Category } from "models/admin/indicatorModel";
/** AI分析結果資訊模組 */
export interface AiAnalysisResultValueModel {
    /** 分析id */
    analysisId: string;
    /** 分析完成時間 */
    analysisTime?: Date | null;
    /** 分析類別 */
    categories: AnalysisIndicatorCategoryModel[];
}

/** 分析指標類別模組 */
export interface AnalysisIndicatorCategoryModel {
    /** 類別 */
    category: Category;
    categoryName: string;
    /** 主題資料 */
    subjects: AnalysisIndicatorSubjectModel[];

    /** 前端用 */
    rowspan?: number;
}

/** 分析指標主題模組 */
export interface AnalysisIndicatorSubjectModel {
    subject: string;
    /** 指標資料 */
    indicators: AnalysisIndicatorModel[];

    /** 前端用 */
    rowspan?: number;
}

/** 分析指標模組 */
export interface AnalysisIndicatorModel {
    /** 指標值類型 */
    type: IndicatorType;
    /** 指標類型名稱 */
    typeName: string;
    /** 指標名稱 */
    indicator: string;
    /** 分析頁碼 */
    page: string;
    /** 值 */
    values: string[];
    /** 單位轉換後值 */
    formatValues: string[];
    /** 指標單位 */
    unit: string;
    /** 單位代號 */
    code: number;
    sortIndex: number;
    /** 已填報內容 */
    declareValue: string | null;
}

export interface AiAnalysisStatusModel {
    status: AnalysisStatus;
    analysisId: string | null;
    percentage: number | null;
}

/** 前端送出請求model */
export interface AiAnalysisResultModel {
    id: string;
    data: EditAiAnalysisResultModel;
}

export interface EditAiAnalysisResultModel {
    data: AiAnalysisResultValueModel;
}


export enum IndicatorType {
    /** 量化 */
    Quantify,
    /** 質化 */
    Qualitative
}

export enum AnalysisStatus {
    None,
    Analysising,
    Completed,
    Fail
}
