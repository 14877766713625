import { FC, useEffect, useState } from "react";
import styles from "./SysFunction.module.scss";
import SearchInput from "components/base/SearchInput/SearchInput";
import { ListRequestModel } from "models/baseModel";
import TablePagination from "components/base/TablePagination/TablePagination";
import { SysFunctionModel } from "models/admin/systemManageModel";
import SwitchCheckbox from "components/base/SwitchCheckbox/SwitchCheckbox";
import { useMutation } from "@tanstack/react-query";
import SystemManageService from "services/admin/systemManageService";
import toast from "react-hot-toast";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { usePermissionContext } from "context/PermissionProvider";
import { SystemManageActionsPermissionCodes } from "models/auth/permissionModel";

export interface tmpreq {
  id: string | null;
  status: boolean | null;
}

const SysFunction: FC = () => {

  // #region 變數
  const [list, setList] = useState<SysFunctionModel[]>([]);
  const [count, setCount] = useState<number | undefined>(0);
  const [request, setRequest] = useState<ListRequestModel>({
      page: 1,
      pageSize: 10,
      searchKey: null,
      sortKey: null,
      sortType: true
  });
  const [request2, setRequest2] = useState<tmpreq>({
    id: null,
    status: null
});
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;

  //#endregion

  // #region CRUD

  // 取得清單
  const { mutate: getListMutation, isPending: listIsPending } =
    useMutation({
      mutationFn: (request: ListRequestModel) =>
      SystemManageService.getSystemFunList(request),
      onSuccess: (res) => {
        if (res.code === 200 && res.success) {
          setList(res.data);
        } else {
          setList([]);
        }
      },
      onError: (err) => {
        console.log("getCompanyListMutation", err);
        toast.error(err.message);
      },
    });

  // 狀態更新
  const { mutate: switchStatusMutation } =
    useMutation({
      mutationFn: (req: { id: string; status: boolean }) =>
      SystemManageService.switchSysFunStatus(req.id!, req.status!),
      onSuccess: (res) => {
        if (res.code === 200 && res.success) {
          //getListMutation(request);
        } else {
          getListMutation(request);
          toast.error(res.message);
        }
      },
      onError: (err) => {
        console.log("getCompanyListMutation", err);
        toast.error(err.message);
      },
    });

  //#endregion


  // #region 事件

  useEffect(() => {
    getListMutation(request);
  }, [request]);

  const handleStatusChange = (id: string | null) => {
    if(id) {
      let chk = list.find(x=>x.id === id);
      if(chk) {
        switchStatusMutation({id: id, status: !chk.status});
        setList(list.map((ck, index) => {
          if(ck.id === id){
              ck.status = !ck.status;
          }
          return ck;
          }
        ));
      }
    }
  };

  const handleSearch = (key: string) => {

    let tmpreq = {...request};
    if(key && key !== '') {
      tmpreq.searchKey = key;
      tmpreq.page = 1;
      setRequest(tmpreq);
    } else {
      tmpreq.searchKey = null;
      tmpreq.page = 1;
      setRequest(tmpreq);
    }
  };

  // #endregion

    return (
      <div className={styles["sysFunction-list"]}>

        <div className="tool-box mt-20-p">
          <div className="mr-1-m">
            <SearchInput onClick={handleSearch} />
          </div>
        </div>

        {/** 主表 */}
        <div className="result-box scroll">
        {listIsPending ? <VisuallLoading /> : <></>}
          <div className="table-box ">
            <table
                aria-label="查詢結果table"
                className="table-container sticky-table"
            >
              <thead>
                  <tr>
                    <th scope="col">功能名稱</th>
                  {detectEveryActionPermission([
                    SystemManageActionsPermissionCodes.SystemFunctionManageSetStatus,
                  ]) ? (
                    <th scope="col">狀態</th>
                  ) : null}

                  </tr>
              </thead>
              <tbody>
              {list.length === 0 ? <tr><td style={{textAlign: 'center'}} colSpan={detectEveryActionPermission([
                    SystemManageActionsPermissionCodes.SystemFunctionManageSetStatus,
                  ]) ? 2 : 1}>no data</td></tr> :
              list.map((p, i) => {
                  return (
                      <tr key={p.id}>
                        <td>{p.name}</td>
                        {detectEveryActionPermission([
                    SystemManageActionsPermissionCodes.SystemFunctionManageSetStatus,
                  ]) ? (
                    <td>
                    <SwitchCheckbox key={p.id} checked={p.status} text={p.status ? '啟用' : '關閉'} onClick={()=>handleStatusChange(p.id)}/>
                    </td>
                  ) : null}
                      </tr>
                    );
                  })}

              </tbody>
            </table>
          </div>
        </div>
        <div className="pad-10 page" style={{border: '1px solid #dbe8ef'}}>
            <TablePagination
                page={request.page}
                pageSize={request.pageSize}
                onChange={(e) => {

                    let tmpreq = {...request};
                    tmpreq.pageSize = e.pageSize;
                    tmpreq.page = e.page;
                    setRequest(tmpreq);
                }}
                total={count || 0}
            />
        </div>
      </div>
    );
  };

export default SysFunction;
