import { HttpClient } from "services/httpClient";

const httpClient = new HttpClient();

/** 取得教育宣傳資源的所有檔案 */
const getEduPropagandaResourceFileList = () => {
    return httpClient.post(`EduPropagandaResource/list`);
}

/** 教育宣傳資源 檔案下載 */
const getEduPropagandaResourceFileStream = (fileId:string)=>{
    return httpClient.getBlob(`EduPropagandaResource/list/FileStream?fileId=${fileId}`);
}

const EduPropagandaResourceService = {
    getEduPropagandaResourceFileList,
    getEduPropagandaResourceFileStream
};

export default EduPropagandaResourceService;