import SearchInput from "components/base/SearchInput/SearchInput";
import SwitchCheckbox from "components/base/SwitchCheckbox/SwitchCheckbox";
import TablePagination from "components/base/TablePagination/TablePagination";
import { SysFunctionModel, SysLogModel, SystemLogRequestModel } from "models/admin/systemManageModel";
import { ListRequestModel } from "models/baseModel";
import { FC, useEffect, useState } from "react";
import styles from "./SysLog.module.scss";
import { normalDate } from "utils/dateTimeFormat";
import { useMutation } from "@tanstack/react-query";
import SystemManageService from "services/admin/systemManageService";
import toast from "react-hot-toast";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { CommonService } from "services/common/commonService";
import { ResponseCode } from "models/responseCodeModel";
import { error2Alert } from "utils/otherToast";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const SysLog: FC = () => {

  // #region 變數
  const [list, setList] = useState<SysLogModel[]>([]);
  const [count, setCount] = useState<number | undefined>(0);
  const [dates, setDates] = useState<Value>([new Date(), new Date()]);
  const [request, setRequest] = useState<SystemLogRequestModel>({
      page: 1,
      pageSize: 10,
      searchKey: null,
      sortKey: null,
      sortType: null,
      startTime: new Date(new Date().setHours(0, 0, 0)),
      endTime: new Date(new Date().setHours(23, 59, 59))
  });

  //#endregion

  // #region CRUD

  // 取得清單
  const { mutate: getListMutation, isPending: listIsPending } =
    useMutation({
      mutationFn: (request: SystemLogRequestModel) =>
      SystemManageService.getSysLog(request),
      onSuccess: (res) => {
        if (res.code === 200 && res.success) {
          setList(res.data);
          setCount(res.count);
        } else {
          setList([]);
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });
  
  // 匯出清單
  const { mutate: exportSysLog } =
    useMutation({
      mutationFn: (request: SystemLogRequestModel) =>
      SystemManageService.exportSysLog(request),
      onSuccess: (res) => {
        if (res.status !== ResponseCode.ServerErrorInternal) {
          CommonService.downloadByStream(res);
        }else{
          error2Alert(res.data.message);
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });
  //#endregion


  // #region 事件

  useEffect(() => {
    getListMutation(request);
  }, [request]);

  

  const handleSearch = (key: string) => {

    let tmpreq = {...request};
    if(key && key !== '') {
      tmpreq.searchKey = key;
      tmpreq.page = 1;
      setRequest(tmpreq);
    } else {
      tmpreq.searchKey = null;
      tmpreq.page = 1;
      setRequest(tmpreq);
    }
  };
  const handleSearchDate = (e: any, type: string) => {
    let tmpreq = {...request};
    console.log(e.target.value);
    if(e.target.value && e.target.value !== '') {
      switch(type) {
        case 's':
          tmpreq.startTime = e.target.value;
          tmpreq.page = 1;
          break;
        case 'e':
          tmpreq.endTime = e.target.value;
          tmpreq.page = 1;
          break;
      }
      setRequest(tmpreq);
    } else {
      switch(type) {
        case 's':
          tmpreq.startTime =null;
          tmpreq.page = 1;
          break;
        case 'e':
          tmpreq.endTime = null;
          tmpreq.page = 1;
          break;
      }
      setRequest(tmpreq);
    }
  }

  const handleSearchDate2 = (e: any) => {
    console.log(e);
    let tmpreq = {...request};
    if(e && e.length === 2) {
      tmpreq.startTime = e[0];
      tmpreq.endTime = e[1];
      tmpreq.page = 1;
      setDates(e);
      setRequest(tmpreq);
    } else {
      setDates(null);
      tmpreq.startTime = null;
      tmpreq.endTime = null;
      tmpreq.page = 1;
      setRequest(tmpreq);
    }
  }

  const handleExport = () => {
    exportSysLog(request);
  }

  // #endregion

    return (
      <div className={styles["sysLog-list"]}>
        
        <div className="tool-box mt-20-p">
          <div className="mr-1-m tool-box">
            <div className="mr-1-m">
              <SearchInput onClick={handleSearch} />
            </div>
            <div className="mr-1-m">
              {/*<input type="date" onChange={(e) => handleSearchDate(e, 's')}  />*/}
            </div>
            <div className="mr-1-m">
              {/*<input type="date" onChange={(e) => handleSearchDate(e, 'e')} />*/}
              <DateRangePicker onChange={handleSearchDate2} className={'myDateRangePicker'} value={dates} />
            </div>
          </div>
          
          
          <div className="mr-1-m">
            <button className="secondary" onClick={handleExport}>
              匯出
            </button>
          </div>
        </div>

        {/** 主表 */}
        <div className="result-box scroll">
          {listIsPending ? <VisuallLoading /> : <></>}
          <div className="table-box ">
            <table
                aria-label="查詢結果table"
                className="table-container sticky-table"
            >
              <thead>
                  <tr>
                    <th scope="col">編號</th>
                    <th scope="col">登入帳號</th>
                    <th scope="col">頁面</th>
                    <th scope="col">IP</th>
                    <th scope="col">動作</th>
                    <th scope="col">內容</th>
                    <th scope="col">建立時間</th>
                  </tr>
              </thead>
              <tbody>
              {list.length === 0 ? <tr><td style={{textAlign: 'center'}} colSpan={7}>no data</td></tr> :
              list.map((p, i) => {
                  return (
                      <tr key={i}>
                        <td>{p.sno}</td>
                        <td>{p.userId}</td>
                        <td>{p.page}</td>
                        <td>{p.ipAddress}</td>
                        <td>{p.method}</td>
                        <td>{p.body}</td>
                        <td>{p.createTime? normalDate(p.createTime) : '-'}</td>
                      </tr>
                    );
                  })}
                  
              </tbody>
            </table>
          </div>
        </div>
        <div className="pad-10 page" style={{border: '1px solid #dbe8ef'}}>
            <TablePagination
                page={request.page}
                pageSize={request.pageSize}
                onChange={(e) => {
     
                    let tmpreq = {...request};
                    tmpreq.pageSize = e.pageSize;
                    tmpreq.page = e.page;
                    setRequest(tmpreq);
                }}
                total={count || 0}
            />
        </div>
      </div>
    );
  };
  
export default SysLog;