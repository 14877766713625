import React, { FC, useState } from "react";
import {
  Navigate,
  Outlet,
  RouteObject,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import { NavBarItemModel } from "../../../models/baseModel";
import Navbar from "../../../components/layouts/Navbar/Navbar";
import List from "./List/List";
import Setting from "./Setting/Setting";
import DisclosureSetting from "./DisclosureSetting/DisclosureSetting";
import styles from "./IndicatorRouter.module.scss";
import Edit from "./Edit/Edit";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import {
  FeaturePermissionCodes,
  PermissionRouteObjectModel,
  PermissionNavBarItemModel,
  ActionsPermissionCodes,
} from "models/auth/permissionModel";
import EmptyWaitPage from "components/common/EmptyWaitPage/EmptyWaitPage";
import useUserInfoStore from "state/useUserInfoStore";
import { usePermissionContext } from "context/PermissionProvider";

interface IndicatorRouterProps {}
interface IndicatorSettingLayoutProps {
  title: string;
}
/*
const IndicatorSettingLayout: FC<IndicatorSettingLayoutProps> = (props) => {
    //const [title] = useState(props.title);
    const [tabName, setTabName] = useState("setting");
    const navigate = useNavigate();
    const handleItem = (url: string) => {
        setTabName(url);
        navigate(url);
      };

    const navItems: NavBarItemModel[] = [
      {
        name: "指標設定",
        url: "setting",
      },
      {
        name: "必要揭露指標設定",
        url: "disclosureSetting",
      },
    ];
    return (
      <>
        <div className="title-box">
          <div className="back-box">
            <div className="back-icon" onClick={()=>handleItem('/admin/indicator')}></div>
            <span className="title">{title}</span>
          </div>
        </div>
        {// content 內容 }
        <div className="indicator-setting-content-box">
            {// 左區塊 }
            <div className="left-box">
                <div className={"tree-box scroll"}>
                    <ul>
                    {navItems.map((p, index) => {
                    return (
                        <li
                        key={index}
                        className={tabName === p.url ? "active" : ""}
                        onClick={($event) => handleItem(p.url)}
                        >
                        {p.name}
                        </li>
                    );
                    })}
                    </ul>
                </div>
            </div>
            {// 右區塊 }
            <div className={"right-box scroll"}>
                <Outlet></Outlet>
            </div>
            </div>
      </>
    );
  };*/

const IndicatorRouter: FC<IndicatorRouterProps> = () => {
  //* ---------------------------- state start ---------------------------- *//
  const [title, setTitle] = useState("");
  const { PermissionService } = usePermissionContext();
  const { permissionRouter } = PermissionService;

  const routers: PermissionRouteObjectModel[] = [
    {
      path: "",
      element: <List handleMessage={setTitle} />,
      featurePermission: FeaturePermissionCodes.IndicatorManage.IndicatorList,
      actionReadPermission:
        ActionsPermissionCodes.IndicatorManage.IndicatorListRead,
    },
    {
      path: ":id/setting",
      element: <Setting />,
      featurePermission:
        FeaturePermissionCodes.IndicatorManage.IndicatorSetting,
      actionReadPermission:
        ActionsPermissionCodes.IndicatorManage.IndicatorSettingRead,
    },
    {
      path: ":id/setting/:eid/edit",
      element: <Edit />,
      featurePermission:
        FeaturePermissionCodes.IndicatorManage.IndicatorSetting,
      actionReadPermission:
        ActionsPermissionCodes.IndicatorManage.IndicatorSettingRead,
    },
    {
      path: ":id/setting/:eid/disclosureSetting",
      element: <DisclosureSetting />,
      featurePermission:
        FeaturePermissionCodes.IndicatorManage.NecessaryIndicator,
      actionReadPermission:
        ActionsPermissionCodes.IndicatorManage.NecessaryIndicatorRead,
    },
  ];

  //* ---------------------------- state end ---------------------------- *//

  let elements = useRoutes(permissionRouter(routers));
  return (
    <>
      <div className={styles["indicator-admin-layout"]}>
        <div className="no-nav-content-box">
          <CustomErrorBoundary>{elements}</CustomErrorBoundary>
        </div>
      </div>
    </>
  );
};

export default IndicatorRouter;
