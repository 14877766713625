import React, { FC } from "react";
import styles from "./IconButton.module.scss";

type IconButtonProps = {
  imgName: string;
  text: string;
  onClick: () => void | null;
  className?: string | null;
  /** 資料夾路徑 */
  prePath?: string | null;
}

const IconButton: FC<IconButtonProps> = ({
  prePath= 'buttonIcon',
  ...props
}) => {
  // 父層事件處理
  const handleEvent = () => {
    props.onClick();
  };
  return (
    <button className={`${styles["icon-button-box"]} 
    ${props.className ? props.className :"default"}`} onClick={() => handleEvent()}>
      <img alt="" 
      src={`/assets/images/${prePath}/${props.imgName}`} />
      <div className="text">{props.text}</div>
    </button>
  );
};

export default IconButton;
