import { AddPrincipleMatrixModel } from "models/admin/sustainStandardModel";
import { SystemLogRequestModel } from "models/admin/systemManageModel";
import { HttpClient } from "services/httpClient";

const httpClient = new HttpClient();

/** 取矩陣資料 */
const getMatrixData = (indicatorMarketId?: string) => {
    return httpClient.get(`PrincipleMatrixManage/data?indicatorMarketId=${indicatorMarketId}`);
}

/** 新增矩陣資料 */
const addMatrixData = (data: AddPrincipleMatrixModel) => {
    return httpClient.post(`PrincipleMatrixManage/data?indicatorMarketId=${data.indicatorMarketId}`, data);
}

/** 刪除矩陣準則 */
const deleteMatrixPrinciple = (principleId: string) => {
    return httpClient.post(`PrincipleMatrixManage/deleteData?principleId=${principleId}`);
}

/** 儲存矩陣資料 */
const saveMatrixData = (data: any) => {
    return httpClient.post(`PrincipleMatrixManage/editData?indicatorMarketId=${data.indicatorMarketId}`, data);
}

/** 透過companyCode匯出矩陣圖  */
const getMatrixDiagram = (year:number) => {
    return httpClient.postBlob(`PrincipleMatrixManage/matrix-diagram/FileStream?year=${year}`);
  }
  
/** 取得批次匯入資料範本 */
const loadImportXls = (indicatorMarketId?: string) => {
    return httpClient.getBlob(`PrincipleMatrixManage/excel/example/FileStream?indicatorMarketId=${indicatorMarketId}`);
}

/** 批次匯入資料 */
const importMatrix = (data: any) => {
    return httpClient.post(`PrincipleMatrixManage/excel/example?indicatorMarketId=${data.indicatorMarketId}`, data.file);
}

const PrincipleMatrixService = {
    getMatrixData,
    addMatrixData,
    getMatrixDiagram,
    deleteMatrixPrinciple,
    saveMatrixData,
    loadImportXls,
    importMatrix
}

export default PrincipleMatrixService;
