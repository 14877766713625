/** 控制表單欄位 特殊code */
export const SampleControlCode = {
    //#region --------------- 指標管理相關 ---------------

    //#region 溫室氣體排放
    /** 溫室氣體管理之策略、方法、目標 */
    GreenhouseGasEmissionsDisclosureTextBlock: 'greenhouseGasEmissionsDisclosureTextBlock',
    //#endregion

    //#region 董事會
    /** 董事會席次 */
    BoardOfDirectorsNumbers: 'boardOfDirectorsNumbers',
    /** 獨立董事席次 */
    BoardOfIndependentDirectorsNumbers: 'boardOfIndependentDirectorsNumbers',
    /** 女性董事席次 */
    BoardOfFemaleDirectorsNumbers: 'boardOfFemaleDirectorsNumbers',
    //#endregion

    //#region 人力發展
    /** 職業災害人數 */
    OccupationalInjuryEmployees: 'occupationalInjuryEmployees',
    //#endregion

    //#region 投資人關係
    /** 公司年度召開法說會次數 */
    AnnualCompanyHeldMeetingsNumbers: 'annualCompanyHeldMeetingsNumbers',
    /** 利害關係人或公司治理專區連結*/
    StakeholderOrCorporateGovernanceAreaLink: 'stakeholderOrCorporateGovernanceAreaLink',
    //#endregion

    //#region 廢棄物管理
    /** 廢棄物量盤查情形 */
    WasteVolumeInventory: 'wasteVolumeInventory',
    /** 有害廢棄物 */
    HazardousWaste: 'hazardousWaste',
    /** 非有害廢棄物 */
    NonHazardousWaste: 'nonHazardousWaste',
    /** 總重量(有害+非有害) */
    WasteTotal: 'wasteTotal',
    //#endregion
    //#endregion --------------- 指標管理相關 ---------------


    //#region --------------- 問卷相關 ---------------

    //#region 營運經濟活動 economicActivity
    /** 活動序號 */
    EconomicActivityNumber: 'economicActivityNumber',
    /** 本公司『營運經濟活動』是否經第三方確信? */
    EconomicActivityThirdPartyConfidence: 'economicActivityThirdPartyConfidence',
    /** 該營運經濟活動適用本指引之類別 */
    EconomicActivityApplicableCategories: 'economicActivityApplicableCategories',
    /** 條件一：是否符合【對氣候變遷減緩具有實質貢獻】之技術篩選標準？ */
    EconomicActivityTechnicalCriteriaCompliance: 'economicActivityTechnicalCriteriaCompliance',
    /** 條件二：是否符合【未對其他五項環境目的造成重大危害】？ */
    EconomicActivitySignificantEnvironmentalHarm: 'economicActivitySignificantEnvironmentalHarm',
    /** 請續填不符【未對其他五項環境目的造成重大危害】項目 */
    EconomicActivityEnvironmentalHarmExplanation: 'economicActivityEnvironmentalHarmExplanation',
    /** 條件三：是否符合【未對社會保障造成重大危害】？ */
    EconomicActivityImprovementPlan: 'economicActivityImprovementPlan',
    /** 條件一、二、三如有不符合者，是否有具體改善或轉型計畫？ */
    EconomicActivitySignificantSocialHarm: 'economicActivitySignificantSocialHarm',
    //#endregion 

    //#region  個別專案項目 projectItem
    /** 本公司『個別專案項目』是否經第三方確信? */
    ProjectItemthirdPartyConfidence: 'projectItemthirdPartyConfidence',
    /** 該個別專案項目適用本指引之類別 */
    ProjectItemApplicableCategories: 'projectItemApplicableCategories',
    /** 條件一：是否符合【對氣候變遷減緩具有實質貢獻】之技術篩選標準？ */
    ProjectItemTechnicalCriteriaCompliance: 'projectItemTechnicalCriteriaCompliance',
    /**  條件二：是否符合【未對其他五項環境目的造成重大危害】？ */
    ProjectItemSignificantEnvironmentalHarm: 'projectItemSignificantEnvironmentalHarm',
    /**  請續填不符【未對其他五項環境目的造成重大危害】項目 */
    ProjectItemEnvironmentalHarmExplanation: 'projectItemEnvironmentalHarmExplanation',
    /** 條件三：是否符合【未對社會保障造成重大危害】？ */
    ProjectItemImprovementPlan: 'projectItemImprovementPlan',
    /** 條件一、二、三如有不符合者，是否有具體改善或轉型計畫？ */
    ProjectItemSignificantSocialHarm: 'projectItemSignificantSocialHarm'
    //#endregion

    //#endregion --------------- 問卷相關 ---------------
};