import React, { FC } from 'react';
import styles from './EmptySearchPage.module.scss';
import { openExternalUrl } from 'utils/redirectExternal';

export interface EmptySearchPageProps {
  word?: string;
  url?: string | null;
  className?: any;
}

const EmptySearchPage: FC<EmptySearchPageProps> = (props) => {
  // 前往連結
  const gotoTarget = ()=>{
    if(props.url){
      openExternalUrl(props.url);
    }
  }
  return(
  <div className={styles['empty-search-page-box']}>  
    <div 
    onClick={gotoTarget}
     className={`${styles['empty-msg']} ${props.url? styles.link:''} `+
    `${props.className? props.className:''}`}
   >
    {props.word}
    </div>
  </div>
)};

export default EmptySearchPage;
