import StorageName from "constants/storageName";
import {
  AccountType,
  AuthInfoModel,
  AuthPermissionModel,
} from "models/auth/authModel";
import { MarketTypeEnum } from "models/baseModel";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
export interface UserInfoState {
  userInfo: AuthInfoModel;
  setUserInfo: (info: AuthInfoModel | null) => void;
}

export const emptyUserInfo: AuthInfoModel = {
  userId: "",
  accType: AccountType.Company,
  companyCode: "",
  subCode: "",
  isAdmin: false,
  marketType: MarketTypeEnum.Listing,
  accId: "",
  accessToken: null,
  refreshToken: null,
  name: "",
  permission: new AuthPermissionModel(),
};

/** 儲存userInfo資訊 */
const setUserInfo = (newInfo: AuthInfoModel | null) => {

  if (!newInfo) {
    return { ...emptyUserInfo };
  }
  //- permission init handle
  newInfo.permission.pageCodes = newInfo.permission.pageCodes ?? [];
  newInfo.permission.actionCodes = newInfo.permission.actionCodes ?? [];
  newInfo.permission.featureCodes = newInfo.permission.featureCodes ?? [];
  newInfo.permission.roleNames = newInfo.permission.roleNames ?? [];
  return { ...newInfo };
};

/** store資訊
 * typescipt 用devtools 要多一個()
 */
const useUserInfoStore = create<UserInfoState>()(
  persist(
    devtools((set) => ({
      userInfo: { ...emptyUserInfo },
      setUserInfo: (newInfo) =>
        set((state) => {
          // immutably 方式給值，不改變state ，改變位址
          return {
            ...state,
            userInfo: setUserInfo(newInfo),
          };
        })
    })),
    {
      name: StorageName.USER_INFO,
      storage: createJSONStorage(() => localStorage),
      // 指定儲存部分欄位
      // partialize: (state) => ({ userInfo: state.userInfo })
    }
  )
);

export default useUserInfoStore;
