import { HttpClient } from 'services/httpClient';
import {
  QuestuinnaireSampleRequestModel,
  QuestionnaireDataListRequestModel,
  QuestionnaireSampleDataModel,
} from 'models/application/sustainQuestionnaire/sustainQuestionnaireModel';

const httpClient = new HttpClient();

/** 取得所有問卷年資料 */
const getSustainQuestionnaireYears = () => {
  return httpClient.get('SustainQuestionnaire/years');
};

/** 取得公司問卷年度範本表單資料列表 */
const getQuestionnaireYearSample = (
  request: QuestuinnaireSampleRequestModel
) => {
  return httpClient.post('SustainQuestionnaire/sample', request);
};

/** 取得公司問卷表單資料列表 */
const getQuestionnaireSamples = (
  request: QuestionnaireDataListRequestModel
) => {
  return httpClient.post('SustainQuestionnaire/dataList', request);
};

/** 取得公司問卷表單資料 */
const getQuestionnaireData = (sampleId: string, dataId?: string) => {
  if (!dataId) {
    return httpClient.get(`SustainQuestionnaire/data?sampleId=${sampleId}`);
  }
  return httpClient.get(
    `SustainQuestionnaire/data?sampleId=${sampleId}&dataId=${dataId}`
  );
};

/** 新增公司問卷表單 */
const addSustainQuestionnaireData = (request: QuestionnaireSampleDataModel) => {
  return httpClient.post('SustainQuestionnaire/add', request);
};

/** 儲存公司問卷表單 */
const saveSustainQuestionnaireData = (
  request: QuestionnaireSampleDataModel
) => {
  return httpClient.post('SustainQuestionnaire/edit', request);
};

/** 刪除公司問卷表單 */
const deleteQuestionnaireDatas = (data: string[]) => {
  return httpClient.post('SustainQuestionnaire/delete', data);
};

/** 確認申報公司問卷表單 */
const declareSustainQuestionnaireData = (dataId: string) => {
  return httpClient.post(`SustainQuestionnaire/finished/${dataId}`);
};

const SustainQuestionnaireService = {
  getSustainQuestionnaireYears,
  getQuestionnaireYearSample,
  getQuestionnaireSamples,
  getQuestionnaireData,
  addSustainQuestionnaireData,
  saveSustainQuestionnaireData,
  deleteQuestionnaireDatas,
  declareSustainQuestionnaireData,
};

export default SustainQuestionnaireService;
