import moment from "moment";

/** 日期樣式1 */
const normalDate = (date: any): string => {
    if (!date) {
        return date;
    }
    return moment(date).format('yyyy-MM-DD');
}

/** 日期樣式2 */
const normalDate2 = (date: any): string => {
    if (!date) {
        return date;
    }
    return moment(date).format('yyyy.MM.DD');
}

/** 日期樣式3 */
const normalDate3 = (date: any): string => {
    if (!date) {
        return date;
    }
    return moment(date).format('yyyy/MM/DD');
}

/** 日期格式 */
const dateFormat = (date: any, formatStr: string): string => {
    if (!date) {
        return date;
    }
    return moment(date).format(formatStr);
}

export { normalDate, normalDate2 , dateFormat, normalDate3};