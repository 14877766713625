import { MopsSustainQuestionnaireDataRequestModel } from 'models/inquiry/sustainEconomic/sustainEconomicModel';
import { HttpClient } from 'services/httpClient';

const httpClient = new HttpClient();

/** 永續經濟活動公司查詢 */
const getMopsSustainQuestionnaireData = (
  request: MopsSustainQuestionnaireDataRequestModel
) => {
  return httpClient.post(`MopsSustainQuestionnaire/singleCompanyData`, request);
};

const SustainEconomicService = {
  getMopsSustainQuestionnaireData,
};

export default SustainEconomicService;
