import Navbar from "components/layouts/Navbar/Navbar";
import { NavBarItemModel } from "models/baseModel";
import { FC } from "react";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
import styles from "./SystemManageRouter.module.scss";
import SysLog from "./SysLog/SysLog";
import SysFunction from "./SysFunction/SysFunction";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import {
  FeaturePermissionCodes,
  PermissionRouteObjectModel,
  PermissionNavBarItemModel,
  ActionsPermissionCodes,
} from "models/auth/permissionModel";
import EmptyWaitPage from "components/common/EmptyWaitPage/EmptyWaitPage";
import useUserInfoStore from "state/useUserInfoStore";
import { usePermissionContext } from "context/PermissionProvider";

export const SystemManageLayout: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const { PermissionState, PermissionService } = usePermissionContext();
  const { noPermissionPage } = PermissionState;
  const { detectEveryFeatruePermission } = PermissionService;

  const navItems: PermissionNavBarItemModel[] = [
    {
      name: "系統功能管理",
      url: "function",
      permission: FeaturePermissionCodes.SystemManage.SystemFunctionManage,
    },
    {
      name: "系統操作紀錄",
      url: "log",
      permission: FeaturePermissionCodes.SystemManage.SystemLog,
    },
  ];
  const filterNavItems = navItems.filter((e) =>
    detectEveryFeatruePermission([e.permission])
  );
  // console.log("filterNavItems", filterNavItems);
  // * ---------------------------- state end ---------------------------- *//
  return (
    <>
      <CustomErrorBoundary>
        {filterNavItems?.length ? (
          <>
            {/* tab 資訊 */}
            <Navbar items={filterNavItems} />
            <div className="nav-content-box">
              <Outlet></Outlet>
            </div>
          </>
        ) : (
          noPermissionPage()
        )}
      </CustomErrorBoundary>
    </>
  );
};

interface SystemManageRouterProps {}
const SystemManageRouter: FC<SystemManageRouterProps> = () => {
  //* ---------------------------- state start ---------------------------- *//
  const { PermissionService } = usePermissionContext();
  const { permissionRouter } = PermissionService;

  const routers: PermissionRouteObjectModel[] = [
    {
      path: "",
      element: <SystemManageLayout />,
      children: [
        {
          path: "function",
          element: <SysFunction />,
          featurePermission:
            FeaturePermissionCodes.SystemManage.SystemFunctionManage,
          actionReadPermission:
            ActionsPermissionCodes.SystemManage.SystemFunctionManageRead,
        },
        {
          path: "log",
          element: <SysLog />,
          featurePermission: FeaturePermissionCodes.SystemManage.SystemLog,
          actionReadPermission:
            ActionsPermissionCodes.SystemManage.SystemLogRead,
        },
      ],
    },
  ];

  //* ---------------------------- state end ---------------------------- *//

  let elements = useRoutes(permissionRouter(routers));
  return (
    <>
      <div className={styles["systemManage-layout"]}>{elements}</div>
    </>
  );
};

export default SystemManageRouter;
