//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect } from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import moment from "moment";
import { useNavigate } from "react-router-dom";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./Description.module.scss";
import IconButton from "components/button/IconButton/IconButton";
import SearchInput from "components/base/SearchInput/SearchInput";
import Dropdown from "components/base/Dropdown/Dropdown";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import TablePagination from "components/base/TablePagination/TablePagination";
import { PaginationModel, ListRequestModel } from "models/baseModel";
import { OptionModel, BaseResponse } from "models/baseModel";
import QuestionnaireService from "services/admin/questionnaireService";
import { QuestionnaireSettingModel } from "models/admin/questionnaireModel";
import SwitchCheckbox from "components/base/SwitchCheckbox/SwitchCheckbox";
import { success2Alert, error2Alert } from "utils/otherToast";
import SustainQuestionnaireService from "services/application/sustainQuestionnaire/sustainQuestionnaireService";
import { QuestionnaireYearModel } from "models/application/sustainQuestionnaire/sustainQuestionnaireModel";
//* ---------------------------- local end ---------------------------- *//

import { Link } from "react-router-dom";
import { normalDate2, normalDate3 } from "utils/dateTimeFormat";
import toast, { Toaster } from "react-hot-toast";
import { IndicatorDelareInfoListModel } from "models/application/indicators/indicatorModel";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { CommonService } from "services/common/commonService";
import useUserInfoStore from "state/useUserInfoStore";
import EmptySearchPage from "components/otherModule/EmptySearchPage/EmptySearchPage";
import ProblemReportContent from "components/otherModule/ProblemReportContent/ProblemReportContent";

interface DescriptionProps {}

export interface DescriptionModel {}

const Description: FC<DescriptionProps> = () => {
  //* ---------------------------- state start ---------------------------- *//
  const { userInfo } = useUserInfoStore();
  const [questionnaireYearList, setQuestionnaireYearList] = useState<{
    count: number;
    list: QuestionnaireYearModel[];
  }>({
    count: 0,
    list: [],
  });
  const [tablePagination, setTablePagination] = useState<PaginationModel>({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  // 有無公司資料
  const [companyDataWord, setCompanyDataWord] = useState<string | null>(null);
  const navigate = useNavigate();
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getSustainQuestionnaireYears
  const {
    mutate: getSustainQuestionnaireYearsMutate,
    isPending: getSustainQuestionnaireYearsIsPending,
  } = useMutation({
    mutationFn: () =>
      SustainQuestionnaireService.getSustainQuestionnaireYears(),
    onSuccess: (res) => {
      console.log("getSustainQuestionnaireYears", res);
      if (res.code === 200 && res.success) {
        setQuestionnaireYearList({
          count: res.count!,
          list: res.data,
        });
      } else {
        setQuestionnaireYearList({
          count: 0,
          list: [],
        });
      }
    },
    onError: (err) => {
      console.log("getSustainQuestionnaireYears", err);
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  const navigateToListPage = (year: number) => {
    navigate(`/apply/sustain-questionnaire/${year}`);
  };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init
  useEffect(() => {
    // 無文字才能正常顯示
    let str = CommonService.getApplyDisplayWord(userInfo, "永續經濟活動問卷");
    if (str) {
      setCompanyDataWord(str);
    } else {
      getSustainQuestionnaireYearsMutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //* ---------------------------- hook end ---------------------------- *//

  return (
    <div className="no-nav-content-box">
      {!companyDataWord ? (
        <div className={styles["indcator-list-box"]}>
          <div className="title mb-20-p">永續經濟活動問卷</div>
          <div className="result-box scroll">
            <div className="table-box">
              {getSustainQuestionnaireYearsIsPending ? (
                <VisuallLoading />
              ) : null}
              <div className="topBarDesc mb-3">
                為鼓勵金融業將資金導引至永續的經濟活動，帶動企業永續發展及減碳轉型，以及協助企業及金融業判斷何謂永續經濟活動，避免「漂綠」之情形發生，本申報系統以鼓勵方式，推動上市櫃公司參考金管會「永續經濟活動認定參考指引」，以自願方式揭露於公開資訊觀測站。
              </div>
            </div>
            <table
              aria-label="查詢結果table"
              className="table-container sticky-table"
            >
              <thead>
                <tr>
                  <th scope="col">年度</th>
                  <th scope="col">最後編輯日期</th>
                  <th scope="col">功能</th>
                </tr>
              </thead>
              <tbody>
                {questionnaireYearList.list?.map(
                  (item: QuestionnaireYearModel, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.year}</td>
                        <td>
                          {item.updateTime
                            ? moment(item.updateTime).format("yyyy-MM-DD")
                            : "-"}
                        </td>
                        <td>
                          <button
                            className="secondary operator-Btn"
                            onClick={() => navigateToListPage(item.year)}
                          >
                            開始申報
                          </button>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
          {/* <div className="table-pagination">
          <TablePagination
            total={tablePagination.total!}
            onChange={(e) => {
              set((prev) => ({
                ...prev,
                page: e.page,
                pageSize: e.pageSize,
              }));
            }}
            page={tablePagination.page}
            pageSize={tablePagination.pageSize}
          />
        </div> */}
          <div className={`reference`}>
            <div className="reference-title">參考資料：</div>
            <ol>
              <li>
                「
                <a
                  href="https://www.fsc.gov.tw/uploaddowndoc?file=news/202308211724070.pdf&filedisplay=%E9%99%84%E4%BB%B61%E6%B0%B8%E7%BA%8C%E7%B6%93%E6%BF%9F%E6%B4%BB%E5%8B%95%E8%AA%8D%E5%AE%9A%E5%8F%83%E8%80%83%E6%8C%87%E5%BC%95.pdf&flag=doc"
                  target="_blank"
                  rel="noreferrer"
                >
                  永續經濟活動認定參考指引
                </a>
                」
              </li>
              <li>
                「
                <a
                  href="https://www.fsc.gov.tw/uploaddowndoc?file=news/202212081505421.pdf&filedisplay=%E9%99%84%E4%BB%B62%E6%B0%B8%E7%BA%8C%E7%B6%93%E6%BF%9F%E6%B4%BB%E5%8B%95%E8%AA%8D%E5%AE%9A%E5%8F%83%E8%80%83%E6%8C%87%E5%BC%95%E7%B0%A1%E5%A0%B1.pdf&flag=doc"
                  target="_blank"
                  rel="noreferrer"
                >
                  永續經濟活動認定參考指引簡報
                </a>
                」
              </li>
              <li>
                「
                <a
                  href="https://www.fsc.gov.tw/uploaddowndoc?file=news/202212081505422.pdf&filedisplay=%E9%99%84%E4%BB%B63%E6%B0%B8%E7%BA%8C%E7%B6%93%E6%BF%9F%E6%B4%BB%E5%8B%95%E8%AA%8D%E5%AE%9A%E5%8F%83%E8%80%83%E6%8C%87%E5%BC%95%E5%95%8F%E7%AD%94%E9%9B%86.pdf&flag=doc"
                  target="_blank"
                  rel="noreferrer"
                >
                  永續經濟活動認定參考指引問答集
                </a>
                」
              </li>
            </ol>
            <div className="reference-source">
              <div className="reference-title">來源：</div>
              <a
                href="https://www.fsc.gov.tw/ch/home.jsp?id=96&parentpath=0,2&mcustomize=news_view.jsp&dataserno=202212080003&dtable=News"
                target="_blank"
                rel="noreferrer"
              >
                金管會與環保署、經濟部、交通部、內政部共同公告「永續經濟活動認定參考指引」，鼓勵金融業協助企業朝永續減碳轉型-金融監督管理委員會全球資訊網
                (fsc.gov.tw)
              </a>
            </div>
            <div className="mt-2">
            <ProblemReportContent customClass="desc"/>
            </div>
          </div>
        </div>
      ) : (
        <EmptySearchPage
          className={"no-company-data-word"}
          word={companyDataWord}
        />
      )}
    </div>
  );
};

export default Description;
