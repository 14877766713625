//* common serivce 可以共用的處理函數在這裡寫

import { AxiosResponse } from "axios";
import StorageName from "constants/storageName";
import { AuthInfoModel } from "models/auth/authModel";
import { StreamFileType, MarketTypeEnum, OptionModel } from "models/baseModel";
import DOMPurify from 'dompurify';
//- 深拷貝
const deepClone = <T>(originData: T): T => {
  if (originData) {
    return JSON.parse(JSON.stringify(originData));
  }
  return originData;
}

/** 取得預設西元年option */
const getDefaultYearOp = (preYear: number = 2011): OptionModel[] => {
  // 110~到至今
  const nowYear = new Date().getFullYear();
  const op: OptionModel[] = [];
  for (let i = preYear; i <= nowYear; i++) {
    const year = i.toString();
    op.push({
      id: year,
      text: year
    });
  }
  return op;
}

/** 根據url 前端下載 */
const downloadByUrl = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.click();
  link.remove();
}

/** 根據stream 前端下載 */
const downloadByStream = (res: AxiosResponse, fileType = StreamFileType.Xlsx) => {
  // 為了取到header當中的檔案名稱，回傳格式調整為response，內容調整為response.body
  let blob: Blob = new Blob([res.data], { type: fileType });
  const disposition = res.headers['content-disposition'];
  if (disposition) {

    let fileName = disposition.split(';')[1].trim().split('=')[1].replace(/"/g, '');
    if (fileName) {
      // decode fileName
      fileName = decodeURIComponent(fileName);
      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = fileName;
      link.href = downloadURL;
      link.target = "_blank";
      link.click();
      link.remove();
    }
  }
}

/** 轉民國字串為西元年資料 */
const convertRCYearToADYearByString = (rcYear: string | null): number | null => {
  if (!rcYear) {
    return null
  } else {
    return Number.parseInt(rcYear) + 1911;
  }
}

//- 驗證 uuid
const isUUID = (uuid: string) => {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return uuidRegex.test(uuid);
}


/**  (申報)市場別權限*/
const getApplyMarketPermissions = (type: MarketTypeEnum) => {
  const permissions = ['home', 'indicator', 'sustain-report', 'ghg-reduction', 'sustain-questionnaire', 'report-generation', 'propaganda'];
  switch (type) {
    case MarketTypeEnum.Listing:
    case MarketTypeEnum.OTC:
      return permissions;
    case MarketTypeEnum.Emerging:
    case MarketTypeEnum.PO:
      return ['home', 'sustain-report', 'ghg-reduction', 'propaganda'];
    default:
      return [];
  }
}

/** 開發測試模式 */
const isDevMode = () => {
  const url = window.location.host;
  const preUrl = `${window.location.protocol}//${url}`;
  if (preUrl.includes('localhost') ||
    preUrl.includes('twse.chase.com.tw')) {
    return true;
  } else {
    return false;
  }
}

/** 是否為證交所查詢前台 */
const isTwseInquiry = (): boolean => {
  const host = window.location.href;
  // 完全符合 代表從外部導入 並沒有任何跳轉 (符合其一就可以)
  const urls = [
    'https://esggenplus.twse.com.tw/',
    'https://esggenplus.twse.com.tw',
    'https://esggenplustest.twse.com.tw/',
    'https://esggenplustest.twse.com.tw'
  ]

  return urls.includes(host);
}

/** 是否為證交所正式機 */
const isTwseProduction = (): boolean => {
  const host = window.location.origin;
  const url = 'https://esggenplus.twse.com.tw';
  return host === url;
};

/** 取得市場別名稱 */
const getMarketTypeName = (type: MarketTypeEnum): string => {
  switch (type) {
    case MarketTypeEnum.Emerging:
      return "興櫃";
    case MarketTypeEnum.Listing:
      return "上市";
    case MarketTypeEnum.OTC:
      return "上櫃";
    case MarketTypeEnum.PO:
      return "公發";
    default:
      return "";
  }
}

/** 公發/興櫃申報(若登入尚無lastMarketType等資訊)需顯示的文字 */
const getApplyDisplayWord = (userInfo: AuthInfoModel, moduleWord: string) => {
  const marketWord = ['證交所公司治理部', '櫃買中心管區'];
  const str = `貴公司為新${getMarketTypeName(userInfo.marketType)}公司，新增${moduleWord}資料，請聯繫「{0}」。`;
  let outputStr = null;
  /**
   * 若是前一年度有在 公發/興櫃 今年度轉 上/市櫃
      =>  lastYearMarketType: 2 或 3
      => marketTypep: 0 或 1

      若是前一年度都沒有在 公發/興櫃
      => lastYearMarketType: null
      => marketTypep: 0 或 1

      若是前一年度都沒有在 公發/興櫃(但是今年是公發/興櫃)
      => lastYearMarketType: null
      => marketTypep: 2 或 3
   */
  const lastMarket = userInfo.lastYearMarketType;
  const nowMarket = userInfo.marketType;
  if (lastMarket === null) {
    if (nowMarket === MarketTypeEnum.Listing) {
      outputStr = str.replace("{0}", marketWord[0]);
    } else {
      outputStr = str.replace("{0}", marketWord[1]);
    }
  } else if (lastMarket === MarketTypeEnum.PO ||
    lastMarket === MarketTypeEnum.Emerging) {
    if (nowMarket === MarketTypeEnum.Listing) {
      outputStr = str.replace("{0}", marketWord[0]);
    } else if (nowMarket === MarketTypeEnum.OTC) {
      outputStr = str.replace("{0}", marketWord[1]);
    }
  }
  return outputStr;
}

/** 是否為url */
const isURL = (str: string): boolean => {
  if (!str) {
    return false;
  }
  // 匹配URL的正则表达式
  const urlPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
  return urlPattern.test(str);
}

/** 確定有無http or https */
const getProtocolUrl = (url: string): string => {
  if (!url) {
    return url;
  }
  let newUrl = url;
  const pattern = /^(https?:\/\/)/i;
  if (!pattern.test(url)) {
    newUrl = 'http://' + url;
  }
  return newUrl;
}

/** 取得網站site url */
const getWebsiteHost = (): string => {
  const url = window.location.host;
  const preUrl = `${window.location.protocol}//${url}`;
  return preUrl;
}

/** 取得網站site url */
const getWebsiteHref = (): string => {
  const url = window.location.href;
  const preUrl = `${window.location.protocol}//${url}`;
  return preUrl;
}

/** csv 部分轉譯符號設置 */
const sanitizeCSVField = (field: string | null): string => {
  if (!field) {
    return "";
  }
  try {

    // 替換雙引號
    field = field.replace(/"/g, '""');
    // 替換換行
    field = field.replace(/\n/g, ' ');

    // 包裹字段以處理逗號 (如果只存在逗號，前後加引號就可以) 
    // if (field.includes(',')) {
    //   field = `"${field}"`;
    // }
    return field;
  } catch (ex) {
    console.log('sanitizeCSVField', ex);
    return field || "";
  }
}

/** 轉為本地isos時間 */
const toLocalISOString = (date: Date) => {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-";
  const pad = (num: number) => {
    let norm = Math.abs(Math.floor(num));
    return (norm < 10 ? "0" : "") + norm;
  };
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};

/** 是否在排除API ROUTER上面 */
const isExcludeApiRouter = (): boolean => {
  // false 代表在裡面非排除, true 代表排除
  let url = getWebsiteHref();
  // 查詢inquiry 轉跳頁esgcheck 開發機登入頁entrance 開發工具頁 development開發工具頁
  let pass = true;
  let excludeArrs = ['inquiry', 'esgcheck', 'entrance', 'empty', 'development'];
  excludeArrs.forEach(p => {
    if (pass) {
      if (url.includes(p)) {
        pass = false;
      }
    }
  })
  return pass;
}

/** 加強html 文本安全性問題 */
const sanitizedData = (orgData:string|null) => {
  if(!orgData){
    return { __html: "<></>" } ;
  }
  return { __html: DOMPurify.sanitize(orgData) } 
}

export const CommonService = {
  deepClone,
  getDefaultYearOp,
  downloadByUrl,
  convertRCYearToADYearByString,
  isUUID,
  getApplyMarketPermissions,
  downloadByStream,
  isDevMode,
  getApplyDisplayWord,
  getMarketTypeName,
  isURL,
  isTwseInquiry,
  isTwseProduction,
  getProtocolUrl,
  getWebsiteHost,
  getWebsiteHref,
  sanitizeCSVField,
  toLocalISOString,
  isExcludeApiRouter,
  sanitizedData
};
