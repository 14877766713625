import { SustainReportUserTreeNode } from "models/application/sustainReportDeclare/sustainReportProduceModel";
import Wrapper from "pages/Application/ReportGeneration/ReportGenerationInfo/RGstep1/GroupTree/Wrapper";
import { FC, useRef } from "react";

interface GroupTreeProps {
  group: SustainReportUserTreeNode[];
  // 選擇Item 項目
  clickItem: (groupsIndex: number[], group: SustainReportUserTreeNode, type:string) => void;
  /** 將最後排序結果資料往外傳 */
  setGroup: (groups: SustainReportUserTreeNode[]) => void;
}
/** 樹狀資料 */
const GroupTree: FC<GroupTreeProps> = (props) => {
  const { group, clickItem, setGroup: handleChangeGroups } = props;
  const ref = useRef<any[]>([]);

  // 設定group
  const handleSetGroups = (groupsIndex: number[], currentList: SustainReportUserTreeNode[]) => {
    // 更動的結果傳遞將從遞迴的最內層依序往外層放入
    // 將子層資料放入ref 做後續拖曳判別使用
    ref.current.push({ groupsIndex, currentList });
  };

  // 拖曳結束時更動的方式
  const handleOnEnd = () => {
    // 觸發的優先序 會在 handleSetGroups 之後
    let attemps = [...ref.current];
    // 1. Trying to execute attems in original order
    // -> same error

    // 2. Trying to execute attems in new order (the deeper will be execute firts)
    // -> worked!!!
    attemps.sort((a, b) => b.groupsIndex.length - a.groupsIndex.length);
    // 3. Execute attems

    //#region 原始陣列比較跟改值
    // 複製原始的陣列
    let tempList:SustainReportUserTreeNode[] = [...group];
    let attempIndex = 0;
    // 根據階層的groupIndex 找到對應要修改的數據階層
    while (attempIndex < attemps.length) {
      const attemp = { ...attemps[attempIndex] };
      attempIndex++;
      // 遞迴每一層的index
      const _blockIndex = [...attemp.groupsIndex];
      // 找出該陣列對應位子最後的index
      const lastIndex = _blockIndex.pop()!;
      // 用原始陣列找出其對應位子的items
      const lastArr = _blockIndex.reduce(
        (arr, i) => arr[i]["children"] ?? [],
        tempList
      );
      // 移動後新的items
      lastArr[lastIndex]["children"] = attemp.currentList;
    }
    // 清空暫存的資料
    ref.current = [];
    // 將移動後最終結果傳給外層
    handleChangeGroups(tempList);
    //#endregion
  };

  return (
    <div className={"tree-table-box"}>
      {group &&
        group.length > 0 &&
        group.map((item, index) => {
          return (
            <Wrapper
              key={item.userChapterId}
              group={item}
              groupsIndex={[index]}
              setList={handleSetGroups}
              onEnd={handleOnEnd}
              clickItem={clickItem}
              isRoot
            />
          );
        })}
    </div>
  );
};

export default GroupTree;
