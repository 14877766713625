import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./EmailInviteInput.module.scss";
import { error2Alert } from "utils/otherToast";

interface EmailInviteInputProps {
  onChange:(items:string[])=> void;
}

const EmailInviteInput: FC<EmailInviteInputProps> = (props) => {
  const [obj, setObj] = useState({
    emails: [] as string[],
    value: "",
  });
  const boxRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isValid = (email: string): boolean => {
    let pass = true;
    let message = '';
    if (isInList(email)) {
      message = `${email}已經存在\n`;
      pass = false;
    }

    if (!isEmail(email)) {      
      message += `${email}格式不正確`;
      pass = false;
    }

    if (!pass) {
      error2Alert(message);
      return false;
    }
    return true;
  };
  /** 驗證email正確 */
  const isEmail = (email: string) => {
    return /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/.test(
      email
    );
  };

  const isInList = (email: string) => {
    return obj.emails.includes(email);
  };

  // 按下按鈕時
  const handleKeyDown = (evt: any) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      const value = obj.value;
      dataCheck(value);
    }
  };

  const dataCheck = (value:string| undefined)=>{
    if (value && isValid(value)) {
      const newEmails = [...obj.emails, value];
      props.onChange(newEmails);
      setObj({
        ...obj,
        emails: newEmails,
        value: ""
      });
    }
  }

  // 改變值
  const handleChange = (evt: any) => {
    setObj((prev) => {
      return {
        ...prev,
        value: evt.target.value,
      };
    });
  };

  // 刪除
  const handleDelete = (email: string) => {
    
    const newEmails = obj.emails.filter((p) => p !== email);
    props.onChange(newEmails);
    setObj((prev) => {
      return {
        ...prev,
        emails: newEmails,
      };
    });
  };

  useEffect(() => {
    const handleOtherClick = (e: any) => {
      if (!boxRef.current?.contains(e.target)) {
        dataCheck(inputRef.current?.value);
      }
    };
    document.body.addEventListener("click", handleOtherClick);
    return () => {
      document.body.removeEventListener("click", handleOtherClick);
    };
  }, [obj]);

  return (
    <div ref={boxRef} className={styles["email-invite-box"]}>
      <div className="email-box">
        {obj.emails.map((email) => {
          return (
            <div className="email-item" key={email}>
              {email}
              <img alt="" 
              className="delete-icon"
              onClick={()=>{handleDelete(email)}}
              src="/assets/images/buttonIcon/toast-error2-icon.svg" />

            </div>
          );
        })}
        
      {/* 輸入框 */}
        <input
          type="text"
          ref={inputRef}
          className={`seach-input `+
          `${obj.emails.length !== 0?'any-item': ''}`
          }
          value={obj.value}
          placeholder={obj.emails.length === 0 ? "請填寫E-mail資訊（可多筆）":""}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </div>
    </div>
  );
};

export default EmailInviteInput;
