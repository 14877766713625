/** 編輯器model */
export interface jsEditorOptionModel {
    /** 前端自定義 */
    id?: string;
    /** 編輯器editor */
    selector?: string;
    /**  語言 */
    language?: string | null;
    /** 關閉品牌 powerd by tinyMCE字樣 */
    branding?: boolean;
    /** 編輯器高度 */
    height?: number;
    /**  menu呈現 */
    menubar?: boolean;
    toolbar_sticky?: boolean;
    image_advtab?: boolean;
    /**　可以重外部貼入圖片 */
    paste_data_images?: boolean;
    etd_file_name?: string;
    /** tool出來模式 */
    toolbar_mode?: string;
    setup?: any;
    /** 初始化後的callback */
    init_instance_callback?: any;
    /** 工具列表上的功能 */
    toolbar?: string;
    /** 文字格式下拉工具列按鈕 */
    block_formats?: string;
    /** 文字大小下拉工具列按鈕 */
    font_size_formats?: string;
    /** 字體大小的預設測量單位 */
    font_size_input_default_unit?: string;
    /** 字體大小的預設測量單位 */
    style_formats?: any[];
    /** 字體大小的預設測量單位 */
    style_formats_merge?: boolean;
    /** plugins */
    plugins?: string;
    /** file_picker_callback */
    file_picker_callback?: Function;
    /** statusbar */
    statusbar?: boolean;
    /** autosave_ask_before_unload */
    autosave_ask_before_unload?: boolean;
    /** content_style */
    content_style?: string;
    /** contextmenu */
    contextmenu?: string;
    /** license_key */
    license_key?: string;
}

/** JS版本對應option */
export const jsEditorOp = {
    /** 前端自定義 */
    id: null,
    /** 編輯器editor */
    selector: null,
    // 語言中文
    language: 'zh_TW',
    // 關閉品牌 powerd by tinyMCE字樣
    branding: true,
    height: 500,
    // menu呈現
    menubar: false,
    // 先暫時不設置，有些情形會出錯
    // toolbar_sticky: false,
    image_advtab: true,
    // 可以重外部貼入圖片
    paste_data_images: true,
    etd_file_name: 'tinymce-content',
    // 要關閉之前提醒為保存的更改
    // autosave_ask_before_unload: true,
    // 儲存草稿30分鐘
    // autosave_retention: '30m',
    // 載入的plugins
    // quickbars 快速bars先不用
    //- adjust importcss 10/04/23
    // plugins: `advlist autolink lists link image
    // charmap preview anchor searchreplace
    // visualblocks code fullscreen insertdatetime
    // media table code help wordcount visualchars
    // importcss autosave`,
    //- adjust importcss 10/04/23
    plugins: `advlist autolink lists link image
    charmap preview anchor searchreplace
    visualblocks code fullscreen insertdatetime
    media table code help wordcount visualchars
    autosave`,
    // 工具列表上的功能
    toolbar: `undo redo | fontfamily fontsize blocks  |
      bold italic underline strikethrough |
      forecolor backcolor |
      alignleft aligncenter alignright alignjustify |
      bullist numlist outdent indent |
      image link table |
      fullscreen preview`,
    // 字形樣式
    font_family_formats: `
    ${getFormatEnglish('微軟正黑體')}=微軟正黑體,Microsoft JhengHei;
    ${getFormatEnglish('新細明體')}=新細明體,PMingLiU;
    ${getFormatEnglish('標楷體')}=標楷體,DFKai-sb;
    Times New Roman=times new roman,times;
    Andale Mono=andale mono,monospace;Arial=arial,helvetica,sans-serif;Arial Black=arial black,sans-serif;Book Antiqua=book antiqua,palatino,serif;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier,monospace;Georgia=georgia,palatino,serif;Helvetica=helvetica,arial,sans-serif;Impact=impact,sans-serif;Terminal=terminal,monaco,monospace;Trebuchet MS=trebuchet ms,geneva,sans-serif;Verdana=verdana,geneva,sans-serif`,
    // tool出來模式
    toolbar_mode: 'wrap',
    // 快速點兩下會出現的bar 暫時不用
    // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable'
    setup: null,
    init_instance_callback: null,
    table_cell_class_list: [
        {
            title: 'None',
            value: '',
        },
        {
            title: 'Base cell',
            value: 'tiny-cell-border',
        },
    ],
    /** 暫時，後續等商用授權 */
    license_key:'gpl'
};

export function TransEditorLanguage(): void {
    if (localStorage.getItem('language') === 'zh') {
        jsEditorOp.language = 'zh_TW';
    } else {
        // 其他一律都是英文版
        jsEditorOp.language = 'en';
    }
}

function getFormatEnglish(str: string): string {
    let language = localStorage.getItem('language');
    let result = '';
    if (language === 'zh-TW') {
        result = str;
    } else {
        if (language === 'zh-CN') {
            switch (str) {
                case '微軟正黑體':
                    result = '微软正黑体';
                    break;
                case '新細明體':
                    result = '新细明体';
                    break;
                case '標楷體':
                    result = '标楷体';
                    break;
            }
        } else {
            switch (str) {
                case '微軟正黑體':
                    result = 'Microsoft JhengHei';
                    break;
                case '新細明體':
                    result = 'PMingLiU';
                    break;
                case '標楷體':
                    result = 'DFKai-sb';
                    break;
            }
        }
    }
    return result;
}
