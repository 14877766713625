import { SystemManageType } from "models/admin/systemManageModel";
import { SustainCorrectionRequestModel, SustainReportDeclareActionModel, SustainReportExportExcelModel, SustainReportExportModel } from "models/application/sustainReportDeclare/sustainReportDeclareModel";
import { ListRequestModel } from "models/baseModel";
import { HttpClient } from "services/httpClient";

/** 定義的query keys */
export const SustainReportDeclareKeys = {
    infoList: 'infoList'
};

const httpClient = new HttpClient();

/** 透過companyCode取得永續報告書申報作業清單 */
const getSustainReportDeclareList = (model:ListRequestModel) => {
    return httpClient.post(`sustainReportDeclare/list`, model);
}

/** 透過declareId取得永續報告書申報作業資訊 */
const getSustainReportDeclareData = (declareId: string) => {
    return httpClient.get(`sustainReportDeclare/data?declareId=${declareId}`);
}

/**  新增/編輯永續報告書申報作業資訊 */
const editSustainReportDeclareData = (data: any) => {
    return httpClient.post(`sustainReportDeclare/data`,data);
}

/** 永續報告書申報作業-更正申請 */
const addSustainReportDeclareDatacCrrectionRequest = (data: SustainCorrectionRequestModel) => {
    return httpClient.post(`sustainReportDeclare/data/correction-request`,data);
}

/** 上傳永續報告書修正版 */
const editRevisedVersionReport = (data: any) => {
    return httpClient.post(`sustainReportDeclare/revised-version-report`,data);
}

/** 變更永續報告書申報狀態 */
const editSustainReportDeclareStatus= (data: SustainReportDeclareActionModel) => {
    return httpClient.post(`sustainReportDeclare/status`,data);
}

/** 取得會計師事務所清單 */
const getAccountingAssociationList= () => {
    return httpClient.get(`sustainReportDeclare/accountingAssociation/list`);
}


/** 取得永續報告書申報作業資訊的檔案下載 */
const getSustainReportDeclareDataFileStream= (id: string) => {
    return httpClient.getBlob(`sustainReportDeclare/data/FileStream?id=${id}`);
}

/** 取得永續報告書匯出excel */
const getExportExcel = (param:SustainReportExportExcelModel)=>{
    return httpClient.postBlob(`sustainReportDeclare/export/excel`, param)

}

/** 後台系統功能管理是否開啟功能(登入版)，type: 
 0=XBRL匯出按鈕
 1=ESG報告書AI解析按鈕
 2=永續報告書產製
 3=下載永續準則矩陣
*/
const isActiveFunction = (type: SystemManageType) => {
    return httpClient.get(`SystemManage/status?type=${type}`);
}
/** 不需登入版 */
const isActiveNoAuthFunction = (type: SystemManageType) => {
    return httpClient.get(`MopsSustainReport/status?type=${type}`);
}

//#region 匯出相關
/** 匯出報告書套版 */
const exportPOCReport = (data:SustainReportExportModel)=>{
    return httpClient.postBlob(`sustainReportExport/exportReport/FileStream`,data)
}

/** 取得報告書套版年份清單 */
const getDeclaredEsgDeclareYearList= ()=>{
    return httpClient.get(`sustainReportExport/yearList`)
}
//#endregion

const SustainReportDeclareService = {
    getSustainReportDeclareList,
    getSustainReportDeclareData,
    editSustainReportDeclareData,
    addSustainReportDeclareDatacCrrectionRequest,
    editRevisedVersionReport,
    exportPOCReport,
    getDeclaredEsgDeclareYearList,
    editSustainReportDeclareStatus,
    getSustainReportDeclareDataFileStream,
    isActiveFunction,
    isActiveNoAuthFunction,
    getAccountingAssociationList,
    getExportExcel
}

export default SustainReportDeclareService;