import React, { FC, ReactNode } from "react";
import styles from "./ConfirmModal.module.scss";
import { Modal } from "react-bootstrap";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";

export interface ConfirmModalProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  title?: string;
  children?: ReactNode;
  size?: "sm" | "lg" | "xl";
  loading?: boolean;
  displayType?: string;
}

const ConfirmModal: FC<ConfirmModalProps> = ({ loading = false, ...props }) => {
  const { show, handleClose, handleConfirm, title, children, size } = props;
  return (
    <Modal
      className={`${styles["confirm-modal-box"]} ${
        props.displayType ? styles[props.displayType] : ""
      }`}
      show={show}
      size={size}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      {loading && <VisuallLoading></VisuallLoading>}
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <div className="btn-box">
          <div className="btn-item mr-1-m">
            <button className="secondary" onClick={handleClose}>
              取消
            </button>
          </div>
          <div className="btn-item">
            <button className="default" onClick={handleConfirm}>
              確認
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
