import { IndicatorModel } from "models/admin/indicatorModel";
import { IndustryManageRequestModel, IndustryModel, MarketYearModel, MarketYearRequestModel, EditCompanyModel, ChangeIndustryModel } from "models/admin/industryModel";
import { HttpClient } from "services/httpClient";

const httpClient = new HttpClient();

// #region 行業別管理

/** 行業別管理(年度) (0=全部,1=上市,2=上櫃,3=公發,4=興櫃) */
const marketList = (request: MarketYearRequestModel) => {
    return httpClient.post(`IndustryYear/list`, request);
}

/** 新增行業別年度資料 */
const addMarket = (data: MarketYearModel) => {
    return httpClient.post('IndustryYear/add', data);
}

/** 編輯行業別年度資料 */
const saveMarket = (data: MarketYearModel) => {
    return httpClient.post(`IndustryYear/${data.id}`, data);
}

/** 複製行業別年度資料 */
const copyMarket = (data: MarketYearModel) => {
    return httpClient.post('IndustryYear/copy', data);
}

/** 刪除行業別年度資料 */
const removeMarket = (ids: (string|null)[] | null) => {
    return httpClient.post(`IndustryYear/delete`, ids);
}

// #endregion

// #region 產業類別管理

/** 產業類別(年度) */
const industryList = (request: IndustryManageRequestModel) => {
    return httpClient.post(`IndustryManage/list`, request);
}

/** 新增產業類別 */
const addIndustry = (data: IndustryModel) => {
    return httpClient.post('IndustryManage/add', data);
}

/** 編輯產業類別資料 */
const saveIndustry = (data: IndustryModel) => {
    return httpClient.post(`IndustryManage/${data.id}`, data);
}

/** 刪除產業類別資料 */
const removeIndustry = (ids: (string|null)[] | null) => {
    return httpClient.post(`IndustryManage/delete`, ids);
}

/** 取得批次匯入產業類別資料範本 */
const loadImportXls = (industryYearId?: string) => {
    return httpClient.getBlob(`IndustryManage/importExcel/example/FileStream?industryYearId=${industryYearId}`);
}

/** 批次匯入產業類別資料 */
const importIndustry = (data: any) => {
    return httpClient.post('IndustryManage/import?industryYearId=' + data.industryYearId, data.file);
}

// #endregion

// #region 公司管理

/** 查詢公司管理 */
const getCompanyList = (request: IndustryManageRequestModel) => {
  return httpClient.post(`CompanyManage/list`, request);
}

/** 單一公司管理資料 */
const getCompanyDetail = (id: string) => {
  return httpClient.get(`CompanyManage/detail?id=${id}`);
}

/** 新增公司 */
const addCompany = (request: EditCompanyModel) => {
  return httpClient.post(`CompanyManage/Add`, request);
}

/** 編輯公司 */
const editCompany = (id: string, request: EditCompanyModel) => {
  return httpClient.post(`CompanyManage/${id}`, request);
}

/** 批次刪除公司 */
const batchDeleteCompanies = (idList: string[]) => {
  return httpClient.post(`CompanyManage/delete`, idList);
}

/** 批次匯入範本檔案-公司管理 */
const getCompanyImportExcelExample = (industryYearId: string) => {
  return httpClient.getBlob(`CompanyManage/importExcel/example/FileStream?industryYearId=${industryYearId}`);
}

/** 批次新增公司 */
const batchAddCompanies = (industryYearId: string, request: FormData) => {
  return httpClient.post(`CompanyManage/import?industryYearId=${industryYearId}`, request);
}

/** 查詢產業類別option */
const getIndustryOption = (request: IndustryManageRequestModel) => {
  return httpClient.post(`IndustryManage/industry/option`, request);
}

/** 查詢sasb option */
const getSasbOption = () => {
  return httpClient.get(`IndustryManage/sasb/option`);
}

/** 查詢 table-info-type=附表 */
const getTableInfoType = () => {
  return httpClient.get(`Option/enumType/?type=table-info-type`);
}

/** 轉移公司市場別 */
const changeIndustry = (companyId: string | undefined, data: ChangeIndustryModel) => {
  return httpClient.post(`CompanyManage/change/${companyId}`, data);
}
/** 取得目前的市場別 */
const getMarketType = (industryYearId: string) => {
  return httpClient.get(`CompanyManage/MarketType?id=${industryYearId}`);
}
/**公司選單API */
const getCompanyOption = (request: IndustryManageRequestModel) => {
  return httpClient.post(`CompanyManage/company/option`, request);
}
/**欲轉換市場別選單API */
const getMarketOption = (request: IndustryManageRequestModel) => {
  return httpClient.post(`CompanyManage/marketType/option`, request);
}
/**欲轉換產業別選單API */
const getChangeIndustryOption = (request: IndustryManageRequestModel) => {
  return httpClient.post(`CompanyManage/industry/option`, request);
}

// #endregion 公司管理

const MarketService = {
    marketList,
    addMarket,
    copyMarket,
    removeMarket,
    saveMarket,
    industryList,
    addIndustry,
    saveIndustry,
    removeIndustry,
    loadImportXls,
    importIndustry,
    getCompanyList,
    addCompany,
    editCompany,
    batchDeleteCompanies,
    getCompanyImportExcelExample,
    batchAddCompanies,
    getIndustryOption,
    getSasbOption,
    getTableInfoType,
    getCompanyDetail,
    changeIndustry,
    getMarketType,
    getCompanyOption,
    getMarketOption,
    getChangeIndustryOption
}

export default MarketService;
