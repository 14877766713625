import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./SustainReportEnInfo.module.scss";
import { StreamFileType, PageActionTyep } from "models/baseModel";
import NormalInput from "components/base/NormalInput/NormalInput";
import IconButton from "components/button/IconButton/IconButton";
import BatchUploadModal, {
  BatchUploadModalProps,
} from "components/base/BatchUploadModal/BatchUploadModal";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import {
  DataValUploadFileModel,
  Multilingual,
  RevisedVersionReportModel,
  SustainReportDeclareDataShowModel,
  SustainReportExportExcelModel,
  UploadFileStatus,
} from "models/application/sustainReportDeclare/sustainReportDeclareModel";
import { useMutation } from "@tanstack/react-query";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import { error2Alert, success2Alert, successAlert } from "utils/otherToast";
import {
  DeclareDataAction,
  DeclareStatus,
} from "models/application/indicators/indicatorModel";
import { Link, useParams } from "react-router-dom";
import UpdateApplyModal, {
  UpdateApplyModalProps,
} from "components/otherModule/UpdateApplyModal/UpdateApplyModal";
import {
  CorrectionRequestModel,
  RequestType,
} from "models/admin/declareDataMangeModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { CommonService } from "services/common/commonService";
import { ResponseCode } from "models/responseCodeModel";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";

interface SustainReportEnInfoProps {
  infoData?: SustainReportDeclareDataShowModel;
  action: string;
  setAction: React.Dispatch<React.SetStateAction<string>>;
  saveFinished: () => void;
  loading?: boolean;
}

const SustainReportEnInfo: FC<SustainReportEnInfoProps> = (props) => {
  const params = useParams();
  // 父層編輯狀態
  const { action, setAction } = props;
  const [data, setData] = useState<
    SustainReportDeclareDataShowModel | undefined
  >();
  const orgInfoData = useRef<SustainReportDeclareDataShowModel>();
  const saveInfoData = useRef<SustainReportDeclareDataShowModel>();
  const [fileData, setFileData] = useState<any[]>([]);
  // 提醒訊息
  const [noticeMsg, setNoticeMsg] = useState<string>("");
  // 是否可以按申報按鈕
  const [applyBtnDisabled, setApplyBtnDisabled] = useState(true);

  useEffect(() => {
    if (props.infoData) {
      orgInfoData.current = { ...props.infoData };
      orgInfoData.current.uploadInfoList =
        orgInfoData.current.uploadInfoList?.filter(
          (p) => p.multilingual === Multilingual.English
        ) || [];
      setData({ ...orgInfoData.current });
      checkCanApply(orgInfoData.current);
      saveInfoData.current = {
        ...orgInfoData.current,
      };
    }
  }, [props.infoData]);

  //#region  題目
  const questions = [
    "一、英文版永續報告書檔案置於公司網站之連結(請輸入網址)",
    "二、英文首版上傳檔案",
  ];
  //#endregion

  //#region 資料確認儲存
  const [dataConfirmModal, setDataConfirmModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setDataConfirmModal({
        ...dataConfirmModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "資料確認",
  });
  //#endregion

  //#region 上傳資料
  const [uploadModal, setUploadModal] = useState<BatchUploadModalProps>({
    show: false,
    handleClose: () => {
      setUploadModal({
        ...uploadModal,
        show: false,
      });
    },
    title: "報告書上傳",
    accept: ".pdf",
    limitFileSize: 50,
    handleUpload: (e) => {},
    sampleDownloadUrl: "",
    uploadLoading: false,
  });

  const uploadFile = (files: any[]) => {
    const items: DataValUploadFileModel[] = [];
    for (const file of files) {
      items.push({
        multilingual: Multilingual.English,
        fileId: null,
        fileName: file.name,
        uploadFileStatus: UploadFileStatus.FirstUpload,
      });
    }
    setFileData([...files]);
    if (saveInfoData.current) {
      saveInfoData.current.uploadInfoList = [...items];
    }
    setData({
      ...data!,
      uploadInfoList: [...items],
    });
    setUploadModal({
      ...uploadModal,
      show: false,
    });
  };
  //#endregion

  //#region 更正申報
  const [updateModal, setUpdateModal] = useState<UpdateApplyModalProps>({
    show: false,
    handleClose: () => {
      setUpdateModal({
        ...updateModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    requestType: RequestType.SustainReport,
  });

  const updateApplyMutation = useMutation({
    mutationFn:
      SustainReportDeclareService.addSustainReportDeclareDatacCrrectionRequest,
    onSuccess: (res) => {
      setUpdateModal({
        ...updateModal,
        loading: false,
        show: false,
      });
      if (res.success && res.code === ResponseCode.SuccessOK) {
        props.saveFinished();
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const handleUpdateModal = (item: CorrectionRequestModel) => {
    setUpdateModal({
      ...updateModal,
      loading: true,
    });
    updateApplyMutation.mutate({
      ...item,
      declareId: params.id!,
      multilingual: Multilingual.English,
    });
  };
  //#endregion

  //#region 確認申報
  // modal 物件(confirm)
  const [applyModal, setApplyModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setApplyModal({
        ...applyModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "確認申報",
  });
  //#endregion

  //#region 編輯報告書
  const editSustainReportDeclareData = useMutation({
    mutationFn: (param: any) =>
      SustainReportDeclareService.editSustainReportDeclareData(param),
    onSuccess: (res) => {
      setApplyModal({
        ...applyModal,
        show: false,
        loading: false,
      });
      setNoticeConfirmModal({
        ...noticeConfirmModal,
        show: false,
        loading: false,
      });
      if (res.success) {
        props.saveFinished();
        success2Alert(res.message);
        setAction(PageActionTyep.View);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
      setNoticeConfirmModal({
        ...noticeConfirmModal,
        show: false,
        loading: false,
      });
    },
  });

  // 儲存
  const save = (actinType: DeclareDataAction) => {
    const formData = new FormData();
    const dataItem = {
      ...saveInfoData.current,
      action: actinType,
      multilingual: Multilingual.English,
      enIsFinished: checkCanApply(data!),
    };
    formData.append("data", JSON.stringify(dataItem));

    if (fileData.length > 0) {
      for (const file of fileData) {
        formData.append("file", file);
      }
    } else {
      formData.append("file", JSON.stringify([]));
    }
    // 確認填寫完必
    if (dataItem.enIsFinished) {
      setApplyModal({
        ...applyModal,
        loading: true,
      });
      editSustainReportDeclareData.mutate(formData);
    } else {
      setNoticeConfirmModal({
        ...noticeConfirmModal,
        handleConfirm: () => {
          setNoticeConfirmModal({
            ...noticeConfirmModal,
            loading: true,
          });
          setApplyModal({
            ...applyModal,
            loading: true,
          });
          editSustainReportDeclareData.mutate(formData);
        },
        show: true,
      });
    }
  };
  //#endregion

  //#region 上傳報告書(修正版)
  const editRevisedVersionReport = useMutation({
    mutationFn: (param: any) =>
      SustainReportDeclareService.editRevisedVersionReport(param),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        success2Alert(res.message);
        props.saveFinished();
      } else {
        error2Alert(res.message);
      }
      setUploadModal({        
        ...uploadModal,
        uploadLoading: false,
        show: false,
      });
    },
    onError: (err) => {
      console.log(err);
      setUploadModal({        
        ...uploadModal,
        uploadLoading: false,
        show: false,
      });
    },
  });

  const uploadRevisedFile = (files: any[]) => {
    const item: RevisedVersionReportModel = {
      declareId: params.id!,
      multilingual: Multilingual.English,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(item));
    if (files.length > 0) {
      for (const file of files) {
        formData.append("file", file);
      }
    } else {
      formData.append("file", JSON.stringify([]));
    }

    setUploadModal({
      ...uploadModal,
      uploadLoading: true,
    });
    editRevisedVersionReport.mutate(formData);
  };
  //#endregion

  //#region 下載檔案
  const getSustainReportDeclareDataFileStream = useMutation({
    mutationFn: (id: string) =>
      SustainReportDeclareService.getSustainReportDeclareDataFileStream(id),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        successAlert("下載成功");
        CommonService.downloadByStream(res, StreamFileType.Pdf);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 下載檔案 */
  const downloadFile = (file: DataValUploadFileModel) => {
    if (file) {
      getSustainReportDeclareDataFileStream.mutate(file.fileId!);
    }
  };

  /** 取得永續報告書匯出excel */
  const getExportExcel = useMutation({
    mutationFn: (model: SustainReportExportExcelModel) =>
      SustainReportDeclareService.getExportExcel(model),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        successAlert("下載成功");
        CommonService.downloadByStream(res, StreamFileType.Xlsx);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  //#endregion

  //#region  確認是否申報
  const checkCanApply = (data: SustainReportDeclareDataShowModel): boolean => {
    let pass = true;
    let msg = "";
    const fileExist = data.uploadInfoList?.some(
      (p) => p.multilingual === Multilingual.English
    );
    if (!data.companyENReportUrl) {
      msg += `${questions[0]}\n`;
      pass = false;
    }
    if (!fileExist) {
      msg += `${questions[1]}\n`;
      pass = false;
    }
    setApplyBtnDisabled(!pass);
    if (!pass) {
      setNoticeMsg(msg);
    }
    return pass;
  };
  //#endregion

  //#region 必填modal 提醒
  const [noticeConfirmModal, setNoticeConfirmModal] =
    useState<ConfirmModalProps>({
      show: false,
      handleClose: () => {
        setNoticeConfirmModal({
          ...noticeConfirmModal,
          show: false,
        });
      },
      handleConfirm: () => {},
      title: "尚未填寫的必要欄位",
    });
  //#endregion

  const openUrl = (url: string | null | undefined) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.click();
    }
  };

  const setControlVal = (type: string, value: any) => {
    // 用ref存資料，實際回寫用ref的資料
    if (saveInfoData.current) {
      const reportSaveInfo = saveInfoData.current;
      if (type === "companyENReportUrl") {
        reportSaveInfo.companyENReportUrl = value;
      }
      setData({
        ...reportSaveInfo,
      });
    }
  };
  return (
    <div className={styles["sustain-report-eninfo-box"]}>
      {(props.loading ||
        getSustainReportDeclareDataFileStream.isPending ||
        editSustainReportDeclareData.isPending||
        getExportExcel.isPending ||
        editRevisedVersionReport.isPending) && <VisuallLoading />}
      <div className="content-title-box">
        <div className="main-title">報告書申報作業</div>
        <div className="content-tool">
          <LinkIconButton
            imgName={"download-icon.svg"}
            text={"匯出"}
            className={"min-w80-p"}
            onClick={() => {
              getExportExcel.mutate({
                declareId: params.id!,
                multilingual: Multilingual.English,
              });
            }}
          />
          {data?.enStatus !== DeclareStatus.UnDeclared &&
            data?.enFirstDeclareDate&& (
              <IconButton
                className="secondary mr-1-m"
                imgName={"batch-upload-icon.svg"}
                text={"上傳報告書(修正版)"}
                onClick={() => {
                  setUploadModal({
                    ...uploadModal,
                    title: "上傳報告書(修正版)",
                    handleUpload: (e) => uploadRevisedFile(e),
                    show: true,
                  });
                }}
              />
            )}
          {(data?.enStatus === DeclareStatus.Declared ||
            data?.enStatus === DeclareStatus.DeclareEnd) && (
            <button
              className="default mr-1-m"
              onClick={() => {
                setUpdateModal({
                  ...updateModal,
                  declareStatus: data.enStatus,
                  show: true,
                });
              }}
            >
              {data?.enStatus === DeclareStatus.DeclareEnd
                ? "申請開放"
                : "更正申請"}
            </button>
          )}

          {(data?.enStatus === DeclareStatus.Declaring ||
            data?.enStatus === DeclareStatus.Correcting) && (
            <button
              className={
                `secondary mr-1-m ` + `${applyBtnDisabled ? "disabled" : ""}`
              }
              onClick={() => {
                setApplyModal({
                  ...applyModal,
                  show: true,
                });
              }}
            >
              確認申報
            </button>
          )}
          {(data?.enStatus === DeclareStatus.UnDeclared ||
            data?.enStatus === DeclareStatus.Declaring ||
            data?.enStatus === DeclareStatus.Correcting) && (
            <>
              {action === PageActionTyep.View ? (
                <button
                  className="secondary mr-1-m"
                  onClick={() => {
                    setAction(PageActionTyep.Edit);
                  }}
                >
                  編輯
                </button>
              ) : (
                <>
                  <button
                    className="secondary mr-1-m"
                    onClick={() => {
                      setAction(PageActionTyep.View);
                      saveInfoData.current = CommonService.deepClone(
                        orgInfoData.current!
                      );
                      setData({ ...orgInfoData.current! });
                    }}
                  >
                    取消
                  </button>
                  <button
                    className="default"
                    onClick={() => {
                      save(DeclareDataAction.Saved);
                    }}
                  >
                    儲存
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className={"desc"}>
        <span className="star">*</span>為必填欄位
      </div>
      <div className="form-box">
        <div className="question-box">
          <div className="question">
            {questions[0]}
            <span className="star">*</span>
          </div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <NormalInput
                defaultValue={data?.companyENReportUrl}
                onChange={(str: string) => {
                  setControlVal("companyENReportUrl", str);
                }}
              />
            ) : (
              <div
                className="file-url"
                onClick={() => openUrl(data?.companyENReportUrl)}
              >
                {data?.companyENReportUrl}
              </div>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div className="question-box">
          <div className="question">
            {questions[1]}
            <span className="star">*</span>
          </div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <div>
                {
                  !data?.enFirstDeclareDate  &&(
                    <IconButton
                    imgName={"upload-btn-icon.svg"}
                    text={"上傳檔案"}
                    onClick={() => {
                      setUploadModal({
                        ...uploadModal,
                        handleUpload: (e) => uploadFile(e),
                        show: true,
                      });
                    }}
                  />
                  )
                }
                {data?.uploadInfoList?.map((item, index: number) => {
                  return (
                    <div key={index} className="upload-file-name">
                      {item.fileName}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="file-word">
                {data?.uploadInfoList?.map((item) => {
                  return (
                    item.uploadFileStatus === UploadFileStatus.FirstUpload && (
                      <div
                        key={item.fileId}
                        className="file-item"
                        onClick={() => downloadFile(item)}
                      >
                        {item.fileName}
                      </div>
                    )
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {/* 如果有修正編輯檔案才有這個內容 */}
        {action === PageActionTyep.View &&
          data?.uploadInfoList?.some(
            (p) => p.uploadFileStatus === UploadFileStatus.EditUpload
          ) && (
            <>
              <div className="line"></div>
              <div className="question-box">
                <div className="question">二-1、英文修正版上傳檔案</div>
                <div className="control">
                  <div className="file-word">
                    {data?.uploadInfoList?.map((item) => {
                      return (
                        item.uploadFileStatus ===
                          UploadFileStatus.EditUpload && (
                          <div
                            key={item.fileId}
                            className="file-item"
                            onClick={() => downloadFile(item)}
                          >
                            {item.fileName}
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="line"></div>
            </>
          )}
      </div>
      {/* 批次匯入彈窗 */}
      <BatchUploadModal
        {...uploadModal}
        noteChildren={
          <div>
            1.永續報告書應以原始文字檔案進行PDF轉檔後上傳，即勿直接掃描紙本產製PDF檔案，以利投資人搜尋電子書內容
          </div>
        }
      />

      {/* 資料尚未儲存modal */}
      <ConfirmModal {...dataConfirmModal}>
        <div>資料尚未儲存，確定要離開嗎?</div>
      </ConfirmModal>

      {/* 申報資料更正申請 */}
      <UpdateApplyModal
        {...updateModal}
        handleConfirm={(e) => {
          handleUpdateModal(e);
        }}
      />
      {/* 確認申報modal */}
      <ConfirmModal
        {...applyModal}
        handleConfirm={() => {
          save(DeclareDataAction.Declared);
        }}
      >
        <div>
          確認後不得再編輯資料，若申報完成後需修改，請至
          <span style={{ fontWeight: 700 }}>申報資料更正申請。</span>
        </div>
      </ConfirmModal>
      {/* 以下為未填寫必填 資料 */}
      <ConfirmModal {...noticeConfirmModal}>
        <div className="notice-msg-box">{noticeMsg}</div>
      </ConfirmModal>
    </div>
  );
};

export default SustainReportEnInfo;
