import React, { FC, useContext, useEffect } from "react";
import {
  Navigate,
  useLocation,
} from "react-router-dom";
import useUserInfoStore from "../state/useUserInfoStore";
import { CommonService } from "services/common/commonService";

type ApplyProtectedRouteProps = {
  children?: React.ReactNode;
  /** 導向頁面 */
  redirectPath?: string;
  /** 是否有該權限 */
  isAllowed?: boolean;
};

const ApplyProtectedRoute: FC<ApplyProtectedRouteProps> = ({
  children,
  redirectPath = "",
  isAllowed = true,
}) => {
  const { userInfo } = useUserInfoStore();
  const router = useLocation();

  if (router) {
    const arr = router.pathname.split("/");
    const index = arr.findIndex((p) => p === "apply");
    if (arr.length > index) {
      const moduleKey = arr[index + 1];
      const permissions = CommonService.getApplyMarketPermissions(
        userInfo.marketType
      );
      if (!permissions.includes(moduleKey)) {
        return <Navigate to={"/apply/home"} replace />;
      }
    }
  }
  // 權限不足
  if (userInfo.accessToken && !isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  // 暫時都倒回自己的頁面
  return (
    <>{children}</>
  );
};

export default ApplyProtectedRoute;
