import {
  MopsSingleCompanyDataRequestModel,
  MopsSummaryDataRequestModel,
} from "models/inquiry/infoDisclosure/mopsEsgModel";
import {
  SustainReportResultModel,
  SustainReportRequestModel,
} from "models/inquiry/sustainReportModel";
import {
  GhgReductionInfoResultModel,
  IndividualResultModel,
  SummaryResultModel,
} from "models/localSearchResultModel";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

export interface SearchParamState {
  //#region get相關搜尋
  // 個別公司
  individualParam?: MopsSingleCompanyDataRequestModel | null;
  individualResult?: IndividualResultModel | null;
  // 彙總公司
  summaryParam?: MopsSummaryDataRequestModel | null;
  summaryResult?: SummaryResultModel | null;
  // 溫室氣體盤查搜尋
  ghgReductionInfoParam?: MopsSummaryDataRequestModel | null;
  ghgReductionInfoResult?: GhgReductionInfoResultModel | null;

  // 永續報告書
  sustainReportParam?: SustainReportRequestModel | null;
  sustainReportResult?: SustainReportResultModel | null;
  //#endregion

  //#region set相關
  setIndividualParam: (info: MopsSingleCompanyDataRequestModel) => void;
  setIndividualResult: (info: IndividualResultModel) => void;
  clearIndividualResult: () => void;
  setSummaryParam: (info: MopsSummaryDataRequestModel) => void;
  setSummaryResult: (info: SummaryResultModel) => void;
  clearSummaryResult: () => void;
  clearSummaryParam: () => void;
  setGhgReductionInfoParam: (info: MopsSummaryDataRequestModel) => void;
  setGhgReductionInfoResult: (info: GhgReductionInfoResultModel) => void;
  clearGhgReductionInfoResult: () => void;
  setSustainReportParam: (info: SustainReportRequestModel) => void;
  setSustainReportResult: (info: SustainReportResultModel) => void;
  clearSustainReportResult: () => void;
  //#endregion
}

/** store資訊 */
const useSearchParamStore = create<SearchParamState>()(
  persist(
    devtools((set) => ({
      //#region 個別公司
      // 個別公司
      setIndividualParam: (param: MopsSingleCompanyDataRequestModel) =>
        set((state) => ({
          ...state,
          individualParam: param,
        })),
      // 個別公司結果
      setIndividualResult: (param: IndividualResultModel) =>
        set((state) => ({
          ...state,
          individualResult: { ...state.individualResult, ...param },
        })),
      // 清除個別公司結果
      clearIndividualResult: () =>
        set((state) => ({
          ...state,
          individualResult: null,
        })),
      //#endregion

      //#region 彙總公司
      // 彙總公司
      setSummaryParam: (param: MopsSummaryDataRequestModel) =>
        set((state) => ({
          ...state,
          summaryParam: param,
        })),
      // 清除彙總公司 查詢
      clearSummaryParam: () =>
        set((state) => ({
          ...state,
          summaryParam: null,
        })),
      // 彙總公司 結果
      setSummaryResult: (param: SummaryResultModel) =>
        set((state) => ({
          ...state,
          summaryResult: { ...state.summaryResult, ...param },
        })),
      // 清除彙總公司 結果
      clearSummaryResult: () =>
        set((state) => ({
          ...state,
          summaryResult: null,
        })),
      //#endregion

      //#region 溫室氣體盤查搜尋
      // 溫室氣體盤查搜尋
      setGhgReductionInfoParam: (param: MopsSummaryDataRequestModel) =>
        set((state) => ({
          ...state,
          ghgReductionInfoParam: param,
        })),
      // 溫室氣體盤查結果
      setGhgReductionInfoResult: (param: GhgReductionInfoResultModel) =>
        set((state) => ({
          ...state,
          ghgReductionInfoResult: { ...state.ghgReductionInfoResult, ...param },
        })),
      // 清除溫室氣體盤查結果
      clearGhgReductionInfoResult: () =>
        set((state) => ({
          ...state,
          ghgReductionInfoResult: null,
        })),
      //#endregion

      //#region 永續報告書
      // 永續報告書搜尋
      setSustainReportParam: (param: SustainReportRequestModel) =>
        set((state) => ({
          ...state,
          sustainReportParam: param,
        })),
      // 永續報告書結果
      setSustainReportResult: (param: SustainReportResultModel) =>
        set((state) => ({
          ...state,
          sustainReportResult: { ...state.sustainReportResult, ...param },
        })),
      // 清除永續報告書結果
      clearSustainReportResult: () =>
        set((state) => ({
          ...state,
          sustainReportResult: null,
        })),
      //#endregion
    })),
    {
      name: "twse-search-param",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useSearchParamStore;
