import { CorrectionRequestModel } from "models/admin/declareDataMangeModel";
import { ListRequestModel } from "models/baseModel";
import { HttpClient } from "services/httpClient";
import { GhgDeclareActionModel, GhgDeclareDataModel } from "models/application/ghgReductionDeclare/ghgDeclareModel";

/** 定義的query keys */
export const GhgDeclareKeys = {
    infoList: 'infoList'
};

const httpClient = new HttpClient();

/** 透過subId取得溫室氣體排放及減量資訊申報作業清單 */
const getGhgDeclareList = (model:ListRequestModel) => {
    return httpClient.post(`ghgDeclare/list`, model);
}

/** 透過declareId取得溫室氣體排放及減量資訊申報作業資訊 */
const getGhgDeclareData = (declareId: string) => {
    return httpClient.get(`ghgDeclare/data?declareId=${declareId}`);
}

/** 新增/編輯溫室氣體排放及減量資訊申報作業資訊 */
const editGhgDeclareData = (data: GhgDeclareDataModel) => {
    return httpClient.post(`ghgDeclare/data`,data);
}

/** 溫室氣體排放及減量資訊申報作業-更正申請 */
const addGhgDeclareDatacCrrectionRequest = (data: CorrectionRequestModel) => {
    return httpClient.post(`ghgDeclare/data/correction-request`,data);
}

/** 變更溫室氣體排放及減量申報狀態 */
const editGhgDeclareStatus = (data: GhgDeclareActionModel) => {
    return httpClient.post(`ghgDeclare/status`,data);
}

/** 取得溫室氣體排放及減量資訊檔案 */
const getExportExcel = (declareId: string) => {
    return httpClient.getBlob(`ghgDeclare/export/excel?declareId=${declareId}`);
}

const GhgDeclareService = {
    getGhgDeclareList,
    getGhgDeclareData,
    editGhgDeclareData,
    addGhgDeclareDatacCrrectionRequest,
    editGhgDeclareStatus,
    getExportExcel
}

export default GhgDeclareService;