import React, { FC, useEffect, useState } from "react";
import styles from "./PropagandaFileInfo.module.scss";
import BackTitle from "components/base/BackTitle/BackTitle";
import { useNavigate, useParams } from "react-router-dom";
import { EduPropagandaManageActionsPermissionCodes } from "models/auth/permissionModel";
import { usePermissionContext } from "context/PermissionProvider";
import { useMutation } from "@tanstack/react-query";
import EduPropagandaManageService from "services/admin/eduPropagandaManageService";
import { error2Alert, success2Alert, successAlert } from "utils/otherToast";
import {
  AddEduResourceFileModel,
  EditEduResourceFileModel,
  EduMarketHedareModel,
  EduReadPermissionType,
  EduSystemPermissionMarketModel,
} from "models/admin/eduPropagandaManageModel";
import { ResponseCode } from "models/responseCodeModel";
import Dropdown from "components/base/Dropdown/Dropdown";
import { MarketTypeEnum, OptionModel } from "models/baseModel";
import BatchUploadModal, {
  BatchUploadModalProps,
} from "components/base/BatchUploadModal/BatchUploadModal";
import IconButton from "components/button/IconButton/IconButton";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { CommonService } from "services/common/commonService";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { Guid } from "guid-typescript";

interface PropagandaFileInfoProps {}

const PropagandaFileInfo: FC<PropagandaFileInfoProps> = () => {
  //#region 參數相關
  const params = useParams();
  const fileId = params.fileId;
  // usePermissionContext
  const { PermissionService } = usePermissionContext();
  // PermissionService
  const { detectEveryActionPermission } = PermissionService;
  // 是否有選擇類型id
  const [validDirectoryId, setValidDirectoryId] = useState<boolean>(true);
  const [fileTypeOption, setFileTypeOption] = useState<OptionModel[]>([]);
  // 系統項目類型(前端用)
  const [systemOption, setSystemOption] = useState<
    EduSystemPermissionMarketModel[]
  >([]);
  // 系統項目類型(原始)
  const [orgSystemOption, setOrgSystemOption] = useState<OptionModel[]>([]);
  // 檔案資訊(新增/編輯)
  const [fileData, setFileData] = useState<EditEduResourceFileModel>({
    eduResourceDirectoryFileMapId: "",
    directoryId: "",
    directoryName: "",
    uploadFileLogId: "",
    fileName: "",
    marketFileReadDatas: null,
  });
  const navigate = useNavigate();
  // 上傳的檔案
  const [tmpfile, setTmpFile] = useState<any[]>([]);

  // 設置檔案上傳
  const [uploadModal, setUploadModal] = useState<BatchUploadModalProps>({
    show: false,
    handleClose: () => {
      setUploadModal({
        ...uploadModal,
        show: false,
      });
    },
    accept: ".pdf",
    handleUpload: (e) => {},
    title: "上傳檔案",
    uploadLoading: false,
    limitFileSize: 30,
    isMultiple: true,
  });

  // 市場header 資料
  const [marketHeader, setMarketHeader] = useState<EduMarketHedareModel[]>([
    {
      name: "上市",
      key: MarketTypeEnum.Listing,
      checked: true,
    },
    {
      name: "上櫃",
      key: MarketTypeEnum.OTC,
      checked: true,
    },
    {
      name: "公發",
      key: MarketTypeEnum.PO,
      checked: true,
    },
    {
      name: "興櫃",
      key: MarketTypeEnum.Emerging,
      checked: true,
    },
  ]);
  //#endregion

  //#region  useEffect 初始資料

  useEffect(() => {
    // 取得清單
    getEduResourceDirectoryOption.mutate();
    getSystemManageOption.mutate();
  }, []);

  //#endregion

  //#region API 相關
  /** 儲存 */
  const save = () => {
    if (!validData()) {
      return;
    }

    //  新調整資料
    const data = getSaveData();
    if (fileId === "-1") {
      const postData = new FormData();
      postData.append("data", JSON.stringify(data));

      // 檔案新增
      if (tmpfile.length > 0) {
        for (const file of tmpfile) {
          postData.append("files", file);
        }
      }

      // 新增
      addEduResourceFile.mutate(postData);
    } else {
      // 編輯
      editEduResourceFile.mutate({
        ...fileData,
        ...data,
        // 這邊是實際fileId
        fileId: fileId
      });
    }
  };

  /** 取得回寫資料 */
  const getSaveData = (): AddEduResourceFileModel => {
    let data: AddEduResourceFileModel = {
      directoryId: fileData.directoryId,
      marketFileReadDatas: [],
    };
    // header
    marketHeader.forEach((p) => {
      if (p.checked) {
        data.marketFileReadDatas?.push({
          type: EduReadPermissionType.MarKet,
          marketType: p.key,
          // header 不需要這個
          systemManageId: null,
        });
      }
    });
    // body
    systemOption.forEach((system) => {
      system.markets.forEach((market) => {
        if (market.checked) {
          data.marketFileReadDatas?.push({
            type: EduReadPermissionType.SystemManage,
            marketType: market.key,
            systemManageId: system.id,
          });
        }
      });
    });
    return data;
  };

  /** validData */
  const validData = () => {
    let msg = "";
    let pass = true;
    setValidDirectoryId(true);
    if (!fileData.directoryId) {
      msg += "請選擇檔案類型\n";
      pass = false;
      setValidDirectoryId(false);
    }

    if (fileId === "-1" && tmpfile.length === 0) {
      msg += "請上傳檔案";
    }

    if (!pass) {
      error2Alert(msg);
    }
    return pass;
  };

  /** 編輯檔案 */
  const editEduResourceFile = useMutation({
    mutationFn: (model: EditEduResourceFileModel) =>
      EduPropagandaManageService.editEduResourceFile(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        success2Alert(res.message);
        getEduResourceFile.mutate(fileId!);
      } else {
        console.log(res);

        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 新增檔案 */
  const addEduResourceFile = useMutation({
    mutationFn: (model: any) =>
      EduPropagandaManageService.addEduResourceFile(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        success2Alert(res.message);
        // 回到清單
        navigate("/admin/edu-propaganda/file");
      } else {
        console.log(res);

        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 檔案類型選單 */
  const getEduResourceDirectoryOption = useMutation({
    mutationFn: () =>
      EduPropagandaManageService.getEduResourceDirectoryOption(),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        setFileTypeOption(res.data);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 系統功能按鈕選單 */
  const getSystemManageOption = useMutation({
    mutationFn: () => EduPropagandaManageService.getSystemManageOption(),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        setOrgSystemOption(CommonService.deepClone(res.data));
        // 如果是編輯
        if (fileId !== "-1") {
          getEduResourceFile.mutate(fileId!);
        } else {
          initSystemOption(res.data);
        }
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 下載檔案 */
  const getEduPropagandaResourceFileStream = useMutation({
    mutationFn: (fileId: string) =>
      EduPropagandaManageService.getEduPropagandaResourceFileStream(fileId),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        successAlert("下載成功");
        CommonService.downloadByStream(res);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /**  取得單一檔案資料(編輯頁面使用) */

  const getEduResourceFile = useMutation({
    mutationFn: (fileId: string) =>
      EduPropagandaManageService.getEduResourceFile(fileId),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        initEditInfoData(res.data);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  //#endregion

  //#region 處理邏輯

  /** 初始編輯資訊資料 */
  const initEditInfoData = (model: EditEduResourceFileModel) => {
    setFileData(model);
    // 取出市場別
    let headerOp =
      model.marketFileReadDatas?.filter(
        (p) => p.type === EduReadPermissionType.MarKet
      ) || [];
    // 設置header 勾選
    setMarketHeader(
      marketHeader.map((p) => {
        const exist = headerOp.some((item) => item.marketType === p.key);
        p.checked = exist;
        return p;
      })
    );

    // 設置body 勾選
    let bodyOp =
      model.marketFileReadDatas?.filter(
        (p) => p.type === EduReadPermissionType.SystemManage
      ) || [];
    const sysOps: EduSystemPermissionMarketModel[] = [];
    orgSystemOption.forEach((op) => {
      const newMarket = CommonService.deepClone(marketHeader);
      newMarket.forEach((market) => {
        // 跟編輯資料確認有無勾選
        market.checked = bodyOp.some(
          (item) =>
            item.marketType === market.key && item.systemManageId === op.id
        );
      });
      let item: EduSystemPermissionMarketModel = {
        markets: newMarket,
        id: op.id,
        text: op.text,
      };

      sysOps.push(item);
    });
    setSystemOption([...sysOps]);
  };

  /** 初始化系統選單 */
  const initSystemOption = (orgOption: OptionModel[]) => {
    const sysOps: EduSystemPermissionMarketModel[] = [];
    if (orgOption) {
      orgOption.forEach((op) => {
        const newMarket = CommonService.deepClone(marketHeader);
        // 預設是false
        newMarket.forEach((market) => {
          market.checked = false;
        });
        let item: EduSystemPermissionMarketModel = {
          markets: newMarket,
          id: op.id,
          text: op.text,
        };

        sysOps.push(item);
      });
      setSystemOption([...sysOps]);
    }
  };

  // 檔案類型
  const handleFileType = (id: string) => {
    setFileData({
      ...fileData,
      directoryId: id,
    });
  };

  // 上傳檔案
  const uploadFile = (files: any[]) => {
    // 加入id 讓前端可以做刪除使用
    for (const file of files) {
      file["mainId"] = Guid.create().toString();
    }
    setTmpFile([...files]);

    setUploadModal({
      ...uploadModal,
      show: false,
    });
  };

  // 前端移除檔案
  const handleRemove = (item: any) => {
    setTmpFile(tmpfile.filter((p) => p.mainId !== item.mainId));
  };

  // 設定header check
  const handleMarketHeader = (item: EduMarketHedareModel) => {
    setMarketHeader(
      marketHeader.map((p) => {
        if (item.key === p.key) {
          p.checked = !p.checked;
        }
        return p;
      })
    );
  };

  /** 處理權限 */
  const handlePermission = (
    marketItem: EduMarketHedareModel,
    systemOp: OptionModel
  ) => {
    setSystemOption(
      systemOption.map((p) => {
        p.markets.map((market) => {
          if (p.id === systemOp.id && market.key === marketItem.key) {
            market.checked = !market.checked;
          }
          return market;
        });
        return p;
      })
    );
  };
  //#endregion

  return (
    <div className={styles["propaganda-file-info-box"]}>
      {(addEduResourceFile.isPending ||
        editEduResourceFile.isPending ||
        getEduPropagandaResourceFileStream.isPending ||
        getEduResourceFile.isPending) && <VisuallLoading></VisuallLoading>}
      {/*上方bar*/}
      <div className="title-box">
        <BackTitle title={"檔案設定"} url={`/admin/edu-propaganda/file`} />
        {(fileId === "-1" &&
          detectEveryActionPermission([
            EduPropagandaManageActionsPermissionCodes.EduPropagandaFileCreate,
          ])) ||
        (fileId !== "-1" &&
          detectEveryActionPermission([
            EduPropagandaManageActionsPermissionCodes.EduPropagandaFileUpdate,
          ])) ? (
          <button className="default saveBtn" onClick={() => save()}>
            儲存
          </button>
        ) : null}
      </div>
      {/* edit-box */}
      <div className="edit-box scroll">
        {/* 檔案類型 */}
        <div className="basic box">
          <div className="title">檔案類型：</div>
          <div className="basicItem">
            <Dropdown
              placeholder={"檔案類型*"}
              valid={validDirectoryId}
              defaultId={fileData.directoryId}
              disabled={fileId !== "-1"}
              options={fileTypeOption}
              isFloatTitle={false}
              onChange={(e) => handleFileType(e!)}
            />
          </div>
        </div>
        {/* 上傳檔案 */}
        {fileId === "-1" && (
          <div className="basic box">
            <div className="title">上傳檔案：</div>
            <div className="upload-box">
              <IconButton
                imgName={"upload-btn-icon.svg"}
                text={"上傳檔案"}
                onClick={() => {
                  setUploadModal({
                    ...uploadModal,
                    handleUpload: (e) => uploadFile(e),
                    show: true,
                  });
                }}
              />
              <div className="upload-file-box">
                {tmpfile.map((item, index: number) => {
                  return (
                    <div key={index} className="upload-file-item">
                      <div className="upload-file-name">{item.name}</div>

                      <LinkIconButton
                        imgName="trash-icon.svg"
                        text=""
                        className={"hover"}
                        onClick={() => handleRemove(item)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {/* 檔案名稱(可做下載) */}
        {fileId !== "-1" && fileData.uploadFileLogId && (
          <div className="basic box">
            <div className="title">檔案名稱：</div>
            <div
              className="basicItem"
              onClick={() => {
                getEduPropagandaResourceFileStream.mutate(
                  fileData.uploadFileLogId!
                );
              }}
            >
              <div className="download-file">{fileData.fileName}</div>
            </div>
          </div>
        )}

        {/* 權限設定 */}
        <div className="basic box">
          <div className="title">權限設定：</div>
          <div className="table-box scroll">
            <table
              aria-label="查詢結果table"
              className="table-container sticky-table"
            >
              <thead>
                <tr>
                  <th>項目</th>
                  {marketHeader.map((item: EduMarketHedareModel) => {
                    return (
                      <th scope="col" key={item.key}>
                        <div>
                          <label className="custom-checkbox">
                            <input
                              type="checkbox"
                              checked={item.checked}
                              onChange={() => {
                                handleMarketHeader(item);
                              }}
                            />
                            <span>{item.name}</span>
                          </label>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {systemOption.map(
                  (p: EduSystemPermissionMarketModel, index: number) => {
                    return (
                      <tr key={p.id} className="cur-pointer">
                        <td>{p.text}</td>
                        {p.markets.map((market) => {
                          return (
                            <td key={`${p.id}_${market.key}`}>
                              <label className="custom-checkbox">
                                <input
                                  type="checkbox"
                                  checked={market.checked}
                                  onChange={() => {
                                    handlePermission(market, p);
                                  }}
                                />
                                <span>&nbsp;</span>
                              </label>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* 批次匯入彈窗 */}
      <BatchUploadModal {...uploadModal} />
    </div>
  );
};

export default PropagandaFileInfo;
