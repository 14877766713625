import { CorrectionRequestModel } from "models/admin/declareDataMangeModel";
import { DeclareDataAction, DeclareStatus } from "../indicators/indicatorModel";

/**  中文版/英文版 (針對永續報告書申報) */
export interface SustainCorrectionRequestModel extends CorrectionRequestModel {
    multilingual: Multilingual;
}

export interface SustainReportDeclareShowModel {
    id: string;
    declareYear: number;
    /** 中文首次申報日期 */
    twFirstDeclareDate: Date | null;
    twLastEditDate: Date | null;
    twReportRevisionUploadTime: Date | null;
    twEditDeclareDate: Date | null;
    twStatusName: string;
    twStatus: DeclareStatus;

    /** 英文首次申報日期 */
    enFirstDeclareDate: Date | null;
    enLastEditDate: Date | null;
    enReportRevisionUploadTime: Date | null;
    enEditDeclareDate: Date | null;
    enStatus: DeclareStatus;
    enStatusName: string;

    canEdit: boolean;
    enIsFinished: boolean;
    twIsFinished: boolean;
}

export interface SustainReportDeclareDataOriginModel {
    /** 申報作業Id */
    declareId: string;
    /** 規範對象 */
    canonicalObject: CanonicalObject[] | null;
    /** 報告書完成日期 */
    completionDate: any | null;
    /** 報告書涵蓋期間(起始) */
    reportPeriodStart: any | null;
    /** 報告書涵蓋期間(終末) */
    reportPeriodFinal: any | null;
    /** 編制依循準則 */
    followRules: FollowRules | null;
    /** 依循標準說明(若FollowRules選擇Others須填寫) */
    followRulesExplain: string | null;
    /** 是否取得第三方保證 */
    isThirdPartyGuaranteed: boolean | null;
    /** 第三方保證查驗證單位 */
    thirdPartyVerificationAgency: string | null;
    /** 第三方保證標準 */
    thirdPartyStandard: ThirdPartyStandard | null;
    /** 第三方保證標準說明(若ThirdPartyStandard選擇Others須填寫) */
    thirdPartyStandardExplain: string | null;
    /** 是否取得會計師確信 */
    isAccountantConvinced: boolean | null;
    /** 會計師事務所 */
    accountingAssociationEnumList: AccountingAssociation[] | null;
    /** 會計師事務所 */
    accountingAssociation: string | null;
    /** 會計師確信標準 */
    accountantStandard: AccountantStandard | null;
    /** 會計師確信意見類型 */
    accountantOptionType: AccountantOptionType | null;
    /** 會計師確信意見類型說明(若AccountantOptionType選擇Others須填寫) */
    accountantOptionTypeExplain: string | null;
    /** 中文版報告書公司網站之連結 */
    companyTWReportUrl: string | null;
    /** 英文版報告書公司網站之連結 */
    companyENReportUrl: string | null;
    /** 中文首次申報日期 */
    twFirstDeclareDate: Date | null;
    /** 英文首次申報日期 */
    enFirstDeclareDate: Date | null;
    /**  */
    tWReportUploadId: string | null;
    /**  */
    eNReportUploadId: string | null;
    /** 永續報告書提報董事會情形 */
    boardOfDirectorsStatus: BoardOfDirectorsStatus | null;
    /** 提報日期(後端實際) */
    mentionedDate: any | null;
    /** 討論案(前端新增) */
    discussCaseDate: any | null;
    /** 報告案(前端新增) */
    reportCaseDate: any | null;
    /** 報告書聯絡資訊 */
    reportContactInformation: string | null;
    /** 備註 */
    comment: string | null;
    reportLimitSize: number;
}

export interface SustainReportDeclareDataShowModel extends SustainReportDeclareDataOriginModel {
    year: number;
    /** 中文版申報狀態 */
    twStatus: DeclareStatus;
    /** 英文版申報狀態 */
    enStatus: DeclareStatus;
    /** 中英文上傳檔案資訊列表 */
    uploadInfoList: DataValUploadFileModel[] | null;
    /** 是否為可編輯狀態 */
    canEdit: boolean;
    twIsFinished: boolean;
    enIsFinished: boolean;
}

/** 上傳檔案模組 */
export interface DataValUploadFileModel {
    /** 中文版/英文版 */
    multilingual: Multilingual;
    /** 檔案Id */
    fileId: string | null;
    /** 檔案名稱 */
    fileName: string | null;
    /** 上傳檔案類型 */
    uploadFileStatus: UploadFileStatus;
}

export interface SustainCorrectionRequestModel extends CorrectionRequestModel {
    /** 中文版/英文版 (針對永續報告書申報) */
    multilingual: Multilingual;
}

export interface RevisedVersionReportModel {
    /** 申報Id */
    declareId: string;
    multilingual: Multilingual;
}

export interface SustainReportDeclareDataModel extends SustainReportDeclareDataOriginModel {
    /** 中文版/英文版 */
    multilingual: Multilingual;
    /** 當前申報狀態 */
    status: DeclareStatus;
    /** 申報動作 */
    action: DeclareDataAction;
    /** 英文是否填報完畢 */
    enIsFinished: boolean;
    /** 中文是否填報完畢 */
    twIsFinished: boolean;
}


export enum Multilingual {
    /**  中文版*/
    Chinese,
    /**  英文版*/
    English
}

export enum BoardOfDirectorsStatus {
    /** 提討論案通過 */
    DiscussionPassed,
    /** 提報告案通過 */
    ReportPassed,
    /** 未提 */
    NotMentioned
}

export enum AccountantOptionType {
    /** 有限確信 */
    LimitedCertainty,
    /** 合理確信 */
    ReasonableCertainty,
    /** 其他 */
    Others
}

export enum AccountantStandard {
    /** 確信準則3000號(原公報第一號) */
    OriginFirst,
    /** ISAE 3000 */
    ISAE
}

export enum FollowRules {
    /** GRI準則 */
    GRI,
    /** 其他依循標準 */
    Others
}

export enum ThirdPartyStandard {
    /** AA1000 第1類型(Type1) 中度保證 */
    Type1ModerateGuarantee,
    /** AA1000 第1類型(Type1) 高度保證 */
    Type1HighGuarantee,
    /** AA1000 第2類型(Type2) 中度保證 */
    Type2ModerateGuarantee,
    /** AA1000 第2類型(Type2) 高度保證 */
    Type2HighGuarantee,
    /** 其他 */
    Others
}


export enum CanonicalObject {
    /** 屬食品工業者 */
    FoodIndustry,
    /** 屬餐飲收入占其全部營業收人之比率達百分之五十以上者 */
    CateringRevenueMoreThan50Percent,
    /** 屬化學工業者 */
    ChemicalIndustry,
    /** 屬金融保險業者 */
    FinancialAndInsurance,
    /** 資本額達新臺幣二十億元以上者 */
    CapitalMoreThan2Billion,
    /** 係自願編製與申報 */
    Voluntary,
}


/** 報告書套版Type */
export enum TemplateSetType {
    /** 原始報告書 */
    ExportReport,
    /** POC報告書套版(直式1) */
    VerticalSet1,
    /** POC報告書套版(直式2) */
    VerticalSet2,
    /** POC報告書套版(橫式1) */
    HorizontalSet1,
    /** POC報告書套版(橫式2) */
    HorizontalSet2
}


/** 匯出報告書template */
export interface ReportTemplateModel {
    id: string;
    leftText: string;
    rightText: string;
    previewImg: string;
    previewItems: number[];
    type: TemplateSetType;
}

export interface SustainReportExportModel {
    multilingual: Multilingual;
    produceId:string;
}

export interface SustainReportDeclareActionModel {
    declareId: string;
    action: DeclareDataAction;
    multilingual: Multilingual;
}

/** 區塊陣列資料model */
export interface BlockArrModel {
    /** 個別key */
    id: number;
    /** 是否disable */
    disabled: boolean;
    /** 文字 */
    text: string;
    /** 是否選取 */
    check: boolean;
}

/** 會計師事務所enum */
export interface AccountingAssociationModel {
    enumKey: number;
    enumText: string;
}

export enum UploadFileStatus {
    /** 首次上傳 */
    FirstUpload,
    /** 修正版 */
    EditUpload
}

/** 會計師事務所 */
export enum AccountingAssociation {
    /** 其他 */
    Others,
    /** KPMG 安侯建業聯合會計師事務所 */
    KPMG,
    /** 資誠聯合會計師事務所 */
    PwC,
    /** Deloitte 勤業眾信聯合會計師事務所 */
    Deloitte,
    /** 安永聯合會計師事務所 */
    EY,
    /** 國富浩華聯合會計師事務所 */
    Crowe,
    /** 正大聯合會計師事務所 Grant Thornton Taiwan */
    GrantThornton,
    /** 青山永續聯合會計師事務所 */
    GMCSR,
    /** 立本台灣聯合會計師事務所 BDO Taiwan */
    BDO,
    /** 宜群聯合會計師事務所 */
    YihChyun,
    /** 利安達平和聯合會計師事務所 Reanda Taiwan */
    Reanda,
    /** 秉承聯合會計師事務所 */
    LSA,
    /** 正風聯合會計師事務所 */
    Bakertilly,
    /** 大中國際聯合會計師事務所 */
    PKF,
    /** 蘊揚聯合會計師事務所 */
    Wewincpa,
    /** 恆生永續聯合會計師事務所 */
    Hangsengsd
}

/** 申報報告書匯出 */
export interface SustainReportExportExcelModel {
    declareId: string;
    /** 報告書版本 */
    multilingual: Multilingual;
}


