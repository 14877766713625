import React, { FC, useEffect, useState } from "react";
import styles from "./PaginationSelect.module.scss";
// import Pagination from "rc-pagination"; 套件目前移除
// import locale from 'rc-pagination/es/locale/zh_TW';
import { PaginationModel } from "models/baseModel";

interface PaginationSelectProps {
  /** 跳頁顯示*/
  showQuickJumper: boolean | object;
  /* 顯示紀錄筆數 */
  // dispalyRecordsSelect?: boolean | null;
  /** 資料總數 */
  total: number;
  defaultCurrent: number;
  /** 調整改變 */
  onChange: (item: PaginationModel) => void;
}

const PaginationSelect: FC<PaginationSelectProps> = (props) => {
  const [displayCountArray, setDisplayCountArray] = useState([
    { text: "顯示10筆資料", value: 10 },
    { text: "顯示25筆資料", value: 25 },
    { text: "顯示50筆資料", value: 50 },
    { text: "顯示100筆資料", value: 100 },
    { text: "顯示所有資料", value: props.total },
  ]);

  const [selPageSize, setSelPageSize] = useState<number>(10);

  const onChange = (current: number, pageSize: number) => {
    props.onChange({
      page: current,
      pageSize: pageSize,
    });
  };

  const handlePageSize = (item: any) => {
    const val = item.target.value;
    if (val) {
      const newPageSize = Number.parseInt(val);
      setSelPageSize(newPageSize)
    }
  };

  useEffect(() => {
    console.log("renew", selPageSize);
  }, [selPageSize]);
  return (
    <div className={styles["pagination-box"]}>
      <select
        className="pagesize-select"
        value={selPageSize}
        onChange={($event) => {
          handlePageSize($event);
        }}
      >
        {displayCountArray.map((p) => {
          return (
            <option key={p.text} value={p.value}>
              {p.text}
            </option>
          );
        })}
      </select>
      {/* <Pagination
        locale={locale}
        // selectComponentClass={
        //   <select></select>
        // }
        showTotal={(total, range) =>
          `${range[0]} ~ ${range[1]} 筆，共${total}筆`
        }
        showQuickJumper={props.showQuickJumper}
        showSizeChanger
        pageSize={selPageSize}
        defaultCurrent={props.defaultCurrent}
        onShowSizeChange={onChange}
        onChange={onChange}
        total={props.total}
      /> */}
    </div>
  );
};

export default PaginationSelect;
