import React, { FC, useRef, useState } from "react";
import styles from "./DevelopmentPage.module.scss";
import Header from "../../components/layouts/Header/Header";
import Sidebar from "../../components/layouts/SideBar/Sidebar";
import { SideBarItemModel } from "../../models/baseModel";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
import ToolComponent from "./ToolComponent/ToolComponent";
import ImageComponent from "./ImageComponent/ImageComponent";
import { useTranslation } from "react-i18next";

interface DevelopmentPageProps {}

const DevelpomentLayout: FC = () => {
  const {t} = useTranslation();
  const sidebarItems: SideBarItemModel[] = [
    {
      moduleName: "元件管理",
      url: "tools",
      icon: "sidebar-admin-declaration-information-icon.svg",
    },
    {
      moduleName: "圖片管理",
      url: "images",
      icon: "sidebar-admin-declaration-information-icon.svg",
    },
  ];
  return (
    <div className="root-box admin">
      {/* Narbar */}
      <div>
        <Header moduleName={t('WEBSITE_RESOURCE_MANAGEMENT')} />
      </div>
      <div className="layout-box scroll">
        <nav>
          <Sidebar items={sidebarItems} />
        </nav>
        {/* 子層替換 */}
        <div className="layout-container-box">
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

const DevelopmentPage: FC<DevelopmentPageProps> = () => {
  const routers: RouteObject[] = [
    {
      path: "",
      element: <DevelpomentLayout></DevelpomentLayout>,
      children: [
        {
          path: "",
          element: <Navigate to={"tools"} />,
        },
        {
          path: "tools",
          element: <ToolComponent />,
        },
        {
          path: "images",
          element: <ImageComponent />,
        },
      ],
    },
  ];
  let elements = useRoutes(routers);

  return (<>{elements}</>);
};

export default DevelopmentPage;
