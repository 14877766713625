import React, { FC } from 'react';
import styles from './ImageComponent.module.scss';

interface ImageComponentProps {}

const ImageComponent: FC<ImageComponentProps> = () => {
  
  //* Image
  const imagesFolderRootPath: string = "/assets/images/";
  const imagesFolderDataList: {
    path: string;
    requireContext: __WebpackModuleApi.RequireContext;
  }[] = [
    //- buttonIcon
    {
      path: "buttonIcon",
      requireContext: require.context(
        "../../../../public/assets/images/buttonIcon",
        false,
        /\.(png|jpe?g|svg)$/
      ),
    },
    //- declaration/application
    {
      path: "declaration/application",
      requireContext: require.context(
        "../../../../public/assets/images/declaration/application",
        false,
        /\.(png|jpe?g|svg)$/
      ),
    },
    //- header
    {
      path: "header",
      requireContext: require.context(
        "../../../../public/assets/images/header",
        false,
        /\.(png|jpe?g|svg)$/
      ),
    },
    //- logo
    {
      path: "logo",
      requireContext: require.context(
        "../../../../public/assets/images/logo",
        false,
        /\.(png|jpe?g|svg)$/
      ),
    },
    //- sidebar
    {
      path: "sidebar",
      requireContext: require.context(
        "../../../../public/assets/images/sidebar",
        false,
        /\.(png|jpe?g|svg)$/
      ),
    },
  ];
  const imagesData = imagesFolderDataList.map((imagesFolderData) => {
    return {
      folderPath: imagesFolderData.path,
      images: imagesFolderData.requireContext,
      imagePaths: imagesFolderData.requireContext?.keys() || [],
    };
  });
return  (
  <div className={`${styles["images-box"]} scroll`}>   

        {/* Image */}
        <div className="row">
          <div className="col-12 title">Image</div>
          <div className="col-12">
            <div className={`${styles["image-display-box"]}`}>
              <div className={`${styles["images-folder-root-path"]}`}>
                imagesFolderRootPath: {imagesFolderRootPath}
              </div>
              {imagesData.map((data, index) => (
                <div key={index} style={{ marginTop: ".5rem" }}>
                  <div
                    className="component-title"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {data.folderPath}
                  </div>
                  <div className="d-flex flex-wrap">
                    {data.imagePaths.map((path, imgIndex) => (
                      <div  key={imgIndex} className={`${styles["image-box"]}`}>
                        <div>{path.replace("./", "")}</div>
                        <img
                          src={data.images(path)}
                          alt={`${path}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
  </div>
)
};

export default ImageComponent;
