import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./SustainReportTwInfo.module.scss";
import { StreamFileType, PageActionTyep } from "models/baseModel";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import NormalInput from "components/base/NormalInput/NormalInput";
import IconButton from "components/button/IconButton/IconButton";
import BatchUploadModal, {
  BatchUploadModalProps,
} from "components/base/BatchUploadModal/BatchUploadModal";
import NormalTextarea from "components/base/NormalTextarea/NormalTextarea";
import {
  AccountantOptionType,
  AccountantStandard,
  AccountingAssociation,
  AccountingAssociationModel,
  BlockArrModel,
  BoardOfDirectorsStatus,
  CanonicalObject,
  DataValUploadFileModel,
  FollowRules,
  Multilingual,
  RevisedVersionReportModel,
  SustainReportDeclareDataShowModel,
  SustainReportExportExcelModel,
  ThirdPartyStandard,
  UploadFileStatus,
} from "models/application/sustainReportDeclare/sustainReportDeclareModel";
import UpdateApplyModal, {
  UpdateApplyModalProps,
} from "components/otherModule/UpdateApplyModal/UpdateApplyModal";
import {
  CorrectionRequestModel,
  RequestType,
} from "models/admin/declareDataMangeModel";
import {
  DeclareDataAction,
  DeclareStatus,
} from "models/application/indicators/indicatorModel";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import { useMutation } from "@tanstack/react-query";
import { error2Alert, success2Alert, successAlert } from "utils/otherToast";
import { normalDate, normalDate3 } from "utils/dateTimeFormat";
import { useParams } from "react-router-dom";
import { CommonService } from "services/common/commonService";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { ResponseCode } from "models/responseCodeModel";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";

interface SustainReportTwInfoProps {
  infoData?: SustainReportDeclareDataShowModel;
  action: string;
  setAction: React.Dispatch<React.SetStateAction<string>>;
  saveFinished: () => void;
  loading?: boolean;
}

const SustainReportTwInfo: FC<SustainReportTwInfoProps> = (props) => {
  const params = useParams();
  // 父層編輯狀態
  const { action, setAction } = props;
  // 畫面渲染實際資料
  const [reportData, setReportData] = useState<
    SustainReportDeclareDataShowModel | undefined
  >(props.infoData);
  const orgInfoData = useRef<SustainReportDeclareDataShowModel>();
  const saveInfoData = useRef<SustainReportDeclareDataShowModel>();
  const [fileData, setFileData] = useState<any[]>([]);
  // 是否可以按申報按鈕
  const [applyBtnDisabled, setApplyBtnDisabled] = useState(true);
  // 提醒訊息
  const [noticeMsg, setNoticeMsg] = useState<string>("");
  // 會計事務所清單
  const [sixCheckbox, setSixCheckbox] = useState<BlockArrModel[]>([]);
  // 對應按鈕disabled
  const [disabledBtn, setDisabledBtn] = useState<any>({
    // 區塊欄位
    fiveBlock: false,
    sixBlock: false,
    // 個別欄位
    followRulesExplain: false,
    thirdPartyStandardExplain: false,
    accountantOptionTypeExplain: false,
    discussCaseDate: false,
    reportCaseDate: false,
  });

  useEffect(() => {
    if (props.infoData) {
      orgInfoData.current = { ...props.infoData };
      orgInfoData.current.completionDate = normalDate(
        orgInfoData.current.completionDate
      );
      orgInfoData.current.mentionedDate = normalDate(
        orgInfoData.current.mentionedDate
      );
      orgInfoData.current.reportPeriodFinal = normalDate(
        orgInfoData.current.reportPeriodFinal
      );
      orgInfoData.current.reportPeriodStart = normalDate(
        orgInfoData.current.reportPeriodStart
      );
      orgInfoData.current.uploadInfoList =
        orgInfoData.current.uploadInfoList?.filter(
          (p) => p.multilingual === Multilingual.Chinese
        ) || [];
      // 題組九前端時間
      switch (orgInfoData.current.boardOfDirectorsStatus) {
        case BoardOfDirectorsStatus.DiscussionPassed:
          orgInfoData.current.discussCaseDate =
            orgInfoData.current.mentionedDate;
          break;
        case BoardOfDirectorsStatus.ReportPassed:
          orgInfoData.current.reportCaseDate =
            orgInfoData.current.mentionedDate;
          break;
      }
      // 原始報告資料
      setReportData(CommonService.deepClone(orgInfoData.current));
      reloadDisabled(orgInfoData.current);
      checkCanApply(orgInfoData.current);
      // 儲存編輯資料
      saveInfoData.current = CommonService.deepClone(orgInfoData.current);
    }
  }, [props.infoData]);

  useEffect(() => {
    getAccountingAssociationList.mutate();
  }, []);

  //#region question
  const questions = [
    "一、貴公司是否屬「上市/櫃公司編製與申報永續報告書作業辦法」第二條規範之對象？(若符合1~4其中一項，且同時符合第5點，請一併勾選)",
    "二、報告書完成日期",
    "三、報告書內容涵蓋期間",
    "四、編制依循準則",
    "五、是否取得第三方保證",
    "六、是否取得會計師確信",
    "七、報告書檔案置於公司網站之連結(請輸入網址)",
    "八、中文首版上傳檔案",
    "九、永續報告書提報董事會情形",
    "十、報告書聯絡資訊",
    "十一、備註",
  ];
  //#endregion

  //#region 資料確認儲存
  const [dataConfirmModal, setDataConfirmModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setDataConfirmModal({
        ...dataConfirmModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "資料確認",
  });
  //#endregion

  //#region 上傳資料
  const [uploadModal, setUploadModal] = useState<BatchUploadModalProps>({
    show: false,
    handleClose: () => {
      setUploadModal({
        ...uploadModal,
        show: false,
      });
    },
    title: "報告書上傳",
    accept: ".pdf",
    limitFileSize: 50,
    handleUpload: (e) => {},
    sampleDownloadUrl: "",
    uploadLoading: false,
  });

  const uploadFile = (files: any[]) => {
    const items: DataValUploadFileModel[] = [];
    for (const file of files) {
      items.push({
        multilingual: Multilingual.Chinese,
        fileId: null,
        fileName: file.name,
        uploadFileStatus: UploadFileStatus.FirstUpload,
      });
    }
    setFileData([...files]);
    if (saveInfoData.current) {
      saveInfoData.current.uploadInfoList = [...items];
    }
    setReportData({
      ...reportData!,
      uploadInfoList: [...items],
    });
    setUploadModal({
      ...uploadModal,
      show: false,
    });
  };
  //#endregion

  //#region 更正申報
  const [updateModal, setUpdateModal] = useState<UpdateApplyModalProps>({
    show: false,
    handleClose: () => {
      setUpdateModal({
        ...updateModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    requestType: RequestType.SustainReport,
  });

  const updateApplyMutation = useMutation({
    mutationFn:
      SustainReportDeclareService.addSustainReportDeclareDatacCrrectionRequest,
    onSuccess: (res) => {
      setUpdateModal({
        ...updateModal,
        loading: false,
        show: false,
      });
      if (res.success && res.code === ResponseCode.SuccessOK) {
        props.saveFinished();
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const handleUpdateModal = (item: CorrectionRequestModel) => {
    setUpdateModal({
      ...updateModal,
      loading: true,
    });
    updateApplyMutation.mutate({
      ...item,
      declareId: params.id!,
      multilingual: Multilingual.Chinese,
    });
  };
  //#endregion

  //#region 確認申報
  // modal 物件(confirm)
  const [applyModal, setApplyModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setApplyModal({
        ...applyModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "確認申報",
  });
  //#endregion

  //#region 編輯報告書
  const editSustainReportDeclareData = useMutation({
    mutationFn: (param: any) =>
      SustainReportDeclareService.editSustainReportDeclareData(param),
    onSuccess: (res) => {
      setApplyModal({
        ...applyModal,
        show: false,
        loading: false,
      });
      setNoticeConfirmModal({
        ...noticeConfirmModal,
        show: false,
        loading: false,
      });
      if (res.success && res.code === ResponseCode.SuccessOK) {
        props.saveFinished();
        success2Alert(res.message);
        setAction(PageActionTyep.View);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
      setNoticeConfirmModal({
        ...noticeConfirmModal,
        show: false,
        loading: false,
      });
    },
  });

  // 儲存
  const save = (actinType: DeclareDataAction) => {
    const formData = new FormData();
    const dataItem = {
      ...saveInfoData.current,
      action: actinType,
      multilingual: Multilingual.Chinese,
      twIsFinished: checkCanApply(saveInfoData.current!),
    };
    formData.append("data", JSON.stringify(dataItem));

    if (fileData.length > 0) {
      for (const file of fileData) {
        formData.append("file", file);
      }
    } else {
      formData.append("file", JSON.stringify([]));
    }
    // 確認填寫完必
    if (dataItem.twIsFinished) {
      setApplyModal({
        ...applyModal,
        loading: true,
      });
      editSustainReportDeclareData.mutate(formData);
    } else {
      setNoticeConfirmModal({
        ...noticeConfirmModal,
        handleConfirm: () => {
          setNoticeConfirmModal({
            ...noticeConfirmModal,
            loading: true,
          });
          setApplyModal({
            ...applyModal,
            loading: true,
          });
          editSustainReportDeclareData.mutate(formData);
        },
        show: true,
      });
    }
  };
  //#endregion

  //#region 上傳報告書(修正版)
  const editRevisedVersionReport = useMutation({
    mutationFn: (param: any) =>
      SustainReportDeclareService.editRevisedVersionReport(param),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        success2Alert(res.message);
        props.saveFinished();
      } else {
        error2Alert(res.message);
      }
      setUploadModal({
        ...uploadModal,
        uploadLoading: false,
        show: false,
      });
    },
    onError: (err) => {
      setUploadModal({
        ...uploadModal,
        uploadLoading: false,
        show: false,
      });
      console.log(err);
    },
  });

  const uploadRevisedFile = (files: any[]) => {
    const item: RevisedVersionReportModel = {
      declareId: params.id!,
      multilingual: Multilingual.Chinese,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(item));
    if (files.length > 0) {
      for (const file of files) {
        formData.append("file", file);
      }
    } else {
      formData.append("file", JSON.stringify([]));
    }

    setUploadModal({
      ...uploadModal,
      uploadLoading: true,
    });
    editRevisedVersionReport.mutate(formData);
  };
  //#endregion

  //#region 下載檔案
  const getSustainReportDeclareDataFileStream = useMutation({
    mutationFn: (id: string) =>
      SustainReportDeclareService.getSustainReportDeclareDataFileStream(id),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        successAlert("下載成功");
        CommonService.downloadByStream(res, StreamFileType.Pdf);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 下載檔案 */
  const downloadFile = (file: DataValUploadFileModel) => {
    if (file) {
      getSustainReportDeclareDataFileStream.mutate(file.fileId!);
    }
  };

  /** 取得永續報告書匯出excel */
  const getExportExcel = useMutation({
    mutationFn: (model: SustainReportExportExcelModel) =>
      SustainReportDeclareService.getExportExcel(model),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        successAlert("下載成功");
        CommonService.downloadByStream(res, StreamFileType.Xlsx);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  //#endregion

  //#region  確認是否申報
  const checkCanApply = (item: SustainReportDeclareDataShowModel): boolean => {
    let pass = true;
    let msg = "";
    const fileExist = item.uploadInfoList?.some(
      (p) => p.multilingual === Multilingual.Chinese
    );
    // 一般欄位邏輯
    if (!item.canonicalObject || item.canonicalObject?.length === 0) {
      msg += `${questions[0]}\n`;
      pass = false;
    }
    if (!item.completionDate) {
      msg += `${questions[1]}\n`;
      pass = false;
    }
    if (!item.reportPeriodStart || !item.reportPeriodFinal) {
      msg += `${questions[2]}\n`;
      pass = false;
    }
    if (item.followRules === null) {
      msg += `${questions[3]}\n`;
      pass = false;
    } else {
      // 如果選其他依循準則
      if (item.followRules === FollowRules.Others) {
        // 確認其他有無填寫
        if (!item.followRulesExplain) {
          msg += `${questions[3]}\n`;
          pass = false;
        }
      }
    }

    if (item.isThirdPartyGuaranteed === null) {
      msg += `${questions[4]}\n`;
      pass = false;
    } else {
      let fivePass = true;
      // 是否取得第三方保證
      if (item.isThirdPartyGuaranteed === true) {
        if (
          !item.thirdPartyVerificationAgency ||
          item.thirdPartyStandard === null
        ) {
          pass = false;
          fivePass = false;
        }

        if (item.thirdPartyStandard === ThirdPartyStandard.Others) {
          // 確認其他有無填寫
          if (!item.thirdPartyStandardExplain) {
            pass = false;
            fivePass = false;
          }
        }

        if (!fivePass) {
          msg += `${questions[4]}\n`;
        }
      }
    }

    if (item.isAccountantConvinced === null) {
      msg += `${questions[5]}\n`;
      pass = false;
    } else {
      // 針對第六題自己其他卡控
      let sixPass = true;
      // 是否取得會計師確信
      if (item.isAccountantConvinced === true) {
        if (
          item.accountantStandard === null ||
          item.accountantOptionType === null ||
          !item.accountingAssociationEnumList ||
          item.accountingAssociationEnumList?.length === 0
        ) {
          pass = false;
          sixPass = false;
        }

        // 會計師事務所包含其他
        if (
          item.accountingAssociationEnumList?.includes(
            AccountingAssociation.Others
          )
        ) {
          // 輸入框要確認有寫
          if (!item.accountingAssociation) {
            pass = false;
            sixPass = false;
          }
        }

        if (item.accountantOptionType === AccountantOptionType.Others) {
          // 確認其他有無填寫
          if (!item.accountantOptionTypeExplain) {
            pass = false;
            sixPass = false;
          }
        }

        if (!sixPass) {
          msg += `${questions[5]}\n`;
        }
      }
    }

    if (!item.companyTWReportUrl) {
      msg += `${questions[6]}\n`;
      pass = false;
    }
    if (!fileExist) {
      msg += `${questions[7]}\n`;
      pass = false;
    }
    if (item.boardOfDirectorsStatus === null) {
      msg += `${questions[8]}\n`;
      pass = false;
    } else {
      // 0 or 1 要確認時間有無填寫
      if (item.boardOfDirectorsStatus !== BoardOfDirectorsStatus.NotMentioned) {
        if (!item.mentionedDate) {
          msg += `${questions[8]}\n`;
          pass = false;
        }
      }
    }

    if (!item.reportContactInformation) {
      msg += `${questions[9]}\n`;
      pass = false;
    }

    setApplyBtnDisabled(!pass);
    if (!pass) {
      setNoticeMsg(msg);
    }
    return pass;
  };
  //#endregion

  //#region 必填modal 提醒
  const [noticeConfirmModal, setNoticeConfirmModal] =
    useState<ConfirmModalProps>({
      show: false,
      handleClose: () => {
        setNoticeConfirmModal({
          ...noticeConfirmModal,
          show: false,
        });
      },
      handleConfirm: () => {},
      title: "尚未填寫的必要欄位",
    });
  //#endregion

  const openUrl = (url: string | null | undefined) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.click();
    }
  };

  // 取得展示文字
  const getWordDisplay = (type: string): string => {
    let str = "";
    if (reportData) {
      switch (type) {
        case "canonicalObject":
          const selItems = oneCheckbox.filter((p) =>
            reportData.canonicalObject?.includes(p.id)
          );
          selItems.forEach((p) => {
            str += `${p.id + 1}.${p.text}\n`;
          });
          break;

        case "reportPeriod":
          str = `${normalDate3(reportData.reportPeriodStart) || ""} ~ ${
            normalDate3(reportData.reportPeriodFinal) || ""
          }`;
          break;
        case "companyTWReportUrl":
          str = reportData.companyTWReportUrl || "";
          break;
        case "completionDate":
          str = normalDate3(reportData.completionDate);
          break;
        case "reportContactInformation":
          str = reportData.reportContactInformation || "";
          break;
        case "comment":
          str = reportData.comment || "";
          break;
        case "followRules":
          switch (reportData.followRules) {
            case FollowRules.GRI:
              str = `1.GRI準則`;
              break;
            case FollowRules.Others:
              str = `2.其他依循標準`;
              if (reportData.followRulesExplain) {
                str += `(${reportData.followRulesExplain})`;
              }
              break;
            default:
              str = "無";
              break;
          }
          break;
        case "boardOfDirectorsStatus":
          switch (reportData.boardOfDirectorsStatus) {
            case BoardOfDirectorsStatus.DiscussionPassed:
              str = `經提報${normalDate3(reportData.mentionedDate)}討論案通過`;
              break;
            case BoardOfDirectorsStatus.ReportPassed:
              str = `經提報${normalDate3(reportData.mentionedDate)}報告案通過`;
              break;
            case BoardOfDirectorsStatus.NotMentioned:
              str = `未提董事會討論或報告`;
              break;
            default:
              str = "無";
              break;
          }
          break;
        case "isAccountantConvinced":
          if (reportData.isAccountantConvinced) {
            str += `有\n`;
            str += `1.會計師事務所：`;
            const selItems = sixCheckbox.filter((p) =>
              reportData.accountingAssociationEnumList?.includes(p.id)
            );
            selItems.forEach((p, index: number) => {
              str += `${p.text}`;
              // 其他
              if (p.id === AccountingAssociation.Others) {
                str += `(${reportData.accountingAssociation})`;
              }
              str += index === selItems.length - 1 ? "\n" : "、";
            });

            const standard = sixTwoRadiobox.find(
              (p) => p.id === reportData.accountantStandard
            );
            if (standard) {
              str += `2.標準：${standard.text}\n`;
            }
            const opinion = sixThreeRadiobox.find(
              (p) => p.id === reportData.accountantOptionType
            );

            if (opinion) {
              str += `3.意見類型：${opinion.text}`;
              if (reportData.accountantOptionTypeExplain) {
                str += `(${reportData.accountantOptionTypeExplain})`;
              }
              str += `\n`;
            }
          } else {
            str = `無`;
          }
          break;
        case "isThirdPartyGuaranteed":
          if (reportData.isThirdPartyGuaranteed) {
            str += `有\n`;
            str += `1.查驗證單位：${
              reportData.thirdPartyVerificationAgency || ""
            }\n`;
            const selItem = fiveRadiobox.find(
              (p) => p.id === reportData.thirdPartyStandard
            );
            if (selItem) {
              str += `2.標準：${selItem.text}`;
              if (reportData.thirdPartyStandardExplain) {
                str += `(${reportData.thirdPartyStandardExplain})`;
              }
              str += `\n`;
            }
          } else {
            str = `無`;
          }
          break;
      }
    }
    return str;
  };

  const setControlVal = (type: string, value: any) => {
    // 用ref存資料，實際回寫用ref的資料
    if (saveInfoData.current) {
      const reportSaveInfo = saveInfoData.current;
      switch (type) {
        case "canonicalObject":
          if (!reportSaveInfo.canonicalObject) {
            reportSaveInfo.canonicalObject = [];
          }
          const val = Number.parseInt(value);
          if (!reportSaveInfo.canonicalObject?.includes(val)) {
            reportSaveInfo.canonicalObject.push(val);
          } else {
            reportSaveInfo.canonicalObject =
              reportSaveInfo.canonicalObject?.filter((p) => p !== val) || [];
          }
          break;
        case "followRules":
          if (value) {
            console.log(value);
            reportSaveInfo.followRules = Number.parseInt(value);
            if (reportSaveInfo.followRules !== FollowRules.Others) {
              reportSaveInfo.followRulesExplain = null;
            }
          } else {
            reportSaveInfo.followRules = null;
          }
          break;
        case "thirdPartyStandard":
          if (value) {
            reportSaveInfo.thirdPartyStandard = Number.parseInt(value);
            if (
              reportSaveInfo.thirdPartyStandard !== ThirdPartyStandard.Others
            ) {
              reportSaveInfo.thirdPartyStandardExplain = null;
            }
          } else {
            reportSaveInfo.thirdPartyStandard = null;
          }
          break;
        case "isThirdPartyGuaranteed":
          // 是否取得第三方保證
          reportSaveInfo.isThirdPartyGuaranteed = value;
          if (reportSaveInfo.isThirdPartyGuaranteed === false) {
            reportSaveInfo.thirdPartyStandardExplain = null;
            reportSaveInfo.thirdPartyVerificationAgency = null;
            reportSaveInfo.thirdPartyStandard = null;
          }
          break;
        case "isAccountantConvinced":
          // 是否取得會計師確信
          reportSaveInfo.isAccountantConvinced = value;
          if (reportSaveInfo.isAccountantConvinced === false) {
            reportSaveInfo.accountingAssociation = null;
            reportSaveInfo.accountantOptionTypeExplain = null;
            reportSaveInfo.accountantOptionType = null;
            reportSaveInfo.accountantStandard = null;
            reportSaveInfo.accountingAssociationEnumList = [];
          }
          break;
        case "accountingAssociationEnumList":
          // 會計確性資料
          if (!reportSaveInfo.accountingAssociationEnumList) {
            reportSaveInfo.accountingAssociationEnumList = [];
          }
          const val2 = Number.parseInt(value);
          if (!reportSaveInfo.accountingAssociationEnumList?.includes(val2)) {
            reportSaveInfo.accountingAssociationEnumList.push(val2);
          } else {
            reportSaveInfo.accountingAssociationEnumList =
              reportSaveInfo.accountingAssociationEnumList?.filter(
                (p) => p !== val2
              ) || [];

            if (val2 === AccountingAssociation.Others) {
              reportSaveInfo.accountingAssociation = null;
            }
          }

          break;
        case "accountantOptionType":
          if (value) {
            reportSaveInfo.accountantOptionType = Number.parseInt(value);
            if (
              reportSaveInfo.accountantOptionType !==
              AccountantOptionType.Others
            ) {
              reportSaveInfo.accountantOptionTypeExplain = null;
            }
          } else {
            reportSaveInfo.accountantOptionType = null;
          }
          break;
        case "accountantStandard":
          if (value) {
            reportSaveInfo.accountantStandard = Number.parseInt(value);
          } else {
            reportSaveInfo.accountantStandard = null;
          }
          break;
        case "boardOfDirectorsStatus":
          // 董事會狀態
          if (value) {
            reportSaveInfo.boardOfDirectorsStatus = Number.parseInt(value);
            reportSaveInfo.mentionedDate = null;
            reportSaveInfo.reportCaseDate = null;
            reportSaveInfo.discussCaseDate = null;
          } else {
            reportSaveInfo.boardOfDirectorsStatus = null;
          }
          break;
        case "companyTWReportUrl":
          reportSaveInfo.companyTWReportUrl = value;
          break;
        case "followRulesExplain":
          reportSaveInfo.followRulesExplain = value;
          break;
        case "thirdPartyStandardExplain":
          reportSaveInfo.thirdPartyStandardExplain = value;
          break;
        case "thirdPartyVerificationAgency":
          reportSaveInfo.thirdPartyVerificationAgency = value;
          break;
        case "accountingAssociation":
          reportSaveInfo.accountingAssociation = value;
          break;
        case "accountantOptionTypeExplain":
          reportSaveInfo.accountantOptionTypeExplain = value;
          break;
        case "reportContactInformation":
          reportSaveInfo.reportContactInformation = value;
          break;
        case "comment":
          reportSaveInfo.comment = value;
          break;
        case "reportCaseDate":
          reportSaveInfo.reportCaseDate = value;
          reportSaveInfo.mentionedDate = value;
          break;
        case "discussCaseDate":
          reportSaveInfo.discussCaseDate = value;
          reportSaveInfo.mentionedDate = value;
          break;
        case "reportPeriodStart":
          reportSaveInfo.reportPeriodStart = value;
          break;
        case "reportPeriodFinal":
          reportSaveInfo.reportPeriodFinal = value;
          break;
        case "completionDate":
          reportSaveInfo.completionDate = value;
          break;
      }
      // 將資料存回reportData
      setReportData({
        ...reportSaveInfo,
      });
      reloadDisabled(reportSaveInfo);
    }
  };

  // 設置disabled (純刷新畫面，不影響資料結果)
  const reloadDisabled = (newData: SustainReportDeclareDataShowModel) => {
    // 刷新畫面使用
    if (newData) {
      const op = {
        // 區塊欄位
        fiveBlock: newData.isThirdPartyGuaranteed === false,
        sixBlock: newData.isAccountantConvinced === false,
        // 個別欄位
        followRulesExplain: false,
        thirdPartyStandardExplain: false,
        accountantOptionTypeExplain: false,
        discussCaseDate: false,
        reportCaseDate: false,
      };
      setDisabledBtn({
        ...disabledBtn,
        ...op,
      });
    }
  };

  //#region 資料區(大多靜態資料 check 無使用)
  /** 取得會計師事務所清單 */
  const getAccountingAssociationList = useMutation({
    mutationFn: () =>
      SustainReportDeclareService.getAccountingAssociationList(),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: AccountingAssociationModel[] = res.data;
        let newArr: BlockArrModel[] = [];
        data.forEach((item) => {
          newArr.push({
            id: item.enumKey,
            disabled: false,
            text: item.enumText,
            check: false,
          });
        });
        setSixCheckbox([...newArr]);
      } else {
        console.log(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const oneCheckbox = [
    {
      id: CanonicalObject.FoodIndustry,
      disabled: false,
      text: "屬食品工業者",
      check: false,
    },
    {
      id: CanonicalObject.CateringRevenueMoreThan50Percent,
      disabled: false,
      text: "屬餐飲收入占其全部營業收人之比率達百分之五十以上者",
      check: false,
    },
    {
      id: CanonicalObject.ChemicalIndustry,
      disabled: false,
      text: "屬化學工業者",
      check: false,
    },
    {
      id: CanonicalObject.FinancialAndInsurance,
      disabled: false,
      text: "屬金融保險業者",
      check: false,
    },
    {
      id: CanonicalObject.CapitalMoreThan2Billion,
      disabled: false,
      text: "資本額達新臺幣二十億元以上者",
      check: false,
    },
    {
      id: CanonicalObject.Voluntary,
      disabled: false,
      text: "係自願編製與申報",
      check: false,
    },
  ];
  const fourRadiobox = [
    {
      id: FollowRules.GRI,
      disabled: false,
      text: "GRI準則",
      check: false,
    },
    {
      id: FollowRules.Others,
      disabled: false,
      text: "其他依循標準",
      check: false,
    },
  ];

  const fiveRadiobox = [
    {
      id: ThirdPartyStandard.Type1ModerateGuarantee,
      disabled: false,
      text: "AA1000 第1類型(Type1) 中度保證",
      check: false,
    },
    {
      id: ThirdPartyStandard.Type1HighGuarantee,
      disabled: false,
      text: "AA1000 第1類型(Type1) 高度保證",
      check: false,
    },
    {
      id: ThirdPartyStandard.Type2ModerateGuarantee,
      disabled: false,
      text: "AA1000 第2類型(Type2) 中度保證",
      check: false,
    },
    {
      id: ThirdPartyStandard.Type2HighGuarantee,
      disabled: false,
      text: "AA1000 第2類型(Type2) 高度保證",
      check: false,
    },
    {
      id: ThirdPartyStandard.Others,
      disabled: false,
      text: "其他",
      check: false,
    },
  ];

  // sixCheckbox 取db資料

  const sixTwoRadiobox = [
    {
      id: AccountantStandard.OriginFirst,
      disabled: false,
      text: "確信準則3000號(原公報第一號)",
      check: false,
    },
    {
      id: AccountantStandard.ISAE,
      disabled: false,
      text: "ISAE 3000",
      check: false,
    },
  ];

  const sixThreeRadiobox = [
    {
      id: AccountantOptionType.LimitedCertainty,
      disabled: false,
      text: "有限確信",
      check: false,
    },
    {
      id: AccountantOptionType.ReasonableCertainty,
      disabled: false,
      text: "合理確信",
      check: false,
    },
    {
      id: AccountantOptionType.Others,
      disabled: false,
      text: "其他",
      check: false,
    },
  ];
  //#endregion

  return (
    <div className={styles["sustain-report-twinfo-box"]}>
      {(props.loading ||
        getSustainReportDeclareDataFileStream.isPending ||
        editSustainReportDeclareData.isPending ||
        getAccountingAssociationList.isPending ||
        getExportExcel.isPending) && <VisuallLoading />}
      <div className="content-title-box">
        <div className="main-title">報告書申報作業</div>
        <div className="content-tool">
          <LinkIconButton
            imgName={"download-icon.svg"}
            text={"匯出"}
            className={"min-w80-p"}
            onClick={() => {
              getExportExcel.mutate({
                declareId: params.id!,
                multilingual: Multilingual.Chinese
              });
            }}
          />
          {/* 有首次申報日按鈕就能出現 */}
          {reportData?.twStatus !== DeclareStatus.UnDeclared &&
            reportData?.twFirstDeclareDate 
           && (
              <IconButton
                className="secondary mr-1-m"
                imgName={"batch-upload-icon.svg"}
                text={"上傳報告書(修正版)"}
                onClick={() => {
                  setUploadModal({
                    ...uploadModal,
                    title: "上傳報告書(修正版)",
                    handleUpload: (e) => uploadRevisedFile(e),
                    show: true,
                  });
                }}
              />
            )}
          {(reportData?.twStatus === DeclareStatus.Declared ||
            reportData?.twStatus === DeclareStatus.DeclareEnd) && (
            <button
              className="default mr-1-m"
              onClick={() => {
                setUpdateModal({
                  ...updateModal,
                  declareStatus: reportData?.twStatus,
                  show: true,
                });
              }}
            >
              {reportData?.twStatus === DeclareStatus.DeclareEnd
                ? "申請開放"
                : "更正申請"}
            </button>
          )}

          {(reportData?.twStatus === DeclareStatus.Declaring ||
            reportData?.twStatus === DeclareStatus.Correcting) && (
            <button
              className={
                `secondary mr-1-m ` + `${applyBtnDisabled ? "disabled" : ""}`
              }
              onClick={() => {
                setApplyModal({
                  ...applyModal,
                  show: true,
                });
              }}
            >
              確認申報
            </button>
          )}
          {(reportData?.twStatus === DeclareStatus.UnDeclared ||
            reportData?.twStatus === DeclareStatus.Declaring ||
            reportData?.twStatus === DeclareStatus.Correcting) && (
            <>
              {action === PageActionTyep.View ? (
                <button
                  className="secondary mr-1-m"
                  onClick={() => {
                    setAction(PageActionTyep.Edit);
                  }}
                >
                  編輯
                </button>
              ) : (
                <>
                  <button
                    className="secondary mr-1-m"
                    onClick={() => {
                      setAction(PageActionTyep.View);
                      saveInfoData.current = CommonService.deepClone(
                        orgInfoData.current!
                      );
                      setReportData({ ...orgInfoData.current! });
                    }}
                  >
                    取消
                  </button>
                  <button
                    className="default"
                    onClick={() => {
                      save(DeclareDataAction.Saved);
                    }}
                  >
                    儲存
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className={"desc"}>
        <span className="star">*</span>為必填欄位
      </div>
      <div className="form-box scroll">
        <div className="question-box">
          <div className="question">
            {questions[0]}
            <span className="star">*</span>
          </div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <div className="d-flex flex-column">
                {oneCheckbox.map((item, index) => {
                  return (
                    <label key={item.id} className="custom-checkbox ">
                      <input
                        name={`oneQuestion`}
                        type="checkbox"
                        disabled={item.disabled}
                        value={item.id}
                        defaultChecked={reportData?.canonicalObject?.includes(
                          item.id
                        )}
                        onChange={(e) => {
                          setControlVal("canonicalObject", e.target.value);
                        }}
                      />
                      <span>
                        {index + 1}.{item.text}
                      </span>
                    </label>
                  );
                })}
              </div>
            ) : (
              <div className="view-word">
                {getWordDisplay("canonicalObject")}
              </div>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div className="question-box">
          <div className="question">
            {questions[1]}
            <span className="star">*</span>
          </div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <div className="item">
                <div className="item-content">
                  <NormalInput
                    id="completionDate"
                    type="date"
                    defaultValue={normalDate(reportData?.completionDate)}
                    onChange={(val: string) => {
                      setControlVal("completionDate", val);
                    }}
                  />
                </div>
                <span className="note">(西元年月日，例如20240101)</span>
              </div>
            ) : (
              <div className="view-word">
                {getWordDisplay("completionDate")}
              </div>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div className="question-box">
          <div className="question">
            {questions[2]}
            <span className="star">*</span>
          </div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <>
                <div className="item">
                  <div className="item-content">
                    <NormalInput
                      id="reportPeriodStart"
                      type="date"
                      defaultValue={reportData?.reportPeriodStart}
                      onChange={(val: string) => {
                        setControlVal("reportPeriodStart", val);
                      }}
                    />
                  </div>
                  <span className="note">~</span>
                  <div className="item-content">
                    <NormalInput
                      id="reportPeriodFinal"
                      type="date"
                      defaultValue={reportData?.reportPeriodFinal}
                      onChange={(val: string) => {
                        setControlVal("reportPeriodFinal", val);
                      }}
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="note">
                    西元年月日，例如20240101~20241231；請寫主要內容涵蓋期間，若報告內容包含期後重大事項，得以備註欄敘明即可
                  </div>
                </div>
              </>
            ) : (
              <div className="view-word">{getWordDisplay("reportPeriod")}</div>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div className="question-box">
          <div className="question">
            {questions[3]}
            <span className="star">*</span>
          </div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <div className="item-col">
                {fourRadiobox.map((item) => {
                  return (
                    <label key={item.id} className={`custom-radio `}>
                      <input
                        type="radio"
                        name={`four_radio`}
                        value={item.id}
                        checked={reportData?.followRules === item.id}
                        onChange={(e) => {
                          setControlVal("followRules", e.target.value);
                        }}
                      />
                      <span
                        className={
                          item.id === FollowRules.Others ? "other-control" : ""
                        }
                      >
                        {item.text}
                        {/* 其他類型 */}
                        {item.id === FollowRules.Others && (
                          <div className="item-content">
                            <NormalInput
                              id="followRulesExplain"
                              defaultValue={reportData?.followRulesExplain}
                              disabled={
                                reportData?.followRules !== FollowRules.Others
                              }
                              onChange={(val: string) => {
                                setControlVal("followRulesExplain", val);
                              }}
                            />
                          </div>
                        )}
                      </span>
                    </label>
                  );
                })}
              </div>
            ) : (
              <div className="view-word">{getWordDisplay("followRules")}</div>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div className="question-box">
          <div className="question">
            {questions[4]}
            <span className="star">*</span>
          </div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <div>
                <div className="d-flex">
                  <label className={`custom-radio `}>
                    <input
                      type="radio"
                      checked={reportData?.isThirdPartyGuaranteed === true}
                      name={`five_check_radio`}
                      value={1}
                      onChange={(e) => {
                        setControlVal("isThirdPartyGuaranteed", true);
                      }}
                    />
                    <span>有</span>
                  </label>
                  <label className={`custom-radio `}>
                    <input
                      type="radio"
                      checked={reportData?.isThirdPartyGuaranteed === false}
                      name={`five_check_radio`}
                      value={0}
                      onChange={(e) => {
                        setControlVal("isThirdPartyGuaranteed", false);
                      }}
                    />
                    <span>無</span>
                  </label>
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ color: "#008dc1" }}
                >
                  <div className="item">
                    <div className="">1.查驗證單位：</div>
                    <div className="item-content">
                      <NormalInput
                        id="thirdPartyVerificationAgency"
                        defaultValue={reportData?.thirdPartyVerificationAgency}
                        disabled={disabledBtn.fiveBlock}
                        onChange={(val: string) => {
                          setControlVal("thirdPartyVerificationAgency", val);
                        }}
                      />
                    </div>
                  </div>
                  <div className="item align-items-start">
                    <div className="special-top">2.標準：</div>
                    <div
                      className={
                        `item-col ` +
                        `${disabledBtn.fiveBlock ? "disabled-block " : ""}`
                      }
                    >
                      {fiveRadiobox.map((item) => {
                        return (
                          <label key={item.id} className={`custom-radio `}>
                            <input
                              type="radio"
                              name={`five_option_radio`}
                              value={item.id}
                              checked={
                                reportData?.thirdPartyStandard === item.id
                              }
                              disabled={disabledBtn.fiveBlock}
                              onChange={(e) => {
                                setControlVal(
                                  "thirdPartyStandard",
                                  e.target.value
                                );
                              }}
                            />
                            <span
                              className={
                                item.id === ThirdPartyStandard.Others
                                  ? "other-control"
                                  : ""
                              }
                            >
                              {item.text}
                              {/* 其他類型 */}
                              {item.id === ThirdPartyStandard.Others && (
                                <div className="item-content">
                                  <NormalInput
                                    id="thirdPartyStandardExplain"
                                    defaultValue={
                                      reportData?.thirdPartyStandardExplain
                                    }
                                    disabled={
                                      reportData?.thirdPartyStandard !==
                                        ThirdPartyStandard.Others ||
                                      disabledBtn.fiveBlock
                                    }
                                    onChange={(val: string) => {
                                      setControlVal(
                                        "thirdPartyStandardExplain",
                                        val
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="view-word">
                {getWordDisplay("isThirdPartyGuaranteed")}
              </div>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div className="question-box">
          <div className="question">
            {questions[5]}
            <span className="star">*</span>
          </div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <div>
                <div className="d-flex">
                  <label className={`custom-radio `}>
                    <input
                      type="radio"
                      name={`six_check_radio`}
                      checked={reportData?.isAccountantConvinced === true}
                      value={1}
                      onChange={(e) => {
                        setControlVal("isAccountantConvinced", true);
                      }}
                    />
                    <span>有</span>
                  </label>
                  <label className={`custom-radio `}>
                    <input
                      type="radio"
                      name={`six_check_radio`}
                      checked={reportData?.isAccountantConvinced === false}
                      value={0}
                      onChange={(e) => {
                        setControlVal("isAccountantConvinced", false);
                      }}
                    />
                    <span>無</span>
                  </label>
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ color: "#008dc1" }}
                >
                  <div className="item align-items-start">
                    <div className="special-top">1.會計師事務所：</div>
                    <div
                      className={
                        `item-col ` +
                        `${disabledBtn.sixBlock ? "disabled-block " : ""}`
                      }
                    >
                      {sixCheckbox.map((item, index) => {
                        return (
                          <label key={item.id} className="custom-checkbox ">
                            <input
                              name={`six_checkbox_question`}
                              type="checkbox"
                              disabled={disabledBtn.sixBlock}
                              value={item.id}
                              checked={reportData?.accountingAssociationEnumList?.includes(
                                item.id
                              )}
                              onChange={(e) => {
                                setControlVal(
                                  "accountingAssociationEnumList",
                                  e.target.value
                                );
                              }}
                            />
                            <span
                              className={
                                item.id === AccountingAssociation.Others
                                  ? "other-control"
                                  : ""
                              }
                            >
                              {item.text}
                              {/* 其他類型 */}
                              {item.id === AccountingAssociation.Others && (
                                <div className="item-content">
                                  <NormalInput
                                    id="accountingAssociation"
                                    defaultValue={
                                      reportData?.accountingAssociation
                                    }
                                    disabled={
                                      !reportData?.accountingAssociationEnumList?.includes(
                                        item.id
                                      ) || disabledBtn.sixBlock
                                    }
                                    onChange={(val: string) => {
                                      setControlVal(
                                        "accountingAssociation",
                                        val
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                  <div className="item align-items-start">
                    <div className="special-top">2.標準：</div>
                    <div
                      className={
                        `item-col ` +
                        `${disabledBtn.sixBlock ? "disabled-block " : ""}`
                      }
                    >
                      {sixTwoRadiobox.map((item) => {
                        return (
                          <label key={item.id} className={`custom-radio `}>
                            <input
                              type="radio"
                              name={`six_two_option_radio`}
                              value={item.id}
                              disabled={disabledBtn.sixBlock}
                              checked={
                                reportData?.accountantStandard === item.id
                              }
                              onChange={(e) => {
                                setControlVal(
                                  "accountantStandard",
                                  e.target.value
                                );
                              }}
                            />
                            <span>{item.text}</span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                  <div className="item align-items-start">
                    <div className="special-top">3.意見類型：</div>
                    <div
                      className={
                        `item-col ` +
                        `${disabledBtn.sixBlock ? "disabled-block " : ""}`
                      }
                    >
                      {sixThreeRadiobox.map((item) => {
                        return (
                          <label key={item.id} className={`custom-radio `}>
                            <input
                              type="radio"
                              name={`six_three_option_radio`}
                              checked={
                                reportData?.accountantOptionType === item.id
                              }
                              disabled={disabledBtn.sixBlock}
                              value={item.id}
                              onChange={(e) => {
                                setControlVal(
                                  "accountantOptionType",
                                  e.target.value
                                );
                              }}
                            />
                            <span
                              className={
                                item.id === AccountantOptionType.Others
                                  ? "other-control"
                                  : ""
                              }
                            >
                              {item.text}
                              {/* 其他類型 */}
                              {item.id === AccountantOptionType.Others && (
                                <div className="item-content">
                                  <NormalInput
                                    id="accountantOptionTypeExplain"
                                    defaultValue={
                                      reportData?.accountantOptionTypeExplain
                                    }
                                    disabled={
                                      reportData?.accountantOptionType !==
                                        AccountantOptionType.Others ||
                                      disabledBtn.sixBlock
                                    }
                                    onChange={(val: string) => {
                                      setControlVal(
                                        "accountantOptionTypeExplain",
                                        val
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="view-word">
                {getWordDisplay("isAccountantConvinced")}
              </div>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div className="question-box">
          <div className="question">
            {questions[6]}
            <span className="star">*</span>
          </div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <NormalInput
                id="companyTWReportUrl"
                defaultValue={reportData?.companyTWReportUrl}
                onChange={(val: string) => {
                  setControlVal("companyTWReportUrl", val);
                }}
              />
            ) : (
              <div
                className="file-url"
                onClick={() => openUrl(reportData?.companyTWReportUrl)}
              >
                {getWordDisplay("companyTWReportUrl")}
              </div>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div className="question-box">
          <div className="question">
            {questions[7]}
            <span className="star">*</span>
          </div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <div className="item-col">
                <div className="item-content">
                  {
                    !reportData?.twFirstDeclareDate &&(
                      <IconButton
                      imgName={"upload-btn-icon.svg"}
                      text={"上傳檔案"}
                      onClick={() => {
                        setUploadModal({
                          ...uploadModal,
                          handleUpload: (e) => uploadFile(e),
                          show: true,
                        });
                      }}
                    />
                    )
                  }
                  {reportData?.uploadInfoList?.map((item, index: number) => {
                    return (
                      <div key={index} className="upload-file-name">
                        {item.fileName}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="file-word">
                {reportData?.uploadInfoList?.map((item) => {
                  return (
                    item.uploadFileStatus === UploadFileStatus.FirstUpload && (
                      <div
                        key={item.fileId}
                        className="file-item"
                        onClick={() => downloadFile(item)}
                      >
                        {item.fileName}
                      </div>
                    )
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="line"></div>
        {/* 如果有修正編輯檔案才有這個內容 */}
        {action === PageActionTyep.View &&
          reportData?.uploadInfoList?.some(
            (p) => p.uploadFileStatus === UploadFileStatus.EditUpload
          ) && (
            <>
              <div className="question-box">
                <div className="question">八-1、中文修正版上傳檔案</div>
                <div className="control">
                  <div className="file-word">
                    {reportData?.uploadInfoList?.map((item) => {
                      return (
                        item.uploadFileStatus ===
                          UploadFileStatus.EditUpload && (
                          <div
                            key={item.fileId}
                            className="file-item"
                            onClick={() => downloadFile(item)}
                          >
                            {item.fileName}
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="line"></div>
            </>
          )}

        <div className="question-box">
          <div className="question">
            {questions[8]}
            <span className="star">*</span>
          </div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <div className="d-flex flex-column" style={{ color: "#008dc1" }}>
                <label className={`custom-radio `}>
                  <input
                    type="radio"
                    name={`nine_radio`}
                    checked={
                      reportData?.boardOfDirectorsStatus ===
                      BoardOfDirectorsStatus.DiscussionPassed
                    }
                    value={BoardOfDirectorsStatus.DiscussionPassed}
                    onChange={(e) => {
                      setControlVal("boardOfDirectorsStatus", e.target.value);
                    }}
                  />
                  <span className="d-flex align-items-center">
                    經提報
                    <div className="item-content">
                      <NormalInput
                        id="discussCaseDate"
                        type="date"
                        defaultValue={reportData?.discussCaseDate}
                        disabled={
                          reportData?.boardOfDirectorsStatus !==
                          BoardOfDirectorsStatus.DiscussionPassed
                        }
                        onChange={(val: string) => {
                          setControlVal("discussCaseDate", val);
                        }}
                      />
                    </div>
                    討論案通過
                  </span>
                </label>
                <label className={`custom-radio `}>
                  <input
                    type="radio"
                    name={`nine_radio`}
                    checked={
                      reportData?.boardOfDirectorsStatus ===
                      BoardOfDirectorsStatus.ReportPassed
                    }
                    value={BoardOfDirectorsStatus.ReportPassed}
                    onChange={(e) => {
                      setControlVal("boardOfDirectorsStatus", e.target.value);
                    }}
                  />
                  <span className="d-flex align-items-center">
                    經提報
                    <div className="item-content">
                      <NormalInput
                        id="reportCaseDate"
                        type="date"
                        defaultValue={reportData?.reportCaseDate}
                        disabled={
                          reportData?.boardOfDirectorsStatus !==
                          BoardOfDirectorsStatus.ReportPassed
                        }
                        onChange={(val: string) => {
                          setControlVal("reportCaseDate", val);
                        }}
                      />
                    </div>
                    報告案通過
                  </span>
                </label>
                <label className={`custom-radio `}>
                  <input
                    type="radio"
                    name={`nine_radio`}
                    checked={
                      reportData?.boardOfDirectorsStatus ===
                      BoardOfDirectorsStatus.NotMentioned
                    }
                    value={BoardOfDirectorsStatus.NotMentioned}
                    onChange={(e) => {
                      setControlVal("boardOfDirectorsStatus", e.target.value);
                    }}
                  />
                  <span>未提董事會討論或報告</span>
                </label>
              </div>
            ) : (
              <div className="view-word">
                {getWordDisplay("boardOfDirectorsStatus")}
              </div>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div className="question-box">
          <div className="question">
            {questions[9]}
            <span className="star">*</span>
          </div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <NormalInput
                id="reportContactInformation"
                placeholder={"請填寫電子郵件"}
                defaultValue={reportData?.reportContactInformation}
                onChange={(val: string) => {
                  setControlVal("reportContactInformation", val);
                }}
              />
            ) : (
              <div className="view-word">
                {getWordDisplay("reportContactInformation")}
              </div>
            )}
          </div>
        </div>
        <div className="line"></div>
        <div className="question-box">
          <div className="question">{questions[10]}</div>
          <div className="control">
            {action === PageActionTyep.Edit ? (
              <div className="item-col">
                <div>
                  <NormalTextarea
                    id="comment"
                    maxLength={40}
                    defaultValue={reportData?.comment || ""}
                    onChange={(val: string) => {
                      setControlVal("comment", val);
                    }}
                    placeholder={""}
                  />
                </div>
                <div className="note">
                  如：包含截至oooo年oo月oo日之重大事件，字數上限40字
                </div>
              </div>
            ) : (
              <div className="view-word">{getWordDisplay("comment")}</div>
            )}
          </div>
        </div>
        <div className="line"></div>
      </div>
      {/* 批次匯入彈窗 */}
      <BatchUploadModal
        {...uploadModal}
        noteChildren={
          <div>
            1.永續報告書應以原始文字檔案進行PDF轉檔後上傳，即勿直接掃描紙本產製PDF檔案，以利投資人搜尋電子書內容
          </div>
        }
      />
      {/* 資料尚未儲存modal */}
      <ConfirmModal {...dataConfirmModal}>
        <div>資料尚未儲存，確定要離開嗎?</div>
      </ConfirmModal>
      {/* 確認申報modal */}
      <ConfirmModal
        {...applyModal}
        handleConfirm={() => {
          save(DeclareDataAction.Declared);
        }}
      >
        <div>
          確認後不得再編輯資料，若申報完成後需修改，請至
          <span style={{ fontWeight: 700 }}>申報資料更正申請。</span>
        </div>
      </ConfirmModal>
      {/* 申報資料更正申請 */}
      <UpdateApplyModal
        {...updateModal}
        handleConfirm={(e) => {
          handleUpdateModal(e);
        }}
      />
      {/* 以下為未填寫必填 資料 */}
      <ConfirmModal {...noticeConfirmModal}>
        <div className="notice-msg-box">{noticeMsg}</div>
      </ConfirmModal>
    </div>
  );
};

export default SustainReportTwInfo;
