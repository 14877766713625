import { SystemLogRequestModel } from "models/admin/systemManageModel";
import { ListRequestModel } from "models/baseModel";
import { HttpClient } from "services/httpClient";

const httpClient = new HttpClient();

// #region 系統操作管理頁面

/** 取得列表 */
const getSystemFunList = (req: ListRequestModel) => {
    return httpClient.post(`SystemManage/list`, req);
}

/** 編輯系統功能管理資料狀態 */
const switchSysFunStatus = (id: string, status: boolean) => {
    return httpClient.post(`SystemManage/${id}`, {status: status});
}

// #endregion

// #region 系統操作紀錄

/** 系統操作紀錄清單列表 */
/** 取得列表 */
const getSysLog = (req: SystemLogRequestModel) => {
    return httpClient.post(`SystemLog/list`, req);
}

/** 匯出系統操作紀錄清單列表  */
const exportSysLog = (request: SystemLogRequestModel) => {
  return httpClient.postBlob(`SystemLog/exportExcel/FileStream`, request);
}

// #endregion 必要揭露指標



const SystemManageService = {
    getSystemFunList,
    switchSysFunStatus,
    getSysLog,
    exportSysLog
}

export default SystemManageService;
