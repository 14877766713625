import React, { FC, useEffect, useState } from "react";
import styles from "./PropagandaCategory.module.scss";
import Dropdown from "components/base/Dropdown/Dropdown";
import SearchInput from "components/base/SearchInput/SearchInput";
import { normalDate3 } from "utils/dateTimeFormat";
import TablePagination from "components/base/TablePagination/TablePagination";
import { ListRequestModel, PageActionTyep } from "models/baseModel";
import IconButton from "components/button/IconButton/IconButton";
import { usePermissionContext } from "context/PermissionProvider";
import { EduPropagandaManageActionsPermissionCodes } from "models/auth/permissionModel";
import EduPropagandaManageService from "services/admin/eduPropagandaManageService";
import { useMutation } from "@tanstack/react-query";
import { ResponseCode } from "models/responseCodeModel";
import {
  AddEduResourceDirectoryModel,
  EditEduResourceDirectoryModel,
  EduResourceDirectoryModel,
} from "models/admin/eduPropagandaManageModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import NormalInput from "components/base/NormalInput/NormalInput";
import { error2Alert, success2Alert } from "utils/otherToast";

interface PropagandaCategoryProps {}

const PropagandaCategory: FC<PropagandaCategoryProps> = () => {
  //#region 搜尋參數
  /** 資料 */
  const [listData, setListData] = useState<EduResourceDirectoryModel[]>([]);
  /** 設置資料總數 */
  const [totalCount, setTotalCount] = useState<number>(0);
  /** 搜尋條件 */
  const [param, setParam] = useState<ListRequestModel>({
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortKey: null,
  });
  const [isEdit, setIsEdit] = useState(false);
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;
  const [batchOperateList, setBatchOperateList] = useState<string[]>([]);
  const [infoData, setInfoData] = useState<EditEduResourceDirectoryModel>({
    newName: "",
    id: "",
  });
  // 動作
  const [action, setAction] = useState<string>(PageActionTyep.Add);
  /** 是否有填資料 */
  const [valid, setValid] = useState<boolean>(true);
  // 取得一次清單
  useEffect(() => {
    getEduResourceDirectoryList.mutate(param);
  }, []);

  useEffect(() => {
    if (!isEdit) {
      setBatchOperateList([]);
    }
  }, [isEdit]);
  //#endregion

  //#region api 相關

  /** 類型(目錄)管理列表 */
  const getEduResourceDirectoryList = useMutation({
    mutationFn: (model: ListRequestModel) =>
      EduPropagandaManageService.getEduResourceDirectoryList(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: EduResourceDirectoryModel[] = res.data;
        setListData([...data]);
        setTotalCount(res.count || 0);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /**  新增類型(目錄)管理列表 */
  const addEduResourceDirectoryList = useMutation({
    mutationFn: (model: AddEduResourceDirectoryModel) =>
      EduPropagandaManageService.addEduResourceDirectoryList(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        setInfoModal({
          ...infoModal,
          loading: false,
          show: false,
        });
        getEduResourceDirectoryList.mutate(param);
        success2Alert(res.message);
      } else {
        console.log(res);
        setInfoModal({
          ...infoModal,
          loading: false,
        });
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /**  編輯類型(目錄)管理列表 */
  const editEduResourceDirectoryList = useMutation({
    mutationFn: (model: EditEduResourceDirectoryModel) =>
      EduPropagandaManageService.editEduResourceDirectoryList(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        setInfoModal({
          ...infoModal,
          loading: false,
          show: false,
        });

        getEduResourceDirectoryList.mutate(param);
        success2Alert(res.message);
      } else {
        setInfoModal({
          ...infoModal,
          loading: false,
        });
        console.log(res);
        error2Alert(res.message);

      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /**  刪除類型(目錄)管理列表*/
  const deleteEduResourceDirectoryList = useMutation({
    mutationFn: (ids: string[]) =>
      EduPropagandaManageService.deleteEduResourceDirectoryList(ids),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        getEduResourceDirectoryList.mutate(param);
        setBatchOperateList([]);
        success2Alert(res.message);
      } else {
        console.log(res);
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  //#endregion

  //#region modal 資訊
  /** 新增/編輯modal */
  const [infoModal, setInfoModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setInfoModal({
        ...infoModal,
        show: false,
      });
      setInfoData({
        ...infoData,
        newName: "",
      });
    },
    handleConfirm: () => {},
    title: "新增類型",
  });

  /** 打開infoModal */
  const openInfoModal = (item: EduResourceDirectoryModel, action:string) => {
    setAction(action);
    // 清空資料
    setInfoData({
      ...infoData,
      newName: item.name,
      id: item.id,
    });

    let preTitle = action === PageActionTyep.Add ? '新增':'編輯';
    setInfoModal({
      ...infoModal,
      show: true,
      title: `${preTitle}類型`
    });
  };

  /** 儲存 */
  const save = () => {
    if (!infoData.newName) {
      error2Alert("請輸入類型名稱");
      setValid(false);
      return;
    }
    setInfoModal({
      ...infoModal,
      loading: true,
    });

    if (action === PageActionTyep.Add) {
      const model: AddEduResourceDirectoryModel = {
        name: infoData.newName,
      };
      addEduResourceDirectoryList.mutate(model);
    } else {
      editEduResourceDirectoryList.mutate(infoData);
    }
  };
  //#endregion

  return (
    <div className={styles["propaganda-category-list-box"]}>
      {(getEduResourceDirectoryList.isPending ||
        deleteEduResourceDirectoryList.isPending) && (
        <VisuallLoading></VisuallLoading>
      )}
      <div className={`${styles["operation-header"]}`}>
        <div className={`left`}>
          <div className={`select-item`}>
            <SearchInput onClick={(e) => {
              const newParam = {
                ...param,
                page:1,
                searchKey: e
              };
              setParam(newParam);
              getEduResourceDirectoryList.mutate(newParam);
            }} placeholder={"搜尋"} />
          </div>
        </div>
        <div className={`right`}>
          {batchOperateList.length ? (
            <>
              {detectEveryActionPermission([
                EduPropagandaManageActionsPermissionCodes.EduPropagandaCategoryDelete,
              ]) ? (
                <div className={`select-item`}>
                  <IconButton
                    imgName="trash-icon.svg"
                    text="刪除"
                    className="secondary"
                    onClick={() => {
                      deleteEduResourceDirectoryList.mutate(batchOperateList);
                    }}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className={`select-item`}>
                <button
                  className="secondary"
                  onClick={() => {
                    setIsEdit(!isEdit);
                  }}
                >
                  {!isEdit ? "多筆編輯" : "取消編輯"}
                </button>
              </div>
              {(detectEveryActionPermission([
                EduPropagandaManageActionsPermissionCodes.EduPropagandaCategoryCreate,
              ]) && !isEdit) && (
                <div className={`select-item`}>
                  <button
                    className="default"
                    onClick={() => {
                      openInfoModal({
                        id: "",
                        name: "",
                        updateTime: new Date(),
                      }, PageActionTyep.Add);
                    }}
                  >
                    +新增
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className={`${styles["propaganda-category-table-box"]} scroll`}>
        <table
          aria-label="查詢結果table"
          className="table-container sticky-table"
        >
          <thead>
            <tr>
              {isEdit && (
                <th
                  scope="col"
                  className={`${styles["setting-checkbox"]}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <label className={`custom-checkbox hideText`}>
                    <input
                      type="checkbox"
                      checked={listData.length === batchOperateList.length}
                      onChange={() => {
                        if (listData.length === batchOperateList.length) {
                          setBatchOperateList([]);
                        } else {
                          setBatchOperateList(listData.map((e) => e.id!));
                        }
                      }}
                    />
                    <span
                      className={`${
                        batchOperateList.length > 0 &&
                        listData.length > batchOperateList.length
                          ? "partial"
                          : ""
                      }`}
                    ></span>
                  </label>
                </th>
              )}
              <th scope="col">類型名稱</th>
              <th scope="col">編輯日期</th>
            </tr>
          </thead>
          <tbody>
            {listData.length === 0 ? (
              <tr>
                <td colSpan={2}>查無資料</td>
              </tr>
            ) : (
              listData?.map((p: EduResourceDirectoryModel) => {
                return (
                  <tr
                    key={p.id}
                    onClick={() => {
                      openInfoModal(p, PageActionTyep.Edit);
                    }}
                  >
                    {isEdit && (
                      <td
                        className={`${styles["setting-checkbox"]}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <label className={`custom-checkbox hideText`}>
                          <input
                            type="checkbox"
                            checked={batchOperateList.includes(p.id!)}
                            onChange={() => {
                              if (batchOperateList.includes(p.id!)) {
                                setBatchOperateList((prev) =>
                                  prev.filter((e) => e !== p.id!)
                                );
                              } else {
                                setBatchOperateList((prev) => [...prev, p.id!]);
                              }
                            }}
                          />
                          <span></span>
                        </label>
                      </td>
                    )}
                    <td>{p.name}</td>
                    <td>{normalDate3(p.updateTime)}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="table-pagination mb-20-p">
        <TablePagination
          page={param.page}
          pageSize={param.pageSize}
          onChange={(e) => {
            const newModel = {
              ...param,
              ...e,
            }
            setParam(newModel);
            getEduResourceDirectoryList.mutate(newModel);
          }}
          total={totalCount}
        />
      </div>
      {/* 新增/編輯modal */}
      <ConfirmModal {...infoModal} handleConfirm={save}>
        <div className="propaganda-info-box">
          <div>類型名稱</div>
          <div>
            <NormalInput
              placeholder="類型名稱*"
              defaultValue={infoData.newName}
              valid={valid}
              onChange={(e) => {
                setInfoData({
                  ...infoData,
                  newName: e,
                });
                if (e) {
                  setValid(true);
                }
              }}
            />
          </div>
        </div>
      </ConfirmModal>
    </div>
  );
};

export default PropagandaCategory;
