import { FC} from "react";
import { RouteObject, useRoutes, Navigate, Outlet } from "react-router-dom";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import RGstep1 from "pages/Application/ReportGeneration/ReportGenerationInfo/RGstep1/RGstep1";
import ReportGenerationList from "pages/Application/ReportGeneration/ReportGenerationList/ReportGenerationList";
import RGstep2 from "pages/Application/ReportGeneration/ReportGenerationInfo/RGstep2/RGstep2";
import RGstep4 from "pages/Application/ReportGeneration/ReportGenerationInfo/RGstep4/RGstep4";
import RGstep3 from "pages/Application/ReportGeneration/ReportGenerationInfo/RGstep3/RGstep3";
import ReportGenerationInfo from "pages/Application/ReportGeneration/ReportGenerationInfo/ReportGenerationInfo";

type ReportGenerationLayoutProps = {};
// layout
const ReportGenerationLayout: FC<ReportGenerationLayoutProps> = () => {
  return (
    <div className="no-nav-content-box">
    <CustomErrorBoundary>
      <Outlet></Outlet>
    </CustomErrorBoundary>
  </div>
  );
};


interface ReportGenerationRouterProps {}
const ReportGenerationRouter: FC<ReportGenerationRouterProps> = () => {
 
  // 創建router
  const routers: RouteObject[] = [
    {
      path: "",
      element: <ReportGenerationLayout />,
      children: [
        {
          path: "",
          element: <Navigate to={"list"} />,
        },
        {
          path: "list/*",
          element: <ReportGenerationList />,
        },
        {
          path: ":id/info/:stepId",
          element: <ReportGenerationInfo/>,
        }
     
      ],
    },
  ];
  let elements = useRoutes(routers);
  return <>{elements}</>;
};

export default ReportGenerationRouter;
