import { CorrectionRequestModel, DeclareImportExcelModel } from "models/admin/declareDataMangeModel";
import { DeclareItemRequestModel } from "models/admin/indicatorModel";
import { ListRequestModel } from "models/baseModel";
import { EsgDeclareDataModel } from "models/application/indicators/indicatorModel";
import { CompanySampleDataFormModel } from "models/samples/companySampleDataModel/companySampleDataModel";
import { HttpClient } from "services/httpClient";

const httpClient = new HttpClient();

/** 取得年度申報基礎資料 */
const getDeclareInfo = (declareId: string) => {
    return httpClient.get(`indicatorDeclare/declareInfo?declareId=${declareId}`);
}

/** 取得年度申報資料列表 */
const getDeclareInfoList = (model: ListRequestModel) => {
    return httpClient.post(`indicatorDeclare/declareInfoList`,model);
}

/** 取得申報項目樹狀 */
const getDeclareItemTree = (model:DeclareItemRequestModel) => {
    return httpClient.post(`indicatorDeclare/declareItemTree`,model);
}

/** 取得單一申報項目詳細資料 */
const getDeclareItemDetail = (model:DeclareItemRequestModel) => {
    return httpClient.post(`indicatorDeclare/getDeclareItemDetail`,model);
}

/** 編輯單一申報項目詳細資料 */
const editDeclareItemDetail= (model:CompanySampleDataFormModel) => {
    return httpClient.post(`indicatorDeclare/editDeclareItemDetail`,model);
}

/**  新增/編輯ESG申報作業資訊 */
const editEsgDeclareData= (model:EsgDeclareDataModel) => {
    return httpClient.post(`indicatorDeclare/data`,model);
}

/** ESG申報作業-更正申請 */
const addEsgDeclareDatacCrrectionRequest= (model:CorrectionRequestModel) => {
    return httpClient.post(`indicatorDeclare/data/correction-request`,model);
}

/** 取得批次匯入範本檔案 */
const getDeclareImportExcelExample= (model:DeclareImportExcelModel) => {
    return httpClient.get(`indicatorDeclare/declare/importExcel/example?year=${model.year}&isImport=${model.isImport}`);
}

/** 批次匯入excel或csv檔 */
const declareImportExcel= (model:any) => {
    return httpClient.post(`indicatorDeclare/declare/importExcel?year=${model.year}`,model.file);
}

/** 批次匯入範本檔案(stream 方式) */
const getDeclareImportExcelExampleFileStream= (model:DeclareImportExcelModel) => {
    return httpClient.getBlob(`indicatorDeclare/declare/importExcel/example/FileStream?year=${model.year}&isImport=${model.isImport}`);
}

/** 指標api 方法 */
const IndicatorDeclareService = {
    getDeclareInfo,
    getDeclareInfoList,
    getDeclareItemTree,
    getDeclareItemDetail,
    editDeclareItemDetail,
    editEsgDeclareData,
    addEsgDeclareDatacCrrectionRequest,
    getDeclareImportExcelExample,
    declareImportExcel,
    getDeclareImportExcelExampleFileStream
}

export default IndicatorDeclareService;