import { create } from 'zustand';
import { ListRequestModel } from 'models/baseModel';

export interface MarketYearModel {
  id: string | null;
  marketType: number | null;
  marketTypeName?: string | null;
  year: number | null;
  newYear?: number | null;
  updateTime?: Date | null;
  checked?: boolean;
  isEdit?: boolean;
}

export interface MarketYearCopyModel {
  id: string;
  newYear: number;
}

export interface IndustryModel {
  id: string | null;
  industryYearId: string | null;
  industryYear?: number;
  marketType?: number | null;
  marketTypeName?: string;
  name?: string;
  createTime?: Date;
  updateTime?: Date | null;
  checked?: boolean;
  isEdit?: boolean;
}

export interface MarketYearRequestModel extends ListRequestModel {
  marketType?: number | null;
  year?: number | null;
}
export interface IndustryManageRequestModel extends ListRequestModel {
  industryYearId?: string | null;
  /** 是否餐飲營收50%以上 */
  isHighRevenueIndicator?: boolean | null;
}

// #region 公司管理

//- EditCompanyModel
export class EditCompanyModel {
  industryYearId: string | undefined;
  companyCode: string | undefined;
  companyName: string | undefined;
  companyShortName: string | undefined;
  industryId: string | undefined;
  sasbIndustryId: string | undefined;
  tableInfo: number | undefined;
  highRevenueIndicator: boolean | undefined;
}

export class EditCompanyValidModel {
  industryYearId: boolean = true;
  companyCode: boolean = true;
  companyName: boolean = true;
  companyShortName: boolean = true;
  industryId: boolean = true;
  sasbIndustryId: boolean = true;
  tableInfo: boolean = true;
  highRevenueIndicator: boolean = true;
}

//- CompanyModel
export interface CompanyModel {
  id: string | undefined;
  industryYearId: string | undefined;
  industryYear: number | undefined;
  marketType: number | undefined;
  marketTypeName: string | undefined;
  companyCode: string | undefined;
  companyName: string | undefined;
  companyShortName: string | undefined;
  industryId: string | undefined;
  industryName: string | undefined;
  sasbIndustryId: string | undefined;
  sasbIndustryName: string | undefined;
  highRevenueIndicator: boolean | undefined;
  tableInfo: number | undefined;
  tableInfoName: string | undefined;
  capitalAmount: number | undefined;
}

export class TransferIndustryModel {
  companyCode: string | undefined;
  companyId: string | undefined;
  industryId: string | undefined;
  marketType: string | undefined;
  marketTypeId: string | undefined;
}

export class TransferIndustryValidModel {
  companyCode: boolean = true;
  industryId: boolean = true;
  marketType: boolean = true;
}

export interface ChangeIndustryModel {
  industryYearId: string;
  marketType: number | null | undefined;
  companyCode: string | undefined;
  changeMarketType: string | undefined;
  changeIndustryYearId: string | undefined;
  changeIndustryId: string | undefined;
}

export interface CompanyOptionModel {
  companyCode: string;
  marketType: string;
  industryName: string;
  id: string;
  text: string;
  code?: string;
  enumKey?: number | null;
}


// #endregion 公司管理
