import { FC, useEffect, useMemo, useState } from "react";
import styles from "./List.module.scss";
import { useNavigate } from "react-router-dom";
import Dropdown from "components/base/Dropdown/Dropdown";
import { OptionModel } from "models/baseModel";
import ConfirmModal, { ConfirmModalProps } from "components/base/ConfirmModal/ConfirmModal";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import moment from "moment";
import { IndicatorListRequest2Model, IndicatorMarketModel } from "models/admin/indicatorModel";
import IndicatorService from "services/admin/indicatorService";
import { useMutation } from "@tanstack/react-query";
import toast, { Toaster } from "react-hot-toast";
import TablePagination from "components/base/TablePagination/TablePagination";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import useUserInfoStore from "state/useUserInfoStore";
import { usePermissionContext } from "context/PermissionProvider";
import { IndicatorManageActionsPermissionCodes } from "models/auth/permissionModel";

interface ListProps {
    handleMessage: any
}

const List: FC<ListProps> = (props) => {

    // #region 變數
    const [list, setList] = useState<IndicatorMarketModel[]>([]);
    const [orgList, setOrgList] = useState<IndicatorMarketModel[]>([]);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [checkCount, setCheckCount] = useState(0);
    const [openCheck, setOpenCheck] = useState(false);
    const [newList, setNewList] = useState<IndicatorMarketModel[]>([]);

    const [count, setCount] = useState<number | undefined>(0);
    const [listIsPending, setListIsPending] = useState(false);
    const { userInfo } = useUserInfoStore();
    const [request, setRequest] = useState<IndicatorListRequest2Model>({
        page: 1,
        pageSize: 10,
        searchKey: null,
        sortKey: null,
        marketType: userInfo.accType === 1 ? 0 : 1,
        year: null
      });
    // modal 物件(confirm)
    const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
        show: false,
        handleClose: () => {
        setConfirmModal({
            ...confirmModal,
            show: false,
        });
        },
        handleConfirm: () => {},
        title: "確認",
    });
    const { PermissionService } = usePermissionContext();
    const { detectEveryActionPermission } = PermissionService;
    // #endregion

    // #region 事件

    // 取得清單
    // 取得
    const GetIndicatorMarketMutation = useMutation({
        mutationFn: IndicatorService.getIndicatorMarket,
    });

    const handleList = (data: IndicatorListRequest2Model) => {
        setListIsPending(true);
        GetIndicatorMarketMutation
        .mutateAsync(data)
        .then(p=>{
            setListIsPending(false);

            if(p.success){
                setOrgList(JSON.parse(JSON.stringify(p.data)));
                p.data.forEach((d:IndicatorMarketModel) => {
                    d['isEdit'] = false;
                    d['checked'] = false;
                });
                setList(p.data);
                setCount(p.count);
            } else {
                setList([]);
                toast.error(p.message);
            }
        }).catch((err) => {
            setListIsPending(false);
            console.log(err);
            setList([]);
            toast.error(err);
        });
    };


    useEffect(() => {
        handleList(request);
    }, [request]);

    let queryYearOption: any[] = [{
        id: null,
        text: "全部",
      }];
    let yearOption: any[] = [];
    let today = new Date();
    for(let i=today.getFullYear();i>=2021; i--) {
        yearOption.push({
            id: i.toString(),
            text: `${i.toString()}年度`
        });
        queryYearOption.push({
            id: i.toString(),
            text: `${i.toString()}年度`
        });
    }
    const navigate = useNavigate();
    const handleNavigate = (item: IndicatorMarketModel) => {
        if(item.id) {
            props.handleMessage(item.year);
            navigate(`/admin/indicator/${item.id}/setting`);
        }
    }


    const options: any[] = [
    /*{
      id: 'null',
      text: "全部",
    }*/];
    if(userInfo.accType === 1) {
      options.push({
        id: '0',
        text: "上市",
      });
    } else {
      options.push({
        id: '1',
        text: "上櫃",
      });
    }
    const options2: any[] = [];
    if(userInfo.accType === 1) {
      options2.push({
        id: '0',
        text: "上市",
      });
    } else {
      options2.push({
        id: '1',
        text: "上櫃",
      });
    }


    const handleTmpClick = () => {
        console.log("test");
      };

    const [modal, setModal] = useState<ConfirmModalProps>({
        show: false,
        handleClose: () => handleCloseModal(),
        handleConfirm: () => handleConfirmModal(),
        title: "新增ESG指標",
      });

    // 處理關閉
    const handleCloseModal = () => {
        console.log("close");
        handleModal();
    };

    // 處理確認
    const handleConfirmModal = () => {
        console.log("confirm");
        handleModal();
    };

    // 處理modal關閉事件
    const handleModal = () => {
        setModal((prev) => {
        return {
            ...prev,
            show: !prev.show,
        };
        });
    };

    function handleChkChange(id: string | null) {
        if(id) {
            setList(list.map((ck, index) => {
                    if(ck.id === id){
                        ck.checked = !ck.checked;
                    }
                    return ck;
                }
            ));
            countCheckbox();
        }
    }

    function handleAllCheckbox() {
        setCheckAll(!checkAll);
        if(!checkAll === true) {
            setCheckCount(list.length);
        } else {
            setCheckCount(0);
        }
        list.forEach(item => {
            item.checked = !checkAll;
        });
    }

    function countCheckbox() {
        const total = list.length;
        const checked = list.filter(x=>x.checked === true).length;
        setCheckCount(checked);
        if(total !== 0 && total === checked) {
            setCheckAll(true);
        } else if(checked !== 0 && total !== checked) {
            setCheckAll(false);
        } else {
            setCheckAll(false);
        }
    }
    // 選取年度開關
    const handleOpenCheckToggle = () => {
        setOpenCheck(!openCheck);
        setCheckAll(false);
        setCheckCount(0);
        setNewList([]);
        handleEditToggle(null, false);
        let tmpreq = {...request};
        tmpreq.marketType = userInfo.accType === 1 ? 0 : 1;
        setRequest(tmpreq);

    };

    const handleEditToggle = (index: number|null, isEdit: boolean) => {
        let tmpList = [...list];
        if(index===null) {
          tmpList.forEach(t => {
            t.isEdit = false;
          })
        } else {
          tmpList[index].isEdit=isEdit;
          if(isEdit===false){
            tmpList[index].marketType=orgList[index].marketType;
            tmpList[index].year=orgList[index].year;
          }
        }
        setList(tmpList);
    }

    // #region CRUD

    // 新增
    const addIndicatorMarketMutation = useMutation({
        mutationFn: IndicatorService.addIndicatorMarket,
    });

    // 新增事件
    const handleAdd = (index: number) => {
        if(newList[index].marketType === null || newList[index].year === null) {
            toast.error('欄位必填');
        } else {
            const add = {
                id: null,
                marketType: newList[index].marketType,
                year: newList[index].year
            };
            setListIsPending(true);
            addIndicatorMarketMutation
            .mutateAsync(add)
            .then(p=>{
                setListIsPending(false);
                if(p.success){
                    handleList(request);
                    setNewList([]);

                    toast.success('success');
                } else {
                    toast.error(p.message);
                }
            })
            .catch((err) => {
                setListIsPending(false);
                console.log(err);
                toast.error(err);
            });
        }
    };

    // 編輯
    const saveIndicatorMarketMutation = useMutation({
        mutationFn: IndicatorService.saveIndicatorMarket,
    });

    // 編輯事件
    const handleSave = (index: number) => {
        if(list[index].marketType === null || list[index].year === null) {
            toast.error('欄位必填');
        } else {
            const add = {
                id: list[index].id,
                marketType: list[index].marketType,
                year: list[index].year
            };
            setListIsPending(true);
            saveIndicatorMarketMutation
            .mutateAsync(add)
            .then(p=>{
                setListIsPending(false);
                if(p.success){
                    handleList(request);
                    toast.success('success');
                } else {
                    toast.error(p.message);
                }
            })
            .catch((err) => {
                setListIsPending(false);
                console.log(err);
                toast.error(err);
            });
        }
    };

    // 刪除
    const removeIndicatorMarketMutation = useMutation({
        mutationFn: IndicatorService.removeIndicatorMarket,
    });



    //刪除清單
    const handleRemove = (id: string | null) => {

      setConfirmModal({
        ...confirmModal,
        show: true,
        handleConfirm: () => {
          let checked = list.filter(x=>x.checked === true);
          if(id) {
            checked = list.filter(x=>x.id === id);
          }
          const ids: (string| null)[] = [];
          if(checked) {
            checked.forEach((c: IndicatorMarketModel) => {
               ids.push(c.id);
            });
            setConfirmModal((prev) => {
                return {
                    ...prev,
                    loading: true,
                };
            });
            setListIsPending(true);
            removeIndicatorMarketMutation
                .mutateAsync(ids)
                .then(p=>{
                    setListIsPending(false);
                    if(p.success){
                        handleList(request);
                        toast.success('刪除成功');
                        setCheckCount(0);
                    } else {
                        toast.error('刪除失敗');
                    }
                })
                .catch((err) => {
                    setListIsPending(false);
                    console.log(err);
                    toast.error(err);
                })
                .finally(()=> {
                    setConfirmModal({
                        ...confirmModal,
                        show: false,
                        loading: false,
                    });
                });;
            }
        },
      });

    };

    // 複製
    const copyIndicatorMarketMutation = useMutation({
        mutationFn: IndicatorService.copyIndicatorMarket,
    });

    // 複製事件
    const handleCopy = (data: IndicatorMarketModel | null) => {
        if(data) {
            if(data.year) {
                let copyTmp = {
                    indicatorMarketId: data.id,
                    year: data.year + 1
                }
                copyEvent(copyTmp);
            }
        } else {
            const checked = list.find(x=>x.checked === true);
            if(checked && checked.year) {
                const copyTmp = {
                    indicatorMarketId: checked.id,
                    year: checked.year + 1
                };
                copyEvent(copyTmp);
            }
        }
    };

    const copyEvent = (data: any) => {
        setListIsPending(true);
        copyIndicatorMarketMutation
        .mutateAsync(data)
        .then(p=>{
            setListIsPending(false);
            if(p.success){
                handleList(request);
                toast.success('success');
                setCheckCount(0);
            } else {
                toast.error(p.message);
            }
        })
        .catch((err) => {
            setListIsPending(false);
            console.log(err);
            toast.error(err);
        });
    }

    // #endregion

    // 篩選切換年度
    const handleYearChange = (year: string | null) => {
        let tmpreq = {...request};
        if(year) {
            tmpreq.year = parseInt(year, 10);
            setRequest(tmpreq);

        } else {
            tmpreq.year = null;
            setRequest(tmpreq);
        }
    };

    // 篩選切換市場別
    const handleMarketTypeChange = (type: string | null) => {
        let typeNum = 'null';
        if(type !== null) {
            typeNum = type;
        }
        let tmpreq = {...request};
        tmpreq.marketType = typeNum === 'null' ? null : parseInt(typeNum, 10);
        setRequest(tmpreq);

    }

    const handleDDIndustryChange = (industry: string | null, index: number, isAdd: boolean) => {
        if(isAdd === true) {
            if(newList[index] && industry !== null) {
                setNewList(newList.map((ck, i) => {
                        if(index === i){
                            ck.marketType = parseInt(industry, 10);
                        }
                        return ck;
                    }
                ));
            }
        } else {
            if(list[index] && industry) {
                setList(list.map((ck, i) => {
                    if(index === i){
                        ck.marketType = parseInt(industry, 10);
                    }
                    return ck;
                }));
            }
        }
    }

    function handleDDYearChange(year: string | null, index: number, isAdd: boolean) {
        if(isAdd === true) {
            if(newList[index] && year) {
                setNewList(newList.map((ck, i) => {
                        if(index === i){
                            ck.year = parseInt(year, 10);
                        }
                        return ck;
                    }));
            }
        } else {
            if(list[index] && year) {
                setList(list.map((ck, i) => {
                    if(index === i){
                        ck.year = parseInt(year, 10);
                    }
                    return ck;
                }));
            }
        }
    }

    const handleDropdownChange = (str: string | null) => {
        console.log(str);
    }

    // #endregion


  return (
    <div className={styles["indicator-list"]}>
      <div className="title">ESG指標管理</div>

      <div className="tool-box mt-20-p">
        <div className="tool-box">
        {!openCheck ? (
            <>
            <div className="mr-1-m">
              <Dropdown
                  placeholder={"市場別"}
                  options={options}
                  defaultId={request.marketType === null ? 'null' : request.marketType?.toString() }
                  onChange={handleMarketTypeChange}
                />
            </div>
            <div>
              <Dropdown
                  placeholder={"年度"}
                  options={queryYearOption}
                  defaultId={request.year === null ? null : request.year?.toString()}
                  onChange={handleYearChange}
                />
            </div>
            </>
            ): <>
            <div className="tool-box mt-10-p">
                <div className="sel-item">已選取{checkCount}項目</div>
                {detectEveryActionPermission([
                  IndicatorManageActionsPermissionCodes.IndicatorListUpdate,
                ]) ? (
                  <div className="icon-button">
                      <LinkIconButton
                          imgName={`${checkCount===1 ? 'copy-icon.svg' :'copy-lock-icon.svg'}`}
                          text="複製"
                          className={`${checkCount===1 ? '' :'lock'}`}
                          onClick={checkCount===1 ? () => handleCopy(null) : ()=>{}}
                          />
                  </div>
                ) : null}
                {detectEveryActionPermission([
                  IndicatorManageActionsPermissionCodes.IndicatorListDelete,
                ]) ? (
                  <div className="icon-button">
                  <LinkIconButton
                      imgName={`${checkCount>0 ? 'trash-icon.svg' :'trash-lock-icon.svg'}`}
                      text="刪除"
                      className={`${checkCount>0 ? '' :'lock'}`}
                      onClick={checkCount>0 ? ()=>handleRemove(null) : ()=>{}}
                      />
                  </div>
                ) : null}

            </div>
            </>
             }
        </div>

          <div className="tool-box">
          <button className="secondary mr-1-m" onClick={handleOpenCheckToggle}>
              {!openCheck ? '多筆編輯': '取消選取'}
            </button>
            {detectEveryActionPermission([
              IndicatorManageActionsPermissionCodes.IndicatorListCreate,
            ]) ? (
              <button className="default" onClick={()=>{
                  setNewList([
                      ...newList,
                      {
                      id: (newList.length++).toString(),
                      marketType: userInfo.accType === 1 ? 0 : 1,
                      year: null,
                      updateTime: null,
                      checked: false,
                      isEdit: false
                      }
                  ]);
                  setOpenCheck(false);
              }}>
                +新增
              </button>
            ) : null}

          </div>



      </div>

        {/** 主表 */}
        <div className="result-box scroll">
        {listIsPending === true ? <VisuallLoading /> : <></>}
            <div className="table-box">
                <table
                    aria-label="查詢結果table"
                    className="table-container sticky-table"
                >
                <thead>
                    <tr>
                    {openCheck ? (
                    <th style={{width: '50px'}} scope="col">
                        <label className="custom-checkbox">
                            <input
                                type="checkbox"
                                checked={checkAll}
                                onChange={() => handleAllCheckbox()}
                            />
                            <span className={`${checkCount>0 ? 'partial' :''}`}>&nbsp;</span>
                        </label>
                    </th>
                    ) : <></>}
                    <th scope="col">
                        市場別
                        <LinkIconButton
                          imgName="sort-icon.svg"
                          text={null}
                          className={'d-inline'}
                          onClick={handleTmpClick}
                        />
                    </th>
                    <th scope="col">
                        年度
                        <LinkIconButton
                          imgName="sort-icon.svg"
                          text={null}
                          className={'d-inline'}
                          onClick={handleTmpClick}
                        />
                    </th>
                    <th scope="col">
                        編輯日期
                        <LinkIconButton
                          imgName="sort-icon.svg"
                          text={null}
                          className={'d-inline'}
                          onClick={handleTmpClick}
                        />
                    </th>
                    <th scope="col" style={{width: '40px'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {/*status === "error" && <tr><td colSpan={5}>Error fetching data</td></tr>*/}
                    {/*status === "pending" && <tr><td colSpan={5}>Fetching data...</td></tr>*/}
                    {list.length === 0 ? <tr><td style={{textAlign: 'center'}} colSpan={5}>no data</td></tr> :
                    list.map((p: IndicatorMarketModel, i: number) => {
                    return (
                        <tr key={p.id} className="cur-pointer">
                            {/*開啟checkbox的td*/}
                            {openCheck ? (
                            <td>
                                <label className="custom-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={p.checked}
                                        onChange={()=>handleChkChange(p.id)}
                                    />
                                    <span>&nbsp;</span>
                                </label>
                            </td>
                            ) : <></>}
                            {/*市場別*/}
                            { openCheck || p.isEdit!==true ?
                                (<td onClick={()=>handleNavigate(p)}>{p.marketType === 0 ? '上市' : '上櫃'}</td>) :
                                  (<td><Dropdown
                                      isFloatTitle={false}
                                      placeholder={"市場別"}
                                      options={options2}
                                      defaultId={p.marketType?.toString()}
                                      onChange={(e) => handleDDIndustryChange(e, i, false)}
                                    /></td>)
                            }
                            {/*年度*/}
                            { openCheck || p.isEdit!==true ?
                                (<td onClick={()=>handleNavigate(p)}>{p.year}</td>) :
                                  (<td><Dropdown
                                    placeholder={"編輯年度"}
                                    isFloatTitle={false}
                                    options={yearOption}
                                    defaultId={p.year?.toString()}
                                    onChange={(e) => handleDDYearChange(e, i, false)}
                                    /></td>)
                            }
                            <td onClick={()=>handleNavigate(p)}>{p.updateTime? moment(p.updateTime).format('yyyy-MM-DD') : '-'}</td>
                            {!openCheck ? (
                          <td>
                            {
                              p.isEdit === true ? (
                                <>
                                  {detectEveryActionPermission([
                                    IndicatorManageActionsPermissionCodes.IndicatorListUpdate,
                                  ]) ? (
                                    /** 儲存與取消 */
                                    <div className="d-flex">
                                      <LinkIconButton
                                        imgName="check-icon.svg"
                                        text=""
                                        imgStyle={{ width: '24px', height: '24px' }}
                                        onClick={() => handleSave(i)}
                                      />
                                      <LinkIconButton
                                        imgName="cross-icon.svg"
                                        text=""
                                        imgStyle={{ width: '24px', height: '24px' }}
                                        onClick={() => handleEditToggle(i, false)}
                                      />
                                    </div>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  {detectEveryActionPermission([
                                    IndicatorManageActionsPermissionCodes.IndicatorListUpdate,
                                  ]) ? (
                                    /* 開啟編輯*/
                                    <LinkIconButton
                                      imgName="right-arrow.svg"
                                      text=""
                                      onClick={() => handleEditToggle(i, true)}
                                    />
                                  ) : null}
                                </>
                              )
                            }
                          </td>
                            ) :
                            (
                                <td>
                                    { /** 複製與刪除 */}
                                    <div className="d-flex">
                                    {detectEveryActionPermission([
                                      IndicatorManageActionsPermissionCodes.IndicatorListUpdate,
                                    ]) ? (
                                      <LinkIconButton
                                            imgName="copy-icon.svg"
                                            text=""
                                            className={'hover'}
                                            onClick={()=>handleCopy(p)}
                                            />
                                    ) : null}
                                    {detectEveryActionPermission([
                                      IndicatorManageActionsPermissionCodes.IndicatorListDelete,
                                    ]) ? (
                                      <LinkIconButton
                                            imgName="trash-icon.svg"
                                            text=""
                                            className={'hover'}
                                            onClick={() => handleRemove(p.id)}
                                        />
                                    ) : null}

                                    </div>
                                </td>
                            )}
                        </tr>
                    );
                    })}
                    {newList.map((n, i) => {
                    return (

                        <tr key={n.id} className="cur-pointer">
                            <td><Dropdown
                                    isFloatTitle={false}
                                    placeholder={"市場別"}
                                    options={options2}
                                    defaultId={n.marketType?.toString()}
                                    onChange={(e) => handleDDIndustryChange(e, i, true)}
                                    /></td>
                            <td>
                             <Dropdown
                                isFloatTitle={false}
                                placeholder={"新增年度"}
                                options={yearOption}
                                defaultId={n.year?.toString()}
                                onChange={(e) => handleDDYearChange(e, i, true)}
                                />
                            </td>
                            <td>-</td>
                            <td>
                              <div className="d-flex">
                                <LinkIconButton
                                  imgName="check-icon.svg"
                                  text=""
                                  imgStyle={{width: '24px', height: '24px'}}
                                  onClick={() => handleAdd(i)}
                                 />
                                <LinkIconButton
                                  imgName="cross-icon.svg"
                                  text=""
                                  imgStyle={{width: '24px', height: '24px'}}
                                  onClick={() => {
                                    setNewList(
                                        newList.filter(a =>
                                        a.id !== n.id
                                      )
                                    )}}
                                  />
                                </div>
                            </td>
                        </tr>
                    );
                    })}
                </tbody>
                </table>
            </div>
        </div>
        <div className="pad-10 page" style={{border: '1px solid #dbe8ef'}}>
            <TablePagination
                page={request.page}
                pageSize={request.pageSize}
                onChange={(e) => {

                    let tmpreq = {...request};
                    tmpreq.pageSize = e.pageSize;
                    tmpreq.page = e.page;
                    setRequest(tmpreq);
                }}
                total={count || 0}
            />
        </div>
        {/** 新增modal */}
        <ConfirmModal {...modal}>
          <div>
            <div>
              <Dropdown
                  placeholder={"市場別"}
                  options={options}
                  defaultId={"A"}
                  onChange={handleDropdownChange}
                />
            </div>
            <div className="mt-10-p">
              <Dropdown
                  placeholder={"新增年度"}
                  options={yearOption}
                  defaultId={"A"}
                  onChange={handleDropdownChange}
                />
            </div>
          </div>
        </ConfirmModal>

        {/* 確認刪除modal */}
        <ConfirmModal {...confirmModal}>
          <div>是否刪除?</div>
        </ConfirmModal>
    </div>
  );
};

export default List;
