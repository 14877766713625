import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useReducer,
} from "react";
import { useBlocker, useNavigate, Location } from "react-router-dom";
import styles from "./Prompt.module.scss";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";

const modalReducer = (
  state: ContentModalProps,
  action: { type: string; payload?: any }
): ContentModalProps => {
  switch (action.type) {
    case "TOGGLE":
      return { ...state, show: action.payload };
    default:
      return state;
  }
};

const useModalHandler = (initialState: ContentModalProps) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);

  const toggle = useCallback(
    (show: boolean) => dispatch({ type: "TOGGLE", payload: show }),
    []
  );

  return [state, toggle] as const;
};

const useNavigationInterceptor = (
  when: boolean,
  isConfirmNavigateRef: React.MutableRefObject<boolean>,
  setTargetLocation: React.Dispatch<React.SetStateAction<Location | null>>,
  toggleModal: (show: boolean) => void
) => {
  const blocker = useCallback(
    (tx: { currentLocation: Location; nextLocation: Location }) => {
      if (when && !isConfirmNavigateRef.current) {
        setTargetLocation(tx.nextLocation);
        toggleModal(true);
        return true;
      }
      return false;
    },
    [when, isConfirmNavigateRef, setTargetLocation, toggleModal]
  );

  useBlocker(useMemo(() => blocker, [blocker]));
};

interface PromptProps {
  when: boolean;
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const Prompt: React.FC<PromptProps> = ({
  when,
  message,
  onConfirm,
  onCancel,
}) => {
  const navigate = useNavigate();
  const [targetLocation, setTargetLocation] = useState<Location | null>(null);
  const isConfirmNavigateRef = useRef(false);

  const initialModalState = useMemo(
    () => ({
      show: false,
      size: "sm" as const,
      customClass: "prompt-modal",
      handleClose: () => {},
    }),
    []
  );

  const [modalProps, toggleModal] = useModalHandler(initialModalState);

  useNavigationInterceptor(
    when,
    isConfirmNavigateRef,
    setTargetLocation,
    toggleModal
  );

  const handleCancel = useCallback(() => {
    toggleModal(false);
    onCancel?.();
  }, [toggleModal]);

  const handleConfirm = useCallback(() => {
    toggleModal(false);
    if (targetLocation) {
      isConfirmNavigateRef.current = true;
      navigate(
        targetLocation.pathname + targetLocation.search + targetLocation.hash,
        { replace: true }
      );
      onConfirm?.();
      isConfirmNavigateRef.current = false;
    }
  }, [navigate, toggleModal, targetLocation]);

  return (
    <ContentModal {...modalProps} handleClose={handleCancel}>
      <div className={styles["modal-content"]}>
        <div className="content-box">
          <div className="reminder">{message}</div>
        </div>
        <div className="footer-box">
          <button className="secondary" onClick={handleCancel}>
            取消
          </button>
          <button className="default" onClick={handleConfirm}>
            確定
          </button>
        </div>
      </div>
    </ContentModal>
  );
};

export default Prompt;
