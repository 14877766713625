import { useEffect } from 'react';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const envParameter: { hosts: string[]; gtm?: string; gtag?: string }[] = [
  //- chase test (gtm -> chase)
  {
    hosts: ['localhost', 'twse.chase.com.tw'],
    gtm: 'GTM-NDBLVKL2',
  },
  //- twse test (gtm -> chase, gtag -> twse)
  {
    hosts: ['esggenplustest.twse.com.tw'],
    gtm: 'GTM-KQFFCLB8',
    gtag: 'G-27JWF9NEGD',
  },
  //- twse prod (gtm -> chase, gtag -> twse)
  {
    hosts: ['esggenplus.twse.com.tw'],
    gtm: 'GTM-W4M5FDLL',
    gtag: 'G-HLX3BQ6NK2',
  },
];

const host = window.location.hostname;
const currentEnv = envParameter.find((env) => env.hosts.includes(host));
const gtmId = currentEnv?.gtm;
const gtagId = currentEnv?.gtag;

export const GTM = () => {
  useEffect(() => {
    if (gtmId) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });

      const scriptContent = `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
          j.async=true; j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      `;

      const script = document.createElement('script');
      script.setAttribute('nonce', 'twse_gtm'); // 使用從後端獲取的 nonce 值
      script.textContent = scriptContent;
      document.head.appendChild(script);

      // Noscript remains the same
      const noscript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';
      noscript.appendChild(iframe);
      document.body.appendChild(noscript);
    } else {
      // console.error('GTM Init Error');
    }
  }, []);

  return null;
};

export const Gtag = () => {
  useEffect(() => {
    if (gtagId) {
      const gtagScript = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
      document.head.appendChild(gtagScript);

      const scriptContent = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gtagId}');
      `;

      const script = document.createElement('script');
      script.setAttribute('nonce', 'twse_gtag'); // 使用從後端獲取的 nonce 值
      script.textContent = scriptContent;
      document.head.appendChild(script);
    } else {
      // console.error('Gtag Init Error');
    }
  }, []);

  return null;
};

export const submitGAEvent = (eventName: string, submitEventObject?: any) => {
  if (!gtmId) {
    console.error('submitGAEvent Error');
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...submitEventObject,
  });
};
