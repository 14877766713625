import { DeclareDataAction } from "models/application/indicators/indicatorModel";
import { ListRequestModel } from "models/baseModel";

export interface CorrectionRequestSearchModel extends ListRequestModel {
    declareYear: number | null;
    requestType: RequestType | null;
    status: CorrectionStatus | null;
    companyInfo: string | null;
}

export interface CorrectionRequestModel {
    declareId: string;
    requestType: RequestType;
    applyCompany: string;
    applyAccount: string;
    applicantEmailList: string[];
    /** 更正原因說明 */
    correctionRequestReason: string;
    /** 更正狀態 */
    action:DeclareDataAction;
}

/** 匯入/匯出 excel modal */
export interface DeclareImportExcelModel{
    year:number;
    isImport: boolean;
}


export enum RequestType {
    /** 永續報告書 */
    SustainReport,
    /** 溫室氣體排放及減量 */
    GhgEmissionAndReduction,
    /** ESG資訊揭露 */
    ESGDisclosure,
}


export enum CorrectionStatus {
    /** 已申請未開通 */
    AppliedUnactivated,
    /** 已開通未申報 */
    ActivatedUndeclared,
    /** 已重新申報 */

    Declared,
}