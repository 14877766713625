import React, { FC, useEffect, useState } from "react";
import styles from "./SustainReportInfo.module.scss";
import TabVerticalThin from "components/base/TabVerticalThin/TabVerticalThin";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { NavBarItemModel, PageActionTyep } from "models/baseModel";
import BackTitle from "components/base/BackTitle/BackTitle";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import { useMutation } from "@tanstack/react-query";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import { SustainReportDeclareDataShowModel } from "models/application/sustainReportDeclare/sustainReportDeclareModel";
import SustainReportEnInfo from "../SustainReportEnInfo/SustainReportEnInfo";
import SustainReportTwInfo from "../SustainReportTwInfo/SustainReportTwInfo";
import { success2Alert } from "utils/otherToast";
import { DeclareStatus } from "models/application/indicators/indicatorModel";

interface SustainReportInfoProps {
  children?: React.ReactNode;
}

const SustainReportInfo: FC<SustainReportInfoProps> = (props) => {
  const params = useParams();
  const [queryParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [infoData, setInfoData] = useState<SustainReportDeclareDataShowModel>();
  const [defaultTab, setDefaultTab] = useState<string | undefined>(
    queryParams.get("version") || "tw"
  );
  // 編輯狀態
  const [action, setAction] = useState(PageActionTyep.View);
  const [dataConfirmModal, setDataConfirmModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setDataConfirmModal({
        ...dataConfirmModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "資料確認",
  });

  const [navItems, setNavItems] = useState<NavBarItemModel[]>([
    {
      name: "中文版報告書",
      key: "tw",
      url: `/apply/sustain-report/${params.id}/info?version=tw`,
      disabled: false,
    },
    {
      name: "英文版報告書",
      key: "en",
      url: `/apply/sustain-report/${params.id}/info?version=en`,
      disabled: false,
    },
  ]);

  useEffect(() => {
    // 如果沒給params導向對應頁面
    if (queryParams.size === 0) {
      setDefaultTab("tw");
      setSearchParams({ version: "tw" });
    }
  }, []);

  /** 回清單頁 */
  const handleBackList = () => {
    if (action === PageActionTyep.Edit) {
      setDataConfirmModal({
        ...dataConfirmModal,
        show: true,
        handleConfirm() {
          navigate("/apply/sustain-report");
          setDataConfirmModal({
            ...dataConfirmModal,
            show: false,
          });
        },
      });
    } else {
      navigate("/apply/sustain-report");
    }
  };

  // 取得編輯資訊
  const getGhgDeclareData = useMutation({
    mutationFn: () =>
      SustainReportDeclareService.getSustainReportDeclareData(params.id!),
    onSuccess: (x) => {
      if (x.success) {
        const newData: SustainReportDeclareDataShowModel = x.data;
        setInfoData({ ...newData });
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    getGhgDeclareData.mutate();
  }, []);


  const saveFinished = ()=>{
    getGhgDeclareData.mutate();
  }

  const changeItem = (item: NavBarItemModel) => {
    if (action === PageActionTyep.Edit) {
      setDataConfirmModal({
        ...dataConfirmModal,
        show: true,
        handleConfirm() {
          setDefaultTab(item.key);
          // 確認是不是為可以編輯狀態
          let status = item.key === 'tw' ? infoData?.twStatus: infoData?.enStatus;
          if(status !== DeclareStatus.Correcting &&
            status !== DeclareStatus.Declaring&&
            status !== DeclareStatus.UnDeclared
          ){
            // 未申報 申報中 更正申報 並非以上，改為view 的狀態
            setAction(PageActionTyep.View);
          }
          navigate(item.url);
          setDataConfirmModal({
            ...dataConfirmModal,
            show: false,
          });
        },
      });
    } else {
      setDefaultTab(item.key);
      navigate(item.url);
    }
  };
  return (
    <div className={styles["sustain-report-info-box"]}>
      {/*上方bar*/}
      <div className="title-box">
        <BackTitle
          url="/apply/sustain-report"
          title={`${infoData?.year ?? ''}年`}
          handleOtherEvent={true}
          changeItem={handleBackList}
        />
      </div>
      <div className={"sustain-content-box"}>
        {/* 左區塊 */}
        <div className="left-box">
          <TabVerticalThin
            data={navItems}
            tabKey={defaultTab!}
            handleOtherEvent={true}
            changeItem={changeItem}
          />
        </div>

        {/* 右區塊 */}
        <div className={"right-box"}>
          {defaultTab === "tw" ? (
            <SustainReportTwInfo
              loading={getGhgDeclareData.isPending}
              infoData={infoData}
              action={action}
              setAction={setAction}
              saveFinished={saveFinished}
            />
          ) : (
            <SustainReportEnInfo
              loading={getGhgDeclareData.isPending}
              infoData={infoData}
              action={action}
              setAction={setAction}
              saveFinished={saveFinished}
            />
          )}
        </div>
      </div>
      {/* 資料尚未儲存modal */}
      <ConfirmModal {...dataConfirmModal}>
        <div>資料尚未儲存，確定要離開嗎?</div>
      </ConfirmModal>
    </div>
  );
};

export default SustainReportInfo;
