import { ListRequestModel } from 'models/baseModel';
import { v4 as uuid } from 'uuid';

export interface QuestionnaireSettingListRequestModel extends ListRequestModel {
  years: number[] | undefined;
}

export interface QuestionnaireSettingModel {
  id: string;
  name: string;
  createTime: string;
  updateTime: string;
  isActive: boolean;
}

export interface QuestionnaireReplyListRequestModel extends ListRequestModel {
  year: number | undefined;
  questionnaireDataId: string | undefined;
  isDeclared: boolean | null;
}

export class QuestionnaireSettingDataModel {
  //- need
  id: string = '';
  dataType: number = 1;
  name: string = '';
  comment: string = '';
  isActive: boolean = true;
  hasMultipleData: boolean = false;
  controls: ControlModel[] = [];
  year: number | undefined;
  createTime: string | undefined;
  updateTime: string | undefined;
  //- unneed
  category: number | undefined;
  code: string | undefined;
  marketId: string | undefined;
  customerId: string | undefined;
  createrId: string | undefined;
  lang: string | undefined;
  //- web use
  valid_year: boolean = true;
  valid_name: boolean = true;
}

export class ControlModel {
  //- need
  id: string = '';
  required: boolean = false;
  sortIndex: number = 0;
  name: string = '';
  /** 前台顯示控制項名稱 */
  nameInMops: string = '';
  ctrType: string = '';
  isBlankSection: boolean = true;
  comment: string = '';
  hasImage: boolean = false;
  imageData: SampleControlFileModel | undefined;
  options: ControlOptionModel[] | undefined;
  unit: string | undefined;
  excludeNegative: boolean | undefined;
  digitNumber: number | undefined;
  matrix: MatrixModel | undefined;
  line: LineModel | undefined;
  //- unneed
  sourceControlId: string | undefined;
  targetControlId: string | undefined;
  isShow: boolean | undefined;
  isNotShowInMops: boolean | undefined;
  code: string | undefined;
  hasData: boolean | undefined;
  isCustomUnit: boolean | undefined;
  showSortIndex: number | undefined;
  sectionName: string | undefined;
  excelRowIndex: number | undefined;
  parentId: string | undefined;
  //- web use
  valid_name: boolean = true;
  valid_ctrType: boolean = true;
  valid_digitNumber: boolean = true;
  valid_control: boolean = true;
  isSectionCollapse: boolean = false;
  needRemove: boolean = false;
  isUploadImage: boolean = false;
  uniqueKey: string = uuid();
}

export class SampleControlFileModel {
  fileId: string = '';
  controlId: string = '';
  fileName: string = '';
  url: string = '';
}

export class FileCorrespondModel {
  controlUniqueKey: string = '';
  file: File | undefined;
}

export class ControlOptionModel {
  //- need
  id: string = '';
  text: string = '';
  skipControlId: string | undefined;
  followingControlIds: string[] | undefined;
  isExtraText: boolean | undefined;
  check: boolean | undefined;
  //- unneed
  code: string | undefined;
  enumKey: number | undefined;
  //- web use
  valid_text: boolean = true;
  dropdownOpen: boolean = false;
  uniqueKey: string = uuid();
}

export interface MatrixModel {
  rowTotal?: boolean;
  columnTotal?: boolean;
  rows: MatrixRowColumnModel[];
  columns: MatrixRowColumnModel[];
}

export interface MatrixRowColumnModel {
  id?: string;
  name: string;
  type: string;
  sortIndex: number;
  code?: string;
  controlId?: string;
}

export interface LineModel {
  id?: string;
  startNum: number | null;
  endNum: number | null;
  headTag: string | null;
  tailTag: string | null;
  nums?: number[];
}

//- reply
export interface QuestionnaireReplyModel {
  createTime: string;
  updateTime: string;
  companyCode: string;
  questionnaireName: string;
  companyName: string;
  questionnaireSampleId: string;
  isDeclared: boolean;
}

export interface QuestionnaireColValModel {
  dataId: string;
  colValModel: {
    title: string;
    value: string;
  }[];
  sampleId: string;
  sampleName: string;
  comment: string;
}

//* QuestionnaireStatusEnum
export enum QuestionnaireStatusEnum {
  //- 已儲存
  Saved,
  //- 已申報
  Declared,
}
