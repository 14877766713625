import { FC } from "react";
import { NavLink } from "react-router-dom";
import { SideBarItemModel } from "../../../models/baseModel";
import styles from "./SideBar.module.scss";
interface SideBarItemProps {
  item: SideBarItemModel;
}

const SideBarItem: FC<SideBarItemProps> = (props) => {
  const { item } = props;
  return (
    <li className={item.divideLine?"sidebar-li ":""}>
      {item.divideLine && <div className={"divide-line"}></div>}
      <NavLink to={`${item.url}`} 
      className={`sidebar-link `+
      `${item.composition ? item.composition: ''} `}>
        {item.revealText && (
          <div className="reveal-text">{item.revealText}</div>
        )}
        <img
          src={`/assets/images/sidebar/${item.icon}`}
          alt={item.moduleName}
        />
        <div className={"sidebar-text"}>{item.moduleName}</div>
      </NavLink>
    </li>
  );
};

export default SideBarItem;
