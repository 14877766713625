import { FC, useEffect, useRef, useState } from "react";
import styles from "./EsgIndicatorInfo.module.scss";
import SearchInput from "components/base/SearchInput/SearchInput";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BatchUploadModal, {
  BatchUploadModalProps,
} from "components/base/BatchUploadModal/BatchUploadModal";
import MultiSelect from "components/base/MultiSelect/MultiSelect";
import IndicatorDeclareService from "services/application/indicatorDeclare/indicatorDeclareService";
import { useMutation } from "@tanstack/react-query";
import useUserInfoStore from "state/useUserInfoStore";
import {
  DeclareDataAction,
  DeclareStatus,
  EsgDeclareInfoModel,
} from "models/application/indicators/indicatorModel";
import {
  CtrType,
  DelareItemTreeDetailModel,
  DelareItemTreeModel,
  SpecialControlModel,
} from "models/admin/indicatorModel";
import { CompanySampleDataFormModel } from "models/samples/companySampleDataModel/companySampleDataModel";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import Tip from "components/base/Tip/Tip";
import {
  CompanySampleControlValModel,
  ControlValSectionDetial,
  CustomerSampleDataControlValSectionModel,
} from "models/samples/companySampleDataModel/companySampleControlValModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { PageActionTyep, StreamFileType } from "models/baseModel";
import { error2Alert, error3Alert, success2Alert } from "utils/otherToast";
import {
  CorrectionRequestModel,
  DeclareImportExcelModel,
  RequestType,
} from "models/admin/declareDataMangeModel";
import NormalInput from "components/base/NormalInput/NormalInput";
import Dropdown from "components/base/Dropdown/Dropdown";
import NormalTextarea from "components/base/NormalTextarea/NormalTextarea";
import UpdateApplyModal, {
  UpdateApplyModalProps,
} from "components/otherModule/UpdateApplyModal/UpdateApplyModal";
import BackTitle from "components/base/BackTitle/BackTitle";
import { CommonService } from "services/common/commonService";
import PrincipleMatrixService from "services/admin/principleMatrixService";
import { ResponseCode } from "models/responseCodeModel";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import FloatCalculateService from "services/common/floatCalculateService";
import { SystemManageType } from "models/admin/systemManageModel";
import { PrintService } from "services/common/printService";
import { SampleControlCode } from "models/samples/sampleControlCode";
import { PrintElementType } from "models/common/printElementModel";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";
interface EsgIndicatorInfoProps {}

// 控制項
interface ControlProps {
  section: ControlValSectionDetial;
  control: CompanySampleControlValModel;
  controlIndx: number;
  /** 是否為必要揭露 */
  isNecessary: boolean;
}
// 樹狀
interface TreeItem {
  item: DelareItemTreeDetailModel;
}

const EsgIndicatorInfo: FC<EsgIndicatorInfoProps> = () => {
  const [queryParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // 實際section 畫面渲染資訊
  const [detailInfo, setDetailInfo] = useState<
    CustomerSampleDataControlValSectionModel[]
  >([]);
  const infoAllData = useRef<CompanySampleDataFormModel>();
  // 隱藏非必要區塊
  const [hide, setHide] = useState(false);
  // 編輯狀態
  const [action, setAction] = useState(PageActionTyep.View);
  // 使用者資訊
  const { userInfo } = useUserInfoStore();
  const [realTree, setRealTree] = useState<DelareItemTreeModel[]>([]);
  // 選擇樹狀節點
  const [selTreeItem, setSelTreeItem] = useState<DelareItemTreeDetailModel>();
  // 所有接題控制項id
  const allFollowingControlIds = useRef<string[]>([]);
  // 選擇接題的控制項id
  const selFollowingControlIds = useRef<string[]>([]);
  // 提醒訊息
  const [noticeMsg, setNoticeMsg] = useState<string>("");
  // 特殊控制向操作
  const specialControl = useRef<SpecialControlModel>();
  // 第一次編輯
  const firstEdit = useRef<boolean>(true);
  // 輸入框的ref(動態refer)
  const inputRefObj = useRef<{ [key: string]: HTMLInputElement }>({});
  const params = useParams();
  const [infoData, setInfoData] = useState<EsgDeclareInfoModel>();
  const [activeGenReporter, setActiveGenReporter] = useState<boolean>(false);
  const [activeDownloadMatrix, setDownloadMatrix] = useState<boolean>(false);
  // esg 總覽
  const [isOverView, setIsOverView] = useState<boolean>(true);
  const [errorModal, setErrorModal] = useState<ContentModalProps>({
    show: false,
    handleClose: () => closeErrorModal(),
    title: "指標匯入格式錯誤，請確認",
    size: "lg",
  });
  const [errorMsg, setErrorMsg] = useState<String>();

  const closeErrorModal = () => {
    treeMutation.mutate();
    setErrorModal((prev) => {
      return {
        ...prev,
        show: !prev.show,
      };
    });
  };

  /** 回清單頁 */
  const handleBackList = () => {
    if (action === PageActionTyep.Edit && !firstEdit.current) {
      setDataConfirmModal({
        ...dataConfirmModal,
        show: true,
        handleConfirm() {
          navigate("/apply/indicator");
          setDataConfirmModal({
            ...dataConfirmModal,
            show: false,
          });
        },
      });
    } else {
      navigate("/apply/indicator");
    }
  };

  const handleHide = () => {
    setHide(!hide);
  };

  const handleAction = (action: string) => {
    setAction(action);
  };

  //#region 取得基本資訊
  const baseInfoRefetch = () => {
    getDeclareInfo.mutate(params.id!);
  };

  const getDeclareInfo = useMutation({
    mutationFn: (id: string) => IndicatorDeclareService.getDeclareInfo(id),
    onSuccess: (res) => {
      if (res.code === ResponseCode.SuccessOK && res.success) {
        setInfoData({
          ...infoData,
          ...res.data,
        });
      } else {
        console.log(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // 一開始撈取資料 (只撈一遍)
  useEffect(() => {
    baseInfoRefetch();
    isActiveFunction(SystemManageType.SustainReport); //產製報告書功能檢查
    isActiveFunction(SystemManageType.MatrixDiagram); //永續準則矩陣下載功能檢查
  }, []);

  // 有基本資料後取得樹狀
  useEffect(() => {
    if (infoData) {
      treeMutation.mutate();
    }
  }, [infoData]);

  const { mutate: isActiveFunction } = useMutation({
    mutationFn: (type: SystemManageType) =>
      SustainReportDeclareService.isActiveFunction(type),
    onSuccess: (res, type) => {
      if (res.code === 200) {
        switch (type) {
          case SystemManageType.SustainReport:
            setActiveGenReporter(res.success);
            break;
          case SystemManageType.MatrixDiagram:
            setDownloadMatrix(res.success);
            break;
        }
      }
    },
    onError: (err) => {
      console.log("isActiveFunction", err);
    },
  });

  //#endregion

  //#region 上傳資料
  const uploadMutation = useMutation({
    mutationFn: IndicatorDeclareService.declareImportExcel,
  });

  // modal 物件(context confirm)
  const [modal, setModal] = useState<BatchUploadModalProps>({
    show: false,
    handleClose: () => {
      setModal({
        ...modal,
        show: false,
      });
    },
    handleUpload: (e) => {
      uploadFile(e);
    },
    sampleDownloadUrl: "",
    uploadLoading: false,
    isExternalSample: true,
  });

  /** 上傳檔案 */
  const uploadFile = (files: any[]) => {
    const postData = new FormData();
    for (const file of files) {
      postData.append("file", file);
    }
    setModal({
      ...modal,
      uploadLoading: true,
    });
    uploadMutation.mutate(
      {
        year: infoData?.year,
        file: postData,
      },
      {
        onSuccess: (x) => {
          if (x.success) {
            if (x.code === 500) {
              //let msgs = x.message.split('\n');
              setErrorMsg(x.message);
              setErrorModal({
                ...errorModal,
                show: true,
              });
              setModal({
                ...modal,
                uploadLoading: false,
              });
            } else {
              // 重撈樹狀
              treeMutation.mutate();
              setModal({
                ...modal,
                uploadLoading: false,
                show: false,
              });
              updateStatus();
              success2Alert(x.message);
            }
          } else {
            setModal({
              ...modal,
              uploadLoading: false,
            });
            error2Alert(x.message);
          }
        },
      }
    );
  };

  /** 更新狀態 */
  const updateStatus = () => {
    // 先取router
    // 更新儲存狀態
    applyMutation.mutate(
      {
        action: DeclareDataAction.Saved,
        declareId: params.id!,
        // 帶哪一個sampleId都可以 總覽不會有id所以固定帶樹第一筆
        sampleId: getSampleId(),
        indicatorMarketId: infoData?.indicatorMarketId,
      },
      {
        onSuccess: (res) => {
          baseInfoRefetch();
        },
      }
    );
  };

  /** 取得sampleId如果是總覽取第一筆 */
  const getSampleId = (): string => {
    // 總覽的時候帶入第一筆sammpleId
    // 先取router
    let tmpSampleId = queryParams.get("sampleId");
    if (!tmpSampleId) {
      if (realTree.length > 0) {
        let items = realTree[0].items;
        if (items && items.length > 0) {
          tmpSampleId = items[0].sampleId;
        }
      }
    }
    return tmpSampleId || "";
  };

  // 打開modal
  const handleOpenModal = () => {
    if (infoData) {
      setModal({
        ...modal,
        show: true,
        uploadLoading: false,
        // 更新新的方法進去，否則會出錯
        handleUpload(file) {
          uploadFile(file);
        },
      });
    } else {
      lostDataErr();
    }
  };

  // const downloadSample = ()=>{
  //   const model: DeclareImportExcelModel = {
  //     year: infoData.year,
  //     isImport: true,
  //   };
  //     getDeclareImportExcelExampleFileStream.mutate(model, {
  //       onSuccess: (res) => {
  //         if (res.data) {
  //           setModal({
  //             ...modal,
  //             show: true,
  //             sampleDownloadStream: res,
  //             // 更新新的方法進去，否則會出錯
  //             handleUpload(file) {
  //               uploadFile(file);
  //             },
  //           });
  //         }
  //       },
  //     });
  // }
  //#endregion

  //#region 樹狀component

  // 取得樹狀
  const treeMutation = useMutation({
    mutationFn: () =>
      IndicatorDeclareService.getDeclareItemTree({
        year: infoData?.year,
        companyCode: userInfo.companyCode,
      }),
    onSuccess: (x) => {
      if (x.success) {
        defaultTreeFlow(x.data);
      }
    },
  });

  // 預設樹狀相關流程
  const defaultTreeFlow = (copyTree: DelareItemTreeModel[]) => {
    copyTree.forEach((p) => {
      p.items?.forEach((item) => {
        initSection(item.sectionDatas, true);
      });
    });
    // 複製一份給真實的tree
    setRealTree([...copyTree]);

    // 設置第一筆 (改成先預覽全部) 有sampleId保留當前頁面
    if (copyTree.length > 0) {
      let allItems: DelareItemTreeDetailModel[] = [];
      copyTree.forEach((p) => {
        allItems = allItems.concat(p.items!);
      });

      setIsOverView(false);
      if (allItems.length > 0) {
        // 確認所有樹狀節點的資料(不包含非必需的)都填完了
        const disalbed = allItems
          .filter((p) => p.isNotNecessary === false)
          .some((p) => p.isWritten === false);
        setApplyBtnDisabled(disalbed);

        const sampleId = queryParams.get("sampleId");
        if (sampleId) {
          let defaultItem: DelareItemTreeDetailModel | undefined;
          // router 有參數，先帶參數的
          defaultItem = allItems.find((p) => p.sampleId === sampleId);
          // 有存在地端
          if (defaultItem) {
            clickTreeItem(defaultItem);
          }
        } else {
          // 帶第0筆
          clickTreeItem(allItems[0]);
        }
      }
    }
  };

  const filterTree = (key: string) => {
    const filterItem: DelareItemTreeModel[] = CommonService.deepClone(
      treeMutation.data?.data
    );
    if (filterItem) {
      if (key) {
        filterItem.forEach((p) => {
          p.items =
            p.items?.filter((item) => item.declareItemName?.includes(key)) ||
            [];
        });
      }
      setRealTree([...filterItem]);
    }
  };
  // 取得右側資訊
  const detailMutation = useMutation({
    mutationFn: IndicatorDeclareService.getDeclareItemDetail,
    onSuccess: (res) => {
      if (res.code === ResponseCode.SuccessOK && res.success) {
        const data: CompanySampleDataFormModel = res.data;
        if (data) {
          data.sectionDatas = initSection(data.sectionDatas, false);
          infoAllData.current = data;
          const originData = CommonService.deepClone(
            infoAllData.current.sectionDatas
          );
          // 初始特殊欄位
          initSpecialColumn(originData, infoAllData.current.sampleName);
        }
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // 更新隱藏
  const hideDetail = (id: string) => {
    setDetailInfo(
      detailInfo.map((item) => {
        item.sections.map((section) => {
          if (section.id === id) {
            section.hide = !section.hide;
          }
          return section;
        });
        return item;
      })
    );
  };

  // 點選item
  const clickTreeItem = (item: DelareItemTreeDetailModel) => {
    // 如果為第一次填寫直接進入編輯(canEdit情境) sampleDataId 都為null
    if (!item.sampleDataId && infoData?.canEdit) {
      setAction(PageActionTyep.Edit);
    } else {
      setAction(PageActionTyep.View);
    }
    // 第一次編輯
    firstEdit.current = true;
    setSelTreeItem({ ...selTreeItem, ...item });

    // 這邊是範本(尚未填寫資料並不一定有sampleDataId)
    queryParams.set("sampleId", item.sampleId!);
    setSearchParams(queryParams);
    detailMutation.mutate({
      sampleDataId: item.sampleDataId,
      sampleId: item.sampleId,
    });

    // 將scroll bar 移至頂端
    let doc = document.getElementById("form-box-data");
    if (doc) {
      doc.scrollTo({ top: 0 });
    }
  };

  /**
   * 初始section 資料
   * @param {CustomerSampleDataControlValSectionModel} sectionDatas 資料
   * @param {boolean} continueNum 鼓勵揭露接續流水號
   *  */
  const initSection = (
    sectionDatas: CustomerSampleDataControlValSectionModel[],
    continueNum: boolean
  ): CustomerSampleDataControlValSectionModel[] => {
    // 是否有特殊radio卡控 (放在賦予控制項值之後)
    let specialRadio = false;
    // 基礎資料
    sectionDatas.forEach((p: CustomerSampleDataControlValSectionModel) => {
      p.sections.forEach((section) => {
        section.hide = false;
        // 置放option
        section.controls?.forEach((control) => {
          // 特殊非必填
          control.isSpecialNotRequired = false;
          // 正數長度
          control.limitIntegerDigits = getPositiveLength(control);
          // 有效性
          control.valid = true;
          // 多選使用
          control.hideOptions = true;
          // 新增前端選單
          control.frontOptions =
            control.optionsVal?.map((op) => {
              return {
                id: op.id,
                text: op.text,
              };
            }) || [];

          control.displayValue = "";
          // 勾沒有資料
          if (control.hasNoDataChecked === true) {
            control.displayValue = `無資料：${control.extraValue}`;
          } else {
            // 編輯控制項 value的值
            switch (control.ctrType) {
              case CtrType.Radio:
              case CtrType.RadioLong:
              case CtrType.Select:
                const selItem = control.optionsVal?.find(
                  (p) => p.id === control.value
                );
                if (selItem) {
                  control.displayValue = selItem.extraTextValue
                    ? `${selItem.text}(${selItem.extraTextValue})`
                    : selItem.text;
                  // 選擇的接題題目id存入，重複的不distink
                  if (selItem.followingControlIds) {
                    selFollowingControlIds.current = [
                      ...selFollowingControlIds.current,
                      ...selItem.followingControlIds,
                    ];
                  }
                }
                break;
              case CtrType.Checkbox:
              case CtrType.SelectMulti:
                const selCheckItems = control.optionsVal?.filter(
                  (p) => p.check === true
                );
                if (selCheckItems) {
                  selCheckItems.forEach((item, index) => {
                    control.displayValue += item.extraTextValue
                      ? `${item.text}(${item.extraTextValue})`
                      : item.text;
                    if (index !== selCheckItems.length - 1) {
                      control.displayValue += ",";
                    }
                    if (item.followingControlIds) {
                      selFollowingControlIds.current = [
                        ...selFollowingControlIds.current,
                        ...item.followingControlIds,
                      ];
                    }
                  });
                }
                break;
              default:
                control.displayValue = control.value;
                break;
            }
          }

          if (control.optionsVal) {
            control.optionsVal?.forEach((op) => {
              // 所有的接題id都存起來
              if (op.followingControlIds && op.followingControlIds.length > 0) {
                allFollowingControlIds.current = [
                  ...allFollowingControlIds.current,
                  ...op.followingControlIds,
                ];
              }
            });
          }

          if (!specialRadio) {
            specialRadio = isSpecialRadioControl(control);
          }
        });
      });
    });

    let sectionSno = 0;
    // 設置disabled (如有接題) 和sno
    sectionDatas?.forEach((p: CustomerSampleDataControlValSectionModel) => {
      // 不接續section 重0開始
      if (!continueNum) {
        sectionSno = 0;
      }
      p.sections.forEach((section) => {
        sectionSno++;
        section.sectionSno = sectionSno;
        let questionSno = 0;

        section.controls?.forEach((control) => {
          control.disabled = false;
          questionSno++;
          control.questionSno = questionSno;

          // 在被接題disabled
          if (
            allFollowingControlIds.current.includes(control.sampleControlId)
          ) {
            // 但沒有被選過
            if (
              !selFollowingControlIds.current.includes(control.sampleControlId)
            ) {
              control.disabled = true;
            }
          }

          // 特殊disabled
          setSpecialDisabled(control);

          // 預設無資料disabled
          if (control.hasData && control.hasNoDataChecked) {
            control.disabled = true;
          }

          // 特殊radio 卡控是否連動 or 必填
          if (specialRadio) {
            setSpecialRadioControl(control);
          }
        });
      });
    });
    return sectionDatas;
  };

  // 樹狀item
  const TreeItem = (props: TreeItem) => {
    const { item } = props;
    // 基礎樹狀
    let selImages = "blue";
    if (selTreeItem?.sampleId === item.sampleId) {
      selImages = "white";
    }
    // 都非必揭露
    if (item.isNotNecessary) {
      if (item.isWritten) {
        selImages = "blue-not-necessary";
      } else {
        selImages = "grey-not-necessary";
      }
      if (selTreeItem?.sampleId === item.sampleId) {
        selImages = "white-not-necessary";
      }
    }
    return (
      <li
        className={selTreeItem?.sampleId === item.sampleId ? "active" : ""}
        onClick={($event) => {
          setIsOverView(false);
          // 編輯情境要跳出資訊
          if (action === PageActionTyep.Edit && !firstEdit.current) {
            setDataConfirmModal({
              ...dataConfirmModal,
              show: true,
              handleConfirm() {
                clickTreeItem(item);
                setDataConfirmModal({
                  ...dataConfirmModal,
                  show: false,
                });
              },
            });
          } else {
            clickTreeItem(item);
          }
        }}
      >
        {item.isWritten || item.isNotNecessary ? (
          <img
            className="check-img"
            alt=""
            src={`/assets/images/declaration/application/${selImages}-check-icon.svg`}
          />
        ) : (
          <div style={{ width: "21.6px" }}></div>
        )}
        <div>{item.declareItemName}</div>
      </li>
    );
  };

  // 樹狀資訊
  const TreeComponent = (item: DelareItemTreeModel[]) => {
    const images = ["global", "social", "governance"];
    return (
      <>
        {item?.map((category, index) => (
          <div key={category.category}>
            <div className={"tree-title"}>
              <img
                alt="類型"
                src={`/assets/images/declaration/application/${
                  images[category.category]
                }-icon.svg`}
              />
              <div>{category.categoryString}</div>
            </div>
            <ul>
              {category.items?.map((p, itenIndex: number) => {
                return <TreeItem key={p.sampleId} item={p} />;
              })}
            </ul>
          </div>
        ))}
      </>
    );
  };
  //#endregion

  //#region 確認申報
  // 是否可以按申報按鈕
  const [applyBtnDisabled, setApplyBtnDisabled] = useState(true);
  // modal 物件(confirm)
  const [applyModal, setApplyModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => handleCloseModal(),
    handleConfirm: () => {},
    title: "確認申報",
  });

  // 處理關閉
  const handleCloseModal = () => {
    setApplyModal((prev) => {
      return {
        ...prev,
        show: !prev.show,
      };
    });
  };

  // 確認申報
  const applyMutation = useMutation({
    mutationFn: IndicatorDeclareService.editEsgDeclareData,
  });

  // 處理申報事件
  const handleApplyModal = (type: DeclareDataAction) => {
    setApplyModal((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    applyMutation.mutate(
      {
        action: type,
        declareId: params.id!,
        sampleId: getSampleId(),
        indicatorMarketId: infoData?.indicatorMarketId,
      },
      {
        onSuccess: (x) => {
          setApplyModal((prev) => {
            return {
              ...prev,
              loading: false,
            };
          });
          if (x.success) {
            // 重新取基本資料
            baseInfoRefetch();
            success2Alert(x.message);

            setApplyModal((prev) => {
              return {
                ...prev,
                show: !prev.show,
              };
            });
          } else {
            error2Alert(x.message);
          }
        },
      }
    );
  };

  //#endregion

  //#region 更正申請
  const updateApplyMutation = useMutation({
    mutationFn: IndicatorDeclareService.addEsgDeclareDatacCrrectionRequest,
    onSuccess: (res) => {
      setUpdateModal({
        ...updateModal,
        loading: false,
        show: false,
      });
      if (res.success && res.code === ResponseCode.SuccessOK) {
        success2Alert(res.message);
        // 取得新的狀態
        baseInfoRefetch();
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [updateModal, setUpdateModal] = useState<UpdateApplyModalProps>({
    show: false,
    handleClose: () => {
      setUpdateModal({
        ...updateModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    requestType: RequestType.ESGDisclosure,
  });

  // 更正申請 model
  const [updateInfo, setUpdateInfo] = useState({
    applicantEmailList: [] as string[],
    correctionRequestReason: "",
  });

  // 處理modal更正申報
  const handleUpdateModal = (item: CorrectionRequestModel) => {
    setUpdateModal({
      ...updateModal,
      loading: true,
    });
    updateApplyMutation.mutate({
      ...item,
      declareId: params.id!,
    });
  };
  //#endregion

  //#region 匯出
  const getDeclareImportExcelExampleFileStream = useMutation({
    mutationFn: (model: DeclareImportExcelModel) =>
      IndicatorDeclareService.getDeclareImportExcelExampleFileStream(model),
    onError: (err) => {
      console.log(err);
    },
  });

  // 矩陣
  const getMatrixDiagram = useMutation({
    mutationFn: (year: number) => PrincipleMatrixService.getMatrixDiagram(year),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        success2Alert("匯出成功");
        CommonService.downloadByStream(res);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // 下載檔案
  const downloadSample = () => {
    if (infoData) {
      const model: DeclareImportExcelModel = {
        year: infoData.year,
        isImport: false,
      };
      getDeclareImportExcelExampleFileStream.mutate(model, {
        onSuccess: (res) => {
          if (res.status !== ResponseCode.ServerErrorInternal) {
            success2Alert("匯出成功");
            CommonService.downloadByStream(res);
          } else {
            error2Alert(res.data.message);
          }
        },
      });
    } else {
      lostDataErr();
    }
  };

  // 下載矩陣
  const downLoadMatrix = () => {
    if (infoData) {
      getMatrixDiagram.mutate(infoData.year);
    } else {
      lostDataErr();
    }
  };

  // 缺少資訊Err
  const lostDataErr = () => {
    error2Alert("缺少參數資訊");
  };
  //#endregion

  //#region 儲存/編輯數據
  const editDeclareMutation = useMutation({
    mutationFn: IndicatorDeclareService.editDeclareItemDetail,
    onError: (error) => {
      console.log(error);
      setNoticeConfirmModal({
        ...noticeConfirmModal,
        loading: false,
      });
    },
    onSuccess: (x) => {
      setNoticeConfirmModal({
        ...noticeConfirmModal,
        show: false,
        loading: false,
      });
      if (x.success) {
        handleAction(PageActionTyep.View);
        success2Alert(x.message);
        // 更新儲存狀態
        updateStatus();
      } else {
        error2Alert(x.message);
      }
    },
  });

  // 編輯申報
  const editDeclare = () => {
    saveState.current = false;
    // 傳畫面實際的資料給info儲存
    if (infoAllData.current) {
      // 是否填完
      infoAllData.current.isFinished = checkFilled();
      if (infoAllData.current.isFinished) {
        clearChoiceQuestionContent();
        checkSend();
      } else {
        setNoticeConfirmModal({
          ...noticeConfirmModal,
          handleConfirm: () => {
            clearChoiceQuestionContent();
            checkSend();
          },
          show: true,
        });
      }
    }
  };

  // 確認送出
  const checkSend = () => {
    if (infoAllData.current) {
      infoAllData.current.sectionDatas = detailInfo;
      infoAllData.current.controls = [];
      infoAllData.current.sectionDatas.forEach((p) => {
        p.sections.forEach((section) => {
          section.controls?.forEach((control) => {
            infoAllData.current?.controls?.push(control);
          });
        });
      });
      infoAllData.current.companyCode = userInfo.companyCode;
      editDeclareMutation.mutate(infoAllData.current);
    }
  };

  // 確認是否必填都有數值，傳給後端使用
  const checkFilled = (): boolean => {
    let pass = true;
    let msg = "";
    detailInfo.forEach((info) => {
      if (info.isNecessary) {
        info.sections.forEach((section) => {
          // 置放option
          section.controls?.forEach((control) => {
            if (control) {
              // 確認該欄位是否為必填
              const isRequired = followRequiredCondition(
                control,
                info.isNecessary
              );

              /**
               * 1.表單有勾可不用填資料 範本表單 => "control.hasData" = true
               * 2.isSkip 雖為必填但可跳過 => 特殊有code的api卡控
               * 3.hasNoDataChecked 實際表單有勾無資料 才會是true
               */
              if (control.hasNoDataChecked) {
                if (!control.extraValue) {
                  // (3) 選無資料但沒輸入文字
                  pass = false;
                  msg += getNotFillQuestion(section, control);
                }
              } else {
                if (isRequired && !control.isSkip) {
                  // (1) (2)
                  // 編輯控制項 value的值
                  switch (control.ctrType) {
                    case CtrType.Checkbox:
                    case CtrType.SelectMulti:
                      // 全部false 就
                      let allFalse = control.optionsVal?.every(
                        (op) => op.check === false
                      );
                      if (allFalse) {
                        pass = false;
                        msg += getNotFillQuestion(section, control);
                      } else {
                        control.optionsVal?.forEach((op) => {
                          // 有勾接其他輸入框，其他必須填好
                          if (op.check && op.isExtraText) {
                            if (!op.extraTextValue) {
                              pass = false;
                              msg += getNotFillQuestion(section, control);
                            }
                          }
                        });
                      }
                      break;
                    case CtrType.Radio:
                    case CtrType.RadioLong:
                    case CtrType.Select:
                      if (!control.value) {
                        pass = false;
                        msg += getNotFillQuestion(section, control);
                      } else {
                        // 有接其他輸入框，其他必須填好
                        let selItem = control.optionsVal?.find(
                          (p) => p.id === control.value
                        );
                        // 其他接塊文字需必填
                        if (selItem && selItem.isExtraText) {
                          if (!selItem.extraTextValue) {
                            pass = false;
                            msg += getNotFillQuestion(section, control);
                          }
                        }
                      }
                      break;
                    default:
                      // 只要一個沒過
                      if (!control.value) {
                        pass = false;
                        msg += getNotFillQuestion(section, control);
                      }
                      break;
                  }
                }
              }
            }
          });
        });
      }
    });

    if (!pass) {
      setNoticeMsg(msg);
    }
    return pass;
  };

  // 接題必填條件
  const followRequiredCondition = (
    control: CompanySampleControlValModel,
    isNecessary: boolean
  ) => {
    /**
     * 1.不是接題選項 且有必要揭露項目 且
     * 2.是否有在接題裡面(有被選) &&
     *
     */

    let required =
      (!allFollowingControlIds.current.includes(control.sampleControlId) &&
        isNecessary) ||
      (allFollowingControlIds.current.includes(control.sampleControlId) &&
        selFollowingControlIds.current.includes(control.sampleControlId));
    return required;
  };

  // 取得未填寫問題
  const getNotFillQuestion = (
    section: ControlValSectionDetial,
    control: CompanySampleControlValModel
  ): string => {
    return `${
      section.isBlankSection
        ? `${section.sectionSno}`
        : `${section.sectionSno}.${control.questionSno}`
    } ${control.name}\n`;
  };

  //#region 必填modal 提醒
  const [noticeConfirmModal, setNoticeConfirmModal] =
    useState<ConfirmModalProps>({
      show: false,
      handleClose: () => {
        setNoticeConfirmModal({
          ...noticeConfirmModal,
          show: false,
        });
      },
      handleConfirm: () => {},
      title: "尚未填寫的必要揭露指標",
    });
  //#endregion

  //#region  檢查表單有效性
  // form ref
  const formRef = useRef<any>(null);
  // 是儲存階段再進來
  const saveState = useRef<boolean>(false);

  // 檢查表單有效性
  const checkValid = (): boolean => {
    let pass = true;
    detailInfo.forEach((info) => {
      info.sections.forEach((section) => {
        // 置放option
        section.controls?.forEach((control) => {
          if (control.required) {
            if (control) {
              // 編輯控制項 value的值
              switch (control.ctrType) {
                case CtrType.Checkbox:
                case CtrType.SelectMulti:
                  control.valid = control.optionsVal?.some(
                    (op) => op.check === true
                  );
                  break;
                default:
                  if (!control.value) {
                    control.valid = false;
                  } else {
                    control.valid = true;
                  }
                  break;
              }
              // 一個沒過就失敗
              if (!control.valid) {
                pass = false;
              }
            }
          }
        });
      });
    });
    return pass;
  };

  // 更新dom資料結構
  useEffect(() => {
    if (action === PageActionTyep.Edit && saveState.current) {
      scrollToElement();
    }
  }, [detailInfo]);

  // 錯誤
  const scrollToElement = () => {
    // 找出第一個有invalid 屬性的元件
    const elements = formRef.current?.getElementsByClassName("invalid");

    if (elements && elements.length > 0) {
      // console.log(elements[0]);
      elements[0].scrollIntoView({ behavior: "smooth" });
    }
  };
  //#endregion

  // 單純傳值控制項
  const setControlVal = (
    orgControl: CompanySampleControlValModel,
    value: any
  ) => {
    setAlreadyEdit();
    saveState.current = false;
    // 清空全部已選接題
    selFollowingControlIds.current = [];
    // 賦予控制項值
    detailInfo.forEach((p) => {
      p.sections.forEach((section) => {
        section.controls?.forEach((control) => {
          if (control.sampleControlId === orgControl.sampleControlId) {
            //
            //#region 依類型決定是要調整option還是value
            switch (control.ctrType) {
              case CtrType.Label:
              case CtrType.Date:
              case CtrType.Number:
              case CtrType.Text:
              case CtrType.Textarea:
                control.value = value;
                break;
              case CtrType.Radio:
              case CtrType.RadioLong:
              case CtrType.Select:
                // 已經選擇原本的再點一次就是取消
                if (control.value === value) {
                  control.value = "";
                } else {
                  control.value = value;
                  if (value) {
                    control.optionsVal?.forEach((op) => {
                      op.check = false;
                      if (op.id === value) {
                        op.check = true;
                        if (op.followingControlIds) {
                          selFollowingControlIds.current = [
                            ...selFollowingControlIds.current,
                            ...op.followingControlIds,
                          ];
                        }
                      }
                    });
                  }
                }
                break;
              // checkbox
              case CtrType.Checkbox:
                if (value) {
                  // 如果已經勾過就在改回false
                  control.optionsVal?.forEach((op) => {
                    if (op.id === value) {
                      op.check = !op.check;
                      if (op.check === true) {
                        if (op.followingControlIds) {
                          selFollowingControlIds.current = [
                            ...selFollowingControlIds.current,
                            ...op.followingControlIds,
                          ];
                        }
                      }
                    }
                  });
                }
                break;
              // 多選
              case CtrType.SelectMulti:
                if (value) {
                  control.hideOptions = false;
                  control.optionsVal?.forEach((op) => {
                    op.check = false;
                    if (value.includes(op.id)) {
                      op.check = true;
                      if (op.followingControlIds) {
                        selFollowingControlIds.current = [
                          ...selFollowingControlIds.current,
                          ...op.followingControlIds,
                        ];
                      }
                    }
                  });
                }
                break;
              default:
                break;
            }
            //#endregion
          } else {
            //#region 計算原先已選
            switch (control.ctrType) {
              case CtrType.Radio:
              case CtrType.RadioLong:
              case CtrType.Select:
                const selItem = control.optionsVal?.find(
                  (p) => p.id === control.value
                );
                if (selItem) {
                  // 選擇的接題題目id存入，重複的不distinct
                  if (selItem.followingControlIds) {
                    selFollowingControlIds.current = [
                      ...selFollowingControlIds.current,
                      ...selItem.followingControlIds,
                    ];
                  }
                }
                break;
              case CtrType.Checkbox:
              case CtrType.SelectMulti:
                const selCheckItems = control.optionsVal?.filter(
                  (p) => p.check === true
                );
                if (selCheckItems) {
                  selCheckItems.forEach((item) => {
                    if (item.followingControlIds) {
                      selFollowingControlIds.current = [
                        ...selFollowingControlIds.current,
                        ...item.followingControlIds,
                      ];
                    }
                  });
                }
                break;
            }
            //#endregion
          }
        });
      });
    });

    // 是否為特殊radio卡控 (放在賦予控制項值之後)
    const specialRadio = isSpecialRadioControl(orgControl);

    // 設置disabled & 特殊radiod卡控 (如有接題)
    detailInfo.forEach((data) => {
      data.sections.forEach((section) => {
        section.controls?.forEach((c) => {
          c.isSpecialNotRequired = false;
          c.disabled = false;
          // 在被接題
          if (allFollowingControlIds.current.includes(c.sampleControlId)) {
            // 但沒有被選過
            if (!selFollowingControlIds.current.includes(c.sampleControlId)) {
              c.disabled = true;
            }
          }
          // 特殊disabled
          setSpecialDisabled(c);

          // 特殊radio 卡控是否連動 or 必填
          if (specialRadio) {
            setSpecialRadioControl(c);
          }
        });
      });
    });

    setDetailInfo([...detailInfo]);
  };

  // 清除選擇接題後的多餘內容
  const clearChoiceQuestionContent = () => {
    // 所有接題 - 已接 = 尚未接題控制項 (清空)
    const noneSelItems = allFollowingControlIds.current.filter(
      (p) => !selFollowingControlIds.current.includes(p)
    );

    detailInfo.forEach((p) => {
      p.sections.forEach((section) => {
        section.controls?.forEach((selControl) => {
          // 跳過特殊加總的 (把畫面上的尚未接跳的值清空)
          if (!jumpSpecialControl(selControl)) {
            // 包含再未被選擇的接題中(須清除內容)
            const includeNoneSelItem = noneSelItems.includes(
              selControl.sampleControlId
            );
            if (includeNoneSelItem) {
              clearControlItem(selControl);
            }
          }

          // 無勾無資料 hasData後台表單(可無資料意思) hasNoDataChecked前台有勾無資料這個radio
          if (selControl.hasData) {
            if (!selControl.hasNoDataChecked) {
              selControl.extraValue = "";
            } else {
              clearControlItem(selControl);
            }
          }
        });
      });
    });
  };

  // 清除item內容
  const clearControlItem = (control: CompanySampleControlValModel) => {
    // 清除一般控制項內容
    switch (control.ctrType) {
      case CtrType.Label:
      case CtrType.Date:
      case CtrType.Number:
      case CtrType.Text:
      case CtrType.Textarea:
        control.value = "";
        break;
      case CtrType.Radio:
      case CtrType.RadioLong:
      case CtrType.Select:
        control.optionsVal?.forEach((op) => {
          op.check = false;
          if (op.isExtraText) {
            op.extraTextValue = "";
          }
        });
        control.value = "";
        break;
      case CtrType.Checkbox:
      case CtrType.SelectMulti:
        control.optionsVal?.forEach((op) => {
          op.check = false;
          if (op.isExtraText) {
            op.extraTextValue = "";
          }
        });
        break;
    }
  };

  // 設定已經編輯過
  const setAlreadyEdit = () => {
    firstEdit.current = false;
  };
  //#endregion

  //#region 資料確認儲存
  const [dataConfirmModal, setDataConfirmModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setDataConfirmModal({
        ...dataConfirmModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "資料確認",
  });
  //#endregion

  //#region 控制項元件
  const ControlComponent = (props: ControlProps) => {
    const { section, control, controlIndx, isNecessary } = props;
    return (
      <div className="question-item">
        <div className="question">
          {/* 有無真實serction */}
          {section.name ? (
            <>
              {section.sectionSno}.{control.questionSno} {control.name}
            </>
          ) : (
            <>
              {section.sectionSno}.{control.name}
            </>
          )}
          {/* 1.不是接題選項 且有必要揭露項目 2.是否有在接題裡面(有被選) */}
          {followRequiredCondition(control, isNecessary) && (
            <span className="star">*</span>
          )}
          {control.hasData && (
            <div className="tip-box">
              <Tip text={"可無資料申報"} />
            </div>
          )}
        </div>
        {control.comment && (
          <div className="question-sub">{control.comment}</div>
        )}
        {/* 控制項 */}
        <div className="control">
          {/* 主要控制項 */}
          {control.ctrType === CtrType.Textarea && (
            <NormalTextarea
              isFloatTitle={false}
              disabled={control.disabled}
              valid={control.valid}
              unit={control.unit}
              defaultValue={control.value}
              onChange={(e) => {
                control.value = e;
                setAlreadyEdit();
              }}
              placeholder={""}
            />
          )}
          {(control.ctrType === CtrType.Number ||
            control.ctrType === CtrType.Date ||
            control.ctrType === CtrType.Text) && (
            <NormalInput
              id={control.sampleControlId}
              ref={(r) => {
                inputRefObj.current[control.sampleControlId] = r!;
              }}
              isPercent={control.unit?.includes("%")}
              isFloatTitle={false}
              disabled={control.disabled}
              type={control.ctrType}
              valid={control.valid}
              unit={control.unit}
              defaultValue={control.value}
              limitFloatDigits={control.digitNumber}
              limitIntegerDigits={control.limitIntegerDigits}
              canNegative={control.excludeNegative}
              debounceTime={100}
              onChange={(e) => {
                control.value = e;
                setSpecialCalculate(control);
              }}
            />
          )}
          {control.ctrType === CtrType.Label && (
            <NormalInput
              isFloatTitle={false}
              ref={(r) => {
                inputRefObj.current[control.sampleControlId] = r!;
              }}
              disabled={true}
              defaultValue={control.value}
              unit={control.unit}
              onChange={(e) => {}}
            />
          )}
          {control.ctrType === CtrType.SelectMulti && (
            <MultiSelect
              isFloatTitle={false}
              placeholder=""
              disabled={control.disabled}
              valid={control.valid}
              options={control.frontOptions}
              hideOptions={control.hideOptions}
              defaultIds={control.optionsVal
                ?.filter((p) => p.check === true)
                .map((p) => p.id)}
              onChange={(e) => {
                // 這邊會重新渲染畫面
                setControlVal(control, e);
              }}
            />
          )}
          {control.ctrType === CtrType.Select && (
            <>
              <Dropdown
                isFloatTitle={false}
                valid={control.valid}
                disabled={control.disabled}
                options={control.frontOptions}
                defaultId={control.value}
                onChange={(e) => {
                  setControlVal(control, e);
                }}
              />
              {/* 是否有文字框 */}
              {control.optionsVal?.map((op) => {
                if (op.id === control.value && op.isExtraText) {
                  return (
                    <div key={op.id} className="other-text-box w-100 mt-1">
                      <NormalInput
                        isFloatTitle={false}
                        defaultValue={op.extraTextValue}
                        onChange={(e) => {
                          op.extraTextValue = e;
                        }}
                      />
                    </div>
                  );
                }
              })}
            </>
          )}
          {(control.ctrType === CtrType.Radio ||
            control.ctrType === CtrType.RadioLong) && (
            <div
              className={
                `d-flex flex-wrap ` +
                `${control.disabled ? "disabled-block " : ""}`
              }
            >
              {control.optionsVal?.map((op) => {
                return (
                  <div key={op.id} className="d-flex">
                    <label
                      className={
                        `custom-radio ` + `${control.valid ? "" : "invalid "}`
                      }
                    >
                      <input
                        type="radio"
                        name={`radio_${control.sampleControlId}`}
                        value={op.id}
                        disabled={control.disabled}
                        defaultChecked={control.value === op.id}
                        onClick={(e) => {
                          setControlVal(control, op.id);
                        }}
                      />
                      <span>{op.text}</span>
                    </label>
                    {/* 是否有文字框 */}
                    {op.id === control.value && op.isExtraText && (
                      <div className="other-text-box">
                        <NormalInput
                          isFloatTitle={false}
                          defaultValue={op.extraTextValue}
                          disabled={control.disabled}
                          onChange={(e) => {
                            op.extraTextValue = e;
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {control.ctrType === CtrType.Checkbox && (
            <div
              className={
                `d-flex flex-wrap ` +
                `${control.disabled ? "disabled-block " : ""}`
              }
            >
              {control.optionsVal?.map((op) => {
                return (
                  <div key={op.id} className="d-flex">
                    <label className="custom-checkbox ">
                      <input
                        name={`check_${control.sampleControlId}`}
                        className={`${control.valid ? "" : "invalid "}`}
                        type="checkbox"
                        disabled={control.disabled}
                        value={op.id}
                        defaultChecked={op.check}
                        onChange={(e) => {
                          setControlVal(control, e.target.value);
                        }}
                      />
                      <span>{op.text}</span>
                    </label>
                    {/* 是否有文字框 */}
                    {op.check && op.isExtraText && (
                      <div className="other-text-box">
                        <NormalInput
                          isFloatTitle={false}
                          defaultValue={op.extraTextValue}
                          disabled={control.disabled}
                          onChange={(e) => {
                            op.extraTextValue = e;
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {/* 無資料 */}
          {control.hasData && (
            <div className="d-flex mt-3">
              <label
                className="custom-radio"
                style={{
                  minWidth: "90px",
                }}
              >
                <input
                  type="radio"
                  name={`radio_${control.sampleControlId}`}
                  value={1}
                  defaultChecked={control.hasNoDataChecked === true}
                  onClick={(e) => {
                    setNoData(section, control, true);
                  }}
                />
                <span>無資料</span>
              </label>
              <div className="w-100">
                <NormalTextarea
                  isFloatTitle={false}
                  placeholder="須填具原因說明(100字內)"
                  disabled={control.hasNoDataChecked !== true}
                  defaultValue={control.extraValue}
                  onChange={(e) => {
                    control.extraValue = e;
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  //#endregion

  //#region 特殊邏輯計算
  const initSpecialData = (): SpecialControlModel => {
    return {
      wasteHarmful: undefined,
      wasteHarmless: undefined,
      wasteTotal: undefined,
    };
  };
  // 初始化特殊欄位擷取
  const initSpecialColumn = (
    datas: CustomerSampleDataControlValSectionModel[],
    sampleName: string
  ) => {
    // datas and detailInfo 是同一個物件
    specialControl.current = initSpecialData();
    const sc = specialControl.current;
    const sampleArr = ["廢棄物管理"];
    if (sampleName) {
      if (sampleArr.includes(sampleName)) {
        datas.forEach((info) => {
          // 先找必填就好
          if (info.isNecessary) {
            info.sections.forEach((section) => {
              switch (sampleName) {
                case "廢棄物管理":
                  sc.wasteHarmful =
                    sc.wasteHarmful ??
                    getControlByCode(
                      SampleControlCode.HazardousWaste,
                      section.controls
                    );
                  sc.wasteHarmless =
                    sc.wasteHarmless ??
                    getControlByCode(
                      SampleControlCode.NonHazardousWaste,
                      section.controls
                    );
                  sc.wasteTotal =
                    sc.wasteTotal ??
                    getControlByCode(
                      SampleControlCode.WasteTotal,
                      section.controls
                    );
                  break;
              }
            });
          }
        });
      }
    }

    setDetailInfo(datas);
  };

  // 根據name 取得欄位
  const getControlByName = (
    name: string,
    controls: CompanySampleControlValModel[] | null
  ) => {
    if (!controls) {
      return undefined;
    }
    return controls?.find((p) => p.name === name);
  };

  // 根據code 取得欄位
  const getControlByCode = (
    code: string,
    controls: CompanySampleControlValModel[] | null
  ) => {
    if (!controls) {
      return undefined;
    }
    return controls?.find((p) => p.code === code);
  };

  // 特別計算
  const setSpecialCalculate = (orgControl: CompanySampleControlValModel) => {
    setAlreadyEdit();
    if (selTreeItem) {
      switch (selTreeItem.declareItemName) {
        case "廢棄物管理":
          wasteCal(orgControl);
          break;
      }
    }
  };

  // 跳過特殊項目(避免接跳題清空)
  const jumpSpecialControl = (
    control: CompanySampleControlValModel
  ): boolean => {
    // 指定code or 特殊不必填欄位
    const codes = [SampleControlCode.WasteTotal];
    return (
      codes.includes(control.code || "") ||
      control.isSpecialNotRequired === true
    );
  };

  //#region 廢棄物管理
  const wasteCal = (orgControl: CompanySampleControlValModel) => {
    switch (orgControl.code) {
      case SampleControlCode.HazardousWaste:
      case SampleControlCode.NonHazardousWaste:
        // 特殊不必填時，不連動
        if (!orgControl.isSpecialNotRequired) {
          const sc = specialControl.current;
          const harmfulVal = sc?.wasteHarmful?.value || "0";
          const harmlessVal = sc?.wasteHarmless?.value || "0";
          if (sc && sc.wasteTotal) {
            // 避免浮點數運算精度差 最後在toFixed
            console.log(orgControl.digitNumber);
            const value = FloatCalculateService.roundFloatingPoint(
              FloatCalculateService.addFloat(
                Number.parseFloat(harmfulVal),
                Number.parseFloat(harmlessVal)
              ),
              orgControl.digitNumber || 0
            );
            sc.wasteTotal.value = value?.toString();
            // 畫面元件的值
            inputRefObj.current[sc.wasteTotal.sampleControlId].value =
              value?.toString();
          }
        }
        break;
    }
  };
  //#endregion

  // 設定特殊欄位disabled
  const setSpecialDisabled = (control: CompanySampleControlValModel) => {
    // 後端的值賦予給前端(true 才去覆蓋)
    if (control.isDisabled) {
      control.disabled = control.isDisabled;
    }
    // if (selTreeItem) {
    //   let columnNames: string[] = [];
    //   switch (selTreeItem?.declareItemName) {
    //     case "溫室氣體排放":
    //       columnNames = ["溫室氣體管理之策略、方法、目標"];
    //       if (columnNames.includes(control.name)) {
    //         control.disabled = true;
    //       }
    //       break;
    //       case "人力發展":
    //         break;
    //   }
    // }
  };

  // 取得特殊radio 選項，接題欄位可非必填 且不連動計算相關欄位
  const isSpecialRadioControl = (
    control: CompanySampleControlValModel
  ): boolean => {
    // 已盤查（非製造業）
    // 廢棄物表單-> 廢棄物量盤查情形
    if (control.optionsVal && control.value) {
      let selRadioItem = control.optionsVal?.find(
        (p) => p.id === control.value
      );
      if (selRadioItem && selRadioItem.text === "已盤查（非製造業）") {
        // console.log('選中')
        return true;
      }
    }
    return false;
  };

  // 設定特殊radio 選項，接題欄位可非必填 且不連動計算相關欄位
  const setSpecialRadioControl = (control: CompanySampleControlValModel) => {
    if (
      control.code === SampleControlCode.HazardousWaste ||
      control.code === SampleControlCode.NonHazardousWaste
    ) {
      control.isSpecialNotRequired = true;
      // allFollowingControlIds.current =
      // allFollowingControlIds.current.filter(p=> p !== control.sampleControlId);
      selFollowingControlIds.current = selFollowingControlIds.current.filter(
        (p) => p !== control.sampleControlId
      );
    }
  };

  // 卡控正數長度
  const getPositiveLength = (control: CompanySampleControlValModel): number => {
    let len = 9;
    // 邏輯參考issue 23636
    switch (control.code) {
      case SampleControlCode.AnnualCompanyHeldMeetingsNumbers:
        len = 4;
        break;
      case SampleControlCode.BoardOfDirectorsNumbers:
      case SampleControlCode.BoardOfFemaleDirectorsNumbers:
      case SampleControlCode.BoardOfIndependentDirectorsNumbers:
        len = 2;
        break;
      case SampleControlCode.OccupationalInjuryEmployees:
        len = 5;
        break;
      default:
        len = 9;
        break;
    }
    return len;
  };

  //#endregion

  //#region 無資料的邏輯
  const setNoData = (
    orgSection: ControlValSectionDetial,
    orgControl: CompanySampleControlValModel,
    value: any
  ) => {
    let existData = false;
    setAlreadyEdit();
    detailInfo.forEach((info) => {
      if (!existData) {
        let sections = info.sections.filter((p) => p.id === orgSection.id);
        if (sections.length > 0 && !existData) {
          sections.forEach((p) => {
            let control = p.controls?.find(
              (con) => con.sampleControlId === orgControl.sampleControlId
            );
            if (control) {
              existData = true;
              if (control.hasNoDataChecked === value) {
                control.hasNoDataChecked = false;
              } else {
                control.hasNoDataChecked = true;
              }
              // 把該控制項disabled掉 保留輸入框的部分
              control.disabled = control.hasNoDataChecked;
            }
          });
        }
      }
    });
    setDetailInfo([...detailInfo]);
  };
  //#endregion

  // 取得section item 名稱
  const getSectionItemName = (section: ControlValSectionDetial) => {
    let str = `${section.sectionSno}.`;
    if (!section.isBlankSection) {
      str += section.name;
    } else {
      if (section.controls && section.controls.length > 0) {
        str += section.controls[0].name;
        if (section.controls[0].unit) {
          str += `(${section.controls[0].unit})`;
        }
      }
    }
    return str;
  };

  //#region 總覽
  const OverViewComponent: FC<{ data: DelareItemTreeModel[] }> = ({
    data = [],
  }) => {
    const images = ["global", "social", "governance"];
    return (
      <div id="esg-overview-form" className={"right-box overview-box"}>
        <div className="content-title-box">
          <div className="main-title">ESG資訊總覽-{infoData?.year}年度</div>
          <div className="content-tool">
            <LinkIconButton
              imgName="print-icon.svg"
              text="列印網頁"
              onClick={() => {
                PrintService.openNewWindow(
                  PrintElementType.EsgOverviewForm,
                  true
                );
              }}
            />
          </div>
        </div>
        {/* 總覽內容 */}
        <div className="overview-all-box scroll">
          {data.map((item) => {
            return (
              <div key={item.category} className="overview-content-box">
                <div className={"overview-title"}>
                  <img
                    alt="類型"
                    src={`/assets/images/declaration/application/${
                      images[item.category]
                    }-icon.svg`}
                  />
                  <div>{item.categoryString}</div>
                </div>
                {item.items?.map((info, index) => {
                  return (
                    <div
                      key={`${item.category}_${index}`}
                      className="overview-content"
                    >
                      <div className="form-name">{info.declareItemName}</div>
                      {info.sectionDatas.map((infoData, sectionIndex) => {
                        return (
                          <div key={sectionIndex} className="overview-item-box">
                            {/* 一般 與 鼓勵 */}
                            {infoData.sections.map((section, sectionIndex) => {
                              return (
                                <div key={section.id}>
                                  {/* 沒section 則不會有control name 而是合併 */}
                                  <div className="overview-section-item">
                                    {getSectionItemName(section)}
                                  </div>
                                  <div className="overview-item">
                                    {section.controls?.map(
                                      (control, controlIndx) => {
                                        return (
                                          <div
                                            key={control.sampleControlId}
                                            className="overview-question-item"
                                          >
                                            {!section.isBlankSection && (
                                              <div className="overview-question">
                                                {section.sectionSno}.
                                                {control.questionSno}{" "}
                                                {control.name}
                                                {control.unit
                                                  ? `(${control.unit})`
                                                  : ""}
                                              </div>
                                            )}
                                            <div className="overview-control">
                                              {control.displayValue
                                                ? control.displayValue
                                                : "-"}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  //#endregion

  //#region 點選總覽
  const clickOverview = () => {
    // 第一次編輯
    firstEdit.current = true;
    setIsOverView(true);
    // 移除queryParam
    queryParams.delete("sampleId");
    setSearchParams(queryParams);
    if (selTreeItem) {
      setSelTreeItem({
        ...selTreeItem,
        sampleId: "all",
      });
    }
  };
  //#endregion

  //#region 是否全部為鼓勵揭露
  const isAllNotNecessary = (): boolean => {
    const item = detailInfo.find((p) => p.isNecessary === true);
    if (item) {
      // 全部為鼓勵
      if (item.sections.length === 0) {
        return true;
      }
    }
    return false;
  };
  //#endregion

  return (
    <div className={styles["indcator-info-box"]}>
      {(treeMutation.isPending ||
        detailMutation.isPending ||
        editDeclareMutation.isPending ||
        getDeclareImportExcelExampleFileStream.isPending ||
        getMatrixDiagram.isPending ||
        applyMutation.isPending ||
        getDeclareInfo.isPending ||
        uploadMutation.isPending) && <VisuallLoading></VisuallLoading>}
      <div className="title-box">
        <BackTitle
          url="/apply/indicator"
          title={`${infoData?.year ?? ""}年`}
          handleOtherEvent={true}
          changeItem={handleBackList}
        />
        <div className="tool-box">
          <div className="mr-1-m">
            <LinkIconButton
              imgName={"export-icon.svg"}
              text={"匯出"}
              onClick={() => {
                downloadSample();
              }}
            />
          </div>
          {activeDownloadMatrix && (
            <div className="mr-1-m">
              <LinkIconButton
                imgName={"guideline-matrix-icon.svg"}
                text={"永續準則矩陣"}
                onClick={() => {
                  downLoadMatrix();
                }}
              />
            </div>
          )}
          {activeGenReporter && (
            <div className="mr-1-m">
              <LinkIconButton
                imgName={"report-generate-icon.svg"}
                text={"報告書產製"}
                onClick={() => {
                  navigate("/apply/report-generation/list");
                }}
              />
            </div>
          )}
          {infoData?.canEdit &&
            infoData?.status !== DeclareStatus.Declared &&
            infoData?.status !== DeclareStatus.ApplyForCorrection && (
              <div className="mr-1-m">
                <LinkIconButton
                  imgName={"batch-import-icon.svg"}
                  text={"批次匯入"}
                  onClick={handleOpenModal}
                />
              </div>
            )}
          <div className="d-flex">
            {(infoData?.status === DeclareStatus.Correcting ||
              infoData?.status === DeclareStatus.Declaring) && (
              <button
                className={
                  `secondary ` + `${applyBtnDisabled ? "disabled" : ""}`
                }
                onClick={() => {
                  setApplyModal({
                    ...applyModal,
                    show: !applyModal.show,
                    handleConfirm() {
                      handleApplyModal(DeclareDataAction.Declared);
                    },
                  });
                }}
              >
                確認申報
              </button>
            )}

            {/* 申報中or readOnly(過了申報時間) 才可以更正申請 */}
            {(infoData?.status === DeclareStatus.Declared ||
              infoData?.status === DeclareStatus.DeclareEnd) && (
              <button
                className="secondary"
                onClick={() => {
                  setUpdateModal({
                    ...updateModal,
                    declareStatus: infoData?.status,
                    show: true,
                  });
                  setUpdateInfo({
                    ...updateInfo,
                    applicantEmailList: [],
                    correctionRequestReason: "",
                  });
                }}
              >
                {infoData?.status === DeclareStatus.DeclareEnd
                  ? "申請開放"
                  : "更正申請"}
              </button>
            )}
          </div>
        </div>
      </div>
      {/* content 內容 */}
      <div className="indicator-content-box">
        {/* 左區塊 */}
        <div className="left-box">
          <div className="main-title">申報項目</div>
          <div className="mt-20-p">
            <SearchInput
              onClick={(e) => {
                filterTree(e);
              }}
            />
          </div>
          {realTree.length > 0 && (
            <div className={"tree-box scroll mt-20-p"}>
              {/* ESG資訊總覽 */}
              <div
                onClick={() => {
                  // 編輯情境要跳出資訊
                  if (action === PageActionTyep.Edit && !firstEdit.current) {
                    setDataConfirmModal({
                      ...dataConfirmModal,
                      show: true,
                      handleConfirm() {
                        clickOverview();
                        setDataConfirmModal({
                          ...dataConfirmModal,
                          show: false,
                        });
                      },
                    });
                  } else {
                    clickOverview();
                  }
                }}
                className={`overview-box ` + `${isOverView ? "active" : ""}`}
              >
                <img
                  className=""
                  alt="overview"
                  src={
                    `/assets/images/declaration/application/` +
                    `${
                      isOverView
                        ? "white-overview-icon.svg"
                        : "black-overview-icon.svg"
                    }`
                  }
                />
                <div>ESG資訊總覽</div>
              </div>
              {TreeComponent(realTree)}
            </div>
          )}
        </div>
        {/* 右區塊總覽 */}
        {isOverView && <OverViewComponent data={realTree} />}
        {/* 右區塊普通節點 */}
        {!isOverView && (
          <div className={"right-box"}>
            <div className="content-title-box">
              <div className="main-title">{selTreeItem?.declareItemName}</div>
              <div className="content-tool">
                {infoData?.canEdit &&
                  (infoData?.status === DeclareStatus.UnDeclared ||
                    infoData?.status === DeclareStatus.Declaring ||
                    infoData?.status === DeclareStatus.Correcting) && (
                    <>
                      {action === PageActionTyep.View ? (
                        <button
                          className="secondary min-w-fit"
                          onClick={() => {
                            handleAction(PageActionTyep.Edit);
                          }}
                        >
                          編輯
                        </button>
                      ) : (
                        <>
                          <button
                            className="secondary min-w-fit mr-1-m"
                            onClick={() => {
                              handleAction(PageActionTyep.View);
                              setDetailInfo(
                                CommonService.deepClone(
                                  infoAllData.current?.sectionDatas!
                                )
                              );
                            }}
                          >
                            取消
                          </button>
                          <button
                            className="default min-w-fit"
                            onClick={() => {
                              saveState.current = true;
                              editDeclare();
                            }}
                          >
                            儲存
                          </button>
                        </>
                      )}
                    </>
                  )}
              </div>
            </div>

            {/* 全部鼓勵的話不用顯示 */}
            {!isAllNotNecessary() && (
              <div className={"desc"}>
                <span className="star">*</span>為必填欄位
              </div>
            )}

            {/* 動態內容(編輯模式) */}
            <div id="form-box-data" ref={formRef} className="form-box scroll">
              {/* 編輯畫面 */}
              {action !== PageActionTyep.View && (
                <>
                  {detailInfo?.map((info, index) => {
                    return (
                      info.sections.length > 0 && (
                        <div key={index}>
                          <div className={"content"}>
                            {info.isNecessary
                              ? "必要揭露指標："
                              : "鼓勵揭露指標："}
                          </div>
                          {/* 隱藏線非揭露的 */}
                          {!info.isNecessary && (
                            <div className="hide-line-box" onClick={handleHide}>
                              <div className="line"></div>
                              <div className="hide-word-box">
                                <img
                                  alt=""
                                  src={
                                    hide
                                      ? "/assets/images/buttonIcon/add-icon.svg"
                                      : "/assets/images/buttonIcon/cut-icon.svg"
                                  }
                                />
                                <div>
                                  {hide ? "顯示" : "隱藏"}
                                  鼓勵揭露指標
                                </div>
                              </div>
                              <div className="line"></div>
                            </div>
                          )}
                          {/* 區塊名稱(只有非必要揭露的才可以縮合) */}
                          {(index === 0 || !hide) && (
                            <div className="section-box">
                              {info?.sections.map((section) => {
                                return (
                                  <div
                                    key={section.id}
                                    className={`block-box ${
                                      section.isBlankSection
                                        ? ""
                                        : "real-section"
                                    }`}
                                  >
                                    <div className={`block-name`}>
                                      {/* 延展 */}
                                      {!section.isBlankSection && (
                                        <img
                                          alt="extend"
                                          src="/assets/images/declaration/application/extend-up-icon.svg"
                                          className={section.hide ? "hide" : ""}
                                          onClick={() => {
                                            hideDetail(section.id);
                                          }}
                                        />
                                      )}
                                      {section.name && (
                                        <div>
                                          {section.sectionSno}.{section.name}
                                        </div>
                                      )}
                                    </div>
                                    {/* setion 隱藏 */}
                                    {!section.hide && (
                                      <div
                                        className={
                                          `question-box ` +
                                          `${
                                            !section.isBlankSection
                                              ? "scroll "
                                              : ""
                                          }`
                                        }
                                      >
                                        {section.controls?.map(
                                          (control, index) => {
                                            return (
                                              <ControlComponent
                                                key={`${section.id}_${index}`}
                                                section={section}
                                                control={control}
                                                controlIndx={index}
                                                isNecessary={info.isNecessary}
                                              />
                                            );
                                          }
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )
                    );
                  })}
                </>
              )}

              {/* preview內容 */}
              {action === PageActionTyep.View && (
                <div className="preview-box">
                  {detailInfo?.map((info, index) => {
                    return (
                      info.sections.length > 0 && (
                        <div key={index} className="preview-item">
                          <div className={"content"}>
                            {index === 0 ? "必要揭露指標：" : "鼓勵揭露指標："}
                          </div>
                          {info.sections.map((section, sectionIndex) => {
                            return (
                              <div key={sectionIndex}>
                                {/* 沒section 則不會有control name 而是合併 */}
                                <div className="section-item">
                                  {getSectionItemName(section)}
                                </div>
                                {section.controls?.map(
                                  (control, controlIndx) => {
                                    return (
                                      <div
                                        key={controlIndx}
                                        className="question-item"
                                      >
                                        {!section.isBlankSection && (
                                          <div className="question">
                                            <>
                                              {section.sectionSno}.
                                              {control.questionSno}{" "}
                                              {control.name}
                                              {control.unit
                                                ? `(${control.unit})`
                                                : ""}
                                            </>
                                          </div>
                                        )}
                                        <div className="control">
                                          {control.displayValue
                                            ? control.displayValue
                                            : "-"}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* 批次匯入彈窗 */}
      <BatchUploadModal
        {...modal}
        warningChildren={
          <div>請下載最新版本範例格式，再進行檔案上傳(目前版本：v2)</div>
        }
        handleDownloadExternalSample={() => {
          downloadSample();
        }}
      />
      {/* 確認申報modal */}
      <ConfirmModal {...applyModal}>
        <div>
          確認後不得再編輯資料，若申報完成後需修改，請至
          <span style={{ fontWeight: 700 }}>申報資料更正申請。</span>
        </div>
      </ConfirmModal>
      {/* 申報資料更正申請 */}
      <UpdateApplyModal
        {...updateModal}
        handleConfirm={(e) => {
          handleUpdateModal(e);
        }}
      />
      {/* 資料尚未儲存modal */}
      <ConfirmModal {...dataConfirmModal}>
        <div>資料尚未儲存，確定要離開嗎?</div>
      </ConfirmModal>
      {/* 以下為未填寫必填 資料 */}
      <ConfirmModal {...noticeConfirmModal}>
        <div className="notice-msg-box">{noticeMsg}</div>
      </ConfirmModal>
      <ContentModal {...errorModal}>
        <div
          style={{
            maxHeight: "200px",
            overflow: "auto",
            whiteSpace: "pre-line",
          }}
        >
          {errorMsg}
        </div>
      </ContentModal>
    </div>
  );
};

export default EsgIndicatorInfo;
