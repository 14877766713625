//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect } from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./Setting.module.scss";
import IconButton from "components/button/IconButton/IconButton";
import SearchInput from "components/base/SearchInput/SearchInput";
import Dropdown from "components/base/Dropdown/Dropdown";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import TablePagination from "components/base/TablePagination/TablePagination";
import { PaginationModel, ListRequestModel } from "models/baseModel";
import { OptionModel } from "models/baseModel";
import QuestionnaireService from "services/admin/questionnaireService";
import {
  QuestionnaireSettingModel,
  QuestionnaireSettingListRequestModel,
} from "models/admin/questionnaireModel";
import SwitchCheckbox from "components/base/SwitchCheckbox/SwitchCheckbox";
import { success2Alert, error2Alert } from "utils/otherToast";
import { usePermissionContext } from "context/PermissionProvider";
import { QuestionnaireManageActionsPermissionCodes } from "models/auth/permissionModel";
//* ---------------------------- local end ---------------------------- *//

const Setting: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const location = useLocation();
  const state_questionnaireListRequestModel: QuestionnaireSettingListRequestModel =
    location?.state?.questionnaireListRequestModel;
  const [declareYearOptions] = useState<OptionModel[]>(() => {
    const declareYearOptionsList = [];
    const today = new Date();
    for (let i = today.getFullYear(); i >= 2021; i--) {
      declareYearOptionsList.push({
        id: i.toString(),
        text: `${i.toString()} 年度`,
        enumKey: i,
      });
    }
    return [...declareYearOptionsList];
  });
  const [questionnaireListRequestModel, setQuestionnaireListRequestModel] =
    useState<QuestionnaireSettingListRequestModel>(
      state_questionnaireListRequestModel || {
        page: 1,
        pageSize: 10,
        searchKey: null,
        sortKey: null,
        sortType: undefined,
        years: [declareYearOptions[1].enumKey!],
      }
    );
  const [questionnaireList, setQuestionnaireList] = useState<{
    count: number;
    list: QuestionnaireSettingModel[];
  }>({
    count: 0,
    list: [],
  });
  const [tablePagination, setTablePagination] = useState<PaginationModel>({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [batchOperateList, setBatchOperateList] = useState<string[]>([]);
  const navigate = useNavigate();
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getQuestionnaireList
  const {
    mutate: getQuestionnaireListMutate,
    isPending: getQuestionnaireListIsPending,
  } = useMutation({
    mutationFn: (request: ListRequestModel) =>
      QuestionnaireService.getQuestionnaireSettingList(request),
    onSuccess: (res) => {
      console.log("getQuestionnaireList", res);
      if (res.code === 200 && res.success) {
        // success2Alert(res.message);
        setQuestionnaireList({
          count: res.count!,
          list: res.data,
        });
      } else {
        setQuestionnaireList({
          count: 0,
          list: [],
        });
        // error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("getQuestionnaireList", err);
      // error2Alert("執行失敗");
    },
  });
  //- editQuestionnaireSampleStatus
  const { mutate: editQuestionnaireSampleStatusMutate } = useMutation({
    mutationFn: (data: { id: string; active: boolean }) =>
      QuestionnaireService.editQuestionnaireSampleStatus(data),
    onSuccess: (res) => {
      console.log("editQuestionnaireSampleStatus", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        getQuestionnaireListMutate(questionnaireListRequestModel);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("editQuestionnaireSampleStatus", err);
      // error2Alert("執行失敗");
    },
  });
  //- copyQuestionnaireSettingData
  const {
    mutate: copyQuestionnaireSettingDataMutate,
    isPending: copyQuestionnaireSettingDataIsPending,
  } = useMutation({
    mutationFn: (id: string) =>
      QuestionnaireService.copyQuestionnaireSettingData(id),
    onSuccess: (res) => {
      console.log("copyQuestionnaireSettingData", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        getQuestionnaireListMutate(questionnaireListRequestModel, {
          onSuccess: () => setBatchOperateList([]),
        });
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("copyQuestionnaireSettingData", err);
      error2Alert("執行失敗");
    },
  });
  //- deleteQuestionnaireSettingData
  const {
    mutate: deleteQuestionnaireSettingDataMutate,
    isPending: deleteQuestionnaireSettingDataIsPending,
  } = useMutation({
    mutationFn: (idList: string[]) =>
      QuestionnaireService.deleteQuestionnaireSettingData(idList),
    onSuccess: (res) => {
      console.log("deleteQuestionnaireSettingData", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        getQuestionnaireListMutate(questionnaireListRequestModel, {
          onSuccess: () => setBatchOperateList([]),
        });
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("deleteQuestionnaireSettingData", err);
      error2Alert("執行失敗");
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  const handleStatusChange = (item: QuestionnaireSettingModel) => {
    editQuestionnaireSampleStatusMutate({
      id: item.id,
      active: !item.isActive,
    });
  };
  const navigateToEditPage = (questionnaireId: string) => {
    navigate(`${questionnaireId}/edit`, {
      state: {
        questionnaireListRequestModel: questionnaireListRequestModel,
      },
    });
  };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init
  // useEffect(() => {
  //   getDashboardDataMutate(selectDeclareYear.toString());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  //- selectDeclareYear
  useEffect(() => {
    getQuestionnaireListMutate(questionnaireListRequestModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireListRequestModel]);
  // -useEffect declareDataCorrectionRequestList
  useEffect(() => {
    setTablePagination((prev) => {
      if (questionnaireList?.list.length) {
        return {
          ...prev,
          page: questionnaireListRequestModel.page ?? 1,
          pageSize: questionnaireListRequestModel.pageSize ?? 10,
          total: questionnaireList.count,
        };
      } else {
        return {
          ...prev,
          page: 0,
          total: 0,
        };
      }
    });
  }, [questionnaireList, questionnaireListRequestModel]);
  //- useEffect isEdit
  useEffect(() => {
    if (!isEdit) {
      setBatchOperateList([]);
    }
  }, [isEdit]);
  //* ---------------------------- hook end ---------------------------- *//
  return (
    <div className={`${styles["questionnaire-box"]}`}>
      <div className={`${styles["operation-header"]}`}>
        <div className={`left`}>
          <div className={`select-item`}>
            <Dropdown
              placeholder={"年度"}
              defaultId={questionnaireListRequestModel.years![0].toString()}
              options={declareYearOptions}
              isFloatTitle={false}
              onChange={(e) => {
                setQuestionnaireListRequestModel((prev) => ({
                  ...prev,
                  years: [declareYearOptions.find((o) => o.id === e)?.enumKey!],
                }));
              }}
            />
          </div>
          <div className={`select-item`}>
            <SearchInput
              onClick={(e) => {
                setQuestionnaireListRequestModel((prev) => ({
                  ...prev,
                  searchKey: e,
                }));
              }}
              placeholder={"搜尋"}
            />
          </div>
        </div>
        <div className={`right`}>
          {batchOperateList.length ? (
            <>
              {batchOperateList.length === 1 ? (
                <>
                  {detectEveryActionPermission([
                    QuestionnaireManageActionsPermissionCodes.QuestionnaireSettingUpdate,
                  ]) ? (
                    <div className={`select-item`}>
                      <button
                        className="secondary"
                        onClick={() => {
                          copyQuestionnaireSettingDataMutate(
                            batchOperateList[0]
                          );
                        }}
                      >
                        複製
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}
              {detectEveryActionPermission([
                QuestionnaireManageActionsPermissionCodes.QuestionnaireSettingDelete,
              ]) ? (
                <div className={`select-item`}>
                  <IconButton
                    imgName="trash-icon.svg"
                    text="刪除"
                    className="secondary"
                    onClick={() => {
                      deleteQuestionnaireSettingDataMutate(batchOperateList);
                    }}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className={`select-item`}>
                <button
                  className="secondary"
                  onClick={() => {
                    setIsEdit(!isEdit);
                  }}
                >
                  多筆編輯
                </button>
              </div>
              {detectEveryActionPermission([
                QuestionnaireManageActionsPermissionCodes.QuestionnaireSettingCreate,
              ]) ? (
                <div className={`select-item`}>
                  <button
                    className="default"
                    onClick={() => {
                      navigateToEditPage("-1");
                    }}
                  >
                    +新增
                  </button>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
      <div
        className={`${styles["questionnaire-table-box"]} questionnaire-table-scroll scroll`}
      >
        {getQuestionnaireListIsPending ||
        copyQuestionnaireSettingDataIsPending ||
        deleteQuestionnaireSettingDataIsPending ? (
          <VisuallLoading />
        ) : null}
        <ScrollToTopButton
          targetClassName={"questionnaire-table-scroll"}
          bottom={90}
          right={35}
        />
        <table
          aria-label="table"
          className={`${styles["questionnaire-table"]} table-container sticky-table`}
        >
          <thead>
            <tr>
              {isEdit && (
                <th
                  scope="col"
                  className={`${styles["setting-checkbox"]}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <label className={`custom-checkbox hideText`}>
                    <input
                      type="checkbox"
                      checked={
                        questionnaireList.list.length ===
                        batchOperateList.length
                      }
                      onChange={() => {
                        if (
                          questionnaireList.list.length ===
                          batchOperateList.length
                        ) {
                          setBatchOperateList([]);
                        } else {
                          setBatchOperateList(
                            questionnaireList.list.map((e) => e.id!)
                          );
                        }
                      }}
                    />
                    <span
                      className={`${
                        batchOperateList.length > 0 &&
                        questionnaireList.list.length > batchOperateList.length
                          ? "partial"
                          : ""
                      }`}
                    ></span>
                  </label>
                </th>
              )}
              <th scope="col">名稱</th>
              <th scope="col">建立日期</th>
              <th scope="col">編輯日期</th>
              {detectEveryActionPermission([
                QuestionnaireManageActionsPermissionCodes.QuestionnaireSettingSetStatus,
              ]) ? (
                <th scope="col">狀態</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {questionnaireList.list?.length === 0 ? (
              <tr>
                <td
                  colSpan={
                    detectEveryActionPermission([
                      QuestionnaireManageActionsPermissionCodes.QuestionnaireSettingSetStatus,
                    ])
                      ? 4
                      : 3
                  }
                >
                  查無資料
                </td>
              </tr>
            ) : (
              questionnaireList.list?.map((item, index) => (
                <tr key={index} onClick={() => navigateToEditPage(item.id)}>
                  {isEdit && (
                    <td
                      className={`${styles["setting-checkbox"]}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <label className={`custom-checkbox hideText`}>
                        <input
                          type="checkbox"
                          checked={batchOperateList.includes(item.id!)}
                          onChange={() => {
                            if (batchOperateList.includes(item.id!)) {
                              setBatchOperateList((prev) =>
                                prev.filter((e) => e !== item.id!)
                              );
                            } else {
                              setBatchOperateList((prev) => [
                                ...prev,
                                item.id!,
                              ]);
                            }
                          }}
                        />
                        <span></span>
                      </label>
                    </td>
                  )}
                  <td>{item.name}</td>
                  <td>
                    {item.createTime
                      ? moment(item.createTime).format("yyyy-MM-DD")
                      : "-"}
                  </td>
                  <td>
                    {item.updateTime
                      ? moment(item.updateTime).format("yyyy-MM-DD")
                      : "-"}
                  </td>
                  {detectEveryActionPermission([
                    QuestionnaireManageActionsPermissionCodes.QuestionnaireSettingSetStatus,
                  ]) ? (
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        <SwitchCheckbox
                          checked={item.isActive}
                          text={item.isActive ? "啟用" : "關閉"}
                          onClick={() => {
                            handleStatusChange(item);
                          }}
                        />
                      </div>
                    </td>
                  ) : null}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className={`${styles["table-pagination-box"]}`}>
        <TablePagination
          total={tablePagination.total!}
          onChange={(e) => {
            setQuestionnaireListRequestModel((prev) => ({
              ...prev,
              page: e.page,
              pageSize: e.pageSize,
            }));
          }}
          page={tablePagination.page}
          pageSize={tablePagination.pageSize}
        />
      </div>
    </div>
  );
};

export default Setting;
