import React, { FC } from 'react';
import styles from './ProblemReportModal.module.scss';
import { Modal } from 'react-bootstrap';
import ProblemReportContent from 'components/otherModule/ProblemReportContent/ProblemReportContent';
import { useTranslation } from 'react-i18next';

export interface ProblemReportModalProps {
  show: boolean;
  handleClose: () => void;
  size?: "sm" | "lg" | "xl";
  loading?: boolean;
}

const ProblemReportModal: FC<ProblemReportModalProps> = (props) => 
{
  const {size, show, handleClose} = props;
  const {t} = useTranslation();
  return(
    <Modal
    className={styles['problem-report-box']}
    show={show}
    size={size}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
      <Modal.Title>
        {t('PROBLEM_REPORT')}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className={styles['problem-report-content-box']}>
        <ProblemReportContent/>        
      </div>
    </Modal.Body>
  </Modal>
)
};

export default ProblemReportModal;
