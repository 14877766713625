import { FC, useEffect, useState } from "react";
import styles from "./EsgIndicatorList.module.scss";
import { ListRequestModel, RequestType } from "models/baseModel";
import { Link, useNavigate } from "react-router-dom";
import { normalDate3 } from "utils/dateTimeFormat";
import {
  DeclareDataAction,
  DeclareStatus,
  EsgDeclareDataModel,
  IndicatorDelareInfoListModel,
} from "models/application/indicators/indicatorModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import TablePagination from "components/base/TablePagination/TablePagination";
import { useMutation } from "@tanstack/react-query";
import IndicatorDeclareService from "services/application/indicatorDeclare/indicatorDeclareService";
import { ResponseCode } from "models/responseCodeModel";
import { error2Alert, success2Alert } from "utils/otherToast";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import { CorrectionRequestModel } from "models/admin/declareDataMangeModel";
import UpdateApplyModal, {
  UpdateApplyModalProps,
} from "components/otherModule/UpdateApplyModal/UpdateApplyModal";
import { CommonService } from "services/common/commonService";
import useUserInfoStore from "state/useUserInfoStore";
import EmptySearchPage from "components/otherModule/EmptySearchPage/EmptySearchPage";
import ProblemReportContent from "components/otherModule/ProblemReportContent/ProblemReportContent";

interface EsgIndicatorListProps {}

export interface EsgIndicatorListModel {
  id: string;
  year: number;
  endDate: Date;
  firstDate: Date;
  updateDate: Date;
  status: string;
  isReadOnly: boolean;
}
const EsgIndicatorList: FC<EsgIndicatorListProps> = () => {
  const { userInfo } = useUserInfoStore();
  const [param, setParam] = useState<ListRequestModel>({
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortKey: null,
  });
  const navigate = useNavigate();
  const [indicatorData, setIndicatorData] = useState<
    IndicatorDelareInfoListModel[]
  >([]);
  // 有無公司資料
  const [companyDataWord, setCompanyDataWord] = useState<string | null>(null);

  useEffect(() => {
    // 無文字才能正常顯示
    let str = CommonService.getApplyDisplayWord(userInfo, "企業ESG資訊揭露");
    if (str) {
      setCompanyDataWord(str);
    } else {
      getDeclareInfoList.mutate(param);
    }
  }, []);

  const handleNavigate = (id: string) => {
    navigate(`/apply/indicator/${id}/info`);
  };

  //#region 取得基本資訊清單
  const getDeclareInfoList = useMutation({
    mutationFn: (model: ListRequestModel) =>
      IndicatorDeclareService.getDeclareInfoList(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: IndicatorDelareInfoListModel[] = res.data;
        setIndicatorData([...data]);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  //#endregion

  //#region 確認申報
  // modal 物件(confirm)
  const [applyModal, setApplyModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setApplyModal({
        ...applyModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "確認申報",
  });

  // 確認申報
  const applyMutation = useMutation({
    mutationFn: (model: EsgDeclareDataModel) =>
      IndicatorDeclareService.editEsgDeclareData(model),
    onSuccess: (res) => {
      setApplyModal({
        ...applyModal,
        show: false,
        loading: false,
      });
      if (res.success && res.code === ResponseCode.SuccessOK) {
        getDeclareInfoList.mutate(param);
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // 處理申報事件
  const handleApplyModal = (item: IndicatorDelareInfoListModel) => {
    setApplyModal({
      ...applyModal,
      loading: true,
    });
    applyMutation.mutate({
      action: DeclareDataAction.Declared,
      declareId: item.declareId,
      sampleId: null,
      indicatorMarketId: null
    });
  };

  //#endregion

  //#region 更正申請
  const updateApplyMutation = useMutation({
    mutationFn: (model: CorrectionRequestModel) =>
      IndicatorDeclareService.addEsgDeclareDatacCrrectionRequest(model),
    onSuccess: (res) => {
      setUpdateModal({
        ...updateModal,
        loading: false,
        show: false,
      });
      if (res.success && res.code === ResponseCode.SuccessOK) {
        getDeclareInfoList.mutate(param);
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [updateModal, setUpdateModal] = useState<UpdateApplyModalProps>({
    show: false,
    handleClose: () => {
      setUpdateModal({
        ...updateModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    requestType: RequestType.ESGDisclosure,
  });

  // 更正申請 model
  const [updateInfo, setUpdateInfo] = useState({
    applicantEmailList: [] as string[],
    correctionRequestReason: "",
  });

  // 處理modal更正申報
  const handleUpdateModal = (
    updateInfo: CorrectionRequestModel,
    item: IndicatorDelareInfoListModel
  ) => {
    setUpdateModal({
      ...updateModal,
      loading: true,
    });
    updateApplyMutation.mutate({
      ...updateInfo,
      declareId: item.declareId,
    });
  };
  //#endregion

  //#region 取得按鈕名稱
  const getBtnNameByStatus = (
    status: DeclareStatus,
    canEdit: boolean
  ): string => {
    if (!canEdit) {
      return "檢視";
    }
    let str = "";
    switch (status) {
      case DeclareStatus.Declared:
      case DeclareStatus.ApplyForCorrection:
      case DeclareStatus.DeclareEnd:
        str = "檢視";
        break;
      case DeclareStatus.Correcting:
      case DeclareStatus.Declaring:
        str = "修改";
        break;
      case DeclareStatus.UnDeclared:
      default:
        str = "開始申報";
        break;
    }
    return str;
  };
  //#endregion
  return (
    <div className="no-nav-content-box">
      {!companyDataWord ? (
        <div className={styles["indcator-list-box"]}>
          <div className="title mb-20-p">企業ESG資訊揭露申報作業</div>
          <div className="result-box scroll">
            <div className="table-box">
              {(updateApplyMutation.isPending || 
              getDeclareInfoList.isPending ||
              applyMutation.isPending) && (
                <VisuallLoading></VisuallLoading>
              )}
              <table
                aria-label="查詢結果table"
                className="table-container sticky-table"
              >
                <thead>
                  <tr>
                    <th scope="col">年度</th>
                    <th scope="col">最後編輯日期</th>
                    <th scope="col">首次申報日期</th>
                    <th scope="col">更正申報日期</th>
                    <th scope="col">狀態</th>
                    <th scope="col">功能</th>
                  </tr>
                </thead>
                <tbody>
                  {indicatorData?.map(
                    (p: IndicatorDelareInfoListModel, itemIndex: number) => {
                      return (
                        <tr key={p.declareId}>
                          <td>{p.year}</td>
                          <td>{normalDate3(p.lastEditTime)}</td>
                          <td>{normalDate3(p.firstDeclareTime)}</td>
                          <td>{normalDate3(p.modifiedDeclareTime)}</td>
                          <td>
                            <div
                              className={`status ${
                                p.declareStatus === "未申報"
                                  ? "no-declared"
                                  : ""
                              }`}
                            >
                              {p.declareStatus}
                            </div>
                          </td>
                          <td className="func-td">
                            <div className="func-btn-box">
                              <button
                                className="secondary min-w-fit"
                                onClick={() => {
                                  handleNavigate(p.declareId);
                                }}
                              >
                                {getBtnNameByStatus(p.status, p.canEdit)}
                              </button>
                              {(p.status === DeclareStatus.Correcting ||
                                p.status === DeclareStatus.Declaring) &&
                                p.isFinished && (
                                  <button
                                    className="secondary min-w-fit"
                                    onClick={() => {
                                      setApplyModal({
                                        ...applyModal,
                                        show: true,
                                        handleConfirm: () => {
                                          handleApplyModal(p);
                                        },
                                      });
                                    }}
                                  >
                                    確認申報
                                  </button>
                                )}
                              {(p.status === DeclareStatus.Declared ||
                              p.status === DeclareStatus.DeclareEnd) && (
                                <button
                                  className="secondary min-w-fit"
                                  onClick={() => {
                                    setUpdateInfo({
                                      ...updateInfo,
                                      applicantEmailList: [],
                                      correctionRequestReason: "",
                                    });
                                    setUpdateModal({
                                      ...updateModal,
                                      show: true,
                                      declareStatus: p.status,
                                      handleConfirm: (e) => {
                                        handleUpdateModal(e, p);
                                      },
                                    });
                                  }}
                                >
                                  {p.status === DeclareStatus.DeclareEnd?
                                  "申請開放":"更正申請"}
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="table-pagination mb-20-p">
            <TablePagination
              page={param.page}
              pageSize={param.pageSize}
              onChange={(e) => {
                const tmpReq = {
                  ...param,
                  ...e,
                };
                setParam(tmpReq);
                getDeclareInfoList.mutate(tmpReq);
              }}
              total={indicatorData?.length}
            />
          </div>
          <div className="desc">
            註：
            <br />
            1.上市(上櫃)公司應自111年起每年定期申報企業ESG資訊揭露，於111年申報110年之資料時，資料年度請輸入110年，並以110年全年度資料或年底之資料為申報資訊，以此類推。
            <div className="second-desc">
              <Link to={'/apply/propaganda'}>
              2. 「企業ESG資訊揭露」申報作業說明及適用問答
              </Link>
            </div>
            <br/>
            <ProblemReportContent/> 
          </div>
        </div>
      ) : (
        <EmptySearchPage
          className={"no-company-data-word"}
          word={companyDataWord}
        />
      )}
      {/* 確認申報modal */}
      <ConfirmModal {...applyModal}>
        <div>
          確認後不得再編輯資料，若申報完成後需修改，請至
          <span style={{ fontWeight: 700 }}>申報資料更正申請。</span>
        </div>
      </ConfirmModal>
      {/* 申報資料更正申請 */}
      <UpdateApplyModal {...updateModal} />
    </div>
  );
};

export default EsgIndicatorList;
