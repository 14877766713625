import React, { FC, useEffect, useState } from "react";
import styles from "./EmptyMessagePage.module.scss";
import EmptyWaitPage from "components/common/EmptyWaitPage/EmptyWaitPage";
import useEmptyMessageStore from "state/useEmptyMessageStore";
import { EmptyMessageType } from "models/baseModel";

interface EmptyMessagePageProps {}

const EmptyMessagePage: FC<EmptyMessagePageProps> = () => {
  // 根據store取得現在是哪種訊息
  const emptyStore = useEmptyMessageStore();
  const [message, setMessage] = useState("尚未登入，請透過SII系統重新登入");
  

  useEffect(() => {
    let msg = "";
    switch (emptyStore.messageType) {
      case EmptyMessageType.Logout:
        msg = "您已登出，請透過SII系統重新登入";
        break;
      case EmptyMessageType.Timeout:
        msg = "您的操作已逾時，請透過SII系統重新登入";
        break;
      case EmptyMessageType.NotLogin:
      default:
        msg = "尚未登入，請透過SII系統重新登入";
        break;
    }
    setMessage(msg);
  }, [emptyStore.messageType]);

  return <EmptyWaitPage text={message} />;
};

export default EmptyMessagePage;
