import React, { FC } from 'react';
import styles from './EduPropagandaManageRouter.module.scss';
import { usePermissionContext } from 'context/PermissionProvider';
import { ActionsPermissionCodes, FeaturePermissionCodes, PermissionNavBarItemModel, PermissionRouteObjectModel } from 'models/auth/permissionModel';
import CustomErrorBoundary from 'components/common/CustomErrorBoundary/CustomErrorBoundary';
import Navbar from 'components/layouts/Navbar/Navbar';
import { Outlet, useRoutes } from 'react-router-dom';
import PropagandaCategory from 'pages/Admin/EduPropagandaManage/PropagandaCategory/PropagandaCategory';
import PropagandaFile from 'pages/Admin/EduPropagandaManage/PropagandaFile/PropagandaFile';
import PropagandaFileInfo from 'pages/Admin/EduPropagandaManage/PropagandaFile/PropagandaFileInfo/PropagandaFileInfo';

//#region layout

const EduPropagandaManageLayout: FC = () => {
  //#region  參數
  const { PermissionState, PermissionService } =
    usePermissionContext();
  const { noPermissionPage } = PermissionState;
  const { detectEveryFeatruePermission } = PermissionService;
  const navItems: PermissionNavBarItemModel[] = [
    {
      name: "類型管理",
      url: "category",
      permission:
        FeaturePermissionCodes.EduPropagandaManage.EduResourceDirectory,
    },
    {
      name: "檔案管理",
      url: "file",
      permission: FeaturePermissionCodes.EduPropagandaManage.EduResourceFile,
    },
  ];
  const filterNavItems = navItems.filter((e) =>
    detectEveryFeatruePermission([e.permission])
  );

  //#endregion
  return (
    <>
      <CustomErrorBoundary>
        {filterNavItems?.length ? (
          <>
            {/* tab 資訊 */}
            <Navbar items={filterNavItems} />
            <div className="nav-content-box">
              <Outlet></Outlet>
            </div>
          </>
        ) : (
          noPermissionPage()
        )}
      </CustomErrorBoundary>
    </>
  );
};

//#endregion


//#region 主要元件
interface EduPropagandaManageRouterProps {}

const EduPropagandaManageRouter: FC<EduPropagandaManageRouterProps> = () =>
  {
    const { PermissionService } = usePermissionContext();
    const { permissionRouter } = PermissionService;
    const routers: PermissionRouteObjectModel[] = [
      {
        path: "",
        element: <EduPropagandaManageLayout />,
        children: [
          {
            path: "category",
            element: <PropagandaCategory />,
            featurePermission:
              FeaturePermissionCodes.EduPropagandaManage.EduResourceDirectory,
            actionReadPermission:
              ActionsPermissionCodes.EduPropagandaManage.EduPropagandaCategoryRead,
          },
          {
            path: "file",
            element: <PropagandaFile />,
            featurePermission:
              FeaturePermissionCodes.EduPropagandaManage.EduResourceFile,
            actionReadPermission:
              ActionsPermissionCodes.EduPropagandaManage.EduPropagandaFileRead,
          },
          {
            path: "file/:fileId/info",
            element: <PropagandaFileInfo />,
            featurePermission:
              FeaturePermissionCodes.EduPropagandaManage.EduResourceFile,
            actionReadPermission:
              ActionsPermissionCodes.EduPropagandaManage.EduPropagandaFileRead,
          },
        ],
      },
    ];

    return useRoutes(permissionRouter(routers));
}
//#endregion

export default EduPropagandaManageRouter;
