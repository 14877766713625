import React, { FC, useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  RouteObject,
  useParams,
  useRoutes,
} from "react-router-dom";
import EsgIndicatorList from "./EsgIndicatorList/EsgIndicatorList";
import EsgIndicatorInfo from "./EsgIndicatorInfo/EsgIndicatorInfo";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import ReportAnalysis from "./ReportAnalysis/ReportAnalysis";
import Navbar from "components/layouts/Navbar/Navbar";
import { NavBarItemModel } from "models/baseModel";
import { useMutation } from "@tanstack/react-query";
import IndicatorDeclareService from "services/application/indicatorDeclare/indicatorDeclareService";
import { ResponseCode } from "models/responseCodeModel";
import {
  DeclareStatus,
  EsgDeclareInfoModel,
} from "models/application/indicators/indicatorModel";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import { SystemManageType } from "models/admin/systemManageModel";
interface EsgIndicatorApplyRouterProps {}
const EsgIndicatorApplyLayout: FC = () => {
  let { id } = useParams();
  const [aiPermission, setAiPermission] = useState<boolean>(true);
  const orgNavItems: NavBarItemModel[] = [
    {
      key: "info",
      name: "申報作業",
      url: "info",
    },
    {
      key: "report-analysis",
      name: "ESG報告書解析",
      url: "report-analysis",
    },
  ];

  // 取得有的Tab資訊
  const initTabInfo = () => {
    // 預設一定有的
    const items = ["info"];
    return orgNavItems.filter((p) => p.key && items.includes(p.key));
  };

  const [navItems, setNavItems] = useState<NavBarItemModel[]>([...initTabInfo()]);

  useEffect(() => {
    if (id) {
      getDeclareInfo.mutate(id);
    }
  }, [id]);

  // 取得一次基本資訊
  const getDeclareInfo = useMutation({
    mutationFn: (id: string) => IndicatorDeclareService.getDeclareInfo(id),
    onSuccess: (res) => {
      if (res.code === ResponseCode.SuccessOK && res.success) {
        const data: EsgDeclareInfoModel = res.data;
        if (
          data.status === DeclareStatus.Declared ||
          data.status === DeclareStatus.DeclareEnd ||
          data.status === DeclareStatus.ApplyForCorrection
        ) {
          setNavItems([...initTabInfo()]);
        } else {
          // 確認不是這些狀態在打權限api確認
          isActiveFunction.mutate(SystemManageType.ESGReportAI);
        }
      } else {
        console.log(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // 取得特定權限
  const isActiveFunction = useMutation({
    mutationFn: (type: SystemManageType) =>
      SustainReportDeclareService.isActiveFunction(type),
    onSuccess: (res, type) => {
      if (res.code === ResponseCode.SuccessOK) {
        if(res.success){
          setNavItems([...orgNavItems]);
        }else{
          setNavItems([...initTabInfo()]);
        }
      }
    },
    onError: (err) => {
      setAiPermission(false);
      console.log("isActiveFunction", err);
    },
  });

  return (
    <>
      {/* tab 資訊 */}
      <Navbar items={navItems} />
      <div className="nav-content-box">
        <CustomErrorBoundary>
          <Outlet></Outlet>
        </CustomErrorBoundary>
      </div>
    </>
  );
};
const EsgIndicatorApplyRouter: FC<EsgIndicatorApplyRouterProps> = () => {
  // 創建router
  const routers: RouteObject[] = [
    {
      path: "",
      element: <EsgIndicatorList />,
    },
    {
      path: ":id",
      element: <EsgIndicatorApplyLayout />,
      children: [
        {
          path: "",
          element: <Navigate to={"info"} />,
        },
        {
          path: "info",
          element: <EsgIndicatorInfo />,
        },
        {
          path: "report-analysis",
          element: <ReportAnalysis />,
        },
      ],
    },
  ];
  // 相當於router
  let elements = useRoutes(routers);
  return <>{elements}</>;
};

export default EsgIndicatorApplyRouter;
