// 浮點數相加
const addFloat = (num1: number, num2: number) => {
    let r1, r2, m;
    try {
        r1 = num1.toString().split('.')[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = num2.toString().split('.')[1].length;
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (mulFloat(num1, m) + mulFloat(num2, m)) / m;
}

// 浮點數相減
const subFloat = (num1: number, num2: number) => {
    let r1, r2, m, n;
    try {
        r1 = num1.toString().split('.')[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = num2.toString().split('.')[1].length;
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = r1 >= r2 ? r1 : r2;
    return ((num1 * m - num2 * m) / m).toFixed(n);
}

// 浮點數相乘
const mulFloat = (num1: number, num2: number) => {
    let m = 0,
        s1 = num1.toString(),
        s2 = num2.toString();
    try {
        m += s1.split('.')[1].length;
    } catch (e) { }
    try {
        m += s2.toString().split('.')[1].length;
    } catch (e) { }

    return (
        (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
        Math.pow(10, m)
    );
}

// 浮點數相除
const divFloat = (num1: number, num2: number) => {
    let r1,
        r2,
        t1 = 0,
        t2 = 0;
    try {
        t1 = num1.toString().split('.')[1].length;
    } catch (e) { }
    try {
        t2 = num2.toString().split('.')[1].length;
    } catch (e) { }
    r1 = Number(num1.toString().replace('.', ''));
    r2 = Number(num2.toString().replace('.', ''));
    return (r1 / r2) * Math.pow(10, t2 - t1);
}

//* 浮點數轉換 如果超過幾位數 四捨五入
const roundFloatingPoint = (number: number, fixedTo: number): any => {
    try {
        let decimalString = '';
        //針對科學符號之處理
        if (number.toString().indexOf('e-') > -1) {
            let strNum = number.toFixed(fixedTo).replace(/\.?0+$/, "");
            decimalString = strNum.split('.')[1];
            //- 小數字串超過帶入的位數
            if (decimalString.length > fixedTo) {
                //- 四捨五入到帶入的位數
                return Number(number.toFixed(fixedTo).replace(/\.?0+$/, ""));
            } else {
                //- 小數字串沒有超過帶入的位數 回傳原本值
                return number.toFixed(decimalString.length).replace(/\.?0+$/, "");
            }
        } else {
            //- 小數字串
            decimalString = number.toString().split('.')[1];
            //- 小數字串超過帶入的位數
            if (decimalString !== undefined && decimalString.length > fixedTo) {
                //- 四捨五入到帶入的位數
                return Number(number.toFixed(fixedTo));
            } else {
                //- 小數字串沒有超過帶入的位數 回傳原本值
                return number;
            }
        }
    } catch (e) {
        //- 代表不是浮點數 回傳原本值
        return number;
    }
}


/** 是否為正數 含小數點 */
const isPositiveNum = (str: any): boolean => {
    if (typeof str === "string" && str) {
        // 正浮點數 or 正數
        return (/^[1-9]\d*\.\d*|0\.\d*[1-9]\d*$/.test(str) ||
            /^[0-9]*$/.test(str));
    } else {
        return false;
    }
}


/** 無條件捨去到小數第幾位 */
const roundDown = (num: number, decimal: number | null) => {
    if (decimal === null ||
        decimal === undefined) {
        return num;
    }
    return Math.floor((num + Number.EPSILON) * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

/** 檔案格式轉換size */
const formatFileSize = (bytes: number, fixPosition: number =2): string => {
    if (bytes === 0) {
        return '0 bytes'
    };
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(fixPosition)) + ' ' + units[i];
}

/** 移除開頭0的數值 */
const removeLeadingZeros = (input: string): string =>{
    if(input === '0'){
        return '0';
    }
    // 使用正則表達式移除前面的零 (不包含小數)
    return input.replace(/^0+(?=\d)/, '');
}


const FloatCalculateService = {
    addFloat,
    subFloat,
    mulFloat,
    divFloat,
    roundFloatingPoint,
    isPositiveNum,
    roundDown,
    formatFileSize,
    removeLeadingZeros
}

export default FloatCalculateService;