import React, { FC, useRef, useState } from "react";
import styles from "./Tip.module.scss";
import { Overlay } from "react-bootstrap";

interface TipProps {
  text: string;
  placement?:"top"| "bottom" | "left" | "right";
  imgName?: string;
}

const Tip: FC<TipProps> = ({placement="top", imgName= "tip-icon.svg", ...tipProps}) => {
  const [show, setShow] = useState(false);
  const imgBtn = useRef(null);
  return (
    <>
      <img
      ref={imgBtn}
        alt="tip"
        src={`/assets/images/buttonIcon/${imgName}`}
         onMouseEnter={() => {
          setShow(true);
          // console.log('enter')
        }}
        onMouseLeave={() => {
          setShow(false);
          // console.log('leave')

        }}
      />
      <Overlay placement={placement} show={show} target={imgBtn.current}>
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) =>
        <div
        {...props}
        className={styles["tip-box"]}>
          {tipProps.text}
          </div>}
      </Overlay>
    </>
  );
};

export default Tip;
