import axios, { AxiosResponse } from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { BaseResponse, EmptyMessageType } from "../models/baseModel";
import useUserInfoStore, { emptyUserInfo } from "../state/useUserInfoStore";
import { AccountType, AuthInfoModel } from "models/auth/authModel";
import { error2Alert, errorAlert } from "utils/otherToast";
import { redirectEntrance } from "utils/redirectExternal";
import useEmptyMessageStore from "state/useEmptyMessageStore";
import { ResponseCode } from "models/responseCodeModel";
import { CommonService } from "services/common/commonService";
import { resolve } from "path";
import StorageName from "constants/storageName";
import CryptoService from "services/common/cryptoService";

// axios instance
const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

interface AxiosInterceptorProps {
  children: React.ReactNode;
}

const AxiosInterceptor = (props: AxiosInterceptorProps) => {
  const { setMessageType } = useEmptyMessageStore();
  const { userInfo, setUserInfo } = useUserInfoStore();

  // 畫面渲染前先跑
  useLayoutEffect(() => {
    // 要求攔截器
    const reqInterceptor = (config: any) => {
      // console.log(userInfo)
      const token = userInfo?.accessToken;
      // 無token 就用空的
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        //
        config.headers.Authorization = null;
      }
      // 清空回傳錯誤的promise
      clearResErrorPromise();
      // 清空timeout
      clearTimeoutPromise();

      // 閒置時間流程判斷 (查詢前台不判斷 和 導向頁不判斷)
      if (CommonService.isExcludeApiRouter()) {
        // console.log('idle-flow')
        idleTimeFlow();
      }
      return config;
    };

    //#region 閒置時間判斷
    const idleTimeFlow = () => {
      // 紀錄api 時間
      let localStrTime = localStorage.getItem(StorageName.IdleTime);
      if (!localStrTime) {
        // 取現在有的時間
        let currentIsoTime = CommonService.toLocalISOString(new Date());
        localStorage.setItem(
          StorageName.IdleTime,
          CryptoService.encrypt(currentIsoTime)
        );
        console.log("currentIsoTime", currentIsoTime);
      } else {
        // 解密時間
        let newStrTime = CryptoService.decrypt(localStrTime);
        if (newStrTime) {
          // 將新時間覆蓋
          idleTimeDiffFlow(newStrTime);
        }
      }
    };
    const idleTimeDiffFlow = (localStrTime: string) => {
      if (localStrTime) {
        let idleTime = new Date(localStrTime);
        // 距離秒數
        let seconds = (new Date().getTime() - idleTime.getTime()) / 1000;
        console.log("idel-time-diff:", seconds + "s");
        // 預計30分鐘 1800
        if (seconds >= 1800) {
          setTimeout(() => {
            // 登出閒置
            localStorage.clear();
            sessionStorage.clear();
            setUserInfo(null);
            setMessageType(EmptyMessageType.Timeout);
            window.location.replace(`${CommonService.getWebsiteHost()}/empty`);
          }, 50);
        } else {
          // 儲存現在新的值
          localStorage.setItem(
            StorageName.IdleTime,
            CryptoService.encrypt(CommonService.toLocalISOString(new Date()))
          );
        }
      }
    };

    //#endregion

    //#region  回應攔截器 resInterceptor

    let timeoutPromise: any = null;
    const clearTimeoutPromise = () => {
      timeoutPromise = null;
    };
    // 登入逾時打api success code=> 440 (暫無使用)
    const resTimeoutResponseFlow = async (response: any, errorMsg: string) => {
      if (!timeoutPromise) {
        timeoutPromise = new Promise((resolve) => {
          resolve(response);
        }).then((val) => {
          setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            setMessageType(EmptyMessageType.Timeout);
            const url = CommonService.getWebsiteHost();
            window.location.replace(`${url}/empty`);
          }, 600);
          return val;
        });
        return timeoutPromise;
      }
    };

    // 回應攔截器
    const resInterceptor = async (response: AxiosResponse) => {
      // console.log(response);
      // if (response && response.data) {
      //   // 特殊卡控 api閒置時間
      //   if (response.data.code === ResponseCode.ClientErrorLoginTimeOut) {
      //     // console.log("response", response);
      //     return await resTimeoutResponseFlow(response, response.data.message);
      //   }
      // }

      // 不能直接在這邊取data (有部分是blob需要取header等資訊)
      return response;
    };
    //#endregion

    //#region 錯誤回傳流程
    let resErrorPromise: any = null;
    const clearResErrorPromise = () => {
      resErrorPromise = null;
    };

    // 多次promise 避免登打多次
    let refreshPromise: any = null;
    const clearRefreshPromise = () => {
      refreshPromise = null;
    };

    const errInterceptor = async (error: any) => {
      let errorMsg = "系統發生錯誤，請聯絡客服人員";
      try {
        const resData: BaseResponse<any> = {
          code: error.response.status,
          success: false,
          message: errorMsg,
          data: null,
        };
        // console.log("errInterceptor", error);
        switch (error.response.status) {
          case ResponseCode.ClientErrorBadRequest:
          case ResponseCode.ClientErrorNotFound:
          case ResponseCode.ClientErrorMethodNotAllowed:
          case ResponseCode.ServerErrorInternal:
            error.response.data = resData;
            return await errResponseFlow(error, errorMsg);
          case ResponseCode.ClientErrorUnauthorized:
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
              originalRequest._retry = true;
              try {
                if (!refreshPromise) {
                  // 取得refresh token
                  if (userInfo?.refreshToken) {
                    refreshPromise = http
                      .post("auth/token/refresh", {
                        refreshToken: userInfo?.refreshToken,
                        accId: userInfo.accId,
                      })
                      .then((res) => res.data)
                      .finally(clearRefreshPromise);
                  } else {
                    // 連refresh都沒
                    console.log("no refresh", userInfo);
                  }
                }
                const data = await refreshPromise;
                if (data) {
                  const token = data.data.accessToken;
                  const newInstance: AuthInfoModel = { ...userInfo };
                  newInstance.accessToken = token;
                  setUserInfo(newInstance);
                  // Retry the original request with the new token
                  originalRequest.headers.Authorization = `Bearer ${token}`;
                }

                // axios 為新的設置，與http不同，所以回傳要在設定一次自訂方法
                return await axios(originalRequest).then((x) => {
                  return resInterceptor(x);
                });
              } catch (error) {
                // Handle refresh token error or redirect to login
                console.log("Handle refresh token error", error);
                return await errResponseFlow(error, errorMsg);
              }
            }
            break;
          case ResponseCode.ClientErrorForbidden:
            // 沒有userInfo or company 是前台身分，其餘是後台設定
            if (!userInfo || userInfo.accType === AccountType.Company) {
              errorMsg = "連線失敗，請確認您的網路狀態或貴公司的防火牆設定";
            } else {
              errorMsg = "無此權限";
            }
            return await errResponseFlow(error, errorMsg);
        }

        // return Promise.reject(error);
      } catch (ex) {
        // server 完全異常情境下跑這邊
        console.log("api 連線異常", error);
        return await errResponseFlow(error, errorMsg);
      }
    };

    const errResponseFlow = async (error: any, errorMsg: string) => {
      // 取一筆錯誤api就好，避免多個錯誤訊息
      if (!resErrorPromise) {
        resErrorPromise = new Promise((reslove, reject) => {
          error2Alert(errorMsg);
          reject(error);
        });
        return resErrorPromise;
      }
    };
    //#endregion

    // 攔截器request套用
    const interceptorReq = http.interceptors.request.use(
      reqInterceptor,
      (error) => {
        console.log("interceptor req error", error);
        return Promise.reject(error);
      }
    );

    // 攔截器回應套用
    const interceptorRes = http.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    // 移除產生的instance
    return () => {
      http.interceptors.request.eject(interceptorReq);
      http.interceptors.response.eject(interceptorRes);
    };
  }, [userInfo]);

  return <>{props.children}</>;
};

export default http;
export { AxiosInterceptor };
