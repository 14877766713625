import { FC, useEffect, useRef, useState } from "react";
import styles from "./Edit.module.scss";
import { NavBarItemModel, OptionModel } from "models/baseModel";
import CustDropdown from "components/base/Dropdown/Dropdown";
import NormalInput from "components/base/NormalInput/NormalInput";
import IconButton from "components/button/IconButton/IconButton";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { ReactSortable } from "react-sortablejs";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BackTitle from "components/base/BackTitle/BackTitle";
import TabVerticalThin from "components/base/TabVerticalThin/TabVerticalThin";
import { useMutation } from "@tanstack/react-query";
import IndicatorService from "services/admin/indicatorService";
import { IndicatorCategoriesModel, IndicatorControlModel, IndicatorControlOptionModel, IndicatorDetailModel, IndicatorModel } from "models/admin/indicatorModel";
import toast from "react-hot-toast";
import { spawnSync } from "child_process";
import Dropdown from 'react-bootstrap/Dropdown';
import { v4 as uuid } from "uuid";
import ConfirmModal, { ConfirmModalProps } from "components/base/ConfirmModal/ConfirmModal";
import ContentModal from "components/base/ContentModal/ContentModal";
import { usePermissionContext } from "context/PermissionProvider";
import { IndicatorManageActionsPermissionCodes } from "models/auth/permissionModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";


const Edit: FC = () => {

  // #region 變數
  const params= useParams();
  const [categories, setCategories] = useState<OptionModel[]>([]);
  const [ctrOption, setCtrOption] = useState<OptionModel[]>([]);
  const [formData, setFormData] = useState<IndicatorDetailModel>({
    id: null,
    dataType: 0,
    category: null,
    name: null,
    isActive: true,
    marketId: params.id || null,
    controls: []
  });
  const [list, setList] = useState<IndicatorControlModel[]>([]);
  const [navItems, setNavItems] = useState<NavBarItemModel[]>([
    {
      name: "指標設定",
      key: 'edit',
      url: `/admin/indicator/${params.id}/setting/${params.eid}/edit`,
      disabled: false
    },
    {
      name: "必要揭露指標設定",
      key: 'disclosureSetting',
      url: `/admin/indicator/${params.id}/setting/${params.eid}/disclosureSetting`,
      disabled: params.eid === '-1' ? true : false
    }
  ]);
  const [modal, setModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => handleCloseModal(),
    handleConfirm: () => handleConfirmModal(),
    title: "接題",
  });
  const [ctrSkip, setCtrSkip] = useState<IndicatorControlModel>();
  const [ctrOptionSkip, setCtrOptionSkip] = useState<IndicatorControlOptionModel>();
  const [isSkip, setIsSkip] = useState<boolean | null>(false);
  const formRef = useRef<any>(null);
  const lineOptions: OptionModel[] = [{
    id: '1',
    text: '1'
  },{
    id: '2',
    text: '2'
  },{
    id: '3',
    text: '3'
  },{
    id: '4',
    text: '4'
  },{
    id: '5',
    text: '5'
  },{
    id: '6',
    text: '6'
  },{
    id: '7',
    text: '7'
  },{
    id: '8',
    text: '8'
  },{
    id: '9',
    text: '9'
  },{
    id: '10',
    text: '10'
  }]
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;
  // #endregion
  const location = useLocation();
  const navigate = useNavigate();
  /*const handleItem = (url: string) => {
    setTabName(url);
    navigate(url);
  };*/


  // #region CRUD

  // 取得類別
  const GetCategoriesOptionsMutation = useMutation({
    mutationFn: IndicatorService.indicatorOption,
  });

  // 取得類別清單
  const categoriesList = () => {
    GetCategoriesOptionsMutation
    .mutateAsync()
    .then(p=>{
        if(p.success){
          let tmp: OptionModel[] = [];
          p.data.categories.forEach((c: IndicatorCategoriesModel) => {
            tmp.push({
              id: c.enumKey.toString(),
              text: c.text
            });
          });
          setCategories(tmp);
          let tmp2: OptionModel[] = [];
          p.data.controls.forEach((c: IndicatorCategoriesModel) => {
            tmp2.push({
              id: c.code,
              text: c.text
            });
          });
          setCtrOption(tmp2);
        } else {
          toast.error('error');
        }
    })
  };

  // 取得表單資訊
  const getFormMutation = useMutation({
    mutationFn: IndicatorService.getIndicator,
  });

  // 取得類別清單
  const getForm = () => {
    if(params.eid !== undefined) {
      getFormMutation
        .mutateAsync(params.eid)
        .then(p=>{
            if(p.success){
              setFormData(p.data);
              let independParentId = '';
              p.data.controls.forEach((ctr: IndicatorControlModel, i:number) => {
                ctr['followChecked'] = false;
                ctr.showSortIndex = 1;
                ctr.isSectionOpen = true;
                ctr.isSectionCtrOpen = true;
                if(ctr.ctrType === 'section' && ctr.isBlankSection===true) {
                  independParentId = ctr.id;
                }
                //獨立的control
                if(ctr.id && ctr.parentId === independParentId) {
                  ctr.isIndependent = true;
                  independParentId = '';
                } else {
                  ctr.isIndependent = false;
                }
                ctr.options?.map((mp, mi) => {
                  let ctrTar = p.data.controls.find((x: IndicatorControlModel)=>x.id === mp.skipControlId);
                  if(ctrTar) {
                    mp.skipName = ctrTar.name;
                  }
                  let names = mp.followingControlIds?.map((ctid:string, i) => {
                    let ctrFollow = p.data.controls.find((x: IndicatorControlModel)=>x.id === ctid);
                    if(ctrFollow) {
                      return ctrFollow.name;
                    }
                  });
                  if(names) {
                    mp.followNames = names.join(',');
                  }
                  return mp;
                })
              });
              //console.log(p.data.controls);
              setList(p.data.controls);
            } else {
              toast.error('error');
            }
        })
    } else {
      toast.error('資訊錯誤');
    }
  };

  // 新增表單
  const addFormMutation = useMutation({
    mutationFn: IndicatorService.addIndicator,
  });
   // 新增表單
   const editFormMutation = useMutation({
    mutationFn: IndicatorService.editIndicatorForm,
  });

  // 新增表單
  const saveForm = () => {
    if(vaildForm() === true) {
      if(params.eid !== undefined &&
        params.eid !== '-1') { //編輯
          let tmpForm = {...formData};
          tmpForm.id = params.eid;
          tmpForm.category = parseInt(tmpForm.category, 10);
          list.forEach((it, i)=> {
            it.sortIndex = i;
          })
          tmpForm.controls = list;
          setFormData(tmpForm);
          editFormMutation
            .mutateAsync(tmpForm)
            .then(p=>{
                if(p.success){
                  toast.success('儲存成功');
                  getForm();
                } else {
                  toast.error(p.message);
                }
            })
      } else { //新增
        let tmpForm = {...formData};
        if(tmpForm.category !== undefined &&
          tmpForm.category) {
          tmpForm.id = uuid();//todo
          tmpForm.category = parseInt(tmpForm.category, 10);
          let sectionId: string = '';
          let sectionName: (string | null | undefined) = null;
          list.forEach((it, i)=> {
            if(it.ctrType === 'section' && it.isBlankSection !== true) {
              sectionId = it.id;
              sectionName = it.name;
            }
            if(it.parentId === sectionId) {
              it.sectionName = sectionName;
            }
            it.sortIndex = i;
          })
          tmpForm.controls = list;
          setFormData(tmpForm);
          addFormMutation
            .mutateAsync(tmpForm)
            .then(p=>{
                if(p.success){
                  toast.success('新增成功');
                  navigate(`/admin/indicator/${params.id}/setting/${p.message}/edit`);
                  setNavItems(navItems.map((nav, i) => {
                        if(i === 1){
                            nav.disabled=false;
                            nav.url = `/admin/indicator/${params.id}/setting/${p.message}/disclosureSetting`;
                        }
                        return nav;
                      }
                  ))
                } else {
                  toast.error(p.message);
                }
            })
        }
      }
    }
  };
  // #endregion


  // #region 事件

  useEffect(() => {
    categoriesList();
    if(params.eid !== undefined &&
      params.eid !== '-1') {
      getForm();
    }
  }, []);

  const vaildForm = (): boolean => {
    let msgArr:string[] = [];
    if(formData.category === null) {
      msgArr.push('類別');
    }
    if(formData.name === null) {
      msgArr.push('項目名稱');
    }
    setList(list.map((ck, i) => {
      ck.valid = true;
      if((ck.isBlankSection !== true) &&
         (ck.name === null || ck.name === '')){
          ck.valid = false;
          if(msgArr.indexOf('名稱') === -1) {
            msgArr.push('名稱');
          }
      }
      return ck;
    }));
    setTimeout(() => {
      scrollToElement();
    }, 100);
    if(msgArr.length > 0) {
      toast.error(msgArr.join(',') + '未填寫');
      return false;
    }

    return true;
  }

  const handleFormChange = (str: string | null, type: string) => {
      let tmpData = {...formData};
      switch(type) {
        case 'category':
          tmpData.category = str;
        break;
        case 'name':
          tmpData.name = str;
        break;
      }

      setFormData(tmpData);
  };

  const handleClick = () => {
    console.log("公開資訊站");
  };

  const copyControl = (item: IndicatorControlModel) => {
    let tmp = [...list];
    let newId = uuid();
    let ctrNewId = uuid();
    let chkSection = tmp.find(x=>x.id === item.parentId);
    if(chkSection && chkSection.ctrType==='section' && (chkSection.isBlankSection !== true)) {
      //群組section底下的控件
      tmp.push({
        id: ctrNewId,
        name: item.name,
        ctrType: item.ctrType,
        hasData: item.hasData,
        isShow: true,
        code: null,
        excludeNegative: item.excludeNegative,
        sortIndex: item.sortIndex+1,
        parentId: item.parentId,
        isSectionCtrOpen: true,
        isIndependent: false,
        unit: item.unit,
        comment: item.comment,
        options: item.options,
        isCustomUnit: item.isCustomUnit,
        isNotShowInMopsSingle: item.isNotShowInMopsSingle,
        isNotShowInMopsSummary: item.isNotShowInMopsSummary,
        isNotShowInMops: item.isNotShowInMops,
        digitNumber: item.digitNumber
      });
      tmp.forEach(t=> {
        if(t.sortIndex >= item.sortIndex+1 && t.id !==newId && t.id !== ctrNewId) {
          t.sortIndex = t.sortIndex+1;
        }
      })
    } else {
      //獨立控件
      tmp.push({
        id: newId,
        name: '',
        ctrType: 'section',
        hasData: false,
        isShow: true,
        code: null,
        excludeNegative: false,
        sortIndex: item.sortIndex+1,
        isBlankSection: true,
        parentId: null,
        isSectionCtrOpen: true
      });
      tmp.push({
        id: ctrNewId,
        name: item.name,
        ctrType: item.ctrType,
        hasData: item.hasData,
        isShow: true,
        code: null,
        excludeNegative: item.excludeNegative,
        sortIndex: item.sortIndex+2,
        parentId: newId,
        isSectionCtrOpen: true,
        isIndependent: true,
        unit: item.unit,
        comment: item.comment,
        options: item.options,
        isCustomUnit: item.isCustomUnit,
        isNotShowInMopsSingle: item.isNotShowInMopsSingle,
        isNotShowInMopsSummary: item.isNotShowInMopsSummary,
        isNotShowInMops: item.isNotShowInMops,
        digitNumber: item.digitNumber
      });
      tmp.forEach(t=> {
        if(t.sortIndex >= item.sortIndex+1 && t.id !==newId && t.id !== ctrNewId) {
          t.sortIndex = t.sortIndex+2;
        }
      })
    }

    setList(tmp.sort((a, b) => a.sortIndex - b.sortIndex));
  }

  const handleControlChange = (control: string | null, index: number) => {
    /*if(isAdd === true) {
        if(newList[index] && industry) {
            setNewList(newList.map((ck, i) => {
                    if(index === i){
                        ck.marketType = parseInt(industry, 10);
                    }
                    return ck;
                }
            ));
        }
    } else {*/
        if(list[index] && control) {
            setList(list.map((ck, i) => {
                if(index === i){
                    ck.ctrType = control;
                }
                return ck;
            }));
        }
    //}
  }

  const handleOptionChange = (itemIndex: number) => {
    setList(list.map((it, i) => {
      if(itemIndex === i){
        if(!it.options) {
          it.options = [];
        }
        it.options?.push({
          id: uuid(),
          text: '',
          code: '',
          check: false,
        });
      }
      return it;
    }));
  }
  //刪除select option清單
  const handleRemoveSelectOption = (itemIndex: number, oindex: number) => {
    // eslint-disable-next-line no-restricted-globals
    //if(confirm('確定刪除?') === true) {
      setList(list.map((it, i) => {
        if(itemIndex === i){
          it.options?.splice(oindex, 1);
        }
        return it;
      }));
    //}
  };

  const optionNameChange = (name: string, index:number, oindex: number) => {
      if(name && list[index]) {
        setList(list.map((ck, i) => {
            if(index === i){
              ck.options?.map((opt, oi) => {
                if(oi === oindex) {
                  opt.text = name;
                }
                return opt;
              })
            }
            return ck;
        }));
      }
  };

  const handleAddMatrixOptionChange = (itemIndex: number, type: string) => {
    setList(list.map((it, i) => {
      if(itemIndex === i){
        if(!it.matrix) {
          it.matrix = {
            columns: [],
            rows: []
          };
        }
        if(!it.matrix.columns) {
          it.matrix.columns = [];
        }
        if(!it.matrix.rows) {
          it.matrix.rows = [];
        }
        if(type === 'column') {
          it.matrix.columns.push({
            id: uuid(),
            name: '',
            type: 'column',
            sortIndex: it.matrix.columns.length,
          });
        }
        if(type === 'row') {
          it.matrix.rows.push({
            id: uuid(),
            name: '',
            type: 'row',
            sortIndex: it.matrix.rows.length,
          });
        }
      }
      return it;
    }));
  }

  //matrix option清單
  const handleRemoveMatrixOption = (itemIndex: number, oindex: number, type: string) => {
    // eslint-disable-next-line no-restricted-globals
    //if(confirm('確定刪除?') === true) {
      setList(list.map((it, i) => {
        if(itemIndex === i){
          if(type === 'row') {
            it.matrix?.rows.splice(oindex, 1);
          } else {
            it.matrix?.columns.splice(oindex, 1);
          }
        }
        return it;
      }));
    //}
  };

  const handleMatrixOptioNameChange = (name: string, index:number, oindex: number, type: string) => {
    if(name && list[index]) {
      setList(list.map((ck, i) => {
          if(index === i){
            if(type === 'column') {
              ck.matrix?.columns.map((opt, oi) => {
                if(oi === oindex) {
                  opt.name = name;
                }
                return opt;
              })
            } else {
              ck.matrix?.rows.map((opt, oi) => {
                if(oi === oindex) {
                  opt.name = name;
                }
                return opt;
              })
            }
          }
          return ck;
      }));
    }
  };

  function handleLineDDChange(num: string | null, index: number, type: string) {
      if(list[index] && num) {
          setList(list.map((it, i) => {
              if(index === i){
                if(!it.line) {
                  it.line = {
                    startNum: null,
                    endNum: null,
                    headTag: null,
                    tailTag: null
                  }
                }
                if(type === 'startNum') {
                  it.line.startNum = parseInt(num, 10);
                }
                if(type === 'endNum') {
                  it.line.endNum = parseInt(num, 10);
                }
              }
              return it;
          }));
      }
    }

    const handleLineTagChange = (tag: string, index:number, type: string) => {
      if(tag && list[index]) {
        setList(list.map((ck, i) => {
            if(index === i){
              if(!ck.line) {
                ck.line = {
                  startNum: null,
                  endNum: null,
                  headTag: null,
                  tailTag: null
                }
              }
              if(type === 'head') {
                ck.line.headTag = tag;
              } else {
                ck.line.tailTag = tag;
              }
            }
            return ck;
        }));
      }
    };

    const handleItemNameChange = (value: string, index: number, type: string) => {
      if(value!==null && list[index]) {
        setList(list.map((ck, i) => {
            if(index === i){
              switch(type) {
                case 'name':
                  list[index].name = value;
                  break;
                case 'comment':
                  list[index].comment = value;
                  break;
                case 'digitNumber':
                  list[index].digitNumber = value;
                  break;
                case 'unit':
                  list[index].unit = value;
                  break;
              }
            }
            return ck;
        }));
      }
    }

    function handleChkChange(index: number, type: string) {
      if(list[index]) {
        setList(list.map((ck, i) => {
          if(i === index){
            switch(type) {
              case 'hasData':
                list[index].hasData = !list[index].hasData;
                break;
              case 'isCustomUnit':
                list[index].isCustomUnit = !list[index].isCustomUnit;
                if(list[index].isCustomUnit === false) {
                  list[index].unit = '';
                }
                break;
              case 'excludeNegative':
                list[index].excludeNegative = !list[index].excludeNegative;
                break;
              case 'isNotShowInMopsSingle':
                list[index].isNotShowInMopsSingle = !list[index].isNotShowInMopsSingle;
                break;
              case 'isNotShowInMopsSummary':
                list[index].isNotShowInMopsSummary = !list[index].isNotShowInMopsSummary;
                break;
            }
          }
          return ck;
      }));
    }
  }

  const handleAddTitle = (sortIndex: number | null) => {
    let tmp = [...list];
    let newId = uuid();
    let newId2 = uuid();
    if(sortIndex !== null) {
      tmp.push({
        id: newId,
        name: '',
        ctrType: 'section',
        hasData: false,
        isShow: true,
        code: null,
        isBlankSection: false,
        excludeNegative: false,
        sortIndex: sortIndex+1,
        parentId: null,
        isSectionCtrOpen: true
      });
      tmp.push({
        id: newId2,
        name: '',
        ctrType: 'select',
        hasData: false,
        isShow: true,
        isIndependent: false,
        code: null,
        excludeNegative: false,
        sortIndex: sortIndex+2,
        parentId: newId,
        isSectionCtrOpen: true
      });
      tmp.forEach(t=> {
        if(t.sortIndex >= sortIndex+1 && t.id !==newId && t.id !== newId2) {
          t.sortIndex = sortIndex+3;
        }
      });
    } else {
      tmp.push({
        id: newId,
        name: '',
        ctrType: 'section',
        hasData: false,
        isShow: true,
        code: null,
        isBlankSection: false,
        excludeNegative: false,
        sortIndex: list.length+1,
        parentId: null,
        isSectionCtrOpen: true
      });

      tmp.push({
        id: uuid(),
        name: '',
        ctrType: 'select',
        hasData: false,
        isShow: true,
        code: null,
        isIndependent: false,
        excludeNegative: false,
        sortIndex: list.length+1,
        parentId: newId,
        isSectionCtrOpen: true
      });
    }
    setList(tmp.sort((a, b) => a.sortIndex - b.sortIndex));
  }

  const handleAddControl = (sectionId: string | null, type: string, sortIndex: number) => {
    let rearIndex = sortIndex;
    let tmp = [...list];
    let newId = uuid();
    if(sectionId === null) { //初始新增控件
      sectionId = newId;
      tmp.push({
        id: newId,
        name: '',
        ctrType: 'section',
        hasData: false,
        isShow: true,
        code: null,
        excludeNegative: false,
        sortIndex: sortIndex+1,
        isBlankSection: true,
        parentId: null,
        isSectionCtrOpen: true
      });
      tmp.push({
        id: uuid(),
        name: '',
        ctrType: 'select',
        hasData: false,
        isShow: true,
        code: null,
        isIndependent: false,
        excludeNegative: false,
        sortIndex: sortIndex+1,
        parentId: sectionId,
        isSectionCtrOpen: true
      });
    } else if(type === 'title') { // section底下的控件
      let selCount = tmp.filter(x=>x.parentId === sectionId);
      tmp.push({
        id: newId,
        name: '',
        ctrType: 'select',
        hasData: false,
        isShow: true,
        code: null,
        isIndependent: false,
        excludeNegative: false,
        sortIndex: sortIndex + selCount.length + 1,
        parentId: sectionId,
        isSectionCtrOpen: true
      });
      rearIndex = sortIndex + selCount.length + 1;
      tmp.forEach(t=> {
        if(t.sortIndex >= rearIndex && t.id !==newId) {
          t.sortIndex = t.sortIndex+1;
        }
      })
    } else if(type === 'control') { // section底下的控件下一個控件與獨立控件 parentId
      let chkSection = tmp.find(x=>x.id === sectionId); //parent id
      if(chkSection?.ctrType==='section' && chkSection.isBlankSection === true) {
        sectionId = newId;
        let id2 = uuid();
        //如果parent id 是 isBlankSection 則 為獨立控件
        tmp.push({
          id: sectionId,
          name: '',
          ctrType: 'section',
          hasData: false,
          isShow: true,
          code: null,
          excludeNegative: false,
          sortIndex: sortIndex+1,
          isBlankSection: true,
          parentId: null,
          isSectionCtrOpen: true
        });
        tmp.push({
          id: id2,
          name: '',
          ctrType: 'select',
          hasData: false,
          isShow: true,
          code: null,
          isIndependent: true,
          excludeNegative: false,
          sortIndex: sortIndex+1,
          parentId: sectionId,
          isSectionCtrOpen: true
        });
        rearIndex = sortIndex + 1;
        tmp.forEach(t=> {
          if(t.sortIndex >= rearIndex && t.id !==sectionId && t.id !== id2) {
            t.sortIndex = t.sortIndex+1;
          }
        })
      } else { //section底下的控件
        tmp.push({
          id: newId,
          name: '',
          ctrType: 'select',
          hasData: false,
          isShow: true,
          code: null,
          isIndependent: false,
          excludeNegative: false,
          sortIndex: sortIndex + 1,
          parentId: sectionId,
          isSectionCtrOpen: true
        });
        rearIndex = sortIndex + 1;
        tmp.forEach(t=> {
          if(t.sortIndex >= rearIndex && t.id !==newId) {
            t.sortIndex = t.sortIndex+1;
          }
        })
      }
    }
    setList(tmp.sort((a, b) => a.sortIndex - b.sortIndex));
  }

  const handleRemoveItem = (item: IndicatorControlModel, index:number) => {
    let tmpList = [...list];
    tmpList.splice(index, 1);
    //如果是獨立控件，則刪除對應之section
    if(item.isIndependent === true) {
      const sindex = tmpList.findIndex(object => {
        return object.id ===  item.parentId;
      });
      tmpList.splice(sindex, 1);
    } else if(item.ctrType === 'section' && item.isBlankSection !== true) {
      //一般section刪除，則下方control全部獨立
      let ctrs = tmpList.filter(x=>x.parentId === item.id);
      ctrs.forEach(ctr => {
        let newId = uuid();
        tmpList.push({
          id: newId,
          name: '',
          ctrType: 'section',
          hasData: false,
          isShow: true,
          code: null,
          excludeNegative: false,
          sortIndex: ctr.sortIndex-1,
          isBlankSection: true,
          parentId: null
        });
        ctr.parentId = newId;
        ctr.isIndependent = true;
      });
    }
    setList(tmpList.sort((a, b) => a.sortIndex - b.sortIndex));
    console.log(tmpList.sort((a, b) => a.sortIndex - b.sortIndex));
  }

  function checkParentSection(chkItem: IndicatorControlModel, newIndex: number): boolean {
    //檢查控制項移動，只能在規範的section內
    if(chkItem.parentId !== null &&
       chkItem.isIndependent !== true &&
       chkItem.parentId !== list[newIndex].parentId) {
      return false;
    }
    //檢查section移動到哪,只能在外層
    if(chkItem.parentId === null && (list[newIndex].parentId !== null && list[newIndex].isIndependent !== true)) {
      return false;
    }
    //檢查獨立移動到哪,只能在外層
    if(chkItem.isIndependent === true && (list[newIndex].ctrType !== 'section' && list[newIndex].isIndependent !== true)) {
      return false;
    }
    return true;
  }

  const endSort = (e: any) => {
    /*console.log('old', e.oldIndex);
    console.log('new',e.newIndex);
    console.log('item',e.item);
    console.log('item',e.item.dataset.id);*/
    let checkItem = list.find(x=>x.id === e.item.dataset.id);
    if(checkItem) {
      let pass= false;//checkParentSection(checkItem, e.newIndex);
      if(pass) {
        let tmpList = [...list];
        tmpList.forEach((ck, i) => {
          ck.sortIndex = i;
          if(ck.isIndependent===true) {
            ck.sortIndex = (i-1);
          }
        });
        let sectionId = '';
        let curSortIndex = 0;
        let independCtr:(any) = {};
        tmpList.forEach((ck, i) => {
          if(ck.id === e.item.dataset.id){
            /*if(ck.sortIndex < e.newIndex) {
              ck.sortIndex-=e.newIndex;
            } else if(ck.sortIndex > e.newIndex) {
              ck.sortIndex+=e.newIndex;;
            }*/
            ck.sortIndex = e.newIndex-1;
            //獨立控件處理
            if(ck.isIndependent === true) {
              independCtr = ck;
            }
            //section處理
            if(ck.ctrType === 'section') {
              sectionId = ck.id;
            }
            curSortIndex = e.newIndex;
          }
        });
        // section 底下的控件，全部跟著往下移對應位置
        tmpList.forEach((ck, i) => {
          if(ck.parentId === sectionId){
            ck.sortIndex = curSortIndex+1;
            curSortIndex = ck.sortIndex;
          }
          if(independCtr && ck.id === independCtr.parentId) {
            ck.sortIndex = independCtr.sortIndex;
          }
        });
        curSortIndex = curSortIndex+1;
        // section 拖曳到的位置，上方的的減一位，下加一位
        tmpList.forEach((ck, i) => {
          if(ck.id !== e.item.dataset.id &&
             ck.parentId !== sectionId){
            if(ck.sortIndex === e.newIndex) {
              if(e.oldIndex < e.newIndex) {
                curSortIndex = curSortIndex-1;
                ck.sortIndex=curSortIndex;
              } else if(e.oldIndex > e.newIndex) {
                curSortIndex = curSortIndex+1;
                ck.sortIndex=curSortIndex;
              }
            } else if(ck.sortIndex < e.newIndex) {
              curSortIndex = curSortIndex-1;
              ck.sortIndex=curSortIndex;
            } else if(ck.sortIndex > e.newIndex) {
              curSortIndex = curSortIndex+1;
              ck.sortIndex=curSortIndex;
            }
          }
        });

        setList([...tmpList.sort((a, b) => a.sortIndex - b.sortIndex)]);
      } else {
        let tmpList = [...list];
        setList([...tmpList.sort((a, b) => a.sortIndex - b.sortIndex)]);
      }
    }
  }

  const handleSort=(itemId: string, type: string) => {
    let tmpList:(IndicatorControlModel[]) = JSON.parse(JSON.stringify(list)); //[...list];
    //console.log(tmpList);
    tmpList.forEach((ck, i) => {
      ck.sortIndex = i;
      if(ck.isIndependent===true) {
        ck.sortIndex = (i-1);
      }
    });
    //console.log(tmpList);
    switch(type) {
      case 'up':
        let targetSort = 0;
        let targetCtr = tmpList.find(x=>x.id === itemId);
        let targetSection:(any) = null;
        if(targetCtr) {
          targetSection = tmpList.find(x=>x.id === targetCtr?.parentId);
          let pass = false;
          //往上找到最近的sortIndex，再分配給目標控件
          for(let i=targetCtr?.sortIndex-1; i>=0;i--){
            //console.log(i);
            if(!pass) {
              if((tmpList[i].ctrType==='section' && tmpList[i].isBlankSection !== true) ||
                (tmpList[i].isIndependent===true)) {
                targetSort = tmpList[i].sortIndex;
                pass = true;
              }
            }
          }
        }
        let ctrParent: any = null;//目標是section物件暫存，因為底下整包都要一起移動
        tmpList.forEach((ck, i) => {
          if(ck.id === itemId){
            //獨立控件
            if(ck.isIndependent === true) {
              ck.sortIndex =targetSort;
            } else if(ck.ctrType==='section' && ck.isBlankSection !== true) { //section
              ck.sortIndex = targetSort;
              ctrParent = ck;
            } else { //section內的控件往上
              ck.sortIndex -=1;
              targetSort = ck.sortIndex;
            }
          } else {
            ck.sortIndex+=1;
          }
          //獨立控件的父曾section也設定相同sortindex
          if(targetSection && ck.id === targetSection.id) {
            ck.sortIndex = targetSort;
          }
        });
        if(ctrParent) { //如果有值
          let c = 1;
          tmpList.forEach((ck, i) => {
            //找到section底下所有控件，sortIndex一起往上移
            if(ck.parentId === ctrParent.id) {
              ck.sortIndex = ctrParent.sortIndex + c;
              c++;
            } else if (ck.sortIndex > ctrParent.sortIndex) {
              //非section下的項目且比section下方的項目都往下
              let len = tmpList.filter(x=>x.parentId=== ctrParent.id);
              ck.sortIndex = ctrParent.sortIndex + len.length+1;
            }
          });
        }
        setList([...tmpList.sort((a, b) => a.sortIndex - b.sortIndex)]);
        // console.log(tmpList);
        break;
      case 'down':
        let targetSortDown = 0;
        let targetCtrDown = tmpList.find(x=>x.id === itemId);
        let targetSectionDown:(any) = null;
        if(targetCtrDown) {
          targetSectionDown = tmpList.find(x=>x.id === targetCtrDown?.parentId);
          let pass = false;
          //往下找到最近的sortIndex，再分配給目標控件
          for(let j=targetCtrDown?.sortIndex+1; j<tmpList.length;j++){
            //console.log(j);
            if(!pass && tmpList[j].id !== itemId) {
              if((tmpList[j].ctrType==='section' && tmpList[j].isBlankSection !== true) ||
                (tmpList[j].isIndependent===true)) {
                  // eslint-disable-next-line no-loop-func
                  let chlCtr = tmpList.filter(x=>x.parentId === tmpList[j].id).length || 0;
                  targetSortDown = tmpList[j].sortIndex + chlCtr;
                  pass = true;
              }
            }
          }
        }
        let ctrParentDown: any = null;//目標是section物件暫存，因為底下整包都要一起移動
        tmpList.forEach((ck, i) => {
          if(ck.id === itemId){
            //本身是獨立控件
            if(ck.isIndependent === true) {
              ck.sortIndex = targetSortDown;
            } else if(ck.ctrType==='section' && ck.isBlankSection !== true) { //section
              ck.sortIndex = targetSortDown;
              ctrParentDown = ck;
            } else { //section內的控件往下
              ck.sortIndex +=1;
              targetSortDown = ck.sortIndex;
            }
          } else {
            ck.sortIndex-=1;
          }
          //獨立控件的父曾section也設定相同sortindex
          if(targetSectionDown && ck.id === targetSectionDown.id &&
            targetCtrDown?.isBlankSection === null) {
            ck.sortIndex = targetSortDown;
          }
        });
        if(ctrParentDown) { //如果有值
          let c = 1;
          tmpList.forEach((ck, i) => {
            //找到section底下所有控件，sortIndex一起往上移
            if(ck.parentId === ctrParentDown.id) {
              ck.sortIndex = ctrParentDown.sortIndex + c;
              c++;
            } else if (ck.sortIndex >= ctrParentDown.sortIndex && ck.id !== itemId) {
              //非section下的項目且比section下方的項目都往下
              let len = tmpList.filter(x=>x.parentId=== ctrParentDown.id);
              ck.sortIndex = ctrParentDown.sortIndex + len.length;
            }
          });
        }

        setList([...tmpList.sort((a, b) => a.sortIndex - b.sortIndex)]);
        console.log(tmpList);
        break;
    }
  }

  // 控制項獨立(往左)
  const handleOutCtr = (item: IndicatorControlModel) => {
    let tmpList = [...list];
    let lastSort = tmpList[tmpList.length-1].sortIndex;
    let id = uuid();
    tmpList.push({
      code: 'section',
      ctrType: 'section',
      excludeNegative: false,
      hasData: false,
      id: id,
      isBlankSection: true,
      isCustomUnit: false,
      isShow: true,
      name: '',
      showSortIndex: 0,
      sortIndex: lastSort+1,
      parentId: null
    });

    setList(tmpList.map((it, i) => {
      if(it.id === item.id) {
        item.parentId = id;
        item.isIndependent = true;
        item.sortIndex = lastSort + 2;
      }
      return it;
    }))
    //console.log(tmpList);
    setList(tmpList.sort((a, b) => a.sortIndex - b.sortIndex));
  }

  // 獨立控項附加到上面的section(往右)
  const handleInCtr = (item: IndicatorControlModel, index: number) => {
    let tmpList = [...list];
    if(index >= 2 && tmpList[index-2].parentId!==null&& tmpList[index-2].isIndependent!==true) {
      let targetSec = tmpList.find(x=>x.id === tmpList[index-2].parentId);
      if(targetSec) {
        tmpList.forEach(x=> {
          if(x.id === item.id) {
            x.parentId =targetSec?.id!;
            x.isIndependent = false;
          }
        })
        tmpList.splice((index-1),1);
      }
    }
    setList(tmpList.sort((a, b) => a.sortIndex - b.sortIndex));
    //console.log(tmpList);
  }

  // section下的子控件第一個不能推出
  const isCanOutCtr = (item: IndicatorControlModel, index: number):boolean => {
    if(item.isIndependent === true || index === 0 || item.isIndependent === undefined)
      return false;
    if(list[index-1].ctrType === 'section' && (list[index-1].isBlankSection !== true)) {
      return false;
    }
    return true;
  }

  //僅獨立控件第一個可推到上一個section底下
  const isCanInCtr = (item: IndicatorControlModel, index: number):boolean => {
    if(item.isIndependent !== true || index < 2) {
      return false;
    }

    if(index >= 2 && list[index-2].parentId!==null&& list[index-2].isIndependent!==true) {
      return true;
    }
    return false;
  }

  const isSectionIsBlock = (parentId: string | null):boolean => {
    let section = list.find(x=>x.id === parentId);
    if(section) {
      return section.isBlankSection || false;
    }
    return false;
  }

  const canPrevChild = (index: number):boolean => {
    if(index >0 && list[index] && list[index-1]) {
      if(index === 1 && list[index].isIndependent === true) {
        return false;
      }
      if((list[index].isIndependent !== true && list[index-1].ctrType === 'section')) {
        return false;
      }
      if((index === 1 && list[index].ctrType === 'section')) {
        return false;
      }
      return true;
    }
    return false;
  }
  const canNextChild = (index: number):boolean => {
    if(index < list.length && list[index]) {
      if(index >= list.length-1) {
        return false;
      }
      if(list[index].ctrType !== 'section' && list[index].isIndependent !== true && list[index+1].parentId !== list[index].parentId) {
        return false;
      }
      if((index === (list.length-1) && list[index].ctrType === 'section') ||
         (list[list.length-1].parentId === list[index].id)) {
        return false;
      }
      return true;
    }
    return false;
  }

    // 處理關閉
    const handleCloseModal = () => {
      console.log("close");
      handleModal();
    };

    // 處理確認
    const handleConfirmModal = () => {
        console.log(list);
        setList(list.map((ctr) => {
          ctr.options?.map((mp, mi) => {
            let names = mp.followingControlIds?.map((ctid:string, i) => {
              let ctrFollow = list.find((x: IndicatorControlModel)=>x.id === ctid);
              if(ctrFollow) {
                return ctrFollow.name;
              }
            });
            if(names) {
              mp.followNames = names.join(',');
            }
            return mp;
          })
          return ctr;
        }))
        handleModal();
    };
    // 處理modal關閉事件
    const handleModal = () => {
      setModal((prev: any) => {
      return {
          ...prev,
          show: !prev.show,
      };
      });
    };

    // 開啟跳接題modal
    const followSkipCtr = (type: string, ctrtar: IndicatorControlModel, optiontar: IndicatorControlOptionModel) => {
      setCtrSkip(ctrtar); //設定control
      setCtrOptionSkip(optiontar); //設定option

      let tmpList = [...list];

      //設定checkbox/radio顯示
      /*if(type === 'skip') {
        setList(tmpList.map((ctr, i) => {
          if(ctr.id === optiontar.skipControlId) {
            ctr.skipChecked = true;
          } else {
            ctr.skipChecked = false;
          }
          return ctr;
        }))
      } else {*/ //接題
      if(optiontar.followingControlIds!== undefined) {
        setList(tmpList.map((ctr, i) => {
          ctr.followChecked = false;
          return ctr;
        }))
        setList(tmpList.map((ctr, i) => {
          optiontar.followingControlIds?.forEach(it => {
            if(it === ctr.id) {
              ctr.followChecked = true;
            }
          })
          return ctr;
        }))
      }
      //}
      setIsSkip(type === 'skip' ? true : false);
      setModal((prev) => {
        return {
          ...prev,
          show: !prev.show,
          title: type === 'skip' ? '' : '接題'
        };
      });
    }

    //接文字區塊
    const followTextarea = (ctrtar: IndicatorControlModel, optiontar: IndicatorControlOptionModel) => {
      let tmpList = [...list];
      setList(tmpList.map((ctr, i) => {
        if(ctrtar.id === ctr.id) {
          ctr.options?.map((opt, oi) => {
            if(opt.id === optiontar.id) {
              opt.isExtraText = !opt.isExtraText;
            }
            return opt;
          });
        }
        return ctr;
      }))
    }

    //預設選項
    const setDefaultOption = (ctrtar: IndicatorControlModel, optiontar: IndicatorControlOptionModel) => {
      let tmpList = [...list];
      setList(tmpList.map((ctr, i) => {
        if(ctrtar.id === ctr.id) {
          ctr.options?.map((opt, oi) => {
            if(opt.id === optiontar.id) {
              opt.check = !opt.check;
            } else {
              opt.check = false;
            }
            return opt;
          });
        }
        return ctr;
      }))
    }

    // 跳題radio切換
    const handleSkipCtrRadioChange = (skipToCtr: IndicatorControlModel) => {
      let tmpList = [...list];

      tmpList = list.map((ck, index) => {
        if(skipToCtr.id === ck.id &&  ck.skipChecked === false){
            ck.skipChecked = !ck.skipChecked;
        }
        return ck;
        }
      );
      if(ctrSkip) {
        tmpList.forEach((ctr, i) => {
          if(ctr.id === ctrSkip.id) {
            if(ctrOptionSkip) {
              ctr.options?.forEach((op, oi) => {
                if(op.id === ctrOptionSkip.id) {
                  op.skipControlId = skipToCtr.id;
                }
              });
            }
          }
        })
      }
      setList([...tmpList]);
    }


    const handleFollowCtrCheckBoxChange = (skipToCtr: IndicatorControlModel, e:any) => {
      let tmpList = [...list];
      if(skipToCtr) {
        tmpList = list.map((ck, index) => {
              if(skipToCtr.id === ck.id){
                  ck.followChecked = !ck.followChecked;
              }
              return ck;
          }
        );
        let chkCtrfollow = tmpList.filter(x=>x.followChecked === true);
        if(ctrSkip) {
          let followIds: Array<string> = [];
          chkCtrfollow.forEach(ctr => {
            followIds.push(ctr.id);
          });
          tmpList.forEach((ctr, i) => {
            if(ctr.id === ctrSkip.id) {
              if(ctrOptionSkip) {
                ctr.options?.forEach((op, oi) => {
                  if(op.id === ctrOptionSkip.id) {
                    op.followingControlIds = followIds;
                  }
                });
              }
            }
          })
        }
        setList([...tmpList]);
        console.log(list);
      }
    }

    const handleCancelSkip = () => {
      let tmpList = [...list];
      /*if(isSkip === true) { //取消跳題
        if(ctrSkip && ctrOptionSkip) {
          tmpList.map((ctr, i) => {
            if(ctr.id === ctrSkip.id) {
              if(ctrOptionSkip) {
                ctr.options?.map((op, oi) => {
                  if(op.id === ctrOptionSkip.id) {
                    op.skipControlId = null;
                  }
                  return op;
                });
              }
            }
            return ctr;
          })
        }
      } else {*/ //取消接題
      if(ctrSkip && ctrOptionSkip) {
        tmpList.forEach((ctr, i) => {
          if(ctr.id === ctrSkip.id) {
            if(ctrOptionSkip) {
              ctr.options?.forEach((op, oi) => {
                if(op.id === ctrOptionSkip.id) {
                  op.followingControlIds = [];
                  op.followNames = '';
                }
              });
            }
          }
        })
      }
      //}
      setList([...tmpList]);
      handleModal();
    }

    const countCtr = (item: IndicatorControlModel):number => {
      return list.filter(x=>x.parentId === item.id).length || 0;
    }

    const toggleSection = (item: IndicatorControlModel) => {
      let tmpList = [...list];
      tmpList.forEach((ctr, i) => {
        if(ctr.id === item.id) {
          item.isSectionOpen = !item.isSectionOpen;
        }
        if(item.isSectionOpen === true && ctr.parentId === item.id) {
          ctr.isSectionCtrOpen = true;
        } else if(item.isSectionOpen === false && ctr.parentId === item.id) {
          ctr.isSectionCtrOpen = false;
        }
      })
      setList([...tmpList]);
    }

  // 錯誤定位
  const scrollToElement = () => {
    // 找出第一個有invalid 屬性的元件
    const elements = formRef.current?.getElementsByClassName("invalid");
    console.log(elements);
    if (elements && elements.length > 0) {
      elements[0].scrollIntoView({ behavior: "smooth" });
    }
  };
    // #endregion

    return (
      <div className={styles["indicator-edit"]}>
        {
          (editFormMutation.isPending||
            addFormMutation.isPending ||
            getFormMutation.isPending
          ) &&
          <VisuallLoading></VisuallLoading>
        }
        {/*上方bar*/}
        <div className="title-box">
            <BackTitle title={params.eid === '-1'? '新增指標' : '編輯指標'} url={`/admin/indicator/${params.id}/setting`}/>
        </div>
        <div className="indicator-setting-content-box" style={{'borderTop': '1px solid #dbe8ef'}}>


          {/* 左區塊tab */}
          <div className="left-box">
            <TabVerticalThin data={navItems} tabKey={'edit'} state={location.state || null}/>
          </div>

          {/* 右區塊 */}
          <div className={"right-box"}>

            {/** 儲存與標頭 */}
            <div className="form-save tool-box">
              <div className="subTitle">指標設定</div>
              <div>
                {(detectEveryActionPermission([
                  IndicatorManageActionsPermissionCodes.IndicatorSettingCreate,
                ]) && params.eid === '-1') || (detectEveryActionPermission([
                  IndicatorManageActionsPermissionCodes.IndicatorSettingUpdate,
                ]) && params.eid !== '-1') ? (
                  <button className="default" onClick={saveForm}>
                    儲存
                  </button>
                ) : null}

              </div>
            </div>

            <div ref={formRef} className="scroll scroll-content">

              {/** 基本設定 */}
              <div className="form-attr row g-0">
                <div className="col-md-4">
                  <div className="title">基本設定：</div>
                  <div className="mt-20-p">
                    <CustDropdown
                      placeholder={"類別"}
                      options={categories}
                      defaultId={formData.category?.toString()}
                      onChange={(e) => handleFormChange(e, 'category')}
                    />
                  </div>
                  <div className="mt-20-p">
                    <NormalInput
                      placeholder="項目名稱*"
                      defaultValue={formData.name}
                      onChange={(e) => handleFormChange(e, 'name')}
                    />
                  </div>

                </div>
                <div className="col-md-12">
                <div className="title mt-20-p tool-box">
                    <div style={{padding: '10px 0'}}>進階設定：</div>
                    <div>

                    </div>
                  </div>
                </div>
              </div>

              {/** 控件清單 */}
              <div className="control-list">

              {list.length === 0 ? (<>
                <div className="add-control-block">
                  <div className="button-block">
                    <IconButton
                      imgName="add-icon.svg"
                      text="新增群組標題"
                      className="secondary"
                      onClick={() => handleAddTitle(null)}
                    />
                    <IconButton
                      imgName="add-bg-icon.svg"
                      text="新增欄位"
                      className="ml-16-p default"
                      onClick={() => handleAddControl(null, '', 0)}
                    />
                  </div>
                </div>
              </>) :
                list.map((item, i) => (
                  <div key={item.id} className="control-item mt-20-p">

                    {item.ctrType === 'section' ? (
                    <>
                    {item.isBlankSection === true ? <></> : <>
                    {/** section */}
                      <div className="section-type">
                        <div className="operators">
                          <div className="handle">
                            <div style={{width: '16px'}}>
                              <LinkIconButton
                                imgName="hambur-icon.svg"
                                text=""
                                onClick={handleClick}
                              />
                            </div>
                          </div>
                          <div className="tool-box">
                            {canPrevChild(i) === false ? <></> : (<>
                              <LinkIconButton
                              imgName="top-arrow-icon.svg"
                              text=""
                              onClick={()=>handleSort(item.id, 'up')}
                            />
                            </>)}
                            {canNextChild(i) === false ? <></> : (<>
                              <LinkIconButton
                              imgName="down-arrow-icon.svg"
                              text=""
                              onClick={()=>handleSort(item.id, 'down')}
                            />
                            </>)}

                            <div className="mr-1-m" style={{borderRight: '1px solid #d3e1e7'}}></div>
                            {/*<LinkIconButton
                              imgName="copy-icon.svg"
                              text=""
                              onClick={handleClick}
                            />*/}
                            <LinkIconButton
                              imgName="trash-icon.svg"
                              text=""
                              onClick={() => handleRemoveItem(item, i)}
                            />
                          </div>
                        </div>
                        <div className="row g-0">
                          <div className={`${item.valid === false ? "valid_fail" : ""} col-md-12` }>
                            <div className="mt-20-p">
                              <NormalInput
                                isFloatTitle={false}
                                placeholder={"標題名稱*"}
                                valid={item.valid}
                                defaultValue={item.name || ''}
                                onChange={(e) => handleItemNameChange(e, i, 'name')}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mt-20-p">
                              <NormalInput
                                isFloatTitle={false}
                                placeholder={"欄位說明"}
                                defaultValue={item.comment || ''}
                                onChange={(e) => handleItemNameChange(e, i, 'comment')}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="toggle-title" onClick={() => toggleSection(item)}>
                          <img
                            style={{ cursor: "pointer" }}
                            alt="cancel"
                            src={`/assets/images/buttonIcon/${item.isSectionOpen === true ? 'top-arrow-icon.svg': 'down-arrow-icon.svg'}`}
                          />
                          {item.isSectionOpen === true ? '縮合': '展開'}{countCtr(item)}個項目
                        </div>
                      </div>
                      <div className="add-control-block">
                        <div className="button-block">
                          <IconButton
                            imgName="add-bg-icon.svg"
                            text="新增欄位"
                            className="ml-16-p default"
                            onClick={() => handleAddControl(item.id, 'title', item.sortIndex)}
                          />
                        </div>
                      </div>
                    </>}
                    </>) :
                     (<>
                     {/** 控制項 */}
                     {item.isSectionCtrOpen === true ?
                     <>
                      <div className={`${isSectionIsBlock(item.parentId) === true  ? "" : "child"} control-type` }>
                        {isCanOutCtr(item, i) === true ?
                        <div className="outStand">
                          <LinkIconButton
                            imgName="back-icon.svg"
                            text=""
                            onClick={() => handleOutCtr(item)}
                          />
                        </div> :
                        <></>

                        }
                        {isCanInCtr(item, i) === true ?
                          <div className="outStand">
                            <LinkIconButton
                              imgName="right-arrow.svg"
                              text=""
                              onClick={() => handleInCtr(item, i)}
                            />
                          </div> :
                          <></>
                        }
                        <div className="operators">

                          <div className="handle">
                            <div style={{width: '16px'}}>
                              <LinkIconButton
                                imgName="hambur-icon.svg"
                                text=""
                                onClick={handleClick}
                              />
                            </div>
                          </div>
                          <div className="tool-box">
                            {canPrevChild(i) === false ? <></> : (<>
                              <LinkIconButton
                              imgName="top-arrow-icon.svg"
                              text=""
                              onClick={()=>handleSort(item.id, 'up')}
                            />
                            </>)}
                            {canNextChild(i) === false ? <></> : (<>
                              <LinkIconButton
                              imgName="down-arrow-icon.svg"
                              text=""
                              onClick={()=>handleSort(item.id, 'down')}
                            />
                            </>)}

                            <div className="mr-1-m" style={{borderRight: '1px solid #d3e1e7'}}></div>
                            <LinkIconButton
                              imgName="copy-icon.svg"
                              text=""
                              onClick={() => copyControl(item)}
                            />
                            <LinkIconButton
                              imgName="trash-icon.svg"
                              text=""
                              onClick={() => handleRemoveItem(item ,i)}
                            />
                          </div>
                        </div>
                        <div className="row g-0 mt-10-p">
                          <div className="col-md-9 border-right">
                            <div className={`${item.valid === false ? "valid_fail" : ""} mt-20-p` }>
                              <NormalInput
                                placeholder="項目名稱*"
                                valid={item.valid}
                                defaultValue={item.name}
                                onChange={(e) => handleItemNameChange(e, i, 'name')}
                              />
                            </div>
                            <div className="mt-20-p" style={{marginBottom: '-10px'}}>
                              <textarea
                                className="custom-textarea"
                                placeholder="欄位說明"
                                defaultValue={item.comment || '' }
                                onChange={(e) => handleItemNameChange(e.target.value, i, 'comment')}
                              ></textarea>
                            </div>
                            <div className="d-flex mt-20-p">
                              <div style={{width: '30%'}}>
                                <CustDropdown
                                  placeholder={"欄位類別*"}
                                  options={ctrOption}
                                  defaultId={item.ctrType}
                                  onChange={(e) => handleControlChange(e, i)}
                                />
                              </div>
                              {item.ctrType === 'number' ?
                              <>
                              <div className="pointer-txt">小數點後</div>
                              <div style={{width: '20%'}}>
                                <NormalInput
                                  placeholder="小數點後"
                                  type="number"
                                  unit="位"
                                  defaultValue={item.digitNumber}
                                  onChange={(e) => handleItemNameChange(e, i, 'digitNumber')}
                                />
                              </div>
                              <div className="pad">
                                <label className="custom-checkbox">
                                  <input type="checkbox" defaultChecked={!item.excludeNegative}
                                        onChange={()=>handleChkChange(i, 'excludeNegative')} />
                                  <span>不可為負數</span>
                                </label>
                              </div>
                              </> :
                              <></>}

                            </div>
                          </div>
                          <div className="col-md-3 mt-20-p">
                            {/*<div className="border-bottom">
                              <label className="custom-checkbox">
                                <input type="checkbox" defaultChecked={item.required}
                                      onChange={()=>handleChkChange(i, 'required')}/>
                                <span>必填欄位</span>
                              </label>
                            </div>*/}
                            <div className="border-bottom">
                              <label className="custom-checkbox">
                                <input type="checkbox" defaultChecked={item.hasData}
                                onChange={()=>handleChkChange(i, 'hasData')}/>
                                <span>可無資料申報</span>
                              </label>
                            </div>

                            <div className="border-bottom">
                              <label className="custom-checkbox">
                                <input type="checkbox" defaultChecked={item.isNotShowInMopsSingle}
                                onChange={()=>handleChkChange(i, 'isNotShowInMopsSingle')}/>
                                <span>查詢前台個別公司不顯示</span>
                              </label>
                              <label className="custom-checkbox">
                                <input type="checkbox" defaultChecked={item.isNotShowInMopsSummary}
                                onChange={()=>handleChkChange(i, 'isNotShowInMopsSummary')}/>
                                <span>查詢前台彙總資料不顯示</span>
                              </label>
                            </div>
                            <div style={{marginLeft: '20px', padding: '10px'}}>
                              <label className="custom-checkbox">
                                <input type="checkbox" defaultChecked={item.isCustomUnit}
                                onChange={()=>handleChkChange(i, 'isCustomUnit')}/>
                                <span>定義單位</span>
                                <span style={{color: '#79A0B4'}}>&nbsp;&nbsp;*若未勾選即自訂單位</span>
                              </label>
                              <div className="mt-15-p">
                                <NormalInput
                                  isFloatTitle={false}
                                  placeholder={"定義單位"}
                                  disabled={item.isCustomUnit===false}
                                  defaultValue={item.unit}
                                  onChange={(e) => handleItemNameChange(e, i, 'unit')}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/** 控制項細項 */}
                        <div className="control-detail">
                          {item.ctrType === 'select' ||
                           item.ctrType === 'radio' ||
                           item.ctrType === 'radio-long' ||
                           item.ctrType === 'select-multi' ||
                           item.ctrType === 'checkbox' ?
                          (<>
                          {/** 多選 */}
                          <div className="ctr_select_block">
                            {item.options?.map((opt, oi) => (
                              <div key={opt.id} className="option-drop">
                                <div className="cur-move">
                                  <img
                                    alt="progress"
                                    src="/assets/images/buttonIcon/hambur-icon.svg"
                                    />
                                </div>
                                <div>
                                  <NormalInput
                                    isFloatTitle={false}
                                    placeholder={"項目名稱"}
                                    defaultValue={opt.text}
                                    onChange={(e)=>optionNameChange(e, i, oi)}
                                  />
                                </div>
                                <div className="cur-pointer"
                                     onClick={() => handleRemoveSelectOption(i, oi)}>
                                  <img
                                    alt="progress"
                                    src="/assets/images/buttonIcon/job-close-icon.svg"
                                    />
                                </div>
                                <div>
                                <Dropdown>
                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <img alt="progress" src="/assets/images/buttonIcon/hambur-icon.svg"/>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {/*<Dropdown.Item onClick={() => followSkipCtr('skip', item, opt)}>跳題</Dropdown.Item>*/}
                                    {item.ctrType === 'select' ||
                                     item.ctrType === 'radio' ||
                                     item.ctrType === 'radio-long' ?
                                    <Dropdown.Item onClick={() => setDefaultOption(item, opt)}>預設選項</Dropdown.Item>
                                     :
                                     <></>
                                    }
                                    {item.ctrType === 'select-multi' ? <></> :
                                    <Dropdown.Item onClick={() => followTextarea(item, opt)}>接文字區塊</Dropdown.Item>
                                    }
                                    <Dropdown.Item onClick={() => followSkipCtr('follow', item, opt)}>接題</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>

                                </div>
                                {/*opt.skipName ? (<div>跳題: {opt.skipName} </div>): <></>*/}
                                <div>
                                  { opt.check === true ? <span>預設選項 </span> : ''}
                                  { opt.isExtraText === true ? <span>接文字區塊 </span> : ''}
                                  { opt.followNames ? (<span>接題: {opt.followNames} </span>): <></>}
                                </div>
                              </div>
                            ))}
                            <div className="d-flex mt-15-p">
                              <LinkIconButton
                                imgName="add-icon.svg"
                                text="新增項目"
                                onClick={() => handleOptionChange(i)}
                              />
                            </div>
                          </div>
                          </>)
                          :
                          (<></>)}
                          {/** matrix */}
                          {item.ctrType === 'checkbox-matrix' ||
                          item.ctrType === 'radio-matrix' ||
                          item.ctrType === 'number-matrix' ?
                          (<>
                          <div className="ctr_matrix_block">
                            <div className="row g-0 mt-10-p">
                              <div className="col-md-5">列<br/>
                              {item.matrix?.rows.map((rowOpt, ri) => (
                                <div key={rowOpt.id} className="d-flex mr-1-m mt-10-p">
                                  <NormalInput
                                    isFloatTitle={false}
                                    placeholder={"請輸入選項" + (ri+1)}
                                    defaultValue={rowOpt.name}
                                    onChange={(e) => handleMatrixOptioNameChange(e, i, ri, 'row')}
                                  />
                                  <LinkIconButton
                                    imgName="job-close-icon.svg"
                                    text=""
                                    onClick={() => handleRemoveMatrixOption(i, ri, 'row')}
                                  />
                                </div>
                                ))}
                                <div className="d-flex mt-15-p">
                                  <LinkIconButton
                                    imgName="add-icon.svg"
                                    text="新增項目"
                                    onClick={() => handleAddMatrixOptionChange(i, 'row')}
                                  />

                                </div>
                              </div>
                              <div className="col-md-5">欄<br/>
                              {item.matrix?.columns.map((colOpt, oi) => (
                                <div key={colOpt.id} className="d-flex mt-10-p">
                                  <NormalInput
                                    isFloatTitle={false}
                                    placeholder={"請輸入選項" + (oi+1)}
                                    defaultValue={colOpt.name}
                                    onChange={(e) => handleMatrixOptioNameChange(e, i, oi, 'column')}
                                  />
                                  <LinkIconButton
                                    imgName="job-close-icon.svg"
                                    text=""
                                    onClick={() => handleRemoveMatrixOption(i, oi, 'column')}
                                  />
                                </div>
                                ))}
                                <div className="d-flex mt-15-p">
                                  <LinkIconButton
                                    imgName="add-icon.svg"
                                    text="新增項目"
                                    onClick={() => handleAddMatrixOptionChange(i, 'column')}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          </>)
                          :
                          (<></>)
                          }
                          {/** line */}
                          {item.ctrType === 'line' ?
                          (<>
                          <div className="ctr_line_block">
                            <div className="row g-0 mt-10-p">
                              <div className="col-md-3" style={{padding: '9px 0px'}}>
                                刻度起始
                              </div>
                              <div className="col-md-4 mr-1-m">
                                <CustDropdown
                                  placeholder={"1"}
                                  isFloatTitle={false}
                                  options={lineOptions}
                                  onChange={(e) => handleLineDDChange(e, i, 'startNum')}
                                />
                              </div>
                              <div className="col-md-4">
                                <NormalInput
                                  isFloatTitle={false}
                                  placeholder={"請輸入標籤(選填)"}
                                  onChange={(e) => handleLineTagChange(e, i, 'head')}
                                />
                              </div>
                            </div>
                            <div className="row g-0 mt-10-p">
                              <div className="col-md-3" style={{padding: '9px 0px'}}>
                                刻度上限
                              </div>
                              <div className="col-md-4 mr-1-m">
                                <CustDropdown
                                  placeholder={"10"}
                                  isFloatTitle={false}
                                  options={lineOptions}
                                  onChange={(e) => handleLineDDChange(e, i, 'endNum')}
                                />
                              </div>
                              <div className="col-md-4">
                                <NormalInput
                                  isFloatTitle={false}
                                  placeholder={"請輸入標籤(選填)"}
                                  onChange={(e) => handleLineTagChange(e, i, 'tail')}
                                />
                              </div>
                            </div>
                          </div>
                          </>)
                          :
                          (<></>)
                          }
                          {item.ctrType === 'line-agree' ?
                          (<>
                          <div className="ctr_line_block">
                            <div className="row g-0 mt-10-p">

                            </div>
                          </div>
                          </>)
                          :
                          (<></>)
                          }
                          {
                          item.ctrType === 'line-satisfy' ?
                          (<>
                          <div className="ctr_line_block">
                            <div className="row g-0 mt-10-p">

                            </div>
                          </div>
                          </>)
                          :
                          (<></>)
                          }
                          </div>
                     </div>
                      <div className={`${isSectionIsBlock(item.parentId) === true ? "" : "child"} add-control-block`}>
                        <div className="button-block">
                          {isSectionIsBlock(item.parentId) === true ? <>
                            <IconButton
                            imgName="add-icon.svg"
                            text="新增群組標題"
                            className="secondary"
                            onClick={() => handleAddTitle(item.sortIndex)}
                          />
                          </> : <></>}

                          <IconButton
                            imgName="add-bg-icon.svg"
                            text="新增欄位"
                            className="ml-16-p default"
                            onClick={() => handleAddControl(item.parentId, 'control', item.sortIndex)}
                          />
                        </div>
                      </div>
                     </> :
                      <></>}
                     </>)}


                  </div>
                ))}


              </div>

            </div>

          </div>

        </div>
        {/** 跳題/接題 modal */}
        <ContentModal {...modal}>
          {list.map((item: IndicatorControlModel, i: number) => (
            <div key={item.id} className="d-flex">
              {(item.isBlankSection !== true) ?
              <>
                {(item.ctrType === 'section') ||
                (item.ctrType !== 'section' && item.isIndependent === true) ? <div className="NumBlock">{i+1}</div> :
                <><div className="NumBlock">{i+1}</div><div className="ml-20-p">&nbsp;&nbsp;</div></>
                }
                {isSkip === true ?
                <>
                  {/*跳題*/ }
                  {/*<label className={`${(item.id === ctrSkip?.id) || (ctrSkip && ctrSkip?.sortIndex >= item.sortIndex)? 'custom-radio disabled' : 'custom-radio'}` }>
                    <input type="radio" name="skip"
                           checked={item.skipChecked }
                           disabled={(item.id === ctrSkip?.id) || (ctrSkip && ctrSkip?.sortIndex >= item.sortIndex)}
                           onChange={() => handleSkipCtrRadioChange(item)} />
                    <span className={`${(item.id === ctrSkip?.id) ? 'fw-bold' : ''}` }>{item.name}</span>
                  </label>
                  */}
                </> :
                <>
                  {/*接題*/ }
                  <label className={`${(item.id === ctrSkip?.id) || (ctrSkip && ctrSkip?.sortIndex >= item.sortIndex)? 'custom-checkbox disabled' : 'custom-checkbox'}` }>
                    <input type="checkbox" name="skip"
                           checked={item.followChecked }
                           disabled={(item.id === ctrSkip?.id) || (ctrSkip && ctrSkip?.sortIndex >= item.sortIndex)}
                           onChange={(e) => handleFollowCtrCheckBoxChange(item, e)} />
                    <span className={`${(item.id === ctrSkip?.id) ? 'fw-bold' : ''}` }>{item.name}</span>
                  </label>
                </>}

              </> : <></>}

            </div>
          ))}
          <div className="modal-footer" style={{margin: '9px -16px -15px -16px'}}>
            <div className="btn-box">
              <button className="secondary" onClick={() => handleCancelSkip()}>
              {`取消${isSkip === true ? '' : '接題'}`}
              </button>
              </div>
              <div className="btn-box">
              <button className="secondary" onClick={() => handleModal()}>
              {`取消`}
              </button>
              </div>
              <div className="btn-box">
              <button className="default" onClick={handleConfirmModal}>
                  {`儲存${isSkip === true ? '' : '接題'}`}
              </button>
            </div>
          </div>
        </ContentModal>

      </div>
    );
};

export default Edit;
