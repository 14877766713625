import React, { FC, useState } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import List from "./List/List";
import styles from "./SustainStandardRouter.module.scss";
import Edit from "./Edit/Edit";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import {
  FeaturePermissionCodes,
  PermissionRouteObjectModel,
  PermissionNavBarItemModel,
  ActionsPermissionCodes,
} from "models/auth/permissionModel";
import EmptyWaitPage from "components/common/EmptyWaitPage/EmptyWaitPage";
import useUserInfoStore from "state/useUserInfoStore";
import { usePermissionContext } from "context/PermissionProvider";



const SustainStandardRouter: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const [title, setTitle] = useState("");
  const { PermissionService } = usePermissionContext();
  const { permissionRouter } = PermissionService;

  const routers: PermissionRouteObjectModel[] = [
    {
      path: "",
      element: <List handleMessage={setTitle} />,
      featurePermission:
        FeaturePermissionCodes.SustainMatrixManage.MatrixManage,
      actionReadPermission:
        ActionsPermissionCodes.SustainMatrixManage.MatrixManageRead,
    },
    {
      path: ":id/edit",
      element: <Edit />,
      featurePermission:
        FeaturePermissionCodes.SustainMatrixManage.MatrixManage,
      actionReadPermission:
        ActionsPermissionCodes.SustainMatrixManage.MatrixManageRead,
    },
  ];
  //* ---------------------------- state end ---------------------------- *//

  let elements = useRoutes(permissionRouter(routers));
  return (
    <>
      <div className={styles["sustain-standard-router-layout"]}>
        <div className="no-nav-content-box">
          <CustomErrorBoundary>{elements}</CustomErrorBoundary>
        </div>
      </div>
    </>
  );
};

export default SustainStandardRouter;
