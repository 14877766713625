import React, { FC, useEffect, useRef } from "react";
import styles from "./ProgressBar.module.scss";
import { ProgressBarModel } from "models/common/progressBarModel";

interface ProgressBarProps {
  bgcolor?: string | "#29ACEC";
  option: ProgressBarModel;
  changeOption?: () => void;
}

const ProgressBar: FC<ProgressBarProps> = (props) => {
  const { option, bgcolor } = props; /** 停止 */
  const frameInterval = useRef<NodeJS.Timer>();
  const stop = useRef<boolean>(false);
  // 直接現在百分比
  const directNowPercent = useRef<number>(0);
  const fillBox = {
    height: "100%",
    // width: `${directNowPercent}%`,
    backgroundColor: bgcolor || "#29ACEC",
    borderRadius: "inherit",
  };

  useEffect(() => {
    if (!option.isDirectPercent) {
      fillProgressBar();
    } else {
      setProgressBar();
    }
    return () => {
      clearInterval(frameInterval.current);
      console.log("effect clear interval", frameInterval.current);
    };
  }, [option]);

  /** 填滿bar(前端假累計) */
  const fillProgressBar = () => {
    // if (!stop.current && option.isProcessing) {
    //   setTimeout(() => {
    //     const fillBarElement = document.getElementById(`fill-bar-${option.id}`);
    //     if (fillBarElement) {
    //       fillBarElement.style.width = `${option.nowPercent}%`;
    //       // const frame = () => {
    //       //   // 大於100趴或是外部傳入的暫停百分比即停止
    //       //   if (option.nowPercent >= 100) {
    //       //     // console.log('clear')
    //       //     clearInterval(frameInterval);
    //       //     // this.stop = true;
    //       //   } else {
    //       //     option.nowPercent++;
    //       //     fillBarElement.style.width = `${option.nowPercent}%`;
    //       //   }
    //       // };
    //       // 預設1000
    //       // const rate = option.rate? 1000: option.rate;
    //       // 執行進度條
    //       // const frameInterval = setInterval(frame, rate);
    //     }
    //   }, 10);
    // }
  };

  /** 設置bar(不自己累加) */
  const setProgressBar = () => {
    const fillBarElement = document.getElementById(`fill-bar-${option.id}`);
    if (fillBarElement) {
      fillBarElement.style.width = `${directNowPercent.current}%`;
      if (frameInterval.current !== null) {     
        const frame = () => {
          // 大於100趴或是外部傳入的暫停百分比即停止
          if (
            directNowPercent.current >= 100 ||
            directNowPercent.current >= option.pausePercent
          ) {
            clearInterval(frameInterval.current);
          } else {
            directNowPercent.current++;
            fillBarElement.style.width = `${directNowPercent.current}%`;
          }
        };
        // 預設1000
        const rate = option.rate ? option.rate : 1000;
        // 執行進度條
        frameInterval.current = setInterval(frame, rate);
      }
    }
  };

  return (
    <div className={styles["progress-bar-box"]}>
      <div id={`fill-bar-${option.id}`} style={fillBox}></div>
    </div>
  );
};

export default ProgressBar;
