import CryptoJS from 'crypto-js';

// 16 字節密碼用於 AES-256
const secretKey = CryptoJS.enc.Utf8.parse('1cr76jgp2is011zfyy4594finc6d4ory');
// 16 字節 IV 用於 AES
const iv =  CryptoJS.enc.Utf8.parse('4vb8e0qyq57pxh49'); 

/** 加密函數 */
const encrypt = (text: string) => {
    const encrypted = CryptoJS.AES.encrypt(text, secretKey, { iv: iv });
    return encrypted.toString();
}

/** 解密函數 */
const decrypt = (encryptedText: string) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
}

const CryptoService = {
    encrypt,
    decrypt
}

export default CryptoService;