import { FC } from "react";
import { NavLink } from "react-router-dom";
import { NavBarItemModel } from "../../../models/baseModel";
interface NavbarItemProps {
  item: NavBarItemModel;
}

const NavbarItem: FC<NavbarItemProps> = (props) => {
  const { item } = props;
  return (
    <li className={"navbar-item-box"}>
      <NavLink className={"navbar-text"} to={item.url}>
        {item.name}
      </NavLink>
    </li>
  );
};

export default NavbarItem;
