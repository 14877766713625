//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect, useContext } from "react";
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./List.module.scss";
import SustainQuestionnaireService from "services/application/sustainQuestionnaire/sustainQuestionnaireService";
import {
  QuestuinnaireSampleRequestModel,
  QuestionnaireDataListRequestModel,
  SustainQuestionnaireListDataModel,
  QuestionnaireData,
} from "models/application/sustainQuestionnaire/sustainQuestionnaireModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import BackTitle from "components/base/BackTitle/BackTitle";
import { LayoutContext } from "pages/Application/SustainQuestionnaire/SustainQuestionnaireLayout/SustainQuestionnaireLayout";
import TablePagination from "components/base/TablePagination/TablePagination";
import { PaginationModel } from "models/baseModel";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import IconButton from "components/button/IconButton/IconButton";
import { success2Alert, error2Alert } from "utils/otherToast";

//* ---------------------------- local end ---------------------------- *//

const List: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  //- params
  const params = useParams();
  //- year
  const year = params.year;
  //- sampleId
  const sampleId = params.sampleId;
  //- navigate
  const navigate = useNavigate();
  //- layoutData
  const { SustainQuestionnaireSampleModel } = useContext(LayoutContext);
  //- questionnaireDataListRequestModel
  const [
    questionnaireDataListRequestModel,
    setQuestionnaireDataListRequestModel,
  ] = useState<QuestionnaireDataListRequestModel>(
    new QuestionnaireDataListRequestModel()
  );
  //- tablePagination
  const [tablePagination, setTablePagination] = useState<PaginationModel>({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  //- sustainQuestionnaireListData
  const [sustainQuestionnaireListData, setSustainQuestionnaireListData] =
    useState<SustainQuestionnaireListDataModel>();
  //- questionnaireDataList
  const [questionnaireDataList, setQuestionnaireDataList] = useState<{
    count: number;
    list: QuestionnaireData[];
  }>({
    count: 0,
    list: [],
  });
  //- batchOperateList
  const [batchOperateList, setBatchOperateList] = useState<string[]>([]);
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getQuestionnaireSamples
  const {
    mutate: getQuestionnaireSamplesMutate,
    isPending: getQuestionnaireSamplesIsPending,
  } = useMutation({
    mutationFn: (request: QuestionnaireDataListRequestModel) =>
      SustainQuestionnaireService.getQuestionnaireSamples(request),
    onSuccess: (res) => {
      console.log("getQuestionnaireSamples", res);
      if (res.code === 200 && res.success) {
        setSustainQuestionnaireListData(res.data);
        //- 如果是單筆問卷 直接進入編輯頁面
        if (!SustainQuestionnaireSampleModel?.isMultiple) {
          if (res.data.questionnaireDataList.length) {
            navigateToEditPage(res.data.questionnaireDataList[0].dataId!);
          } else {
            navigateToEditPage("-1");
          }
          return;
        }
        setQuestionnaireDataList({
          count: res.count!,
          list: res.data.questionnaireDataList,
        });
      } else {
        setSustainQuestionnaireListData(undefined);
        setQuestionnaireDataList({
          count: 0,
          list: [],
        });
      }
    },
    onError: (err) => {
      console.log("getQuestionnaireSamples", err);
    },
  });

  //- deleteQuestionnaireDatas
  const {
    mutate: deleteQuestionnaireDatasMutate,
    isPending: deleteQuestionnaireDatasIsPending,
  } = useMutation({
    mutationFn: (idList: string[]) =>
      SustainQuestionnaireService.deleteQuestionnaireDatas(idList),
    onSuccess: (res) => {
      console.log("deleteQuestionnaireDatas", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        getQuestionnaireSamplesMutate(questionnaireDataListRequestModel, {
          onSuccess: () => setBatchOperateList([]),
        });
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("deleteQuestionnaireDatas", err);
      error2Alert("執行失敗");
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  //- navigateToEditPage
  const navigateToEditPage = (dataId: string) => {
    navigate(`/apply/sustain-questionnaire/${year}/${sampleId}/edit/${dataId}`);
  };
  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init
  useEffect(() => {
    if (
      !year ||
      !sampleId ||
      !SustainQuestionnaireSampleModel ||
      sampleId !== SustainQuestionnaireSampleModel.sampleId
    )
      return;
    console.log(year, sampleId, SustainQuestionnaireSampleModel);
    setQuestionnaireDataListRequestModel({
      ...new QuestionnaireDataListRequestModel(),
      questionnaireSampleId: sampleId,
    });
  }, [year, sampleId, SustainQuestionnaireSampleModel]);

  //- useEffect questionnaireDataListRequestModel
  useEffect(() => {
    if (!questionnaireDataListRequestModel.questionnaireSampleId) return;
    console.log(questionnaireDataListRequestModel);
    getQuestionnaireSamplesMutate(questionnaireDataListRequestModel);
  }, [questionnaireDataListRequestModel, getQuestionnaireSamplesMutate]);

  // -useEffect declareDataCorrectionRequestList
  useEffect(() => {
    setTablePagination((prev) => {
      if (questionnaireDataList?.list.length) {
        return {
          ...prev,
          page: questionnaireDataListRequestModel.page ?? 1,
          pageSize: questionnaireDataListRequestModel.pageSize ?? 10,
          total: questionnaireDataList.count,
        };
      } else {
        return {
          ...prev,
          page: 0,
          total: 0,
        };
      }
    });
    setBatchOperateList([]);
  }, [questionnaireDataList, questionnaireDataListRequestModel]);

  //* ---------------------------- hook end ---------------------------- *//

  if (!year || !sampleId || !SustainQuestionnaireSampleModel) {
    return <VisuallLoading />;
  }
  return (
    <div className={`${styles["list-box"]}`}>
      <div className="list-title-box">
        <div className="left">{SustainQuestionnaireSampleModel.sampleName}</div>
        <div className="right">
          {batchOperateList.length ? (
            <div className={`select-item`}>
              <IconButton
                imgName="trash-icon.svg"
                text="刪除"
                className="secondary"
                onClick={() => {
                  deleteQuestionnaireDatasMutate(batchOperateList);
                }}
              />
            </div>
          ) : (
            <button
              className="default"
              onClick={() => {
                navigateToEditPage("-1");
              }}
            >
              +新增
            </button>
          )}
        </div>
      </div>
      <div
        className={`${styles["list-table-box"]} list-table-scroll-box scroll`}
      >
        {getQuestionnaireSamplesIsPending ||
        deleteQuestionnaireDatasIsPending ? (
          <VisuallLoading />
        ) : null}
        <ScrollToTopButton
          targetClassName={"list-table-scroll-box"}
          bottom={90}
          right={35}
        />
        <table
          aria-label="table"
          className={`${styles["questionnaire-table"]} table-container sticky-table`}
        >
          <thead>
            <tr>
              <th
                scope="col"
                className={`${styles["setting-checkbox"]}`}
                onClick={(e) => e.stopPropagation()}
              >
                <label className={`custom-checkbox hideText`}>
                  <input
                    type="checkbox"
                    disabled={!questionnaireDataList.list.length}
                    checked={
                      questionnaireDataList.list.length > 0 &&
                      questionnaireDataList.list.length ===
                        batchOperateList.length
                    }
                    onChange={() => {
                      if (
                        questionnaireDataList.list.length ===
                        batchOperateList.length
                      ) {
                        setBatchOperateList([]);
                      } else {
                        setBatchOperateList(
                          questionnaireDataList.list.map((e) => e.dataId!)
                        );
                      }
                    }}
                  />
                  <span
                    className={`${
                      batchOperateList.length > 0 &&
                      questionnaireDataList.list.length >
                        batchOperateList.length
                        ? "partial"
                        : ""
                    }`}
                  ></span>
                </label>
              </th>
              <th scope="col">活動序號</th>
              {sustainQuestionnaireListData?.titleWithControlId?.length ? (
                <>
                  {sustainQuestionnaireListData.titleWithControlId?.map(
                    (item, index) => (
                      <th key={index} scope="col">
                        {item.name}
                      </th>
                    )
                  )}
                </>
              ) : null}
              {/* <th scope="col">狀態</th> */}
              <th scope="col">建立日期</th>
              <th scope="col">最後編輯日期</th>
              <th scope="col">功能</th>
            </tr>
          </thead>
          <tbody>
            {questionnaireDataList.list?.length === 0 ? (
              <tr>
                <td
                  colSpan={
                    sustainQuestionnaireListData?.titleWithControlId?.length
                      ? 6 +
                        sustainQuestionnaireListData?.titleWithControlId?.length
                      : 6
                  }
                >
                  查無資料
                </td>
              </tr>
            ) : (
              questionnaireDataList.list?.map((item, index) => (
                <tr key={index}>
                  <td
                    className={`${styles["setting-checkbox"]}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <label className={`custom-checkbox hideText`}>
                      <input
                        type="checkbox"
                        checked={batchOperateList.includes(item.dataId!)}
                        onChange={() => {
                          if (batchOperateList.includes(item.dataId!)) {
                            setBatchOperateList((prev) =>
                              prev.filter((e) => e !== item.dataId!)
                            );
                          } else {
                            setBatchOperateList((prev) => [
                              ...prev,
                              item.dataId!,
                            ]);
                          }
                        }}
                      />
                      <span></span>
                    </label>
                  </td>
                  <td>{item.sno}</td>
                  {item.columns.length ? (
                    <>
                      {item.columns.map((column, cIndex) => (
                        <td key={cIndex}>{column.value || "-"}</td>
                      ))}
                    </>
                  ) : null}
                  {/* <td>{item.isDelared ? "已申報" : "已儲存"}</td> */}
                  <td>
                    {item.createTime
                      ? moment(item.createTime).format("yyyy-MM-DD")
                      : "-"}
                  </td>
                  <td>
                    {item.lastUpdateTime
                      ? moment(item.lastUpdateTime).format("yyyy-MM-DD")
                      : "-"}
                  </td>
                  <td>
                    <button
                      className="secondary operator-Btn"
                      onClick={() => navigateToEditPage(item.dataId!)}
                    >
                      修改
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className={`${styles["table-pagination-box"]}`}>
        <TablePagination
          total={tablePagination.total!}
          onChange={(e) => {
            setQuestionnaireDataListRequestModel((prev) => ({
              ...prev,
              page: e.page,
              pageSize: e.pageSize,
            }));
          }}
          page={tablePagination.page}
          pageSize={tablePagination.pageSize}
        />
      </div>
    </div>
  );
};

export default List;
