import { HttpClient } from "services/httpClient";
import StorageName from "../../constants/storageName";
import { AuthInfoModel, AuthModel, TokenRequestModel } from "models/auth/authModel";
const httpClient = new HttpClient();

/**  產生 access token */
const getAccessTokenByAccountId = (model: TokenRequestModel) => {
    return httpClient.post('auth/token', model);
}

/** 更新 access token */
const refresh = (model: TokenRequestModel) => {
    return httpClient.post('auth/token/refresh', model);
}

/** 進行認證並回傳登入資訊 */
const auth = (model:AuthModel) => {
    return httpClient.post<AuthInfoModel>(`auth?userId=${model.userId}&&eventKey=${model.eventKey}`);
}

/** for前端開發用 */
const getUserIdOption = (type:number = 0)=>{
    return httpClient.get(`auth/option?type=${type}`);

}

// 以下暫無用到

// 從 localStorage 讀取 token
const getAuthToken = (): string | null => {
    return localStorage.getItem(StorageName.TOKEN_NAME);
};

// 從 localStorage 讀取 token
const getLanguage = (): string | null => {
    return localStorage.getItem(StorageName.LANGUAGE);
};

// 將 refreshToken 存到 localStorage
const setLanguage = (lang: string) => {
    localStorage.setItem(StorageName.LANGUAGE, lang);
};

/** token 是否到期 */
const tokenExpire = (): boolean => {
    let pass = true;
    let token = getAuthToken();
    if (token) {
        console.log(token)
        // 若token存在，則判斷token到期時間
        let exp = Number(JSON.parse(atob(token.split('.')[1])).exp + '000');

        console.log('exp', exp)
        console.log('now', Date.now());
        pass = exp > Date.now();
    }
    return pass;
}

const AuthService = {
    getAccessTokenByAccountId,
    refresh,
    auth,
    setLanguage,
    getUserIdOption,
    tokenExpire,
    getLanguage
};

export default AuthService;