import React, { FC, useEffect, useState } from "react";
import styles from "./EducationPropaganda.module.scss";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { useMutation } from "@tanstack/react-query";
import EduPropagandaResourceService from "services/application/EducationPropaganda/eduPropagandaResource";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { ResponseCode } from "models/responseCodeModel";
import { error2Alert, successAlert } from "utils/otherToast";
import {
  EduPropagandaResourceFileModel,
  EduPropagandaResourceListRequestModel,
} from "models/application/educationPropaganda/eduPropagandaResourceModel";
import { CommonService } from "services/common/commonService";
import FloatCalculateService from "services/common/floatCalculateService";

interface EducationPropagandaProps {}

const EducationPropaganda: FC<EducationPropagandaProps> = () => {
  const [fileArr, setFileArr] = useState<
    EduPropagandaResourceListRequestModel[]
  >([]);


  //#region 取得教育宣傳資源的所有檔案
  const getEduPropagandaResourceFileList = useMutation({
    mutationFn: () =>
      EduPropagandaResourceService.getEduPropagandaResourceFileList(),
    onSuccess: (res) => {
      if (res.code === ResponseCode.SuccessOK && res.success) {
        console.log(res.data);
        setFileArr([...res.data]);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    getEduPropagandaResourceFileList.mutate();
  }, []);

  //#endregion

  
  //#region 檔案下載
  const getEduPropagandaResourceFileStream = useMutation({
    mutationFn: (fileId:string) =>
      EduPropagandaResourceService.getEduPropagandaResourceFileStream(fileId),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        successAlert("下載成功");
        CommonService.downloadByStream(res);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  
  const donwload = (file: EduPropagandaResourceFileModel) => {
    getEduPropagandaResourceFileStream.mutate(file.id);
  };
  //#endregion

  return (
    <div className="no-nav-content-box">
      { (getEduPropagandaResourceFileList.isPending ||
      getEduPropagandaResourceFileStream.isPending) && <VisuallLoading />}
      <div className={styles["propaganda-box"]}>
        {/* title */}
        <div className={styles.title}>教育宣導資源</div>
        {/* 內容 */}
        <div className={`${styles["propaganda-content-box"]} scroll`}>
          {fileArr.map((item, itemIndex) => {
            return (
              <div
                key={`resource_${itemIndex}`}
                className={styles["propaganda-item-box"]}
              >
                <div className={styles.desc}>{item.name}:</div>
                <div
                  className={`${styles["file-box"]} container-fluid  scroll`}
                >
                  <div className="row no-gutters ">
                    {item.files?.map((file, fileIndex) => {
                      return (
                        <div
                          key={`resource_${itemIndex}_file_${fileIndex}`}
                          className="col-6 mb-20-p"
                          onClick={()=>{                            
                            donwload(file);
                          }}
                        >
                          <div className={styles["file-item"]}>
                            <div>
                              <LinkIconButton
                                imgName="download-icon.svg"
                                text={file.fileName}
                                onClick={() => {
                                }}
                              />
                            </div>
                            {/* 檔案大小 */}
                            <div className={styles["file-size"]}>
                              ({FloatCalculateService.formatFileSize(file.fileSize)})
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EducationPropaganda;
