//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect, useContext, useRef, ReactNode } from "react";
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./Edit.module.scss";
import SustainQuestionnaireService from "services/application/sustainQuestionnaire/sustainQuestionnaireService";
import {
  QuestuinnaireSampleRequestModel,
  QuestionnaireDataListRequestModel,
  SustainQuestionnaireListDataModel,
  QuestionnaireData,
  QuestionnaireSampleDataModel,
  SectionModel,
  SectionItemModel,
  ControlModel,
  OptionModel,
  SampleControlFileModel,
} from "models/application/sustainQuestionnaire/sustainQuestionnaireModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { LayoutContext } from "pages/Application/SustainQuestionnaire/SustainQuestionnaireLayout/SustainQuestionnaireLayout";
import TablePagination from "components/base/TablePagination/TablePagination";
import { PaginationModel } from "models/baseModel";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { CommonService } from "services/common/commonService";
import NormalInput from "components/base/NormalInput/NormalInput";
import NormalTextarea from "components/base/NormalTextarea/NormalTextarea";
import { success2Alert, error2Alert } from "utils/otherToast";
import Dropdown from "components/base/Dropdown/Dropdown";
import { OptionModel as BasicOptionModel } from "models/baseModel";
import MultiSelect from "components/base/MultiSelect/MultiSelect";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";
//! specificRules
import {
  SpecificRulesData,
  SampleControlIdCorrespondsValueModel,
} from "models/application/sustainQuestionnaire/specificRulesModel";
import { useEditSpecialRules } from "./EditSpecialRulesProvider";
//* ---------------------------- local end ---------------------------- *//

const Edit: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  //- params
  const params = useParams();
  //- year
  const year = params.year;
  //- sampleId
  const sampleId = params.sampleId;
  //- dataId
  const dataId = params.dataId;
  //- navigate
  const navigate = useNavigate();
  //- layoutData
  const { SustainQuestionnaireSampleModel } = useContext(LayoutContext);
  //- QuestionnaireSampleDataModel
  const [questionnaireSampleData, setQuestionnaireSampleData] =
    useState<QuestionnaireSampleDataModel>(new QuestionnaireSampleDataModel());
  //- QuestionnaireSampleDataIsChangedCount
  // const [
  //   questionnaireSampleDataIsChangedCount,
  //   setQuestionnaireSampleDataIsChangedCount,
  // ] = useState<number>(0);
  // //- detectQuestionnaireSampleDataIsChanged
  // const [
  //   detectQuestionnaireSampleDataIsChanged,
  //   setDetectQuestionnaireSampleDataIsChanged,
  // ] = useState<boolean>(false);
  //- questionnaireSampleDataRef
  const questionnaireSampleDataRef = useRef(questionnaireSampleData);
  //- hasOptionsControlTypeList
  const hasOptionsControlTypeList: string[] = [
    "radio",
    "checkbox",
    "select",
    "select-multi",
  ];
  //- hasSingleValueControlTypeList
  const hasSingleValueControlTypeList: string[] = ["radio", "select"];
  //- hasMultiValueControlTypeList
  const hasMultiValueControlTypeList: string[] = ["checkbox", "select-multi"];
  //- controlCommentImageModal
  const [controlCommentImageModal, setControlCommentImageModal] =
    useState<ContentModalProps>({
      show: false,
      handleClose: () => closeControlCommentImageModel(),
      customClass: "control-comment-image-modal",
      backdrop: true,
      keyboard: true,
    });
  //- selectedControlFileModel
  const [selectedControlFileModel, setSelectedControlFileModel] =
    useState<SampleControlFileModel>(new SampleControlFileModel());
  //! specificRules - specificRulesData
  const [specificRulesData, setSpecificRulesData] = useState<any>(); //TODO
  //! specificRules - SpecificRulesService
  const { SpecificRulesService } = useEditSpecialRules();
  //! specificRules - validSaveSpecificRulesIsPending
  const [validSaveSpecificRulesIsPending, setValidSaveSpecificRulesIsPending] =
    useState<boolean>(false);
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getQuestionnaireData
  const {
    mutate: getQuestionnaireDataMutate,
    isPending: getQuestionnaireDataIsPending,
  } = useMutation({
    mutationFn: (request: { sampleId: string; dataId?: string }) =>
      SustainQuestionnaireService.getQuestionnaireData(
        request.sampleId,
        request.dataId
      ),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        console.log("getQuestionnaireData", res.data);
        questionnaireDataInit(res.data);
      } else {
        console.log("getQuestionnaireData", res);
      }
    },
    onError: (err) => {
      console.log("getQuestionnaireData", err);
    },
  });

  //- addSustainQuestionnaireData
  const {
    mutate: addSustainQuestionnaireDataMutate,
    isPending: addSustainQuestionnaireDataIsPending,
  } = useMutation({
    mutationFn: (data: QuestionnaireSampleDataModel) =>
      SustainQuestionnaireService.addSustainQuestionnaireData(data),
    onSuccess: (res) => {
      console.log("addSustainQuestionnaireData", res);
      if (res.code === 200 && res.success) {
        // success2Alert(res.message);
        // navigate(`/admin/questionnaire/setting`);
        // getQuestionnaireListMutate(questionnaireListRequestModel);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("addSustainQuestionnaireData", err);
      error2Alert("執行失敗");
    },
  });

  //- saveSustainQuestionnaireData
  const {
    mutate: saveSustainQuestionnaireDataMutate,
    isPending: saveSustainQuestionnaireDataIsPending,
  } = useMutation({
    mutationFn: (data: QuestionnaireSampleDataModel) =>
      SustainQuestionnaireService.saveSustainQuestionnaireData(data),
    onSuccess: (res) => {
      console.log("saveSustainQuestionnaireData", res);
      if (res.code === 200 && res.success) {
        // success2Alert(res.message);
        // getQuestionnaireSettingDataMutate(questionnaireId!);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("saveSustainQuestionnaireData", err);
      error2Alert("執行失敗");
    },
  });

  //- getQuestionnaireSamples
  const {
    mutate: getQuestionnaireSamplesMutate,
    isPending: getQuestionnaireSamplesIsPending,
  } = useMutation({
    mutationFn: (request: QuestionnaireDataListRequestModel) =>
      SustainQuestionnaireService.getQuestionnaireSamples(request),
    onSuccess: (res) => {
      console.log("getQuestionnaireSamples", res);
      // if (res.code === 200 && res.success) {
      //   success2Alert(res.message);
      //   // getQuestionnaireSettingDataMutate(questionnaireId!);
      // } else {
      //   error2Alert(res.message);
      // }
    },
    onError: (err) => {
      console.log("getQuestionnaireSamples", err);
      error2Alert("執行失敗");
    },
  });

  //- declareSustainQuestionnaireData
  const {
    mutate: declareSustainQuestionnaireDataMutate,
    isPending: declareSustainQuestionnaireDataIsPending,
  } = useMutation({
    mutationFn: (dataId: string) =>
      SustainQuestionnaireService.declareSustainQuestionnaireData(dataId),
    onSuccess: (res) => {
      console.log("declareSustainQuestionnaireData", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        // getQuestionnaireSettingDataMutate(questionnaireId!);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("declareSustainQuestionnaireData", err);
      error2Alert("執行失敗");
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  //- backToList
  const backToList = () => {
    navigate(`/apply/sustain-questionnaire/${year}/${sampleId}/list`);
  };

  //- questionnaireDataInit
  const questionnaireDataInit = (data: QuestionnaireSampleDataModel) => {
    const sectionData = data.sectionDatas.find((e) => !e.isNecessary)!;
    const tempFollowingControlIdList: string[] = [];
    sectionData.sections = sectionData.sections.map((section) => {
      section.controls = section.controls.map((control) => {
        control.optionsVal = control.optionsVal.map((option) => {
          return { ...new OptionModel(), ...option };
        });
        control.webOptions = control.optionsVal.map((option) => {
          return {
            id: option.id,
            text: option.text,
          };
        });
        if (hasOptionsControlTypeList.includes(control.ctrType!)) {
          control.optionsVal.forEach((option) => {
            const shouldAddFollowingControlIds =
              hasSingleValueControlTypeList.includes(control.ctrType)
                ? control.value !== option.id
                : !option.check;
            if (
              shouldAddFollowingControlIds &&
              option.followingControlIds?.length
            ) {
              option.followingControlIds.forEach((id) => {
                if (!tempFollowingControlIdList.includes(id)) {
                  tempFollowingControlIdList.push(id);
                }
              });
            }
          });
        }
        //! specificRules
        if (
          specificRulesData &&
          specificRulesData.controlsInitPropertyData.find(
            (e: any) => e.sampleControlId === control.sampleControlId
          )
        ) {
          const specificControlPropertyData =
            specificRulesData.controlsInitPropertyData.find(
              (e: any) => e.sampleControlId === control.sampleControlId
            );
          return {
            ...new ControlModel(),
            ...control,
            ...specificControlPropertyData,
          };
        } else {
          return { ...new ControlModel(), ...control };
        }
      });
      return { ...new SectionItemModel(), ...section };
    });
    sectionData.sections.forEach((section) => {
      section.controls = section.controls.map((control) => {
        if (tempFollowingControlIdList.includes(control.sampleControlId)) {
          control.isFollowingControlDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isFollowingControlDisabled = false;
        }
        return control;
      });
    });
    data.controls = data.controls.map((control) => {
      return (
        sectionData.sections
          .flatMap((section) => section.controls)
          .find((e) => e.sampleControlId === control.sampleControlId) || control
      );
    });
    //! specificRules
    if (specificRulesData) {
      data.controls = handleSpecificRules(data.controls);
    }
    if (specificRulesData) {
      data.controls = handleSpecificRules(data.controls);
      sectionData.sections = sectionData.sections.map((section) => {
        section.controls = section.controls.map((controlItem: ControlModel) => {
          const specificUpdateControl = data.controls.find(
            (e) => e.sampleControlId === controlItem.sampleControlId
          );
          if (specificUpdateControl) {
            return specificUpdateControl;
          }
          return controlItem;
        });
        return section;
      });
    }

    console.log("questionnaireDataInit", data);
    // setFollowingControlIdList(followingControlIdListSet);
    setQuestionnaireSampleData(data);
    // setQuestionnaireSampleDataIsChangedCount(0);
    // setDetectQuestionnaireSampleDataIsChanged(false);
  };

  //- sectionsData
  const sectionsData = (): SectionItemModel[] => {
    return (
      questionnaireSampleData.sectionDatas.find((e) => !e.isNecessary)
        ?.sections || []
    );
  };

  //- resetControlValue
  const resetControlValue = (control: ControlModel): ControlModel => {
    return {
      ...control,
      value: undefined,
      optionsVal: control.optionsVal.map((option) => {
        option.check = false;
        if (option.isExtraText) {
          option.extraTextValue = undefined;
        }
        return option;
      }),
    };
  };

  //- setControlsAndSectionDatasSameTime
  const setControlsAndSectionDatasSameTime = (updateControl: ControlModel) => {
    let newControls = CommonService.deepClone(
      questionnaireSampleDataRef.current.controls
    );
    let newSectionDatas = CommonService.deepClone(
      questionnaireSampleDataRef.current.sectionDatas.find(
        (e) => !e.isNecessary
      )?.sections!
    );

    //> update control
    newControls = newControls.map((controlItem: ControlModel) => {
      if (controlItem.sampleControlId === updateControl.sampleControlId) {
        return updateControl;
      }
      return controlItem;
    });
    newSectionDatas = newSectionDatas.map((section) => {
      section.controls = section.controls.map((controlItem: ControlModel) => {
        if (controlItem.sampleControlId === updateControl.sampleControlId) {
          return updateControl;
        }
        return controlItem;
      });
      return section;
    });

    //> handle followingControlIds
    const handleFollowingControlIds = (handleUpdateControl: ControlModel) => {
      if (
        hasOptionsControlTypeList.includes(handleUpdateControl.ctrType) &&
        handleUpdateControl.optionsVal.some(
          (e) => e.followingControlIds?.length
        )
      ) {
        const reUpdateControls = (
          controls: ControlModel[],
          disabledControlIds: Set<string>
        ): ControlModel[] => {
          return controls.map((control) => {
            if (disabledControlIds.has(control.sampleControlId)) {
              control.isFollowingControlDisabled = true;
              control = resetControlValue(control);

              if (hasOptionsControlTypeList.includes(control.ctrType!)) {
                control.optionsVal.forEach((option) => {
                  const shouldAddFollowingControlIds =
                    hasSingleValueControlTypeList.includes(control.ctrType)
                      ? control.value !== option.id
                      : !option.check;
                  if (
                    shouldAddFollowingControlIds &&
                    option.followingControlIds?.length
                  ) {
                    option.followingControlIds.forEach((id) => {
                      if (!disabledControlIds.has(id)) {
                        disabledControlIds.add(id);
                      }
                    });
                  }
                });
              }

              // control.optionsVal.forEach((option) => {
              // 	if (!option.check && option.followingControlIds?.length) {
              // 		option.followingControlIds.forEach((id) => {
              // 			if (!disabledControlIds.has(id)) {
              // 				disabledControlIds.add(id);
              // 			}
              // 		});
              // 	}
              // });
            } else {
              control.isFollowingControlDisabled = false;
            }

            return control;
          });
        };

        const tempFollowingControlIdList: Set<string> = new Set();
        newSectionDatas.forEach((section) => {
          section.controls.forEach((control) => {
            //- 把所有的followingControlIds加入一個temp陣列
            const sameControlAllFollowingControlIds = new Set(
              control.optionsVal.flatMap((e) => e.followingControlIds)
            );
            //- 再把裡面的filter掉 check的followingConrolId (因為可能有重複)
            if (hasOptionsControlTypeList.includes(control.ctrType!)) {
              control.optionsVal.forEach((option) => {
                const shouldDeleteFollowingControlIds =
                  hasSingleValueControlTypeList.includes(control.ctrType)
                    ? control.value === option.id
                    : option.check;
                if (
                  shouldDeleteFollowingControlIds &&
                  option.followingControlIds?.length
                ) {
                  option.followingControlIds.forEach((id) => {
                    if (sameControlAllFollowingControlIds.has(id)) {
                      sameControlAllFollowingControlIds.delete(id);
                    }
                  });
                }
              });
            }

            // control.optionsVal.forEach((option) => {
            // 	if (option.check && option.followingControlIds?.length) {
            // 		option.followingControlIds.forEach((id) => {
            // 			if (sameControlAllFollowingControlIds.has(id)) {
            // 				sameControlAllFollowingControlIds.delete(id);
            // 			}
            // 		});
            // 	}
            // });
            //- 把剩下的加入tempFollowingControlIdList
            sameControlAllFollowingControlIds.forEach((id) => {
              if (!tempFollowingControlIdList.has(id)) {
                tempFollowingControlIdList.add(id);
              }
            });
          });
        });

        newSectionDatas.forEach((section) => {
          section.controls = reUpdateControls(
            section.controls,
            tempFollowingControlIdList
          );
        });

        //> update controls
        newControls = newControls.map((control) => {
          return (
            newSectionDatas
              .flatMap((section) => section.controls)
              .find((e) => e.sampleControlId === control.sampleControlId) ||
            control
          );
        });
      }
    };

    //> run handle followingControlIds
    handleFollowingControlIds(updateControl);

    //! specificRules
    if (specificRulesData) {
      newControls = handleSpecificRules(newControls);
      newSectionDatas = newSectionDatas.map((section) => {
        section.controls = section.controls.map((controlItem: ControlModel) => {
          const specificUpdateControl = newControls.find(
            (e) => e.sampleControlId === controlItem.sampleControlId
          );
          if (specificUpdateControl) {
            return specificUpdateControl;
          }
          return controlItem;
        });
        return section;
      });
      newSectionDatas.forEach((section) => {
        section.controls.forEach((control) => {
          if (
            hasOptionsControlTypeList.includes(control.ctrType) &&
            control.optionsVal.some((e) => e.followingControlIds?.length)
          ) {
            handleFollowingControlIds(control);
          }
        });
      });
    }

    //> set
    setQuestionnaireSampleData((prev) => ({
      ...prev,
      controls: newControls,
      sectionDatas: questionnaireSampleData.sectionDatas.map(
        (sectionData: SectionModel) => {
          if (!sectionData.isNecessary) {
            return {
              ...sectionData,
              sections: newSectionDatas,
            };
          }
          return sectionData;
        }
      ),
    }));
  };

  //- handleSaveSustainQuestionnaireData
  const handleSaveSustainQuestionnaireData = async (
    action: "saveAndNew" | "save" | "declare"
  ) => {
    if (
      addSustainQuestionnaireDataIsPending ||
      saveSustainQuestionnaireDataIsPending ||
      getQuestionnaireSamplesIsPending ||
      declareSustainQuestionnaireDataIsPending ||
      validSaveSpecificRulesIsPending
    )
      return;
    // console.log(questionnaireSampleData);
    if (!questionnaireSampleData) return;
    if (!validateQuestionnaireData()) return;
    // declare 特殊規則驗證
    let isValidSaveSpecificRules = true;
    if (action === "declare") {
      setValidSaveSpecificRulesIsPending(true);
      isValidSaveSpecificRules =
        await SpecificRulesService.ValidSaveSpecificRules(
          questionnaireSampleData
        );
      setValidSaveSpecificRulesIsPending(false);
    }
    if (!isValidSaveSpecificRules) return;

    // console.log("isValid");
    const storageQuestionnaireSampleData = CommonService.deepClone(
      questionnaireSampleData
    );
    if (dataId === "-1") {
      console.log("add");
      // storageQuestionnaireSampleData.dataId = uuid();
      addSustainQuestionnaireDataMutate(storageQuestionnaireSampleData, {
        onSuccess: (res) => {
          if (res.code === 200 && res.success) {
            if (action === "save") {
              success2Alert(res.message);
              if (SustainQuestionnaireSampleModel?.isMultiple) {
                backToList();
              } else {
                getQuestionnaireSamplesMutate(
                  {
                    ...new QuestionnaireDataListRequestModel(),
                    questionnaireSampleId: sampleId!,
                    pageSize: 5,
                    isMultiple: SustainQuestionnaireSampleModel?.isMultiple,
                  },
                  {
                    onSuccess: (secondRes) => {
                      if (
                        secondRes.code === 200 &&
                        secondRes.success &&
                        secondRes.data?.questionnaireDataList[0]?.dataId
                      ) {
                        const singleQuestionnaireDataId =
                          secondRes.data.questionnaireDataList[0].dataId;
                        navigate(
                          `/apply/sustain-questionnaire/${year}/${sampleId}/edit/${singleQuestionnaireDataId}`
                        );
                      }
                    },
                  }
                );
              }
            } else if (action === "saveAndNew") {
              getQuestionnaireSamplesMutate(
                {
                  ...new QuestionnaireDataListRequestModel(),
                  questionnaireSampleId: sampleId!,
                  pageSize: 9999,
                },
                {
                  onSuccess: (secondRes) => {
                    if (
                      secondRes.code === 200 &&
                      secondRes.success &&
                      secondRes.data?.questionnaireDataList.length
                    ) {
                      const response: SustainQuestionnaireListDataModel =
                        secondRes.data;
                      const lastItemDataId =
                        response.questionnaireDataList[
                          response.questionnaireDataList.length - 1
                        ].dataId;
                      if (!lastItemDataId) return;
                      declareSustainQuestionnaireDataMutate(lastItemDataId, {
                        onSuccess: (thirdRes) => {
                          if (thirdRes.code === 200 && thirdRes.success) {
                            success2Alert(res.message);
                            navigate(
                              `/apply/sustain-questionnaire/${year}/${sampleId}/edit/-1`
                            );
                            getQuestionnaireDataMutate({
                              sampleId: sampleId!,
                            });
                          }
                        },
                      });
                    }
                  },
                }
              );
            } else if (action === "declare") {
              getQuestionnaireSamplesMutate(
                {
                  ...new QuestionnaireDataListRequestModel(),
                  questionnaireSampleId: sampleId!,
                  pageSize: 9999,
                },
                {
                  onSuccess: (secondRes) => {
                    if (
                      secondRes.code === 200 &&
                      secondRes.success &&
                      secondRes.data?.questionnaireDataList.length
                    ) {
                      const response: SustainQuestionnaireListDataModel =
                        secondRes.data;
                      const lastItemDataId =
                        response.questionnaireDataList[
                          response.questionnaireDataList.length - 1
                        ].dataId;
                      if (!lastItemDataId) return;
                      declareSustainQuestionnaireDataMutate(lastItemDataId, {
                        onSuccess: (thirdRes) => {
                          if (thirdRes.code === 200 && thirdRes.success) {
                            if (SustainQuestionnaireSampleModel?.isMultiple) {
                              backToList();
                            } else {
                              navigate(
                                `/apply/sustain-questionnaire/${year}/${sampleId}/edit/${lastItemDataId}`
                              );
                              questionnaireSampleData.isFinished = true;
                            }
                          }
                        },
                      });
                    }
                  },
                }
              );
            }
          }
        },
      });
    } else {
      console.log("edit");

      if (action === "save" || action === "saveAndNew") {
        storageQuestionnaireSampleData.isFinished = false;
      }

      saveSustainQuestionnaireDataMutate(storageQuestionnaireSampleData, {
        onSuccess: (res) => {
          if (res.code === 200 && res.success) {
            if (action === "save") {
              success2Alert(res.message);
              if (SustainQuestionnaireSampleModel?.isMultiple) {
                backToList();
              } else {
                questionnaireSampleData.isFinished = false;
              }
            } else if (action === "saveAndNew") {
              declareSustainQuestionnaireDataMutate(
                questionnaireSampleData.dataId,
                {
                  onSuccess: (thirdRes) => {
                    if (thirdRes.code === 200 && thirdRes.success) {
                      // success2Alert(res.message);
                      navigate(
                        `/apply/sustain-questionnaire/${year}/${sampleId}/edit/-1`
                      );
                      getQuestionnaireDataMutate({
                        sampleId: sampleId!,
                      });
                    }
                  },
                }
              );
            
            } else if (action === "declare") {
              declareSustainQuestionnaireDataMutate(
                questionnaireSampleData.dataId,
                {
                  onSuccess: (thirdRes) => {
                    if (thirdRes.code === 200 && thirdRes.success) {
                      if (SustainQuestionnaireSampleModel?.isMultiple) {
                        backToList();
                      } else {
                        navigate(
                          `/apply/sustain-questionnaire/${year}/${sampleId}/edit/${questionnaireSampleData.dataId}`
                        );
                        questionnaireSampleData.isFinished = true;
                      }
                    }
                  },
                }
              );
            }
          }
        },
      });
    }
  };

  //- validateQuestionnaireData
  const validateQuestionnaireData = () => {
    //- state
    // console.log(questionnaireSampleData);
    let valid = true;
    let invalidMessage = "";

    //- function
    //> updateInvalidMessage
    const updateInvalidMessage = (message: string) => {
      if (!invalidMessage) {
        valid = false;
        invalidMessage = message;
      }
    };

    //> validateControlRequiredValue
    const validateControlRequiredValue = (control: ControlModel): boolean => {
      if (
        control.value === "" ||
        control.value === null ||
        control.value === undefined
      ) {
        control.valid = false;
        updateInvalidMessage("請填寫必填欄位");
        return false;
      } else {
        control.valid = true;
        return true;
      }
    };

    //> validateControlWithOptionsRequiredValue
    const validateControlWithOptionsRequiredValue = (
      control: ControlModel
    ): boolean => {
      if (hasSingleValueControlTypeList.includes(control.ctrType!)) {
        if (
          control.value === "" ||
          control.value === null ||
          control.value === undefined
        ) {
          control.valid = false;
          updateInvalidMessage("請填寫必填欄位");
          return false;
        } else {
          control.optionsVal
            .filter((e) => e.check)
            .forEach((option) => {
              if (option.isExtraText && !option.extraTextValue) {
                option.valid_extraTextValue = false;
                updateInvalidMessage("請填寫必填欄位");
                return false;
              } else {
                option.valid_extraTextValue = true;
                return true;
              }
            });
          control.valid = true;
          return true;
        }
      }
      if (hasMultiValueControlTypeList.includes(control.ctrType!)) {
        if (!control.optionsVal.some((e) => e.check)) {
          control.valid = false;
          updateInvalidMessage("請填寫必填欄位");
          return false;
        } else {
          control.optionsVal
            .filter((e) => e.check)
            .forEach((option) => {
              if (option.isExtraText && !option.extraTextValue) {
                option.valid_extraTextValue = false;
                updateInvalidMessage("請填寫必填欄位");
                return false;
              } else {
                option.valid_extraTextValue = true;
                return true;
              }
            });
          control.valid = true;
          return true;
        }
      }
      return true;
    };

    //- process
    const checkedData = CommonService.deepClone(
      questionnaireSampleDataRef.current.sectionDatas.find(
        (e) => !e.isNecessary
      )?.sections!
    );

    checkedData.forEach((section) => {
      section.controls.forEach((control) => {
        let sectionIsValidate = true;
        if (control.required && !control.isFollowingControlDisabled) {
          console.log("control", control);
          let controlIsValidate;
          if (!hasOptionsControlTypeList.includes(control.ctrType!)) {
            controlIsValidate = validateControlRequiredValue(control);
          } else {
            controlIsValidate =
              validateControlWithOptionsRequiredValue(control);
          }
          if (!controlIsValidate) sectionIsValidate = false;
        }
        if (!sectionIsValidate) section.isSectionCollapse = false;
      });
    });

    setQuestionnaireSampleData((prev) => ({
      ...prev,
      sectionDatas: questionnaireSampleData.sectionDatas.map(
        (sectionData: SectionModel) => {
          if (!sectionData.isNecessary) {
            return {
              ...sectionData,
              sections: checkedData,
            };
          }
          return sectionData;
        }
      ),
    }));

    console.log(
      "validateQuestionnaireData",
      valid,
      invalidMessage,
      checkedData
    );

    //> alert invalidMessage
    if (!valid && invalidMessage) {
      error2Alert(invalidMessage);
      setTimeout(() => {
        const invalidNode = document.querySelector(".invalid");
        if (invalidNode) {
          //> 滑到致中間
          invalidNode.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 100);
    }

    return valid;
  };

  //- closeControlCommentImageModel
  const closeControlCommentImageModel = () => {
    setSelectedControlFileModel(new SampleControlFileModel());
    setControlCommentImageModal((prev) => {
      return {
        ...prev,
        show: false,
      };
    });
  };

  //- openControlCommentImageModel
  const openControlCommentImageModel = (control: ControlModel) => {
    console.log("openControlCommentImageModel", control);
    if (!control.imageData) return;
    setSelectedControlFileModel(control.imageData);
    setControlCommentImageModal((prev) => {
      return {
        ...prev,
        show: true,
      };
    });
  };

  //! specificRules
  const handleSpecificRules = (handleControls: ControlModel[]) => {
    console.group("handleSpecificRules");
    console.log("handleControls", handleControls);

    let newControls = CommonService.deepClone(
      handleControls.sort((a, b) => a.sortIndex! - b.sortIndex!)
    );
    // let newSectionDatas = CommonService.deepClone(
    //   questionnaireSampleDataRef.current.sectionDatas.find(
    //     (e) => !e.isNecessary
    //   )?.sections!
    // );

    const sampleControlIdCorrespondsValue: SampleControlIdCorrespondsValueModel[] =
      newControls.map((control) => {
        const detectControlValue = () => {
          //! 目前只有radio用得到
          if (control.ctrType === "radio") {
            return control.value || null;
          }
          return null;
        };
        return {
          name: control.name,
          sampleControlId: control.sampleControlId,
          value: detectControlValue(),
        };
      });
    console.log(
      "sampleControlIdCorrespondsValue",
      sampleControlIdCorrespondsValue
    );

    newControls = newControls.map((control) => {
      return specificRulesData.handleControl(
        control,
        sampleControlIdCorrespondsValue,
        resetControlValue
      );
    });

    console.groupEnd();

    return newControls;
  };

  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  //- editQuestionnaireNode
  const editQuestionnaireNode = (): ReactNode => {
    //* ---------------------------- component-state start ---------------------------- *//
    let level1Index: number = 0;
    //* ---------------------------- component-state start ---------------------------- *//
    //* ---------------------------- component-function start ---------------------------- *//
    //* ---------------------------- component-function end ---------------------------- *//
    //* ---------------------------- component-subComponent start ---------------------------- *//
    //* ---------------------------- component-subComponent end ---------------------------- *//
    if (!sectionsData().length) {
      return <div className="no-data">沒有資料</div>;
    }
    return (
      <div className="questionnaire-edit-out-box">
        <div className="questionnaire-edit-in-box">
          {sectionsData().map((section, sectionIndex) => {
            if (!section.isBlankSection) {
              level1Index++;
            }
            return (
              <div key={sectionIndex} className="section-box">
                {!section.isBlankSection ? (
                  sectionNode(section, level1Index)
                ) : (
                  <>
                    {section.controls.map((control, controlIndex) => {
                      level1Index++;
                      return (
                        <div key={controlIndex} className="control-box">
                          {controlNode(control, level1Index.toString())}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  //- sectionNode
  const sectionNode = (
    section: SectionItemModel,
    level1Index: number
  ): ReactNode => {
    //* ---------------------------- component-function start ---------------------------- *//
    //- handleSectionFieldChange
    const handleSectionFieldChange = (
      value: string | number | boolean,
      key: string,
      selectedSection: SectionItemModel
    ) => {
      console.log("handleSectionFieldChange", value, key, selectedSection);
      selectedSection = { ...selectedSection, [key]: value };
      setQuestionnaireSampleData((prev) => ({
        ...prev,
        sectionDatas: questionnaireSampleData.sectionDatas.map(
          (sectionData: SectionModel) => {
            if (!sectionData.isNecessary) {
              return {
                ...sectionData,
                sections: sectionData.sections.map(
                  (section: SectionItemModel) => {
                    if (section.id === selectedSection.id) {
                      return selectedSection;
                    }
                    return section;
                  }
                ),
              };
            }
            return sectionData;
          }
        ),
      }));
    };
    //* ---------------------------- component-function end ---------------------------- *//
    return (
      <>
        <div
          className="section-title-box"
          onClick={() => {
            handleSectionFieldChange(
              !section.isSectionCollapse,
              "isSectionCollapse",
              section
            );
          }}
        >
          <img
            alt="extend"
            src="/assets/images/declaration/application/extend-up-icon.svg"
            className={section.isSectionCollapse ? "isSectionCollapse" : ""}
          />
          <div className="info">
            <div className="title">
              {level1Index}. {section.name}
            </div>
            {section.comment ? (
              <div className="comment">{section.comment}</div>
            ) : null}
          </div>
        </div>
        {!section.isSectionCollapse ? (
          <div className="section-controls-box">
            {section.controls.map((control, controlIndex) => {
              const displayIndex = `${level1Index}.${controlIndex + 1}`;
              return (
                <div key={controlIndex} className="control-box">
                  {controlNode(control, displayIndex)}
                </div>
              );
            })}
          </div>
        ) : null}
      </>
    );
  };

  //- controlNode
  const controlNode = (control: ControlModel, showIndex: string): ReactNode => {
    //* ---------------------------- component-state start ---------------------------- *//
    //* ---------------------------- component-state end ---------------------------- *//
    //* ---------------------------- component-function start ---------------------------- *//
    //- getCurrentControl
    const getCurrentControl = () => {
      const sectionDatas = CommonService.deepClone(
        questionnaireSampleDataRef.current.sectionDatas.find(
          (e) => !e.isNecessary
        )?.sections!
      );
      const currentControl = sectionDatas
        .flatMap((section) => section.controls)
        .find((e) => e.sampleControlId === control.sampleControlId)!;
      return currentControl;
    };

    //- handleControlFieldChange
    const handleControlFieldChange = (value: string | number) => {
      const valueControl = { ...getCurrentControl(), value: value?.toString() };
      setControlsAndSectionDatasSameTime(valueControl);
    };

    //- handleOptionFieldChange
    const handleOptionFieldChange = (
      value: string | string[],
      controlType: string
    ) => {
      console.log("handleOptionFieldChange", value, controlType);
      const valueControl = { ...getCurrentControl() };
      if (hasSingleValueControlTypeList.includes(controlType)) {
        const singleValue = value as string;
        valueControl.value = singleValue;
      }
      valueControl.optionsVal = control.optionsVal.map((option) => {
        if (hasSingleValueControlTypeList.includes(controlType)) {
          const singleValue = value as string;
          if (option.id === singleValue) {
            option.check = true;
          } else {
            option.check = false;
            if (option.isExtraText) {
              option.extraTextValue = undefined;
            }
          }
          return option;
        }
        if (hasMultiValueControlTypeList.includes(controlType)) {
          if (typeof value === "object") {
            const multiValue = value as string[];
            if (multiValue.includes(option.id)) {
              option.check = true;
            } else {
              option.check = false;
              if (option.isExtraText) {
                option.extraTextValue = undefined;
              }
            }
          }
          if (typeof value === "string") {
            const singleValue = value as string;
            if (option.id === singleValue) {
              option.check = !option.check;
            }
          }
          return option;
        }
        return option;
      });
      setControlsAndSectionDatasSameTime(valueControl);
    };

    //- handleOptionExtraTextChange
    const handleOptionExtraTextChange = (value: string, optionId: string) => {
      const valueControl = { ...getCurrentControl() };
      valueControl.optionsVal = control.optionsVal.map((option) => {
        if (option.id === optionId) {
          option.extraTextValue = value;
        }
        return option;
      });
      setControlsAndSectionDatasSameTime(valueControl);
    };

    //* ---------------------------- component-function end ---------------------------- *//
    //* ---------------------------- component-subComponent start ---------------------------- *//
    //- text_controlTypeNode
    const text_controlTypeNode = (): ReactNode => (
      <NormalInput
        isFloatTitle={false}
        disabled={control.isFollowingControlDisabled}
        type={"text"}
        valid={control.valid}
        defaultValue={control.value}
        unit={control.unit}
        onChange={(e) => {
          handleControlFieldChange(e);
        }}
      />
    );
    //- textarea_controlTypeNode
    const textarea_controlTypeNode = (): ReactNode => (
      <NormalTextarea
        isFloatTitle={false}
        disabled={control.isFollowingControlDisabled}
        valid={control.valid}
        defaultValue={control.value}
        onChange={(e) => {
          handleControlFieldChange(e);
        }}
        unit={control.unit}
        placeholder={""}
      />
    );
    //- number_controlTypeNode
    const number_controlTypeNode = (): ReactNode => (
      //TODO 負數
      <NormalInput
        isFloatTitle={false}
        //! specificRules
        disabled={
          control.isFollowingControlDisabled || control.isDefaultOptionDisabled
        }
        type={"number"}
        valid={control.valid}
        defaultValue={control.value}
        limitFloatDigits={control.digitNumber}
        canNegative={!control.excludeNegative}
        unit={control.unit}
        onChange={(e) => {
          handleControlFieldChange(e);
        }}
      />
    );
    //- date_controlTypeNode
    const date_controlTypeNode = (): ReactNode => (
      <NormalInput
        isFloatTitle={false}
        disabled={control.isFollowingControlDisabled}
        type={"date"}
        valid={control.valid}
        defaultValue={control.value}
        unit={control.unit}
        onChange={(e) => {
          handleControlFieldChange(e);
        }}
      />
    );
    //- time_controlTypeNode
    const time_controlTypeNode = (): ReactNode => (
      <NormalInput
        isFloatTitle={false}
        disabled={control.isFollowingControlDisabled}
        type={"time"}
        valid={control.valid}
        defaultValue={control.value}
        unit={control.unit}
        onChange={(e) => {
          handleControlFieldChange(e);
        }}
      />
    );
    //- radio_controlTypeNode
    const radio_controlTypeNode = (): ReactNode => (
      <div
        className={`radio-box ${control.valid ? "" : "invalid"} ${
          control.isFollowingControlDisabled ? "disabled" : ""
        } ${control.isNotOpenForSubmission ? "notSubmission" : ""}
        //! specificRules
        ${control.isDefaultOptionDisabled ? "do-disabled" : ""}`}
      >
        {control.optionsVal?.map((option, optionIndex) => {
          return (
            <div key={optionIndex} className="option-box">
              <div className="setting-radio">
                <label
                  className={`custom-radio ${option.valid ? "" : "invalid"}`}
                >
                  <input
                    type="radio"
                    name={`radio_${control.sampleControlId}`}
                    value={option.id}
                    checked={control.value === option.id}
                    onChange={(e) => {}}
                    onClick={(e) => {
                      handleOptionFieldChange(option.id, control.ctrType);
                    }}
                  />
                  <span>{option.text}</span>
                </label>
              </div>
              {/* 是否有文字框 */}
              {option.id === control.value && option.isExtraText && (
                <div className="other-text-box">
                  <NormalInput
                    isFloatTitle={false}
                    defaultValue={option.extraTextValue}
                    valid={option.valid_extraTextValue}
                    onChange={(e) => {
                      handleOptionExtraTextChange(e, option.id);
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
    //- checkbox_controlTypeNode
    const checkbox_controlTypeNode = (): ReactNode => (
      <div
        className={`checkbox-box ${control.valid ? "" : "invalid"} ${
          control.isFollowingControlDisabled ? "disabled" : ""
        }
        //! specificRules
        ${control.isDefaultOptionDisabled ? "do-disabled" : ""}`}
      >
        {control.optionsVal?.map((option, optionIndex) => {
          return (
            <div key={optionIndex} className="option-box">
              <div className="setting-checkbox">
                <label className={`custom-checkbox`}>
                  <input
                    type="checkbox"
                    checked={option?.check || false}
                    onChange={(e) => {
                      handleOptionFieldChange(option.id, control.ctrType);
                    }}
                  />
                  <span>
                    <span>{option.text}</span>
                  </span>
                </label>
              </div>
              {/* 是否有文字框 */}
              {option.check && option.isExtraText && (
                <div className="other-text-box">
                  <NormalInput
                    isFloatTitle={false}
                    defaultValue={option.extraTextValue}
                    valid={option.valid_extraTextValue}
                    onChange={(e) => {
                      handleOptionExtraTextChange(e, option.id);
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
    //- select_controlTypeNode
    const select_controlTypeNode = (): ReactNode => (
      <div className={`select-box`}>
        <div className="option-box">
          <Dropdown
            isFloatTitle={false}
            valid={control.valid}
            disabled={control.isFollowingControlDisabled}
            options={control.webOptions}
            defaultId={control.value}
            onChange={(e) => {
              if (e) {
                handleOptionFieldChange(e, control.ctrType);
              }
            }}
          />
          {/* 是否有文字框 */}
          {control.optionsVal?.map((option, optionIndex) => {
            if (option.id === control.value && option.isExtraText) {
              return (
                <div key={optionIndex} className="other-text-box">
                  <NormalInput
                    isFloatTitle={false}
                    defaultValue={option.extraTextValue}
                    valid={option.valid_extraTextValue}
                    onChange={(e) => {
                      handleOptionExtraTextChange(e, option.id);
                    }}
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
    //- selectMulti_controlTypeNode
    const selectMulti_controlTypeNode = (): ReactNode => (
      <div className={`select-box`}>
        <div className="option-box">
          <MultiSelect
            isFloatTitle={false}
            placeholder=""
            disabled={control.isFollowingControlDisabled}
            valid={control.valid}
            options={control.webOptions}
            hideOptions={true}
            defaultIds={control.optionsVal
              ?.filter((p) => p.check === true)
              .map((p) => p.id)}
            onChange={(e) => {
              handleOptionFieldChange(e, control.ctrType);
            }}
          />
        </div>
      </div>
    );
    //* ---------------------------- component-subComponent end ---------------------------- *//

    return (
      <>
        <div className="title">
          {showIndex}. {control.name}
          {
            //! specificRules
            (!control.isFollowingControlDisabled ||
              !control.isDefaultOptionDisabled) &&
            control.required ? (
              <span className="required-icon"> *</span>
            ) : null
          }
          {control.imageData ? (
            <img
              className="comment-image-tip"
              alt="tip"
              src={`/assets/images/buttonIcon/tip-icon.svg`}
              onClick={() => openControlCommentImageModel(control)}
            />
          ) : null}
        </div>
        {control.comment ? (
          <div className="comment">{control.comment}</div>
        ) : null}
        {/* control type */}
        <div className={`value-box ${control.ctrType}-value-box`}>
          {/* text */}
          {(control.ctrType === "text" || control.ctrType === "text-long") &&
            text_controlTypeNode()}
          {/* textarea */}
          {control.ctrType === "textarea" && textarea_controlTypeNode()}
          {/* number */}
          {control.ctrType === "number" && number_controlTypeNode()}
          {/* date */}
          {control.ctrType === "date" && date_controlTypeNode()}
          {/* time */}
          {control.ctrType === "time" && time_controlTypeNode()}
          {/* radio */}
          {control.ctrType === "radio" && radio_controlTypeNode()}
          {/* checkbox */}
          {control.ctrType === "checkbox" && checkbox_controlTypeNode()}
          {/* select */}
          {control.ctrType === "select" && select_controlTypeNode()}
          {/* select-multi */}
          {control.ctrType === "select-multi" && selectMulti_controlTypeNode()}
        </div>
      </>
    );
  };

  //- controlCommentImageModel
  const controlCommentImageModel = (): ReactNode => {
    const handleImageLoad = (
      event: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
      const imageWidth = event.currentTarget.naturalWidth;
      const modalDialog = document.querySelector(
        ".control-comment-image-modal .modal-dialog"
      ) as HTMLElement;
      // console.log("handleImageLoad", imageWidth);
      if (modalDialog) {
        modalDialog.style.width = `${imageWidth}px`;
      }
    };
    return (
      <ContentModal {...controlCommentImageModal}>
        <img
          className="comment-image"
          src={selectedControlFileModel.url}
          alt={selectedControlFileModel.fileName}
          onLoad={handleImageLoad}
        />
      </ContentModal>
    );
  };
  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  useEffect(() => {
    if (
      !year ||
      !sampleId ||
      !SustainQuestionnaireSampleModel ||
      sampleId !== SustainQuestionnaireSampleModel.sampleId ||
      !dataId
    )
      return;
    console.log(year, sampleId, SustainQuestionnaireSampleModel, dataId);
    if (dataId === "-1") {
      getQuestionnaireDataMutate({ sampleId });
    }
    //- dataid match uuid
    else if (CommonService.isUUID(dataId)) {
      getQuestionnaireDataMutate({ sampleId, dataId });
    } else {
      navigate(`/apply/sustain-questionnaire/${year}/${sampleId}/list`);
      return;
    }
    //! specificRules
    //- detect specificRules
    const isSpecificRules = SpecificRulesData.find(
      (e) => e.sampleId === sampleId
    );
    if (isSpecificRules) {
      setSpecificRulesData(isSpecificRules);
      console.warn("isSpecificRules", isSpecificRules);
    }
  }, [
    year,
    sampleId,
    SustainQuestionnaireSampleModel,
    dataId,
    getQuestionnaireDataMutate,
    navigate,
  ]);

  // //! useEffect detectQuestionnaireSampleDataIsChanged
  // useEffect(() => {
  //   console.log("sectionsData()-", sectionsData());
  //   console.log(
  //     "questionnaireSampleData-controls-",
  //     questionnaireSampleData.controls.sort((a, b) => {
  //       return a.sortIndex! - b.sortIndex!;
  //     })
  //   );
  //   // console.warn("questionnaireSampleData-", questionnaireSampleData.controls.sort((a, b) => { return a.sortIndex! - b.sortIndex! }));
  //   questionnaireSampleDataRef.current = questionnaireSampleData;
  // }, [questionnaireSampleData]);

  //! test
  useEffect(() => {
    console.log("sectionsData()-", sectionsData());
    console.log(
      "questionnaireSampleData-controls-",
      questionnaireSampleData.controls.sort((a, b) => {
        return a.sortIndex! - b.sortIndex!;
      })
    );
    // console.warn("questionnaireSampleData-", questionnaireSampleData.controls.sort((a, b) => { return a.sortIndex! - b.sortIndex! }));
    questionnaireSampleDataRef.current = questionnaireSampleData;
    // setQuestionnaireSampleDataIsChangedCount((prev) => prev + 1);
    // if (questionnaireSampleDataIsChangedCount) {
    //   setDetectQuestionnaireSampleDataIsChanged(true);
    //   // console.log(
    //   //   "detectQuestionnaireSampleDataIsChanged!!!!!",
    //   //   questionnaireSampleDataIsChangedCount
    //   // );
    // }
  }, [questionnaireSampleData]);

  //! test
  // useEffect(() => {
  //   console.log("followingControlIdList-", followingControlIdList);
  // }, [followingControlIdList]);
  //* ---------------------------- hook end ---------------------------- *//

  if (!year || !sampleId || !SustainQuestionnaireSampleModel || !dataId) {
    return <VisuallLoading />;
  }
  return (
    <>
      <div className={`${styles["questionnaire-edit-box"]}`}>
        {getQuestionnaireDataIsPending ? <VisuallLoading /> : null}
        {!questionnaireSampleData.sampleId ? (
          <>
            {!getQuestionnaireDataIsPending ? (
              <div className="no-data">沒有資料</div>
            ) : null}
          </>
        ) : (
          <>
            <div className="questionnaire-edit-title-box">
              <div className="info">
                <div className="left">
                  {SustainQuestionnaireSampleModel.sampleName}
                </div>
                <div className="right">
                  {sectionsData().length ? (
                    <>
                      {SustainQuestionnaireSampleModel.isMultiple ? (
                        <>
                          <button
                            className="secondary"
                            onClick={() => backToList()}
                          >
                            取消
                          </button>
                          <button
                            className="default saveAndAdd"
                            onClick={() =>
                              handleSaveSustainQuestionnaireData("saveAndNew")
                            }
                          >
                            儲存並建立新活動
                          </button>
                        </>
                      ) : null}
                      {/* <button
                        className="default"
                        onClick={() =>
                          handleSaveSustainQuestionnaireData("save")
                        }
                      >
                        儲存
                      </button> */}
                      <button
                        className="default"
                        onClick={() =>
                          handleSaveSustainQuestionnaireData("declare")
                        }
                      >
                        儲存
                      </button>
                    </>
                  ) : null}
                </div>
              </div>
              {questionnaireSampleData.comment ? (
                <div className="questionnaire-comment">
                  {questionnaireSampleData.comment}
                </div>
              ) : null}
              <div className="required-comment">
                <span className="required-icon">*</span>為必填欄位
              </div>
            </div>
            {/* editQuestionnaireNode */}
            {editQuestionnaireNode()}
            {/* controlCommentImageModel */}
            {controlCommentImageModel()}
          </>
        )}
      </div>
    </>
  );
};

export default Edit;
