import { SustainReportRequest2OptionModel } from "models/inquiry/sustainReportModel";
import { HttpClient } from "services/httpClient";

const httpClient = new HttpClient();

/** 取得篩選條件之市場別及報告年度對應 */
/*const getIndustryYears = () => {
    return httpClient.get(`MopsEsg/industryYearList`);
}*/

/** 取得篩選條件之產業別對應公司代號 */
const getCompanyCodes = (marketType: string) => {
    return httpClient.get(`MopsSustainReport/industryAndCompanyCode?marketType=${marketType}`);
}

/** 取得公司報告書資訊 */
const getMopsSustainReportOldDataList = (data: SustainReportRequest2OptionModel) => {
    return httpClient.post(`MopsSustainReport/data/old`, data);
}

/** 取得公司報告書資訊(舊年度) */
const getSustainReports = (data: SustainReportRequest2OptionModel) => {
    return httpClient.post(`MopsSustainReport/data`, data);
}

/** 下載pdf */
const getFileStream = (fileId: string) => {
    return httpClient.getBlob(`MopsSustainReport/data/FileStream?id=${fileId}`);
}


const SustainReportService = {
    //getIndustryYears,
    getCompanyCodes,
    getSustainReports,
    getFileStream,
    getMopsSustainReportOldDataList
}

export default SustainReportService;
