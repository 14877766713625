import React, { FC } from "react";
import styles from "./GhgReductionApplyRouter.module.scss";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
import GhgReductionInfo from "./GhgReductionInfo/GhgReductionInfo";
import GhgReductionList from "./GhgReductionList/GhgReductionList";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";

interface GhgReductionApplyRouterProps {}
const GhgReductionApplyLayout: FC = () => {
  return (
    <>
      <div className="no-nav-content-box">
        <CustomErrorBoundary>
          <Outlet></Outlet>
        </CustomErrorBoundary>
      </div>
    </>
  );
};
const GhgReductionApplyRouterRouter: FC<GhgReductionApplyRouterProps> = () => {
  // 創建router
  const routers: RouteObject[] = [
    {
      path: "",
      element: <GhgReductionList />,
    },
    {
      path: ":id",
      element: <GhgReductionApplyLayout />,
      children: [
        {
          path: "",
          element: <Navigate to={"info"} />,
        },
        {
          path: "info",
          element: <GhgReductionInfo />,
        },
      ],
    },
  ];
  // 相當於router
  let elements = useRoutes(routers);
  return <>{elements}</>;
};

export default GhgReductionApplyRouterRouter;
