import { FC, useState, useEffect, useRef } from "react";
import styles from "./ScrollToTopButton.module.scss";

interface ScrollToTopButtonProps {
  targetClassName?: string;
  bottom?: number;
  right?: number;
}

const ScrollToTopButton: FC<ScrollToTopButtonProps> = (props) => {
  const { targetClassName, bottom, right } = props;
  const [isShow, setIsShow] = useState(false);
  const targetRef = useRef<HTMLElement | null>(null);

  const dynamicStyle: React.CSSProperties = {
    bottom: bottom ?? "unset",
    right: right ?? "unset",
    position: bottom !== undefined || right !== undefined ? "fixed" : "unset",
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        targetRef.current?.scrollTop ?? document.documentElement.scrollTop;
      setIsShow(scrollTop > 100);
    };

    const targetElement = document.querySelector(`.${targetClassName}`);
    targetRef.current = targetElement as HTMLElement;


    if (targetRef.current) {
      targetRef.current.addEventListener("scroll", handleScroll);
    } else {
      setIsShow(true);
    }

    return () => {
      if (targetRef.current) {
        targetRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [targetClassName]);

  const handleClick = () => {
    const scrollToOptions = {
      top: 0,
      behavior: "smooth",
    };

    if (targetRef.current) {
      targetRef.current.scrollTo?.({
        ...scrollToOptions,
        behavior: "smooth" as ScrollBehavior,
      });
    } else {
      window.scrollTo?.({
        ...scrollToOptions,
        behavior: "smooth" as ScrollBehavior,
      });
    }
  };

  return (
    <>
      {isShow && (
        <div
          className={styles["scroll-to-top-button"]}
          style={dynamicStyle}
          onClick={handleClick}
        >
          <img src="/assets/images/buttonIcon/scroll-to-top-icon.svg" alt="" />
        </div>
      )}
    </>
  );
};

export default ScrollToTopButton;
