import { ListRequestModel, TableRequestModel } from "models/baseModel"
import { CompanySampleControlValModel, CustomerSampleDataControlValSectionModel } from "models/samples/companySampleDataModel/companySampleControlValModel";

export interface IndicatorMarketModel {
    id: string | null;
    marketType?: number | null; // (0:全部;1:上市;2:上櫃)
    year: number | null;
    updateTime?: Date | null;
    isEdit?: boolean;
    checked?: boolean;
}

export interface IndicatorModel {
    id: string | null;
    dataType?: number | null; // (表單類別 0:指標1:問卷)
    category?: any | null;
    name?: string | null;
    createTime?: Date;
    updateTime?: Date | null;
    isActive: boolean;
    checked?: boolean;
}

export interface IndicatorListRequestModel extends ListRequestModel {
    indicatorMarketId?: string | null;
    category?: number | null;
}

export interface IndicatorListRequest2Model extends ListRequestModel {
    marketType?: number | null;
    year?: number | null;
}

export interface IndicatorDetailModel extends IndicatorModel {
    code?: string | null;
    marketId: string | null;
    customerId?: string;
    comment?: string;
    createId?: string;
    lang?: string;
    controls: Array<IndicatorControlModel>;
}

export interface IndicatorControlModel {
    id: string;
    name?: string | null;
    valid?: boolean;
    ctrType: string;
    unit?: string;
    hasData: boolean;
    isShow: boolean;
    code?: string | null;
    comment?: string | null;
    excludeNegative: boolean;
    options?: Array<IndicatorControlOptionModel> | null;
    matrix?: IndicatorControlMatrixModel | null;
    line?: IndicatorControlLineModel | null;
    sortIndex: number;
    showSortIndex?: number;
    sectionName?: string | null;
    excelRowIndex?: string | null;
    digitNumber?: string | null;
    isBlankSection?: boolean;
    isCustomUnit?: boolean;
    parentId: string | null;
    isIndependent?: boolean;
    skipChecked?: boolean;
    followChecked?: boolean;
    isSectionOpen?: boolean;
    isSectionCtrOpen?: boolean;
    /** 預計後續拿掉 */
    isNotShowInMops?: boolean;
    /** 是否顯示於個別查詢前台 */
    isNotShowInMopsSingle?: boolean;
    /** 是否顯示於彙總查詢前台 */
    isNotShowInMopsSummary?: boolean;
}

export interface IndicatorControlOptionModel {
    id?: string | null;
    text: string;
    code: string;
    check: boolean;
    skipControlId?: string | null;
    followingControlIds?: Array<string>;
    isExtraText?: boolean;
    skipName?: string;
    followNames?: string;
}

export interface IndicatorControlMatrixModel {
    columnTotal?: boolean;
    rowTotal?: boolean;
    columns: IndicatorControlMatrixItemModel[];
    rows: IndicatorControlMatrixItemModel[];
}

export interface IndicatorControlMatrixItemModel {
    id?: string;
    name: string;
    type: string;
    sortIndex: number;
    code?: string;
}

export interface IndicatorControlLineModel {
    startNum?: number | null;
    endNum?: number | null;
    headTag?: string | null;
    tailTag?: string | null;
    nums?: number[];
}

export interface IndicatorCategoriesModel {
    id: string;
    text: string;
    code: string;
    enumKey: string;
}

export interface IndicatorSwitchActive {
    id: string;
    isActive: boolean;
}

export interface NecessaryIndicatorModel {
    indicatorSampleId: string;
    indicatorName: string;
    necessaryIndicatorIndustryDatas: {
        industryId: string;
        name: string;
        indicatorControls: NecessaryIndicatorControlModel[];
    }[];
}

export interface NecessaryIndicatorControlModel {
    controlId: string;
    controlName: string;
    isNecessaryOverTwoBillion: boolean;
    isNecessaryUnderTwoBillion: boolean;
    [propertyName: string]: boolean | string;
}


export interface DelareItemTreeModel {
    category: Category;
    categoryString: string | null;
    /** 申報項目資料 */
    items?: DelareItemTreeDetailModel[];
}

export interface DelareItemTreeDetailModel {
    /** 申報項目類型 */
    category: Category;
    /** 申報項目名稱 */
    declareItemName: string | null;
    sampleId: string | null;
    sampleDataId: string | null;
    /** 已填寫狀態 */
    isWritten: boolean;
    /** 是否都非必要揭露 */
    isNotNecessary: boolean;
    /** section 資料 */
    sectionDatas: CustomerSampleDataControlValSectionModel[];
}

/** 類別 */
export enum Category {
    /** 環境 */
    Environment,
    /** 社會 */
    Society,
    /** 公司治理 */
    Governance
}

/** 定義控制項目型態 */
export const CtrType = {

    /** 文字框 (短) */
    Text: "text",
    /**  文字框 (長) (都改為Text了，暫時沒用) */
    TextLong: "text-long",
    /**  文字區塊 */
    Textarea: "textarea",
    /**  數值 */
    Number: "number",
    /**  日期 */
    Date: "date",
    /**  單選鈕 */
    Radio: "radio",
    /**  單選鈕 (長) */
    RadioLong: "radio-long",
    /**  核取方塊 */
    Checkbox: "checkbox",
    /**  下拉選單 */
    Select: "select",
    /**  下拉多選單 */
    SelectMulti: "select-multi",
    /**  部門清單 */
    Department: "department",
    /**  時間 */
    Time: "time",
    /**  文字顯示 */
    Label: "label",
    /**  檔案上傳 */
    Upload: "upload",
    /**  區塊 */
    Section: "section",
    /**  數值矩陣 */
    NumberMatrix: "number-matrix",
    /**  核取方塊矩陣 */
    CheckboxMatrix: "checkbox-matrix",
    /**  單選鈕矩陣 */
    RadioMatrix: "radio-matrix",
    /**  供應商清單 */
    Supplier: "supplier",
    /**  供應商品項清單 */
    SupplierItem: "supplier-item",
    /**  單位 */
    Unit: "unit",
    /**  線性刻度 */
    Line: "line",
    /**  同意線性刻度 */
    LineAgree: "line-agree",
    /**  滿意線性刻度 */
    LineSatisfy: "line-satisfy",
    /**  隱藏欄位 */
    Hidden: "hidden",
    /**  系統選單 */
    CodeTable: "code-table",
    /**  專案資訊選單 */
    ProjectInfo: "project-info",
    /**  公司資訊選單 */
    CustomerInfo: "customer-info",
    /**  Email驗證欄位 */
    Email: "email",
    /**  幣別選單 */
    Currency: "currency",
}

/** 搜尋參數 */
export interface DeclareItemRequestModel {
    sampleDataId?: string | null;
    sampleId?: string | null;
    year?: number;
    companyCode?: string | null;
}

/** 特殊控制向model */
export interface SpecialControlModel {
    //#region 廢棄物管理
    /** 有害廢棄物 */
    wasteHarmful: CompanySampleControlValModel | undefined;
    /** 非有害廢棄物 */
    wasteHarmless: CompanySampleControlValModel | undefined;
    /** 總重量(有害+非有害) */
    wasteTotal: CompanySampleControlValModel | undefined;
    //#endregion
}
