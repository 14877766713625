//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect, useRef } from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./Detail.module.scss";
import BackTitle from "components/base/BackTitle/BackTitle";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import { CommonService } from "services/common/commonService";
import {
  DeclareStatusEnum,
  EsgDisclosureDataModel,
  EsgDisclosureDataRequestModel,
  RequestTypeEnum,
  DeclareSettingDataRequestModel,
} from "models/admin/declarationModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import GhgDeclareService from "services/application/ghgReductionDeclare/ghgDeclareService";
import {
  GhgDeclareDataModel,
  GhgDeclareShowDataModel,
  GhgSectionQuestionModel,
} from "models/application/ghgReductionDeclare/ghgDeclareModel";
import {
  DeclareDataAction,
  DeclareStatus,
} from "models/application/indicators/indicatorModel";
import useUserInfoStore from "state/useUserInfoStore";
import { ActionsPermissionCodes } from "models/auth/permissionModel";
import EmptyWaitPage from "components/common/EmptyWaitPage/EmptyWaitPage";
//* ---------------------------- local end ---------------------------- *//

const Detail: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const params = useParams();
  const id: string = params?.id!;
  const location = useLocation();
  const ghgEmissionsReduction_SelectedItemData: EsgDisclosureDataModel =
    location?.state?.ghgEmissionsReduction_SelectedItemData;
  const navigate = useNavigate();
  const orgInfoData = useRef<GhgDeclareShowDataModel>({
    year: 0,
    canEdit: true,
    status: DeclareStatus.UnDeclared,
    regulationsRisk: null,
    substanceRisk: null,
    chance: null,
    ghgEmissionInfo: null,
    strategy: null,
    target: null,
    budgetAndPlan: null,
    reductionEffect: null,
    isFinished: false,
  });
  const [infoData, setInfoData] = useState<any>(orgInfoData.current);
  // section 問題
  const sectionData = [
    {
      sectionTitle: "一、企業對於溫室氣體排放之影響，或衝擊之程度：",
      questions: [
        {
          title: "(一) 企業受氣候變遷相關法規規範之風險",
          value: "regulationsRisk",
        },
        {
          title: "(二) 企業受氣候變遷之實質風險",
          value: "substanceRisk",
        },
        {
          title: "(三) 氣候變遷提供企業之機會",
          value: "chance",
        },
        {
          title:
            "(四) 企業(直、間接)溫室氣體排放量 (註明盤查範疇及時間)，及是否通過外部驗證",
          value: "ghgEmissionInfo",
        },
      ],
    },
    {
      sectionTitle: "二、企業對於溫室氣體管理之策略、方法、目標等：",
      questions: [
        {
          title: "(一) 企業對於因應氣候變遷或溫室氣體管理之策略",
          value: "strategy",
        },
        {
          title: "(二) 企業溫室氣體排放量減量目標",
          value: "target",
        },
        {
          title: "(三) 企業溫室氣體排放量減量之預算與計畫",
          value: "budgetAndPlan",
        },
        {
          title: "(四) 企業產品或服務帶給客戶或消費者之減碳效果",
          value: "reductionEffect",
        },
      ],
    },
  ];
  //* userInfo
  const { userInfo } = useUserInfoStore();
  //* permission
  const { permission } = userInfo;
  //* actionCodes
  const { actionCodes } = permission;
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getGhgDeclareData
  const {
    mutate: getGhgDeclareDataMutate,
    isPending: getGhgDeclareDataIsPending,
  } = useMutation({
    mutationFn: (id: string) => GhgDeclareService.getGhgDeclareData(id),
    onSuccess: (res) => {
      console.log("getGhgDeclareData", res);
      if (res.code === 200 && res.success) {
        const newData: GhgDeclareShowDataModel = res.data;
        setInfoData({ ...newData });
        // success2Alert(res.message);
      } else {
        // error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("getGhgDeclareData", err);
      // error2Alert("執行失敗");
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  // 問題item 元件
  const QuestionItemComponent: FC<{ question: GhgSectionQuestionModel }> = (
    props
  ) => {
    const { question } = props;
    return (
      <div key={`${question.title}`} className="question-box">
        <div className="question">{question.title}</div>
        <div className="control">
          <div className="display-val">{infoData[question.value]}</div>
        </div>
      </div>
    );
  };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init
  useEffect(() => {
    console.log("id", id);
    console.log(
      "ghgEmissionsReduction_SelectedItemData",
      ghgEmissionsReduction_SelectedItemData
    );
    if (!id || !ghgEmissionsReduction_SelectedItemData) {
      navigate("/admin/declaration/ghg-emissions-reduction");
      return;
    }
    getGhgDeclareDataMutate(id);
  }, [
    id,
    ghgEmissionsReduction_SelectedItemData,
    navigate,
    getGhgDeclareDataMutate,
  ]);
  //* ---------------------------- hook end ---------------------------- *//

  return (
    <div className={`${styles["disclosure-read-box"]}`}>
      {/*上方bar*/}
      <div className="title-box">
        <BackTitle
          title={`${
            ghgEmissionsReduction_SelectedItemData
              ? ghgEmissionsReduction_SelectedItemData.companyCode +
                " - " +
                ghgEmissionsReduction_SelectedItemData.companyName
              : ""
          }`}
          url={`/admin/declaration/ghg-emissions-reduction`}
          state={location.state || null}
        />
      </div>
      <div className="content-box position-relative scroll">
        {getGhgDeclareDataIsPending ? <VisuallLoading /> : null}
        {sectionData.map((section, sectionIndex) => {
          return (
            <div key={sectionIndex} className="section-box">
              <div className="section-title">{section.sectionTitle}</div>
              {section.questions.map((question, questionIndex) => {
                return (
                  <QuestionItemComponent
                    key={`${sectionIndex}_${questionIndex}`}
                    question={question}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Detail;
