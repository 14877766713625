// 列印model elemnt 相關id
export const PrintElementType = {
    /** 申報ESG資訊總覽 */
    EsgOverviewForm: 'esg-overview-form',
    /** 個別公司查詢 */
    IndividualTableBox: 'individual-table-box',
    /** 資料彙總查詢(表格模式) */
    SummaryTableBox: 'summary-table-box',
    /** 資料彙總查詢(section 模式) */
    SummaryFormBox: 'summary-form-box'
}