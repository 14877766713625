//* ---------------------------- React start ---------------------------- *//
import { FC } from "react";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
// import styles from "./RolePermissionRouter.module.scss";
import List from "./List/List";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import {
  FeaturePermissionCodes,
  PermissionRouteObjectModel,
  PermissionNavBarItemModel,
  ActionsPermissionCodes,
} from "models/auth/permissionModel";
import EmptyWaitPage from "components/common/EmptyWaitPage/EmptyWaitPage";
import useUserInfoStore from "state/useUserInfoStore";
import { usePermissionContext } from "context/PermissionProvider";
//* ---------------------------- local end ---------------------------- *//

const RolePermissionRouterLayout: FC = () => {
  return (
    <div className="no-nav-content-box">
      <CustomErrorBoundary>
        <Outlet></Outlet>
      </CustomErrorBoundary>
    </div>
  );
};

const RolePermissionRouter: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const { PermissionService } = usePermissionContext();
  const { permissionRouter } = PermissionService;

  const routers: PermissionRouteObjectModel[] = [
    {
      path: "",
      element: <RolePermissionRouterLayout />,
      children: [
        {
          path: "list",
          element: <List />,
          featurePermission: FeaturePermissionCodes.RoleManage.RoleManage,
          actionReadPermission:
            ActionsPermissionCodes.RoleManage.RoleManageRead,
        },
      ],
    },
  ];
  //* ---------------------------- state end ---------------------------- *//
  return useRoutes(permissionRouter(routers));
};

export default RolePermissionRouter;
