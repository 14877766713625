import React, { FC, useEffect, useState } from "react";
import styles from "./SearchInput.module.scss";

interface SearchInputProps {
  onClick: (key: string) => void;
  placeholder?: string;
  value?: string | null;
}

const SearchInput: FC<SearchInputProps> = (props) => {
  const [key, setKey] = useState("");

  const handleChangeKey = (msg: string) => {
    setKey(msg);
    // 空的時候主動觸發
    if (!msg) {
      props.onClick("");
    }
  };
  const handleEvent = () => {
    props.onClick(key);
  };
  useEffect(() => {
    if(props.value) {
      setKey(props.value);
    }
  }, [props.value]);
  
  return (
    <div className={styles["search-box"]}>
      <input
        className={styles["seach-input"]}
        placeholder={props.placeholder || "搜尋..."}
        value={key}
        onKeyUp={e => e.key === 'Enter' && handleEvent()}
        onChange={(e) => handleChangeKey(e.target.value)}
      />
      <div className={styles["search-img-box"]}>
        <img
          alt="搜尋"
          src="/assets/images/buttonIcon/search-icon.svg"
          onClick={() => handleEvent()}
        />
      </div>
    </div>
  );
};

export default SearchInput;
