import React, { FC, useEffect, useState } from "react";
import styles from "./GhgReductionInfo.module.scss";
import Dropdown from "../../../components/base/Dropdown/Dropdown";
import LinkIconButton from "../../../components/button/LinkIconButton/LinkIconButton";
import ContentModal, {
  ContentModalProps,
} from "../../../components/base/ContentModal/ContentModal";
import { MarketTypeEnum, OptionModel, RequestType } from "models/baseModel";
import { useMutation } from "@tanstack/react-query";
import mopsEsgService from "services/inquiry/infoDisclosure/mopsEsgService";
import mopsGhgService from "services/inquiry/ghgReuctionInfo/mopsGhgService";
import {
  GhgDeclareInfoModel,
  MopsGhgDataRequestModel,
  MopsGhgRequestModel,
} from "models/inquiry/ghgReductionInfo/mopsGhgDataInfoModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import EmptySearchPage, {
  EmptySearchPageProps,
} from "components/otherModule/EmptySearchPage/EmptySearchPage";
import { useLocation, useSearchParams } from "react-router-dom";
import useSearchParamStore from "state/useSearchParamStore";
import { MarketOp } from "models/optionModel";
import { ResponseCode } from "models/responseCodeModel";
import { error2Alert } from "utils/otherToast";
import { GhgReductionInfoResultModel } from "models/localSearchResultModel";
import ProblemReportModal, {
  ProblemReportModalProps,
} from "components/otherModule/ProblemReportModal/ProblemReportModal";
import { PrintService } from "services/common/printService";
import { submitGAEvent } from "services/common/GA";
import { useTranslation } from "react-i18next";

//#region GhgReductionTableComponent
interface GhgReductionTableComponentProps {
  data: GhgDeclareInfoModel[];
  year?: number | null;
  marketType?: MarketTypeEnum | null;
}

const GhgReductionTableComponent: FC<GhgReductionTableComponentProps> = (
  props
) => {
  const { data, year, marketType } = props;
  // 將資料切成一半呈現
  const [tableData, setTableData] = useState<GhgDeclareInfoModel[][]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (data.length > 0) {
      if (data.length > 1) {
        // 資料拆為左右各一半
        const halfIndex = Math.ceil(data.length / 2);
        const newData = [
          [...data.slice(0, halfIndex)],
          [...data.slice(halfIndex, data.length)],
        ];

        setTableData(newData);
      } else {
        // 一筆資料而已
        setTableData([[...data]]);
      }
    }
  }, [data]);

  // modal 物件(context confirm)
  const [detailModal, setDetailModal] = useState<ContentModalProps>({
    show: false,
    handleClose: () => {
      setDetailModal({
        ...detailModal,
        show: false,
      });
    },
    title: t("GREENHOUSE_GAS_EMISSIONS_AND_REDUCTION_INFORMATION"),
    size: "lg",
  });

  const getGhgDeclareData = useMutation({
    mutationFn: (model: MopsGhgRequestModel) =>
      mopsGhgService.getGhgDeclareData(model),
    onError: (err) => {
      console.log(err);
    },
  });

  /** 取得市場別字串 */
  const getMarketStr = (): string => {
    let str = "";
    let name = '';
    switch (marketType) {
      case MarketTypeEnum.Listing:
        name = '上市';
        break;
      case MarketTypeEnum.OTC:
        name = '上櫃';
        break;
      case MarketTypeEnum.PO:
        name = '公發';
        break;
      case MarketTypeEnum.Emerging:
        name = '興櫃';
        break;
      default:
        name = '';
    }
    if(name){
      str = `(${name}公司)`;
    }
    return str;
  };

  // 處理modal關閉事件
  const openDetailData = (item: GhgDeclareInfoModel) => {
    // console.log(item);
    let model: MopsGhgRequestModel = {
      declareId: item.declareId,
      year: year,
      companyCode: item.companyCode,
    };
    getGhgDeclareData.mutate(model, {
      onSuccess: (res) => {
        if (res.code === ResponseCode.SuccessOK && res.success) {
          if (res.data) {
            setDetailModal({
              ...detailModal,
              title: `${t(
                "GREENHOUSE_GAS_EMISSIONS_AND_REDUCTION_INFORMATION"
              )} - ${item.companyName}`,
              show: true,
              children: (
                <div className="detail-content-box scroll">
                  <div className="company-title">
                    {`${t("INDIVIDUAL_MESSAGE2", {
                      marketName: getMarketStr(),
                      companyName: item.companyName,
                    })}( ${t("DATA_YEAR")}：${year} )`}
                  </div>
                  {sectionData.map((section, sectionIndex) => {
                    return (
                      <div key={sectionIndex} className="section-box">
                        <div className="section-title">
                          {section.sectionTitle}：
                        </div>
                        {section.questions.map((question, questionIndex) => {
                          return (
                            <div
                              key={`${questionIndex}_${question.title}`}
                              className="question-box"
                            >
                              <div className="question">{question.title}</div>
                              <div className="control">
                                <div className="display-val">
                                  {res.data[question.value]}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ),
            });
          }
        } else {
          error2Alert(res.message);
        }
      },
    });
  };

  // section 問題
  const sectionData = [
    {
      sectionTitle: t("GHG_QUESTION_MESSAGE1"),
      questions: [
        {
          title: t("GHG_QUESTION_MESSAGE2"),
          value: "regulationsRisk",
        },
        {
          title: t("GHG_QUESTION_MESSAGE3"),
          value: "substanceRisk",
        },
        {
          title: t("GHG_QUESTION_MESSAGE4"),
          value: "chance",
        },
        {
          title: t("GHG_QUESTION_MESSAGE5"),
          value: "ghgEmissionInfo",
        },
      ],
    },
    {
      sectionTitle: t("GHG_QUESTION_MESSAGE6"),
      questions: [
        {
          title: t("GHG_QUESTION_MESSAGE7"),
          value: "strategy",
        },
        {
          title: t("GHG_QUESTION_MESSAGE8"),
          value: "target",
        },
        {
          title: t("GHG_QUESTION_MESSAGE9"),
          value: "budgetAndPlan",
        },
        {
          title: t("GHG_QUESTION_MESSAGE10"),
          value: "reductionEffect",
        },
      ],
    },
  ];
  return (
    <div id="table-box" className="table-box">
      {tableData.map((item, itemIndex) => {
        return (
          <div key={itemIndex} className="table-item">
            <table
              aria-label={t("SEARCH_RESULT")}
              className="table-container sticky-table table-layout-fixed inquiry-table"
            >
              <thead>
                <tr>
                  <th scope="col">{t("COMPANY_CODE")}</th>
                  <th scope="col">{t("COMPANY_NAME")}</th>
                  <th scope="col">{t("DATA")}</th>
                </tr>
              </thead>
              <tbody>
                {item.map((itemData, itemIndex) => {
                  return (
                    <tr key={itemIndex}>
                      <td>{itemData.companyCode}</td>
                      <td title={itemData.companyName}>
                        {itemData.companyName}
                      </td>
                      <td>
                        <LinkIconButton
                          imgName="document-icon.svg"
                          text={t("DETAILS")}
                          onClick={() => openDetailData(itemData)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
      <ContentModal {...detailModal}></ContentModal>
    </div>
  );
};
//#endregion

//#region FilterComponent
interface FilterProps {
  searchChange: (param: MopsGhgDataRequestModel) => void;
  yearOption: OptionModel[];
  marketOption: OptionModel[];
  /** 預設param */
  defaultParam: MopsGhgDataRequestModel;
}
const FilterComponent: FC<FilterProps> = (props) => {
  const { defaultParam, yearOption, marketOption } = props;
  const [param, setParam] = useState<MopsGhgDataRequestModel>(defaultParam);

  // 按鈕卡控
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const { t } = useTranslation();
  useEffect(() => {
    if (defaultParam.marketType !== null || defaultParam.year !== null) {
      setParam({
        ...param,
        ...defaultParam,
      });
    }
  }, [defaultParam]);

  useEffect(() => {
    // 不存在則預設空
    if (yearOption && yearOption.length > 0) {
      if (defaultParam.year !== null) {
        const exist = yearOption.some(
          (p) => p.id === defaultParam.year?.toString()
        );
        if (!exist) {
          setParam({
            ...param,
            year: null,
          });
        }
      }
    }
  }, [yearOption]);

  const search = () => {
    props.searchChange(param);
  };

  //#region diabled button
  useEffect(() => {
    if (param) {
      disabledCondition();
    }
  }, [param]);

  const disabledCondition = () => {
    if (param.year !== null && param.marketType !== null) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };
  //#endregion

  const changeValue = (type: string, value: string | null) => {
    switch (type) {
      case "year":
        if (value) {
          const year = Number.parseInt(value);
          setParam({
            ...param,
            year: year,
          });
        } else {
          setParam({
            ...param,
            year: null,
          });
        }
        break;
      case "market":
        if (value) {
          const market = Number.parseInt(value);
          setParam({
            ...param,
            marketType: market,
          });
        } else {
          setParam({
            ...param,
            marketType: null,
          });
        }
        break;
    }
  };

  return (
    <>
      <div className="search-item-box">
        <div className="mt-3">
          <Dropdown
            isFloatTitle={true}
            placeholder={`${t("MARKET_TYPE")}*`}
            options={marketOption}
            defaultId={param.marketType?.toString()}
            onChange={(e) => {
              changeValue("market", e);
            }}
          />
        </div>
        <div className="mt-3">
          <Dropdown
            isFloatTitle={true}
            placeholder={`${t("REPORTING_YEAR")}*`}
            options={yearOption}
            defaultId={param.year?.toString()}
            onChange={(e) => {
              changeValue("year", e);
            }}
          />
        </div>
        <div className="mt-3">
          <button
            disabled={btnDisabled === true}
            className={`default ` + `${btnDisabled ? "disabled " : ""}`}
            onClick={search}
          >
            {t("INQUIRY")}
          </button>
        </div>
      </div>
    </>
  );
};
//#endregion

//#region 純查詢結果
//#endregion

interface GhgReductionInfoProps {}

const GhgReductionInfo: FC<GhgReductionInfoProps> = () => {
  const [defaultParam, setDefaultParam] = useState<MopsGhgDataRequestModel>({
    marketType: null,
    year: null,
  });
  const [param, setParam] = useState<MopsGhgDataRequestModel>({
    marketType: null,
    year: null,
  });
  const [tableYear, setTableYear] = useState<number | null>();
  const [hide, setHide] = useState(false);
  const { t } = useTranslation();
  const [empty, setEmpty] = useState<EmptySearchPageProps>();
  const [loading, setLoading] = useState<boolean>();
  const [ghgReductionData, setReductionData] = useState<GhgDeclareInfoModel[]>(
    []
  );
  // 開分頁暫存的結果
  const [tmpResult, setTmpResult] = useState<GhgReductionInfoResultModel>();
  // 儲存filter參數
  const {
    ghgReductionInfoResult,
    setGhgReductionInfoResult,
    clearGhgReductionInfoResult,
  } = useSearchParamStore();
  // 取得搜尋參數
  const [queryParams, setSearchParams] = useSearchParams();
  // 是否顯示結果
  const [showResult, setShowResult] = useState<any>({
    show: false,
    print: false,
  });
  const [yearOption, setYearOption] = useState<OptionModel[]>([]);
  const location = useLocation();
  useEffect(() => {
    // 初始化完翻譯再set
    setEmpty({
      word: t("PLEASE_ENTER_SEARCH_CONDITION"),
    });
  }, [t]);
  //#region 開新分頁相關
  useEffect(() => {
    // 點別的模組 監聽路由參數，搜尋空 須改回false
    if (!location.search) {
      setShowResult({
        ...showResult,
        show: false,
      });
    }
  }, [location]);

  // query參數拿出
  useEffect(() => {
    const newWindow = queryParams.get("newWindow");
    const isPrint = queryParams.get("isPrint");

    if (newWindow || isPrint) {
      // 為了讓重整or 點別的模組可以區分路由有改變的參數(單純路由變化)
      queryParams.set("result", "y");
      const newObj = {
        show: false,
        print: false,
      };
      // 分頁
      if (newWindow === "y" && ghgReductionInfoResult) {
        newObj.show = true;
        queryParams.delete("newWindow");
        setTmpResult({
          ...ghgReductionInfoResult,
        });
        clearGhgReductionInfoResult();
      }

      // 影印
      if (isPrint === "y") {
        newObj.print = true;
        // 刪掉print
        queryParams.delete("isPrint");
        setTimeout(() => {
          window.print();
        }, 50);
      }
      setSearchParams(queryParams);
      setShowResult({
        ...showResult,
        ...newObj,
      });
    }
  }, [queryParams]);

  // 處理新視窗
  const handleNewWindow = (print: boolean) => {
    setGhgReductionInfoResult({
      data: ghgReductionData,
      year: tableYear,
    });
    PrintService.openNewWindowByParam("newWindow=y", print);
  };
  //#endregion

  const handleHide = () => {
    setHide((prev) => (prev = !prev));
  };

  //#region 取得篩選後代碼名稱列表
  const getMopsGhgList = useMutation({
    mutationFn: (param: MopsGhgDataRequestModel) =>
      mopsGhgService.getMopsGhgList(param),
    onSuccess: (res) => {
      clearEmpty();
      if (res.code === ResponseCode.SuccessOK && res.success) {
        if (res.data) {
          const result: GhgDeclareInfoModel[] = res.data;
          if (result.length > 0) {
            setReductionData([...result]);
          } else {
            setEmpty({
              ...empty,
              word: t("NO_INFORMATION_FOUND"),
              url: null,
            });
          }
        } else {
          setEmpty({
            ...empty,
            word: `${t("OLD_DATA_URL_MESSAGE")}\n${res.message}`,
            url: res.message,
          });
        }
        //- ga event
        submitGAEvent("GHG_Reduction_Inquiry");
      } else {
        setEmpty({
          ...empty,
          word: res.message,
          url: null,
        });
      }
    },
    onError: (err) => {
      console.log(err);
      clearEmpty();
    },
  });

  // 清除空白
  const clearEmpty = () => {
    setReductionData([]);
  };
  //#endregion

  //#region  外部帶入參數
  useEffect(() => {
    const year = queryParams.get("year");
    const market = queryParams.get("market")
      ? Number.parseInt(queryParams.get("market")!)
      : null;
    if (market !== null && year !== null) {
      let newYear = Number.parseInt(year);
      const newModel = {
        ...defaultParam,
        marketType: market,
        year: newYear,
      };
      setTableYear(newYear);
      setDefaultParam({
        ...newModel,
      });
      getMopsGhgList.mutate(newModel);
    }
  }, [queryParams]);
  //#endregion

  //#region 取得清單相關
  // 取得一次
  useEffect(() => {
    getYearList.mutate(RequestType.GhgEmissionAndReduction);
  }, []);
  // 取得報告年度與市場別清單
  const getYearList = useMutation({
    mutationFn: (type: RequestType) => mopsEsgService.getYearList(type),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        const result: number[] = res.data;
        setYearOptionModel(result);
      } else {
        console.log(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const setYearOptionModel = (result: number[]) => {
    let op: OptionModel[] = [];
    // 設置option
    result.forEach((p: number) => {
      const year = p.toString();
      op.push({
        id: year,
        text: year,
      });
    });
    setYearOption(op);
  };

  //#endregion

  //#region 問題回報modal
  const [problemModal, setProblemModal] = useState<ProblemReportModalProps>({
    show: false,
    handleClose: () => {
      setProblemModal({
        ...problemModal,
        show: false,
      });
    },
    size: "sm",
  });
  //#endregion
  return (
    <div className="no-nav-content-box">
      <div className={styles["ghg-reduction-info-box"]}>
        {!showResult.show ? (
          <>
            {getMopsGhgList.isPending && <VisuallLoading />}
            {/* 左區塊 */}
            <div className={"left-box " + (hide ? "hide" : "")}>
              <div style={{ display: hide ? "none" : "block" }}>
                <div className="toggle-box">
                  <div className="main-title">{t("SEARCH_CONDITION")}</div>
                  <img
                    alt=""
                    src="/assets/images/buttonIcon/toggle-left-icon.svg"
                    onClick={handleHide}
                  />
                </div>
                <FilterComponent
                  yearOption={yearOption}
                  marketOption={MarketOp}
                  defaultParam={defaultParam}
                  searchChange={(item: MopsGhgDataRequestModel) => {
                    getMopsGhgList.mutate(item);
                    setParam(item);
                    setTableYear(item.year);
                  }}
                />
              </div>
              {hide && (
                <div className="img-tool">
                  <img
                    alt=""
                    src="/assets/images/buttonIcon/toggle-right-icon.svg"
                    onClick={handleHide}
                  />
                  {/* <img
                alt=""
                src="/assets/images/buttonIcon/filter-icon.svg"
                onClick={handleHide}
              /> */}
                </div>
              )}
            </div>
            {/* 右區塊 */}
            <div className={`right-box ` + `${hide ? "" : "has-filter "}`}>
              {/* 上區塊 */}
              <div className="tool-box">
                <div className="result-title-box">
                  <div className="main-title">{t("SEARCH_RESULT")}</div>
                  <div className="sub-title">{`(${ghgReductionData?.length})`}</div>
                </div>
                <div className="tool-item-box">
                  {ghgReductionData.length > 0 && (
                    <>
                      <LinkIconButton
                        imgName="print-icon.svg"
                        text={t("PRINT_WEB_PAGE")}
                        onClick={() => {
                          handleNewWindow(true);
                        }}
                      />
                      <LinkIconButton
                        imgName="new-window-icon.svg"
                        text={t("OPEN_NEW_WINDOW")}
                        onClick={() => {
                          handleNewWindow(false);
                        }}
                      />
                    </>
                  )}
                  <LinkIconButton
                    imgName="question-icon.svg"
                    text={t("PROBLEM_REPORT")}
                    onClick={() => {
                      setProblemModal({
                        ...problemModal,
                        show: true,
                      });
                    }}
                  />
                </div>
              </div>
              {/* 下區塊 */}
              <div className="result-box scroll">
                {ghgReductionData.length > 0 ? (
                  <GhgReductionTableComponent
                    data={ghgReductionData}
                    year={tableYear}
                    marketType={param.marketType}
                  />
                ) : (
                  <EmptySearchPage className="empty-box" {...empty} />
                )}
              </div>
              <ProblemReportModal {...problemModal} />
            </div>
          </>
        ) : (
          <div
            className={
              `show-result-box scroll ` +
              `${showResult.print ? "print-box " : ""}`
            }
          >
            <GhgReductionTableComponent
              data={tmpResult?.data || []}
              year={tmpResult?.year}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GhgReductionInfo;
