import { FC, useEffect, useState } from "react";
import styles from "./TabVerticalCategoryThin.module.scss";
import { useNavigate } from "react-router-dom";
import { CategoryNavBarListModel, NavBarItemModel } from "models/baseModel";
import Prompt from "components/otherModule/Prompt/Prompt";

interface TabVerticalCategoryThinProps {
  data: CategoryNavBarListModel[];
  tabUrl: string;
  //onClick: () => void;
  state?: { [key: string]: unknown };
  /** 是否處理其他事件(直接往外丟事件就好) */
  handleOtherEvent?: boolean;
  /** 改變item */
  changeItem?: (item: NavBarItemModel) => void;
  /** isNeedPrompt */
  isNeedPrompt?: boolean;
  /** IsDataChange */
  isDataChange?: boolean;
  /** promptDisplayText */
  promptDisplayText?: string;
}

const TabVerticalCategoryThin: FC<TabVerticalCategoryThinProps> = ({
  handleOtherEvent = false,
  ...props
}) => {
  const [tabUrl, setTabUrl] = useState<string>(props.tabUrl);
  const [state] = useState<{ [key: string]: unknown } | undefined>(props.state);
  const navigate = useNavigate();
  const [nextNavItem, setNextNavItem] = useState<NavBarItemModel | null>(null);

  const handleEvent = (item: NavBarItemModel) => {
    if (item.disabled === false) {
      if (props.isDataChange) {
        setNextNavItem(item); // 設置下一個要導航的項目
        navigateToItem(item);
      } else {
        navigateToItem(item);
      }
    }
  };

  const navigateToItem = (item: NavBarItemModel) => {
    if (!item) return;
    if (!handleOtherEvent) {
      if (item.url) {
        setTabUrl(item.url);
      }
      if (state) {
        navigate(item.url, { state: state });
      } else {
        navigate(item.url);
      }
    } else {
      if (props.changeItem) {
        props.changeItem(item);
      }
    }
  };

  useEffect(() => {
    setTabUrl(props.tabUrl);
    // console.log("props.tabUrl", props.tabUrl);
  }, [props.tabUrl]);

  return (
    <div className={`${styles["TabVerticalCategoryThin"]}`}>
      <div className={"tree-box scroll"}>
        {props.data.map((item, index) => {
          return (
            <ul className="categoryUl" key={index}>
              <li
                className={`categoryTitle ${item.customClassName} ${
                  tabUrl === item.url ? "active" : ""
                } ${item.disabled === true ? "disabled" : ""}`}
                onClick={() => {
                  if (!item.isNav) return;
                  if (item.url) {
                    handleEvent(item as NavBarItemModel);
                  }
                }}
              >
                {item.name}
              </li>
              {item.list?.length ? (
                <ul className="navItemsUl">
                  {item.list.map((subItem, subIndex) => {
                    return (
                      <li
                        key={subIndex}
                        className={`navItemsLi ${
                          tabUrl === subItem.url ? "active" : ""
                        } ${subItem.disabled === true ? "disabled" : ""}`}
                        onClick={() => handleEvent(subItem)}
                      >
                        {subItem.name} {subItem.disabled}
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </ul>
          );
        })}
      </div>
      {props.isNeedPrompt ? (
        <Prompt
          when={props.isDataChange !== undefined ? props.isDataChange : false}
          message={props.promptDisplayText || "確定要離開嗎？"}
          onConfirm={() => {
            navigateToItem(nextNavItem!);
            setNextNavItem(null);
            // console.log("onConfirm");
          }}
          onCancel={() => {
            setNextNavItem(null);
            // console.log("onCancel");
          }}
        />
      ) : null}
    </div>
  );
};

export default TabVerticalCategoryThin;
