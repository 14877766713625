//* ---------------------------- React start ---------------------------- *//
import React, {
  FC,
  createContext,
  useContext,
  ReactNode,
  useEffect,
} from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import {
  PagePermissionCodes,
  PermissionRouteObjectModel,
  PermissionSideBarItemModel,
  ActionsPermissionCodes,
  FeaturePermissionCodes,
} from "models/auth/permissionModel";
import { usePermissionContext } from "context/PermissionProvider";
//* ---------------------------- local end ---------------------------- *//

import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
import ProtectedRoute from "../../context/ProtectedRoute";
import { SideBarItemModel } from "../../models/baseModel";
import Header from "../../components/layouts/Header/Header";
import Sidebar from "../../components/layouts/SideBar/Sidebar";
import IndicatorRouter from "./Indicator/IndicatorRouter";
import Home from "./Home/Home";
import IndustryRouter from "./Industry/IndustryRouter";
import useUserInfoStore from "state/useUserInfoStore";
import { Toaster } from "react-hot-toast";
import styles from "./AdminRouter.module.scss";
import { NavLink } from "react-router-dom";
import DeclarationRouter from "./Declaration/DeclarationRouter";
import QuestionnaireRouter from "./Questionnaire/QuestionnaireRouter";
import SystemManageRouter from "./SystemManage/SystemManageRouter";
import RolePermissionRouter from "./RolePermission/RolePermissionRouter";
import SustainStandard from "./SustainStandard/SustainStandardRouter";
import ManuscriptRouter from "./Manuscript/ManuscriptRouter";
import { useTranslation } from "react-i18next";
import EduPropagandaManageRouter from "pages/Admin/EduPropagandaManage/EduPropagandaManageRouter";

//~ ---------------------------- AdminLayout start ---------------------------- ~//

const AdminLayout: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const { PermissionState, PermissionService } =
  usePermissionContext();
  const { noPermissionPage } = PermissionState;
  const { detectEveryPagePermission, isAnyPagePermission } =
    PermissionService;
  const {t} = useTranslation();
  const sidebarItems: PermissionSideBarItemModel[] = [
    {
      moduleName: "首頁",
      url: "home",
      icon: "/assets/images/sidebar/sidebar-admin-home-icon.svg",
      permission: PagePermissionCodes.Home,
    },
    {
      moduleName: "申報資料管理",
      url: "declaration",
      icon: "/assets/images/sidebar/sidebar-admin-declaration-information-icon.svg",
      permission: PagePermissionCodes.DeclareDataManage,
    },
    {
      moduleName: "ESG指標管理",
      url: "indicator",
      icon: "/assets/images/sidebar/sidebar-admin-esg-indicator-icon.svg",
      permission: PagePermissionCodes.IndicatorManage,
    },
    {
      moduleName: "報告書底稿管理",
      url: "manuscript",
      icon: "/assets/images/sidebar/sidebar-admin-report-manuscript-icon.svg",
      permission: PagePermissionCodes.ReportManage,
    },
    {
      moduleName: "行業別管理",
      url: "industry",
      icon: "/assets/images/sidebar/sidebar-admin-industry-management-icon.svg",
      permission: PagePermissionCodes.IndustryManage,
    },
    {
      moduleName: "永續準則矩陣管理",
      url: "principle-matrix",
      icon: "/assets/images/sidebar/sidebar-admin-sustainability-matrix-icon.svg",
      permission: PagePermissionCodes.SustainMatrixManage,
    },
    {
      moduleName: "問卷管理",
      url: "questionnaire",
      icon: "/assets/images/sidebar/sidebar-admin-questionnaire-management-icon.svg",
      permission: PagePermissionCodes.QuestionnaireManage,
    },
    {
      moduleName: "教育資源宣導管理",
      url: "edu-propaganda",
      icon: "/assets/images/sidebar/sidebar-admin-role-permission-icon.svg",
      permission: PagePermissionCodes.EduPropagandaManage,
    },
    {
      moduleName: "角色權限管理",
      url: "role-permission",
      icon: "/assets/images/sidebar/sidebar-admin-role-permission-icon.svg",
      permission: PagePermissionCodes.RoleManage,
    },
    {
      moduleName: "系統管理",
      url: "function",
      icon: "/assets/images/sidebar/sidebar-admin-system-operation-icon.svg",
      permission: PagePermissionCodes.SystemManage,
    },
  ];
  //* ---------------------------- state end ---------------------------- *//

  return (
    <div className="root-box admin">
      {/* Narbar */}
      <div>
        <Header moduleName={t('MANAGEMENT_BACKGROUND')} />
      </div>
      <div className={`layout-box scroll`}>
        {isAnyPagePermission() ? (
          <>
            {/* 父層不更動的部分 */}
            <nav className={`${styles["admin-sidebar-nav"]}`}>
              <ul>
                {sidebarItems.map((item, index) =>
                  detectEveryPagePermission([item.permission]) ? (
                    <li key={index}>
                      <NavLink to={`${item.url}`}>
                        <img src={item.icon} alt={item.moduleName} />
                        <div>{item.moduleName}</div>
                      </NavLink>
                    </li>
                  ) : null
                )}
              </ul>
            </nav>
            {/* 子層替換 */}
            <div className="layout-container-box">
              <Outlet></Outlet>
            </div>
          </>
        ) : (
          <div className={`${styles["noPermission"]}`}>
            {noPermissionPage("unset")}
          </div>
        )}
      </div>
    </div>
  );
};

//~ ---------------------------- AdminLayout end ---------------------------- ~//

//~ ---------------------------- AdminRouter start ---------------------------- ~//

interface AdminRouterProps {}

const AdminRouter: FC<AdminRouterProps> = () => {
  //* ---------------------------- state start ---------------------------- *//
  const isAdmin = useUserInfoStore((p) => p.userInfo?.isAdmin);
  // 不是管理者才可以
  const isAllowed = isAdmin;
  // console.log("adminPage isAdmin:", isAdmin);
  const { PermissionService } = usePermissionContext();
  const {
    detectEveryFeatruePermission,
    detectEveryActionPermission,
    permissionRouter,
  } = PermissionService;
  //* ---------------------------- state end ---------------------------- *//

  const routers: PermissionRouteObjectModel[] = [
    {
      path: "",
      element: (
        <ProtectedRoute isAllowed={isAllowed} redirectPath="/apply">
          <AdminLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "home/*",
          element: <Home />,
          pagePermission: PagePermissionCodes.Home,
          actionReadPermission:
            detectEveryFeatruePermission([
              FeaturePermissionCodes.Home.Dashboard,
            ]) &&
            detectEveryActionPermission([
              ActionsPermissionCodes.Home.DashboardRead,
            ]),
        },
        //- 申報資料管理
        {
          path: "declaration/*",
          element: <DeclarationRouter />,
          pagePermission: PagePermissionCodes.DeclareDataManage,
        },
        {
          path: "indicator/*",
          element: <IndicatorRouter />,
          pagePermission: PagePermissionCodes.IndicatorManage,
        },
        {
          path: "industry/*",
          element: <IndustryRouter />,
          pagePermission: PagePermissionCodes.IndustryManage,
        },
        // 報告書底稿
        {
          path: "manuscript/*",
          element: <ManuscriptRouter />,
          pagePermission: PagePermissionCodes.ReportManage,
        },
        //- 問卷管理
        {
          path: "questionnaire/*",
          element: <QuestionnaireRouter />,
          pagePermission: PagePermissionCodes.QuestionnaireManage,
        },
        //- 教育宣導資源
        {
          path: "edu-propaganda/*",
          element: <EduPropagandaManageRouter />,
          pagePermission: PagePermissionCodes.EduPropagandaManage,
        },
        //- 角色權限管理
        {
          path: "role-permission/*",
          element: <RolePermissionRouter />,
          pagePermission: PagePermissionCodes.RoleManage,
        },
        {
          path: "function/*",
          element: <SystemManageRouter />,
          pagePermission: PagePermissionCodes.SystemManage,
        },
        {
          path: "principle-matrix/*",
          element: <SustainStandard />,
          pagePermission: PagePermissionCodes.SustainMatrixManage,
          featurePermission: undefined,
          actionReadPermission: undefined,
          children: undefined,
        },
      ],
    },
  ];

  // console.log("permissionRouter", permissionRouter());
  let elements = useRoutes(permissionRouter(routers));
  return <>{elements}</>;
};

export default AdminRouter;
//~ ---------------------------- AdminRouter end ---------------------------- ~//
