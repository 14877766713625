import { FC, useEffect, useState } from "react";
import styles from "./Industry.module.scss";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import BackTitle from "components/base/BackTitle/BackTitle";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import MarketService from "services/admin/marketService";
import { IndustryManageRequestModel, IndustryModel } from "models/admin/industryModel";
import toast from "react-hot-toast";
import NormalInput from "components/base/NormalInput/NormalInput";
import BatchUploadModal, { BatchUploadModalProps } from "components/base/BatchUploadModal/BatchUploadModal";
import TablePagination from "components/base/TablePagination/TablePagination";
import { normalDate } from "utils/dateTimeFormat";
import SearchInput from "components/base/SearchInput/SearchInput";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import ConfirmModal, { ConfirmModalProps } from "components/base/ConfirmModal/ConfirmModal";
import { CommonService } from "services/common/commonService";
import { ResponseCode } from "models/responseCodeModel";
import { error2Alert } from "utils/otherToast";
import { usePermissionContext } from "context/PermissionProvider";
import { IndustryManageActionsPermissionCodes } from "models/auth/permissionModel";

const Industry: FC = () => {
    // #region 變數
    const [list, setList] = useState<IndustryModel[]>([]);
    const [orgList, setOrgList] = useState<IndustryModel[]>([]);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [checkCount, setCheckCount] = useState(0);
    const [openCheck, setOpenCheck] = useState(false);
    const [newList, setNewList] = useState<IndustryModel[]>([]);
    const [title, setTtle] = useState('');

    const params= useParams();
    const [count, setCount] = useState<number | undefined>(0);
    const [listIsPending, setListIsPending] = useState(false);
    const [request, setRequest] = useState<IndustryManageRequestModel>({
        page: 1,
        pageSize: 10,
        searchKey: null,
        sortKey: null,
        sortType: null,
        industryYearId: params.id
    });
    // modal 物件(context confirm)
    const [importModal, setImportModal] = useState<BatchUploadModalProps>({
        show: false,
        handleClose: () => {
            setImportModal((prev) => {
            return {
            ...prev,
            show: !prev.show,
            };
        });
        },
        handleUpload: (e) => uploadFile(e),
        sampleDownloadUrl: null,
        uploadLoading: false,
    });
    // modal 物件(confirm)
    const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
        show: false,
        handleClose: () => {
        setConfirmModal({
            ...confirmModal,
            show: false,
        });
        },
        handleConfirm: () => {},
        title: "確認",
    });
    const { PermissionService } = usePermissionContext();
    const { detectEveryActionPermission } = PermissionService;
    // #endregion

    // #region CRUD

    //取的清單
    const GetListMutation = useMutation({
        mutationFn: MarketService.industryList,
    });
    // 取得清單
    const handleList = () => {
        setListIsPending(true);
        GetListMutation
        .mutateAsync(request)
        .then(p=>{
            setListIsPending(false);
            if(p.success){
                setOrgList(JSON.parse(JSON.stringify(p.data)));
                setList(p.data);
                setTtle(p.message);
                setCount(p.count);
            } else {
                toast.error(p.message);
            }
        }).catch((err) => {
            setListIsPending(false);
            console.log(err);
            setList([]);
            toast.error(err);
        });
    };

    // 新增
    const addIndustryMutation = useMutation({
        mutationFn: MarketService.addIndustry,
    });

    // 新增事件
    const handleAdd = (index: number) => {
        if(newList[index].name === null ||
            newList[index].name === undefined || params.id === undefined) {
            toast.error('欄位必填');
        } else {
            const add:IndustryModel  = {
                id: null,
                industryYearId: params.id,
                //marketType: newList[index].marketType,
                name: newList[index].name
            };
            setListIsPending(true);
            addIndustryMutation
            .mutateAsync(add)
            .then(p=>{
                setListIsPending(false);
                if(p.success){
                    handleList();
                    setNewList([]);
                    toast.success('success');
                } else {
                    toast.error(p.message);
                }
            })
            .catch((err) => {
                setListIsPending(false);
                toast.error(err);
            });
        }
    };

    // 複製
    const copyMarketYearMutation = useMutation({
        mutationFn: MarketService.copyMarket,
    });

    // 複製事件
    /*const handleCopy = (data: MarketYearModel | null) => {
        if(data) {
            if(data.year) {
                let copyTmp = {
                    id: data.id,
                    newYear: data.year + 1
                }
                copyEvent(copyTmp);
            }
        } else {
            const checked = list.find(x=>x.checked === true);
            if(checked && checked.year) {
                const copyTmp = {
                    id: checked.id,
                    year: checked.year + 1
                };
                copyEvent(copyTmp);
            }
        }
    };

    const copyEvent = (data: any) => {
        copyMarketYearMutation
        .mutateAsync(data)
        .then(p=>{
            if(p.success){
                handleList();
                toast.success('success');
                setCheckCount(0);
            } else {
                toast.error(p.message);
            }
        })
    }*/

    // 刪除
    const removeIndustryMutation = useMutation({
        mutationFn: MarketService.removeIndustry,
    });

    //刪除清單
    const handleRemove = (id: string | null) => {

        setConfirmModal({
            ...confirmModal,
            show: true,
            handleConfirm: () => {
                let checked = list.filter(x=>x.checked === true);
                if(id) {
                    checked = list.filter(x=>x.id === id);
                }
                const ids: (string| null)[] = [];
                if(checked) {
                    checked.forEach((c: IndustryModel) => {
                        ids.push(c.id);
                    });
                    setConfirmModal((prev) => {
                        return {
                            ...prev,
                            loading: true,
                        };
                    });
                    setListIsPending(true);
                    removeIndustryMutation
                        .mutateAsync(ids)
                        .then(p=>{
                            setListIsPending(false);
                            if(p.success){
                                handleList();
                                toast.success('刪除成功');
                                setCheckCount(0);
                            } else {
                                toast.error(p.message);
                            }
                        })
                        .catch((err) => {
                            setListIsPending(false);
                            toast.error(err);
                        })
                        .finally(()=> {
                            setConfirmModal({
                                ...confirmModal,
                                show: false,
                                loading: false,
                            });
                        });

                }
            },
          });
    };

    // 編輯
    const saveIndustryMutation = useMutation({
        mutationFn: MarketService.saveIndustry,
    });

    // 編輯事件
    const handleSave = (index: number) => {
        if(list[index].marketType === null || list[index].name === null ||
            list[index].name === undefined || params.id === undefined) {
            toast.error('欄位必填');
        } else {
            const edit:IndustryModel = {
                id: list[index].id,
                industryYearId: params.id,
                //marketType: list[index].marketType,
                name: list[index].name,
            };
            setListIsPending(true);
            saveIndustryMutation
            .mutateAsync(edit)
            .then(p=>{
                setListIsPending(false);
                if(p.success){
                    handleList();
                    toast.success('success');
                } else {
                    toast.error(p.message);
                }
            })
            .catch((err) => {
                setListIsPending(false);
                toast.error(err);
            });
        }
    };

    //下載範例
    const LoadImportXlsMutation = useMutation({
        mutationFn: MarketService.loadImportXls,
    });
    // 取得清單
    const loadSampleXls = () => {
        LoadImportXlsMutation
        .mutateAsync(params.id)
        .then(res=>{
            if (res.status !== ResponseCode.ServerErrorInternal) {
                setImportModal((prev) => {
                    return {
                        ...prev,
                        show: !prev.show,
                        sampleDownloadStream: res
                    };
                });
            }else{
                error2Alert(res.data.message);
            }
        })
        .catch((err) => {
            toast.error(err);
            console.log(err);
        });
    };

    const uploadMutation = useMutation({
        mutationFn: MarketService.importIndustry,
      });

    // #endregion

    // #region 事件



    useEffect(() => {
        handleList();
    }, [request]);


    function handleAllCheckbox() {
        setCheckAll(!checkAll);
        if(!checkAll === true) {
            setCheckCount(list.length);
        } else {
            setCheckCount(0);
        }
        list.forEach(item => {
            item.checked = !checkAll;
        });
    }

    function countCheckbox() {
        const total = list.length;
        const checked = list.filter(x=>x.checked === true).length;
        setCheckCount(checked);
        if(total !== 0 && total === checked) {
            setCheckAll(true);
        } else if(checked !== 0 && total !== checked) {
            setCheckAll(false);
        } else {
            setCheckAll(false);
        }
    }

    function handleChkChange(id: string | null) {
        if(id) {
            setList(list.map((ck, index) => {
                    if(ck.id === id){
                        ck.checked = !ck.checked;
                    }
                    return ck;
                }
            ));
            countCheckbox();
        }
    }

    const handleTmpClick = () => {
        console.log("test");
      };

    const handleEditToggle = (index: number|null, isEdit: boolean) => {
    let tmpList = [...list];
    if(index===null) {
        tmpList.forEach(t => {
        t.isEdit = false;
        })
    } else {
        tmpList[index].isEdit=isEdit;
        tmpList[index].name=orgList[index].name;
    }
    setList(tmpList);
    }
    // 選取年度開關
    const handleOpenCheckToggle = () => {
        setOpenCheck(!openCheck);
        setCheckAll(false);
        setCheckCount(0);
        setNewList([]);
        handleEditToggle(null, false);
        setList(list.map((ck, index) => {
            ck.checked = false;
            return ck;
            }
        ));
    };

    /*const handleDDIndustryChange = (industry: string | null, index: number, isAdd: boolean) => {
        if(isAdd === true) {
            if(newList[index] && industry) {
                setNewList(newList.map((ck, i) => {
                        if(index === i){
                            ck.marketType = parseInt(industry, 10);
                        }
                        return ck;
                    }
                ));
            }
        } else {
            if(list[index] && industry) {
                setList(list.map((ck, i) => {
                    if(index === i){
                        ck.marketType = parseInt(industry, 10);
                    }
                    return ck;
                }));
            }
        }
    }*/

    const industryNameChange = (name: string, index:number, isAdd: boolean) => {
        if(isAdd === true) {
            if(newList[index] && name) {
                setNewList(newList.map((ck, i) => {
                        if(index === i){
                            ck.name = name;
                        }
                        return ck;
                    }
                ));
            }
        } else {
            if(list[index] && name) {
                setList(list.map((ck, i) => {
                    if(index === i){
                        ck.name = name;
                    }
                    return ck;
                }));
            }
        }
    };
    const handleSearch = (key: string) => {

        let tmpreq = {...request};
        tmpreq.searchKey = key === '' ? null : key;
        tmpreq.page = 1;
        setRequest(tmpreq);
      };

    const uploadFile = (files: any[]) => {
        const postData = new FormData();
        for (const file of files) {
            postData.append("file", file);
        }
        setImportModal((prev)=>{
            return{
            ...prev,
            uploadLoading: true
            };
        })
        setListIsPending(true);
        uploadMutation.mutateAsync({
            file: postData,
            industryYearId: params.id
        }).then((x) => {
            setListIsPending(false);
            if (x.success) {
                setImportModal((prev)=>{
                    return{
                    ...prev,
                    uploadLoading: false,
                    show:false
                    };
                })
                if(x.data.status === true) {
                    handleList();
                }
                toast.success(x.data.message);
            } else {
                setImportModal((prev)=>{
                    return{
                    ...prev,
                    uploadLoading: false
                    };
                })
                toast.error(x.message);
            }
        })
        .catch((err) => {
            setListIsPending(false);
            console.log(err);
            toast.error(err);
        });
    };
    // #endregion
    return (
        <div className={styles["industry-list"]}>
            {/*上方bar*/}
            <div className="title-box">
            <BackTitle title={title} url={`/admin/industry`}/>
            </div>
            {listIsPending === true ? <VisuallLoading /> : <></>}
            <div className="tool-box pad-20-t" style={{borderTop: '1px solid #dbe8ef'}}>
                <div className="tool-box">
                    {!openCheck ? (
                        <>
                        <div className="mr-1-m">
                        <SearchInput onClick={handleSearch} />
                        </div>
                        </>
                        ): <>
                        <div className="tool-box mt-10-p">
                            <div className="sel-item">已選取{checkCount}項目</div>
                            {checkCount > 0 ? (
                            <>
                          {detectEveryActionPermission([
                            IndustryManageActionsPermissionCodes.IndustryYearListDelete,
                          ]) ? (
                            <div className="icon-button">
                              <LinkIconButton
                                imgName="trash-icon.svg"
                                text="刪除"
                                onClick={checkCount > 0 ? () => handleRemove(null) : () => { }}
                              />
                            </div>
                          ) : null}
                                </>
                            ) : <></>}
                        </div>
                        </>
                        }
                    </div>

                    <div className="tool-box">
                        <button className="secondary mr-1-m" onClick={handleOpenCheckToggle}>
                        {!openCheck ? '多筆編輯': '取消選取'}
                        </button>

                        {detectEveryActionPermission([
                          IndustryManageActionsPermissionCodes.IndustryCategoryCreate,
                        ]) ? (
                          <button className="default mr-1-m" onClick={() => {
                            setNewList([
                              ...newList,
                              {
                                id: (newList.length++).toString(),
                                industryYearId: null,
                                marketType: null,
                                //name: null,
                                checked: false,
                                isEdit: false
                              }
                            ]);
                            setOpenCheck(false);
                          }}>
                            +新增
                          </button>
                        ) : null}
                        {detectEveryActionPermission([
                          IndustryManageActionsPermissionCodes.IndustryCategoryImport,
                        ]) ? (
                          <><button
                            className="secondary"
                            onClick={() => { loadSampleXls(); }}
                          >
                            批次匯入
                          </button>
                            <BatchUploadModal {...importModal} /></>
                        ) : null}

                    </div>



                </div>

            {/** 主表 */}
            <div className="result-box scroll">
                <div className="table-box ">
                    <table
                        aria-label="查詢結果table"
                        className="table-container sticky-table"
                    >
                    <thead>
                        <tr>
                        {openCheck ? (
                        <th style={{width: '50px'}} scope="col">
                            <label className="custom-checkbox">
                                <input
                                    type="checkbox"
                                    checked={checkAll}
                                    onChange={() => handleAllCheckbox()}
                                />
                                <span>&nbsp;</span>
                            </label>
                        </th>
                        ) : <></>}
                        <th scope="col">產業類別
                        <LinkIconButton
                            imgName="sort-icon.svg"
                            text={null}
                            className={'d-inline'}
                            onClick={handleTmpClick}
                            /></th>
                        <th scope="col">建立日期
                        <LinkIconButton
                            imgName="sort-icon.svg"
                            text={null}
                            className={'d-inline'}
                            onClick={handleTmpClick}
                            /></th>
                        <th scope="col">編輯日期
                        <LinkIconButton
                            imgName="sort-icon.svg"
                            text={null}
                            className={'d-inline'}
                            onClick={handleTmpClick}
                            /></th>
                        <th scope="col" style={{width: '40px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                    {list.length === 0 ? <tr><td style={{textAlign: 'center'}} colSpan={4}>no data</td></tr> :
                    list.map((p, i) => {
                        return (
                            <tr key={p.id}>
                                {/*開啟checkbox的td*/}
                                {openCheck ? (
                                <td>
                                    <label className="custom-checkbox">
                                        <input
                                            type="checkbox"
                                            checked={p.checked}
                                            onChange={()=>handleChkChange(p.id)}
                                        />
                                        <span>&nbsp;</span>
                                    </label>
                                </td>
                                ) : <></>}
                                {/*產業類別*/}
                                { openCheck || p.isEdit!==true ?
                                    (<td>{p.name}</td>) :
                                    (<td><NormalInput
                                        isFloatTitle={false}
                                        placeholder="產業類別*"
                                        defaultValue={p.name}
                                        onChange={(e)=>industryNameChange(e, i, false)}
                                      /></td>)
                                }
                                {/*建立日期*/}
                                <td>{p.createTime? normalDate(p.createTime) : '-'}</td>
                                {/*編輯日期*/}
                                <td>{p.updateTime? normalDate(p.updateTime) : '-'}</td>
                                {!openCheck ? (
                              <td>
                                {
                                  p.isEdit === true ? (
                                    /** 儲存與取消 */
                                    <div className="d-flex">

                                      {detectEveryActionPermission([
                                        IndustryManageActionsPermissionCodes.IndustryCategoryUpdate,
                                      ]) ? (
                                        <> <LinkIconButton
                                          imgName="check-icon.svg"
                                          text=""
                                          imgStyle={{ width: '24px', height: '24px' }}
                                          onClick={() => handleSave(i)}
                                        />
                                          <LinkIconButton
                                            imgName="cross-icon.svg"
                                            text=""
                                            imgStyle={{ width: '24px', height: '24px' }}
                                            onClick={() => handleEditToggle(i, false)}
                                          /></>
                                      ) : null}




                                    </div>
                                  ) :
                                    <>
                                      {detectEveryActionPermission([
                                        IndustryManageActionsPermissionCodes.IndustryCategoryUpdate,
                                      ]) ? (
                                        /* 開啟編輯*/
                                        <LinkIconButton
                                          imgName="right-arrow.svg"
                                          text=""
                                          onClick={() => handleEditToggle(i, true)}
                                        />
                                      ) : null}
                                    </>




                                }
                              </td>
                                ) : (
                                    <td>
                                      { /** 複製與刪除 */}
                                      {detectEveryActionPermission([
                                        IndustryManageActionsPermissionCodes.IndustryCategoryDelete,
                                      ]) ? (

                                        <div className="d-flex">
                                            <LinkIconButton
                                                imgName="trash-icon.svg"
                                                text=""
                                                className={'hover'}
                                                onClick={() => handleRemove(p.id)}
                                            />
                                        </div>
                                      ) : null}

                                        </td>
                                )}
                            </tr>
                          );
                        })}
                        {newList.map((n, i) => {
                            return (
                                <tr key={n.id}>
                                    <td>
                                    <NormalInput
                                        isFloatTitle={false}
                                        placeholder="產業類別*"
                                        onChange={(e)=>industryNameChange(e, i, true)}
                                      />
                                    </td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>
                                    <div className="d-flex">

                                    {detectEveryActionPermission([
                                        IndustryManageActionsPermissionCodes.IndustryCategoryCreate,
                                      ]) ? (

                                        <LinkIconButton
                                        imgName="check-icon.svg"
                                        text=""
                                        imgStyle={{width: '24px', height: '24px'}}
                                        onClick={() => handleAdd(i)}
                                        />
                                      ) : null}



                                    {detectEveryActionPermission([
                                        IndustryManageActionsPermissionCodes.IndustryCategoryDelete,
                                      ]) ? (
                                        <LinkIconButton
                                        imgName="cross-icon.svg"
                                        text=""
                                        imgStyle={{width: '24px', height: '24px'}}
                                        onClick={() => {
                                            setNewList(
                                                newList.filter(a =>
                                                a.id !== n.id
                                            )
                                            )}}
                                        />
                                      ) : null}


                                        </div>
                                    </td>
                                </tr>
                                );
                            })}
                    </tbody>
                    </table>
                </div>
            </div>
            <div className="pad-10 page" style={{border: '1px solid #dbe8ef'}}>
                        <TablePagination
                            page={request.page}
                            pageSize={request.pageSize}
                            onChange={(e) => {

                                let tmpreq = {...request};
                                tmpreq.pageSize = e.pageSize;
                                tmpreq.page = e.page;
                                setRequest(tmpreq);
                            }}
                            total={count || 0}
                        />
            </div>
            {/* 確認刪除modal */}
            <ConfirmModal {...confirmModal}>
            <div>是否刪除?</div>
            </ConfirmModal>
        </div>
    );
};


export default Industry;
