import { TWSE_URL } from "constants/baseConstants";
import { CommonService } from "services/common/commonService";

/** 導向回證交所官方(暫無使用) */
const redirectTWSEOffice = () => {
    const url = window.location.host;
    const preUrl = `${window.location.protocol}//${url}`;
    if (preUrl.includes('localhost') ||
        preUrl.includes('twse.chase.com.tw')) {
        // 暫時先導回登入口
        window.location.replace(`${preUrl}/entrance`);
    } else {
        window.location.replace(TWSE_URL);
    }
}

/** 導向回入口 */
const redirectEntrance = () => {
    const url = window.location.host;
    const preUrl = `${window.location.protocol}//${url}`;
    if (CommonService.isDevMode()) {
        // 暫時先導回登入口
        window.location.replace(`${preUrl}/entrance`);
    } else {
        window.location.replace(`${preUrl}/empty`);
    }
}

/** 開始外部連結 */
const openExternalUrl = (url: string) => {
    window.open(url);
}

export {
    redirectTWSEOffice,
    openExternalUrl,
    redirectEntrance
}