//* ---------------------------- React start ---------------------------- *//
import {
  FC,
  useEffect,
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
  memo,
  ReactNode,
} from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import {
  useMutation,
  UseMutationResult,
  UseMutateFunction,
} from "@tanstack/react-query";
import { v4 as uuid } from "uuid";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./List.module.scss";
import SearchInput from "components/base/SearchInput/SearchInput";
import IconButton from "components/button/IconButton/IconButton";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import {
  RoleListRequestModel,
  RoleModel,
  RoleSaveModel,
  PermissionModel,
} from "models/admin/rolePermissionModel";
import RolePermissionService from "services/admin/rolePermissionService";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";
import NormalInput from "components/base/NormalInput/NormalInput";
import MultiSelectInput from "components/base/MultiSelectInput/MultiSelectInput";
import {
  MultiOptionModel,
  BaseResponse,
  MarketTypeEnum,
} from "models/baseModel";
import BasicAccordion from "components/base/BasicAccordion/BasicAccordion";
import { CommonService } from "services/common/commonService";
import { success2Alert, error2Alert } from "utils/otherToast";
import useUserInfoStore from "state/useUserInfoStore";
import {
  AccountType,
  AuthInfoModel,
  AuthPermissionModel,
} from "models/auth/authModel";
import TablePagination from "components/base/TablePagination/TablePagination";
import { PaginationModel } from "models/baseModel";
import { usePermissionContext } from "context/PermissionProvider";
import { RoleManageActionsPermissionCodes } from "models/auth/permissionModel";
//* ---------------------------- local end ---------------------------- *//

//* ---------------------------- ListContext start ---------------------------- *//
interface ListContextProps {
  listBoxLoading: boolean;
  setListBoxLoading: Dispatch<SetStateAction<boolean>>;
  tableComponentLoading: boolean;
  setTableComponentLoading: Dispatch<SetStateAction<boolean>>;
  roleListRequestModel: RoleListRequestModel;
  setRoleListRequestModel: Dispatch<SetStateAction<RoleListRequestModel>>;
  roleList: {
    count: number;
    list: RoleModel[];
  };
  setRoleList: Dispatch<
    SetStateAction<{
      count: number;
      list: RoleModel[];
    }>
  >;
  batchRoleIdList: string[];
  setBatchRoleIdList: Dispatch<SetStateAction<string[]>>;
  getRoleListMutate: UseMutateFunction<
    BaseResponse<any>,
    Error,
    RoleListRequestModel,
    unknown
  >;
  setGetRoleListMutate: Dispatch<
    SetStateAction<
      UseMutateFunction<BaseResponse<any>, Error, RoleListRequestModel, unknown>
    >
  >;
  getPermissionTree: UseMutationResult<BaseResponse<any>, Error, void, unknown>;
  setGetPermissionTree: Dispatch<
    SetStateAction<UseMutationResult<BaseResponse<any>, Error, void, unknown>>
  >;
  openAddRoleModal: () => void;
  setOpenAddRoleModal: Dispatch<SetStateAction<() => void>>;
  openEditRoleModal: (id: string) => void;
  setOpenEditRoleModal: Dispatch<SetStateAction<(id: string) => void>>;
  tablePagination: PaginationModel;
  setTablePagination: Dispatch<SetStateAction<PaginationModel>>;
}

const ListContext = createContext<ListContextProps>({
  listBoxLoading: false,
  setListBoxLoading: () => {},
  tableComponentLoading: false,
  setTableComponentLoading: () => {},
  roleListRequestModel: {
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortKey: null,
    sortType: true,
    isActive: true,
  },
  setRoleListRequestModel: () => {},
  roleList: {
    count: 0,
    list: [],
  },
  setRoleList: () => {},
  batchRoleIdList: [],
  setBatchRoleIdList: () => {},
  getRoleListMutate: {} as UseMutateFunction<
    BaseResponse<any>,
    Error,
    RoleListRequestModel,
    unknown
  >,
  setGetRoleListMutate: () => {},
  getPermissionTree: {} as UseMutationResult<
    BaseResponse<any>,
    Error,
    void,
    unknown
  >,
  setGetPermissionTree: () => {},
  openAddRoleModal: () => {},
  setOpenAddRoleModal: () => {},
  openEditRoleModal: () => {},
  setOpenEditRoleModal: () => {},
  tablePagination: {
    page: 1,
    pageSize: 10,
    total: 0,
  },
  setTablePagination: () => {},
});

const ListContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [listBoxLoading, setListBoxLoading] = useState(true);
  const [tableComponentLoading, setTableComponentLoading] = useState(false);
  const [roleListRequestModel, setRoleListRequestModel] =
    useState<RoleListRequestModel>({
      page: 1,
      pageSize: 10,
      searchKey: null,
      sortKey: null,
      sortType: true,
      isActive: true,
    });
  const [roleList, setRoleList] = useState<{
    count: number;
    list: RoleModel[];
  }>({
    count: 0,
    list: [],
  });
  const [batchRoleIdList, setBatchRoleIdList] = useState<string[]>([]);
  const [getRoleListMutate, setGetRoleListMutate] = useState<
    UseMutateFunction<BaseResponse<any>, Error, RoleListRequestModel, unknown>
  >(
    {} as UseMutateFunction<
      BaseResponse<any>,
      Error,
      RoleListRequestModel,
      unknown
    >
  );
  const [getPermissionTree, setGetPermissionTree] = useState<
    UseMutationResult<BaseResponse<any>, Error, void, unknown>
  >({} as UseMutationResult<BaseResponse<any>, Error, void, unknown>);
  const useUserInfoStoreState = useUserInfoStore((state) => state.userInfo);
  const [openAddRoleModal, setOpenAddRoleModal] = useState<() => void>(
    () => {}
  );
  const [openEditRoleModal, setOpenEditRoleModal] = useState<
    (id: string) => void
  >(() => {});
  const [tablePagination, setTablePagination] = useState<PaginationModel>({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const contextValue: ListContextProps = {
    listBoxLoading,
    setListBoxLoading,
    tableComponentLoading,
    setTableComponentLoading,
    roleListRequestModel,
    setRoleListRequestModel,
    roleList,
    setRoleList,
    batchRoleIdList,
    setBatchRoleIdList,
    getRoleListMutate,
    setGetRoleListMutate,
    getPermissionTree,
    setGetPermissionTree,
    openAddRoleModal,
    setOpenAddRoleModal,
    openEditRoleModal,
    setOpenEditRoleModal,
    tablePagination,
    setTablePagination,
  };

  // -useEffect roleList
  useEffect(() => {
    setTablePagination((prev) => {
      if (roleList?.list.length) {
        return {
          ...prev,
          page: roleListRequestModel.page ?? 1,
          pageSize: roleListRequestModel.pageSize ?? 10,
          total: roleList.count,
        };
      } else {
        return {
          ...prev,
          page: 0,
          total: 0,
        };
      }
    });
  }, [roleList, roleListRequestModel]);

  return (
    <ListContext.Provider value={contextValue}>{children}</ListContext.Provider>
  );
};

const ListContextValue = (): ListContextProps => {
  return useContext(ListContext);
};

//* ---------------------------- ListContext end ---------------------------- *//

//* ---------------------------- OperationHeaderComponent start ---------------------------- *//

const OperationHeaderComponent: FC = memo(() => {
  //> ---------------------------- component state start ---------------------------- <//
  const listContextValue = ListContextValue();
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;
  //> ---------------------------- component state end ---------------------------- <//
  //> ---------------------------- component react query start ---------------------------- <//
  //- copyRolePermission
  const copyRolePermission = useMutation({
    mutationFn: (id: string) => RolePermissionService.copyRolePermission(id),
    onSuccess: (res) => {
      console.log("copyRolePermission", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        listContextValue.getRoleListMutate(
          listContextValue.roleListRequestModel,
          {
            onSuccess: () => listContextValue.setBatchRoleIdList([]),
          }
        );
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("copyRolePermission", err);
      error2Alert("執行失敗");
    },
  });

  //- deleteRoles
  const deleteRoles = useMutation({
    mutationFn: (idList: string[]) => RolePermissionService.deleteRoles(idList),
    onSuccess: (res) => {
      console.log("deleteRoles", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        listContextValue.getRoleListMutate(
          listContextValue.roleListRequestModel,
          {
            onSuccess: () => listContextValue.setBatchRoleIdList([]),
          }
        );
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("deleteRoles", err);
      error2Alert("執行失敗");
    },
  });
  //> ---------------------------- component react query end ---------------------------- <//

  //> ---------------------------- component useEffect start ---------------------------- <//
  //- copyRolePermission.isPending, deleteRoles.isPending
  useEffect(() => {
    listContextValue.setListBoxLoading(
      copyRolePermission.isPending || deleteRoles.isPending
    );
  }, [listContextValue, copyRolePermission.isPending, deleteRoles.isPending]);
  //> ---------------------------- component useEffect end ---------------------------- <//

  return (
    <div className={`${styles["role-permission-list-operation-header"]}`}>
      <div className={`left`}>
        <div className={`item`}>
          <SearchInput
            placeholder="搜尋"
            onClick={(e) => {
              listContextValue.setRoleListRequestModel((prev) => ({
                ...prev,
                searchKey: e,
              }));
            }}
          />
        </div>
      </div>
      <div className={`right`}>
        {listContextValue.batchRoleIdList.length ? (
          <>
            {listContextValue.batchRoleIdList.length === 1 &&
            detectEveryActionPermission([
              RoleManageActionsPermissionCodes.RoleManageUpdate,
            ]) ? (
              <div className={`item`}>
                <button
                  className="secondary"
                  onClick={() => {
                    copyRolePermission.mutate(
                      listContextValue.batchRoleIdList[0]
                    );
                  }}
                >
                  複製
                </button>
              </div>
            ) : null}
            {detectEveryActionPermission([
              RoleManageActionsPermissionCodes.RoleManageDelete,
            ]) ? (
              <div className={`item`}>
                <IconButton
                  imgName="trash-icon.svg"
                  text="刪除"
                  className="secondary"
                  onClick={() => {
                    deleteRoles.mutate(listContextValue.batchRoleIdList);
                  }}
                />
              </div>
            ) : null}
          </>
        ) : (
          <>
            {detectEveryActionPermission([
              RoleManageActionsPermissionCodes.RoleManageCreate,
            ]) ? (
              <div className={`item`}>
                <IconButton
                  imgName="add-icon.svg"
                  text="新增"
                  className="secondary"
                  onClick={() => {
                    listContextValue.openAddRoleModal();
                  }}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
});

//* ---------------------------- OperationHeaderComponent end ---------------------------- *//

//* ---------------------------- TableComponent start ---------------------------- *//

const TableComponent: FC = memo(() => {
  // console.log("TableComponent");
  //> ---------------------------- component state start ---------------------------- <//
  const listContextValue = ListContextValue();
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;
  //> ---------------------------- component state end ---------------------------- <//
  //> ---------------------------- component react query start ---------------------------- <//
  //- getRoleList
  const { mutate: getRoleListMutate, isPending: getRoleListIsPending } =
    useMutation({
      mutationFn: (request: RoleListRequestModel) =>
        RolePermissionService.getRoleList(request),
      onSuccess: (res) => {
        console.log("getRoleList", res);
        if (res.code === 200 && res.success) {
          listContextValue.setRoleList({
            count: res.count!,
            list: res.data,
          });
        } else {
          listContextValue.setRoleList({
            count: 0,
            list: [],
          });
        }
      },
      onError: (err) => {
        console.log("getRoleList", err);
      },
    });
  //> ---------------------------- component react query end ---------------------------- <//
  //> ---------------------------- component useEffect start ---------------------------- <//
  //- init
  useEffect(() => {
    // getRoleListMutate(listContextValue.roleListRequestModel);
    listContextValue.setGetRoleListMutate(() => getRoleListMutate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //- getRoleListIsPending
  useEffect(() => {
    listContextValue.setTableComponentLoading(getRoleListIsPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRoleListIsPending]);

  //- useEffect roleListRequestModel
  useEffect(() => {
    console.log("roleListRequestModel", listContextValue.roleListRequestModel);
    getRoleListMutate(listContextValue.roleListRequestModel);
  }, [listContextValue.roleListRequestModel, getRoleListMutate]);

  //> ---------------------------- component useEffect end ---------------------------- <//

  return (
    <div
      className={`${styles["role-permission-table-box"]} role-permission-table-scroll scroll`}
    >
      {listContextValue.tableComponentLoading ? <VisuallLoading /> : null}

      <ScrollToTopButton
        targetClassName={`${styles["role-permission-table-box"]}`}
        bottom={90}
        right={35}
      />
      <table
        aria-label="table"
        className={`${styles["role-permission-table"]} table-container sticky-table`}
      >
        <thead>
          <tr>
            {listContextValue.roleList?.list?.length === 0 ||
            !detectEveryActionPermission([
              RoleManageActionsPermissionCodes.RoleManageUpdate,
            ]) ? null : (
              <th scope="col" className={`${styles["setting-checkbox"]}`}>
                <label
                  className={`custom-checkbox hideText`}
                  onClick={(event) => event?.stopPropagation()}
                >
                  <input
                    type="checkbox"
                    checked={
                      listContextValue.roleList?.list?.length ===
                      listContextValue.batchRoleIdList.length
                    }
                    onChange={(event) => {
                      event?.stopPropagation();
                      if (
                        listContextValue.roleList?.list?.length ===
                        listContextValue.batchRoleIdList.length
                      ) {
                        listContextValue.setBatchRoleIdList([]);
                      } else {
                        const a = [
                          ...listContextValue.batchRoleIdList,
                          ...listContextValue.roleList!.list?.map((e) => e.id!),
                        ];
                        listContextValue.setBatchRoleIdList(
                          a.filter((e, i, arr) => arr.indexOf(e) === i)
                        );
                      }
                    }}
                  />
                  <span
                    className={`${
                      listContextValue.batchRoleIdList.length > 0 &&
                      listContextValue.roleList?.list?.length! >
                        listContextValue.batchRoleIdList.length
                        ? "partial"
                        : ""
                    }`}
                  ></span>
                </label>
              </th>
            )}
            <th scope="col">角色</th>
            <th scope="col">功能權限</th>
            <th scope="col">帳號</th>
          </tr>
        </thead>
        <tbody>
          {listContextValue.roleList?.list?.length === 0 ? (
            <tr>
              <td
                colSpan={
                  detectEveryActionPermission([
                    RoleManageActionsPermissionCodes.RoleManageUpdate,
                  ])
                    ? 4
                    : 3
                }
              >
                查無資料
              </td>
            </tr>
          ) : (
            listContextValue.roleList?.list?.map((role, roleIndex) => (
              <tr
                key={roleIndex}
                onClick={() =>
                  detectEveryActionPermission([
                    RoleManageActionsPermissionCodes.RoleManageUpdate,
                  ])
                    ? listContextValue.openEditRoleModal(role.id)
                    : null
                }
              >
                {detectEveryActionPermission([
                  RoleManageActionsPermissionCodes.RoleManageUpdate,
                ]) ? (
                  <td className={`${styles["setting-checkbox"]}`}>
                    <label
                      className={`custom-checkbox hideText`}
                      onClick={(event) => event?.stopPropagation()}
                    >
                      <input
                        type="checkbox"
                        checked={listContextValue.batchRoleIdList.includes(
                          role.id!
                        )}
                        onChange={(event) => {
                          event?.stopPropagation();
                          if (
                            listContextValue.batchRoleIdList.includes(role.id!)
                          ) {
                            listContextValue.setBatchRoleIdList((prev) =>
                              prev.filter((e) => e !== role.id!)
                            );
                          } else {
                            listContextValue.setBatchRoleIdList((prev) => [
                              ...prev,
                              role.id!,
                            ]);
                          }
                        }}
                      />
                      <span></span>
                    </label>
                  </td>
                ) : null}
                <td>{role.name}</td>
                <td>
                  {/* 只留 type 0 */}
                  {role.permission
                    ?.filter((e) => e.type === 0)
                    ?.map((permission, permissionIndex) => (
                      <span key={permissionIndex}>
                        {permission.name}
                        {permissionIndex !==
                        role.permission.filter((e) => e.type === 0).length - 1
                          ? "、"
                          : null}
                      </span>
                    ))}
                </td>
                <td>{role.userIds?.join("、") || "-"}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
});

//* ---------------------------- TableComponent end ---------------------------- *//

//* ---------------------------- EditRoleModalComponent start ---------------------------- *//

const EditRoleModalComponent: FC = memo((props, ref) => {
  //> ---------------------------- component state start ---------------------------- <//
  const listContextValue = ListContextValue();
  const [modal, setModal] = useState<ContentModalProps>({
    show: false,
    size: "xl",
    handleClose: () => {
      // if (getPermissionTree.isPending) return;
      setModal((prev) => ({ ...prev, show: false }));
      setPermissionTree([]);
      setUserList([]);
    },
  });
  const [roleSaveModel, setRoleSaveModel] = useState<RoleSaveModel>({
    id: undefined,
    name: undefined,
    isActive: undefined,
    userIds: [],
    permissionIds: [],
    accType: undefined,
  });
  const [actionType, setActionType] = useState<"add" | "edit">("add"); // add, edit
  const [permissionTree, setPermissionTree] = useState<PermissionModel[]>([]);
  const [userList, setUserList] = useState<MultiOptionModel[]>([]);
  const { userInfo } = useUserInfoStore();
  //> ---------------------------- component state end ---------------------------- <//
  //> ---------------------------- component react query start ---------------------------- <//

  //- getPermissionTree
  const getPermissionTree = useMutation({
    mutationFn: () => RolePermissionService.getPermissionTree(),
    onSuccess: (res) => {
      console.log("getPermissionTree", res);
      if (res.code === 200 && res.success) {
        // setPermissionTree(res.data);
      } else {
        setPermissionTree([]);
      }
    },
    onError: (err) => {
      console.log("getPermissionTree", err);
    },
  });

  //- getUserList
  const getUserList = useMutation({
    mutationFn: () => RolePermissionService.getUserList(),
    onSuccess: (res) => {
      console.log("getUserList", res);
      if (res.code === 200 && res.success) {
        //- text 才是 id 要傳的值
        const userList = res.data.map((e: MultiOptionModel) => {
          return { ...e, id: e.text, code: e.id };
        });
        setUserList(userList);
      } else {
        setUserList([]);
      }
    },
    onError: (err) => {
      console.log("getUserList", err);
    },
  });

  //- addRolePermission
  const addRolePermission = useMutation({
    mutationFn: (request: RoleSaveModel) =>
      RolePermissionService.addRolePermission(request),
    onSuccess: (res) => {
      console.log("addRolePermission", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        modal.handleClose();
        listContextValue.getRoleListMutate(
          listContextValue.roleListRequestModel
        );
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("addRolePermission", err);
      error2Alert("執行失敗");
    },
  });

  //- saveRolePermission
  const saveRolePermission = useMutation({
    mutationFn: (request: RoleSaveModel) =>
      RolePermissionService.saveRolePermission(request),
    onSuccess: (res) => {
      console.log("saveRolePermission", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        modal.handleClose();
        listContextValue.getRoleListMutate(
          listContextValue.roleListRequestModel
        );
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("saveRolePermission", err);
      error2Alert("執行失敗");
    },
  });

  //- getUserInfo
  const getUserInfo = useMutation({
    mutationFn: (id: string) => RolePermissionService.getUserInfo(id),
    onSuccess: (res) => {
      console.log("getUserInfo", res);
      if (res.code === 200 && res.success) {
        const roleData: RoleModel = res.data;
        setRoleSaveModel(
          initRoleSaveModel({
            id: roleData.id,
            name: roleData.name,
            isActive: roleData.isActive,
            userIds: roleData.userIds,
            permissionIds:
              roleData.permission
                ?.map((e) => e.id!)
                ?.filter((e, i, arr) => arr.indexOf(e) === i) || [],
            accType: roleData.accType,
          })
        );
      } else {
        error2Alert(res.message);
        modal.handleClose();
      }
    },
    onError: (err) => {
      console.log("getUserInfo", err);
    },
  });
  //> ---------------------------- component react query end ---------------------------- <//

  //> ---------------------------- component const func start ---------------------------- <//
  const initRoleSaveModel = (model: RoleSaveModel = {}): RoleSaveModel => {
    return {
      ...{
        id: undefined,
        name: undefined,
        isActive: true,
        userIds: [],
        permissionIds: [],
        accType: userInfo.accType,
      },
      ...model,
    };
  };

  const openAddRoleModal = () => {
    setModal((prev) => ({ ...prev, title: "新增角色權限", show: true }));
    setActionType("add");
    getPermissionTree.mutate(undefined, {
      onSuccess: (res) => {
        if (res.code === 200 && res.success) {
          const permissionTree = res.data;
          const permissionIds: string[] = [];
          //- 全部勾選並且,勾到的id都要放進去permissionIds 並且setRoleSaveModel
          const updatePermissionIds = (item: PermissionModel) => {
            if (item.checked) {
              permissionIds.push(item.id!);
            }
          };
          const updatePermissionTree = (item: PermissionModel) => {
            item.checked = true;
            updatePermissionIds(item);
            if (item.children?.length) {
              item.children.forEach((child) => {
                updatePermissionTree(child);
              });
            }
          };
          permissionTree.forEach((item: PermissionModel) => {
            updatePermissionTree(item);
          });
          setPermissionTree(permissionTree);
          setRoleSaveModel({
            ...initRoleSaveModel({ id: uuid() }),
            permissionIds: permissionIds,
          });
        }
      },
    });
    getUserList.mutate();
  };

  const openEditRoleModal = (id: string) => {
    setModal((prev) => ({ ...prev, title: "編輯角色權限", show: true }));
    setActionType("edit");
    getUserList.mutate();
    Promise.all([
      getPermissionTree.mutateAsync(),
      getUserInfo.mutateAsync(id),
    ]).then((res) => {
      if (
        res[0].code === 200 &&
        res[0].success &&
        res[1].code === 200 &&
        res[1].success
      ) {
        const permissionTree = res[0].data;
        const roleData: RoleModel = res[1].data;
        const permissionIds =
          roleData.permission
            ?.map((e) => e.id!)
            ?.filter((e, i, arr) => arr.indexOf(e) === i) || [];
        if (permissionIds.length) {
          const updatePermissionTree = (item: PermissionModel) => {
            if (permissionIds.includes(item.id!)) {
              item.checked = true;
            }
            if (item.children?.length) {
              item.children.forEach((child) => {
                updatePermissionTree(child);
              });
            }
          };
          permissionTree.forEach((item: PermissionModel) => {
            updatePermissionTree(item);
          });
          setPermissionTree(permissionTree);
        }
      }
    });
  };

  const addAccountVerify = (verifyString: string): boolean => {
    const regex = /^[SO]\d{6}$/;
    if (!regex.test(verifyString)) {
      error2Alert("帳號格式錯誤");
      return false;
    }
    const { accType } = userInfo;
    if (
      (accType === AccountType.Twse && verifyString[0] === "O") ||
      (accType === AccountType.Tpex && verifyString[0] === "S")
    ) {
      error2Alert("帳號格式錯誤");
      return false;
    }

    return true;
  };
  //> ---------------------------- component const func end ---------------------------- <//

  //- init
  useEffect(() => {
    listContextValue.setGetPermissionTree(getPermissionTree);
    listContextValue.setOpenAddRoleModal(() => openAddRoleModal);
    listContextValue.setOpenEditRoleModal(() => openEditRoleModal);
    //! test
    // openAddRoleModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //- modal loading
  useEffect(() => {
    setModal((prev) => ({
      ...prev,
      loading:
        getPermissionTree.isPending ||
        getUserList.isPending ||
        addRolePermission.isPending ||
        saveRolePermission.isPending ||
        getUserInfo.isPending,
    }));
  }, [
    getPermissionTree.isPending,
    getUserList.isPending,
    addRolePermission.isPending,
    saveRolePermission.isPending,
    getUserInfo.isPending,
  ]);

  useEffect(() => {
    console.log("userList", userList);
  }, [userList]);

  useEffect(() => {
    console.log("roleSaveModel", roleSaveModel);
  }, [roleSaveModel]);

  useEffect(() => {
    console.log("permissionTree", permissionTree);
  }, [permissionTree]);

  const memoizedMultiSelect = useMemo(
    () => (
      <MultiSelectInput
        placeholder={"請選擇/輸入帳號"}
        isFloatTitle={false}
        options={userList}
        defaultIds={roleSaveModel.userIds}
        customCreatableVerify={addAccountVerify}
        onChange={(e: string[]) => {
          setRoleSaveModel((prev) => ({ ...prev, userIds: e }));
        }}
      />
    ),
    [userList, roleSaveModel.userIds]
  );

  const PermissionTreeComponent: FC = () => {
    if (!permissionTree?.length) return null;

    const handleCheckboxChange = (
      permissionTreeItem: PermissionModel,
      tagName: string
    ) => {
      const updatePermissionIds = (item: PermissionModel) => {
        if (item.checked) {
          if (!roleSaveModel.permissionIds?.includes(item.id!)) {
            roleSaveModel.permissionIds?.push(item.id!);
          }
        } else {
          roleSaveModel.permissionIds = roleSaveModel.permissionIds?.filter(
            (e) => e !== item.id
          );
        }
      };

      const handleCheckboxChangeRecursive = (item: PermissionModel) => {
        item.children?.forEach((child) => {
          child.checked = item.checked;
          updatePermissionIds(child);
          if (child.children?.length) {
            handleCheckboxChangeRecursive(child);
          }
        });
      };

      permissionTreeItem.checked = !permissionTreeItem.checked;
      if (permissionTreeItem.children?.length) {
        if (permissionTreeItem.checked) {
          if (roleSaveModel.permissionIds?.includes(permissionTreeItem.id!)) {
            return;
          }
          roleSaveModel.permissionIds?.push(permissionTreeItem.id!);
        } else {
          roleSaveModel.permissionIds = roleSaveModel.permissionIds?.filter(
            (e) => e !== permissionTreeItem.id
          );
        }
        handleCheckboxChangeRecursive(permissionTreeItem);
      } else {
        updatePermissionIds(permissionTreeItem);
      }

      console.log("tagName", tagName);
      const tagArr = tagName
        .split("-")
        .slice(1, -1)
        .map((e) => +e);
      if (tagArr.length) {
        let base = permissionTree[tagArr[0]];
        base.checked = true;
        updatePermissionIds(base);
        for (let i = 1; i < tagArr.length; i++) {
          const index = tagArr[i];
          base = base.children![index];
        }
        if (!base.checked) {
          base.checked = true;
          updatePermissionIds(base);
        }
      }

      setRoleSaveModel((prev) => ({ ...prev, ...roleSaveModel }));
      setPermissionTree(permissionTree);
    };

    const detectChildHaveCheck = (permissionTreeItem: PermissionModel) => {
      //- 有兩種判斷 一種是子項目全選 一種是子項目部分選, 只要有子項目有選 父層就要加入 roleSaveModel.permissionIds, 子項目部分選是 partial, 全選是 all
      let result = "";
      let allChecked = true;
      const detectChildHaveCheckRecursive = (
        permissionTreeItem: PermissionModel
      ) => {
        if (permissionTreeItem.checked) {
          result = "partial";
        } else {
          allChecked = false;
        }
        permissionTreeItem.children?.forEach((child) => {
          detectChildHaveCheckRecursive(child);
        });
      };
      detectChildHaveCheckRecursive(permissionTreeItem);
      if (allChecked) result = "checked";
      return result;
    };

    const permissionTreeComponent = (
      permissionTree: PermissionModel[]
    ): JSX.Element => {
      let baseLevel = 0;
      let baseTagClassName = "tag";
      const renderPermissionTree = (
        permissionTreeItem: PermissionModel,
        level: number,
        itemIndex: number
      ): JSX.Element => {
        if (level === 0) {
          baseLevel = 0;
          baseTagClassName = `tag-${itemIndex}`;
        } else if (baseLevel !== 0 && baseLevel === level) {
          baseTagClassName = baseTagClassName.split("-").slice(0, -1).join("-");
          baseTagClassName += `-${itemIndex}`;
        } else if (baseLevel < level) {
          baseLevel = level;
          baseTagClassName += `-${itemIndex}`;
        } else if (baseLevel > level) {
          baseLevel = level;
          baseTagClassName = baseTagClassName
            .split("-")
            .slice(0, level + 1)
            .join("-");
          baseTagClassName += `-${itemIndex}`;
        }
        const childrenLength = permissionTreeItem.children?.length || 0;
        const deepCopyBaseTagClassName =
          CommonService.deepClone(baseTagClassName);
        // console.log(
        //   "deepCopyBaseTagClassName",
        //   deepCopyBaseTagClassName,
        //   level,
        //   permissionTreeItem.name
        // );
        if (!childrenLength) {
          return (
            <div
              key={deepCopyBaseTagClassName}
              className={`${styles["tree-item"]} ${deepCopyBaseTagClassName} ${styles["noChild"]}`}
            >
              <div className={`${styles["setting-checkbox"]}`}>
                <label className={`custom-checkbox`}>
                  <input
                    type="checkbox"
                    checked={permissionTreeItem.checked}
                    onChange={() => {
                      handleCheckboxChange(
                        permissionTreeItem,
                        deepCopyBaseTagClassName
                      );
                    }}
                  />
                  <span>{permissionTreeItem.name}</span>
                </label>
              </div>
            </div>
          );
        }
        return (
          <div
            key={deepCopyBaseTagClassName}
            className={`${styles["tree-item"]}`}
          >
            <BasicAccordion
              header={
                <div className={`${styles["setting-checkbox"]}`}>
                  <label className={`custom-checkbox`}>
                    <input
                      className={`custom`}
                      type="checkbox"
                      // checked={permissionTreeItem.checked}
                      onChange={() => {
                        handleCheckboxChange(
                          permissionTreeItem,
                          deepCopyBaseTagClassName
                        );
                      }}
                    />
                    <span
                      className={`${detectChildHaveCheck(permissionTreeItem)}`}
                    >
                      {permissionTreeItem.name}
                    </span>
                  </label>
                </div>
              }
              children={
                <div className={`${styles["indentation"]}`}>
                  {permissionTreeItem.children.map((child, index) =>
                    renderPermissionTree(child, level + 1, index)
                  )}
                </div>
              }
              defaultOpen={true}
              customHeaderClassName={deepCopyBaseTagClassName}
            />
          </div>
        );
      };
      return (
        <div className={`${styles["permission-tree"]}`}>
          {permissionTree.map((permissionTreeItem, permissionTreeIndex) =>
            renderPermissionTree(permissionTreeItem, 0, permissionTreeIndex)
          )}
        </div>
      );
    };

    return permissionTreeComponent(permissionTree);
  };

  return (
    <ContentModal {...modal}>
      <div className={`${styles["edit-role-modal-content"]}`}>
        <div className={`${styles["content-box"]}`}>
          <div className={`${styles["left"]}`}>
            <div className={`${styles["item"]}`}>
              <div className={`${styles["title"]}`}>角色名稱</div>
              <div className={`${styles["operate"]}`}>
                <NormalInput
                  isFloatTitle={false}
                  placeholder={"請輸入角色名稱"}
                  defaultValue={roleSaveModel.name}
                  onChange={(e) => {
                    setRoleSaveModel((prev) => ({ ...prev, name: e }));
                  }}
                />
              </div>
            </div>
            <div className={`${styles["item"]}`}>
              <div className={`${styles["title"]}`}>帳號</div>
              <div className={`${styles["operate"]}`}>
                {memoizedMultiSelect}
              </div>
            </div>
          </div>
          <div className={`${styles["right"]}`}>
            <PermissionTreeComponent />
          </div>
        </div>
        <div className={`${styles["footer-box"]}`}>
          <button
            className={`${styles["save-btn"]} secondary`}
            onClick={() => {
              if (actionType === "add") {
                addRolePermission.mutate(roleSaveModel);
              } else {
                saveRolePermission.mutate(roleSaveModel);
              }
            }}
          >
            儲存
          </button>
        </div>
      </div>
    </ContentModal>
  );
});

//* ---------------------------- EditRoleModalComponent end ---------------------------- *//

//* ---------------------------- ListLayout start ---------------------------- *//

const ListLayout: FC<{ children: ReactNode }> = memo(({ children }) => {
  const listContextValue = ListContextValue();
  return (
    <div className={`${styles["role-permission-list-box"]}`}>
      {listContextValue.listBoxLoading ? <VisuallLoading /> : null}
      {children}
    </div>
  );
});

//* ---------------------------- ListLayout end ---------------------------- *//

//* ---------------------------- TablePaginationComponent start ---------------------------- *//

const TablePaginationComponent: FC = memo(() => {
  //> ---------------------------- component state start ---------------------------- <//
  const listContextValue = ListContextValue();
  //> ---------------------------- component state end ---------------------------- <//
  //> ---------------------------- component react query start ---------------------------- <//

  //> ---------------------------- component react query end ---------------------------- <//

  //> ---------------------------- component useEffect start ---------------------------- <//
  //> ---------------------------- component useEffect end ---------------------------- <//

  return (
    <div className={`${styles["table-pagination-box"]}`}>
      <TablePagination
        total={listContextValue.tablePagination.total!}
        onChange={(e) => {
          listContextValue.setRoleListRequestModel((prev) => ({
            ...prev,
            page: e.page,
            pageSize: e.pageSize,
          }));
        }}
        page={listContextValue.tablePagination.page}
        pageSize={listContextValue.tablePagination.pageSize}
      />
    </div>
  );
});

//* ---------------------------- ListLayout end ---------------------------- *//

//* ---------------------------- List start ---------------------------- *//

const List: FC = () => {
  return (
    <ListContextProvider>
      <ListLayout>
        <OperationHeaderComponent />
        <TableComponent />
        <TablePaginationComponent />
        <EditRoleModalComponent />
      </ListLayout>
    </ListContextProvider>
  );
};

//* ---------------------------- List end ---------------------------- *//

export default List;
