import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./GhgReductionInfo.module.scss";
import { PageActionTyep, StreamFileType } from "models/baseModel";
import { useMutation } from "@tanstack/react-query";
import GhgDeclareService from "services/application/ghgReductionDeclare/ghgDeclareService";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import BackTitle from "components/base/BackTitle/BackTitle";
import { useNavigate, useParams } from "react-router-dom";
import {
  GhgDeclareDataModel,
  GhgDeclareShowDataModel,
  GhgSectionQuestionModel,
} from "models/application/ghgReductionDeclare/ghgDeclareModel";
import {
  DeclareDataAction,
  DeclareStatus,
} from "models/application/indicators/indicatorModel";
import NormalTextarea from "components/base/NormalTextarea/NormalTextarea";
import UpdateApplyModal, {
  UpdateApplyModalProps,
} from "components/otherModule/UpdateApplyModal/UpdateApplyModal";
import {
  CorrectionRequestModel,
  RequestType,
} from "models/admin/declareDataMangeModel";
import { error2Alert, success2Alert, successAlert } from "utils/otherToast";
import { title } from "process";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import { CommonService } from "services/common/commonService";
import { ResponseCode } from "models/responseCodeModel";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";

interface GhgReductionInfoProps {}

const GhgReductionInfo: FC<GhgReductionInfoProps> = () => {
  const params = useParams();
  const navigate = useNavigate();
  // 編輯狀態
  const [action, setAction] = useState(PageActionTyep.View);
  const orgInfoData = useRef<GhgDeclareShowDataModel>({
    year: 0,
    canEdit: true,
    status: DeclareStatus.UnDeclared,
    regulationsRisk: null,
    substanceRisk: null,
    chance: null,
    ghgEmissionInfo: null,
    strategy: null,
    target: null,
    budgetAndPlan: null,
    reductionEffect: null,
    isFinished: false,
  });
  const [infoData, setInfoData] = useState<any>(orgInfoData.current);
  // 是否可以按申報按鈕
  const [applyBtnDisabled, setApplyBtnDisabled] = useState(true);
  // 提醒訊息
  const [noticeMsg, setNoticeMsg] = useState<string>("");
  // modal 物件(confirm)
  const [applyModal, setApplyModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setApplyModal({
        ...applyModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "確認申報",
  });
  const [updateModal, setUpdateModal] = useState<UpdateApplyModalProps>({
    show: false,
    handleClose: () => {
      setUpdateModal({
        ...updateModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    requestType: RequestType.GhgEmissionAndReduction,
  });
  const [dataConfirmModal, setDataConfirmModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setDataConfirmModal({
        ...dataConfirmModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "資料確認",
  });

  // 取得編輯資訊
  const getGhgDeclareData = useMutation({
    mutationFn: () => GhgDeclareService.getGhgDeclareData(params.id!),
    onSuccess: (x) => {
      if (x.success) {
        const newData: GhgDeclareShowDataModel = x.data;
        setInfoData({ ...newData });
        orgInfoData.current = CommonService.deepClone(newData);
        checkCanApply(newData);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // 取得編輯資訊
  const editGhgDeclareData = useMutation({
    mutationFn: (param: GhgDeclareDataModel) =>
      GhgDeclareService.editGhgDeclareData(param),
    onSuccess: (x) => {
      setNoticeConfirmModal({
        ...noticeConfirmModal,
        show: false,
        loading: false,
      });
      if (x.success) {
        success2Alert(x.message);
        setAction(PageActionTyep.View);
        // 取得資料
        getGhgDeclareData.mutate();
        setApplyModal({
          ...applyModal,
          show: false,
          loading: false,
        });
      } else {
        error2Alert(x.message);
        setApplyModal({
          ...applyModal,
          loading: false,
        });
      }
    },
    onError: (err) => {
      setNoticeConfirmModal({
        ...noticeConfirmModal,
        show: false,
        loading: false,
      });
      console.log(err);
    },
  });

  useEffect(() => {
    getGhgDeclareData.mutate();
  }, []);

  //#region  處理modal更正申報
  const updateApplyMutation = useMutation({
    mutationFn: GhgDeclareService.addGhgDeclareDatacCrrectionRequest,
    onSuccess: (res) => {
      setUpdateModal({
        ...updateModal,
        loading: false,
        show: false,
      });
      if (res.success && res.code === ResponseCode.SuccessOK) {
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  const handleUpdateModal = (item: CorrectionRequestModel) => {
    setUpdateModal({
      ...updateModal,
      loading: true,
    });
    updateApplyMutation
      .mutate({
        ...item,
        declareId: params.id!,
      });
  };
  //#endregion

  //#region 必填modal 提醒
  const [noticeConfirmModal, setNoticeConfirmModal] =
    useState<ConfirmModalProps>({
      show: false,
      handleClose: () => {
        setNoticeConfirmModal({
          ...noticeConfirmModal,
          show: false,
        });
      },
      handleConfirm: () => {},
      title: "尚未填寫的必要欄位",
    });
  //#endregion

  //#region 檔案匯出
    /** 取得永續報告書匯出excel */
    const getExportExcel = useMutation({
      mutationFn: (id:string) =>
        GhgDeclareService.getExportExcel(id),
      onSuccess: (res) => {
        if (res.status !== ResponseCode.ServerErrorInternal) {
          successAlert("下載成功");
          CommonService.downloadByStream(res, StreamFileType.Xlsx);
        } else {
          error2Alert(res.data.message);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  //#endregion

  /** 回清單頁 */
  const handleBackList = () => {
    if (action === PageActionTyep.Edit) {
      setDataConfirmModal({
        ...dataConfirmModal,
        show: true,
        handleConfirm() {
          navigate("/apply/ghg-reduction");
          setDataConfirmModal({
            ...dataConfirmModal,
            show: false,
          });
        },
      });
    } else {
      navigate("/apply/ghg-reduction");
    }
  };

  // 儲存
  const save = (actinType: DeclareDataAction) => {
    const item = {
      ...infoData,
      action: actinType,
      declareId: params.id!,
      isFinished: checkCanApply(infoData),
    };

    if (item.isFinished) {
      editGhgDeclareData.mutate(item);
    } else {
      setNoticeConfirmModal({
        ...noticeConfirmModal,
        handleConfirm: () => {
          setNoticeConfirmModal({
            ...noticeConfirmModal,
            loading: true,
          });
          editGhgDeclareData.mutate(item);
        },
        show: true,
      });
    }
  };

  // 確認是否申報
  const checkCanApply = (data: any): boolean => {
    let pass = true;
    let msg = "";
    // 確認所有欄位是否填寫 (目前不卡控必填)
    // sectionData.forEach((p, sectionIndex) => {
    //   let tmpMsg = "";
    //   let count = 0;
    //   p.questions.forEach((ques, questionIndex) => {
    //     if (!data[ques.value]) {
    //       count++;
    //       pass = false;
    //       tmpMsg += `\t${ques.title}\n`;
    //     }
    //   });
    //   if (count > 0) {
    //     msg += `${p.sectionTitle}\n${tmpMsg}`;
    //   }
    // });
    setApplyBtnDisabled(!pass);
    if (!pass) {
      setNoticeMsg(msg);
    }
    return pass;
  };

  // section 問題
  const sectionData = [
    {
      sectionTitle: "一、企業對於溫室氣體排放之影響，或衝擊之程度：",
      questions: [
        {
          title: "(一) 企業受氣候變遷相關法規規範之風險",
          value: "regulationsRisk",
        },
        {
          title: "(二) 企業受氣候變遷之實質風險",
          value: "substanceRisk",
        },
        {
          title: "(三) 氣候變遷提供企業之機會",
          value: "chance",
        },
        {
          title:
            "(四) 企業(直、間接)溫室氣體排放量 (註明盤查範疇及時間)，及是否通過外部驗證",
          value: "ghgEmissionInfo",
        },
      ],
    },
    {
      sectionTitle: "二、企業對於溫室氣體管理之策略、方法、目標等：",
      questions: [
        {
          title: "(一) 企業對於因應氣候變遷或溫室氣體管理之策略",
          value: "strategy",
        },
        {
          title: "(二) 企業溫室氣體排放量減量目標",
          value: "target",
        },
        {
          title: "(三) 企業溫室氣體排放量減量之預算與計畫",
          value: "budgetAndPlan",
        },
        {
          title: "(四) 企業產品或服務帶給客戶或消費者之減碳效果",
          value: "reductionEffect",
        },
      ],
    },
  ];

  // 問題item 元件
  const QuestionItemComponent: FC<{ question: GhgSectionQuestionModel }> = (
    props
  ) => {
    const { question } = props;
    return (
      <div key={`${question.title}`} className="question-box">
        <div className="question">
          {question.title}
        </div>
        <div className="control">
          {action === PageActionTyep.View ? (
            <div className="display-val">{infoData[question.value]}</div>
          ) : (
            <NormalTextarea
              placeholder={""}
              defaultValue={infoData[question.value]}
              onChange={(e: string) => {
                infoData[question.value] = e;
              }}
            />
          )}
        </div>
      </div>
    );
  };
  return (
    <div className={styles["ghg-reduction-info-box"]}>
      {(getGhgDeclareData.isPending || editGhgDeclareData.isPending ||
        getExportExcel.isPending
      ) && (
        <VisuallLoading></VisuallLoading>
      )}

      <div className="title-box">
        <BackTitle
          url="/apply/ghg-reduction"
          title={`${infoData?.year ?? ''}年`}
          handleOtherEvent={true}
          changeItem={handleBackList}
        />
        <div className="tool-box">
          <div className="d-flex">
          <LinkIconButton
            imgName={"download-icon.svg"}
            text={"匯出"}
            className={"min-w80-p"}
            onClick={() => {
              getExportExcel.mutate(params.id!);
            }}
          />
            {(infoData?.status === DeclareStatus.Declaring ||
              infoData?.status === DeclareStatus.Correcting) && (
                <button
                  className={
                    `secondary ` + `${applyBtnDisabled ? "disabled" : ""}`
                  }
                  onClick={() => {
                    setApplyModal({
                      ...applyModal,
                      show: true,
                      handleConfirm() {},
                    });
                  }}
                >
                  確認申報
                </button>
              )}

            {/* 申報中or readOnly(過了申報時間) 才可以更正申請 */}
            {(infoData?.status === DeclareStatus.Declared ||
              infoData?.status === DeclareStatus.DeclareEnd) && (
              <button
                className="secondary"
                onClick={() => {
                  setUpdateModal({
                    ...updateModal,
                    declareStatus: infoData?.status,
                    show: true,
                  });
                }}
              >
                {infoData?.status === DeclareStatus.DeclareEnd
                  ? "申請開放"
                  : "更正申請"}
              </button>
            )}
          </div>
        </div>
      </div>
      {/* content 內容 */}

      <div className="ghg-reduction-content-box">
        <div className="content-title-box ">
          <div className="main-title">溫室氣體排放及減量資訊申報作業</div>
          <div className="content-tool">
            {infoData.canEdit &&
              (infoData?.status === DeclareStatus.UnDeclared ||
                infoData?.status === DeclareStatus.Declaring ||
                infoData?.status === DeclareStatus.Correcting) && (
                <>
                  {action === PageActionTyep.View ? (
                    <button
                      className="secondary min-w-fit"
                      onClick={() => {
                        setAction(PageActionTyep.Edit);
                      }}
                    >
                      編輯
                    </button>
                  ) : (
                    <>
                      <button
                        className="secondary mr-1-m min-w-fit"
                        onClick={() => {
                          setAction(PageActionTyep.View);
                          setInfoData(
                            CommonService.deepClone(orgInfoData.current)
                          );
                        }}
                      >
                        取消
                      </button>
                      <button
                        className="default min-w-fit"
                        onClick={() => {
                          save(DeclareDataAction.Saved);
                        }}
                      >
                        儲存
                      </button>
                    </>
                  )}
                </>
              )}
          </div>
        </div>
        <div className="content-box scroll">
          {sectionData.map((section, sectionIndex) => {
            return (
              <div key={sectionIndex} className="section-box">
                <div className="section-title">{section.sectionTitle}</div>
                {section.questions.map((question, questionIndex) => {
                  return (
                    <QuestionItemComponent
                      key={`${sectionIndex}_${questionIndex}`}
                      question={question}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>

      {/* 確認申報modal */}
      <ConfirmModal
        {...applyModal}
        handleConfirm={() => {
          save(DeclareDataAction.Declared);
        }}
      >
        <div>
          確認後不得再編輯資料，若申報完成後需修改，請至
          <span style={{ fontWeight: 700 }}>申報資料更正申請。</span>
        </div>
      </ConfirmModal>
      {/* 申報資料更正申請 */}
      <UpdateApplyModal
        {...updateModal}
        handleConfirm={(e) => {
          handleUpdateModal(e);
        }}
      />
      {/* 資料尚未儲存modal */}
      <ConfirmModal {...dataConfirmModal}>
        <div>資料尚未儲存，確定要離開嗎?</div>
      </ConfirmModal>
      {/* 以下為未填寫必填 資料 */}
      <ConfirmModal {...noticeConfirmModal}>
        <div className="notice-msg-box">{noticeMsg}</div>
      </ConfirmModal>
    </div>
  );
};

export default GhgReductionInfo;
