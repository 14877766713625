//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect, createContext } from "react";
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useNavigate, useParams, Outlet } from "react-router-dom";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./SustainQuestionnaireLayout.module.scss";
import SustainQuestionnaireService from "services/application/sustainQuestionnaire/sustainQuestionnaireService";
import {
  QuestuinnaireSampleRequestModel,
  SustainQuestionnaireSampleModel,
} from "models/application/sustainQuestionnaire/sustainQuestionnaireModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import BackTitle from "components/base/BackTitle/BackTitle";
import TabVerticalThin from "components/base/TabVerticalThin/TabVerticalThin";
import { NavBarItemModel, PageActionTyep } from "models/baseModel";
import { useEditSpecialRules } from "../Edit/EditSpecialRulesProvider";
//* ---------------------------- local end ---------------------------- *//

export const LayoutContext = createContext<{
  SustainQuestionnaireSampleModel: SustainQuestionnaireSampleModel | null;
}>({
  SustainQuestionnaireSampleModel: null,
});

const SustainQuestionnaireLayout: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  //- params
  const params = useParams();
  //- year
  const year = params.year;
  //- sampleId
  const sampleId = params.sampleId;
  //- navigate
  const navigate = useNavigate();
  //- layoutData
  const [layoutData, setLayoutData] = useState<{
    SustainQuestionnaireSampleModel: SustainQuestionnaireSampleModel | null;
  }>({
    SustainQuestionnaireSampleModel: null,
  });

  //- navItems
  const [navItems, setNavItems] = useState<NavBarItemModel[]>([]);
  //- selectedSampleId
  const [selectedSampleId, setSelectedSampleId] = useState<string>();
  //- samplesList
  const [samplesList, setSamplesList] = useState<
    SustainQuestionnaireSampleModel[]
  >([]);
  //! specificRules - SpecificRulesService
  const { SpecificRulesService } = useEditSpecialRules();
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getQuestionnaireYearSample
  const {
    mutate: getQuestionnaireYearSampleMutate,
    isPending: getQuestionnaireYearSampleIsPending,
  } = useMutation({
    mutationFn: (request: QuestuinnaireSampleRequestModel) =>
      SustainQuestionnaireService.getQuestionnaireYearSample(request),
    onSuccess: (res) => {
      console.log("getQuestionnaireYearSample", res);
      if (res.code === 200 && res.success && res.data.length) {
        setSamplesList(res.data);
        SpecificRulesService.setSamplesList(res.data);
        setNavItems(
          res.data.map((item: SustainQuestionnaireSampleModel) => {
            return {
              name: item.sampleName,
              key: item.sampleId,
              url: `/apply/sustain-questionnaire/${year}/${item.sampleId}/list`,
              disabled: false,
            };
          })
        );
      } else {
        setSamplesList([]);
        SpecificRulesService.setSamplesList([]);
        setNavItems([]);
      }
    },
    onError: (err) => {
      console.log("getQuestionnaireYearSample", err);
      setSamplesList([]);
      SpecificRulesService.setSamplesList([]);
      setNavItems([]);
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  //- changeNavItem
  const changeNavItem = (item: NavBarItemModel) => {
    console.log("item", item);
    // setSelectedSampleId(item.key);
    // setLayoutData({
    //   SustainQuestionnaireSampleModel: samplesList.find(
    //     (sample) => sample.sampleId === item.key
    //   )!,
    // });
    // navigate(item.url, { replace: true, state: location.state });
    navigate(item.url, { replace: true });
  };
  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init year
  useEffect(() => {
    console.log(year);
    if (!year) return;
    getQuestionnaireYearSampleMutate({ year: parseInt(year) });
  }, [year, getQuestionnaireYearSampleMutate]);

  //- useEffect navItems
  useEffect(() => {
    if (!navItems.length) return;
    console.log(sampleId, navItems);
    if (sampleId) {
      setSelectedSampleId(sampleId);
      setLayoutData({
        SustainQuestionnaireSampleModel: samplesList.find(
          (sample) => sample.sampleId === sampleId
        )!,
      });
      return;
    }
    const firstNavItem = navItems[0];
    setSelectedSampleId(firstNavItem.key);
    setLayoutData({
      SustainQuestionnaireSampleModel: samplesList.find(
        (sample) => sample.sampleId === firstNavItem.key
      )!,
    });
    navigate(firstNavItem.url, { replace: true });
  }, [navItems, sampleId, navigate, samplesList]);
  //* ---------------------------- hook end ---------------------------- *//

  return (
    <>
      <div className="no-nav-content-box">
        <div className={`${styles["sustain-questionnaire-box"]}`}>
          {getQuestionnaireYearSampleIsPending ? <VisuallLoading /> : null}
          <div className="title-box">
            <BackTitle
              title={`${year ? year + "年度" : null}`}
              url={`/apply/sustain-questionnaire/description`}
            />
            {/* <button className="default saveBtn" onClick={() => {}}>
              儲存
            </button> */}
          </div>

          <div className="sustain-questionnaire-content-box position-relative">
            {!navItems.length ? (
              <>
                {!getQuestionnaireYearSampleIsPending ? (
                  <div className="no-data">沒有資料</div>
                ) : null}
              </>
            ) : (
              <>
                {/* {getQuestionnaireYearSampleIsPending ? <VisuallLoading /> : null} */}
                {/* 左區塊 */}
                <div className="left-box">
                  <TabVerticalThin
                    data={navItems}
                    tabKey={selectedSampleId!}
                    handleOtherEvent={true}
                    changeItem={changeNavItem}
                  />
                </div>

                {/* 右區塊 */}
                <div className={"right-box"}>
                  <div className="sustain-questionnaire-inside-content-box scroll position-relative">
                    <ScrollToTopButton
                      targetClassName={
                        "sustain-questionnaire-inside-content-box"
                      }
                      bottom={90}
                      right={35}
                    />
                    <LayoutContext.Provider value={layoutData}>
                      <Outlet></Outlet>
                    </LayoutContext.Provider>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SustainQuestionnaireLayout;
