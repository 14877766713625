import toast from "react-hot-toast";

const successAlert = (text: string) => {
  toast.success(text);
  return <></>;
};


const success2Alert = (text: string) => {
  toast(text, {
    duration: 2000,
    position: "top-center",
    // Styling
    style: {
      backgroundColor: "#FFF",
      border: "1px solid rgba(33, 75, 98, 0.50)",
      color: "#214B62",
      fontWeight: "500",
      fontFamily: "Noto Sans",
      fontSize: "14px",
    },
    className: "",

    // Custom Icon
    icon: (
      <img alt="" src="/assets/images/buttonIcon/toast-success2-icon.svg" />
    ),

  });

  return <></>;
};

const errorAlert = (text: string) => {
  toast.error(text);
  return <></>;
};

const error2Alert = (text: string) => {
  toast(text, {
    duration: 2000,
    position: "top-center",

    style: {
      backgroundColor: "#FFF",
      border: "1px solid rgba(33, 75, 98, 0.50)",
      color: "#214B62",
      fontWeight: "500",
      fontFamily: "Noto Sans",
      fontSize: "14px",
    },
    // Custom Icon
    icon: (
      <img alt="" src="/assets/images/buttonIcon/toast-error2-icon.svg" />
    ),
  });

  return <></>;
};

const error3Alert = (text: string) => {
  toast((t) => (<span>{text} <a style={{float: 'right', cursor: 'pointer'}} onClick={() => toast.dismiss(t.id)}>關閉</a></span>), {
    duration: Infinity,
    position: "top-center",

    style: {
      backgroundColor: "#FFF",
      border: "1px solid rgba(33, 75, 98, 0.50)",
      color: "#214B62",
      fontWeight: "500",
      fontFamily: "Noto Sans",
      fontSize: "14px",
    },
    // Custom Icon
    icon: (
      <img alt="" src="/assets/images/buttonIcon/toast-error2-icon.svg" />
    ),
  });

  return <></>;
};
export { successAlert, success2Alert , errorAlert, error2Alert, error3Alert};
