import React, { FC, ReactNode } from "react";
import styles from "./ContentModal.module.scss";
import { Modal } from "react-bootstrap";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";

export interface ContentModalProps {
  show: boolean;
  handleClose: () => void;
  title?: string;
  children?: ReactNode;
  size?: "sm" | "lg" | "xl";
  loading?: boolean;
  footer?: ReactNode;
  customClass?: string;
  backdrop?: boolean | "static";
  keyboard?: boolean;
}

const ContentModal: FC<ContentModalProps> = ({ loading = false, keyboard = false, backdrop = 'static', ...props }) => {
  const { show, handleClose, title, children, size, footer, customClass } = props;
  return (
    <Modal
      className={`content-modal-box ${customClass}`}
      show={show}
      size={size}
      onHide={handleClose}
      backdrop={backdrop}
      keyboard={keyboard}
    >
      {loading && <VisuallLoading></VisuallLoading>}
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default ContentModal;
