const TOKEN_NAME = 'twse_token';
const REFRESH_TOKEN_NAME = 'twse_refresh_token';
const USER_INFO = 'twse_userInfo';
const ESG_LANG = 'twse_esg_lang';
const LANGUAGE = 'twse_lang';
const ApplyIndicatorSelItem = 'twse_applyIndicatorSelItem';
const EmptyMessageType = 'twse_empty_message_type';
const IdleTime = 'twse_timer'

const StorageName = {
    TOKEN_NAME,
    REFRESH_TOKEN_NAME,
    USER_INFO,
    ESG_LANG,
    /** 語言 */
    LANGUAGE,
    /** 申報指標選擇item */
    ApplyIndicatorSelItem,
    /** 空白頁訊息type */
    EmptyMessageType,
    /** 閒置時間 */
    IdleTime,
};

export default StorageName;