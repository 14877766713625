//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect } from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./Response.module.scss";
import IconButton from "components/button/IconButton/IconButton";
import SearchInput from "components/base/SearchInput/SearchInput";
import Dropdown from "components/base/Dropdown/Dropdown";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import TablePagination from "components/base/TablePagination/TablePagination";
import { PaginationModel, ListRequestModel } from "models/baseModel";
import { OptionModel, BaseResponse } from "models/baseModel";
import QuestionnaireService from "services/admin/questionnaireService";
import {
  QuestionnaireSettingModel,
  QuestionnaireReplyListRequestModel,
  QuestionnaireReplyModel,
  QuestionnaireStatusEnum,
} from "models/admin/questionnaireModel";
import SwitchCheckbox from "components/base/SwitchCheckbox/SwitchCheckbox";
import { success2Alert, error2Alert } from "utils/otherToast";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { usePermissionContext } from "context/PermissionProvider";
import { QuestionnaireManageActionsPermissionCodes } from "models/auth/permissionModel";
import { CommonService } from "services/common/commonService";
import { ResponseCode } from "models/responseCodeModel";
//* ---------------------------- local end ---------------------------- *//

const Response: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const navigate = useNavigate();
  const location = useLocation();
  const state_questionnaireListRequestModel: QuestionnaireReplyListRequestModel =
    location?.state?.questionnaireListRequestModel;
  const [declareYearOptions] = useState<OptionModel[]>(() => {
    const declareYearOptionsList = [];
    const today = new Date();
    for (let i = today.getFullYear(); i >= 2021; i--) {
      declareYearOptionsList.push({
        id: i.toString(),
        text: `${i.toString()} 年度`,
        enumKey: i,
      });
    }
    return [
      // {
      //   id: "undefined",
      //   text: "全部",
      //   enumKey: undefined,
      // },
      ...declareYearOptionsList,
    ];
  });

  const [questionnaireStatusOptions] = useState<OptionModel[]>(() => {
    const enumValues = Object.values(QuestionnaireStatusEnum);
    const questionnaireStatusDescriptions: Record<
      QuestionnaireStatusEnum,
      string
    > = {
      [QuestionnaireStatusEnum.Saved]: "已儲存",
      [QuestionnaireStatusEnum.Declared]: "已申報",
    };

    const questionnaireStatusOptionsList = enumValues
      .filter((e) => !isNaN(Number(e)))
      .map((key) => ({
        id: key ? true.toString() : false.toString(),
        text: questionnaireStatusDescriptions[key as QuestionnaireStatusEnum],
        enumKey: key,
      })) as OptionModel[];

    return [
      {
        id: "null",
        text: "全部",
        enumKey: null,
      },
      ...questionnaireStatusOptionsList,
    ];
  });

  const [questionnaireListRequestModel, setQuestionnaireListRequestModel] =
    useState<QuestionnaireReplyListRequestModel>(
      state_questionnaireListRequestModel || {
        page: 1,
        pageSize: 10,
        searchKey: null,
        sortKey: null,
        sortType: undefined,
        year: declareYearOptions[1].enumKey!,
        questionnaireDataId: undefined,
        isDeclared: null,
      }
    );
  const [questionnaireList, setQuestionnaireList] = useState<{
    count: number;
    list: QuestionnaireReplyModel[];
  }>({
    count: 0,
    list: [],
  });
  const [tablePagination, setTablePagination] = useState<PaginationModel>({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getQuestionnaireResponseList
  const {
    mutate: getQuestionnaireResponseListMutate,
    isPending: getQuestionnaireResponseListIsPending,
  } = useMutation({
    mutationFn: (request: QuestionnaireReplyListRequestModel) =>
      QuestionnaireService.getQuestionnaireResponseList(request),
    onSuccess: (res) => {
      console.log("getQuestionnaireResponseList", res);
      if (res.code === 200 && res.success) {
        setQuestionnaireList({
          count: res.count!,
          list: res.data,
        });
      } else {
        setQuestionnaireList({
          count: 0,
          list: [],
        });
      }
    },
    onError: (err) => {
      console.log("getQuestionnaireResponseList", err);
    },
  });
  //- exportQuestionnaireResponseExcel
  const {
    mutate: exportQuestionnaireResponseExcelMutate,
    isPending: exportQuestionnaireResponseExcelIsPending,
  } = useMutation({
    mutationFn: (year: string) =>
      QuestionnaireService.exportQuestionnaireResponseExcel(year),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        CommonService.downloadByStream(res);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log("getDeclareDataCorrectionRequestExcel", err);
      error2Alert("執行失敗");
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  const navigateToDetailPage = (item: QuestionnaireReplyModel) => {
    navigate(`${item.companyCode}/${item.questionnaireSampleId}/detail`, {
      state: {
        questionnaireResponse_SelectedItemData: item,
        questionnaireListRequestModel: questionnaireListRequestModel,
      },
    });
  };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init
  // useEffect(() => {
  //   getDashboardDataMutate(selectDeclareYear.toString());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  //- selectDeclareYear
  useEffect(() => {
    getQuestionnaireResponseListMutate(questionnaireListRequestModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireListRequestModel]);
  // -useEffect declareDataCorrectionRequestList
  useEffect(() => {
    setTablePagination((prev) => {
      if (questionnaireList?.list.length) {
        return {
          ...prev,
          page: questionnaireListRequestModel.page ?? 1,
          pageSize: questionnaireListRequestModel.pageSize ?? 10,
          total: questionnaireList.count,
        };
      } else {
        return {
          ...prev,
          page: 0,
          total: 0,
        };
      }
    });
  }, [questionnaireList, questionnaireListRequestModel]);
  //* ---------------------------- hook end ---------------------------- *//
  return (
    <div className={`${styles["questionnaire-box"]}`}>
      <div className={`${styles["operation-header"]}`}>
        <div className={`left`}>
          <div className={`select-item`}>
            <Dropdown
              placeholder={"年度"}
              defaultId={questionnaireListRequestModel.year?.toString()}
              options={declareYearOptions}
              isFloatTitle={false}
              onChange={(e) => {
                setQuestionnaireListRequestModel((prev) => ({
                  ...prev,
                  year:
                    declareYearOptions.find((o) => o.id === e)?.enumKey ??
                    undefined,
                }));
              }}
            />
          </div>
          <div className={`select-item`}>
            <span>狀態</span>
            <Dropdown
              placeholder={"請選擇"}
              defaultId={null}
              options={questionnaireStatusOptions}
              isFloatTitle={false}
              onChange={(e) => {
                setQuestionnaireListRequestModel((prev) => ({
                  ...prev,
                  isDeclared: JSON.parse(e!),
                }));
              }}
            />
          </div>
          {/*
          <div className={`select-item`}>
            <span>狀態</span>
            <Dropdown
              placeholder={"請選擇"}
              defaultId={
                declareDataRequestModel.status === undefined
                  ? "undefined"
                  : declareDataRequestModel.status?.toString()
              }
              options={correctionStatusOptions}
              isFloatTitle={false}
              onChange={(e) => {
                setDeclareDataRequestModel((prev) => ({
                  ...prev,
                  status:
                    correctionStatusOptions.find((o) => o.id === e)?.enumKey ??
                    undefined,
                }));
              }}
            />
          </div>
          */}
          <div className={`select-item`}>
            <SearchInput
              onClick={(e) => {
                setQuestionnaireListRequestModel((prev) => ({
                  ...prev,
                  searchKey: e,
                }));
              }}
              placeholder={"搜尋問卷名稱/公司名稱"}
            />
          </div>
        </div>
        <div className={`right`}>
          <div className={`select-item`}>
            <button
              className="secondary"
              onClick={() => {
                if (exportQuestionnaireResponseExcelIsPending) return;
                exportQuestionnaireResponseExcelMutate(
                  questionnaireListRequestModel.year!.toString()
                );
              }}
            >
              匯出 Excel
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${styles["questionnaire-table-box"]} questionnaire-table-scroll scroll`}
      >
        {getQuestionnaireResponseListIsPending ? <VisuallLoading /> : null}
        <ScrollToTopButton
          targetClassName={"questionnaire-table-scroll"}
          bottom={90}
          right={35}
        />
        <table
          aria-label="table"
          className={`${styles["questionnaire-table"]} table-container sticky-table`}
        >
          <thead>
            <tr>
              <th scope="col">公司代號</th>
              <th scope="col">公司名稱</th>
              <th scope="col">問卷名稱</th>
              <th scope="col">狀態</th>
              <th scope="col">建立日期</th>
              <th scope="col">最後編輯日期</th>
              <th scope="col">功能</th>
            </tr>
          </thead>
          <tbody>
            {questionnaireList.list?.length === 0 ? (
              <tr>
                <td colSpan={7}>查無資料</td>
              </tr>
            ) : (
              questionnaireList.list?.map((item, index) => (
                <tr key={index}>
                  <td>{item.companyCode}</td>
                  <td>{item.companyName}</td>
                  <td>{item.questionnaireName}</td>
                  <td>{item.isDeclared ? "已申報" : "已儲存"}</td>
                  <td>
                    {item.createTime
                      ? moment(item.createTime).format("yyyy-MM-DD")
                      : "-"}
                  </td>
                  <td>
                    {item.updateTime
                      ? moment(item.updateTime).format("yyyy-MM-DD")
                      : "-"}
                  </td>
                  <td>
                    <button
                      className="secondary operator-Btn"
                      onClick={() => navigateToDetailPage(item)}
                    >
                      查看
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className={`${styles["table-pagination-box"]}`}>
        <TablePagination
          total={tablePagination.total!}
          onChange={(e) => {
            setQuestionnaireListRequestModel((prev) => ({
              ...prev,
              page: e.page,
              pageSize: e.pageSize,
            }));
          }}
          page={tablePagination.page}
          pageSize={tablePagination.pageSize}
        />
      </div>
    </div>
  );
};

export default Response;
