//* ---------------------------- React start ---------------------------- *//
import React, { useState, useEffect } from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import BackTitle from "components/base/BackTitle/BackTitle";
import styles from "./Detail.module.scss";
import QuestionnaireService from "services/admin/questionnaireService";
import {
  QuestionnaireColValModel,
  QuestionnaireReplyListRequestModel,
} from "models/admin/questionnaireModel";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { QuestionnaireReplyModel } from "models/admin/questionnaireModel";
//* ---------------------------- local end ---------------------------- *//

const Detail = () => {
  //* ---------------------------- state start ---------------------------- *//
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const companyCode = params.companyCode;
  const questionnaireSampleId = params.questionnaireSampleId;
  const questionnaireResponse_SelectedItemData: QuestionnaireReplyModel =
    location?.state?.questionnaireResponse_SelectedItemData;
  const questionnaireListRequestModel: QuestionnaireReplyListRequestModel =
    location?.state?.questionnaireListRequestModel;
  const [questionnaireResponseData, setQuestionnaireResponseData] = useState<
    QuestionnaireColValModel[]
  >([]);
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getQuestionnaireResponseData
  const {
    mutate: getQuestionnaireResponseDataMutate,
    isPending: getQuestionnaireResponseDataIsPending,
  } = useMutation({
    mutationFn: (request: {
      companyCode: string;
      questionnaireSampleId: string;
    }) =>
      QuestionnaireService.getQuestionnaireResponseData(
        request.companyCode,
        request.questionnaireSampleId
      ),
    onSuccess: (res) => {
      console.log("getQuestionnaireResponseData", res);
      if (res.code === 200 && res.success) {
        setQuestionnaireResponseData(res.data);
      } else {
        setQuestionnaireResponseData([]);

      }
    },
    onError: (err) => {
      console.log("getQuestionnaireResponseData", err);
      setQuestionnaireResponseData([]);

    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init questionnaireId
  useEffect(() => {
    console.log(
      "questionnaireSampleId",
      questionnaireSampleId,
      "companyCode",
      companyCode
    );
    console.log(
      "questionnaireResponse_SelectedItemData",
      questionnaireResponse_SelectedItemData
    );
    if (
      !questionnaireSampleId ||
      !companyCode ||
      !questionnaireResponse_SelectedItemData
    ) {
      navigate("/admin/questionnaire/response");
      return;
    }
    getQuestionnaireResponseDataMutate({
      companyCode: companyCode,
      questionnaireSampleId: questionnaireSampleId,
    });
  }, [questionnaireSampleId, companyCode, getQuestionnaireResponseDataMutate]);
  //* ---------------------------- hook end ---------------------------- *//
  return (
    <div className={`${styles["questionnaire-response-detail"]}`}>
      {/*上方bar*/}
      <div className="title-box">
        <BackTitle
          title={`${questionnaireResponse_SelectedItemData?.companyCode}${questionnaireResponse_SelectedItemData?.companyName} - ${questionnaireResponse_SelectedItemData?.questionnaireName}`}
          url={`/admin/questionnaire/response`}
          state={{
            questionnaireListRequestModel: questionnaireListRequestModel,
          }}
        />
      </div>
      <div className="detail-content-box scroll">
        {getQuestionnaireResponseDataIsPending ? <VisuallLoading /> : null}
        <ScrollToTopButton
          targetClassName={"detail-content-box"}
          bottom={90}
          right={35}
        />
        {questionnaireResponseData.length ? (
          <>
            <div className="detail-box">
              {questionnaireResponseData[0].comment ? (
                <div className="comment">
                  {questionnaireResponseData[0].comment}
                </div>
              ) : null}
              <div className="table-box scroll">
                <table aria-label="table" className="table-container">
                  <thead>
                    <tr>
                      <th>活動序號</th>
                      {questionnaireResponseData[0].colValModel.map(
                        (col, colIndex) => {
                          return <th key={colIndex}>{col.title}</th>;
                        }
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {questionnaireResponseData.map((item, itemIndex) => {
                      return (
                        <tr key={itemIndex}>
                          <td>{itemIndex + 1}</td>
                          {item.colValModel.map((col, colIndex) => {
                            return <td key={colIndex}>{col.value || "-"}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <>
            {getQuestionnaireResponseDataIsPending ? null : (
              <div className="no-data">沒有資料</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Detail;
