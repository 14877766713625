import React, { FC } from "react";
import styles from "./ErrorBoundaryFallback.module.scss";
import ProblemReportContent from "components/otherModule/ProblemReportContent/ProblemReportContent";

interface ErrorBoundaryFallbackProps {}

const ErrorBoundaryFallback: FC<ErrorBoundaryFallbackProps> = () => {
  return (
    <div className={styles["error-boundary-fallback-box"]}>
      <div className={styles["error-content"]}>
        <ProblemReportContent/>       
        <img
          alt="boundary-error"
          src="/assets/images/buttonIcon/toast-error1-icon.svg"
        />
      </div>
    </div>
  );
};

export default ErrorBoundaryFallback;
