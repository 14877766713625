import React, {
  FC,
  useEffect,
  useState,
  useRef,
  KeyboardEventHandler,
} from "react";
import styles from "./MultiSelectInput.module.scss";
import { MultiOptionModel } from "../../../models/baseModel";
import CreatableSelect, { components } from "react-select";
import { useTranslation } from "react-i18next";

interface MultiSelectInputProps {
  onChange: (selIds: string[]) => void;
  placeholder: string | null;
  options: MultiOptionModel[];
  defaultIds?: string[];
  valid?: boolean;
  disabled?: boolean;
  isFloatTitle?: boolean;
  showSearch?: boolean;
  showAll?: boolean;
  showAllText?: string;
  hideOptions?: boolean;
  isOnlyIcon?: boolean;
  customCreatableVerify?: (verifyString: string) => boolean;
}

const MultiSelectInput: FC<MultiSelectInputProps> = ({
  valid = true,
  showSearch = true,
  showAll = true,
  hideOptions = true,
  showAllText = "全選",
  ...props
}) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  const boxRef = useRef<HTMLDivElement>(null);
  const { defaultIds } = props;
  const [hide, setHide] = useState<boolean>(hideOptions);
  const [searchKey, setSearchKey] = useState<string>("");
  const [checkAll, setCheckAll] = useState<boolean>();
  const [inputValue, setInputValue] = useState<string>("");
  const [additionOptions, setAdditionOptions] = useState<any[]>([]);
  const additionOptionsRef = useRef(additionOptions);
  const { t } = useTranslation();
  // 格式化選項以適應 react-select
  useEffect(() => {

    //#region 設置CreatableSelect 預選項目
    const formattedOptions = props.options.map((option) => ({
      value: option.id,
      label: option.text,
    }));

    // 設置初始選擇
    const initialSelected = formattedOptions.filter((option) =>
      defaultIds?.includes(option.value)
    );
    const selIds = [
      ...initialSelected,
      ...additionOptionsRef.current.map((p) => {
        return { value: p.value, label: p.label };
      }),
    ];
    // console.log("selIds", selIds);
    setSelectedOptions(selIds);
    //#endregion

    //#region 設置篩選下拉
    
    // 將預設的選單checked 都改為false
    const newOptions = props.options.slice();
    newOptions.forEach((p) => {
      p.checked = false;
      if (defaultIds && defaultIds.includes(p.id)) {
        p.checked = true;
      }
    });
    if (searchKey) {
      handleChangeKey(searchKey);
    } else {
      setFilterOptions(newOptions);
      setCheckFlow(newOptions);
    }

    //#endregion
    // console.log("newOptions", newOptions);
  }, [props.options, defaultIds]);

  useEffect(() => {
    additionOptionsRef.current = additionOptions;
    const selIds = filterOptions.filter((p) => p.checked).map((p) => p.id);
    const passSelIds = [
      ...selIds,
      ...additionOptionsRef.current.map((p) => p.value),
    ];
    props.onChange(passSelIds);
    //
  }, [additionOptions]);

  // 當選擇變化時更新
  const handleChange = (selected: any, actionMeta: any) => {
    // console.log("selected", selected);
    // console.log(actionMeta);
    if (
      actionMeta.action === "remove-value" ||
      actionMeta.action === "pop-value"
    ) {
      // 如果是因為刪除某個選項而觸發
      if (actionMeta.removedValue) {
        // 可以處理特定的邏輯，例如發送刪除請求到後端等
        // console.log("Removed value: ", actionMeta.removedValue);
        const findOption = filterOptions.find(
          (option) => option.id === actionMeta.removedValue.value
        );
        if (findOption) {
          clickItem(null, findOption);
          return;
        }
        const findAdditionOption = additionOptionsRef.current.find(
          (option) => option.value === actionMeta.removedValue.value
        );
        if (findAdditionOption) {
          setAdditionOptions((prev) =>
            prev.filter((option) => option.value !== findAdditionOption.value)
          );
        }
      }
    }
  };

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        const checkOptionsItem = filterOptions.find(
          (option) =>
            option.id.trim().toLocaleLowerCase() ===
            inputValue.trim().toLocaleLowerCase()
        );
        if (checkOptionsItem) {
          clickItem(null, checkOptionsItem);
        } else {
          // console.log(
          //   "props.customCreatableVerify",
          //   props.customCreatableVerify
          // );
          if (
            props.customCreatableVerify &&
            !props.customCreatableVerify(inputValue)
          )
            return;
          setAdditionOptions((prev) => [...prev, createOption(inputValue)]);
        }
        setInputValue("");
        event.preventDefault();
    }
  };

  // 輸入框
  const clickItem = (
    e: React.MouseEvent<HTMLDivElement> | null = null,
    item: MultiOptionModel
  ) => {
    e?.preventDefault();
    e?.stopPropagation();
    const newOptions = props.options.slice();
    // 找到選擇的部分
    const index = newOptions.findIndex((p) => p.id === item.id);
    newOptions[index].checked = !newOptions[index].checked;

    // 實際資料
    setDataFlow(newOptions);
    setCheckFlow(newOptions);
    // 搜尋清單調整(不是完整的)
    setFilterOptions(
      filterOptions.map((p) => {
        if (p.id === item.id) {
          p.checked = !p.checked;
        }
        return p;
      })
    );
  };

  // 搜尋key
  const handleChangeKey = (key: string | null) => {
    setSearchKey(key || "");
    // 複製新陣列
    const newOptions = [...props.options];
    if (key) {
      const selItems = newOptions.filter((p) =>
        p.text.toLocaleLowerCase().includes(key.toLocaleLowerCase())
      );
      setFilterOptions(selItems);
    } else {
      setFilterOptions(newOptions);
      setCheckFlow(newOptions);
    }
  };

  // 全選
  const selectAll = (res: boolean) => {
    setCheckAll(res);
    const newOptions = filterOptions.slice();
    // 全勾 or 取消
    newOptions.forEach((p) => {
      p.checked = res;
    });

    setFilterOptions(newOptions);
    setDataFlow(newOptions);
  };

  // 輸出資料flow
  const setDataFlow = (newOptions: MultiOptionModel[]) => {
    //setOptions(newOptions);
    const selIds = newOptions.filter((p) => p.checked).map((p) => p.id);
    const passSelIds = [
      ...selIds,
      ...additionOptionsRef.current.map((p) => p.value),
    ];
    // handleChange(selIds);
    props.onChange(passSelIds);
  };

  // 全選flow
  const setCheckFlow = (newOptions: MultiOptionModel[]) => {
    setCheckAll(newOptions.every((p) => p.checked === true));
  };

  // 點擊外部隱藏
  useEffect(() => {
    const handleOtherClick = (e: any) => {
      if (boxRef.current && !boxRef.current.contains(e.target)) {
        setHide(true);
      }
    };

    document.body.addEventListener("click", handleOtherClick);

    return () => {
      document.body.removeEventListener("click", handleOtherClick);
    };
  }, []);

  /** 打開視窗flow */
  const openFlow = () => {
    setHide(false);
    handleChangeKey("");
  };
  return (
    <div
      ref={boxRef}
      className={`${styles["multi-select-box"]} ${valid ? "" : "invalid"} ${
        props.disabled ? "disabled" : ""
      }`}
      onClick={openFlow}
    >
      <CreatableSelect
        isMulti
        className="react-select-container"
        classNamePrefix="react-select"
        components={{ Menu: () => null }}
        onKeyDown={handleKeyDown}
        value={selectedOptions}
        inputValue={inputValue}
        onInputChange={(newValue) => setInputValue(newValue)}
        // options={options}
        // isSearchable={showSearch}
        onChange={handleChange}
        placeholder={props.placeholder || ""}
        isDisabled={props.disabled}
      />
      <div className="dropdown-icon"></div>
      {/* 搜尋清單 */}
      {!hide && (
        <div
          className={`dropdown-box ${props.isOnlyIcon ? "onlyIcon-box" : ""}`}
        >
          {/* 篩選 */}
          {showSearch && (
            <div className="search-box">
              <input
                className={`seach-input `}
                type="text"
                value={searchKey}
                onChange={($event) => handleChangeKey($event.target.value)}
                placeholder={`${t("SEARCH")}...`}
              />
              <img
                alt="search"
                src="/assets/images/buttonIcon/search-icon.svg"
              />
            </div>
          )}
          {/* 全選 */}
          {showAll && (
            <div className="show-all-box">
              <label className="normal-checkbox ">
                <input
                  type="checkbox"
                  checked={checkAll === true}
                  onChange={($event) => {
                    $event.stopPropagation();
                    selectAll($event.target.checked);
                  }}
                />
                <span>{t("ALL_SELECT")}</span>
              </label>
            </div>
          )}

          <div className="option-box scroll">
            {filterOptions.map((p, index) => {
              return (
                <div
                  className={"option-item"}
                  key={index}
                  onClick={($event) => clickItem($event, p)}
                >
                  <label className="normal-checkbox ">
                    <input
                      type="checkbox"
                      checked={p.checked}
                      onChange={() => {}}
                    />
                    <span>{p.text}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectInput;
