import React, { FC, useEffect, useState } from "react";
import styles from "./ReportGenerationList.module.scss";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { normalDate3 } from "utils/dateTimeFormat";
import TablePagination from "components/base/TablePagination/TablePagination";
import ProblemReportContent from "components/otherModule/ProblemReportContent/ProblemReportContent";
import { ListRequestModel, StreamFileType } from "models/baseModel";
import { Link, useNavigate } from "react-router-dom";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { useMutation } from "@tanstack/react-query";
import SustainReportProduceService from "services/application/sustainReportDeclare/sustainReportProduce";
import { ResponseCode } from "models/responseCodeModel";
import {
  ReportProduceAction,
  ReportProduceStatus,
  SustainReportProduceInfoModel,
  SustainReportProduceShowModel,
  SustainReportProduceStatusModel,
} from "models/application/sustainReportDeclare/sustainReportProduceModel";
import { error2Alert, success2Alert } from "utils/otherToast";
import { Multilingual, SustainReportExportModel } from "models/application/sustainReportDeclare/sustainReportDeclareModel";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import { CommonService } from "services/common/commonService";

interface ReportGenerationListProps {}

const ReportGenerationList: FC<ReportGenerationListProps> = () => {
  let reportData: any[] = [
    {
      id: 1,
      year: 112,
      lastEditTime: new Date(),
      statusName: "未產製",
    },
  ];
  const [param, setParam] = useState<ListRequestModel>({
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortKey: null,
  });
  /** 清單資料 */
  const [listData, setListData] = useState<SustainReportProduceShowModel[]>([]);
  const navigate = useNavigate();
  /** 基本狀態資訊 */
  const [statusData, setStatusData] = useState<SustainReportProduceInfoModel>({
    produceId: "",
    year: 0,
    produceStatus: ReportProduceStatus.NotStarted,
    stepStatus: ReportProduceAction.StartProduce,
    produceStatusName: "",
  });

  useEffect(() => {
    getSustainReportProduceList.mutate(param);
  }, []);

  //#region 方法區塊

  /** 前往指定info 頁面 */
  const gotoDetail = (item: SustainReportProduceShowModel) => {
    if (item.produceStatus === ReportProduceStatus.NotStarted) {
      // 更改狀態後再往下一步驟
      editStatus.mutate(
        {
          produceId: item.id,
          action: ReportProduceAction.StartProduce,
        },
        {
          onSuccess: (res) => {
            if (res.success && res.code === ResponseCode.SuccessOK) {
              gotoTarget(item.id, 1);
            } else {
              console.log(res);
              error2Alert(res.message);
            }
          },
        }
      );
    } else {
      getStatus.mutate(item.id);
    }
  };

  /** 前往目的地 */
  const gotoTarget = (id: string, step: number) => {
    navigate(`/apply/report-generation/${id}/info/step${step}`);
  };

  /** 根據type取得按鈕text文字 */
  const getBtnText = (type: ReportProduceStatus) => {
    let text = "";
    switch (type) {
      case ReportProduceStatus.InProgress:
      case ReportProduceStatus.Completed:
        text = "編輯";
        break;
      case ReportProduceStatus.NotStarted:
      default:
        text = "開始產製";
        break;
    }
    return text;
  };


  /** 下載報告書 */
  const downloadFile = (id:string)=>{
    exportPOCReport.mutate({
      multilingual: Multilingual.Chinese,
      produceId: id
    });
  }
  //#endregion

  //#region API區塊
  /** 永續報告書產製清單 */
  const getSustainReportProduceList = useMutation({
    mutationFn: (model: ListRequestModel) =>
      SustainReportProduceService.getSustainReportProduceList(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportProduceShowModel[] = res.data;
        setListData([...data]);
        console.log(data);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 編輯狀態 */
  const editStatus = useMutation({
    mutationFn: (model: SustainReportProduceStatusModel) =>
      SustainReportProduceService.editStatus(model),
    onError: (err) => {
      console.log(err);
    },
  });

  /** 永續報告書產製清單 */
  const getStatus = useMutation({
    mutationFn: (procedureId: string) =>
      SustainReportProduceService.getStatus(procedureId),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportProduceInfoModel = res.data;
        setStatusData({ ...data });
        let step = 1;
        switch (data.stepStatus) {
          case ReportProduceAction.ReportGenerate:
            step = 2;
            break;
          case ReportProduceAction.SelectStyle:
            step = 3;
            break;
          case ReportProduceAction.ExportReport:
            step = 4;
            break;
          case ReportProduceAction.StartProduce:
          default:
            step = 1;
            break;
        }

        gotoTarget(data.produceId, step);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 匯出報告書 */
  const exportPOCReport = useMutation({
    mutationFn: (data: SustainReportExportModel) =>
      SustainReportDeclareService.exportPOCReport(data),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        success2Alert("匯出成功");
        CommonService.downloadByStream(res, StreamFileType.Docx);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  //#endregion

  return (
    <div className="no-nav-content-box">
      {(getSustainReportProduceList.isPending ||
        getStatus.isPending ||
        editStatus.isPending ||
        exportPOCReport.isPending) && <VisuallLoading />}
      <div className={styles["report-generation-list-box"]}>
        <div className="title mb-20-p">永續報告書產製</div>
        <div className="top-desc mb-20-p">
        報告書產製為系統輔助功能，上市櫃公司仍應進行鑑別利害關係人、重大主題分析、永續相關資料蒐集、報告書彙整編撰等作業。<br/>
        說明：請先完成當年度「企業ESG資訊揭露申報作業」，ESG指標將自動帶入報告書底稿中，若您未完成申報，將無法自動帶入。
        </div>
        <div className="result-box scroll">
          <div className="table-box">
            {false && <VisuallLoading></VisuallLoading>}
            <table
              aria-label="查詢結果table"
              className="table-container sticky-table"
            >
              <thead>
                <tr>
                  <th scope="col">年度</th>
                  <th scope="col">最後編輯日期</th>
                  <th scope="col">狀態</th>
                  <th scope="col">功能</th>
                </tr>
              </thead>
              <tbody>
                {listData.map((p, itemIndex: number) => {
                  return (
                    <tr key={p.id}>
                      <td>{p.produceYear}</td>
                      <td>{normalDate3(p.modifiedProduceTime)}</td>
                      <td>{p.produceStatusName}</td>
                      <td className="func-td">
                        <LinkIconButton
                          imgName="right-arrow.svg"
                          text={getBtnText(p.produceStatus)}
                          imgPosition="img-right"
                          onClick={() => gotoDetail(p)}
                        />
                        {p.produceStatus === ReportProduceStatus.Completed && (
                          <LinkIconButton
                            imgName="download-icon.svg"
                            text={"下載報告書"}
                          imgPosition="img-right"
                            onClick={() => {
                              downloadFile(p.id)
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="table-pagination mb-20-p">
          <TablePagination
            page={param.page}
            pageSize={param.pageSize}
            onChange={(e) => {
              const tmpReq = {
                ...param,
                ...e,
              };
              setParam(tmpReq);
            }}
            total={reportData?.length}
          />
        </div>
        <div className="desc">
          參考資料：
          <div className="ref-desc">
            <Link to={"/apply/propaganda"}>
            1.永續報告書重大主題編製指引<br/>
2.永續報告書重大主題揭露範例<br/>
3.永續報告書產製操作手冊<br/>
4.永續報告書說明文件
            </Link>
          </div>
          <br />
          <ProblemReportContent />
        </div>
      </div>
    </div>
  );
};

export default ReportGenerationList;
