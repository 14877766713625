import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./ReportAnalysis.module.scss";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { useMutation } from "@tanstack/react-query";
import AiAnalysisService from "services/application/indicatorDeclare/aiAnalysisService";
import BackTitle from "components/base/BackTitle/BackTitle";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { useParams } from "react-router-dom";
import IndicatorDeclareService from "services/application/indicatorDeclare/indicatorDeclareService";
import {
  DeclareStatus,
  EsgDeclareInfoModel,
} from "models/application/indicators/indicatorModel";
import {
  AiAnalysisResultValueModel,
  AiAnalysisStatusModel,
  AnalysisIndicatorCategoryModel,
  AnalysisIndicatorModel,
  AnalysisIndicatorSubjectModel,
  AnalysisStatus,
  EditAiAnalysisResultModel,
  IndicatorType,
} from "models/application/indicators/aiAnalysisModel";
import EmptySearchPage from "components/otherModule/EmptySearchPage/EmptySearchPage";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import { PageActionTyep } from "models/baseModel";
import NormalInput from "components/base/NormalInput/NormalInput";
import { CommonService } from "services/common/commonService";
import { error2Alert, success2Alert } from "utils/otherToast";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import { ResponseCode } from "models/responseCodeModel";
import BatchUploadModal, {
  BatchUploadModalProps,
} from "components/base/BatchUploadModal/BatchUploadModal";
import ProgressBar from "components/base/ProgressBar/ProgressBar";
import { useInterval } from "react-use";
import FloatCalculateService from "services/common/floatCalculateService";
import { dateFormat } from "utils/dateTimeFormat";

//#region ------------- TableComponent -------------
interface TableComponentProps {
  data: AnalysisIndicatorCategoryModel[];
  /** 動作 */
  action: string;
  changeValue: (item: AnalysisIndicatorModel, key: string) => void;
}

const TableComponent: FC<TableComponentProps> = (props) => {
  const { data, action } = props;

  return (
    <div className="analysis-table-box scroll">
      <ScrollToTopButton
        targetClassName={"analysis-table-box"}
        bottom={90}
        right={35}
      />

      <table
        aria-label="查詢結果table"
        className="table-container sticky-table"
      >
        <thead>
          <tr>
            <th scope="col">分類</th>
            <th scope="col">ESG主題</th>
            <th scope="col">項次</th>
            <th scope="col">ESG指標</th>
            <th scope="col">已填報內容</th>
            <th scope="col">單位</th>
            <th scope="col">格式</th>
            <th scope="col">報告書原始值</th>
            <th scope="col">轉換後的值</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return item.subjects.map((sub, subIndex) => {
              return sub.indicators.map((indicator, indicatorIndex) => {
                return (
                  <tr key={`${subIndex}_${indicatorIndex}`}>
                    {/* 類型 row合併 */}
                    {subIndex === 0 && indicatorIndex === 0 && (
                      <td rowSpan={item.rowspan}>{item.categoryName}</td>
                    )}
                    {indicatorIndex === 0 && (
                      <td rowSpan={sub.rowspan}>{sub.subject}</td>
                    )}
                    <td>{indicator.sortIndex + 1}</td>
                    <td>{indicator.indicator}</td>
                    <td>{indicator.declareValue}</td>
                    <td>{indicator.unit}</td>
                    <td>{indicator.typeName}</td>
                    <td>{indicator.values.join(",")}</td>
                    <td>
                      {action === PageActionTyep.View ? (
                        indicator.formatValues.join(",")
                      ) : (
                        <div>
                          <NormalInput
                            type={"text"}
                            defaultValue={indicator.formatValues.join(",")}
                            debounceTime={100}
                            onChange={(key: string) => {
                              props.changeValue(indicator, key);
                            }}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              });
            });
          })}
        </tbody>
      </table>
    </div>
  );
};
//#endregion

//#region ------------- ReportAnalysisComponent -------------

interface ReportAnalysisProps {}
const ReportAnalysis: FC<ReportAnalysisProps> = () => {
  //#region 參數類型

  const [infoData, setInfoData] = useState<EsgDeclareInfoModel>({
    year: 0,
    status: DeclareStatus.UnDeclared,
    canEdit: false,
    isFinished: false,
    indicatorMarketId: null
  });
  const realData = useRef<AnalysisIndicatorCategoryModel[]>([]);
  const [resultData, setResultData] = useState<
    AnalysisIndicatorCategoryModel[]
  >([]);
  const [orgResultData, setOrgResultData] = useState<
    AnalysisIndicatorCategoryModel[]
  >([]);
  const [word, setWord] = useState<string>("尚未解析");
  const [action, setAction] = useState<string>(PageActionTyep.View);
  const [analysisStataus, setAnalysisStataus] =
    useState<AiAnalysisStatusModel>();
  const [stopInterval, setStopInterval] = useState(false);
  const params = useParams();
  // ai 結果
  const [aiResultData, setAiResultData] =
    useState<AiAnalysisResultValueModel>();
  // modal 物件(confirm)
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setConfirmModal({
        ...confirmModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "同步至申報項目",
  });
  const [uploadModal, setUploadModal] = useState<BatchUploadModalProps>({
    show: false,
    handleClose: () => {
      setUploadModal({
        ...uploadModal,
        show: false,
      });
    },
    accept: ".pdf",
    handleUpload: (e) => {},
    title: "匯入報告書",
    uploadLoading: false,
    limitFileSize: 50,
  });

  //#endregion

  //#region 取得基本資訊和table 資料
  // 取得基本資訊
  const getDeclareInfo = useMutation({
    mutationFn: (id: string) => IndicatorDeclareService.getDeclareInfo(id),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        setInfoData({ ...res.data });
        if (res.data.year) {
          // 用自訂onsuccess狀態避免跟方法內的重複
          checkHasAnalysis.mutate(res.data.year, {
            onSuccess: (res) => {
              if (res.code === ResponseCode.SuccessOK && res.success) {
                setAnalysisStataus({ ...res.data });
                if (res.data.analysisId) {
                  getAnalysis.mutate(res.data.analysisId);
                }
              } else {
                console.log(res.message);
              }
            },
            onError: (error) => {
              console.log(error);
            },
          });
        }
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    getDeclareInfo.mutate(params.id!);
  }, []);

  // 取得解析資料
  const getAnalysis = useMutation({
    mutationFn: (id: string) => AiAnalysisService.getAnalysis(id),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        const data: AiAnalysisResultValueModel = res.data;
        setAiResultData({ ...data });
        setTableRows(data.categories);
      } else {
        console.log(res.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  /** 設定tableRows */
  const setTableRows = (data: AnalysisIndicatorCategoryModel[]) => {
    const tmpData = CommonService.deepClone(data);
    tmpData.forEach((category) => {
      // 取得category row
      category.rowspan = getCategoryRowSpan(category);

      category.subjects.forEach((sub) => {
        sub.rowspan = getSubjectRowSpan(sub);
      });
    });

    setResultData(CommonService.deepClone(tmpData));
    setOrgResultData(CommonService.deepClone(tmpData));
    realData.current = CommonService.deepClone(tmpData);
  };

  /** 取得表單 rowspan */
  const getCategoryRowSpan = (item: AnalysisIndicatorCategoryModel): number => {
    let count = 0;
    item.subjects?.forEach((p) => {
      const rowLength = p.indicators?.length;
      // 給一格空的
      if (rowLength === 0) {
        count++;
      } else {
        count += rowLength || 0;
      }
    });
    return count;
  };

  /** 取得subject rowspan */
  const getSubjectRowSpan = (item: AnalysisIndicatorSubjectModel): number => {
    let count = 0;
    if (item.indicators) {
      const rowLength = item.indicators.length;
      // 給一格空的
      if (rowLength === 0) {
        count++;
      } else {
        count += rowLength;
      }
    }
    return count;
  };
  //#endregion

  //#region 取得狀態
  const checkHasAnalysis = useMutation({
    mutationFn: (year: number) => AiAnalysisService.checkHasAnalysis(year),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        const statisInfo: AiAnalysisStatusModel = res.data;
        setAnalysisStataus({ ...statisInfo });
        if (statisInfo.status === AnalysisStatus.Completed) {
          getAnalysis.mutate(statisInfo.analysisId!);
        }
      } else {
        console.log(res.message);
        setStopInterval(true);
      }
    },
    onError: (error) => {
      setStopInterval(true);
    },
  });
  //#endregion

  //#region 編輯資料/同步資料
  const editAnalysis = useMutation({
    mutationFn: (model: EditAiAnalysisResultModel) =>
      AiAnalysisService.editAnalysis(model),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        handleAction(PageActionTyep.View);
        success2Alert(res.message);
      } else {
        error2Alert("編輯失敗");
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // 儲存
  const handleSave = () => {
    // 檢查量化數值是不有多個，不允許
    let pass = true;
    let msg = "ESG指標轉換後數值類型不正確:\n";
    // 量化不允許有逗號的資料
    realData.current.forEach((p) => {
      p.subjects.forEach((sub) => {
        let tmpMsg = "";
        sub.indicators.forEach((p) => {
          // 量化且值是有異常的
          if (p.type === IndicatorType.Quantify) {
            if (p.formatValues.length > 0) {
              if (!FloatCalculateService.isPositiveNum(p.formatValues[0])) {
                pass = false;
                tmpMsg += `${p.sortIndex + 1}.${p.indicator}\n`;
              }
            }
          }
        });
        if (tmpMsg) {
          tmpMsg = `${sub.subject}\n${tmpMsg}\n`;
        }
        msg += tmpMsg;
      });
    });
    if (!pass) {
      error2Alert(msg);
      return;
    }
    editAnalysis.mutate({
      data: {
        analysisId: analysisStataus?.analysisId!,
        categories: realData.current,
      },
    });
  };

  // 取消
  const handleCancel = () => {
    setResultData(CommonService.deepClone(orgResultData));
    realData.current = CommonService.deepClone(orgResultData);
  };

  const syncAnalysisToCompanyData = useMutation({
    mutationFn: (id: string) => AiAnalysisService.syncAnalysisToCompanyData(id),
    onSuccess: (res) => {
      const tmpConfirm = {
        show: true,
        loading: false,
      };
      if (res.code === 200 && res.success) {
        console.log(res.data);
        success2Alert(res.message);
        tmpConfirm.show = false;
      } else {
        error2Alert("編輯失敗");
      }
      setConfirmModal({
        ...confirmModal,
        ...tmpConfirm,
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
  //#endregion

  //#region 上傳報告書
  const uploadAndSendReportAnalysis = useMutation({
    mutationFn: (model: any) =>
      AiAnalysisService.uploadAndSendReportAnalysis(model),
    onSuccess: (res) => {
      if (res.code === ResponseCode.SuccessOK && res.success) {
        console.log(res.data);
        checkHasAnalysis.mutate(infoData.year);
        success2Alert("解析中");
      } else {
        error2Alert(res.message);
      }
    },
    onError: (error) => {},
  });

  // 上傳檔案
  const uploadFile = (files: any[]) => {
    const postData = new FormData();
    for (const file of files) {
      postData.append("file", file);
    }
    postData.append("year", infoData.year.toString());
    setUploadModal({
      ...uploadModal,
      show: false,
    });
    uploadAndSendReportAnalysis.mutate(postData);
  };

  const handleUpload = () => {
    setUploadModal({
      ...uploadModal,
      handleUpload: (e) => {
        uploadFile(e);
      },
      show: true,
    });
  };
  //#endregion

  // 更改預覽狀況
  const handleAction = (action: string) => {
    setAction(action);
  };

  useInterval(() => {
    // 每20秒打一次
    // 定時打api
    if (
      analysisStataus?.status === AnalysisStatus.Analysising &&
      !stopInterval
    ) {
      checkHasAnalysis.mutate(infoData.year);
    }
  }, 20000);

  return (
    <div className={`${styles["report-analysis-box"]}`}>
      {(getDeclareInfo.isPending ||
        editAnalysis.isPending ||
        uploadAndSendReportAnalysis.isPending ||
        getAnalysis.isPending) && <VisuallLoading />}

      <div className="title-box">
        <BackTitle url="/apply/indicator" title={`${infoData.year ?? ''}年`} />
        <div className="tool-box">
          {analysisStataus?.status === AnalysisStatus.None && (
            <div className="mr-1-m">
              <LinkIconButton
                imgName={"ai-analysis-icon.svg"}
                text={"ESG報告書解析"}
                onClick={handleUpload}
              />
            </div>
          )}
          {/* 解析失敗或解析完成 */}
          {(analysisStataus?.status === AnalysisStatus.Completed ||
            analysisStataus?.status === AnalysisStatus.Fail) &&
            action === PageActionTyep.View && (
              <div className="mr-1-m">
                <LinkIconButton
                  imgName={"refresh2-icon.svg"}
                  text={"重新解析"}
                  onClick={handleUpload}
                />
              </div>
            )}
          {analysisStataus?.status === AnalysisStatus.Completed &&
            action === PageActionTyep.View && (
              <>
                {/* <div className="mr-1-m">
                <LinkIconButton
                  imgName="print-icon.svg"
                  text="列印網頁"
                  onClick={() => {}}
                />
              </div> */}
                <div className="mr-1-m">
                  <LinkIconButton
                    imgName={"batch-upload-icon.svg"}
                    text={"同步至申報項目"}
                    onClick={() => {
                      setConfirmModal({
                        ...confirmModal,
                        show: true,
                        handleConfirm: () => {
                          setConfirmModal({
                            ...confirmModal,
                            show: true,
                            loading: true,
                          });
                          syncAnalysisToCompanyData.mutate(
                            analysisStataus?.analysisId!
                          );
                        },
                      });
                    }}
                  />
                </div>
              </>
            )}
          {/* 確認申報modal */}
          <ConfirmModal {...confirmModal}>
            <div>
              此操作會將「轉換後的值」同步至ESG申報作業，並且覆蓋已填報的內容；若不想覆蓋，請將「轉換後的值」清空即可。
              <br />
              是否確定同步?
            </div>
          </ConfirmModal>
        </div>
      </div>
      {/* 尚未分析 */}
      {analysisStataus?.status === AnalysisStatus.None && (
        <div className="word-box">
          <EmptySearchPage word={word} />
        </div>
      )}
      {/* 分析中 */}
      {analysisStataus?.status === AnalysisStatus.Analysising && (
        <div className="analysising-box">
          <div className="analysis-word">AI報告書 解析進行中…</div>
          <div className="refresh-box">
            <img
              className="refresh"
              alt="refresh"
              src="/assets/images/buttonIcon/refresh-icon.svg"
            />
          </div>
          <div className="progress-box">
            <ProgressBar
              option={{
                id: "analysis-report",
                pausePercent: analysisStataus.percentage || 0,
                nowPercent: 0,
                isProcessing: false,
                isDirectPercent: true,
                rate: 10,
              }}
            />
            <div className="percent">
              <div>{analysisStataus.percentage || 0}%</div>
            </div>
          </div>
        </div>
      )}
      {/* 分析結果 */}
      {analysisStataus?.status === AnalysisStatus.Completed && (
        <div className={"result-box"}>
          {resultData.length > 0 ? (
            <div className="result-content-box scroll">
              <div className="content-tool-box">
                <div className="text-box">
                  <div className="desc">
                    說明：解析完成後，您可以透過【重新解析】再次分析，或是透過【同步至申報項目】將分析的資訊同步至申報指標。
                    <br />
                    <br />
                    請注意：【同步至申報項目】會將「轉換後的值」全數同步至ESG申報作業，並且覆蓋已填報的內容。
                    請您於同步前核對「轉換後的值」是否正確，若同指標有多筆解析後資料，請透過「編輯」功能將資料刪減為一筆符合格式之資訊。
                    若您不想將「轉換後的值」覆蓋已填報的內容，請透過「編輯」功能將「轉換後的值」清空，再進行【同步至申報項目】。
                  </div>
                  {aiResultData?.analysisTime && (
                    <div className="time-text mt-2 mb-2">
                      解析完成時間({dateFormat(aiResultData?.analysisTime,'yyyy/MM/DD HH:MM:SS')})
                    </div>
                  )}
                </div>
                <div className="content-tool">
                  {action === PageActionTyep.View ? (
                    <button
                      className="secondary min-w-fit h-fit"
                      onClick={() => {
                        handleAction(PageActionTyep.Edit);
                      }}
                    >
                      編輯
                    </button>
                  ) : (
                    <>
                      <button
                        className="secondary min-w-fit h-fit mr-1-m"
                        onClick={() => {
                          handleAction(PageActionTyep.View);
                          handleCancel();
                        }}
                      >
                        取消
                      </button>
                      <button
                        className="default min-w-fit h-fit"
                        onClick={() => {
                          handleSave();
                        }}
                      >
                        儲存
                      </button>
                    </>
                  )}
                </div>
              </div>
              {/* 說明 */}

              <TableComponent
                action={action}
                data={resultData}
                changeValue={(item, value) => {
                  let selected = false;
                  realData.current.forEach((p) => {
                    if (!selected) {
                      p.subjects.forEach((sub) => {
                        if (!selected) {
                          sub.indicators.forEach((p) => {
                            if (!selected && p.code === item.code) {
                              selected = true;
                              p.formatValues = value ? [value] : [];
                            }
                          });
                        }
                      });
                    }
                  });
                }}
              />
            </div>
          ) : (
            <EmptySearchPage word={word} />
          )}
        </div>
      )}

      {/* 批次匯入彈窗 */}
      <BatchUploadModal
        {...uploadModal}
        noteChildren={
          <div>
            1.永續報告書應以原始文字檔案進行PDF轉檔後上傳，即勿直接掃描紙本產製PDF檔案，以利投資人搜尋電子書內容
          </div>
        }
      />
    </div>
  );
};
//#endregion

export default ReportAnalysis;
