//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect, createContext } from "react";
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams, Outlet } from "react-router-dom";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import { SustainQuestionnaireSampleModel } from "models/application/sustainQuestionnaire/sustainQuestionnaireModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import BackTitle from "components/base/BackTitle/BackTitle";
import { NavBarItemModel } from "models/baseModel";
import styles from "./ManuscriptLayout.module.scss";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import ManuscriptService from "services/admin/manuscriptService";
import {
  SustainRreportManuscriptManageDataModel,
  ManuscriptEditionEnum,
} from "models/admin/manuscriptModel";
import TabVerticalCategoryThin from "components/base/TabVerticalCategoryThin/TabVerticalCategoryThin";
import { CategoryNavBarListModel } from "models/baseModel";
//* ---------------------------- local end ---------------------------- *//

export const LayoutContext = createContext<{
  SustainQuestionnaireSampleModel: SustainQuestionnaireSampleModel | null;
}>({
  SustainQuestionnaireSampleModel: null,
});

const ManuscriptLayout: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  /** params */
  const params = useParams();
  /** navigate */
  const navigate = useNavigate();
  /** id */
  const id = params.id;
  /** layoutData */
  const [layoutData, setLayoutData] = useState({
    SustainQuestionnaireSampleModel: null,
  });
  /** selectedNavItemUrl */
  const [selectedNavItemUrl, setSelectedNavItemUrl] = useState<string>();
  /** ManuscriptManageData */
  const [ManuscriptManageData, setManuscriptManageData] =
    useState<SustainRreportManuscriptManageDataModel | null>(null);
  /** manuscriptLayoutNavItems */
  const [manuscriptLayoutNavItems] = useState<CategoryNavBarListModel[]>([
    {
      name: "中文版",
      category: ManuscriptEditionEnum.zh,
      id: ManuscriptEditionEnum.zh,
      list: [
        {
          name: "章節與底稿管理",
          url: `${ManuscriptEditionEnum.zh}/draft-management`,
          key: `${ManuscriptEditionEnum.zh}/draft-management`,
          disabled: false,
        },
        {
          name: "必要揭露章節設定",
          url: `${ManuscriptEditionEnum.zh}/disclosure-settings`,
          key: `${ManuscriptEditionEnum.zh}/disclosure-settings`,
          disabled: false,
        },
      ],
    },
  ]);
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getManuscriptManageDataById
  const {
    mutate: getManuscriptManageDataByIdMutate,
    isPending: getManuscriptManageDataByIdIsPending,
  } = useMutation({
    mutationFn: (id: string) =>
      ManuscriptService.getManuscriptManageDataById(id),
    onSuccess: (res) => {
      // console.log("getManuscriptManageDataById", res);
      if (res.code === 200 && res.success) {
        setManuscriptManageData(res.data);
      } else {
        setManuscriptManageData(null);
      }
    },
    onError: (err) => {
      console.log("getManuscriptManageDataById", err);
      setManuscriptManageData(null);
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  //- changeNavItem
  const changeNavItem = (item: NavBarItemModel) => {
    let year = ManuscriptManageData ? '?year=' + ManuscriptManageData.year : '';
    navigate(item.url + year, { replace: true });
  };
  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init year
  useEffect(() => {
    if (id) {
      getManuscriptManageDataByIdMutate(id);
    }
  }, [id, getManuscriptManageDataByIdMutate]);

  //- useEffect navItems
  useEffect(() => {
    if (ManuscriptManageData && id) {
      const url = params["*"];
      const path = url?.split(`${id}/`).pop();
      if (path) {
        setSelectedNavItemUrl(path);
        return;
      }
      const firstNavItem = manuscriptLayoutNavItems[0].list[0];
      setSelectedNavItemUrl(firstNavItem.url);
      navigate(firstNavItem.url + '?year=' + ManuscriptManageData.year, { replace: true });
    }
  }, [manuscriptLayoutNavItems, id, navigate, ManuscriptManageData]);

  //* ---------------------------- hook end ---------------------------- *//

  return (
    <CustomErrorBoundary>
      <div className="no-nav-content-box">
        <div className={`${styles["sustain-questionnaire-box"]}`}>
          {getManuscriptManageDataByIdIsPending && <VisuallLoading />}
          <div className="title-box">
            <BackTitle
              title={`${
                ManuscriptManageData?.year
                  ? ManuscriptManageData.year + "年度"
                  : ""
              }`}
              url={`/admin/manuscript`}
            />
          </div>

          <div className="sustain-questionnaire-content-box position-relative">
            {!manuscriptLayoutNavItems.length ? (
              !getManuscriptManageDataByIdIsPending && (
                <div className="no-data">沒有資料</div>
              )
            ) : (
              <>
                <div className="left-box">
                  <TabVerticalCategoryThin
                    data={manuscriptLayoutNavItems}
                    tabUrl={selectedNavItemUrl!}
                    handleOtherEvent={true}
                    changeItem={changeNavItem}
                  />
                </div>

                <div className={"right-box"}>
                  <div className="sustain-questionnaire-inside-content-box scroll position-relative">
                    <ScrollToTopButton
                      targetClassName={
                        "sustain-questionnaire-inside-content-box"
                      }
                      bottom={90}
                      right={35}
                    />
                    <LayoutContext.Provider value={layoutData}>
                      <Outlet />
                    </LayoutContext.Provider>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </CustomErrorBoundary>
  );
};

export default ManuscriptLayout;
