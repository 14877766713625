import React, { FC, useEffect, useState } from "react";
import styles from "./Entrance.module.scss";
import Dropdown from "components/base/Dropdown/Dropdown";
import { useNavigate } from "react-router-dom";
import { OptionModel } from "models/baseModel";
import { error2Alert } from "utils/otherToast";
import { useMutation } from "@tanstack/react-query";
import AuthService from "services/auth/authService";

interface EntranceProps {}

const Entrance: FC<EntranceProps> = () => {
  const [companyOption, setCompantOption] = useState<OptionModel[]>([]);
  const [identity, setIdentity] = useState<string>("0");
  const [userId,setUserId] = useState<string>("");
  const navigate = useNavigate();

  const login = () => {
    if (!userId) {
      error2Alert("請選擇登入公司");
      return;
    }
    gotoTarget(userId);
  };

  const gotoTarget = (id:string)=>{
    navigate(`/esgcheck?user_id=${id}&&event_key=0`);
  }

  const [identityOption,setIdentityOption] = useState<OptionModel[]>([
    {
      id: "0",
      text: "前台",
    },
    {
      id: "1",
      text: "後台",
    },
  ]);

  const changeIdentity = (item: string | null) => {
    if (item) {
      setIdentity(item);
      getUserIdOption.mutate(Number.parseInt(item));
    }
  };

  const getUserIdOption = useMutation({
    mutationFn: (param: number) => AuthService.getUserIdOption(param),
    onSuccess: (res) => {
      if (res.success) {
        const data: OptionModel[] = res.data;

        data.forEach((p) => {
          p.id = p.code!;
          p.text = `${p.code}-${p.text}`;
        });
        setCompantOption([...data]);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
      getUserIdOption.mutate(0);
  }, []);

  const changeCompany = (item: string | null) => {
    console.log(item);
    if(item){
      setUserId(item);
    }
  };
  return (
    <div className={styles["entrance-box"]}>
      <div className="form-box">
        <div className="form-item">
          <Dropdown
            isFloatTitle={true}
            placeholder={"請選擇身分"}
            defaultId={identity}
            onChange={changeIdentity}
            options={identityOption}
          />
        </div>
        <div className="form-item">
          <Dropdown
            isFloatTitle={true}
            placeholder={"請選擇登入公司"}
            onChange={changeCompany}
            options={companyOption}
          />
        </div>
        <div className="form-item">
          <button className="default" onClick={login}>
            登入
          </button>
        </div>
      </div>
    </div>
  );
};

export default Entrance;
