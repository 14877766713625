import React, { FC, ReactNode, useEffect, useRef } from "react";
import styles from "./MoreTool.module.scss";

interface MoreToolProps {
  /** 展開項目 */
  onToggle: (show: boolean) => void;
  children?: ReactNode;
  show: boolean;
  target?: any;
}

const MoreTool: FC<MoreToolProps> = (moreProps) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const optionBoxRef = useRef<HTMLDivElement>(null);
  // 點擊外部 or 內部都是關閉
  useEffect(() => {
    const handleOtherClick = (e: any) => {
      // 選單內按鈕或外部都關閉
      if (!boxRef.current?.contains(e.target)||
      optionBoxRef.current?.contains(e.target)) {

        moreProps.onToggle(false);
      }
    };

    document.body.addEventListener("click", handleOtherClick);

    return () => {
      document.body.removeEventListener("click", handleOtherClick);
    };
  }, []);
  return (
    <div ref={boxRef}>
      {/* 按鈕 */}
      <div>{moreProps.target}</div>
      {moreProps.show && (
        <div className={styles["more-tool-box"]}>
          <div ref={optionBoxRef} className="option-box">
            {moreProps.children}
            </div>
        </div>
      )}
    </div>
  );
};

export default MoreTool;
