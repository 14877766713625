import { SustainReportManuscriptUserDataModel, SustainReportProduceStatusModel, SustainReportUserTreeNodeRequestModel, SustainReportUserDataRequestModel, ReportTemplateModel, FileRequestModel } from "models/application/sustainReportDeclare/sustainReportProduceModel";
import { ListRequestModel } from "models/baseModel";
import { HttpClient } from "services/httpClient";

const httpClient = new HttpClient();

/** 永續報告書產製清單 */
const getSustainReportProduceList = (mode: ListRequestModel) => {
    return httpClient.post('sustainReportProduce/list', mode);
}

/** 取得報告書產製狀態 */
const getStatus = (produceId: string) => {
    return httpClient.get(`sustainReportProduce/status?produceId=${produceId}`);
}

/** 改報告書產製狀態 */
const editStatus = (model: SustainReportProduceStatusModel) => {
    return httpClient.post(`sustainReportProduce/status`, model);
}

/** 取得章節樹狀表 */
const getUserSustainReportTree = (produceId: string) => {
    return httpClient.get(`sustainReportProduce/userTree?produceId=${produceId}`);
}

/** 編輯章節預設樹狀表 */
const editDefaultSustainReportTree = (model: SustainReportUserTreeNodeRequestModel) => {
    return httpClient.post(`sustainReportProduce/userTree`, model);
}

/** 取得章節樹狀表 */
const getUserSustainReportTreeFlat = (produceId: string) => {
    return httpClient.get(`sustainReportProduce/userTreeFlat?produceId=${produceId}`);
}

/** 取得章節下資料 */
const getUserSustainReportData = (
    model: SustainReportUserDataRequestModel
) => {
    let str = `userChapterId=${model.userChapterId}`;
    if (model.userChapterDataId) {
        str += `&userChapterDataId=${model.userChapterDataId}`;
    }
    return httpClient.get(`sustainReportProduce/userData?${str}`);
}

/** 編輯章節下資料 */
const editUserSustainReportData = (model: SustainReportManuscriptUserDataModel) => {
    return httpClient.post(`sustainReportProduce/userData`, model);
}

/** 將章節下資料還原成預設 */
const editUserSustainReportDataDefault = (model: SustainReportUserDataRequestModel) => {
    return httpClient.post(`sustainReportProduce/userData/default`, model);
}

/** 取得報告書總覽 */
const getUserSustainReportPreview = (produceId: string) => {
    return httpClient.get(`sustainReportProduce/userPreview?produceId=${produceId}`);
}

/** 取得報告書樣板類型 */
const getTemplateSetType = (year: number) => {
    return httpClient.get(`sustainReportExport/templateType?year=${year}`);
}

/** 取得報告書樣板類型 */
const editTemplateSetType = (model: ReportTemplateModel) => {
    return httpClient.post(`sustainReportExport/templateType`, model);
}

/** 批次匯入範本檔案 (帶入現有資料) */
const getReportProduceImportExcelExampleFileStream = (model: FileRequestModel) => {
    return httpClient.getBlob(`sustainReportProduce/importExcel/example/FileStream?year=${model.year}&isImport=${model.isImport}`);
}

/** 批次匯入excel或csv檔 */
const reportProduceImportExcel = (model: any) => {
    return httpClient.post(`sustainReportProduce/importExcel?year=${model.year}`,model.file);
}
const SustainReportProduceService = {
    getSustainReportProduceList,
    getStatus,
    editStatus,
    getUserSustainReportTree,
    editDefaultSustainReportTree,
    getUserSustainReportTreeFlat,
    getUserSustainReportData,
    editUserSustainReportData,
    editUserSustainReportDataDefault,
    getUserSustainReportPreview,
    getTemplateSetType,
    editTemplateSetType,
    getReportProduceImportExcelExampleFileStream,
    reportProduceImportExcel
}
export default SustainReportProduceService;