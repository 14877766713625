//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect, useMemo } from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./Detail.module.scss";
import BackTitle from "components/base/BackTitle/BackTitle";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import { CommonService } from "services/common/commonService";
import {
  DeclareStatusEnum,
  SustainReportDeclareDataModel,
  EsgDisclosureDataRequestModel,
  RequestTypeEnum,
  DeclareSettingDataRequestModel,
} from "models/admin/declarationModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import { NavBarItemModel, PageActionTyep } from "models/baseModel";
import TabVerticalThin from "components/base/TabVerticalThin/TabVerticalThin";
import { SustainReportDeclareDataShowModel } from "models/application/sustainReportDeclare/sustainReportDeclareModel";
import SustainReportEnInfo from "pages/Application/SustainReportDeclare/SustainReportEnInfo/SustainReportEnInfo";
import SustainReportTwInfo from "pages/Application/SustainReportDeclare/SustainReportTwInfo/SustainReportTwInfo";
import { ActionsPermissionCodes } from "models/auth/permissionModel";
import useUserInfoStore from "state/useUserInfoStore";
import EmptyWaitPage from "components/common/EmptyWaitPage/EmptyWaitPage";

//* ---------------------------- local end ---------------------------- *//

const Detail: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const params = useParams();
  const declareId: string = params?.declareId!;
  const location = useLocation();
  const sustainabilityReport_SelectedItemData: SustainReportDeclareDataModel =
    location?.state?.sustainabilityReport_SelectedItemData;
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [version, setVersion] = useState<string>(
    queryParams.get("version") || "tw"
  );
  const [navItems, setNavItems] = useState<NavBarItemModel[]>([
    {
      name: "中文版報告書",
      key: "tw",
      url: `/admin/declaration/sustainability-report/${declareId}/detail?version=tw`,
      disabled: false,
    },
    {
      name: "英文版報告書",
      key: "en",
      url: `/admin/declaration/sustainability-report/${declareId}/detail?version=en`,
      disabled: false,
    },
  ]);
  const [infoData, setInfoData] = useState<SustainReportDeclareDataShowModel>();
  //* userInfo
  const { userInfo } = useUserInfoStore();
  //* permission
  const { permission } = userInfo;
  //* actionCodes
  const { actionCodes } = permission;
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getSustainReportDeclareData
  const {
    mutate: getSustainReportDeclareDataMutate,
    isPending: getSustainReportDeclareDataIsPending,
  } = useMutation({
    mutationFn: (declareId: string) =>
      SustainReportDeclareService.getSustainReportDeclareData(declareId),
    onSuccess: (res) => {
      console.log("getSustainReportDeclareData", res);
      if (res.code === 200 && res.success) {

        const newData: SustainReportDeclareDataShowModel = res.data;
        setInfoData({ ...newData });
      } else{
        console.log(res.message)
      }
    },
    onError: (err) => {
      console.log("getSustainReportDeclareData", err);

    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  const changeItem = (item: NavBarItemModel) => {
    setVersion(item.key!);
    console.log("item", item);
    navigate(item.url, { replace: true, state: location.state });
  };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init
  useEffect(() => {
    console.log("id", declareId);
    console.log(
      "sustainabilityReport_SelectedItemData",
      sustainabilityReport_SelectedItemData
    );
    console.log("version", version);
    if (!declareId || !sustainabilityReport_SelectedItemData) {
      navigate("/admin/declaration/sustainability-report");
      return;
    }
    getSustainReportDeclareDataMutate(declareId);
  }, [
    declareId,
    sustainabilityReport_SelectedItemData,
    navigate,
    getSustainReportDeclareDataMutate,
    version,
  ]);
  //* ---------------------------- hook end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  // const memoizedSustainReportInfo = useMemo(() => {
  //   switch (version) {
  //     case "tw":
  //       return (
  //         <SustainReportTwInfo
  //           declareId={declareId}
  //           infoData={infoData}
  //           sustainabilityReport_SelectedItemData={sustainabilityReport_SelectedItemData}
  //         />
  //       );
  //     case "en":
  //       return (
  //         <SustainReportEnInfo
  //           declareId={declareId}
  //           infoData={infoData}
  //           sustainabilityReport_SelectedItemData={sustainabilityReport_SelectedItemData}
  //         />
  //       );
  //     default:
  // }, [version]);
  //* ---------------------------- component end ---------------------------- *//

  return (
    <div className={`${styles["disclosure-read-box"]}`}>
      {/*上方bar*/}
      <div className="title-box">
        <BackTitle
          title={`${
            sustainabilityReport_SelectedItemData
              ? sustainabilityReport_SelectedItemData.companyCode +
                " - " +
                sustainabilityReport_SelectedItemData.companyName
              : ""
          }`}
          url={`/admin/declaration/sustainability-report`}
          state={location.state || null}
        />
      </div>
      <div className="content-box position-relative">
        {getSustainReportDeclareDataIsPending ? <VisuallLoading /> : null}
        {/* 左區塊 */}
        <div className="left-box">
          <TabVerticalThin
            data={navItems}
            tabKey={version}
            handleOtherEvent={true}
            changeItem={changeItem}
          />
        </div>

        {/* 右區塊 */}
        <div className={"right-box"}>
          <div className="sustain-report-info-box">
            {version === "tw" ? (
              <SustainReportTwInfo
                infoData={infoData}
                action={PageActionTyep.View}
                setAction={() => {}}
                saveFinished={() => {}}
              />
            ) : (
              <SustainReportEnInfo
                infoData={infoData}
                action={PageActionTyep.View}
                setAction={() => {}}
                saveFinished={() => {}}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
