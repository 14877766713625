import { FC, useEffect, useState } from "react";
import { RouteObject, useRoutes, Navigate, Outlet } from "react-router-dom";
import Home from "./Home/Home";
import useUserInfoStore from "../../state/useUserInfoStore";
import { SideBarItemModel } from "../../models/baseModel";
import Header from "../../components/layouts/Header/Header";
import Sidebar from "../../components/layouts/SideBar/Sidebar";
import EsgIndicatorApplyRouter from "./EsgIndicatorDeclare/EsgIndicatorApplyRouter";
import SustainReportRouter from "./SustainReportDeclare/SustainReportRouter";
import GhgReductionApplyRouter from "./GhgReductionDeclare/GhgReductionApplyRouter";
import SustainQuestionnaireRouter from "./SustainQuestionnaire/SustainQuestionnaireRouter";
import EducationPropaganda from "./EducationPropaganda/EducationPropaganda";
import ApplyProtectedRoute from "context/ApplyProtectedRoute";
import { CommonService } from "services/common/commonService";
import { useMutation } from "@tanstack/react-query";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import { SystemManageType } from "models/admin/systemManageModel";
import { useTranslation } from "react-i18next";
import ReportGenerationRouter from './ReportGeneration/ReportGenerationRouter';
import { usePermissionContext } from "context/PermissionProvider";
import {
  PagePermissionCodes,
  PermissionRouteObjectModel,
  PermissionSideBarItemModel,
  ActionsPermissionCodes,
  FeaturePermissionCodes,
} from "models/auth/permissionModel";


type ApplicationLayoutProps = {
  isActiveGenReport: boolean | null;
};
// layout
const ApplicationLayout: FC<ApplicationLayoutProps> = ({isActiveGenReport}) => {
  const { userInfo } = useUserInfoStore();
  const {t} = useTranslation();
  const { PermissionService } = usePermissionContext();
  const { detectEveryPagePermission } = PermissionService;
  const initSidebarItems: SideBarItemModel[] = [
    // {
    //   moduleName: "首頁",
    //   url: "home",
    //   icon: "sidebar-home-icon.svg",
    // },
    {
      moduleName: "企業ESG資訊揭露\n申報作業",
      url: "indicator",
      icon: "sidebar-prefecture-icon.svg",
      isActive: true
    },
    {
      moduleName: "永續報告書\n申報作業",
      url: "sustain-report",
      icon: "sidebar-book-icon.svg",
      isActive: true
    },
    {
      moduleName: "溫室氣體排放及減量資訊申報作業",
      url: "ghg-reduction",
      icon: "sidebar-ghg-reduction-icon.svg",
      revealText: "自願揭露",
      isActive: true
    },
    {
      moduleName: "永續經濟\n活動問卷",
      url: "sustain-questionnaire",
      icon: "sidebar-questionnaire-icon.svg",
      revealText: "自願揭露",
      isActive: true
    },
    {
      moduleName: "永續報告書產製",
      url: "report-generation",
      icon: "sidebar-report-generation-icon.svg",
      divideLine: true,
      composition: "flex-row",
      isActive: (detectEveryPagePermission([PagePermissionCodes.SustainReportProduceModule]) && isActiveGenReport!)
    },
    {
      moduleName: "教育宣導資源",
      url: "propaganda",
      icon: "sidebar-propaganda-icon.svg",
      divideLine: true,
      composition: "flex-row",
      isActive: true
    },
  ];
  const [sidebarItems, setSidebarItems] = useState<any>([]);

  const {
    mutate: isActiveFunction
    } = useMutation({
      mutationFn: (type: SystemManageType) =>
      SustainReportDeclareService.isActiveFunction(type),
    onSuccess: (res) => {
      if (res.code === 200) {
        const permissions = CommonService.getApplyMarketPermissions(
          userInfo.marketType
        );
        let mainModule = initSidebarItems.filter((p) =>
          permissions.includes(p.url) && p.isActive === true
        );
        if(res.success === false) {
          mainModule = mainModule.filter((p) =>
            p.url!=='report-generation'
        );
        }
        setSidebarItems([...mainModule]);
      }
    },
    onError: (err) => {
      console.log("isActiveFunction", err);
    },
  });

  useEffect(() => {
    if (userInfo && userInfo.marketType !== null) {
      const permissions = CommonService.getApplyMarketPermissions(
        userInfo.marketType
      );
      // console.log(isActiveGenReport);
      const mainModule = initSidebarItems.filter((p) =>
        permissions.includes(p.url) && p.isActive === true
      );
      setSidebarItems([...mainModule]);
      isActiveFunction(SystemManageType.SustainReport);
    }
  }, [userInfo]);

  return (
    <div className="root-box">
      {/* Narbar */}
      <div>
        <Header moduleName={t('DECLARATION_FRONT')} />
      </div>
      <div className="layout-box scroll">
        {/* 父層不更動的部分 */}
        <nav>
          <Sidebar items={sidebarItems} />
        </nav>
        {/* 子層替換 */}
        <div className="layout-container-box">
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

type GenReporterdRouteProps = {
  children?: React.ReactNode;
  isPass: boolean | null
};

/** api判斷是否有開啟永續報告書產製功能，沒有責導回首頁 */
const GenReporterdRoute: FC<GenReporterdRouteProps> = ({
  children,
  isPass
}) => {

  return ((isPass === true) ? <>{children}</> : <Navigate to={'/apply/home'} replace />);
};



interface ApplicationRouterProps {}
const ApplicationRouter: FC<ApplicationRouterProps> = () => {
  // 管理者不可進入該頁面
  const isAdmin = useUserInfoStore((p) => p.userInfo?.isAdmin);
  // 不是管理者才可以
  const isAllowed = !isAdmin;
  const [isPass, setIsPass] = useState<boolean>(true);
  const { PermissionService } = usePermissionContext();
  const { permissionRouter } = PermissionService;
  const { mutate: isActiveFunction } = useMutation({
    mutationFn: (type: SystemManageType) =>
    SustainReportDeclareService.isActiveFunction(type),
    onSuccess: (res) => {
      if (res.code === 200) {
        setIsPass(res.success);
      } else {
        setIsPass(false);
      }
    },
    onError: (err) => {
      setIsPass(false);

    },
  });

  useEffect(() => {
    isActiveFunction(SystemManageType.SustainReport);
  }, []);
  // 創建router
  const routers: PermissionRouteObjectModel[] = [
    {
      path: "",
      element: (
        <ApplyProtectedRoute isAllowed={isAllowed} redirectPath="/admin">
          <ApplicationLayout isActiveGenReport={isPass}></ApplicationLayout>
        </ApplyProtectedRoute>
      ),
      children: [
        {
          path: "",
          element: <Navigate to={"indicator"} />,
        },
        {
          path: "indicator/*",
          element: <EsgIndicatorApplyRouter />,
        },
        {
          path: "sustain-report/*",
          element: <SustainReportRouter />,
        },
        {
          path: "ghg-reduction/*",
          element: <GhgReductionApplyRouter />,
        },
        {
          path: "sustain-questionnaire/*",
          element: <SustainQuestionnaireRouter />,
        },
        {
          path: "report-generation/*",
          element: (<GenReporterdRoute isPass={isPass}><ReportGenerationRouter /></GenReporterdRoute>),
          pagePermission: PagePermissionCodes.SustainReportProduceModule,
        },
        {
          path: "propaganda/*",
          element: <EducationPropaganda />,
        },
      ],
    },
    {
      path: "home",
      element: (
        <ApplyProtectedRoute isAllowed={isAllowed} redirectPath="/admin">
          <Home isActiveGenReport={isPass} />
        </ApplyProtectedRoute>
      ),
    },
  ];
  let elements = useRoutes(permissionRouter(routers));
  return <>{elements}</>;
};

export default ApplicationRouter;
