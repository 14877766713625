import React, { FC } from "react";
import styles from "./ManuscriptRouter.module.scss";
import {
  Navigate,
  Outlet,
  RouteObject,
  useRoutes,
  useLocation,
} from "react-router-dom";
import ManuscriptList from "./ManuscriptList/ManuscriptList";
import {
  FeaturePermissionCodes,
  PermissionRouteObjectModel,
  ActionsPermissionCodes,
} from "models/auth/permissionModel";
import { usePermissionContext } from "context/PermissionProvider";

import ManuscriptLayout from "./ManuscriptLayout/ManuscriptLayout";
import ZhEdition from "./Edition/Zh/Zh";
import DraftSettings from "./DraftSettings/DraftSettings";
import DisclosureSettings from "./DisclosureSettings/DisclosureSettings";
import DraftSettingsLayout from "./DraftSettings/DraftSettingsLayout/DraftSettingsLayout";
import DraftManagement from "./DraftManagement/DraftManagement";

interface ManuscriptRouterProps {}

const ManuscriptRouter: FC<ManuscriptRouterProps> = () => {
  //* ---------------------------- state start ---------------------------- *//
  const { PermissionService } = usePermissionContext();
  const {
    permissionRouter,
    detectSomeFeatruePermission,
    detectSomeActionPermission,
  } = PermissionService;

  const routers: PermissionRouteObjectModel[] = [
    {
      path: "",
      element: <ManuscriptList />,
      featurePermission: FeaturePermissionCodes.ReportManage.ReportList,
      actionReadPermission: ActionsPermissionCodes.ReportManage.ReportListRead,
    },
    {
      path: ":id",
      element: <ManuscriptLayout />,
      featurePermission: detectSomeFeatruePermission([
        FeaturePermissionCodes.ReportManage.ReportCn,
        FeaturePermissionCodes.ReportManage.ReportEn,
      ]),
      actionReadPermission: detectSomeActionPermission([
        ActionsPermissionCodes.ReportManage.ReportCnRead,
        ActionsPermissionCodes.ReportManage.ReportEnRead,
      ]),
      children: [
        {
          path: "zh",
          element: <ZhEdition />,
          featurePermission: FeaturePermissionCodes.ReportManage.ReportCn,
          actionReadPermission:
            ActionsPermissionCodes.ReportManage.ReportCnRead,
          children: [
            {
              path: "draft-management",
              element: <DraftManagement />,
              featurePermission: FeaturePermissionCodes.ReportManage.ReportCn,
              actionReadPermission:
                ActionsPermissionCodes.ReportManage.ReportCnRead,
            },
            {
              path: "disclosure-settings",
              element: <DisclosureSettings />,
              featurePermission: FeaturePermissionCodes.ReportManage.ReportCn,
              actionReadPermission:
                ActionsPermissionCodes.ReportManage.ReportCnRead,
            },
          ],
        },
      ],
    },
    {
      path: ":id",
      element: <DraftSettingsLayout />,
      featurePermission: detectSomeFeatruePermission([
        FeaturePermissionCodes.ReportManage.ReportCn,
        FeaturePermissionCodes.ReportManage.ReportEn,
      ]),
      actionReadPermission: detectSomeActionPermission([
        ActionsPermissionCodes.ReportManage.ReportCnRead,
        ActionsPermissionCodes.ReportManage.ReportEnRead,
      ]),
      children: [
        {
          path: "zh",
          element: <ZhEdition />,
          featurePermission: FeaturePermissionCodes.ReportManage.ReportCn,
          actionReadPermission:
            ActionsPermissionCodes.ReportManage.ReportCnRead,
          children: [
            {
              path: "draft-management/:nodeId/settings",
              element: <DraftSettings />,
              featurePermission: FeaturePermissionCodes.ReportManage.ReportCn,
              actionReadPermission:
                ActionsPermissionCodes.ReportManage.ReportCnRead,
            },
          ],
        },
      ],
    },
  ];

  //* ---------------------------- state end ---------------------------- *//
  let elements = useRoutes(permissionRouter(routers));
  return (
    <>
      <div className={styles["manuscript-layout"]}>{elements}</div>
    </>
  );
};

export default ManuscriptRouter;
