import React, { FC } from "react";
import styles from "./CustomErrorBoundary.module.scss";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "../ErrorBoundaryFallback/ErrorBoundaryFallback";

interface CustomErrorBoundaryProps {
  children?: React.ReactNode;
}

const CustomErrorBoundary: FC<CustomErrorBoundaryProps> = (props) => {
  return (
    <ErrorBoundary
      fallback={<ErrorBoundaryFallback />}
      onError={(err) => {
        console.log(`ErrorBoundary:${err}`);
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
};

export default CustomErrorBoundary;
