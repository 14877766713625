import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./Edit.module.scss";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import IconButton from "components/button/IconButton/IconButton";
import NormalInput from "components/base/NormalInput/NormalInput";
import ConfirmModal, { ConfirmModalProps } from "components/base/ConfirmModal/ConfirmModal";
import { AddPrincipleMatrixModel, MatrixLeftTableModel,  PrincipleMatrixLastItemRightModel,  PrincipleMatrixLeftModel, PrincipleMatrixModel, PrincipleMatrixRightModel, PrincipleMatrixSubItemRightModel } from "models/admin/sustainStandardModel";
import { useMutation } from "@tanstack/react-query";
import PrincipleMatrixService from "services/admin/principleMatrixService";
import toast from "react-hot-toast";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import BatchUploadModal, { BatchUploadModalProps } from "components/base/BatchUploadModal/BatchUploadModal";
import MultiSelect from "components/base/MultiSelect/MultiSelect";
import { MultiOptionModel } from "models/baseModel";
import { useParams } from "react-router-dom";
import BackTitle from "components/base/BackTitle/BackTitle";
import { ResponseCode } from "models/responseCodeModel";
import { error2Alert } from "utils/otherToast";
import { usePermissionContext } from "context/PermissionProvider";
import { SustainMatrixManageActionsPermissionCodes } from "models/auth/permissionModel";



const Edit: FC = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [leftData, setLeftData] = useState<Array<MatrixLeftTableModel>>();//for ui render
  const [dynamicData, setDynamicData] = useState<Array<PrincipleMatrixRightModel>>([]);
  const dynamicDataRef = useRef<Array<PrincipleMatrixRightModel>>([]);
  const [dynamicDataTitle, setDynamicDataTitle] = useState<Array<PrincipleMatrixSubItemRightModel>>([]);
  const [saveData, setSaveData] = useState<PrincipleMatrixModel>();//for save data
  const saveLeftData = useRef<Array<PrincipleMatrixLeftModel>>([]);
  const [hasSasb, setHasSasb] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sampleXls, setSampleXls] = useState('');
  const params= useParams();
  const [title, setTitle] = useState(' ');
  const [editForm, setForm] = useState<AddPrincipleMatrixModel>({
    name: null,
    isSasbIndustry: null,
    indicatorPrincipleMatrix: null,
    indicatorMarketId: params.id!
  });
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;

  // modal 物件(confirm)
  const [modal, setModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {},
    handleConfirm: () => {},
    title: "新增準則",
  });

  // modal 物件(confirm)
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setConfirmModal({
        ...confirmModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "確認",
  });

  // modal 物件(context confirm)
  const [importModal, setImportModal] = useState<BatchUploadModalProps>({
      show: false,
      handleClose: () => {
          setImportModal((prev) => {
          return {
          ...prev,
          show: !prev.show,
          };
      });
      },
      handleUpload: (e) => uploadFile(e),
      sampleDownloadUrl: sampleXls,//'/sample/匯入產業類別範本.xlsx',
      uploadLoading: false,
  });

  // 取的矩陣
  const { mutate: getMatrixData, isPending: listIsPending } =
    useMutation({
      mutationFn: () =>
      PrincipleMatrixService.getMatrixData(params.id),
      onSuccess: (res) => {
        if (res.code === 200 && res.success) {
          setTitle(res.message);
          let tmpLeft:(Array<MatrixLeftTableModel>) = [];
          saveLeftData.current = res.data.leftMatrix;
          res.data.leftMatrix.forEach((d:PrincipleMatrixLeftModel, i:number) => {
            d.subItem.forEach((s, si:number) => {
              s.lastItem.forEach((l, li:number) => {
                let addTr = {
                  category: d.category,
                  categoryName: d.categoryName,
                  categoryRowspan: 0,
                  isLevelOne: false,
                  subjectName: s.subjectName,
                  subjectNameRowspan: 0,
                  isLevelTwo: false,
                  sortIndex: li+1,//l.sampleControlSortIndex,
                  indicatorName: l.indicatorName,
                  indicatorId: l.indicatorId,
                }
                tmpLeft.push(addTr);
              });
            });
          });

          let category:(number | null) = null;
          let subjectName:(string | null) = null;
          tmpLeft.forEach(x=> {
            if(category !== x.category) {
              category = x.category;
              x.isLevelOne = true;
              x.categoryRowspan = tmpLeft.filter(t=>t.category === category).length || 0;
              x.subjectNameRowspan = tmpLeft.filter(s=>s.subjectName === x.subjectName).length || 0;
            }
            if(subjectName !== x.subjectName) {
              subjectName = x.subjectName;
              x.isLevelTwo = true;
              x.subjectNameRowspan = tmpLeft.filter(s=>s.subjectName === x.subjectName).length || 0;
            }
          });
          setLeftData(tmpLeft);
          let tmpRightDdata = res.data.rightMatrix;
          tmpRightDdata.forEach((d:PrincipleMatrixRightModel) => {
            d.subItem.forEach((a:PrincipleMatrixSubItemRightModel) => {
              a.lastItem.forEach((s:PrincipleMatrixLastItemRightModel) => {

                if(dynamicDataTitle.length > 0) {
                  dynamicDataTitle.forEach(tmpt => {
                    let t = tmpt.lastItem.find(x=>x.sasbId === s.sasbId);
                    if(t) {
                      s.isHide = t.isHide;
                    } else {
                      s.isHide = false;
                    }
                  })
                } else {
                  s.isHide = false;
                }

              });
            });
          });
          setDynamicData(tmpRightDdata);
          dynamicDataRef.current = res.data.rightMatrix;
          setSaveData({leftMatrix: res.data.leftMatrix, rightMatrix: res.data.rightMatrix});
          //設定title
          if(res.data.rightMatrix.length > 0) {
            let tmp:(Array<PrincipleMatrixSubItemRightModel>) = [];
            res.data.rightMatrix[0].subItem.forEach((item:PrincipleMatrixSubItemRightModel) => {
              if(item.isSasb === true) {
                setHasSasb(true);
              }
              let tmpFilet:(MultiOptionModel[]) = [];
              item.lastItem.forEach((sbname: PrincipleMatrixLastItemRightModel)=> {

                if(dynamicDataTitle.length > 0) {
                  dynamicDataTitle.forEach(tmpt => {
                    let t = tmpt.lastItem.find(x=>x.sasbId === sbname.sasbId);
                    if(t) {
                      sbname.isHide = t.isHide;
                    } else {
                      sbname.isHide = false;
                    }
                  })
                } else {
                  sbname.isHide = false;
                }
                tmpFilet.push({
                  id: sbname.sasbId!,
                  text: sbname.sasbName!,
                  checked: sbname.isHide === false ? true : false
                })
              })
              tmp.push({
                principleId: item.principleId,
                principleName: item.principleName,
                isSasb: item.isSasb,
                lastItem: item.lastItem,
                filterTitles: tmpFilet
              });
            });
            setDynamicDataTitle(tmp);
          }
        } else {
          //setList([]);
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });

  // 新增矩陣
  const {mutate: addPrincipleMatrix, isPending: addIsPending} =
    useMutation({
      mutationFn: (data: AddPrincipleMatrixModel) =>
      PrincipleMatrixService.addMatrixData(data),
      onSuccess: (res) => {
        if (res.code === 200 && res.success) {
          getMatrixData();
          toast.success('新增成功');
          setModal((prev) => ({
            ...prev,
            show: false,
          }));
        } else {
          toast.error(res.message);
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });

// 刪除準則
const {mutate: deletePrinciple, isPending: deleteIsPending } =
  useMutation({
    mutationFn: (principleId: string) =>
    PrincipleMatrixService.deleteMatrixPrinciple(principleId),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        getMatrixData();
        toast.success('刪除成功');

      } else {
        toast.error(res.message);
      }
      setConfirmModal({
        ...confirmModal,
        show: false,
        loading: false,
      });
    },
    onError: (err) => {
      toast.error(err.message);
      setConfirmModal({
        ...confirmModal,
        show: false,
        loading: false,
      });
    }
  });

  //刪除清單
  const handleRemove = (id: string) => {
    setConfirmModal({
      ...confirmModal,
      show: true,
      handleConfirm: () => {
        setConfirmModal((prev) => {
          return {
            ...prev,
            loading: true,
          };
        });
        deletePrinciple(id);
      },
    });
  };

  useEffect(() => {
    setModal((prev) => ({
      ...prev,
      handleClose: () => {
        setForm({
            name: null,
            isSasbIndustry: null,
            indicatorPrincipleMatrix: null,
            indicatorMarketId: params.id!
        });
        setModal((prev) => ({
          ...prev,
          show: false,
        }));
      },
      handleConfirm: () => {
        console.log(editForm);
        if(editForm.isSasbIndustry === null ||
          editForm.name === null ||
          editForm.name === '') {
            toast.error('欄位必填');
        } else {
          addPrincipleMatrix(editForm);
        }
      },
    }));
  }, [modal.show, editForm]);

  useEffect(() => {
    getMatrixData();
  }, []);


  //設定值
  /*const handleValue = (value: string, indicatorId: string, principleId: string, isSasb:boolean, sasbId?: string| null) => {
    let tmpData = [...dynamicData];
    tmpData?.forEach((indicator:PrincipleMatrixRightModel, i) => {
        if(indicator.indicatorId === indicatorId) {
          indicator.subItem.forEach((principle: PrincipleMatrixSubItemRightModel, pi) => {
            if(principle.principleId === principleId) {
              if(isSasb === true) {
                principle.lastItem.forEach((sasb: PrincipleMatrixLastItemRightModel, si) => {
                  if(sasb.sasbId === sasbId) {
                    sasb.value = value;
                  }
                })
              } else {
                principle.lastItem[0].value = value;
              }
            }
          });
        }
    });
    //console.log(tmpData);
    setSaveData({leftMatrix: saveLeftData.current, rightMatrix: tmpData});
  }*/

  // 儲存矩陣
  const save = () => {

    savePrincipleMatrix({leftMatrix: saveLeftData.current, rightMatrix: dynamicData, indicatorMarketId: params.id});
  }
  const {mutate: savePrincipleMatrix, isPending: saveIsPending} =
    useMutation({
      mutationFn: (data: any) =>
      PrincipleMatrixService.saveMatrixData(data),
      onSuccess: (res) => {
        if (res.code === 200 && res.success) {
          getMatrixData();
          toast.success('儲存成功');
          setIsEdit(false);
        } else {
          toast.error(res.message);
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });


  //下載範例
  const LoadImportXlsMutation = useMutation({
    mutationFn: PrincipleMatrixService.loadImportXls,
  });

  // 取得範例
  const loadSampleXls = () => {
      LoadImportXlsMutation
      .mutateAsync(params.id)
      .then(res=>{
        if (res.status !== ResponseCode.ServerErrorInternal) {
          setImportModal((prev) => {
            return {
                ...prev,
                show: !prev.show,
                sampleDownloadStream: res
            };
          });
        }else{
            error2Alert(res.data.message);
        }
      })
      .catch((err) => {
          toast.error(err);
          console.log(err);
      });
  };

  //上傳excel api
  const uploadMutation = useMutation({
      mutationFn: PrincipleMatrixService.importMatrix,
  });

  //上傳事件
  const uploadFile = (files: any[]) => {
      const postData = new FormData();
      for (const file of files) {
          postData.append("file", file);
      }
      setImportModal((prev)=>{
          return{
          ...prev,
          uploadLoading: true
          };
      })


      uploadMutation.mutateAsync({
          file: postData,
          indicatorMarketId: params.id
      }).then((x) => {

          if (x.success) {
              setImportModal((prev)=>{
                  return{
                  ...prev,
                  uploadLoading: false,
                  show:false
                  };
              })
              if(x.success === true) {
                getMatrixData();
              }
              toast.success(x.message);
          } else {
              setImportModal((prev)=>{
                  return{
                  ...prev,
                  uploadLoading: false
                  };
              })
              toast.error(x.message);
          }
      })
      .catch((err) => {

          console.log(err);
          toast.error(err);
      });
  };

  const filterSasbtTitle = (sasbIds: string[], principleId: string) => {
    console.log(sasbIds);
    console.log(principleId);
    const tmpTitle = [...dynamicDataTitle];
    tmpTitle.forEach((t: PrincipleMatrixSubItemRightModel) => {
      if(t.principleId === principleId) {
        t.lastItem.forEach((sasb: PrincipleMatrixLastItemRightModel) => {
          if(sasbIds.find(x=>x === sasb.sasbId)) {
            sasb.isHide = false;
          } else {
            sasb.isHide = true;
          }
        })
      }
    })

    const tmpData = [...dynamicData];
    tmpData.forEach((t: PrincipleMatrixRightModel) => {
      t.subItem.forEach((sub: PrincipleMatrixSubItemRightModel) => {
        if(sub.principleId === principleId) {
          sub.lastItem.forEach((sasb: PrincipleMatrixLastItemRightModel) => {
            if(sasbIds.find(x=>x === sasb.sasbId)) {
              sasb.isHide = false;
            } else {
              sasb.isHide = true;
            }
          });
        }
      })
    })

    setDynamicDataTitle(tmpTitle);
    setDynamicData(tmpData);
  }

  return (
    <div style={{maxWidth: 'calc(100vw - 260px)', height: '100%'}}>
        <div className={styles["Edit"]}>
          <div className="title-box">
            <BackTitle title={title + '年'} url={`/admin/principle-matrix`}/>
          </div>
            <div className="tool-box pad-20-t">
              <div className="tool-box" style={{width: '40%'}}></div>
              <div className="tool-box" style={{width: '60%'}}>
              {isEdit ? (
                  <>
                  <div className="icon-button mr-1-m">
                   <button className="secondary" onClick={() => {setIsEdit(false);getMatrixData();}}>
                      取消
                   </button>
                  </div>
                  {detectEveryActionPermission([
                SustainMatrixManageActionsPermissionCodes.MatrixManageUpdate,
              ]) ? (
                <div className="icon-button">
                    <button className="default" onClick={() => save()}>
                        儲存
                    </button>
                  </div>
              ) : null}

                  </>
                  ):(<>
                             {detectEveryActionPermission([
                SustainMatrixManageActionsPermissionCodes.MatrixManageImport,
              ]) ? (
                <div className="icon-button mr-1-m" style={{paddingTop: '10px'}}>
                    <LinkIconButton
                          imgName="batch-import-icon.svg"
                          text="批次匯入"
                          onClick={()=>{
                            loadSampleXls();
                          }}
                      />
                      <BatchUploadModal {...importModal} />
                  </div>
              ) : null}

                  <div className="icon-button mr-1-m">
                      <button className="secondary" onClick={() => {setIsLoading(true);setTimeout(()=> {setIsEdit(true); setIsLoading(false)}, 200)}}>
                          編輯
                      </button>
                  </div>

                  {detectEveryActionPermission([
                SustainMatrixManageActionsPermissionCodes.MatrixManageUpdate,
              ]) ? (
                <div className="icon-button">
                <IconButton
                    imgName="add-bg-icon.svg"
                    text="新增準則"
                    onClick={()=>{
                        setModal((prev) => ({
                            ...prev,
                            show: true,
                          }));
                          setForm({
                            name: null,
                            isSasbIndustry: null,
                            indicatorPrincipleMatrix: null,
                            indicatorMarketId: params.id!
                        });
                    }}
                    className=""
                />
            </div>
              ) : null}

                  </>
                  )
              }
              </div>
            </div>

            {listIsPending || isLoading || saveIsPending || deleteIsPending || addIsPending ? <VisuallLoading /> : <></>}
            {/** 主表區 **/}
            <div className="result-box scroll">
              {/**左方固定title */}
              <div className="fix-table-box standardTable-block">
                <table
                  aria-label="查詢結果table"
                  className="table-container sticky-table"
                  style={{width: '440px'}}
                  >
                  <thead>
                      <tr>
                          <th scope="col" style={{lineHeight: (hasSasb ? '60px' : '')}}>分類</th>
                          <th scope="col" style={{lineHeight: (hasSasb ? '60px' : '')}}>ESG主題</th>
                          <th scope="col" style={{lineHeight: (hasSasb ? '60px' : '')}}>項次</th>
                          <th scope="col" style={{lineHeight: (hasSasb ? '60px' : '')}}>ESG指標</th>
                      </tr>
                  </thead>
                  <tbody>

                  { !leftData ? <></> :
                        leftData.map((left, i) => {
                          return (
                            <tr key={`tr_main_${i}`}>
                              {left.isLevelOne ?
                                <td rowSpan={left.categoryRowspan!}>{left.categoryName}</td>
                              : <></>}
                              {left.isLevelTwo ?
                                <td rowSpan={left.subjectNameRowspan!}>
                                  {left.subjectNameRowspan === 1 ? <div className="fixIndicator" title={left.subjectName!}>{left.subjectName}</div> :
                                  <div>{left.subjectName}</div>}
                              </td>
                              : <></>}
                              <td>{left.sortIndex}</td>
                              <td>
                                <div className="fixIndicator" title={left.indicatorName!}>{left.indicatorName}</div>
                              </td>
                            </tr>
                          )
                      })
                  }

                  </tbody>
                </table>
              </div>
              {/**右方卷軸資料 */}
              <div className="standardTable-block2">
                  {
                    dynamicDataTitle?.length === 0 ? (<div style={{marginLeft: '12px'}}>尚未新增準則</div>) :

                    <table
                    key='table2'
                    aria-label="查詢結果table"
                    className="table-container sticky-table"
                    >
                      <thead>
                        <tr>
                        {dynamicDataTitle?.map((title, ti) => {
                          if(title.isSasb === false) { /**一般title */
                            return (
                              <th key={`th_${ti}_two`} scope="col" style={{lineHeight: (hasSasb ? '60px' : '')}} rowSpan={hasSasb? 2: 1}>
                                <div className="d-flex" style={{justifyContent:'center'}}>
                                  <div>{title.principleName}</div>
                                  <LinkIconButton
                                    imgName="trash-icon.svg"
                                    text=""
                                    onClick={()=>{
                                      handleRemove(title.principleId);
                                    }}
                                  />
                                </div>
                              </th>
                            )
                          } else { /**sasb */
                            return (
                              <th key={`th_${ti}_three`} colSpan={title.lastItem.filter(x=>x.isHide===false).length}>
                                <div className="d-flex" style={{justifyContent:'center'}}>
                                  <div>{title.principleName}</div>
                                  <LinkIconButton
                                    imgName="trash-icon.svg"
                                    text=""
                                    onClick={()=>{
                                      handleRemove(title.principleId);
                                    }}
                                  />
                                  <MultiSelect
                                    placeholder={""}
                                    isFloatTitle={false}
                                    isOnlyIcon={true}
                                    options={title.filterTitles}
                                    defaultIds={title.filterTitles
                                              ?.filter((p) => p.checked === true)
                                              .map((p) => p.id)}
                                    onChange={(e)=>{filterSasbtTitle(e, title.principleId)}}
                                  />
                                </div>
                              </th>
                            )
                          }
                        })}
                        </tr>
                          {/** SASB 子title */}
                          {hasSasb &&
                          <tr key="tr3">
                            {dynamicDataTitle?.map((sasbSub, si) => {
                                return(sasbSub.isSasb &&
                                  sasbSub.lastItem.map((itt, tti) => {
                                    return (itt.isHide === false &&
                                      <th key={`th_${si}_${tti}_four`} style={{top: '40px'}}>{itt.sasbName}</th>
                                    )
                                  })
                                )
                              })
                            }
                          </tr>}

                      </thead>
                      <tbody>

                      { !leftData ? <></> :
                          leftData.map((left, i) => {
                              return (
                                <tr key={`tr_${i}_left`}>
                                  {
                                    !dynamicData ? <></> :
                                    dynamicData.map((val, vi) => {
                                      if(left.indicatorId === val.indicatorId) {
                                      return (
                                        val.subItem.map((sub, si) => {
                                          return (
                                            sub.lastItem.map((lait, li) => {
                                              return (
                                                !isEdit ?
                                                  (
                                                    lait.isHide===false &&
                                                    <td key={li} title={lait.value!}>
                                                      <div className="fixIndicator">{lait.value}</div>
                                                    </td>
                                                  )
                                                  :
                                                  (
                                                    lait.isHide===false &&
                                                    <td key={li}>
                                                      <div style={{height: '40px'}}>
                                                        <NormalInput
                                                          isFloatTitle={false}
                                                          placeholder={''}
                                                          defaultValue={lait.value}
                                                          onChange={(e)=>{
                                                            lait.value = e;
                                                            //handleValue(e, val.indicatorId, sub.principleId, sub.isSasb, lait.sasbId)
                                                          }}
                                                        />
                                                      </div>
                                                    </td>
                                                  )
                                              )
                                          }))
                                        })
                                      )}
                                    })
                                  }
                                </tr>
                              )
                          })}

                        </tbody>
                    </table>
                  }

              </div>
            </div>
        </div>

        <ConfirmModal {...modal}>
          <div className="row">
            <div className="col-md-4">準則名稱</div>
            <div className="col-md-8">
              <NormalInput
                isFloatTitle={false}
                placeholder={''}
                defaultValue={null}
                onChange={(e)=>{
                    let tmp = {...editForm};
                    tmp.name = e;
                    setForm(tmp);
                }}
            />
            </div>
            <div className="col-md-4">SASB行業別</div>
            <div className="col-md-8">
              <div className="d-flex">
                <label className="custom-radio">
                  <input type="radio" name="radio" onChange={()=>{
                    let tmp = {...editForm};
                    tmp.isSasbIndustry = true;
                    setForm(tmp);
                  }} />
                  <span>是</span>
                </label>
                <label className="custom-radio">
                  <input type="radio" name="radio" onChange={()=>{
                    let tmp = {...editForm};
                    tmp.isSasbIndustry = false;
                    setForm(tmp);
                  }}/>
                  <span>否</span>
                </label>
              </div>
            </div>
          </div>
        </ConfirmModal>

        {/* 確認刪除modal */}
        <ConfirmModal {...confirmModal}>
          <div>是否刪除準則?</div>
        </ConfirmModal>
    </div>

  );
};

export default Edit;

