import { AddEduResourceDirectoryModel, EditEduResourceDirectoryModel, EditEduResourceFileModel, EditEduResourceFileStatusModel } from "models/admin/eduPropagandaManageModel";
import { ListRequestModel } from "models/baseModel";
import { HttpClient } from "services/httpClient";

const httpClient = new HttpClient();

//#region 教育宣導類型

/** 類型(目錄)管理列表 */
const getEduResourceDirectoryList = (
     model:ListRequestModel
) => {
    return httpClient.post(`EduResourceDirectory/list`, model);
};

/**  新增類型(目錄)管理列表 */
const addEduResourceDirectoryList = (
    model:AddEduResourceDirectoryModel
) => {
   return httpClient.post(`EduResourceDirectory/add`, model);
};

/**  編輯類型(目錄)管理列表*/
const editEduResourceDirectoryList = (
  model:EditEduResourceDirectoryModel
) => {
   return httpClient.post(`EduResourceDirectory/${model.id}`, model);
};

/**  刪除類型(目錄)管理列表*/
const deleteEduResourceDirectoryList = (
    ids:string[]
 ) => {
    return httpClient.post(`EduResourceDirectory/delete`, ids);
 };

//#endregion

//#region 檔案管理

/** 檔案管理列表 */
const getEduResourceFileList = (
   model:ListRequestModel
) => {
   return httpClient.post(`EduResourceFile/list`, model);
};

/**  取得單一檔案資料(編輯頁面使用) */
const getEduResourceFile = (
   id:string
) => {
   return httpClient.post(`EduResourceFile/detail?id=${id}`);
};

/**  新增檔案*/
const addEduResourceFile = (
   formData:any
) => {
   return httpClient.post(`EduResourceFile/add`, formData);
};


/** 編輯檔案 */
const editEduResourceFile = (
   model:EditEduResourceFileModel
) => {
   return httpClient.post(`EduResourceFile/${model.fileId}`, model);
};

/** 刪除檔案 */
const deleteEduResourceFiles = (
   ids: string[]
) => {
   return httpClient.post(`EduResourceFile/delete`, ids);
};

/** 檔案類型選單 */
const getEduResourceDirectoryOption = (
) => {
   return httpClient.get(`EduResourceFile/eduResourceDirectoryOption`);
};

/** 系統功能按鈕選單 */
const getSystemManageOption = (
) => {
   return httpClient.get(`EduResourceFile/systemManageOption`);
};

/** 更新檔案啟用狀態 */
const editEduResourceFileStatus = (
   model: EditEduResourceFileStatusModel
) => {
   return httpClient.post(`EduResourceFile/status/${model.id}`, model);
};

/** 教育宣傳資源 檔案下載 */
const getEduPropagandaResourceFileStream = (
   fileId: string
) => {
   return httpClient.getBlob(`EduResourceFile/list/FileStream?fileId=${fileId}`);
};

//#endregion


const EduPropagandaManageService = {
    getEduResourceDirectoryList,
    addEduResourceDirectoryList,
    editEduResourceDirectoryList,
    deleteEduResourceDirectoryList,
    getEduResourceFileList,
    getEduResourceFile,
    addEduResourceFile,
    editEduResourceFile,
    deleteEduResourceFiles,
    getEduResourceDirectoryOption,
    getSystemManageOption,
    editEduResourceFileStatus,
    getEduPropagandaResourceFileStream
  };
  
  export default EduPropagandaManageService;
  