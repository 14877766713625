import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./IndividualCompany.module.scss";
import LinkIconButton from "../../../../components/button/LinkIconButton/LinkIconButton";
import { useMutation } from "@tanstack/react-query";
import mopsEsgService from "services/inquiry/infoDisclosure/mopsEsgService";
import { MarketTypeEnum, OptionModel, RequestType } from "models/baseModel";
import {
  CompanyOptionModel,
  EsgMatrixDiagramModel,
  MopsAllCompanyDataModel,
  MopsControlValSectionDetail,
  MopsDelareItemTreeDetailModel,
  MopsDelareItemTreeModel,
  MopsSingleCompanyDataRequestModel,
  MopsSingleCompanyDataResponseModel,
} from "models/inquiry/infoDisclosure/mopsEsgModel";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import useSearchParamStore from "state/useSearchParamStore";
import EmptySearchPage, {
  EmptySearchPageProps,
} from "components/otherModule/EmptySearchPage/EmptySearchPage";
import { CommonService } from "services/common/commonService";
import { CSVLink } from "react-csv";
import { Link, useSearchParams } from "react-router-dom";
import { ResponseCode } from "models/responseCodeModel";
import { IndividualResultModel } from "models/localSearchResultModel";
import Dropdown from "components/base/Dropdown/Dropdown";
import { error2Alert, successAlert } from "utils/otherToast";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import { SystemManageType } from "models/admin/systemManageModel";
import ProblemReportModal, {
  ProblemReportModalProps,
} from "components/otherModule/ProblemReportModal/ProblemReportModal";
import { MopsCompanyDataControlDetailModel } from "models/samples/companySampleDataModel/companySampleDataModel";
import { PrintService } from "services/common/printService";
import { submitGAEvent } from "services/common/GA";
import { useTranslation } from "react-i18next";
import { PrintElementType } from "models/common/printElementModel";
import { SampleControlCode } from "models/samples/sampleControlCode";
import { Category } from "models/admin/indicatorModel";

//#region  TreeComponent
interface TreeComponentProps {
  trees: MopsDelareItemTreeModel[];
  itemClick: (param: MopsDelareItemTreeDetailModel | null) => void;
}
// 樹狀資訊
const TreeComponent: FC<TreeComponentProps> = (props) => {
  const { trees } = props;
  const images = ["global", "social", "governance"];
  // 選擇樹狀節點
  const [selItem, setSelItem] = useState<MopsDelareItemTreeDetailModel>();
  const { t } = useTranslation();
  // 點選item
  const clickTreeItem = (item: MopsDelareItemTreeDetailModel) => {
    setSelItem({ ...item });
    props.itemClick(item);
  };

  // 預設給一筆
  useEffect(() => {
    if (trees) {
      if (trees.length > 0) {
        let pass = false;
        // 預選第一筆
        trees.forEach((p) => {
          p.items?.forEach((item) => {
            if (!pass) {
              pass = true;
              setSelItem(item);
              props.itemClick(item);
            }
          });
        });
      }
    }
  }, [trees]);
  return (
    <>
      {trees?.map((category, index) => (
        <div key={category.category}>
          <div className={"tree-title"}>
            <img
              alt={t("SEARCH")}
              src={`/assets/images/declaration/application/${
                images[category.category]
              }-icon.svg`}
            />
            <div>{category.categoryString}</div>
          </div>
          <ul>
            {category.items?.map(
              (item: MopsDelareItemTreeDetailModel, itemIndex: number) => {
                return (
                  <li
                    key={itemIndex}
                    className={
                      item.declareItemName === selItem?.declareItemName
                        ? "active"
                        : ""
                    }
                    onClick={($event) => {
                      clickTreeItem(item);
                    }}
                  >
                    <div>{item.declareItemName}</div>
                  </li>
                );
              }
            )}
          </ul>
        </div>
      ))}
    </>
  );
};
//#endregion

//#region FilterComponent
interface FilterProps {
  searchChange: (param: MopsSingleCompanyDataRequestModel) => void;
  /** 預設param */
  defaultParam: MopsSingleCompanyDataRequestModel;
  yearOption: OptionModel[];
  companyOption: OptionModel[];
}
const FilterComponent: FC<FilterProps> = (props) => {
  const { defaultParam, yearOption, companyOption } = props;
  const [param, setParam] =
    useState<MopsSingleCompanyDataRequestModel>(defaultParam);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const { t } = useTranslation();
  useEffect(() => {
    if (defaultParam.companyCode || defaultParam.year !== null) {
      setParam({
        ...param,
        ...defaultParam,
      });
    }
  }, [defaultParam]);

  useEffect(() => {
    // 不存在則預設空
    if (yearOption && yearOption.length > 0) {
      if (defaultParam.year !== null) {
        const exist = yearOption.some(
          (p) => p.id === defaultParam.year?.toString()
        );
        if (!exist) {
          setParam({
            ...param,
            year: null,
            yearList: [],
          });
        }
      }
    }
  }, [yearOption]);

  useEffect(() => {
    // 不存在則預設空
    if (companyOption && companyOption.length > 0) {
      if (defaultParam.companyCode) {
        const com = companyOption.find(
          (p) => p.id === defaultParam.companyCode
        );
        if (!com) {
          setParam({
            ...param,
            companyCode: null,
            companyName: null,
          });
        } else {
          setParam({
            ...param,
            companyName: com.text,
          });
        }
      }
    }
  }, [companyOption]);

  // 改變年度
  const chagneYear = (e: string | null) => {
    if (e) {
      const year = Number.parseInt(e);
      setParam({
        ...param,
        yearList: [year],
        year: year,
      });
    } else {
      setParam({
        ...param,
        year: null,
        yearList: [],
      });
    }
  };

  //#region diabled button
  useEffect(() => {
    if (param) {
      disabledCondition();
    }
  }, [param]);

  const disabledCondition = () => {
    if (param.companyCode && param.year !== null) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };
  //#endregion

  // 輸出參數
  const handleChange = () => {
    // 包含code的部分
    param.companyName = null;
    props.searchChange(param);
  };

  return (
    <>
      <div className="mt-3">
        <Dropdown
          placeholder={`${t("COMPANY_CODE_AND_STOCK_CODE")}*`}
          isFloatTitle={true}
          options={companyOption}
          defaultId={param.companyCode}
          onChange={(e) => {
            let selCom = companyOption.find((p) => p.id === e);
            setParam({
              ...param,
              companyCode: e,
              companyName: selCom?.text,
            });
          }}
        />
      </div>
      <div className="mt-3">
        <Dropdown
          placeholder={`${t("REPORTING_YEAR")}*`}
          isFloatTitle={true}
          options={yearOption}
          defaultId={param.year?.toString()}
          onChange={(e) => {
            chagneYear(e);
          }}
        />
      </div>
      <div className="mt-3">
        <button
          onClick={handleChange}
          className={`default ` + `${btnDisabled ? "disabled " : ""}`}
        >
          {t("INQUIRY")}
        </button>
      </div>
    </>
  );
};
//#endregion

//#region  TableComponent (E類型)
export interface EIndividualTableProps {
  selItem: MopsDelareItemTreeDetailModel | null;
  data: MopsDelareItemTreeModel[];
  showAll: boolean;
  scrollFirst: React.MutableRefObject<boolean>;
}
const EIndividualTableComponent: FC<EIndividualTableProps> = (props) => {
  const { selItem, data, showAll } = props;
  const [realData, setRealData] = useState<MopsDelareItemTreeModel[]>([]);
  // 基礎colspan
  const [baseColSpan, setBaseColSpan] = useState<number>(5);
  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      setTableRows();
    }
  }, [data, showAll]);

  useEffect(() => {
    if (selItem) {
      scrollToElement();
      if (selItem.year && selItem.year <= 2022) {
        setBaseColSpan(4);
      } else {
        setBaseColSpan(5);
      }
    }
  }, [selItem]);

  /** 設定tableRows */
  const setTableRows = () => {
    const tmpData = CommonService.deepClone(data);
    tmpData.forEach((category) => {
      category.items?.forEach((item) => {
        item.sections?.forEach((p, index) => {
          if (!showAll) {
            p.controls =
              p.controls?.filter((control) => control.value !== null) || [];
          }
        });

        // 沒有control section 也拿掉
        item.sections =
          item.sections?.filter((p) => p.controls?.length !== 0) || [];

        // 取得form row
        item.rowspan = getFormRowSpan(item);
        // 先隱藏完，才能算row
        item.sections?.forEach((p, index) => {
          p.rowspan = getSectionRowSpan(p);
        });
      });
    });

    setRealData([...tmpData]);
  };

  /** 取得表單 rowspan */
  const getFormRowSpan = (item: MopsDelareItemTreeDetailModel): number => {
    let count = 0;
    item.sections?.forEach((p) => {
      const rowLength = p.controls?.length;
      // 給一格空的
      if (rowLength === 0) {
        count++;
      } else {
        count += rowLength || 0;
      }
    });
    return count;
  };

  /** 取得section rowspan */
  const getSectionRowSpan = (item: MopsControlValSectionDetail): number => {
    let count = 0;
    if (item.controls) {
      const rowLength = item.controls.length;
      // 給一格空的
      if (rowLength === 0) {
        count++;
      } else {
        count += rowLength;
      }
    }
    return count;
  };

  // 滾動到指定地方
  const scrollToElement = () => {
    props.scrollFirst.current = true;
    if (selItem) {
      const divs = document.querySelectorAll(
        `[data-tr-key='${selItem.category}_${selItem.declareItemName}']`
      );
      if (divs.length > 0) {
        const element = divs[0];

        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    }
  };

  // 取得控制項值component
  const getControlValue = (control: MopsCompanyDataControlDetailModel) => {
    if (control.value) {
      // 利害人關係連結直接顯示連結
      if (
        control.code ===
        SampleControlCode.StakeholderOrCorporateGovernanceAreaLink
      ) {
        return LinkComponent(control.value, control.value);
      }
      if (CommonService.isURL(control.value)) {
        let text = t("DETAILS");
        // 利害人關係連結直接顯示連結
        if (
          control.code ===
          SampleControlCode.StakeholderOrCorporateGovernanceAreaLink
        ) {
          text = control.value;
        }
        return LinkComponent(control.value, text);
      } else {
        return control.value;
      }
    } else {
      return "N/A";
    }
  };

  /** link元件 */
  const LinkComponent = (value: string, text: string) => {
    return (
      <Link
        className={"detail-link"}
        target="_blank"
        rel="noopener noreferrer"
        to={value}
      >
        {text}
      </Link>
    );
  };

  //#region 取得特殊表格 column
  /** 取得欄位內的內容，避免空值 */
  const getSpecialColumnValue = (
    section: MopsControlValSectionDetail,
    index: number
  ) => {
    if (section.controls && index < section.controls.length) {
      return section.controls[index].value;
    }

    return "";
  };

  /** 取得特殊欄位 td 合併，如無數據 */
  const getSpecialColumnByNoData = (
    itemIndex: number,
    sectionIndex: number,
    section: MopsControlValSectionDetail,
    colspan: number
  ) => {
    if (section.controls && section.controls.length > 0) {
      if (section.controls[0].isNoChecking === true) {
        // 無資料直接合併對應資料
        return <td colSpan={colspan}>{section.controls[0].value}</td>;
      } else {
        let colArrs = [];
        // 不是無資料不需要合併
        for (let i = 0; i < colspan; i++) {
          colArrs.push(i);
        }
        return colArrs.map((colIndex) => {
          if (section.controls && section.controls[colIndex]) {
            let control = section.controls[colIndex];
            return (
              <td
                key={`${itemIndex}_${sectionIndex}_${colIndex}`}
                className="avg-td text-left"
              >
                {getControlValue(control)}
              </td>
            );
          } else {
            return <></>;
          }
        });
      }
    }
    return <></>;
  };

  /** 產生新元件 */
  const newSpecialColumn = (
    itemIndex: number,
    sectionIndex: number,
    section: MopsControlValSectionDetail
  ) => {
    /* 空白section 合併column 依據不同類型回傳不相同的合併類型 */
    if (section.controls && section.controls.length > 0) {
      //#region 2022 (舊年度資料特殊欄位)
      if (selItem && selItem?.year <= 2022) {
        switch (section.name) {
          case "有害廢棄物 (公噸)":
          case "非有害廢棄物 (公噸)":
          case "用水密集度單位":
            return (
              <>
                {getSpecialColumnByNoData(itemIndex, sectionIndex, section, 1)}
                <td colSpan={baseColSpan - 1} className="no-data-td"></td>
              </>
            );
          case "溫室氣體排放密集度":
            return (
              <>
                {getSpecialColumnByNoData(itemIndex, sectionIndex, section, 2)}
                <td colSpan={baseColSpan - 2} className="no-data-td"></td>
              </>
            );
          case "再生能源使用率":
            return getSpecialColumnByNoData(
              itemIndex,
              sectionIndex,
              section,
              baseColSpan
            );
        }
      }
      //#endregion
      switch (section.name) {
        case "溫室氣體管理之策略、方法、目標":
        case "董事會與管理階層對於氣候相關風險與機會之監督及治理":
        case "辨識之氣候風險與機會如何影響企業之業務、策略及財務 (短期、中期、長期)":
        case "極端氣候事件及轉型行動對財務之影響":
        case "氣候風險之辨識、評估及管理流程如何整合於整體風險管理制度":
        case "若使用情境分析評估面對氣候變遷風險之韌性，應說明所使用之情境、參數、假設、分析因子及主要財務影響。":
        case "若有因應管理氣候相關風險之轉型計畫，說明該計畫內容，及用於辨識及管理實體風險及轉型風險之指標與目標。":
        case "若使用內部碳定價作為規劃工具，應說明價格制定基礎。":
        case "若有設定氣候相關目標，應說明所涵蓋之活動、溫室氣體排放範疇、規劃期程，每年達成進度等資訊；若使用碳抵換或再生能源憑證(RECs)以達成相關目標，應說明所抵換之減碳額度來源及數量或再生能源憑證(RECs)數量。":
        case "提升能源使用效率政策":
        case "使用再生物料政策":
        case "水資源管理或減量目標":
        case "廢棄物管理政策或減量目標":
          // 合併所有 col
          return (
            <td className="text-left" colSpan={baseColSpan}>
              {getSpecialColumnValue(section, 0)}
            </td>
          );
        case "溫室氣體排放密集度單位":
        case "消耗能源總量(十億焦耳(GJ))":
        case "外購電力百分比":
        case "自發自用能源總量(十億焦耳(GJ))":
        case "用水密集度":
        case "密集度單位":
        case "總取水量(千立方公尺)":
        case "總耗水量(千立方公尺)":
        case "依法規要求或自願揭露之廢(污)水排放量(千立方公尺)":
        case "有害廢棄物(公噸(t))":
        case "非有害廢棄物(公噸(t))":
        case "廢棄物密集度":
        case "產品生產過程所製造之有害廢棄物回收百分比":
        case "有害廢棄物之百分比":
        case "產品生命週期管理之揭露：報廢產品及電子廢棄物之重量(公噸(t))":
        case "產品生命週期管理之揭露：含報廢產品及電子廢棄物之再循環之百分比":
        case "消耗燃料總量(十億焦耳(GJ))":
        case "煤炭百分比":
        case "天然氣百分比":
        case "再生燃料百分比":
        case "溫室氣體排放密集度(自願申報單位)":
          return (
            <>
              {getSpecialColumnByNoData(itemIndex, sectionIndex, section, 1)}
              <td colSpan={baseColSpan - 1} className="no-data-td"></td>
            </>
          );
        case "溫室氣體排放密集度(自願申報)":
        case "溫室氣體排放密集度(公噸CO₂e/百萬元營業額)":
          return (
            <>
              {getSpecialColumnByNoData(itemIndex, sectionIndex, section, 2)}
              <td colSpan={baseColSpan - 2} className="no-data-td"></td>
            </>
          );
        case "再生能源使用率":
        case "用水量(公噸(t))":
        case "總重量(有害+非有害)(公噸(t))":
          return (
            <>
              {getSpecialColumnByNoData(
                itemIndex,
                sectionIndex,
                section,
                baseColSpan - 1
              )}
              <td className="no-data-td"></td>
            </>
          );
        default:
          return getSpecialColumnByNoData(
            itemIndex,
            sectionIndex,
            section,
            baseColSpan
          );
      }
    }
  };
  //#endregion

  return (
    <div id="individual-table-box1" className="table-box">
      <table
        aria-label={t("SEARCH_RESULT")}
        className="table-container sticky-table inquiry-table table-layout-fixed"
      >
        <thead>
          <tr>
            <th className="w120-p" scope="rowgroup" rowSpan={2}>
              {t("ISSUE")}
            </th>
            <th className="w200-p" scope="rowgroup" rowSpan={2}>
              {t("INDICATOR")}
            </th>
            <th scope="colgroup" colSpan={baseColSpan}>
              {selItem?.year}
              {t("YEAR")}
            </th>
          </tr>
          {selItem && selItem?.year >= 2023 && (
            <tr>
              <th>數據</th>
              <th>資料邊界</th>
              <th>確信機構</th>
              <th>確信標準</th>
              <th>確信範圍</th>
            </tr>
          )}
          {selItem && selItem?.year <= 2022 && (
            <tr>
              <th>數據</th>
              <th>資料範圍</th>
              <th>驗證機構</th>
              <th>驗證標準</th>
            </tr>
          )}
        </thead>
        <tbody>
          {realData &&
            realData?.map((cateogry) => {
              return cateogry.items?.map((item, itemIndex) => {
                // 段落
                return item.sections?.map((section, sectionIndex) => {
                  return (
                    <tr
                      id={`${item.category}_${item.declareItemName}`}
                      data-tr-key={`${item.category}_${item.declareItemName}`}
                      key={`${item.category}_${itemIndex}_${sectionIndex}`}
                    >
                      {/* 表單 row合併 */}
                      {sectionIndex === 0 && (
                        <td className="w120-p" rowSpan={item.sections?.length}>
                          {item.declareItemName}
                        </td>
                      )}
                      <td className="w150-p text-left">{section.name}</td>
                      {newSpecialColumn(itemIndex, sectionIndex, section)}
                    </tr>
                  );
                });
              });
            })}
        </tbody>
      </table>
    </div>
  );
};
//#endregion

//#region  TableComponent (S、G類型)
export interface IndividualTableProps {
  selItem?: MopsDelareItemTreeDetailModel | null;
  data: MopsDelareItemTreeModel[];
  showAll: boolean;
  scrollFirst: React.MutableRefObject<boolean>;
}
const IndividualTableComponent: FC<IndividualTableProps> = (props) => {
  const { selItem, data, showAll } = props;
  const [realData, setRealData] = useState<MopsDelareItemTreeModel[]>([]);

  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      setTableRows();
    }
  }, [data, showAll]);

  useEffect(() => {
    if (selItem) {
      scrollToElement();
    }
  }, [selItem]);

  /** 設定tableRows */
  const setTableRows = () => {
    const tmpData = CommonService.deepClone(data);
    tmpData.forEach((category) => {
      category.items?.forEach((item) => {
        item.sections?.forEach((p, index) => {
          if (!showAll) {
            p.controls =
              p.controls?.filter((control) => control.value !== null) || [];
          }
        });

        // 沒有control section 也拿掉
        item.sections =
          item.sections?.filter((p) => p.controls?.length !== 0) || [];

        // 取得form row
        item.rowspan = getFormRowSpan(item);
        // 先隱藏完，才能算row
        item.sections?.forEach((p, index) => {
          p.rowspan = getSectionRowSpan(p);
        });
      });
    });

    setRealData([...tmpData]);
  };

  /** 取得表單 rowspan */
  const getFormRowSpan = (item: MopsDelareItemTreeDetailModel): number => {
    let count = 0;
    item.sections?.forEach((p) => {
      const rowLength = p.controls?.length;
      // 給一格空的
      if (rowLength === 0) {
        count++;
      } else {
        count += rowLength || 0;
      }
    });
    return count;
  };

  /** 取得section rowspan */
  const getSectionRowSpan = (item: MopsControlValSectionDetail): number => {
    let count = 0;
    if (item.controls) {
      const rowLength = item.controls.length;
      // 給一格空的
      if (rowLength === 0) {
        count++;
      } else {
        count += rowLength;
      }
    }
    return count;
  };

  // 滾動到指定地方
  const scrollToElement = () => {
    props.scrollFirst.current = true;
    if (selItem) {
      const divs = document.querySelectorAll(
        `[data-tr-key='${selItem.category}_${selItem.declareItemName}']`
      );
      if (divs.length > 0) {
        const element = divs[0];

        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    }
  };

  // 取得控制項值component
  const getControlValue = (control: MopsCompanyDataControlDetailModel) => {
    if (control.value) {
      // 利害人關係連結直接顯示連結
      if (
        control.code ===
        SampleControlCode.StakeholderOrCorporateGovernanceAreaLink
      ) {
        return LinkComponent(control.value, control.value);
      }
      if (CommonService.isURL(control.value)) {
        let text = t("DETAILS");
        return LinkComponent(control.value, text);
      } else {
        return control.value;
      }
    } else {
      return "N/A";
    }
  };

  /** link元件 */
  const LinkComponent = (value: string, text: string) => {
    return (
      <div
        className="detail-link"
        onClick={(event) => {
          event.preventDefault();
          if (value && value !== "N/A") {
            const realUrl = CommonService.getProtocolUrl(value || "");
            window.open(realUrl, "_blank", "noopener noreferrer");
          }
        }}
      >
        {text}
      </div>
    );
  };

  return (
    <div id="individual-table-box2" className="table-box">
      <table
        aria-label={t("SEARCH_RESULT")}
        className="table-container sticky-table inquiry-table"
      >
        <thead>
          <tr>
            <th className="min-w120-p" scope="rowgroup" rowSpan={2}>
              {t("ISSUE")}
            </th>
            <th scope="rowgroup" rowSpan={2}>
              {t("INDICATOR")}
            </th>
            <th className="min-w120-p" scope="rowgroup" rowSpan={2}>
              {t("PROJECT")}
            </th>
            <th scope="colgroup" colSpan={2}>
              {selItem?.year}
              {t("YEAR")}
            </th>
          </tr>
        </thead>
        <tbody>
          {realData &&
            realData?.map((cateogry) => {
              return cateogry.items?.map((item, itemIndex) => {
                // 段落
                return item.sections?.map((section, sectionIndex) => {
                  // 控制項
                  return section.controls?.map((control, controlIndex) => {
                    return (
                      <tr
                        id={`${item.category}_${item.declareItemName}`}
                        data-tr-key={`${item.category}_${item.declareItemName}`}
                        key={`${sectionIndex}_${itemIndex}_${sectionIndex}_${controlIndex}`}
                      >
                        {/* 表單 row合併 */}
                        {sectionIndex === 0 && controlIndex === 0 && (
                          <td className="w200-p" rowSpan={item.rowspan}>
                            {item.declareItemName}
                          </td>
                        )}
                        {/* section row合併 */}
                        {controlIndex === 0 && !section.isBlankSection && (
                          <td
                            className="min-w200-p w200-p text-left"
                            rowSpan={section.rowspan}
                          >
                            {section.name}
                          </td>
                        )}
                        {/* 空白section 合併column */}
                        <td
                          className="min-w150-p w150-p text-left"
                          colSpan={section.isBlankSection ? 2 : 1}
                        >
                          {control.title}
                        </td>
                        <td className={`desc-col `}>
                          {getControlValue(control)}
                        </td>
                      </tr>
                    );
                  });
                });
              });
            })}
        </tbody>
      </table>
    </div>
  );
};
//#endregion

//#region 主頁面
interface IndividualCompanyProps {}

const IndividualCompany: FC<IndividualCompanyProps> = () => {
  const [defaultParam, setDefaultParam] =
    useState<MopsSingleCompanyDataRequestModel>({
      companyCode: null,
      yearList: [],
      companyName: null,
      year: null,
    });
  // 實際搜尋條件
  const [realParam, setRealParam] = useState<MopsSingleCompanyDataRequestModel>(
    {
      companyCode: null,
      yearList: [],
      companyName: null,
      year: null,
    }
  );
  const { t } = useTranslation();
  const [realTree, setRealTree] = useState<MopsDelareItemTreeModel[]>([]);
  const [selItem, setSelItem] =
    useState<MopsDelareItemTreeDetailModel | null>();
  // 目前一律改接後端一般揭露 && 鼓勵揭露不顯示 (前端不在有所有指標or 申報指標)
  const [showAll, setShowAll] = useState<boolean>(false);
  const [empty, setEmpty] = useState<EmptySearchPageProps>();
  const [hide, setHide] = useState<boolean>(false);
  // 單筆公司資訊
  const [infoData, setInfoData] = useState<MopsSingleCompanyDataResponseModel>({
    companyName: null,
    companyCode: null,
    year: 0,
    treeModels: [],
  });
  // 取得搜尋參數
  const [queryParams, setSearchParams] = useSearchParams();
  const [yearOption, setYearOption] = useState<OptionModel[]>([]);
  const [companyOption, setCompanyOption] = useState<CompanyOptionModel[]>([]);
  const [activeDownloadMatrix, setDownloadMatrix] = useState<boolean>(false);

  // scroll 首次flag
  const scrollFirst = useRef<boolean>(false);

  // 搜尋區塊隱藏
  const handleHide = () => {
    setHide((prev) => (prev = !prev));
  };

  useEffect(() => {
    // 初始化完翻譯再set
    setEmpty({
      word: t("PLEASE_ENTER_SEARCH_CONDITION"),
    });
  }, [t]);

  // table header 鎖定欄位會有一個大約的差距需要scroll回來
  useEffect(() => {
    let scrollTimeout: any;
    const parenTableBox = document.getElementById("resutl-table");
    const scrollEvent = (e: any) => {
      clearTimeout(scrollTimeout);
      // 偵測到移動到定點後
      scrollTimeout = setTimeout(() => {
        if (scrollFirst.current) {
          scrollFirst.current = false;
          // 偏移45 (S、G)  90(E)\
          let moveDis = selItem?.category === Category.Environment ? -90 : -45;
          parenTableBox?.scrollBy({
            left: 0,
            top: moveDis,
            behavior: "smooth",
          });
        }
      }, 100);
    };
    if (parenTableBox) {
      parenTableBox?.addEventListener("scroll", scrollEvent);
    }
    return () => {
      parenTableBox?.removeEventListener("scroll", scrollEvent);
    };
  }, [selItem]);

  //#region  取得單一公司資訊 查詢
  /** (2023 含以後) */
  const getSingleCompanyData = useMutation({
    mutationFn: (model: MopsSingleCompanyDataRequestModel) =>
      mopsEsgService.getSingleCompanyData(model),
    onSuccess: (res) => {
      clearEmpty();
      if (res.code === ResponseCode.SuccessOK && res.success) {
        setTable({
          ...table,
          data: [],
        });
        if (res.data) {
          const data: MopsSingleCompanyDataResponseModel[] = res.data;
          if (data.length > 0) {
            setRealTree([...res.data[0].treeModels]);
            setInfoData({
              ...infoData,
              ...res.data[0],
            });
            setCompanyInfoByCode(res.data[0].companyCode);
          } else {
            setEmpty({
              ...empty,
              word: t("NO_INFORMATION_FOUND"),
              url: null,
            });
          }
        } else {
          // data null url
          setEmpty({
            ...empty,
            word: `${t("OLD_DATA_URL_MESSAGE")}\n${res.message}`,
            url: res.message,
          });
        }
        //- ga event
        submitGAEvent("ESG_InfoDisclosure_Inquiry");
      } else {
        setEmpty({
          ...empty,
          word: res.message,
          url: null,
        });
      }
    },
    onError: (err) => {
      console.log(err);
      clearEmpty();
    },
  });

  /** (2022 年含以前) */
  const getSingleCompanyDataOld = useMutation({
    mutationFn: (model: MopsSingleCompanyDataRequestModel) =>
      mopsEsgService.getSingleCompanyDataOld(model),
    onSuccess: (res) => {
      clearEmpty();
      if (res.code === ResponseCode.SuccessOK && res.success) {
        setTable({
          ...table,
          data: [],
        });
        if (res.data) {
          const data: MopsSingleCompanyDataResponseModel[] = res.data;
          if (data.length > 0) {
            setRealTree([...res.data[0].treeModels]);
            setInfoData({
              ...infoData,
              ...res.data[0],
            });
            setCompanyInfoByCode(res.data[0].companyCode);
          } else {
            setEmpty({
              ...empty,
              word: t("NO_INFORMATION_FOUND"),
              url: null,
            });
          }
        } else {
          // data null url
          setEmpty({
            ...empty,
            word: `${t("OLD_DATA_URL_MESSAGE")}\n${res.message}`,
            url: res.message,
          });
        }
        //- ga event
        submitGAEvent("ESG_InfoDisclosure_Inquiry");
      } else {
        setEmpty({
          ...empty,
          word: res.message,
          url: null,
        });
      }
    },
    onError: (err) => {
      console.log(err);
      clearEmpty();
    },
  });

  // 清除空白
  const clearEmpty = () => {
    setRealTree([]);
    setSelItem(null);
  };
  //#endregion

  //#region table 設置相關

  const [table, setTable] = useState<any>({
    // header: [],
    data: [] as any[],
  });

  // 組合table
  useEffect(() => {
    if (realTree.length > 0) {
      let tmpData: any[] = [];
      realTree.forEach((category) => {
        if (category.category === Category.Environment) {
          //#region 環境設置不一樣
          // title
          tmpData.push([
            `${realParam.year}${t("YEAR")}-${t("INDIVIDUAL_COMPANY_INQUIRY")}`,
          ]);
          let titles: string[] = [t("CATEGORY"), t("ISSUE"), t("INDICATOR"), t("DATAS")]

          if (realParam.year) {
            if (realParam.year <= 2022) {
              titles = [...titles,...["資料範圍", "驗證機構", "驗證標準"]];
            } else {
              titles = [...titles,...[
                t("DATA_BOUNDARY"),
                t("CONVINCED_ORGANIZATION"),
                t("CONVINCED_STANDARD"),
                t("CONVINCED_SCOPE"),
              ]];
            }
          }
          tmpData.push(titles);

          category.items?.forEach((item) => {
            item.sections?.forEach((section, secIndex) => {
              let controlArr: any[] = [];
              controlArr.push(category.categoryString);
              controlArr.push(item.declareItemName);
              controlArr.push(section.name);
              section.controls?.forEach((control, conIndex) => {
                controlArr.push(control.value);
              });
              tmpData.push(controlArr);
            });
          });

          // 隔一行空白
          tmpData.push([]);
          //#endregion
        } else {
          //#region 社會、治理
          tmpData.push([
            t("CATEGORY"),
            t("ISSUE"),
            t("INDICATOR"),
            t("PROJECT"),
            t("DATAS"),
          ]);
          category.items?.forEach((item) => {
            item.sections?.forEach((section, secIndex) => {
              section.controls?.forEach((control, conIndex) => {
                tmpData.push([
                  category.categoryString,
                  item.declareItemName,
                  section.name,
                  control.title,
                  CommonService.sanitizeCSVField(control.value),
                ]);
              });
            });
          });
          //#endregion
        }
      });
      setTable({
        ...table,
        data: [...tmpData],
      });
    }
  }, [realTree, realParam]);
  //#endregion

  //#region  外部帶入參數
  useEffect(() => {
    const year = queryParams.get("year");
    const companyCode = queryParams.get("companyCode");
    if (companyCode && year) {
      let newYear = Number.parseInt(year);
      const newModel = {
        ...defaultParam,
        companyCode: companyCode,
        year: newYear,
        yearList: [newYear],
      };
      setDefaultParam({
        ...newModel,
      });
      setRealParam({
        ...newModel,
      });
      // if (newModel.year >= 2023) {
        getSingleCompanyData.mutate(newModel);
      // } else {
      //   getSingleCompanyDataOld.mutate(newModel);
      // }
    }
  }, [queryParams]);
  //#endregion

  //#region 取得篩選選單

  useEffect(() => {
    getYearList.mutate(RequestType.ESGDisclosure);
    getAllCompanyCode.mutate();
    isActiveFunction.mutate(SystemManageType.MatrixDiagram); //永續準則矩陣下載功能檢查
  }, []);

  const isActiveFunction = useMutation({
    mutationFn: (type: SystemManageType) =>
      SustainReportDeclareService.isActiveNoAuthFunction(type),
    onSuccess: (res, type) => {
      if (res.code === 200) {
        switch (type) {
          case SystemManageType.MatrixDiagram:
            setDownloadMatrix(res.success);
            break;
        }
      }
    },
    onError: (err) => {
      console.log("isActiveFunction", err);
    },
  });

  // 取得報告年度與市場別清單
  const getYearList = useMutation({
    mutationFn: (type: RequestType) => mopsEsgService.getYearList(type),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        const result: number[] = res.data;
        if (result.length > 0) {
          setYearOptionModel(result);
        }
      }
    },
    onError: () => {},
  });

  const setYearOptionModel = (result: number[]) => {
    let op: OptionModel[] = [];
    // 設置option
    result.forEach((p: number) => {
      const year = p.toString();
      op.push({
        id: year,
        text: year,
      });
    });
    setYearOption([...op]);
  };

  // 取得所有公司資料
  const getAllCompanyCode = useMutation({
    mutationFn: () => mopsEsgService.getAllCompanyCode(),
    onSuccess: (res) => {
      if (res.success) {
        let op: CompanyOptionModel[] = [];
        res.data.forEach((item: MopsAllCompanyDataModel) => {
          item.companyCodeModels?.forEach((com) => {
            op.push({
              id: com.companyCode!,
              text: `${com.companyCode!} - ${com.companyName!}`,
              marketType: item.marketType,
              companyName: com.companyName!
            });
          });
        });
        // 選單變新的
        setCompanyOption([...op]);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  //#endregion

  //#region 下載矩陣
  const getMatrixDiagram = useMutation({
    mutationFn: (model: EsgMatrixDiagramModel) =>
      mopsEsgService.getMatrixDiagram(model),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        successAlert(t("DOWNLOAD_SUCCESSFUL"));
        CommonService.downloadByStream(res);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const downLoadMatrix = () => {
    if (realParam.year !== null && realParam.companyCode) {
      getMatrixDiagram.mutate({
        year: realParam.year,
        companyCode: realParam.companyCode,
      });
    } else {
      error2Alert(t("MISSING_YEAR_OR_COMPANY_CODE"));
    }
  };
  //#endregion

  //#region 問題回報modal
  const [problemModal, setProblemModal] = useState<ProblemReportModalProps>({
    show: false,
    handleClose: () => {
      setProblemModal({
        ...problemModal,
        show: false,
      });
    },
    size: "sm",
  });
  //#endregion

  //#region 取得上市櫃文字
  /** 取得市場別字串 */
  const getMarketStr = (): string => {
    let str = "";
    if (realParam.companyCode) {
      let company = companyOption.find((p) => p.id === realParam.companyCode);
      if (company) {
        let name = "";
        switch (company.marketType) {
          case MarketTypeEnum.Listing:
            name = "上市";
            break;
          case MarketTypeEnum.OTC:
            name = "上櫃";
            break;
          case MarketTypeEnum.PO:
            name = "公發";
            break;
          case MarketTypeEnum.Emerging:
            name = "興櫃";
            break;
          default:
            name = "";
        }
        if (name) {
          str = `(${name}公司)`;
        }
      }
    }
    return str;
  };
  //#endregion

  //#region function 相關
  /** 取得公司基本資訊 */
  const setCompanyInfoByCode = (code:string)=>{
    if(code){
      let company = companyOption.find(p=>p.id ===code);
      if(company){
        setRealParam({
          ...realParam,
          companyCode: company.id,
          companyName: company.companyName,
        });
      }
    }
  }
  //#endregion
  return (
    <div className={styles["individual-box"]}>
      {(getSingleCompanyData.isPending ||
        getSingleCompanyDataOld.isPending ||
        getMatrixDiagram.isPending) && <VisuallLoading />}
      {/* 左區塊 */}
      <div className={"left-box " + (hide ? "hide" : "")}>
        <div style={{ display: hide ? "none" : "block", height: "100%" }}>
          <div className="toggle-box">
            <div className="main-title">{t("SEARCH_CONDITION")}</div>
            <img
              alt=""
              src="/assets/images/buttonIcon/toggle-left-icon.svg"
              onClick={handleHide}
            />
          </div>
          <div className="search-item-box">
            <FilterComponent
              defaultParam={defaultParam}
              yearOption={yearOption}
              companyOption={companyOption}
              searchChange={(e) => {
                setRealParam({
                  ...realParam,
                  ...e,
                });
                if (e && e.year !== null) {
                  // if (e.year >= 2023) {
                    getSingleCompanyData.mutate(e);
                  // } else {
                  //   getSingleCompanyDataOld.mutate(e);
                  // }
                }
              }}
            />
          </div>
          <div className={"tree-box scroll mt-20-p"}>
            <TreeComponent
              trees={realTree}
              itemClick={(e) => {
                setSelItem(e);
                if (!e) {
                  setEmpty({
                    ...empty,
                    word: t("NO_INFORMATION_FOUND"),
                  });
                }
              }}
            />
          </div>
        </div>

        {hide && (
          <div className="img-tool">
            <img
              alt=""
              src="/assets/images/buttonIcon/toggle-right-icon.svg"
              onClick={handleHide}
            />
            {/* <img
            alt=""
            src="/assets/images/buttonIcon/filter-icon.svg"
            onClick={handleHide}
          /> */}
          </div>
        )}
      </div>
      {/* 右區塊 */}
      <div className={`right-box ` + `${hide ? "" : "has-filter "}`}>
        {/* 上區塊 */}
        <div className="tool-box">
          <div className="result-title-box">
            <div className="main-title"></div>
            <div className="sub-title">
              {realParam.companyName
                ? `${realParam.companyCode} - ${realParam.companyName}`
                : ""}
            </div>
          </div>
          <div className="tool-item-box">
            {selItem && (
              <>
                <LinkIconButton
                  imgName="print-icon.svg"
                  text={t("PRINT_WEB_PAGE")}
                  onClick={() => {
                    PrintService.openNewWindow(
                      PrintElementType.IndividualTableBox,
                      true
                    );
                  }}
                />
                <LinkIconButton
                  imgName="new-window-icon.svg"
                  text={t("OPEN_NEW_WINDOW")}
                  onClick={() => {
                    PrintService.openNewWindow(
                      PrintElementType.IndividualTableBox,
                      false
                    );
                  }}
                />
              </>
            )}
            <LinkIconButton
              imgName="question-icon.svg"
              text={t("PROBLEM_REPORT")}
              onClick={() => {
                setProblemModal({
                  ...problemModal,
                  show: true,
                });
              }}
            />
            {table.data.length > 0 && (
              <CSVLink
                className="d-flex align-items-center"
                data={table.data}
                filename={`${t("INDIVIDUAL_COMPANY_INQUIRY")}.csv`}
                onClick={(e: any) => {}}
              >
                <LinkIconButton
                  imgName="download-icon.svg"
                  text={t("SAVE_AS_CSV")}
                  onClick={() => {}}
                ></LinkIconButton>
              </CSVLink>
            )}
            <ProblemReportModal {...problemModal} />
          </div>
        </div>
        {/* 下區塊 */}
        <div id="resutl-table" className={`result-box scroll`}>
          <ScrollToTopButton
            targetClassName={"result-box"}
            bottom={90}
            right={35}
          />
          {selItem ? (
            <>
              {realParam.companyName && (
                <div className="provide-word">
                  {t("INDIVIDUAL_MESSAGE2", {
                    marketName: getMarketStr(),
                    companyName: realParam.companyName,
                  })}
                </div>
              )}
              <div className="mt-4 default-word">
                *{t("INDIVIDUAL_MESSAGE1")}
              </div>
              {/* 下載檔案 */}
              <div className="d-flex justify-content-end mt-20-p">
                {activeDownloadMatrix && (
                  <LinkIconButton
                    imgName={"download-icon.svg"}
                    text={t("DOWNLOAD_SUSTAINABILITY_PRINCIPLES_MATRIX")}
                    onClick={() => {
                      downLoadMatrix();
                    }}
                  />
                )}
              </div>
              <div id="individual-table-box">
                {/* 環境相關table */}
                <EIndividualTableComponent
                  showAll={showAll}
                  data={realTree.filter(
                    (p) => p.category === Category.Environment
                  )}
                  selItem={selItem}
                  scrollFirst={scrollFirst}
                />
                {/* 剩餘類型相關 */}
                <IndividualTableComponent
                  showAll={showAll}
                  data={realTree.filter(
                    (p) => p.category !== Category.Environment
                  )}
                  selItem={selItem}
                  scrollFirst={scrollFirst}
                />
              </div>
            </>
          ) : (
            <EmptySearchPage {...empty} />
          )}
        </div>
      </div>
    </div>
  );
};
//#endregion

export default IndividualCompany;
