import { MarketTypeEnum, OptionModel } from "./baseModel";

//-------------------- options --------------------
/** 市場別選單 */
export const MarketOp: OptionModel[] = [{
    id: MarketTypeEnum.Listing.toString(),
    text: '上市',
    enumKey: MarketTypeEnum.Listing
}, {
    id: MarketTypeEnum.OTC.toString(),
    text: '上櫃',
    enumKey: MarketTypeEnum.OTC
}, {
    id: MarketTypeEnum.PO.toString(),
    text: '公發',
    enumKey: MarketTypeEnum.PO
}, {
    id: MarketTypeEnum.Emerging.toString(),
    text: '興櫃',
    enumKey: MarketTypeEnum.Emerging
}];

