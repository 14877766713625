import React, { FC } from "react";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
import Header from "components/layouts/Header/Header";
import { SideBarItemModel } from "../../models/baseModel";
import InfoDisclosureRouter from "./InfoDisclosure/InfoDisclosureRouter";
import Sidebar from "components/layouts/SideBar/Sidebar";
import GhgReductionInfo from "./GhgReductionInfo/GhgReductionInfo";
import SustainReport from "./SustainReport/SustainReport";
import SustainEconomic from "./SustainEconomic/SustainEconomic";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import { useTranslation } from "react-i18next";
export const InquiryLayout: FC = () => {
  const {t} = useTranslation();
  const sidebarItems: SideBarItemModel[] = [
    {
      moduleName: t('ESG_INFORMATION_DISCLOSURE'),
      url: "info",
      icon: "sidebar-analysis-icon.svg",
    },
    {
      moduleName: t('GREENHOUSE_GAS_EMISSIONS_AND_REDUCTION_INFORMATION'),
      url: "ghg-reduction",
      icon: "sidebar-ghg-reduction-icon.svg",
    },
    {
      moduleName: t('SUSTAINABILITY_REPORT') ,
      url: "report",
      icon: "sidebar-book-icon.svg",
    },
    {
      moduleName: t('SUSTAINABLE_ECONOMIC_ACTIVITIES'),
      url: "economic",
      icon: "sidebar-questionnaire-icon.svg",
    },
  ];
  return (
    <div className="root-box">
      {/* Narbar */}
      <div>
        <Header moduleName={t('INQUIRY_AT_THE_FRONT')} />
      </div>
      <div className="layout-box scroll">
        {/* 父層不更動的部分 */}
        <nav>
          <Sidebar items={sidebarItems} />
        </nav>
        {/* 子層替換 */}
        <div className="layout-container-box">
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

interface InquiryRouterProps {}
const InquiryRouter: FC<InquiryRouterProps> = () => {
  const routers: RouteObject[] = [
    {
      path: "",
      element: <InquiryLayout />,
      children: [
        {
          path: "",
          element: <Navigate to={"info"} />,
        },
        {
          path: "info/*",
          element: <InfoDisclosureRouter />,
        },
        {
          path: "ghg-reduction/*",
          element: (
            <CustomErrorBoundary>
              <GhgReductionInfo />
            </CustomErrorBoundary>
          ),
        },
        {
          path: "report/*",
          element: (
            <CustomErrorBoundary>
              <SustainReport />
            </CustomErrorBoundary>
          ),
        },
        {
          path: "economic/*",
          element: <SustainEconomic />,
        },
      ],
    },
  ];
  let elements = useRoutes(routers);
  return <>{elements}</>;
};

export default InquiryRouter;
