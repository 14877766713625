import React, { FC } from 'react';


interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => {
   return(
  <div className="d-flex align-items-center justify-content-center"
   style={{
    height: "100vh",
    fontSize: "22px"
  }}>
    找不到頁面
  </div>
)};

export default NotFound;
