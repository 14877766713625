import { FC, useState } from "react";
import styles from "./BackTitle.module.scss";
import { useNavigate } from "react-router-dom";

interface BackTitleProps {
  title: string;
  url: string;
  state?: { [key: string]: unknown };
  /** 是否處理其他事件(直接往外丟事件就好) */
  handleOtherEvent?: boolean;
  /** 改變item */
  changeItem?: (url: string) => void;
}

const BackTitle: FC<BackTitleProps> = ({
  handleOtherEvent = false,
  ...props
}) => {
  const [title, setTitle] = useState<string>(props.title);
  const [url, setUrl] = useState<string>(props.url);
  const [state] = useState<{ [key: string]: unknown } | undefined>(props.state);
  const navigate = useNavigate();
  const backNavigate = (url: string) => {
    if (!handleOtherEvent) {
      if (state) {
        navigate(url, { state: state });
      } else {
        navigate(url);
      }
    } else {
      if (props.changeItem) {
        // 處理其他事件 (不導向)
        props.changeItem(url);
      }
    }
  };

  return (
    <div className={`${styles["back-title-box"]}`}>
      <div className="back-box">
        <div className="back-icon" onClick={() => backNavigate(url)}></div>
        <div className="title">{props.title}</div>
      </div>
    </div>
  );
};

export default BackTitle;
