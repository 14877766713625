import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./RGstep3.module.scss";
import { useMutation } from "@tanstack/react-query";
import { PocReportTemplateArr } from "models/application/sustainReportDeclare/pocTemplateModel";
import {
  ReportTemplateModel,
  TemplateSetType,
} from "models/application/sustainReportDeclare/sustainReportDeclareModel";
import { ResponseCode } from "models/responseCodeModel";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import SustainReportProduceService from "services/application/sustainReportDeclare/sustainReportProduce";
import {
  ReportProduceAction,
  ReportProduceStatus,
  ReportTemplateModel as ReportTemplateTypeModel,
  SustainReportProduceInfoModel,
} from "models/application/sustainReportDeclare/sustainReportProduceModel";
import { useParams } from "react-router-dom";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { error2Alert, success2Alert } from "utils/otherToast";

interface RGstep3Props {
  gotoStep: (step: number, status: ReportProduceAction) => void;
}

/** Template 類型 */
const TemplateCategoryType = {
  Vertical: "vertical",
  Horizontal: "horizontal",
};

const RGstep3: FC<RGstep3Props> = (props) => {
  // 年度清單
  const [selTemplate, setSelTemplate] = useState<ReportTemplateModel>();
  const params = useParams();
  /** 設定範本 */
  const [pocTemplateArr, setPocTemplateArr] = useState<ReportTemplateModel[]>(
    []
  );
  // 直式/橫式
  const [radioValue, setRadioValue] = useState<string>(
    TemplateCategoryType.Vertical
  );  
  /** 是否下一步 */
  const isNext = useRef<boolean>(false);
  /** 基本狀態資訊 */
  const [infoData, setInfoData] = useState<SustainReportProduceInfoModel>({
    produceId: "",
    year: 0,
    produceStatus: ReportProduceStatus.NotStarted,
    stepStatus: ReportProduceAction.StartProduce,
    produceStatusName: "",
  });
  const switchView = [
    {
      name: "直式A4",
      value: TemplateCategoryType.Vertical,
    },
    {
      name: "橫式A4",
      value: TemplateCategoryType.Horizontal,
    },
  ];

  useEffect(() => {
    getStatus.mutate(params.id!);
  }, []);

  /** item 點擊 */
  const handleItemClick = (item: ReportTemplateModel) => {
    setSelTemplate({ ...item });
  };

  //#region  API區塊
  /** 永續報告書狀態 */
  const getStatus = useMutation({
    mutationFn: (procedureId: string) =>
      SustainReportProduceService.getStatus(procedureId),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportProduceInfoModel = res.data;
        if (data) {
          let newYear = data.year + 1910;
          setInfoData({
            ...data,
            year: newYear,
          });
          getTemplateSetType.mutate(newYear);
        }
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 取得圖表狀態 */
  const getTemplateSetType = useMutation({
    mutationFn: (year: number) =>
      SustainReportProduceService.getTemplateSetType(year),
    onSuccess: (res) => {
      if (res.code === ResponseCode.SuccessOK && res.success) {
        console.log(res.data);
        if (res.data !== null) {
          setSelTemplate(PocReportTemplateArr.find((p) => p.type === res.data));
        } else {
          // 預設第0筆
          setSelTemplate(PocReportTemplateArr[0]);
        }
        let templateType = "";
        switch (res.data) {
          case TemplateSetType.HorizontalSet1:
          case TemplateSetType.HorizontalSet2:
            templateType = TemplateCategoryType.Horizontal;
            break;
          case TemplateSetType.VerticalSet1:
          case TemplateSetType.VerticalSet2:
          default:
            templateType = TemplateCategoryType.Vertical;
            break;
        }

        chnageSwitchType(templateType);
      } else {
        console.log(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 設置圖表狀態 */
  const editTemplateSetType = useMutation({
    mutationFn: (model: ReportTemplateTypeModel) =>
      SustainReportProduceService.editTemplateSetType(model),
    onSuccess: (res) => {
      if (res.code === ResponseCode.SuccessOK && res.success) {
        if (isNext.current) {
          props.gotoStep(4, ReportProduceAction.ExportReport);
        } else {
          success2Alert(res.message);
        }
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  //#endregion

  //#region 方法區塊
  /** 改變項目類型 */
  const chnageSwitchType = (value: string) => {
    setRadioValue(value);
    let tmpPocTemplate = [];
    if (value === TemplateCategoryType.Vertical) {
      tmpPocTemplate = PocReportTemplateArr.filter((p) =>
        [TemplateSetType.VerticalSet1, TemplateSetType.VerticalSet2].includes(
          p.type
        )
      );
    } else {
      tmpPocTemplate = PocReportTemplateArr.filter((p) =>
        [
          TemplateSetType.HorizontalSet1,
          TemplateSetType.HorizontalSet2,
        ].includes(p.type)
      );
    }

    setPocTemplateArr([...tmpPocTemplate]);
  };

  /** 儲存 */
  const handleSave = () => {
    if (selTemplate) {
      editTemplateSetType.mutate({
        year: infoData.year,
        templateSetType: selTemplate?.type,
      });
    } else {
      error2Alert("請選擇模板");
    }
  };
  //#endregion

  return (
    <div className={styles["rg-step3-box"]}>
      {(getTemplateSetType.isPending || getStatus.isPending) && (
        <VisuallLoading />
      )}
      <div className="title-box">
        <div className="title">選擇美編樣板</div>
        <div className="tool-box">
          <div className="tool-item">
            <button
              className="secondary"
              onClick={() => {
                props.gotoStep(2, ReportProduceAction.ReportGenerate);
              }}
            >
              上一步，製作報告書
            </button>
          </div>
          <div className="tool-item">
            <button
              className="secondary"
              disabled={!selTemplate}
              onClick={() => {
                isNext.current = false;
                handleSave();
              }}
            >
              儲存
            </button>
          </div>
          <div className="tool-item">
            <button
              className="default"
              onClick={() => {
                isNext.current = true;
                handleSave();
              }}
            >
              下一步，匯出永續報告書
            </button>
          </div>
        </div>
      </div>
      <div className="result-box">
        <div className="left-box">
          <ButtonGroup className="switch-box">
            {switchView.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                className="d-flex align-items-center"
                variant="secondary"
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => {
                  chnageSwitchType(e.currentTarget.value);
                }}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
          <div className="template-box scroll">
            {pocTemplateArr.map((item) => {
              return (
                <div
                  className={
                    `template-item ` +
                    `${item.type === selTemplate?.type ? "active " : ""}`
                  }
                  key={item.id}
                  onClick={() => {
                    handleItemClick(item);
                  }}
                >
                  <div className="template-name">{item.leftText}</div>
                  <div className="img-box">
                    <img
                      className={item.type > 2 ? "horizontal" : "vertical"}
                      alt="樣板A"
                      src={item.previewImg}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="center-line"></div>
        <div className="right-box">
          {selTemplate && (
            <div className="title">美編樣板預覽-{selTemplate?.rightText}</div>
          )}
          <div className="preview-box scroll">
            {selTemplate?.previewItems.map((item) => {
              return (
                <div key={item} className={`preview-item `}>
                  <img
                    className={selTemplate.type > 2 ? "horizontal" : "vertical"}
                    alt=""
                    src={`/assets/images/declaration/reportTemplate/${selTemplate.id}_${item}.jpg`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RGstep3;
