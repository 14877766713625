import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { NavBarItemModel } from "../../../models/baseModel";
import NavbarItem from "./NavbarItem";
import styles from "./Navbar.module.scss";
interface NavbarListProps {
  items: NavBarItemModel[];
}

const Navbar: FC<NavbarListProps> = (props) => {
  const { items } = props;
  return (
    <div className={`${styles['navbar-box']}`}>
      <ul className="d-flex">
        {items.map((p, index) => {
          return <NavbarItem key={index} item={p} />;
        })}
      </ul>
    </div>
  );
};

export default Navbar;
