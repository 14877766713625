import React, { FC, useEffect, useState } from "react";
import styles from "./PropagandaFile.module.scss";
import TablePagination from "components/base/TablePagination/TablePagination";
import { ListRequestModel } from "models/baseModel";
import { normalDate3 } from "utils/dateTimeFormat";
import Dropdown from "components/base/Dropdown/Dropdown";
import SearchInput from "components/base/SearchInput/SearchInput";
import { EduPropagandaManageActionsPermissionCodes } from "models/auth/permissionModel";
import { usePermissionContext } from "context/PermissionProvider";
import IconButton from "components/button/IconButton/IconButton";
import EduPropagandaManageService from "services/admin/eduPropagandaManageService";
import { useMutation } from "@tanstack/react-query";
import { ResponseCode } from "models/responseCodeModel";
import {
  EditEduResourceFileStatusModel,
  EduResourceFileListModel,
} from "models/admin/eduPropagandaManageModel";
import SwitchCheckbox from "components/base/SwitchCheckbox/SwitchCheckbox";
import { useNavigate } from "react-router-dom";
import { error2Alert, success2Alert } from "utils/otherToast";

interface PropagandaFileProps {}

const PropagandaFile: FC<PropagandaFileProps> = () => {
  //#region 搜尋參數
  /** 資料 */
  const [listData, setListData] = useState<EduResourceFileListModel[]>([]);
  /** 設置資料總數 */
  const [totalCount, setTotalCount] = useState<number>(0);
  /** 搜尋條件 */
  const [param, setParam] = useState<ListRequestModel>({
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortKey: null,
  });
  const [isEdit, setIsEdit] = useState(false);

  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;
  const [batchOperateList, setBatchOperateList] = useState<string[]>([]);
  const navigator = useNavigate();
  //#endregion

  useEffect(() => {
    getEduResourceFileList.mutate(param);
  }, []);

  useEffect(() => {
    if (!isEdit) {
      setBatchOperateList([]);
    }
  }, [isEdit]);

  //#region API 相關

  /** 類型(目錄)管理列表 */
  const getEduResourceFileList = useMutation({
    mutationFn: (model: ListRequestModel) =>
      EduPropagandaManageService.getEduResourceFileList(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: EduResourceFileListModel[] = res.data;
        setListData([...data]);
        setTotalCount(res.count || 0);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 啟用/停用 */
  const switchActive = (isActive: boolean, id: string) => {
    editEduResourceFileStatus.mutate({
      id: id,
      status: isActive,
    });
  };

  /**  刪除類型(目錄)管理列表*/
  const deleteEduResourceFiles = useMutation({
    mutationFn: (ids: string[]) =>
      EduPropagandaManageService.deleteEduResourceFiles(ids),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        setBatchOperateList([]);
        getEduResourceFileList.mutate(param);
        success2Alert(res.message);
      } else {
        console.log(res);
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 類型(目錄)管理列表 */
  const editEduResourceFileStatus = useMutation({
    mutationFn: (model: EditEduResourceFileStatusModel) =>
      EduPropagandaManageService.editEduResourceFileStatus(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        success2Alert(res.message);
      } else {
        console.log(res);

        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  //#endregion

  //#region 其他function
  /** 更改狀態 */
  const handleStatusChange = (id: string | null) => {
    if (id) {
      let chk = listData.find((x) => x.id === id);
      if (chk) {
        switchActive(!chk.status, id);
      }
      setListData(
        listData.map((ck, index) => {
          if (ck.id === id) {
            ck.status = !ck.status;
          }
          return ck;
        })
      );
    }
  };

  /** 前往細節 */
  const gotoDetail = (id: string) => {
    navigator(`/admin/edu-propaganda/file/${id}/info`);
  };
  //#endregion

  return (
    <div className={styles["propaganda-file-list-box"]}>
      <div className={`${styles["operation-header"]}`}>
        <div className={`left`}>
          <div className={`select-item`}>
            <SearchInput
              onClick={(e) => {
                const newParam = {
                  ...param,
                  page: 1,
                  searchKey: e,
                };
                setParam(newParam);
                getEduResourceFileList.mutate(newParam);
              }}
              placeholder={"搜尋"}
            />
          </div>
        </div>
        <div className={`right`}>
          {batchOperateList.length ? (
            <>
              {detectEveryActionPermission([
                EduPropagandaManageActionsPermissionCodes.EduPropagandaFileDelete,
              ]) ? (
                <div className={`select-item`}>
                  <IconButton
                    imgName="trash-icon.svg"
                    text="刪除"
                    className="secondary"
                    onClick={() => {
                      deleteEduResourceFiles.mutate(batchOperateList);
                    }}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className={`select-item`}>
                <button
                  className="secondary"
                  onClick={() => {
                    setIsEdit(!isEdit);
                  }}
                >
                  {!isEdit ? "多筆編輯" : "取消編輯"}
                </button>
              </div>
              {detectEveryActionPermission([
                EduPropagandaManageActionsPermissionCodes.EduPropagandaFileCreate,
              ]) &&
                !isEdit && (
                  <div className={`select-item`}>
                    <button
                      className="default"
                      onClick={() => {
                        gotoDetail("-1");
                      }}
                    >
                      +新增
                    </button>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
      <div className={`${styles["propaganda-file-table-box"]} scroll`}>
        <div className="table-box">
          <table
            aria-label="查詢結果table"
            className="table-container sticky-table"
          >
            <thead>
              <tr>
                {isEdit && (
                  <th
                    scope="col"
                    className={`${styles["setting-checkbox"]}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <label className={`custom-checkbox hideText`}>
                      <input
                        type="checkbox"
                        checked={listData.length === batchOperateList.length}
                        onChange={() => {
                          if (listData.length === batchOperateList.length) {
                            setBatchOperateList([]);
                          } else {
                            setBatchOperateList(listData.map((e) => e.id!));
                          }
                        }}
                      />
                      <span
                        className={`${
                          batchOperateList.length > 0 &&
                          listData.length > batchOperateList.length
                            ? "partial"
                            : ""
                        }`}
                      ></span>
                    </label>
                  </th>
                )}
                <th scope="col">檔案名稱</th>
                <th scope="col">類型</th>
                <th scope="col">狀態</th>
              </tr>
            </thead>
            <tbody>
              {listData?.map((p: EduResourceFileListModel) => {
                return (
                  <tr key={p.id} onClick={() => gotoDetail(p.id)}>
                    {isEdit && (
                      <td
                        className={`${styles["setting-checkbox"]}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <label className={`custom-checkbox hideText`}>
                          <input
                            type="checkbox"
                            checked={batchOperateList.includes(p.id!)}
                            onChange={() => {
                              if (batchOperateList.includes(p.id!)) {
                                setBatchOperateList((prev) =>
                                  prev.filter((e) => e !== p.id!)
                                );
                              } else {
                                setBatchOperateList((prev) => [...prev, p.id!]);
                              }
                            }}
                          />
                          <span></span>
                        </label>
                      </td>
                    )}
                    <td>{p.name}</td>
                    <td>{p.directoryName}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        <SwitchCheckbox
                          checked={p.status}
                          text={p.status ? "啟用" : "關閉"}
                          onClick={() => {
                            handleStatusChange(p.id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="table-pagination mb-20-p">
        <TablePagination
          page={param.page}
          pageSize={param.pageSize}
          onChange={(e) => {
            const newModel = {
              ...param,
              ...e,
            };
            setParam(newModel);
            getEduResourceFileList.mutate(newModel);
          }}
          total={totalCount}
        />
      </div>
    </div>
  );
};

export default PropagandaFile;
