//* ---------------------------- React start ---------------------------- *//
import { FC } from 'react';
import { Navigate, Outlet, RouteObject, useRoutes } from 'react-router-dom';
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from './DeclarationRouter.module.scss';
import Navbar from 'components/layouts/Navbar/Navbar';
import { NavBarItemModel } from 'models/baseModel';
import EsgDisclosure from './EsgDisclosure/EsgDisclosure';
import EsgDisclosureDetail from './EsgDisclosure/Detail/Detail';
import SustainabilityReport from './SustainabilityReport/SustainabilityReport';
import SustainabilityReportDetail from './SustainabilityReport/Detail/Detail';
import GhgEmissionsReduction from './GhgEmissionsReduction/GhgEmissionsReduction';
import GhgEmissionsReductionDetail from './GhgEmissionsReduction/Detail/Detail';
import TransactionRequest from './TransactionRequest/TransactionRequest';
import CustomErrorBoundary from 'components/common/CustomErrorBoundary/CustomErrorBoundary';
import {
	FeaturePermissionCodes,
	PermissionRouteObjectModel,
	PermissionNavBarItemModel,
	ActionsPermissionCodes,
} from 'models/auth/permissionModel';
import EmptyWaitPage from 'components/common/EmptyWaitPage/EmptyWaitPage';
import useUserInfoStore from 'state/useUserInfoStore';
import { usePermissionContext } from 'context/PermissionProvider';
import ExportDocument from './ExportDocument/ExportDocument';
import AdminReportGeneration from "pages/Admin/Declaration/AdminReportGeneration/AdminReportGeneration";
import AdminReportGenerationDetail from "pages/Admin/Declaration/AdminReportGeneration/AdminReportGenerationDetail/AdminReportGenerationDetail";
//* ---------------------------- local end ---------------------------- *//

const DeclarationLayout: FC = () => {
	//* ---------------------------- state start ---------------------------- *//
	const { PermissionState, PermissionService } = usePermissionContext();
	const { noPermissionPage } = PermissionState;
	const { detectEveryFeatruePermission } = PermissionService;
	const navItems: PermissionNavBarItemModel[] = [
		{
			name: '異動申請管理',
			url: 'transaction-request',
			permission: FeaturePermissionCodes.DeclareDataManage.CorrectionRequestManage,
		},
		{
			name: 'ESG資訊揭露',
			url: 'esg-disclosure',
			permission: FeaturePermissionCodes.DeclareDataManage.IndicatorDeclareManage,
		},
		{
			name: '永續報告書',
			url: 'sustainability-report',
			permission: FeaturePermissionCodes.DeclareDataManage.SustainReportDeclareManage,
		},
		{
			name: '溫室氣體排放及減量資訊',
			url: 'ghg-emissions-reduction',
			permission: FeaturePermissionCodes.DeclareDataManage.GhgDeclareManage,
		},
    {
      name: "報告書產製",
      url: "report-generation",
      permission: FeaturePermissionCodes.DeclareDataManage.SustainReportProduceManage,
    },
		{
			name: '匯出檔案',
			url: 'export-document',
			permission: FeaturePermissionCodes.DeclareDataManage.ExportCsvManage,
		},    
	];
	const filterNavItems = navItems.filter((e) => detectEveryFeatruePermission([e.permission]));

	// console.log("filterNavItems", filterNavItems);
	//* ---------------------------- state end ---------------------------- *//
	return (
		<>
			<CustomErrorBoundary>
				{filterNavItems?.length ? (
					<>
						{/* tab 資訊 */}
						<Navbar items={filterNavItems} />
						<div className='nav-content-box'>
							<Outlet></Outlet>
						</div>
					</>
				) : (
					noPermissionPage()
				)}
			</CustomErrorBoundary>
		</>
	);
};

const DeclarationRouter: FC = () => {
	//* ---------------------------- state start ---------------------------- *//
	const { PermissionService } = usePermissionContext();
	const { permissionRouter } = PermissionService;

	const routers: PermissionRouteObjectModel[] = [
		{
			path: '',
			element: <DeclarationLayout />,
			children: [
				{
					path: 'transaction-request',
					element: <TransactionRequest />,
					featurePermission: FeaturePermissionCodes.DeclareDataManage.CorrectionRequestManage,
					actionReadPermission: ActionsPermissionCodes.DeclareDataManage.CorrectionRequestManageRead,
				},
				{
					path: 'esg-disclosure',
					element: <EsgDisclosure />,
					featurePermission: FeaturePermissionCodes.DeclareDataManage.IndicatorDeclareManage,
					actionReadPermission: ActionsPermissionCodes.DeclareDataManage.IndicatorDeclareManageRead,
				},
				{
					path: 'esg-disclosure/:id/detail',
					element: <EsgDisclosureDetail />,
					featurePermission: FeaturePermissionCodes.DeclareDataManage.IndicatorDeclareManage,
					actionReadPermission: ActionsPermissionCodes.DeclareDataManage.IndicatorDeclareManageRead,
				},
				{
					path: 'sustainability-report',
					element: <SustainabilityReport />,
					featurePermission: FeaturePermissionCodes.DeclareDataManage.SustainReportDeclareManage,
					actionReadPermission: ActionsPermissionCodes.DeclareDataManage.SustainReportDeclareManageRead,
				},
				{
					path: 'sustainability-report/:declareId/detail',
					element: <SustainabilityReportDetail />,
					featurePermission: FeaturePermissionCodes.DeclareDataManage.SustainReportDeclareManage,
					actionReadPermission: ActionsPermissionCodes.DeclareDataManage.SustainReportDeclareManageRead,
				},
				{
					path: 'ghg-emissions-reduction',
					element: <GhgEmissionsReduction />,
					featurePermission: FeaturePermissionCodes.DeclareDataManage.GhgDeclareManage,
					actionReadPermission: ActionsPermissionCodes.DeclareDataManage.GhgDeclareManageRead,
				},
				{
					path: 'ghg-emissions-reduction/:id/detail',
					element: <GhgEmissionsReductionDetail />,
					featurePermission: FeaturePermissionCodes.DeclareDataManage.GhgDeclareManage,
					actionReadPermission: ActionsPermissionCodes.DeclareDataManage.GhgDeclareManageRead,
				},
				{
					path: 'export-document',
					element: <ExportDocument />,
					featurePermission: FeaturePermissionCodes.DeclareDataManage.ExportCsvManage,
					actionReadPermission: ActionsPermissionCodes.DeclareDataManage.ExportCsvManageRead,
				},
        {
          path: "report-generation",
          element: <AdminReportGeneration />,
          featurePermission:
            FeaturePermissionCodes.DeclareDataManage.SustainReportProduceManage,
          actionReadPermission:
            ActionsPermissionCodes.DeclareDataManage.SustainReportProduceManageRead,
        },
        {
          path: "report-generation/:id/detail",
          element: <AdminReportGenerationDetail />,
          featurePermission:
            FeaturePermissionCodes.DeclareDataManage.SustainReportProduceManage,
          actionReadPermission:
            ActionsPermissionCodes.DeclareDataManage.SustainReportProduceManageRead,
        },
			],
		},
	];
	//* ---------------------------- state end ---------------------------- *//

	return useRoutes(permissionRouter(routers));
};

export default DeclarationRouter;
