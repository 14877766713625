import {
  ListRequestModel,
  OptionModel as BasicOptionModel,
} from 'models/baseModel';

export interface QuestionnaireYearModel {
  year: number;
  updateTime: string;
}

export interface QuestuinnaireSampleRequestModel {
  year: number;
}

export interface SustainQuestionnaireSampleModel {
  /// 表單範本id
  sampleId: string;
  /// 範本名稱
  sampleName: string;
  /// 可否重複填寫問卷
  isMultiple: boolean;
}

export class QuestionnaireDataListRequestModel implements ListRequestModel {
  takeCount: number = 0;
  questionnaireSampleId: string = '';
  page: number = 1;
  pageSize: number = 10;
  sortKey: string | null = null;
  searchKey?: string | null;
  sortType?: boolean | null;
  companyCode?: string;
  isMultiple?: boolean;
}

export interface SustainQuestionnaireListDataModel {
  sampleId: string;
  sampleName: string;
  titles: string[];
  titleWithControlId: {
    name: string;
    controlId: string;
  }[];
  questionnaireDataList: QuestionnaireData[];
  isMultiple: boolean;
}

export interface QuestionnaireData {
  dataId: string;
  columns: {
    key: string;
    value: string;
    ctrType: string;
    code: string;
    sampleControlId: string;
  }[];
  sno: number;
  createTime: string;
  lastUpdateTime: string;
  isDelared: boolean;
}

export class QuestionnaireSampleDataModel {
  //- need
  companyCode: string = '';
  sampleId: string = '';
  year: number = 0;
  dataId: string = '';
  sampleName: string = '';
  comment: string = '';
  dataType: number = 1;
  controls: ControlModel[] = [];
  sectionDatas: SectionModel[] = [];
  isFinished: boolean = false;
  //- unneed
  lang?: string;
}

export class ControlModel {
  //- need
  comment: string = '';
  ctrType: string = '';
  id: string = '';
  sampleControlId: string = '';
  name: string = '';
  required: boolean = false;
  optionsVal: OptionModel[] = [];
  value?: string;
  sortIndex?: number;
  digitNumber?: number;
  excludeNegative?: boolean;
  hasImage?: boolean;
  imageData?: SampleControlFileModel;
  //- unneed
  options?: OptionModel[];
  code?: string;
  excelRowIndex?: number;
  hasData?: boolean;
  sourceControlId?: string;
  targetControlId?: string;
  unit?: string;
  isShow?: boolean;
  isCustomUnit?: boolean;
  matrix?: Matrix;
  line?: Line;
  showSortIndex?: number;
  sectionName?: string;
  parentId?: string;
  isBlankSection?: boolean;
  matrixData?: MatrixData;
  lineData?: Line;
  isNecessary?: boolean;
  //- web use
  valid: boolean = true;
  isFollowingControlDisabled: boolean = false;
  webOptions: BasicOptionModel[] = [];
  //> 不開放填寫
  isNotOpenForSubmission: boolean = false;
  //> 預設選項的disable
  isDefaultOptionDisabled: boolean = false;
}

export class SampleControlFileModel {
  fileId: string = '';
  controlId: string = '';
  fileName: string = '';
  url: string = '';
}

export class OptionModel {
  //- need
  id: string = '';
  text: string = '';
  check: boolean = false;
  followingControlIds: string[] = [];
  isExtraText: boolean = false;
  extraTextValue?: string;
  //- unneed
  enumKey?: number;
  code?: string;
  skipControlId?: string;
  infoValue?: string;
  controlNameList?: string[];
  //- web use
  valid: boolean = true;
  valid_extraTextValue: boolean = true;
}

export interface Matrix {
  rowTotal: boolean;
  columnTotal: boolean;
  rows: RowModel[];
  columns: ColumnModel[];
}

export interface RowModel {
  id: string;
  name: string;
  type: string;
  sortIndex: number;
  code: string;
  controlId: string;
}

export interface ColumnModel {
  id: string;
  name: string;
  type: string;
  sortIndex: number;
  code: string;
  controlId: string;
}

export interface Line {
  id: string;
  startNum: number;
  endNum: number;
  headTag: string;
  tailTag: string;
  nums: number[];
}

export interface MatrixData {
  code: string;
  controlName: string;
  rows: string[];
  columns: string[];
  items: MatrixItemModel[];
}

export interface MatrixItemModel {
  rowIndex: number;
  columnIndex: number;
  rowCode: string;
  columnCode: string;
  ctrType: string;
  value: string;
}

export interface SectionModel {
  isNecessary: boolean;
  sections: SectionItemModel[];
}

export class SectionItemModel {
  //- need
  id: string = '';
  sampleControlId: string = '';
  name: string = '';
  startIndex: number = 0;
  controls: ControlModel[] = [];
  comment: string = '';
  isBlankSection: boolean = false;
  //- web use
  isSectionCollapse: boolean = false;
  isFollowingControlDisabled: boolean = false;
}
