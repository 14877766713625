import React, { FC, useState, useEffect, memo } from "react";
import styles from "./Company.module.scss";
import MarketService from "services/admin/marketService";
import { useMutation } from "@tanstack/react-query";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { success2Alert, error2Alert } from "utils/otherToast";
import { useParams } from "react-router-dom";
import {
  IndustryManageRequestModel,
  CompanyModel,
  EditCompanyModel,
  EditCompanyValidModel,
  TransferIndustryModel,
  TransferIndustryValidModel,
  CompanyOptionModel,
  ChangeIndustryModel,
} from "models/admin/industryModel";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import ConfirmModal from "components/base/ConfirmModal/ConfirmModal";
import { ConfirmModalProps } from "components/base/ConfirmModal/ConfirmModal";
import BatchUploadModal from "components/base/BatchUploadModal/BatchUploadModal";
import { BatchUploadModalProps } from "components/base/BatchUploadModal/BatchUploadModal";
import { OptionModel } from "models/baseModel";
import Dropdown from "components/base/Dropdown/Dropdown";
import NormalInput from "components/base/NormalInput/NormalInput";
import BackTitle from "components/base/BackTitle/BackTitle";
import SearchInput from "components/base/SearchInput/SearchInput";
import TablePagination from "components/base/TablePagination/TablePagination";
import { PaginationModel } from "models/baseModel";
import { ResponseCode } from "models/responseCodeModel";
import { usePermissionContext } from "context/PermissionProvider";
import { IndustryManageActionsPermissionCodes } from "models/auth/permissionModel";

interface CompnayModdalContentProps {
  companyData: EditCompanyModel;
  industryOptionList: OptionModel[];
  sasbOptionList: OptionModel[];
  tableInfoOptions: OptionModel[];
  companyDataValidObj: EditCompanyValidModel;
}

const CompnayModdalContentComponent: FC<CompnayModdalContentProps> = memo(
  (data: CompnayModdalContentProps) => {
    useEffect(() => {
      console.log("data.companyDataValidObj", data.companyDataValidObj);
    }, [data.companyDataValidObj]);
    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="w-50">公司代號</div>
          <div className="w-50">
            <NormalInput
              placeholder={"請輸入"}
              defaultValue={data.companyData.companyCode || undefined}
              onChange={(e: string) =>
                (data.companyData.companyCode = e ? e : undefined)
              }
              valid={data.companyDataValidObj.companyCode}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div className="w-50">公司名稱</div>
          <div className="w-50">
            <NormalInput
              placeholder={"請輸入"}
              defaultValue={data.companyData.companyName || undefined}
              onChange={(e: string) =>
                (data.companyData.companyName = e ? e : undefined)
              }
              valid={data.companyDataValidObj.companyName}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div className="w-50">公司簡稱</div>
          <div className="w-50">
            <NormalInput
              placeholder={"請輸入"}
              defaultValue={data.companyData.companyShortName || undefined}
              onChange={(e: string) =>
                (data.companyData.companyShortName = e ? e : undefined)
              }
              valid={data.companyDataValidObj.companyShortName}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div className="w-50">產業類別</div>
          <div className="w-50">
            <Dropdown
              placeholder={"請選擇"}
              defaultId={data.companyData.industryId || undefined}
              options={data.industryOptionList}
              onChange={(e) =>
                (data.companyData.industryId = e ? e : undefined)
              }
              valid={data.companyDataValidObj.industryId}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div className="w-50">SASB產業別</div>
          <div className="w-50">
            <Dropdown
              placeholder={"請選擇"}
              defaultId={data.companyData.sasbIndustryId || undefined}
              options={data.sasbOptionList}
              onChange={(e) =>
                (data.companyData.sasbIndustryId = e ? e : undefined)
              }
              valid={data.companyDataValidObj.sasbIndustryId}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div className="w-50">附表</div>
          <div className="w-50">
            <Dropdown
              placeholder={"請選擇"}
              defaultId={data.companyData.tableInfo?.toString() || undefined}
              options={data.tableInfoOptions}
              onChange={(e) => {
                console.log(e);
                data.companyData.tableInfo =
                  !isNaN(Number(e)) && e !== "" ? Number(e) : undefined;
                console.log(data.companyData);
              }}
              valid={data.companyDataValidObj.tableInfo}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div className="w-50">餐飲營收50%以上</div>
          <div className="w-50">
            <Dropdown
              placeholder={"請選擇"}
              defaultId={
                data.companyData.highRevenueIndicator?.toString() || undefined
              }
              options={[
                { id: "false", text: "否" },
                { id: "true", text: "是" },
              ]}
              onChange={(e) =>
                (data.companyData.highRevenueIndicator = e
                  ? JSON.parse(e)
                  : undefined)
              }
              valid={data.companyDataValidObj.highRevenueIndicator}
            />
          </div>
        </div>
      </div>
    );
  }
);

interface TranferIndustryModdalContentProps {
  transferData: TransferIndustryModel;
  comapnyOptionList: CompanyOptionModel[];
  //industryOptionList: OptionModel[];
  marketTypeOptionList: CompanyOptionModel[];
  transferDataValidObj: TransferIndustryValidModel;
}

const TranferIndustryConentComponent: FC<TranferIndustryModdalContentProps> = memo(
  (data: TranferIndustryModdalContentProps) => {
    const [industryName, setIndustryName] = useState<string>('');
    const [changIndustryOption, setChangeIndustryOption] = useState<CompanyOptionModel[]>([]);

    // 轉換產業別選單(取帶入市場別id)
  const {
    mutate: getChangeIndustryOptionMutation
  } = useMutation({
    mutationFn: (request: IndustryManageRequestModel) =>
      MarketService.getChangeIndustryOption(request),
    onSuccess: (res) => {
      console.log("轉換產業別選單", res);
      if (res.code === 200 && res.success) {
        setChangeIndustryOption(res.data);
      } else {
        setChangeIndustryOption([]);
      }
    },
    onError: (err) => {
      console.log("getChangeIndustryOptionMutation", err);
    },
  });

    useEffect(() => {
      console.log("transferDataValidObj", data.transferDataValidObj);
    }, [data.transferDataValidObj]);

    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="w-40">請選擇公司</div>
          <div className="w-60">
            <Dropdown
              placeholder={"請選擇"}
              options={data.comapnyOptionList}
              onChange={(e: any) =>{
                  let chk:(any | undefined) = data.comapnyOptionList.find(x=>x.id === e);
                  if(chk) {
                    data.transferData.companyCode = chk.companyCode;
                    data.transferData.companyId = e;
                    setIndustryName(chk.industryName);
                  }
                }
              }
              valid={data.transferDataValidObj.companyCode}
            />
          </div>
        </div>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "start",
            marginTop: "1rem",
            width: '100%'
          }}
        >
          <div className="w-40 inline-block">目前產業別</div>
          <div style={{
            width: '50%',
            display: "inline-block",
          }}>{industryName}</div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div className="w-40">請選擇轉移市場別</div>
          <div className="w-60">
            <Dropdown
              placeholder={"請選擇"}
              defaultId={data.transferData.marketTypeId || undefined}
              options={data.marketTypeOptionList}
              onChange={(e) => {
                data.transferData.marketTypeId = e ? e : undefined;
                let marketObj = data.marketTypeOptionList.find(x=>x.id === e);
                data.transferData.marketType = marketObj ? marketObj.marketType : undefined;
                getChangeIndustryOptionMutation({
                  page: 0,
                  pageSize: 0,
                  sortKey: null,
                  industryYearId: e
                });
              }}
              valid={data.transferDataValidObj.marketType}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <div className="w-40">請選擇轉移產業別</div>
          <div className="w-60">
            <Dropdown
              placeholder={"請選擇"}
              defaultId={data.transferData.industryId || undefined}
              options={changIndustryOption}
              onChange={(e) =>
                (data.transferData.industryId = e ? e : undefined)
              }
              valid={data.transferDataValidObj.industryId}
            />
          </div>
        </div>

      </div>
    );
  }
);

const Company: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const params = useParams();
  const [industryYearId] = useState<string>(params.id!);
  const [currentMarketType, setCurrentMarketType] = useState<number | null>();
  const [companyListRequestData, setCompanyListRequestData] =
    useState<IndustryManageRequestModel>({
      industryYearId: industryYearId,
      page: 1,
      pageSize: 10,
      searchKey: null,
      sortKey: null,
      sortType: null,
      isHighRevenueIndicator: null,
    });
  const [companyList, setCompanyList] = useState<{
    count: number;
    list: CompanyModel[];
  }>({
    count: 0,
    list: [],
  });
  const [companyListNode, setCompanyListNode] = useState<React.ReactNode>();
  const [companyData, setCompanyData] = useState<EditCompanyModel>(
    new EditCompanyModel()
  );
  const [transferData, setTransferData] = useState<TransferIndustryModel>(
    new TransferIndustryModel()
  );

  const [transferValidObj, setTransferValidObj] =
    useState<TransferIndustryValidModel>(new TransferIndustryValidModel());

  const [companyDataValidObj, setCompanyDataValidObj] =
    useState<EditCompanyValidModel>(new EditCompanyValidModel());
  // const [marketTypeOptions] = useState<OptionModel[]>(() => {
  //   const enumValues = Object.values(MarketTypeEnum);
  //   const marketTypeDescriptions: Record<MarketTypeEnum, string> = {
  //     [MarketTypeEnum.Listing]: "上市",
  //     [MarketTypeEnum.OTC]: "上櫃",
  //     [MarketTypeEnum.PO]: "公發",
  //     [MarketTypeEnum.Emerging]: "興櫃",
  //   };
  //   return enumValues
  //     .filter((e) => !isNaN(Number(e)))
  //     .map((key) => {
  //       return {
  //         id: key.toString(),
  //         text: marketTypeDescriptions[key as MarketTypeEnum],
  //         enumKey: key,
  //       };
  //     }) as OptionModel[];
  // });
  // const [tableInfoOptions] = useState<OptionModel[]>(() => {
  //   const enumValues = Object.values(TableInfoEnum);
  //   const tableInfoDescriptions: Record<TableInfoEnum, string> = {
  //     [TableInfoEnum.Table1_1]: "附表一之一",
  //     [TableInfoEnum.Table1_2]: "附表一之二",
  //     [TableInfoEnum.Table1_3]: "附表一之三",
  //     [TableInfoEnum.Table1_4]: "附表一之四",
  //     [TableInfoEnum.Table1_5]: "附表一之五",
  //     [TableInfoEnum.Table1_6]: "附表一之六",
  //     [TableInfoEnum.Table1_7]: "附表一之七",
  //     [TableInfoEnum.Table1_8]: "附表一之八",
  //     [TableInfoEnum.Table1_9]: "附表一之九",
  //     [TableInfoEnum.Table1_10]: "附表一之十",
  //     [TableInfoEnum.Table1_11]: "附表一之十一",
  //     [TableInfoEnum.Table1_12]: "附表一之十二",
  //     [TableInfoEnum.Table1_13]: "附表一之十三",
  //     [TableInfoEnum.Table1_14]: "附表一之十四",
  //   };
  //   return enumValues
  //     .filter((e) => !isNaN(Number(e)))
  //     .map((key) => {
  //       return {
  //         id: key.toString(),
  //         text: tableInfoDescriptions[key as TableInfoEnum],
  //         enumKey: key,
  //       };
  //     }) as OptionModel[];
  // });
  const [industryOptionRequestData] = useState<IndustryManageRequestModel>({
    industryYearId: industryYearId,
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortKey: null,
    sortType: null,
  });
  const [industryOptionList, setIndustryOptionList] = useState<OptionModel[]>(
    []
  );
  const [sasbOptionList, setSasbOptionList] = useState<OptionModel[]>([]);
  const [tableInfoTypeList, setTableInfoTypeList] = useState<OptionModel[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [batchCompanyList, setBatchCompanyList] = useState<string[]>([]);
  const [title, setTtle] = useState("");
  const [companyOption, setCompanyOption] = useState<CompanyOptionModel[]>([]);

  const [marketTypeOption, setMarketTypeOption] = useState<CompanyOptionModel[]>([]);
  const [tablePagination, setTablePagination] = useState<PaginationModel>({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  /** 食物選項arr */
  const foodParmaArr = [
    {
      id: "-1",
      text: "全部",
    },
    {
      id: "0",
      text: "是",
    },
    {
      id: "1",
      text: "否",
    },
  ];
  const [isHighRevenueIndicator, setIsHighRevenueIndicator] = useState<
    string | null
  >("-1");
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;
  //* ---------------------------- state end ---------------------------- *//

  //- getCompanyList
  const { mutate: getCompanyListMutation, isPending: getCompanyListIsPending } =
    useMutation({
      mutationFn: (request: IndustryManageRequestModel) =>
        MarketService.getCompanyList(request),
      onSuccess: (res) => {
        if (res.code === 200 && res.success) {
          setCompanyList({
            count: res.count!,
            list: res.data,
          });
          setTtle(res.message);
        } else {
          setCompanyList({
            count: 0,
            list: [],
          });
        }
      },
      onError: (err) => {
        console.log("getCompanyListMutation", err);
      },
    });

  //- getIndustryOption
  const {
    mutate: getIndustryOptionMutation,
    isPending: getIndustryOptionIsPending,
  } = useMutation({
    mutationFn: (request: IndustryManageRequestModel) =>
      MarketService.getIndustryOption(request),
    onSuccess: (res) => {
      console.log("getIndustryOptionMutation", res);
      if (res.code === 200 && res.success) {
        setIndustryOptionList(res.data);
      } else {
        setIndustryOptionList([]);
      }
    },
    onError: (err) => {
      console.log("getIndustryOptionMutation", err);
    },
  });

  //- useEffect industryOptionRequestData
  useEffect(() => {
    getIndustryOptionMutation(industryOptionRequestData);
  }, [industryOptionRequestData, getIndustryOptionMutation]);

  //- getSasbOption
  const { mutate: getSasbOptionMutation, isPending: getSasbOptionIsPending } =
    useMutation({
      mutationFn: () => MarketService.getSasbOption(),
      onSuccess: (res) => {
        console.log("getSasbOptionMutation", res);
        if (res.code === 200 && res.success) {
          setSasbOptionList(res.data);
        } else {
          setSasbOptionList([]);
        }
      },
      onError: (err) => {
        console.log("getSasbOptionMutation", err);
      },
    });

  //- getTableInfoType
  const {
    mutate: getTableInfoTypeMutation,
    isPending: getTableInfoTypeIsPending,
  } = useMutation({
    mutationFn: () => MarketService.getTableInfoType(),
    onSuccess: (res) => {
      console.log("getTableInfoTypeMutation", res);
      if (res.code === 200 && res.success && res.data.length) {
        setTableInfoTypeList(
          res.data.map((e: { key: number; name: string }) => {
            return {
              id: e.key.toString(),
              text: e.name,
              enumKey: e.key,
            } as OptionModel;
          })
        );
      } else {
        setTableInfoTypeList([]);
      }
    },
    onError: (err) => {
      console.log("getTableInfoTypeMutation", err);
    },
  });

  //- getCompanyImportExcelExample
  const {
    mutate: getCompanyImportExcelExampleMutation,
    isPending: getCompanyImportExcelExampleIsPending,
  } = useMutation({
    mutationFn: () =>
      MarketService.getCompanyImportExcelExample(industryYearId),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        setModal3((prev) => {
          return {
            ...prev,
            show: !prev.show,
            sampleDownloadStream: res,
            sampleName: "公司資料範本",
          };
        });
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log("getCompanyImportExcelExampleMutation", err);
    },
  });

  //- useEffect industryYearId
  useEffect(() => {
    if (!industryYearId) return;
    getSasbOptionMutation();
    getTableInfoTypeMutation();

    getChangeCompanyOptionMutation({
      page: 0,
      pageSize: 0,
      sortKey: null,
      industryYearId: industryYearId
    });

    getChangeMarketOptionMutation({
      page: 0,
      pageSize: 0,
      sortKey: null,
      industryYearId: industryYearId
    });

    getCurrentMarketOptionMutation(industryYearId);

  }, [industryYearId, getSasbOptionMutation, getTableInfoTypeMutation]);

  //- addCompany
  const { mutate: addCompanyMutation, isPending: addCompanyIsPending } =
    useMutation({
      mutationFn: (request: EditCompanyModel) =>
        MarketService.addCompany(request),
      onSuccess: (res) => {
        console.log("addCompanyMutation", res);
        if (res.code === 200 && res.success) {
          success2Alert(res.message);
          setCustomModalProps((prev) => ({ ...prev, show: false }));
          resetEditCompanyData();
          setCompanyDataValidObj(new EditCompanyValidModel());
          getCompanyListMutation(companyListRequestData);
        } else {
          error2Alert(res.message);
        }
      },
      onError: (err) => {
        console.log("addCompanyMutation", err);
        error2Alert("執行失敗");
      },
    });

  //- editCompany
  const { mutate: editCompanyMutation, isPending: editCompanyIsPending } =
    useMutation({
      mutationFn: (variables: { id: string; request: EditCompanyModel }) =>
        MarketService.editCompany(variables.id, variables.request),
      onSuccess: (res) => {
        console.log("editCompanyMutation", res);
        if (res.code === 200 && res.success) {
          success2Alert(res.message);
          setCustomModalProps((prev) => ({ ...prev, show: false }));
          resetEditCompanyData();
          setCompanyDataValidObj(new EditCompanyValidModel());
          getCompanyListMutation(companyListRequestData);
        } else {
          error2Alert(res.message);
        }
      },
      onError: (err) => {
        console.log("editCompanyMutation", err);
        error2Alert("執行失敗");
      },
    });

  //- useEffect companyListRequestData
  useEffect(() => {
    getCompanyListMutation(companyListRequestData);
  }, [companyListRequestData, getCompanyListMutation]);

  // -useEffect companyList
  useEffect(() => {

    setTablePagination((prev) => {
      if (companyList?.list.length) {
        return {
          ...prev,
          page: companyListRequestData.page ?? 1,
          pageSize: companyListRequestData.pageSize ?? 10,
          total: companyList.count,
        };
      } else {
        return {
          ...prev,
          page: 0,
          total: 0,
        };
      }
    });
  }, [companyList, companyListRequestData]);


  // 轉換公司的選單
  const {
    mutate: getChangeCompanyOptionMutation,
    isPending: getCompanyOptionIsPending,
  } = useMutation({
    mutationFn: (request: IndustryManageRequestModel) =>
      MarketService.getCompanyOption(request),
    onSuccess: (res) => {
      //console.log("公司OPTION", res);
      if (res.code === 200 && res.success) {
        res.data.forEach((key: any) => {
          key.text = key.companyCode + ' - ' + key.text;
        });
        setCompanyOption(res.data);
      } else {
        setCompanyOption([]);
      }
    },
    onError: (err) => {
      console.log("getChangeCompanyOptionMutation", err);
    },
  });

  // 轉換市場別選單
  const {
    mutate: getChangeMarketOptionMutation,
    isPending: getMarketOptionIsPending,
  } = useMutation({
    mutationFn: (request: IndustryManageRequestModel) =>
      MarketService.getMarketOption(request),
    onSuccess: (res) => {
      //console.log("轉換市場別選單", res);
      if (res.code === 200 && res.success) {
        setMarketTypeOption(res.data);
      } else {
        setMarketTypeOption([]);
      }
    },
    onError: (err) => {
      console.log("getChangeMarketOptionMutation", err);
    },
  });



  //取得目前市場別
  const {
    mutate: getCurrentMarketOptionMutation
  } = useMutation({
    mutationFn: (request: string) =>
      MarketService.getMarketType(industryYearId),
    onSuccess: (res) => {
      //console.log("取得目前市場別", res);
      if (res.code === 200 && res.success) {
        setCurrentMarketType(res.data);
      } else {
        setCurrentMarketType(null);
      }
    },
    onError: (err) => {
      console.log("getChangeMarketOptionMutation", err);
    },
  });

  //- batchDeleteCompanies
  const {
    mutate: batchDeleteCompaniesMutation,
    isPending: batchDeleteCompaniesIsPending,
  } = useMutation({
    mutationFn: () => MarketService.batchDeleteCompanies(batchCompanyList),
    onSuccess: (res) => {
      console.log("batchDeleteCompaniesMutation", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        setBatchCompanyList([]);
        getCompanyListMutation(companyListRequestData);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("batchDeleteCompaniesMutation", err);
      error2Alert("執行失敗");
    },
  });

  //- useEffect companyList
  useEffect(() => {
    const companyListNodeContent = (): React.ReactNode => {
      if (
        getCompanyListIsPending ||
        getIndustryOptionIsPending ||
        getSasbOptionIsPending ||
        getTableInfoTypeIsPending ||
        batchDeleteCompaniesIsPending ||
        getCompanyImportExcelExampleIsPending
      ) {
        return <VisuallLoading />;
      }

      const handleCheckboxChange = (company: CompanyModel) => {
        if (batchCompanyList.includes(company.id!)) {
          setBatchCompanyList((prev) => prev.filter((e) => e !== company.id!));
        } else {
          setBatchCompanyList((prev) => [...prev, company.id!]);
        }
      };

      const editValidateCompanyData = (): boolean => {
        const tempValidObj = new EditCompanyValidModel();
        let isValid = true;

        const setAfterAlertFunc = () => {
          setCompanyDataValidObj({ ...tempValidObj });
          setCustomModalProps((prev) => ({
            ...prev,
            children: (
              <>
                {editCompanyIsPending && <VisuallLoading />}
                <CompnayModdalContentComponent
                  companyData={companyData}
                  tableInfoOptions={tableInfoTypeList}
                  sasbOptionList={sasbOptionList}
                  industryOptionList={industryOptionList}
                  companyDataValidObj={tempValidObj}
                />
              </>
            ),
          }));
        };
        setAfterAlertFunc();
        // Step 1: Check for required fields
        Object.keys(companyData).forEach((key) => {
          const value = companyData[key as keyof EditCompanyModel];
          const excludeKeys = ["sasbIndustryId", "tableInfo"];
          if (
            !excludeKeys.includes(key) &&
            (value === undefined ||
              value === null ||
              (typeof value === "string" && value.trim() === ""))
          ) {
            tempValidObj[key as keyof EditCompanyValidModel] = false;
            isValid = false;
          }
        });
        if (!isValid) {
          setAfterAlertFunc();
          error2Alert("請填寫完整");
          return isValid;
        }
        return isValid;
      };

      //- openEditCompanyModal
      const openEditCompanyModal = (company: CompanyModel) => {
        companyData.industryYearId = industryYearId;
        companyData.companyCode = company.companyCode;
        companyData.companyName = company.companyName;
        companyData.companyShortName = company.companyShortName;
        companyData.industryId = company.industryId;
        companyData.sasbIndustryId = company.sasbIndustryId;
        companyData.tableInfo = company.tableInfo;
        companyData.highRevenueIndicator = company.highRevenueIndicator;
        setTimeout(() => {
          setCustomModalProps({
            show: true,
            title: "編輯公司",
            children: (
              <>
                {editCompanyIsPending && <VisuallLoading />}
                <CompnayModdalContentComponent
                  companyData={companyData}
                  tableInfoOptions={tableInfoTypeList}
                  sasbOptionList={sasbOptionList}
                  industryOptionList={industryOptionList}
                  companyDataValidObj={new EditCompanyValidModel()}
                />
              </>
            ),
            handleClose: () => {
              if (editCompanyIsPending) return;
              setCustomModalProps((prev) => ({ ...prev, show: false }));
              resetEditCompanyData();
              setCompanyDataValidObj(new EditCompanyValidModel());
            },
            handleConfirm: () => {
              if (editCompanyIsPending) return;
              if (!editValidateCompanyData()) return;
              editCompanyMutation({ id: company.id!, request: companyData });
            },
          });
        }, 10);
      };

      return (
        <>
          <ScrollToTopButton
            targetClassName={`${styles["company-list-box"]}`}
            bottom={90}
            right={35}
          />
          <table
            aria-label="table"
            className={`${styles["setting-table"]} table-container sticky-table`}
          >
            <thead>
              <tr>
                {isEdit && (
                  <th
                    scope="col"
                    className={`${styles["setting-checkbox"]}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <label className={`custom-checkbox hideText`}>
                      <input
                        type="checkbox"
                        checked={
                          companyList.list.length === batchCompanyList.length
                        }
                        onChange={() => {
                          if (
                            companyList.list.length === batchCompanyList.length
                          ) {
                            setBatchCompanyList([]);
                          } else {
                            setBatchCompanyList(
                              companyList.list.map((e) => e.id!)
                            );
                          }
                        }}
                      />
                      <span
                        className={`${
                          batchCompanyList.length > 0 &&
                          companyList.list.length > batchCompanyList.length
                            ? "partial"
                            : ""
                        }`}
                      ></span>
                    </label>
                  </th>
                )}
                <th scope="col">公司代號</th>
                <th scope="col">公司名稱</th>
                <th scope="col">公司簡稱</th>
                <th scope="col">產業類別</th>
                <th scope="col">SASB產業別</th>
                <th scope="col">附表</th>
                <th scope="col">餐飲營收50%以上</th>
                <th scope="col">資本額</th>
              </tr>
            </thead>
            <tbody>
              {companyList.list.length ? (
                companyList.list.map((company, companyIndex) => (
                  <tr
                    key={companyIndex}
                    onClick={() =>
                      detectEveryActionPermission([
                        IndustryManageActionsPermissionCodes.CompanyManageUpdate,
                      ])
                        ? openEditCompanyModal(company)
                        : null
                    }
                  >
                    {isEdit && (
                      <td
                        className={`${styles["setting-checkbox"]}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <label className={`custom-checkbox hideText`}>
                          <input
                            type="checkbox"
                            checked={batchCompanyList.includes(company.id!)}
                            onChange={() => handleCheckboxChange(company)}
                          />
                          <span></span>
                        </label>
                      </td>
                    )}
                    <td>{company.companyCode}</td>
                    <td>{company.companyName}</td>
                    <td>{company.companyShortName}</td>
                    <td>{company.industryName}</td>
                    <td>{company.sasbIndustryName}</td>
                    <td>{company.tableInfoName}</td>
                    <td>
                      {company.highRevenueIndicator === true ? "是" : "否"}
                    </td>
                    <td>{company.capitalAmount}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9} className="text-center">
                    查無資料
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      );
    };

    setCompanyListNode(companyListNodeContent());
  }, [
    detectEveryActionPermission,
    batchCompanyList,
    batchDeleteCompaniesIsPending,
    getCompanyImportExcelExampleIsPending,
    companyData,
    companyList,
    getCompanyListIsPending,
    getIndustryOptionIsPending,
    getTableInfoTypeIsPending,
    getSasbOptionIsPending,
    industryYearId,
    isEdit,
    editCompanyIsPending,
    getCompanyListMutation,
    editCompanyMutation,
    industryOptionList,
    sasbOptionList,
    tableInfoTypeList
  ]);

  const [customModalProps, setCustomModalProps] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {},
    handleConfirm: () => {},
  });

  const resetEditCompanyData = () => {
    setCompanyData(new EditCompanyModel());
  };

  const validateCompanyData = (): boolean => {
    const tempValidObj = new EditCompanyValidModel();
    let isValid = true;

    const setAfterAlertFunc = () => {
      setCompanyDataValidObj({ ...tempValidObj });
      setCustomModalProps((prev) => ({
        ...prev,
        children: (
          <>
            {addCompanyIsPending && <VisuallLoading />}
            <CompnayModdalContentComponent
              companyData={companyData}
              tableInfoOptions={tableInfoTypeList}
              sasbOptionList={sasbOptionList}
              industryOptionList={industryOptionList}
              companyDataValidObj={tempValidObj}
            />
          </>
        ),
      }));
    };
    setAfterAlertFunc();
    // Step 1: Check for required fields
    Object.keys(companyData).forEach((key) => {
      const value = companyData[key as keyof EditCompanyModel];
      console.log(key, value);
      const excludeKeys = ["sasbIndustryId", "tableInfo"];
      if (
        !excludeKeys.includes(key) &&
        (value === undefined ||
          value === null ||
          (typeof value === "string" && value.trim() === ""))
      ) {
        tempValidObj[key as keyof EditCompanyValidModel] = false;
        isValid = false;
      }
    });
    if (!isValid) {
      setAfterAlertFunc();
      error2Alert("請填寫完整");
      return isValid;
    }
    // Step 2: Check company code format
    const companyCodeRegex = /^[0-9][0-9]{3}$|^[0-9][0-9]{5}$/;
    if (
      companyData.companyCode &&
      !companyCodeRegex.test(companyData.companyCode)
    ) {
      tempValidObj.companyCode = false;
      isValid = false;
    }
    if (!isValid) {
      setAfterAlertFunc();
      error2Alert("公司代號格式錯誤");
      return isValid;
    }
    return isValid;
  };

  //- openAddCompanyModal
  const openAddCompanyModal = () => {
    companyData.industryYearId = industryYearId;
    // companyData.marketType =
    setCustomModalProps({
      show: true,
      title: "新增公司",
      children: (
        <>
          {addCompanyIsPending && <VisuallLoading />}
          <CompnayModdalContentComponent
            companyData={companyData}
            tableInfoOptions={tableInfoTypeList}
            sasbOptionList={sasbOptionList}
            industryOptionList={industryOptionList}
            companyDataValidObj={companyDataValidObj}
          />
        </>
      ),
      handleClose: () => {
        if (addCompanyIsPending) return;
        setCustomModalProps((prev) => ({ ...prev, show: false }));
        resetEditCompanyData();
        setCompanyDataValidObj(new EditCompanyValidModel());
      },
      handleConfirm: () => {
        if (addCompanyIsPending) return;
        if (!validateCompanyData()) return;
        addCompanyMutation(companyData);
      },
    });
  };

  const resetTransferData = () => {
    setTransferData(new TransferIndustryModel());
  };

  const transferVaild = (): boolean => {
    let isValid = true;
    const tempValidObj = new TransferIndustryValidModel();
    setTransferValidObj({ ...tempValidObj });

    const setAfterAlertFunc = () => {
      setTransferValidObj({ ...tempValidObj });
      setCustomModalProps((prev) => ({
        ...prev,
        children: (
          <>
            {/*addCompanyIsPending && <VisuallLoading />*/}
            <TranferIndustryConentComponent
              transferData={transferData}
              comapnyOptionList={companyOption}
              marketTypeOptionList={marketTypeOption}
              transferDataValidObj={tempValidObj}
          />
          </>
        ),
      }));
    };
    setAfterAlertFunc();
    // Step 1: Check for required fields
    if(transferData.companyCode=== null ||
      transferData.companyCode === undefined
    ) {
      tempValidObj.companyCode = false;
      isValid = false;
    } else {
      tempValidObj.companyCode = true;
    }
    if(transferData.marketType=== null||
      transferData.marketType === undefined
    ) {
      tempValidObj.marketType = false;
      isValid = false;
    } else {
      tempValidObj.marketType = true;
    }

    if(transferData.industryId=== null ||
      transferData.industryId === undefined
    ) {
      tempValidObj.industryId = false;
      isValid = false;
    } else {
      tempValidObj.industryId = true;
    }

    if (!isValid) {
      setAfterAlertFunc();
      error2Alert("請填寫完整");
      return isValid;
    }
    return isValid;
  };

  //- openTranferIndustryModal
  const openTranferIndustryModal = () => {
    companyData.industryYearId = industryYearId;
    setCustomModalProps({
      show: true,
      title: "轉移公司",
      children: (
        <>
          {/*addCompanyIsPending && <VisuallLoading />*/}
          <TranferIndustryConentComponent
            transferData={transferData}
            comapnyOptionList={companyOption}
            marketTypeOptionList={marketTypeOption}
            transferDataValidObj={transferValidObj}
          />
        </>
      ),
      handleClose: () => {
        //if (addCompanyIsPending) return;
        setCustomModalProps((prev) => ({ ...prev, show: false }));
        resetTransferData();
        console.log(transferData.companyCode);
        //setTransferData((prev) => ({ ...prev, industryIdVaild: undefined, companyCodeVaild: undefined }));
      },
      handleConfirm: () => {
        if (changeIndustryIsPending) return;
        if (!transferVaild()) return;
        console.log(transferData);
        changeIndustryMutation({
          industryYearId: industryYearId,
          marketType: currentMarketType,
          companyCode: transferData.companyCode,
          changeMarketType: transferData.marketType,
          changeIndustryYearId: transferData.marketTypeId ? transferData.marketTypeId : '',
          changeIndustryId: transferData.industryId,
        })
      },
    });
  };

  //- 轉移公司市場別
  const {
    mutate: changeIndustryMutation,
    isPending: changeIndustryIsPending,
  } = useMutation({
    mutationFn: (request: ChangeIndustryModel) =>
      MarketService.changeIndustry(transferData.companyId, request),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        setCustomModalProps((prev) => ({ ...prev, show: false }));
        getCompanyListMutation(companyListRequestData);
        resetTransferData();
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("changeIndustryMutation", err);
      error2Alert("執行失敗");
    },
  });

  //- batchAddCompanies
  const {
    mutate: batchAddCompaniesMutation,
    isPending: batchAddCompaniesIsPending,
  } = useMutation({
    mutationFn: (request: FormData) =>
      MarketService.batchAddCompanies(industryYearId, request),
    onSuccess: (res) => {
      console.log("batchAddCompaniesMutation", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        setModal3((prev) => {
          return {
            ...prev,
            show: !prev.show,
          };
        });
        getCompanyListMutation(companyListRequestData);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("batchAddCompaniesMutation", err);
      error2Alert("執行失敗");
    },
  });

  //- useEffect companyData
  // useEffect(() => {
  //   console.log("companyData", companyData);
  // }, [companyData]);

  //- useEffect isEdit
  useEffect(() => {
    if (!isEdit) {
      setBatchCompanyList([]);
    }
  }, [isEdit]);

  const [modal3, setModal3] = useState<BatchUploadModalProps>({
    show: false,
    handleClose: () => {
      setModal3((prev) => {
        return {
          ...prev,
          show: !prev.show,
        };
      });
    },
    handleUpload: (e: FileList) => {
      const formData = new FormData();
      for (let i = 0; i < e.length; i++) {
        formData.append("file", e[i]);
      }
      console.log(formData);
      batchAddCompaniesMutation(formData);
    },
  });

  useEffect(() => {
    setModal3((prev) => {
      return {
        ...prev,
        uploadLoading: batchAddCompaniesIsPending,
      };
    });
  }, [batchAddCompaniesIsPending]);

  /** 設置餐飲項目 */
  const changeIsHighRevenueIndicator = (msg: string | null) => {
    let selHighRevenueIndicator: boolean | null = null;
    switch (msg) {
      case "0":
        selHighRevenueIndicator = true;
        break;
      case "1":
        selHighRevenueIndicator = false;
        break;
      case "-1":
      default:
        selHighRevenueIndicator = null;
        break;
    }

    setCompanyListRequestData((prev) => ({
      ...prev,
      isHighRevenueIndicator: selHighRevenueIndicator,
    }));
  };
  return (
    <div className={`${styles["company-box"]}`}>
      <div className="title-box">
        <BackTitle title={title} url={`/admin/industry`} />
      </div>
      <div
        style={{
          paddingTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
          borderTop: "1px solid #dbe8ef",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className={styles["search-box"]}>
            <div style={{ width: "170px" }}>
              <SearchInput
                onClick={(e) => {
                  setCompanyListRequestData((prev) => ({
                    ...prev,
                    searchKey: e,
                  }));
                }}
                placeholder={"搜尋"}
              />
            </div>
            <div className={styles["revenue-indicator-box"]}>
              <div className={styles["title"]}>餐飲營收50%</div>
              <div style={{width:"100px"}}>
                <Dropdown
                  onChange={(msg: string | null) => {
                    changeIsHighRevenueIndicator(msg);
                    setIsHighRevenueIndicator(msg);
                  }}
                  options={foodParmaArr}
                  defaultId={isHighRevenueIndicator}
                />
              </div>
            </div>
          </div>
          {batchCompanyList.length ? (
            <div
              style={{
                width: "120px",
              }}
            >
              {detectEveryActionPermission([
                IndustryManageActionsPermissionCodes.CompanyManageDelete,
              ]) ? (
                <button
                  className="default ms-3"
                  onClick={() => batchDeleteCompaniesMutation()}
                >
                  刪除
                </button>
              ) : null}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {<button className="default me-3" onClick={() => openTranferIndustryModal()}>
              轉移
          </button>}
          <button
            className="secondary me-3"
            onClick={() => {
              setIsEdit(!isEdit);
            }}
          >
            多筆編輯
          </button>
          {detectEveryActionPermission([
            IndustryManageActionsPermissionCodes.CompanyManageImport,
          ]) ? (
            <>
              <button
                className="secondary me-3"
                onClick={() => {
                  getCompanyImportExcelExampleMutation();
                }}
              >
                批次匯入
              </button>
              <BatchUploadModal {...modal3} />
            </>
          ) : null}
          {detectEveryActionPermission([
            IndustryManageActionsPermissionCodes.CompanyManageCreate,
          ]) ? (
            <button className="default" onClick={() => openAddCompanyModal()}>
              + 新增
            </button>
          ) : null}
          <ConfirmModal {...customModalProps} />
        </div>
      </div>

      <div className={`${styles["company-list-box"]} scroll`}>
        {companyListNode}
      </div>

      <div className={`${styles["table-pagination-box"]}`}>
        <TablePagination
          total={tablePagination.total!}
          onChange={(e) => {
            setCompanyListRequestData((prev) => ({
              ...prev,
              page: e.page,
              pageSize: e.pageSize,
            }));
          }}
          page={tablePagination.page}
          pageSize={tablePagination.pageSize}
        />
      </div>
    </div>
  );
};

export default Company;
