import React, { FC, useEffect } from "react";
import styles from "./Redirection.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import AuthService from "services/auth/authService";
import { AuthInfoModel, AuthModel } from "models/auth/authModel";
import useUserInfoStore from "state/useUserInfoStore";
import { redirectEntrance } from "utils/redirectExternal";
import { errorAlert } from "utils/otherToast";
import { ResponseCode } from "models/responseCodeModel";

interface RedirectionProps {}

const Redirection: FC<RedirectionProps> = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  // 轉跳驗證資訊
  const { userInfo, setUserInfo } = useUserInfoStore();
  // 登入資訊
  const auth = useMutation({
    mutationFn: (model: AuthModel) => AuthService.auth(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const info: AuthInfoModel = res.data;
        if (info) {
          // 儲存資訊
          setUserInfo(info);
          if (info.isAdmin) {
            navigate("/admin", {
              replace: true,
            });
          } else {
            navigate("/apply/home", {
              replace: true,
            });
          }
        }else{
          redirectEntrance();
        }
      } else {
        errorAlert(res.message);
        setTimeout(() => {
          redirectEntrance();
        }, 800);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    // 清除所有紀錄
    sessionStorage.clear();
    localStorage.clear();
    auth.mutate({
      userId: params.get("user_id"),
      eventKey: params.get("event_key"),
    });
  }, []);

  // 導向頁
  return (
    <div className={styles["redirection-box"]}>
      <img
        className="refresh"
        alt="redirect"
        src="/assets/images/buttonIcon/refresh-icon.svg"
      />
      <div>頁面導向中...</div>
    </div>
  );
};

export default Redirection;
