import React, {
  FC,
  LegacyRef,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import styles from "./NormalInput.module.scss";
import _ from "lodash";
import FloatCalculateService from "services/common/floatCalculateService";
interface NormalInputProps {
  /** 單一元件 */
  id?: string;
  placeholder?: string;
  unit?: string | null;
  defaultValue?: any;
  //value?: string | null;
  onChange: (key: any) => void;
  disabled?: boolean | null;
  valid?: boolean;
  type?: "text" | "number" | "date" | string;
  /** 浮動title */
  isFloatTitle?: boolean;
  /** 限制小數點位 */
  limitFloatDigits?: number | null;
  /** 限制整數位數(預設9位) */
  limitIntegerDigits?: number | null;
  /** 可為負數 */
  canNegative?: boolean;
  /** 延遲輸入時間 */
  debounceTime?: number;
  /** 是否為百分比 */
  isPercent?: boolean;
}

const NormalInput = forwardRef(
  (
    {
      type = "text",
      valid = true,
      canNegative = true,
      limitIntegerDigits = 9,
      debounceTime = 0,
      isPercent = false,
      ...props
    }: NormalInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [value, setValue] = useState<any>("");

    // 延遲輸出
    const debounce = _.debounce((_searchVal) => {
      props.onChange(_searchVal);
    }, debounceTime);

    // key change
    const handleChangeKey = (msg: any) => {
      let newMsg = msg;
      // /^\d{1,5}(?:\.\d{1,2})?$/
      // 卡控位數
      if (type === "number" && newMsg) {
        const strs = newMsg.split(".");
        let realValue = "";
        strs.forEach((str: string, index: number) => {
          // 有兩筆則是小數點
          if (index === 0) {
            let regexStr1 = "[0-9]";
            // 限制正數
            if(limitIntegerDigits !== null){
              regexStr1 += "{0," + limitIntegerDigits + "}";
            }else{
              regexStr1 += "{0,9}";
            }
            const regex: RegExp = new RegExp(regexStr1, "g");
            // 百分比卡控
            str = percentCondition(str);
            const pass = regex.test(str);
            if (pass) {
              realValue = str.match(regex)?.[0] || "";
            }
            // console.log('pass',pass)
          } else if (index === 1) {
            let regexStr2 = "[0-9]";
            // 限制小數
            if (props.limitFloatDigits !== null) {
              regexStr2 += "{0," + props.limitFloatDigits + "}";
            } else {
              regexStr2 += "*";
            }
            const regex2: RegExp = new RegExp(regexStr2, "g");
            // 查看整數
            if (isPercent && strs[0] === "100") {
              str = "00";
            }

            const pass2 = regex2.test(str);
            if (pass2) {
              realValue += `.${str.match(regex2)?.[0]}`;
            }
          }
        });
        // 移除數值前的0
        newMsg = FloatCalculateService.removeLeadingZeros(realValue);
      }
      setValue(newMsg);
      debounce(newMsg);
    };

    // 百分比條件
    const percentCondition = (orgStr: string): string => {
      let str = orgStr;
      // 百分比只接受最大100
      if (isPercent && Number.parseFloat(str) > 100) {
        str = "100";
      }
      return str;
    };

    useEffect(() => {
      if (props.defaultValue === null) {
        setValue("");
      } else {
        // 避免0 字串會false 無法顯示
        let value = props.defaultValue;
        if (value === 0) {
          value = props.defaultValue.toString();
        }
        setValue(value);
      }
    }, [props.defaultValue]);

    // 移除滾輪事件
    useEffect(() => {
      // 只有number需要
      const element = props.id
        ? document.getElementById(`input_number_${props.id}`)
        : document.getElementById(`input_number`);
      // passive: false 需增加，否則chrome 預設true，直接用preventDefault會出錯
      const wheelFun = (e: WheelEvent) => {
        e.preventDefault();
      };
      const keypressFun = (e: KeyboardEvent) => {
        if (type === "number") {
          // 不能輸入負數
          if (e.key === "-" && !canNegative) {
            e.preventDefault();
          }
          // 不能輸入小數
          if (e.key === "." && props.limitFloatDigits === 0) {
            e.preventDefault();
          }
        }
      };
      element?.addEventListener("wheel", wheelFun, { passive: false });
      element?.addEventListener("keypress", keypressFun, { passive: false });
      return () => {
        element?.removeEventListener("wheel", wheelFun);
        element?.removeEventListener("keypress", keypressFun);
      };
    }, []);

    return (
      <div
        className={
          `${styles["normal-input-box"]} ` +
          `${valid ? "" : "invalid"} ` +
          `${props.disabled ? "disabled" : ""} `
        }
      >
        <input
          id={props.id ? `input_${type}_${props.id}` : `input_${type}`}
          className={`seach-input `}
          max={type === "date" ? "9999-12-31" : ""}
          ref={ref || null}
          type={type}
          value={value || ""}
          placeholder={props.placeholder}
          onChange={($event) => handleChangeKey($event.target.value)}
        />
        {/* 浮動title */}
        {props.isFloatTitle && (
          <div className="placeholder-item">{props.placeholder}</div>
        )}
        { (value && !props.disabled) && (
          <div className="clear-btn" onClick={() => handleChangeKey("")}>
            <img alt="" src="/assets/images/buttonIcon/input-clear-icon.svg" />
          </div>
        )}
        <div className="unit">{props.unit}</div>
      </div>
    );
  }
);

export default NormalInput;
