import { MarketTypeEnum, OptionModel } from "models/baseModel";

/** 系統操作紀錄 */
export interface EduResourceDirectoryModel {
    /** 目錄Id */
    id: string;
    /** 目錄名稱 */
    name: string;
    /**修改日期 */
    updateTime: Date;
}

/** 編輯教育宣導資源管理模組 */
export interface EditEduResourceDirectoryModel {
    /** 目錄新名稱 */
    newName: string;
    /** 目錄id */
    id?: string;
}

/** 新增刪除教育宣導資源管理模組 */
export interface AddEduResourceDirectoryModel {
    /** 目錄名稱 */
    name: string;
}

//#region 檔案相關MODEL


/**編輯教育宣導資源管理-編輯檔案 */
export interface EditEduResourceFileModel {
    /** 對應Id */
    eduResourceDirectoryFileMapId: string;
    /** 類型名稱Id */
    directoryId: string;
    /** 類型名稱 */
    directoryName: string;
    /** 檔案Id */
    uploadFileLogId: string;
    /** 檔案名稱 */
    fileName: string;
    /** 檔案權限模組 */
    marketFileReadDatas: MarketFileReadModel[] | null;
    /** 實際檔案的id 跟upload 不同 */
    fileId? :string | null;
}

/** 檔案權限模組 */
export interface MarketFileReadModel {
    /** 類型 */
    type: EduReadPermissionType;
    /** 市場別 */
    marketType: MarketTypeEnum;
    /** 系統功能按鈕Id */
    systemManageId: string | null;
}

/** 新增教育宣導資源管理-新增檔案 */
export interface AddEduResourceFileModel {
    /** 類型名稱Id */
    directoryId: string;
    /** 檔案權限模組 */
    marketFileReadDatas: MarketFileReadModel[] | null;
}

/** 教育宣導讀取型態 */
export enum EduReadPermissionType {
    /** 市場別 */
    MarKet,
    /** 系統管理功能按鈕 */
    SystemManage
}

export interface EduResourceFileListModel {
    id: string;
    name: string;
    directoryName: string;
    status: boolean;
}

/** 編輯教育宣導資源管理狀態模組 */
export interface EditEduResourceFileStatusModel {
    id: string;
    status: boolean;
}

/** 前端市場別header model */
export interface EduMarketHedareModel {
    name: string;
    key: number;
    checked: boolean;
}

/** 前端系統權限market */
export interface EduSystemPermissionMarketModel extends OptionModel{
    /** 市場別勾選model */
    markets: EduMarketHedareModel[];
}


//#endregion
