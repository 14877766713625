import React, { FC, useEffect, useState } from "react";
import styles from "./RGstep4.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import {
  Multilingual,
  SustainReportExportModel,
} from "models/application/sustainReportDeclare/sustainReportDeclareModel";
import { error2Alert, success2Alert } from "utils/otherToast";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import { useMutation } from "@tanstack/react-query";
import { ResponseCode } from "models/responseCodeModel";
import { CommonService } from "services/common/commonService";
import { StreamFileType } from "models/baseModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";

interface RGstep4Props {}

const RGstep4: FC<RGstep4Props> = () => {
  const navigate = useNavigate();
  const params = useParams();


  /** 前往列表 */
  const gotoList = () => {
    navigate("/apply/report-generation/list");
  };

  //#region API區塊

  /** 匯出報告書 */
  const exportPOCReport = useMutation({
    mutationFn: (data: SustainReportExportModel) =>
      SustainReportDeclareService.exportPOCReport(data),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        success2Alert("匯出成功");
        CommonService.downloadByStream(res, StreamFileType.Docx);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  //#endregion

  //#region 方法區塊
  const handleExport = () => {
    exportPOCReport.mutate({
      produceId: params.id!,
      multilingual: Multilingual.Chinese
    });
  };
  //#endregion

  //- 畫面初始先匯出報告書一次
  useEffect(() => {
    handleExport();
  }, []);

  return (
    <div className={styles["rg-step4-box"]}>
      {(exportPOCReport.isPending) && <VisuallLoading />}
      <div className="result-box">
        <img
          className="finish-bg"
          alt="report-finish-bg"
          src="/assets/images/declaration/reportGenetation/report-finish-bg.svg"
        />
        <div className="sentence1">恭喜你，已完成報告書初稿產製作業</div>
        <div className="sentence2">
          若未成功匯出報告書，可再次
          <span
            className="link"
            onClick={() => {
              handleExport();
            }}
          >
            下載檔案
          </span>
          。
          <br />
          可直接點擊上方步驟，回至該步驟做後續調整及編輯。
        </div>
        <div>
          <button
            className="default"
            onClick={() => {
              gotoList();
            }}
          >
            查看年度列表
          </button>
        </div>
      </div>
    </div>
  );
};

export default RGstep4;
