import { FC, useEffect, useState } from "react";
import styles from "./TabVerticalThin.module.scss";
import { useNavigate } from "react-router-dom";
import { NavBarItemModel } from "models/baseModel";

interface TabVerticalThinProps {
  data: NavBarItemModel[];
  tabKey: string;
  //onClick: () => void;
  state?: { [key: string]: unknown };
  /** 是否處理其他事件(直接往外丟事件就好) */
  handleOtherEvent?: boolean;
  /** 改變item */
  changeItem?: (item: NavBarItemModel) => void;
}

const TabVerticalThin: FC<TabVerticalThinProps> = ({
  handleOtherEvent = false,
  ...props
}) => {
  const [navItems] = useState<NavBarItemModel[]>(props.data);
  const [tabKey, setTabKey] = useState<string>(props.tabKey);
  const [state] = useState<{ [key: string]: unknown } | undefined>(props.state);
  const navigate = useNavigate();
  const handleEvent = (item: NavBarItemModel) => {
    if (item.disabled === false) {
      if (!handleOtherEvent) {
        if (item.key) {
          setTabKey(item.key);
        }
        if (state) {
          navigate(item.url, { state: state });
        } else {
          navigate(item.url);
        }
      } else {
        if (props.changeItem) {
          // 處理其他事件 (不導向)
          props.changeItem(item);
        }
      }
    }
  };

  useEffect(() => {
    setTabKey(props.tabKey);
  }, [props.tabKey]);

  return (
    <div className={`${styles["tabVerticalThin"]}`}>
      <div className={"tree-box scroll"}>
        <ul>
          {navItems.map((p, index) => {
            return (
              <li
                key={index}
                className={`${tabKey === p.key ? "active" : ""} ${
                  p.disabled === true ? "disabled" : ""
                }`}
                onClick={() => handleEvent(p)}
              >
                {p.name} {p.disabled}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TabVerticalThin;
