import { FC } from "react";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
import Industry from "./Industry/Industry";
import Company from "./Company/Company";
import { NavBarItemModel } from "models/baseModel";
import Navbar from "components/layouts/Navbar/Navbar";
import List from "./List/List";
import styles from "./IndustrtRouter.module.scss";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import {
  FeaturePermissionCodes,
  PermissionRouteObjectModel,
  PermissionNavBarItemModel,
  ActionsPermissionCodes,
} from "models/auth/permissionModel";
import EmptyWaitPage from "components/common/EmptyWaitPage/EmptyWaitPage";
import useUserInfoStore from "state/useUserInfoStore";
import { usePermissionContext } from "context/PermissionProvider";

export const IndustryLayout: FC = () => {
  const { PermissionState, PermissionService } =
    usePermissionContext();
  const { noPermissionPage } = PermissionState;
  const { detectEveryFeatruePermission } = PermissionService;
  const navItems: PermissionNavBarItemModel[] = [
    {
      name: "產業類別",
      url: "industry",
      permission: FeaturePermissionCodes.IndustryManage.IndustryCategory,
    },
    {
      name: "公司管理",
      url: "company",
      permission: FeaturePermissionCodes.IndustryManage.CompanyManage,
    },
  ];
  const filterNavItems = navItems.filter((e) =>
    detectEveryFeatruePermission([e.permission])
  );
  return (
    <>
      <CustomErrorBoundary>
        {filterNavItems?.length ? (
          <>
            {/* tab 資訊 */}
            <Navbar items={filterNavItems} />
            <div className="nav-content-box">
              <Outlet></Outlet>
            </div>
          </>
        ) : (
          noPermissionPage()
        )}
      </CustomErrorBoundary>
    </>
  );
};

interface IndustryRouterProps {}
const IndustryRouter: FC<IndustryRouterProps> = () => {
  //* ---------------------------- state start ---------------------------- *//
  const { PermissionService } = usePermissionContext();
  const {
    detectSomeFeatruePermission,
    detectSomeActionPermission,
    permissionRouter,
  } = PermissionService;

  const routers: PermissionRouteObjectModel[] = [
    {
      path: "",
      element: <List />,
      featurePermission: FeaturePermissionCodes.IndustryManage.IndustryYearList,
      actionReadPermission:
        ActionsPermissionCodes.IndustryManage.IndustryYearListRead,
    },
    {
      path: ":id",
      element: <IndustryLayout />,
      featurePermission: detectSomeFeatruePermission([
        FeaturePermissionCodes.IndustryManage.IndustryCategory,
        FeaturePermissionCodes.IndustryManage.CompanyManage,
      ]),
      actionReadPermission: detectSomeActionPermission([
        ActionsPermissionCodes.IndustryManage.IndustryCategoryRead,
        ActionsPermissionCodes.IndustryManage.CompanyManageRead,
      ]),
      children: [
        {
          path: "industry",
          element: <Industry />,
          featurePermission:
            FeaturePermissionCodes.IndustryManage.IndustryCategory,
          actionReadPermission:
            ActionsPermissionCodes.IndustryManage.IndustryCategoryRead,
        },
        {
          path: "company",
          element: <Company />,
          featurePermission:
            FeaturePermissionCodes.IndustryManage.CompanyManage,
          actionReadPermission:
            ActionsPermissionCodes.IndustryManage.CompanyManageRead,
        },
      ],
    },
  ];
  //* ---------------------------- state end ---------------------------- *//

  let elements = useRoutes(permissionRouter(routers));
  return (
    <>
      <div className={styles["industry-layout"]}>{elements}</div>
    </>
  );
};

export default IndustryRouter;
