import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { SideBarItemModel } from "../../../models/baseModel";
import SideBarItem from "./SideBarItem";
import styles from "./SideBar.module.scss";
interface SidebarProps {
  items: SideBarItemModel[];
}

const Sidebar: FC<SidebarProps> = (props) => {
  const { items } = props;
  return (
    <div className={`${styles['sidebar-box']} scroll`}>
      <ul className="flex-column">
        {items.map((p, index) => {
          return (
              <SideBarItem key={index} item={p} />
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
