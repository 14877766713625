import React, { FC, useEffect, useState } from "react";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
import Description from "./Description/Description";
import List from "./List/List";
import Edit from "./Edit/Edit";
import SustainQuestionnaireLayout from "./SustainQuestionnaireLayout/SustainQuestionnaireLayout";
//! specificRules
import EditSpecialRulesProvider from "./Edit/EditSpecialRulesProvider";

interface SustainQuestionnaireRouterProps {}
const SustainQuestionnaireRouter: FC<SustainQuestionnaireRouterProps> = () => {
  // 創建router
  const routers: RouteObject[] = [
    {
      path: "*",
      element: <Navigate to={"description"} />,
    },
    {
      path: "description",
      element: <Description />,
    },
    {
      path: ":year/",
      element: (
        <EditSpecialRulesProvider>
          <SustainQuestionnaireLayout />
        </EditSpecialRulesProvider>
      ),
      children: [
        {
          path: ":sampleId/list",
          element: <List />,
        },
        {
          path: ":sampleId/edit/:dataId",
          element: <Edit />,
        },
      ],
    },
    // {
    //   path: ":year/:sampleId/edit/:dataId",
    //   element: <Edit />,
    // },
  ];
  // 相當於router
  let elements = useRoutes(routers);
  return <>{elements}</>;
};

export default SustainQuestionnaireRouter;
