import { BaseRequestModel, MarketTypeEnum } from 'models/baseModel';

/** Token 模組 */
export interface TokenRequestModel extends BaseRequestModel {
  refreshToken: string | null;
  accId: string;
}

/** 登入授權model */
export interface AuthModel {
  userId: string | null;
  eventKey: string | null;
}

/** 紀錄登入資訊 */
export interface AuthInfoModel extends AuthTokenModel {
  /** 用戶ID */
  userId: string;
  /** 帳戶類型 */
  accType: AccountType;
  /**  申報端:公司代號; 監控端:部門代號 */
  companyCode: string;
  /** 帳號代碼 (UserId 尾部兩碼) */
  subCode: string;
  /** 是否為監控端 */
  isAdmin: boolean;
  /** 顯示名稱 */
  name: string;
  /** 年度市場別 */
  marketType: MarketTypeEnum;
  /** 去年度市場別 */
  lastYearMarketType?: MarketTypeEnum;
  /** permission */
  permission: AuthPermissionModel;
}

/** 登入 token 模組 */
export interface AuthTokenModel {
  accId: string;
  accessToken: string | null;
  refreshToken: string | null;
}

/** 市場別年度資訊模組 */
export interface YearMarketInfoModel {
  /**市場別 */
  marketType: MarketTypeEnum;
  /** 年度 */
  year: number;
}

export enum AccountType {
  /** 申報端帳號 */
  Company,
  /** 證交所監控端帳號 */
  Twse,
  /** 櫃買中心監控端帳號 */
  Tpex,
}

export class AuthPermissionModel {
  roleNames: string[] = [];
  pageCodes: string[] = [];
  featureCodes: string[] = [];
  actionCodes: string[] = [];
}
