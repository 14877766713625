import { AxiosResponse } from "axios";
import http from "../context/AxiosProvider";
import { BaseResponse } from "../models/baseModel";
import { ResponseCode } from "models/responseCodeModel";

export class HttpClient {

    async get<T>(url: string, options = {}): Promise<BaseResponse<T | any>> {
        return await http.get(url, options).then(res => res.data);
    }

    async post<T>(url: string, param?: any, options = {}): Promise<BaseResponse<T | any>> {
        return await http
            .post(url, param, options).then(res => res.data);
    }

    async patch<T>(url: string, param?: any): Promise<BaseResponse<T | any>> {
        return await http
            .patch(url, param).then(res => res.data);
    }

    async put<T>(url: string, param?: any): Promise<BaseResponse<T | any>> {
        return await http
            .put(url, param).then(res => res.data);
    }

    async delete<T>(url: string): Promise<BaseResponse<T | any>> {
        return await http
            .delete(url).then(res => res.data);
    }

    async deleteByOptions<T>(url: string, param: any): Promise<BaseResponse<T | any>> {
        return await http
            .delete(url, {
                data: param
            }).then(res => res.data);
    }

    // 取得blob 額外設定
    async getBlob(url: string): Promise<AxiosResponse> {
        return await http.get(url, {
            responseType: 'blob'
        }).then((res) => {
            if (res.headers["content-type"].includes("application/json")) {
                return this.blobErrorFlow(res);
            } else {
                return res;
            }
        });
    }

    async postBlob(url: string, param?: any): Promise<AxiosResponse> {
        return await http
            .post(url, param, {
                responseType: 'blob'
            }).then((res) => {
                if (res.headers["content-type"].includes("application/json")) {
                    return this.blobErrorFlow(res);
                } else {
                    return res;
                }
            });
    }

    /** blob 失敗流程 */
    async blobErrorFlow(res: AxiosResponse<any, any>): Promise<AxiosResponse<any, any>> {
        //1.先宣告取得blob型態
        const blb = new Blob([res.data], { type: "json" });
        let reader = new FileReader();
        return new Promise((resolve, reject) => {
            // 讀的過程中出錯直接回傳
            reader.onerror = () => {
                reader.abort();
                res.status = ResponseCode.ServerErrorInternal;
                res.data = {
                    code: ResponseCode.ServerErrorInternal,
                    message: "下載失敗",
                    success: false
                };
                reject(res);
            };
            // //3.完全讀完才會
            reader.onload = (e) => {
                if (e.target &&
                    e.target.readyState === 2) {
                    if (e.target.result !== null) {
                        res.data = JSON.parse(e.target.result.toString());
                        res.status = ResponseCode.ServerErrorInternal;
                        resolve(res);
                    }
                }
            };
            // //2.再將blb型態讀出來
            reader.readAsText(blb);
        });
    }
}