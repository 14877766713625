import React, { FC } from "react";
import styles from "./SwitchCheckbox.module.scss";

interface SwitchCheckboxProps {
  checked: boolean;
  text: string | null;
  onClick: () => void;
}

const SwitchCheckbox: FC<SwitchCheckboxProps> = (props) => {
  return (
    <label
      className={`${styles["switch-checkbox"]} cur-pointer`}
      onClick={(e: React.MouseEvent<HTMLLabelElement>) => {
        e.stopPropagation();
        props.onClick();
      }}
    >
      <img
        alt=""
        src={
          "/assets/images/buttonIcon/switch-" +
          (props.checked ? "on-icon.svg" : "off-icon.svg")
        }
      />
      <input
        className="display-none"
        type="checkbox"
        checked={props.checked}
        onChange={() => {}}
        onClick={(e) => e.stopPropagation()}
      />
      <div className="text">{props.text}</div>
    </label>
  );
};

export default SwitchCheckbox;
