import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./UpdateApplyModal.module.scss";
import { Modal } from "react-bootstrap";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import useUserInfoStore from "state/useUserInfoStore";
import EmailInviteInput from "components/base/EmailInviteInput/EmailInviteInput";
import { error2Alert } from "utils/otherToast";
import {
  CorrectionRequestModel,
  RequestType,
} from "models/admin/declareDataMangeModel";
import NormalTextarea from "components/base/NormalTextarea/NormalTextarea";
import {
  DeclareDataAction,
  DeclareStatus,
} from "models/application/indicators/indicatorModel";

export interface UpdateApplyModalProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: (param: CorrectionRequestModel) => void;
  title?: string;
  size?: "sm" | "lg" | "xl";
  loading?: boolean;
  requestType: RequestType;
  /** 申報狀態 */
  declareStatus?: DeclareStatus;
}

const UpdateApplyModal: FC<UpdateApplyModalProps> = ({
  declareStatus = DeclareStatus.UnDeclared,
  ...props
}) => {
  const { show, handleClose, handleConfirm, size, loading, title } = props;
  const { userInfo } = useUserInfoStore();
  const [orgTitle, setOrgTitle] = useState<string>("");
  // 更正申請 model
  const updateInfo = useRef<CorrectionRequestModel>({
    declareId: "",
    requestType: props.requestType,
    applyCompany: userInfo.companyCode,
    applyAccount: userInfo.accId,
    applicantEmailList: [] as string[],
    correctionRequestReason: "",
    action: DeclareDataAction.CorrectionRequest,
  });

  const sendApply = () => {
    let errorMsg = "";
    if (updateInfo.current.applicantEmailList.length === 0) {
      errorMsg += `請填寫email\n`;
    }
    if (!updateInfo.current.correctionRequestReason) {
      errorMsg += `請填寫原因`;
    }
    if (errorMsg) {
      error2Alert(errorMsg);
    } else {
      props.handleConfirm(updateInfo.current);
    }
  };

  useEffect(() => {
    // 沒有title就吃原始邏輯
    if (!title) {
      if (declareStatus === DeclareStatus.DeclareEnd) {
        setOrgTitle("申報資料申請開放");
      } else {
        setOrgTitle("申報資料更正申請");
      }
    } else {
      setOrgTitle(title);
    }
  }, [declareStatus, title]);

  useEffect(() => {
    // 除了申請結束狀態其他一律更正申請
    if (declareStatus === DeclareStatus.DeclareEnd) {
      updateInfo.current.action = DeclareDataAction.DeclareRequest;
    } else {
      updateInfo.current.action = DeclareDataAction.CorrectionRequest;
    }
  }, [declareStatus]);

  return (
    <Modal
      className={styles["update-apply-modal-box"]}
      show={show}
      size={size}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      {loading && <VisuallLoading></VisuallLoading>}
      <Modal.Header closeButton>
        <Modal.Title>{orgTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="update-apply-box">
          <div className="update-item">
            <div className="update-title">申請公司</div>
            <div className="update-val">{userInfo.name}</div>
          </div>
          <div className="update-item">
            <div className="update-title">申請帳號</div>
            <div className="update-val">{userInfo.userId}</div>
          </div>
          <div className="update-item">
            <div className="d-flex align-items-center">
              <div className=" w-100">
                <EmailInviteInput
                  onChange={(e) => {
                    updateInfo.current.applicantEmailList = e;
                  }}
                />
              </div>
            </div>
            <div className="note">
              *承辦開啟資料更正權限時，系統會發信通知申請人，請務必留下正確的
              Email。
            </div>
          </div>
          <div className="update-item">
            <NormalTextarea
              placeholder={"更正原因說明"}
              onChange={(val) => {
                updateInfo.current.correctionRequestReason = val;
              }}
            />
            <div className="note">
              *送出申請後，系統會自動發送Email給證交所/櫃買中心承辦。
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-box">
          <div className="btn-item mr-1-m">
            <button className="secondary" onClick={handleClose}>
              取消
            </button>
          </div>
          <div className="btn-item">
            <button className="default" onClick={sendApply}>
              送出申請
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateApplyModal;
