import { MopsGhgDataRequestModel, MopsGhgRequestModel } from "models/inquiry/ghgReductionInfo/mopsGhgDataInfoModel";
import { HttpClient } from "services/httpClient";

const httpClient = new HttpClient();


/** 取得篩選後代碼名稱列表 (For 查詢前台) */
const getMopsGhgList = (param:MopsGhgDataRequestModel) => {
    return httpClient.get(`mopsGhg/list?marketType=${param.marketType}&year=${param.year}`);
}

/** 透過declareId取得溫盤申報資訊 */
const getGhgDeclareData = (model:MopsGhgRequestModel) => {
    return httpClient.post(`mopsGhg/data`,model);
}


const mopsGhgService = {
    getMopsGhgList,
    getGhgDeclareData
};
export default mopsGhgService;