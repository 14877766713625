//* ---------------------------- React start ---------------------------- *//
import React, { createContext, useContext, useState, useEffect } from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import {
  QuestionnaireSampleDataModel,
  SustainQuestionnaireSampleModel,
  QuestionnaireDataListRequestModel,
  QuestionnaireData,
} from "models/application/sustainQuestionnaire/sustainQuestionnaireModel";
import SustainQuestionnaireService from "services/application/sustainQuestionnaire/sustainQuestionnaireService";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
//* ---------------------------- local end ---------------------------- *//

interface SpecialRulesContextValue {
  SpecificRulesService: {
    ValidSaveSpecificRules: (
      questionnaireSampleData: QuestionnaireSampleDataModel
    ) => Promise<boolean>;
    setSamplesList: React.Dispatch<
      React.SetStateAction<SustainQuestionnaireSampleModel[]>
    >;
  };
}

const SpecialRulesContext = createContext({} as SpecialRulesContextValue);

export const useEditSpecialRules = () => useContext(SpecialRulesContext);

//~ ---------------------------- divider ---------------------------- ~//

const EditSpecialRulesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  //* ---------------------------- state start ---------------------------- *//
  //- modal
  const [modal, setModal] = useState<ConfirmModalProps>({
    show: false,
    displayType: "alertMessage",
    handleClose: () => {
      setModal((prev) => ({
        ...prev,
        show: false,
      }));
      setModalChildren(null);
    },
    handleConfirm: () => {
      setModal((prev) => ({
        ...prev,
        show: false,
      }));
      setModalChildren(null);
    },
  });
  //- modalChildren
  const [modalChildren, setModalChildren] = useState<React.ReactNode>(null);
  //- samplesList
  const [samplesList, setSamplesList] = useState<
    SustainQuestionnaireSampleModel[]
  >([]);
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //* ---------------------------- api end ---------------------------- *//

  //* ---------------------------- function start ---------------------------- *//
  //! valid save specificRules from #25223
  const ValidSaveSpecificRules = async (
    questionnaireSampleData: QuestionnaireSampleDataModel
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      const examineSampleName: string = "確信情形";
      const examineCompletedQuestionnaireNames: string[] = [
        "營運經濟活動",
        "個別專案項目",
      ];

      if (examineSampleName !== questionnaireSampleData.sampleName) {
        resolve(true);
        return;
      }

      const samplesNameList = samplesList.map((item) => item.sampleName);
      if (
        !samplesNameList.some((item) =>
          examineCompletedQuestionnaireNames.includes(item)
        )
      ) {
        resolve(true);
        return;
      }

      const examineCompletedQuestionnaireSampleIds: string[] = samplesList
        .filter((item) =>
          examineCompletedQuestionnaireNames.includes(item.sampleName)
        )
        .map((item) => item.sampleId);

      // console.log(
      //   "examineCompletedQuestionnaireSampleIds",
      //   examineCompletedQuestionnaireSampleIds
      // );

      const fetchAllQuestionnaireSamples = async (
        examineCompletedQuestionnaireSampleIds: string[]
      ): Promise<any[]> => {
        try {
          const promises = examineCompletedQuestionnaireSampleIds.map(
            (sampleId) =>
              SustainQuestionnaireService.getQuestionnaireSamples({
                ...new QuestionnaireDataListRequestModel(),
                pageSize: 9999,
                questionnaireSampleId: sampleId,
              })
          );
          const responses = await Promise.all(promises);
          return responses.map((response) => {
            if (response.code === 200 && response.success) {
              return response.data;
            }
            return null;
          });
        } catch (err) {
          console.log("Error fetching questionnaire samples:", err);
          throw err;
        }
      };

      fetchAllQuestionnaireSamples(examineCompletedQuestionnaireSampleIds)
        .then((completedQuestionnaireDataList) => {
          // console.log(
          //   "completedQuestionnaireDataList",
          //   completedQuestionnaireDataList
          // );
          const hasValue = completedQuestionnaireDataList.some((data) => data);
          if (hasValue) {
            const examineCompletedQuestionnaireDataList =
              completedQuestionnaireDataList.map(
                (item) => item.questionnaireDataList
              );
            const isHaveAnyDeclaredQuestionnaire =
              examineCompletedQuestionnaireDataList.some(
                (dataList: QuestionnaireData[]) =>
                  dataList.some((data) => data.isDelared)
              );
            if (isHaveAnyDeclaredQuestionnaire) {
              resolve(true);
            } else {
              resolve(false);
              //- 顯示提示信息
              setModalChildren(
                <div>
                  請先確認申報任一筆「營運經濟活動」或「個別專案項目」，再進行確信情形申報作業。
                </div>
              );
              setModal((prev) => ({
                ...prev,
                show: true,
              }));
            }
          } else {
            resolve(true);
          }
        })
        .catch((err) => {
          console.log("Error:", err);
          resolve(true);
        });
    });
  };

  const SpecificRulesService = {
    ValidSaveSpecificRules,
    setSamplesList,
  };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//

  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  useEffect(() => {
    // console.log("samplesList", samplesList);
  }, [samplesList]);
  //* ---------------------------- hook end ---------------------------- *//

  return (
    <SpecialRulesContext.Provider value={{ SpecificRulesService }}>
      {children}
      <ConfirmModal {...modal}>{modalChildren}</ConfirmModal>
    </SpecialRulesContext.Provider>
  );
};

export default EditSpecialRulesProvider;
