import { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import styles from "./Setting.module.scss";
import { OptionModel } from "models/baseModel";
import Dropdown from "components/base/Dropdown/Dropdown";
import ConfirmModal, { ConfirmModalProps } from "components/base/ConfirmModal/ConfirmModal";
import moment from "moment";
import SearchInput from "components/base/SearchInput/SearchInput";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import SwitchCheckbox from "components/base/SwitchCheckbox/SwitchCheckbox";
import { useMutation } from "@tanstack/react-query";
import IndicatorService from "services/admin/indicatorService";
import { IndicatorCategoriesModel, IndicatorListRequestModel, IndicatorModel } from "models/admin/indicatorModel";
import IconButton from "components/button/IconButton/IconButton";
import toast, { Toaster } from "react-hot-toast";
import BackTitle from "components/base/BackTitle/BackTitle";
import TablePagination from "components/base/TablePagination/TablePagination";
import BatchUploadModal, { BatchUploadModalProps } from "components/base/BatchUploadModal/BatchUploadModal";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { CommonService } from "services/common/commonService";
import { usePermissionContext } from "context/PermissionProvider";
import { IndicatorManageActionsPermissionCodes } from "models/auth/permissionModel";

const Setting: FC = () => {

  const location = useLocation();
  // #region 變數
  const [list, setList] = useState<IndicatorModel[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [checkCount, setCheckCount] = useState(0);
  const [title, setTitle] = useState(' ');
  const [categories, setCategories] = useState<OptionModel[]>([]);
  const [openCheck, setOpenCheck] = useState(false);
  const params= useParams();
  const [count, setCount] = useState<number | undefined>(0);
  const [listIsPending, setListIsPending] = useState(false);

  const request = useRef<IndicatorListRequestModel>({
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortType: true,
    sortKey: null,
    indicatorMarketId: params.id,
    category: null
  });
  const [requestUI, setRequestUI] = useState<IndicatorListRequestModel>(() => {
    if(location?.state?.AdminIndicatorSetting_request?.current) {
      // console.log(location)
      const stateRequest = CommonService.deepClone(location.state.AdminIndicatorSetting_request.current)
      window.history.replaceState({}, document.title)
      return stateRequest;
    }
    return {
      page: 1,
      pageSize: 10,
      searchKey: null,
      sortType: true,
      sortKey: null,
      indicatorMarketId: params.id,
      category: null
    }
  });
  // modal 物件(context confirm)
  const [importModal, setImportModal] = useState<BatchUploadModalProps>({
    show: false,
    handleClose: () => {
        setImportModal((prev) => {
        return {
        ...prev,
        show: !prev.show,

        };
    });
    },
    handleUpload: (e) => uploadFile(e),
    sampleDownloadUrl: '/sample/ESG指標_匯入範本.xlsx',
    uploadLoading: false,
  });
  // modal 物件(confirm)
  const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
    setConfirmModal({
        ...confirmModal,
        show: false,
    });
    },
    handleConfirm: () => {},
    title: "確認",
  });
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;
  // #endregion

  // #region CRUD

  // 取得清單
  const GetIndicatorMutation = useMutation({
    mutationFn: IndicatorService.indicatorList,
  });

   // 取得類別
   const GetCategoriesOptionsMutation = useMutation({
    mutationFn: IndicatorService.indicatorOption,
  });

  // 狀態切換
  const activeIndicatorMutation = useMutation({
    mutationFn: IndicatorService.activeIndicator,
  });

  // 取得清單
  const handleList = () => {
    setListIsPending(true);
      GetIndicatorMutation
      .mutateAsync(request.current)
      .then(p=>{
        setListIsPending(false);
          if(p.success){
            setList(p.data.map((ck: IndicatorModel) => {
              ck['checked'] = false;
              return ck;
              }
            ));
            setTitle(p.message);
            setCount(p.count);
          } else {
            toast.error('error');
          }
      })
      .catch((err) => {
        setListIsPending(false);
        console.log(err);
        setList([]);
        toast.error(err);
      });
  };

  // 取得類別清單
  const categoriesList = () => {
    GetCategoriesOptionsMutation
    .mutateAsync()
    .then(p=>{
        if(p.success){
          let tmp: OptionModel[] = [{
            id: 'null',
            text: '全部'
            }];
          p.data.categories.forEach((c: IndicatorCategoriesModel) => {
            tmp.push({
              id: c.enumKey.toString(),
              text: c.text
            });
          });
          setCategories(tmp);
        } else {
          toast.error('error');
        }
    }).catch((err) => {
      console.log(err);
      setCategories([]);
    });
  };

  const switchActive = (isActive: boolean, id: string) => {
    if(params.id) {
      activeIndicatorMutation
      .mutateAsync({id: id, isActive: isActive})
      .then(p=>{
          if(p.success){
            toast.success('成功');
          } else {
            toast.error('失敗');
          }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }


  // 複製
  const copyIndicatorMutation = useMutation({
    mutationFn: IndicatorService.copyIndicator,
  });

  // 複製事件
  const handleCopy = (id: string | null) => {
    if(id) {
        copyEvent(id);
    } else {
        const checked = list.find(x=>x.checked === true);
        if(checked) {
            copyEvent(checked.id);
        }
    }
  };

  const copyEvent = (id: string | null) => {
    if(id) {
      setListIsPending(true);
      copyIndicatorMutation
      .mutateAsync(id)
      .then(p=>{
        setListIsPending(false);
          if(p.success){
            handleList();
            toast.success('success');
            setCheckCount(0);
          } else {
            toast.error(p.message);
          }
      })
      .catch((err) => {
        setListIsPending(false);
        console.log(err);
        toast.error(err);
      });
    }
  }

  // 刪除
  const removeIndicatorMutation = useMutation({
    mutationFn: IndicatorService.removeIndicator,
  });

  //刪除清單
  const handleRemove = (id: string | null) => {

    setConfirmModal({
      ...confirmModal,
      show: true,
      handleConfirm: () => {
        let checked = list.filter(x=>x.checked === true);
        if(id) {
            checked = list.filter(x=>x.id === id);
        }
        const ids: (string| null)[] = [];
        if(checked) {
            checked.forEach((c: IndicatorModel) => {
                ids.push(c.id);
            });
            setConfirmModal((prev) => {
              return {
                  ...prev,
                  loading: true,
              };
            });
            setListIsPending(true);
            removeIndicatorMutation
                .mutateAsync(ids)
                .then(p=>{
                  setListIsPending(false);
                    if(p.success){
                      handleList();
                      setCheckCount(0);
                      toast.success('刪除成功');
                    } else {
                      toast.error(p.message);
                    }
                })
                .catch((err) => {
                  setListIsPending(false);
                  console.log(err);
                  toast.error(err);
                })
                .finally(()=> {
                  setConfirmModal({
                      ...confirmModal,
                      show: false,
                      loading: false,
                  });
                });

        }
      },
    });
  };

  //匯入
  const uploadMutation = useMutation({
    mutationFn: IndicatorService.importIndicator,
  });

  const uploadFile = (files: any[]) => {
    const postData = new FormData();
    for (const file of files) {
        postData.append("file", file);
    }
    setImportModal((prev)=>{
        return{
        ...prev,
        uploadLoading: true
        };
    })
    uploadMutation.mutateAsync({
        file: postData,
        indicatorMarketId: params.id
    }).then((x) => {
        if (x.success === true) {
          setImportModal((prev)=>{
              return{
              ...prev,
              uploadLoading: false,
              show:false
              };
          })
          if(x.data.status === true) {
            handleList();
          }
          toast.success(x.data.message);
        } else {
          setImportModal((prev)=>{
              return{
              ...prev,
              uploadLoading: false
              };
          })
          toast.error(x.message);
        }
    });
};
  // #endregion

  // #region 事件

    useEffect(() => {
      const filterStr = sessionStorage.getItem('setting');
      if(filterStr && filterStr !== undefined) {
        const filter = JSON.parse(filterStr);
        request.current = filter;
        setRequestUI(request.current);
        handleList();
      }
      handleList();
      categoriesList();
    }, []);



    const saveFilterCondition = ():void => {
      sessionStorage.setItem('setting', JSON.stringify(request.current));
    }

    const navigate = useNavigate();
    const handleNavigate = (id: string | null) => {
        navigate(`${id}/edit`, { state: { AdminIndicatorSetting_request: request } });
    }

    // 篩選切換類別
    const handleCategoryChange = (cate: string | null) => {
      let typeNum = 'null';
      if(cate !== null) {
          typeNum = cate;
      }
      request.current.category = parseInt(typeNum, 10);
      setRequestUI(request.current);
      saveFilterCondition();
      handleList();
    };

    const handleSearch = (key: string) => {

      request.current.searchKey = key === '' ? null : key;
      setRequestUI(request.current);
      saveFilterCondition();
      handleList();
    };

    const handleTmpClick = () => {
      console.log("test");
    };


    const handleStatusChange = (id: string | null) => {
        if(id) {
          let chk = list.find(x=>x.id === id);
          if(chk) {
            switchActive(!chk.isActive, id);
          }
            setList(list.map((ck, index) => {
                    if(ck.id === id){
                        ck.isActive = !ck.isActive;
                    }
                    return ck;
                }
            ));
        }
      };

    function handleAllCheckbox() {
      setCheckAll(!checkAll);
      if(!checkAll === true) {
          setCheckCount(list.length);
      } else {
          setCheckCount(0);
      }
      list.forEach(item => {
          item.checked = !checkAll;
      });
    }

    function countCheckbox() {
      const total = list.length;
      const checked = list.filter(x=>x.checked === true).length;
      setCheckCount(checked);
      if(total !== 0 && total === checked) {
          setCheckAll(true);
      } else if(checked !== 0 && total !== checked) {
          setCheckAll(false);
      } else {
          setCheckAll(false);
      }
    }

    // 選取年度開關
    const handleOpenCheckToggle = () => {
        setOpenCheck(!openCheck);
        setCheckAll(false);
        setCheckCount(0);
        request.current.category = null;
        setRequestUI(request.current);
        saveFilterCondition();
    };

    function handleChkChange(id: string | null) {
      if(id) {
          setList(list.map((ck, index) => {
                  if(ck.id === id){
                      ck.checked = !ck.checked;
                  }
                  return ck;
              }
          ));
          countCheckbox();
      }
    }

    // #endregion

    return (
        <>
        {/* 主表 */}
        <div className={styles["indicator-setting"]}>
          {/*上方bar*/}
          <div className="title-box">
            <BackTitle title={title + '年'} url={`/admin/indicator`}/>
            </div>
          {listIsPending === true ? <VisuallLoading /> : <></>}
            <div className="tool-box pad-20-t" style={{'borderTop': '1px solid #dbe8ef'}}>
                <div className="tool-box">
                {!openCheck ? (
                  <>
                  <div className="mr-1-m" style={{width: '200px'}}>
                    <Dropdown
                    placeholder={"類別"}
                    options={categories}
                    defaultId={requestUI.category === null ? null : requestUI.category?.toString()}
                    onChange={handleCategoryChange}
                    />
                  </div>
                  <div style={{width: '200px'}}>
                    <SearchInput onClick={handleSearch} value={requestUI.searchKey}  />
                  </div>
                  </>
                  ): <>
                  <div className="tool-box mt-10-p">
                    <div className="sel-item">已選取{checkCount}項目</div>
                    {detectEveryActionPermission([
                      IndicatorManageActionsPermissionCodes.IndicatorSettingUpdate,
                    ]) ? (
                      <div className="icon-button">
                        <LinkIconButton
                          imgName={`${checkCount === 1 ? 'copy-icon.svg' : 'copy-lock-icon.svg'}`}
                          text="複製"
                          className={`${checkCount === 1 ? '' : 'lock'}`}
                          onClick={checkCount === 1 ? () => handleCopy(null) : () => { }}
                        />
                      </div>
                    ) : null}
                    {detectEveryActionPermission([
                      IndicatorManageActionsPermissionCodes.IndicatorSettingDelete,
                    ]) ? (
                      <div className="icon-button">
                        <LinkIconButton
                          imgName={`${checkCount > 0 ? 'trash-icon.svg' : 'trash-lock-icon.svg'}`}
                          text="刪除"
                          className={`${checkCount > 0 ? '' : 'lock'}`}
                          onClick={checkCount > 0 ? () => handleRemove(null) : () => { }}
                        />
                      </div>
                    ) : null}
                  </div>
                  </>
                }
                </div>

                <div className="tool-box">
                    <button className="secondary mr-1-m" onClick={handleOpenCheckToggle}>
                    {!openCheck ? '多筆編輯': '取消選取'}
                    </button>
                  {detectEveryActionPermission([
                    IndicatorManageActionsPermissionCodes.IndicatorSettingImport,
                  ]) ? (
                    <><IconButton
                      className={`secondary mr-1-m ${openCheck === true ? 'disabled' : ''}`}
                      text="檔案匯入"
                      imgName={`${openCheck === true ? 'import-lock-icon.svg' : 'import-icon.svg'}`}
                      onClick={() => {
                        setImportModal((prev) => {
                          return {
                            ...prev,
                            show: !prev.show,
                            sampleDownloadUrl: '/sample/ESG指標_匯入範本.xlsx'
                          };
                        });
                      }}
                    /><BatchUploadModal {...importModal} /></>
                  ) : null}


                  {detectEveryActionPermission([
                    IndicatorManageActionsPermissionCodes.IndicatorSettingCreate,
                  ]) ? (
                    <IconButton className={`default ${openCheck === true ? 'disabled' : ''}`} text="新增指標" imgName="add-bg-icon.svg" onClick={() => handleNavigate('-1')} />
                  ) : null}
                </div>


            </div>

            <div className="result-box scroll">

                <div className="table-box">
                    <table
                        aria-label="查詢結果table"
                        className="table-container sticky-table"
                    >
                    <thead>
                        <tr>
                        {openCheck ? (
                        <th style={{width: '50px'}} scope="col">
                            <label className="custom-checkbox">
                                <input
                                    type="checkbox"
                                    checked={checkAll}
                                    onChange={() => handleAllCheckbox()}
                                />
                                <span className={`${checkCount>0 ? 'partial' :''}`}>&nbsp;</span>
                            </label>
                        </th>
                        ) : <></>}
                        <th scope="col">類別
                          <LinkIconButton
                            imgName="sort-icon.svg"
                            text={null}
                            className={'d-inline'}
                            onClick={handleTmpClick}
                          />
                        </th>
                        <th scope="col">項目
                          <LinkIconButton
                            imgName="sort-icon.svg"
                            text={null}
                            className={'d-inline'}
                            onClick={handleTmpClick}
                          />
                        </th>
                        <th scope="col">建立日期
                          <LinkIconButton
                            imgName="sort-icon.svg"
                            text={null}
                            className={'d-inline'}
                            onClick={handleTmpClick}
                          />
                        </th>
                        <th scope="col">編輯日期
                          <LinkIconButton
                            imgName="sort-icon.svg"
                            text={null}
                            className={'d-inline'}
                            onClick={handleTmpClick}
                          />
                        </th>
                        <th scope="col">狀態
                          <LinkIconButton
                            imgName="sort-icon.svg"
                            text={null}
                            className={'d-inline'}
                            onClick={handleTmpClick}
                          />
                        </th>
                        <th scope="col" style={{width: '40px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                      {list.length === 0 ? <tr><td style={{textAlign: 'center'}} colSpan={6}>no data</td></tr> :
                        list.map((p: IndicatorModel, i) => {
                        return (
                            <tr key={p.id} className="cur-pointer" >
                              {/*開啟checkbox的td*/}
                                {openCheck ? (
                                <td>
                                    <label className="custom-checkbox">
                                        <input
                                            type="checkbox"
                                            checked={p.checked}
                                            onChange={()=>handleChkChange(p.id)}
                                        />
                                        <span>&nbsp;</span>
                                    </label>
                                </td>
                                ) : <></>}
                                <td onClick={()=>handleNavigate(p.id)}>{categories.find(x=>x.id === p.category?.toString())?.text}</td>
                                <td onClick={()=>handleNavigate(p.id)}>{p.name}</td>
                                <td onClick={()=>handleNavigate(p.id)}>{moment(p.createTime).format('YYYY-MM-DD')}</td>
                                <td onClick={()=>handleNavigate(p.id)}>{p.updateTime ?moment(p.updateTime).format('YYYY-MM-DD') : null }</td>
                                <td align="center" style={{color: p.isActive ? '#008DC1': '#86B2C8'}}>
                                  {!openCheck && detectEveryActionPermission([
                                    IndicatorManageActionsPermissionCodes.IndicatorSettingSetStatus,
                                  ]) ? (
                                  <SwitchCheckbox key={p.id} checked={p.isActive} text={p.isActive ? '啟用' : '關閉'} onClick={()=>handleStatusChange(p.id)}/>
                                  ) : <></>}
                                </td>
                                {!openCheck ? (
                                <td>
                                      <LinkIconButton
                                        imgName="right-arrow.svg"
                                        text=""
                                        onClick={() => handleNavigate(p.id)}
                                      />
                                </td>
                                ) :
                                (
                                  <td>

                                  { /** 複製與刪除 */}
                                  <div className="d-flex">
                                    {detectEveryActionPermission([
                                      IndicatorManageActionsPermissionCodes.IndicatorSettingUpdate,
                                    ]) ? (
                                      <LinkIconButton
                                        imgName="copy-icon.svg"
                                        text=""
                                        className={'hover'}
                                        onClick={() => handleCopy(p.id)}
                                      />
                                    ) : null}
                                    {detectEveryActionPermission([
                                      IndicatorManageActionsPermissionCodes.IndicatorSettingDelete,
                                    ]) ? (
                                      <LinkIconButton
                                        imgName="trash-icon.svg"
                                        text=""
                                        className={'hover'}
                                        onClick={() => handleRemove(p.id)}
                                      />
                                    ) : null}


                                      </div>
                                  </td>
                                )}
                              </tr>
                        );
                        })}
                    </tbody>
                    </table>

                </div>

            </div>
            <div className="pad-10 page" style={{border: '1px solid #dbe8ef'}}>
              <TablePagination
                  page={requestUI.page}
                  pageSize={requestUI.pageSize}
                  onChange={(e) => {
                    request.current.pageSize = e.pageSize;
                    request.current.page = e.page;
                    saveFilterCondition();
                    setRequestUI(request.current);
                    handleList();
                  }}
                  total={count || 0}
              />
            </div>
        </div>
        {/* 確認刪除modal */}
        <ConfirmModal {...confirmModal}>
          <div>是否刪除?</div>
        </ConfirmModal>
        </>
    );
};


export default Setting;
