import React, { ChangeEvent, FC, useEffect, useState } from "react";
import styles from "./TablePagination.module.scss";
import { PaginationModel } from "models/baseModel";

interface TablePaginationProps {
  /** 資料總數 */
  total: number;
  /** 調整改變 */
  onChange: (item: PaginationModel) => void;
  page: number | null;
  pageSize: number | null;
}

const TablePagination: FC<TablePaginationProps> = (props) => {
  const [displayCountArray, setDisplayCountArray] = useState([
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
    { text: "100", value: 100 },
    { text: "所有資料", value: props.total },
  ]);

  // 暫存page (有空字串時)
  const [tmpValue, setTmpValue] = useState<number | null>(null);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [result, setResult] = useState<PaginationModel>({
    page: props.page || 1,
    pageSize: props.pageSize || 10,
  });

  useEffect(() => {
    // pagesize有改變 就重新計算總共頁面 並切回第一頁
    if (props.page !== null && props.pageSize !== null) {
      setResult({ page: props.page, pageSize: props.pageSize });
    } else {
      const newResult = { ...result };
      newResult.page = 1;
      setResult(newResult);
    }
    caculateTotalPage(result.pageSize);

    setDisplayCountArray(
      displayCountArray.map((p) => {
        if (p.text === "所有資料") {
          p.value = props.total;
        }
        return p;
      })
    );
  }, [props.total]);

  // 計算總數
  const caculateTotalPage = (pageSize: number): void => {
    let value = 0;
    if (props.total) {
      value = Math.ceil(props.total / pageSize);
    }
    setTotalPage(value);
  };

  // 每頁size
  const handlePageSize = (item: any) => {
    const val = item.target.value;
    if (val) {
      const newPageSize = Number.parseInt(val);
      const newResult = { ...result };
      newResult.pageSize = newPageSize;
      newResult.page = 1;
      props.onChange(newResult);
      setResult(newResult);
      caculateTotalPage(newResult.pageSize);
    }
  };

  // 上一頁 下一頁
  const pageClick = (pageNumber: number) => {
    // 小於0
    pageNumber = pageNumber <= 0 ? 1 : pageNumber;
    // 大於最大的
    pageNumber = pageNumber >= totalPage ? totalPage : pageNumber;
    const newResult = { ...result };
    newResult.page = pageNumber;
    props.onChange(newResult);
    setResult(newResult);
  };

  // 換頁
  const changePage = (item: ChangeEvent<HTMLInputElement>) => {
    const newResult = { ...result };
    // 有數值
    if (!isNaN(item.target.valueAsNumber)) {
      let realPage = item.target.valueAsNumber;
      // 大於總筆數
      if (realPage > totalPage) {
        realPage = totalPage;
      }
      if (realPage < 1) {
        realPage = 1;
      }
      newResult.page = realPage;
      props.onChange(newResult);
    } else {
      newResult.page = item.target.value;
    }
    setResult(newResult);
  };

  // 移除滾輪事件
  useEffect(() => {
    const element = document.getElementById("pageInput");
    // passive: false 需增加，否則chrome 預設true，直接用preventDefault會出錯
    const wheelFun = (e: WheelEvent) => {
      e.preventDefault();
    };
    element?.addEventListener("wheel", wheelFun, { passive: false });

    return element?.removeEventListener("wheel", wheelFun);
  }, []);
  return (
    <div className={styles["table-pagination-box"]}>
      <div className="pagination-item total-box">共{props.total}筆</div>
      <div className="pagination-item page-box">
        <img
          alt="page-left"
          src="/assets/images/buttonIcon/page-left-icon.svg"
          onClick={() => {
            pageClick(result.page - 1);
          }}
        />
        <div className="page-input">
          <input
            id="pageInput"
            type="number"
            value={result.page ?? ""}
            onChange={($event) => {
              changePage($event);
            }}
          />
          <span className="symbol">/</span>
          <span>{totalPage}</span>
        </div>
        <img
          alt="page-left"
          src="/assets/images/buttonIcon/page-right-icon.svg"
          onClick={() => {
            pageClick(result.page + 1);
          }}
        />
      </div>
      <div className="pagination-item pagesize-box">
        <div className="pagesize-word">每頁顯示</div>
        <select
          className="custom-select"
          value={result.pageSize}
          onChange={($event) => {
            handlePageSize($event);
          }}
        >
          {displayCountArray.map((p) => {
            return (
              <option key={p.text} value={p.value}>
                {p.text}
              </option>
            );
          })}
        </select>
        <div className="pagesize-word">筆</div>
      </div>
    </div>
  );
};

export default TablePagination;
