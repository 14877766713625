import { EditAiAnalysisResultModel } from "models/application/indicators/aiAnalysisModel";
import { HttpClient } from "services/httpClient";

const http = new HttpClient();

/** 報告書解析結果 */
const getAnalysis = (id: string) => {
    return http.get(`AiAnalysis/${id}`);
}

/** 判斷公司該年分是否有分析資料 */
const checkHasAnalysis = (year: number) => {
    return http.get(`AiAnalysis/status?year=${year}`);

}

/** 編輯報告書解析 */
const editAnalysis = (model: EditAiAnalysisResultModel) => {
    return http.post(`AiAnalysis/${model.data.analysisId}`, model.data);
}

/** 解析結果同步用戶表單 */
const syncAnalysisToCompanyData = (id: string) => {
    return http.post(`AiAnalysis/sync/${id}`);
}

/** 上傳報告書 */
const uploadAndSendReportAnalysis = (formData:any) => {
    return http.post(`AiAnalysis/upload`, formData);
}

const AiAnalysisService = {
    getAnalysis,
    editAnalysis,
    checkHasAnalysis,
    syncAnalysisToCompanyData,
    uploadAndSendReportAnalysis
}

export default AiAnalysisService;