import { IndicatorDetailModel, IndicatorListRequest2Model, IndicatorListRequestModel, IndicatorMarketModel, IndicatorModel, IndicatorSwitchActive, NecessaryIndicatorModel } from "models/admin/indicatorModel";
import { HttpClient } from "services/httpClient";

const httpClient = new HttpClient();

// #region esg 指標管理

/** 取得列表 (0=全部,1=上市,2=上櫃) */
const getIndicatorMarket = (req: IndicatorListRequest2Model) => {
    return httpClient.post(`PrincipleMatrixManage/list`, req);
}

/** 新增市場別資料 */
const addIndicatorMarket = (data: IndicatorMarketModel) => {
    return httpClient.post('IndicatorMarket/add', data);
}

/** 編輯市場別資料 */
const saveIndicatorMarket = (data: IndicatorMarketModel) => {
    return httpClient.post(`IndicatorMarket/update`, data);
}

/** 複製市場別資料 */
const copyIndicatorMarket = (data: any) => {
    return httpClient.post('IndicatorMarket/copy', data);
}

/** 刪除市場別資料 */
const removeIndicatorMarket = (ids: (string|null)[] | null) => {
    return httpClient.post(`IndicatorMarket/delete`, ids);
}

/** 取得指標列表 */
const indicatorList = (data: IndicatorListRequestModel) => {
    return httpClient.post('IndicatorManage/list', data);
}

/** 透過指標範本id搜尋指標 */
const getIndicator = (id: string) => {
    return httpClient.get(`IndicatorManage/${id}`);
}

/** 編輯表單 */
const editIndicatorForm = (data: IndicatorDetailModel) => {
    return httpClient.post(`IndicatorManage/${data.id}`, data);
}

/** 新增表單 */
const addIndicator = (data: IndicatorDetailModel) => {
    return httpClient.post(`IndicatorManage/add`, data);
}

/** 編輯狀態表單 */
const activeIndicator = (data: IndicatorSwitchActive) => {
    return httpClient.post(`IndicatorManage/${data.id}/status`, {isActive: data.isActive});
}

/** 刪除指標 */
const removeIndicator = (ids: (string|null)[] | null) => {
    return httpClient.post(`IndicatorManage/delete`, ids);
}

/** 取得指標類別與控件清單 */
const indicatorOption = () => {
    return httpClient.get(`IndicatorManage/option`);
}

/** 複製指標 */
const copyIndicator = (id: string) => {
    return httpClient.post(`IndicatorManage/copy/${id}`, null);
}

/** 匯入指標清單 */
const importIndicator = (model:any) => {
    return httpClient.post(`indicatorManage/import?indicatorMarketId=${model.indicatorMarketId}`,model.file);
}

// #endregion

// #region 必要揭露指標

/** 取得必要揭露指標設定 */
const getNecessaryIndicator = (sampleId: string) => {
  return httpClient.get(`NecessaryIndicator?sampleId=${sampleId}`);
}

/** 編輯必要揭露指標設定 */
const saveNecessaryIndicator = (request: NecessaryIndicatorModel) => {
  return httpClient.post(`NecessaryIndicator`, request);
}

// #endregion 必要揭露指標



const IndicatorService = {
    getIndicatorMarket,
    addIndicatorMarket,
    saveIndicatorMarket,
    copyIndicatorMarket,
    removeIndicatorMarket,
    indicatorList,
    getIndicator,
    editIndicatorForm,
    addIndicator,
    activeIndicator,
    removeIndicator,
    indicatorOption,
    copyIndicator,
    importIndicator,
    getNecessaryIndicator,
    saveNecessaryIndicator
}

export default IndicatorService;
