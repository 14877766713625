import React, { FC, useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useUserInfoStore from "../state/useUserInfoStore";

type ProtectedRouteProps = {
  children?: React.ReactNode;
  /** 導向頁面 */
  redirectPath?: string;
  /** 是否有該權限 */
  isAllowed?: boolean;
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  redirectPath = "",
  isAllowed = true,
}) => {
  const {userInfo} = useUserInfoStore();

  // useEffect(() => {
  //   console.log(userInfo)
  //   // 無token 直接回頁面
  //   if (!userInfo.accessToken) {
  //     error2Alert("尚未登入");
  //     setTimeout(() => {
  //       redirectEntrance();
  //     }, 50);
  //   }
  //   return ()=>{

  //   };
  // }, [userInfo]);
  // 權限不足
  if (userInfo.accessToken && !isAllowed) {
    console.log("redirectPath:", redirectPath);
    return <Navigate to={redirectPath} replace />;
  }
  // 暫時都倒回自己的頁面
  return (
    // <>{!redirectPath ? children : <Navigate to={redirectPath} replace />}</>
    <>{children}</>
  );
};

export default ProtectedRoute;
