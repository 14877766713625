import { ControlModel } from './sustainQuestionnaireModel';

export interface SampleControlIdCorrespondsValueModel {
  name?: string;
  sampleControlId: string;
  value: string | null;
}

export const SpecificRulesData: {
  sampleId: string;
  controlsInitPropertyData: any[];
  handleControl: (
    control: ControlModel,
    sampleControlIdCorrespondsValue: SampleControlIdCorrespondsValueModel[],
    resetControlValue: (control: ControlModel) => ControlModel
  ) => ControlModel;
}[] = [
  //- 營運經濟活動
  {
    sampleId: 'f825ac7f-836a-4cbb-843f-9dce79e76293',
    controlsInitPropertyData: [
      //> 12
      {
        sortIndex: 12,
        sampleControlId: '2039ea4a-fdca-4169-8b80-2f480f562c16',
        ctrType: 'radio',
        name: '條件一、二、三如有不符合者，是否有具體改善或轉型計畫？',
        //- 預設選項的disable
        isDefaultOptionDisabled: true,
      },
      //> 14
      {
        sortIndex: 14,
        sampleControlId: '8a637697-368f-4d9c-9b5f-cdb75f20c87c',
        ctrType: 'radio',
        name: '永續程度自評結果',
        //- 不開放填寫
        isNotOpenForSubmission: true,
      },
    ],
    handleControl: (
      control: ControlModel,
      sampleControlIdCorrespondsValue: SampleControlIdCorrespondsValueModel[],
      resetControlValue: (control: ControlModel) => ControlModel
    ) => {
      //- 3. "一般經濟活動"
      if (control.sampleControlId === '8ca1ce5a-44cc-4b0d-8dd3-d2d2fd8f03f0') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "該營運經濟活動適用本指引之類別"
            sampleControlId: 'ab956789-5822-45e4-a8dd-2f9f02bcd2ff',
            value: '4089997e-a622-4c6e-ae3b-c53638befcfd',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('3. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 4. "前瞻經濟活動"
      if (control.sampleControlId === 'e4bb7ac4-d74e-4817-b813-d5b88017821c') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "該營運經濟活動適用本指引之類別"
            sampleControlId: 'ab956789-5822-45e4-a8dd-2f9f02bcd2ff',
            value: '4089997e-a622-4c6e-ae3b-c53638befcfd',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('4. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 5. "該營運經濟活動過去一年占全部營收之比重(單位%)"
      if (control.sampleControlId === 'b8bbc065-12b7-4945-a0eb-667e23cf96dd') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "該營運經濟活動適用本指引之類別"
            sampleControlId: 'ab956789-5822-45e4-a8dd-2f9f02bcd2ff',
            value: '4089997e-a622-4c6e-ae3b-c53638befcfd',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('5. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 6. "最近一年單位生產之排放強度(單位CO₂e/公噸)"
      if (control.sampleControlId === '8763abd1-272c-49ea-bee4-e06e531edf42') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "該營運經濟活動適用本指引之類別"
            sampleControlId: 'ab956789-5822-45e4-a8dd-2f9f02bcd2ff',
            value: '4089997e-a622-4c6e-ae3b-c53638befcfd',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('6. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 7. "最近一年單位產品電力消耗量(單位度)"
      if (control.sampleControlId === 'f799a345-f0c3-4f6c-a6c2-e7fe40f554ea') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "該營運經濟活動適用本指引之類別"
            sampleControlId: 'ab956789-5822-45e4-a8dd-2f9f02bcd2ff',
            value: '4089997e-a622-4c6e-ae3b-c53638befcfd',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('7. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 8. "條件一：是否符合【對氣候變遷減緩具有實質貢獻】之技術篩選標準？"
      if (control.sampleControlId === 'eeb24054-9e61-43b4-9252-cbf5d9e68ac6') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "該營運經濟活動適用本指引之類別"
            sampleControlId: 'ab956789-5822-45e4-a8dd-2f9f02bcd2ff',
            value: '4089997e-a622-4c6e-ae3b-c53638befcfd',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('8. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 9. "條件二：是否符合【未對其他五項環境目的造成重大危害】？"
      if (control.sampleControlId === 'f35db9a0-0682-49cc-b0f2-66a065ef4dc1') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "該營運經濟活動適用本指引之類別"
            sampleControlId: 'ab956789-5822-45e4-a8dd-2f9f02bcd2ff',
            value: '4089997e-a622-4c6e-ae3b-c53638befcfd',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('9. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 10. "請續填不符【未對其他五項環境目的造成重大危害】項目"
      if (control.sampleControlId === 'd592098a-3dc9-41b2-bc68-1dcfa986a352') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "該營運經濟活動適用本指引之類別"
            sampleControlId: 'ab956789-5822-45e4-a8dd-2f9f02bcd2ff',
            value: '4089997e-a622-4c6e-ae3b-c53638befcfd',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('10. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 11. "條件三：是否符合【未對社會保障造成重大危害】？"
      if (control.sampleControlId === '3100c705-294d-41ef-b667-a90cce2281d5') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "該營運經濟活動適用本指引之類別"
            sampleControlId: 'ab956789-5822-45e4-a8dd-2f9f02bcd2ff',
            value: '4089997e-a622-4c6e-ae3b-c53638befcfd',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('11. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 12. 條件一、二、三如有不符合者，是否有具體改善或轉型計畫？
      if (control.sampleControlId === '2039ea4a-fdca-4169-8b80-2f480f562c16') {
        //> rule1: 第12題只開放：條件1~條件3其中一個有勾選「否」，才開放填寫
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 條件一：是否符合【對氣候變遷減緩具有實質貢獻】之技術篩選標準？
            sampleControlId: 'eeb24054-9e61-43b4-9252-cbf5d9e68ac6',
            value: '88c1ef60-4f00-49a5-8a2c-4ff51d25418b',
          },
          {
            //> 條件二：是否符合【未對其他五項環境目的造成重大危害】？
            sampleControlId: 'f35db9a0-0682-49cc-b0f2-66a065ef4dc1',
            value: '7cafcdc7-b9a9-44cc-9c28-275d71c251a0',
          },
          {
            //> 條件三：是否符合【未對社會保障造成重大危害】？
            sampleControlId: '3100c705-294d-41ef-b667-a90cce2281d5',
            value: '97953db2-61a0-4c96-9ae2-fc0e9ff61cf3',
          },
        ];
        //> rule2: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule2: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "該營運經濟活動適用本指引之類別"
            sampleControlId: 'ab956789-5822-45e4-a8dd-2f9f02bcd2ff',
            value: '4089997e-a622-4c6e-ae3b-c53638befcfd',
          },
        ];
        const isDisabled =
          !matchControlRule1.some((rule) => {
            const controlValue = sampleControlIdCorrespondsValue.find(
              (e) => e.sampleControlId === rule.sampleControlId
            )?.value;
            return controlValue === rule.value;
          }) ||
          matchControlRule2.every((rule) => {
            const controlValue = sampleControlIdCorrespondsValue.find(
              (e) => e.sampleControlId === rule.sampleControlId
            )?.value;
            return controlValue === rule.value;
          });
        console.log('12. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 13. 請敘明具體改善或轉型計畫
      if (control.sampleControlId === '109f028f-6081-4766-adfe-f84b5cca9f63') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "該營運經濟活動適用本指引之類別"
            sampleControlId: 'ab956789-5822-45e4-a8dd-2f9f02bcd2ff',
            value: '4089997e-a622-4c6e-ae3b-c53638befcfd',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('13. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 14. "永續程度自評結果"
      if (control.sampleControlId === '8a637697-368f-4d9c-9b5f-cdb75f20c87c') {
        //> rule1: 第2題選不適用->第14題為不適用
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "該營運經濟活動適用本指引之類別"
            sampleControlId: 'ab956789-5822-45e4-a8dd-2f9f02bcd2ff',
            value: '4089997e-a622-4c6e-ae3b-c53638befcfd',
          },
        ];
        const isMatch1 = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        //> rule2:第8、9、11題均為是->第14題為符合
        const matchControlRule2: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "條件一：是否符合【對氣候變遷減緩具有實質貢獻】之技術篩選標準？"
            sampleControlId: 'eeb24054-9e61-43b4-9252-cbf5d9e68ac6',
            value: '8c5e36f4-0305-4b47-bd07-18b098911112',
          },
          {
            //> "條件二：是否符合【未對其他五項環境目的造成重大危害】？"
            sampleControlId: 'f35db9a0-0682-49cc-b0f2-66a065ef4dc1',
            value: 'e9bb851f-8c0b-4df9-9dd1-b9b459c02b99',
          },
          {
            //> "條件三：是否符合【未對社會保障造成重大危害】？"
            sampleControlId: '3100c705-294d-41ef-b667-a90cce2281d5',
            value: '00980958-f6af-4047-9ac2-39532dc99741',
          },
        ];
        const isMatch2 = matchControlRule2.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        //> rule3:.第8為否且第9、11題為是 且第12題選有者 為努力中
        const matchControlRule3: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "條件一：是否符合【對氣候變遷減緩具有實質貢獻】之技術篩選標準？"
            sampleControlId: 'eeb24054-9e61-43b4-9252-cbf5d9e68ac6',
            value: '88c1ef60-4f00-49a5-8a2c-4ff51d25418b',
          },
          {
            //> "條件二：是否符合【未對其他五項環境目的造成重大危害】？"
            sampleControlId: 'f35db9a0-0682-49cc-b0f2-66a065ef4dc1',
            value: 'e9bb851f-8c0b-4df9-9dd1-b9b459c02b99',
          },
          {
            //> "條件三：是否符合【未對社會保障造成重大危害】？"
            sampleControlId: '3100c705-294d-41ef-b667-a90cce2281d5',
            value: '00980958-f6af-4047-9ac2-39532dc99741',
          },
          {
            //> "條件一、二、三如有不符合者，是否有具體改善或轉型計畫？"
            sampleControlId: '2039ea4a-fdca-4169-8b80-2f480f562c16',
            value: 'd14183d7-5ab5-4a07-bb14-e3938620b4d9',
          },
        ];
        const isMatch3 = matchControlRule3.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        //> rule4:第8為是且第9或11題為否 且第12題選有者 為改善中
        const matchControlRule4: SampleControlIdCorrespondsValueModel[] = [
          {
            //> "條件一：是否符合【對氣候變遷減緩具有實質貢獻】之技術篩選標準？"
            sampleControlId: 'eeb24054-9e61-43b4-9252-cbf5d9e68ac6',
            value: '8c5e36f4-0305-4b47-bd07-18b098911112',
          },
          {
            //> "條件二：是否符合【未對其他五項環境目的造成重大危害】？"
            sampleControlId: 'f35db9a0-0682-49cc-b0f2-66a065ef4dc1',
            value: '7cafcdc7-b9a9-44cc-9c28-275d71c251a0',
          },
          {
            //> "條件三：是否符合【未對社會保障造成重大危害】？"
            sampleControlId: '3100c705-294d-41ef-b667-a90cce2281d5',
            value: '97953db2-61a0-4c96-9ae2-fc0e9ff61cf3',
          },
          {
            //> "條件一、二、三如有不符合者，是否有具體改善或轉型計畫？"
            sampleControlId: '2039ea4a-fdca-4169-8b80-2f480f562c16',
            value: 'd14183d7-5ab5-4a07-bb14-e3938620b4d9',
          },
        ];
        const matchArray4 = matchControlRule4.map((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        const isMatch4 =
          matchArray4[0] &&
          (matchArray4[1] || matchArray4[2]) &&
          matchArray4[3];
        if (isMatch1) {
          console.log('14. isMatch1', isMatch1);
          control.value = '44dfd28b-a6c4-46bf-b526-ee3dfe84d1ce';
        } else if (isMatch2) {
          console.log('14. isMatch2', isMatch2);
          control.value = '8319042f-7fb2-4579-a06c-cb20cf20191d';
        } else if (isMatch3) {
          console.log('14. isMatch3', isMatch3);
          control.value = 'f166f288-5b63-40cb-a843-cf77516a9ce1';
        } else if (isMatch4) {
          console.log('14. isMatch4', isMatch4);
          control.value = '3ea95e99-2081-400d-b995-cb0b039c293c';
        } else {
          //> 其餘為不符合
          console.log('14. isNoMatch', true);
          control.value = 'b5dc879b-e1c1-4e79-97ac-72c738ace685';
        }
        return control;
      }
      return control;
    },
  },
  //- 個別專案項目
  {
    sampleId: '7119c3d7-b8ce-4565-8f4b-251ee55fbfe1',
    controlsInitPropertyData: [
      //> 12
      {
        sortIndex: 12,
        sampleControlId: '1ed16239-04cf-4028-a17e-c4c3a9168740',
        ctrType: 'radio',
        name: '條件一、二、三如有不符合者，是否有具體改善或轉型計畫？',
        //- 預設選項的disable
        isDefaultOptionDisabled: true,
      },
      //> 14
      {
        sortIndex: 14,
        sampleControlId: '698e3e87-45e6-4f44-8446-bd638b75fe91',
        ctrType: 'radio',
        name: '永續程度自評結果',
        //- 不開放填寫
        isNotOpenForSubmission: true,
      },
    ],
    handleControl: (
      control: ControlModel,
      sampleControlIdCorrespondsValue: SampleControlIdCorrespondsValueModel[],
      resetControlValue: (control: ControlModel) => ControlModel
    ) => {
      //- 3. "一般經濟活動"
      if (control.sampleControlId === '88f9b7c9-3064-49d4-a7e6-379a26c2b1e9') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 該個別專案項目適用本指引之類別
            sampleControlId: '9d1add20-de8a-4660-90af-72c1298c303a',
            value: '2c513a85-0a4f-44cb-b5b5-beffdcd02c11',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('3. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 4. "前瞻經濟活動"
      if (control.sampleControlId === 'e4bb7ac4-d74e-4817-b813-d5b88017821c') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 該個別專案項目適用本指引之類別
            sampleControlId: '9d1add20-de8a-4660-90af-72c1298c303a',
            value: '2c513a85-0a4f-44cb-b5b5-beffdcd02c11',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('4. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 5. 條件一：是否符合【對氣候變遷減緩具有實質貢獻】之技術篩選標準？
      if (control.sampleControlId === '51fe8399-9ee0-4edd-8691-a6bbb7767f7c') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 該個別專案項目適用本指引之類別
            sampleControlId: '9d1add20-de8a-4660-90af-72c1298c303a',
            value: '2c513a85-0a4f-44cb-b5b5-beffdcd02c11',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('5. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 6. 最近一年單位生產之排放強度(單位CO₂e/公噸)
      if (control.sampleControlId === '83c593b8-03d9-4905-8eff-bcc927897e18') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 該個別專案項目適用本指引之類別
            sampleControlId: '9d1add20-de8a-4660-90af-72c1298c303a',
            value: '2c513a85-0a4f-44cb-b5b5-beffdcd02c11',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('6. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 7. 最近一年單位產品電力消耗量(單位度)
      if (control.sampleControlId === 'a0d8d25c-cd8b-486d-bef0-379df0f429f5') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 該個別專案項目適用本指引之類別
            sampleControlId: '9d1add20-de8a-4660-90af-72c1298c303a',
            value: '2c513a85-0a4f-44cb-b5b5-beffdcd02c11',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('7. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 8. 資本支出/營業費用
      if (control.sampleControlId === 'f97b2a40-f9a2-4df9-b1ab-d2444e727fbf') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 該個別專案項目適用本指引之類別
            sampleControlId: '9d1add20-de8a-4660-90af-72c1298c303a',
            value: '2c513a85-0a4f-44cb-b5b5-beffdcd02c11',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('8. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 9. 條件二：是否符合【未對其他五項環境目的造成重大危害】？
      if (control.sampleControlId === '5959d8c5-6ad0-49e7-b524-46e49b48a383') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 該個別專案項目適用本指引之類別
            sampleControlId: '9d1add20-de8a-4660-90af-72c1298c303a',
            value: '2c513a85-0a4f-44cb-b5b5-beffdcd02c11',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('9. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 10. 請續填不符【未對其他五項環境目的造成重大危害】項目
      if (control.sampleControlId === 'ec04f444-4e47-4cc2-8598-068d3dfd8fa1') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 該個別專案項目適用本指引之類別
            sampleControlId: '9d1add20-de8a-4660-90af-72c1298c303a',
            value: '2c513a85-0a4f-44cb-b5b5-beffdcd02c11',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('10. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 11. 條件三：是否符合【未對社會保障造成重大危害】？
      if (control.sampleControlId === '12df1f7f-1aaf-413e-b6f3-35a1743ffdb6') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 該個別專案項目適用本指引之類別
            sampleControlId: '9d1add20-de8a-4660-90af-72c1298c303a',
            value: '2c513a85-0a4f-44cb-b5b5-beffdcd02c11',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('11. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 12. 條件一、二、三如有不符合者，是否有具體改善或轉型計畫？
      if (control.sampleControlId === '1ed16239-04cf-4028-a17e-c4c3a9168740') {
        //> rule1: 第12題只開放：條件1~條件3其中一個有勾選「否」，才開放填寫
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 條件一：是否符合【對氣候變遷減緩具有實質貢獻】之技術篩選標準？
            sampleControlId: '51fe8399-9ee0-4edd-8691-a6bbb7767f7c',
            value: 'cc170e5d-a5d7-4b94-882c-53512dd00623',
          },
          {
            //> 條件二：是否符合【未對其他五項環境目的造成重大危害】？
            sampleControlId: '5959d8c5-6ad0-49e7-b524-46e49b48a383',
            value: '7ea4ebbf-2fac-40a5-96a8-941f2ea81ff3',
          },
          {
            //> 條件三：是否符合【未對社會保障造成重大危害】？
            sampleControlId: '12df1f7f-1aaf-413e-b6f3-35a1743ffdb6',
            value: 'c665d2d6-dd50-4d55-b6a4-d12e5f5937fd',
          },
        ];
        //> rule2: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule2: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 該個別專案項目適用本指引之類別
            sampleControlId: '9d1add20-de8a-4660-90af-72c1298c303a',
            value: '2c513a85-0a4f-44cb-b5b5-beffdcd02c11',
          },
        ];
        const isDisabled =
          !matchControlRule1.some((rule) => {
            const controlValue = sampleControlIdCorrespondsValue.find(
              (e) => e.sampleControlId === rule.sampleControlId
            )?.value;
            return controlValue === rule.value;
          }) ||
          matchControlRule2.every((rule) => {
            const controlValue = sampleControlIdCorrespondsValue.find(
              (e) => e.sampleControlId === rule.sampleControlId
            )?.value;
            return controlValue === rule.value;
          });
        console.log('12. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 13. 請敘明具體改善或轉型計畫
      if (control.sampleControlId === '1c00b9a0-10cd-44d6-bcf3-4e664c381e75') {
        //> rule1: 第2題選擇不適用，則該問卷其他題皆不須填
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 該個別專案項目適用本指引之類別
            sampleControlId: '9d1add20-de8a-4660-90af-72c1298c303a',
            value: '2c513a85-0a4f-44cb-b5b5-beffdcd02c11',
          },
        ];
        const isDisabled = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        console.log('13. isDisabled', isDisabled);
        if (isDisabled) {
          control.isDefaultOptionDisabled = true;
          control = resetControlValue(control);
        } else {
          control.isDefaultOptionDisabled = false;
        }
        return control;
      }
      //- 14. "永續程度自評結果"
      if (control.sampleControlId === '698e3e87-45e6-4f44-8446-bd638b75fe91') {
        //> rule1: 第2題選不適用->第14題為不適用
        const matchControlRule1: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 該個別專案項目適用本指引之類別
            sampleControlId: '9d1add20-de8a-4660-90af-72c1298c303a',
            value: '2c513a85-0a4f-44cb-b5b5-beffdcd02c11',
          },
        ];
        const isMatch1 = matchControlRule1.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        //> rule2:第5、9、11題均為是->第14題為符合
        const matchControlRule2: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 條件一：是否符合【對氣候變遷減緩具有實質貢獻】之技術篩選標準？
            sampleControlId: '51fe8399-9ee0-4edd-8691-a6bbb7767f7c',
            value: '0491272e-5ef7-432e-8bca-9be214d27361',
          },
          {
            //> 條件二：是否符合【未對其他五項環境目的造成重大危害】？
            sampleControlId: '5959d8c5-6ad0-49e7-b524-46e49b48a383',
            value: 'b569d4a8-a156-44f3-b840-ccbcd9e0444d',
          },
          {
            //> 條件三：是否符合【未對社會保障造成重大危害】？
            sampleControlId: '12df1f7f-1aaf-413e-b6f3-35a1743ffdb6',
            value: '2080adf3-c98e-40c9-a7c5-99cdfcb63d11',
          },
        ];
        const isMatch2 = matchControlRule2.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        //> rule3:.第5為否且第9、11題為是 且第12題選有者 為努力中
        const matchControlRule3: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 條件一：是否符合【對氣候變遷減緩具有實質貢獻】之技術篩選標準？
            sampleControlId: '51fe8399-9ee0-4edd-8691-a6bbb7767f7c',
            value: 'cc170e5d-a5d7-4b94-882c-53512dd00623',
          },
          {
            //> 條件二：是否符合【未對其他五項環境目的造成重大危害】？
            sampleControlId: '5959d8c5-6ad0-49e7-b524-46e49b48a383',
            value: 'b569d4a8-a156-44f3-b840-ccbcd9e0444d',
          },
          {
            //> 條件三：是否符合【未對社會保障造成重大危害】？
            sampleControlId: '12df1f7f-1aaf-413e-b6f3-35a1743ffdb6',
            value: '2080adf3-c98e-40c9-a7c5-99cdfcb63d11',
          },
          {
            //> "條件一、二、三如有不符合者，是否有具體改善或轉型計畫？"
            sampleControlId: '1ed16239-04cf-4028-a17e-c4c3a9168740',
            value: '61b4bd49-a2d3-4323-bad8-95e76d3678d7',
          },
        ];
        const isMatch3 = matchControlRule3.every((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        //> rule4:第5為是且第9或11題為否 且第12題選有者 為改善中
        const matchControlRule4: SampleControlIdCorrespondsValueModel[] = [
          {
            //> 條件一：是否符合【對氣候變遷減緩具有實質貢獻】之技術篩選標準？
            sampleControlId: '51fe8399-9ee0-4edd-8691-a6bbb7767f7c',
            value: '0491272e-5ef7-432e-8bca-9be214d27361',
          },
          {
            //> 條件二：是否符合【未對其他五項環境目的造成重大危害】？
            sampleControlId: '5959d8c5-6ad0-49e7-b524-46e49b48a383',
            value: '7ea4ebbf-2fac-40a5-96a8-941f2ea81ff3',
          },
          {
            //> 條件三：是否符合【未對社會保障造成重大危害】？
            sampleControlId: '12df1f7f-1aaf-413e-b6f3-35a1743ffdb6',
            value: 'c665d2d6-dd50-4d55-b6a4-d12e5f5937fd',
          },
          {
            //> "條件一、二、三如有不符合者，是否有具體改善或轉型計畫？"
            sampleControlId: '1ed16239-04cf-4028-a17e-c4c3a9168740',
            value: '61b4bd49-a2d3-4323-bad8-95e76d3678d7',
          },
        ];
        const matchArray4 = matchControlRule4.map((rule) => {
          const controlValue = sampleControlIdCorrespondsValue.find(
            (e) => e.sampleControlId === rule.sampleControlId
          )?.value;
          return controlValue === rule.value;
        });
        const isMatch4 =
          matchArray4[0] &&
          (matchArray4[1] || matchArray4[2]) &&
          matchArray4[3];
        if (isMatch1) {
          console.log('14. isMatch1', isMatch1);
          control.value = 'ac1dfa6a-6892-4a1e-8f45-015a1fc14628';
        } else if (isMatch2) {
          console.log('14. isMatch2', isMatch2);
          control.value = 'ac4fa944-7fc8-4f00-8974-15806c752e8d';
        } else if (isMatch3) {
          console.log('14. isMatch3', isMatch3);
          control.value = '53697c3a-ba3a-44b5-9247-84355f21a0c4';
        } else if (isMatch4) {
          console.log('14. isMatch4', isMatch4);
          control.value = '22cd82fc-e030-4629-8170-e476ad50d8ea';
        } else {
          //> 其餘為不符合
          console.log('14. isNoMatch', true);
          control.value = '7c26d3d8-0673-4d48-8e3f-fd0d9ab520b8';
        }
        return control;
      }
      return control;
    },
  },
];
