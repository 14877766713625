import React, { FC } from "react";
import styles from "./ProblemReportContent.module.scss";
import { useTranslation } from "react-i18next";

interface ProblemReportContentProps {
  customClass?: string;
}

const ProblemReportContent: FC<ProblemReportContentProps> = (props) => {
  const {t} = useTranslation();
  return (
    <div
      className={
        `${styles["problam-report-content"]} ` +
        `${props.customClass ? props.customClass : ""}`
      }
    >
      {t('PROBLEM_REPORT_MESSAGE1')}
      <br />
      {t('CUSTOMER_SERVICE_TEL')}：02-27553330
      <br />
      {t('CUSTOMER_SERVICE_EMAIL')}：
      <a href="mailto:ESGsupport@chase.com.tw">ESGsupport@chase.com.tw</a>
    </div>
  );
};

export default ProblemReportContent;
