import { FC, useEffect, useState } from "react";
import styles from "./List.module.scss";
import Dropdown from "components/base/Dropdown/Dropdown";
import { OptionModel, SimpleOptionModel } from "models/baseModel";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { IndicatorListRequest2Model } from "models/admin/indicatorModel";
import { useMutation } from "@tanstack/react-query";
import toast, { Toaster } from "react-hot-toast";
import { MarketYearModel } from "models/admin/industryModel";
import { useNavigate } from "react-router";
import { normalDate } from "utils/dateTimeFormat";
import MarketService from "services/admin/marketService";
import TablePagination from "components/base/TablePagination/TablePagination";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import useUserInfoStore from "state/useUserInfoStore";
import ConfirmModal, { ConfirmModalProps } from "components/base/ConfirmModal/ConfirmModal";
import { usePermissionContext } from "context/PermissionProvider";
import { IndustryManageActionsPermissionCodes } from "models/auth/permissionModel";

const List: FC = () => {
    // #region 變數
    const [list, setList] = useState<MarketYearModel[]>([]);
    const [orgList, setOrgList] = useState<MarketYearModel[]>([]);
    const [newList, setNewList] = useState<MarketYearModel[]>([]);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [checkCount, setCheckCount] = useState(0);
    const [openCheck, setOpenCheck] = useState(false);
    const [count, setCount] = useState<number | undefined>(0);
    const [listIsPending, setListIsPending] = useState(false);
    const { userInfo } = useUserInfoStore();
    const [filterYears, setFilterYears] = useState<OptionModel[]>([]);
    const [request, setRequest] = useState<IndicatorListRequest2Model>({
        page: 1,
        pageSize: 10,
        searchKey: null,
        sortKey: null,
        sortType: null,
        marketType: userInfo.accType === 1 ? 0 : 1,
        year: null
      });
    // modal 物件(confirm)
    const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({
        show: false,
        handleClose: () => {
        setConfirmModal({
            ...confirmModal,
            show: false,
        });
        },
        handleConfirm: () => {},
        title: "確認",
    });
    const { PermissionService } = usePermissionContext();
    const { detectEveryActionPermission } = PermissionService;
    // #endregion

    // #region CRUD

    //取的清單
    const GetListMutation = useMutation({
        mutationFn: MarketService.marketList,
    });
    // 取得清單
    const handleList = () => {
        setListIsPending(true);
        GetListMutation
        .mutateAsync(request)
        .then(p=>{
            setListIsPending(false);
            if(p.success){
                setList(p.data);
                if(filterYears.length === 0) {
                    const tmpYears: (OptionModel[]) = [{
                        id: 'null',
                        text: "全部",
                        enumKey: 999
                    }];
                    p.data.forEach((y: MarketYearModel)=> {
                        if(y.year!==null && y.year !== undefined) {
                            let chk = tmpYears.find(x=>x.id===y.year?.toString());
                            if(!chk) {
                                tmpYears.push({id: y.year?.toString(), text: y.year?.toString() + '年度', enumKey: y.year})
                            }
                        }
                    });
                    tmpYears.sort((a, b) => b.enumKey! - a.enumKey!);
                    setFilterYears(tmpYears);
                }
                setOrgList(JSON.parse(JSON.stringify(p.data)));
                setCount(p.count);
            } else {
                toast.error('error');
            }
        })
        .catch((err) => {
            setListIsPending(false);
            console.log(err);
            setList([]);
            toast.error(err);
        });
    };

    // 新增
    const addMarketYearMutation = useMutation({
        mutationFn: MarketService.addMarket,
    });

    // 新增事件
    const handleAdd = (index: number) => {
        if(newList[index].marketType === null || newList[index].year === null) {
            toast.error('欄位必填');
        } else {
            const add = {
                id: null,
                marketType: newList[index].marketType,
                year: newList[index].year
            };
            setListIsPending(true);
            addMarketYearMutation
            .mutateAsync(add)
            .then(p=>{
                setListIsPending(false);
                if(p.success){
                    handleList();
                    setNewList([]);
                    toast.success('success');
                } else {
                    toast.error(p.message);
                }
            })
            .catch((err) => {
                setListIsPending(false);
                console.log(err);
                toast.error(err);
            });
        }
    };

    // 複製
    const copyMarketYearMutation = useMutation({
        mutationFn: MarketService.copyMarket,
    });

    // 複製事件
    const handleCopy = (data: MarketYearModel | null) => {
        if(data) {
            if(data.year) {
                let copyTmp = {
                    id: data.id,
                    newYear: data.year + 1
                }
                copyEvent(copyTmp);
            }
        } else {
            const checked = list.find(x=>x.checked === true);
            if(checked && checked.year) {
                const copyTmp = {
                    id: checked.id,
                    newYear: checked.year + 1
                };
                copyEvent(copyTmp);
            }
        }
    };

    const copyEvent = (data: any) => {
        setListIsPending(true);
        copyMarketYearMutation
        .mutateAsync(data)
        .then(p=>{
            setListIsPending(false);
            if(p.success){
                handleList();
                toast.success('success');
                setCheckCount(0);
            } else {
                toast.error(p.message);
            }
        })
        .catch((err) => {
            setListIsPending(false);
            console.log(err);
            toast.error(err);
        });
    }

    // 刪除
    const removeMarketYearMutation = useMutation({
        mutationFn: MarketService.removeMarket,
    });

    //刪除清單
    const handleRemove = (id: string | null) => {
        setConfirmModal({
            ...confirmModal,
            show: true,
            handleConfirm: () => {
                let checked = list.filter(x=>x.checked === true);
                if(id) {
                    checked = list.filter(x=>x.id === id);
                }
                const ids: (string| null)[] = [];
                if(checked) {
                    checked.forEach((c: MarketYearModel) => {
                        ids.push(c.id);
                    });
                    setConfirmModal((prev) => {
                        return {
                            ...prev,
                            loading: true,
                        };
                    });
                    setListIsPending(true);
                    removeMarketYearMutation
                            .mutateAsync(ids)
                            .then(p=>{
                                setListIsPending(false);
                                if(p.success){
                                    handleList();
                                    toast.success('刪除成功');
                                    setCheckCount(0);
                                } else {
                                    toast.error(p.message);
                                }
                            })
                            .catch((err) => {
                                setListIsPending(false);
                                console.log(err);
                                toast.error(err);
                            })
                            .finally(()=> {
                                setConfirmModal({
                                    ...confirmModal,
                                    show: false,
                                    loading: false,
                                });
                            });

                }
            },
          });
    };

    // 編輯
    const saveMarketYearMutation = useMutation({
        mutationFn: MarketService.saveMarket,
    });

    // 編輯事件
    const handleSave = (index: number) => {
        if(list[index].marketType === null || list[index].year === null) {
            toast.error('欄位必填');
        } else {
            const add = {
                id: list[index].id,
                marketType: list[index].marketType,
                year: list[index].year
            };
            setListIsPending(true);
            saveMarketYearMutation
            .mutateAsync(add)
            .then(p=>{
                setListIsPending(false);
                if(p.success){
                    handleList();
                    toast.success('success');
                } else {
                    toast.error(p.message);
                }
            })
            .catch((err) => {
                setListIsPending(false);
                console.log(err);
                toast.error(err);
            });
        }
    };

    // #endregion

    // #region 事件

    useEffect(() => {
        handleList();
    }, [request]);

    /*const options: OptionModel[] = [
        {
          id: 'null',
          text: "全部",
        }
        {
          id: '2',
          text: "公發",
        },
        {
          id: '3',
          text: "興櫃",
        }
      ];*/
      const options: any[] = [
        /*{
          id: 'null',
          text: "全部",
        }*/];
        if(userInfo.accType === 1) {
          options.push({
            id: '0',
            text: "上市",
          });
          options.push({
            id: '2',
            text: "公發",
          });
        } else {
          options.push({
            id: '1',
            text: "上櫃",
          });
          options.push({
            id: '3',
            text: "興櫃",
          });
        }
        const options2: any[] = [];
        if(userInfo.accType === 1) {
          options2.push({
            id: '0',
            text: "上市",
          });
          options2.push({
            id: '2',
            text: "公發",
          });
        } else {
          options2.push({
            id: '1',
            text: "上櫃",
          });
          options2.push({
            id: '3',
            text: "興櫃",
          });
        }


    let today = new Date();

    let yearOption2 : any[] = [];
    for(let i=today.getFullYear();i>=2021; i--) {
        yearOption2.push({
            id: i.toString(),
            text: `${i.toString()}年度`
        });
    }


    function handleAllCheckbox() {
        setCheckAll(!checkAll);
        if(!checkAll === true) {
            setCheckCount(list.length);
        } else {
            setCheckCount(0);
        }
        list.forEach(item => {
            item.checked = !checkAll;
        });
    }

    function countCheckbox() {
        const total = list.length;
        const checked = list.filter(x=>x.checked === true).length;
        setCheckCount(checked);
        if(total !== 0 && total === checked) {
            setCheckAll(true);
        } else if(checked !== 0 && total !== checked) {
            setCheckAll(false);
        } else {
            setCheckAll(false);
        }
    }

    function handleChkChange(id: string | null) {
        if(id) {
            setList(list.map((ck, index) => {
                    if(ck.id === id){
                        ck.checked = !ck.checked;
                    }
                    return ck;
                }
            ));
            countCheckbox();
        }
    }

    const handleDropdownChange = (str: string | null) => {
        console.log(str);
    };

    // 篩選切換年度
    const handleYearChange = (year: string | null) => {
        let tmpreq = {...request};
        if(year) {
            tmpreq.year = parseInt(year, 10);
            setRequest(tmpreq);

        } else {
            tmpreq.year = null;
            setRequest(tmpreq);
        }
    };

    // 篩選切換市場別
    const handleMarketTypeChange = (type: string | null) => {
        let typeNum = null;
        //if(type !== '0') {
            typeNum = type;
        //}
        let tmpreq = {...request};
        tmpreq.marketType = !typeNum ? null : parseInt(typeNum, 10);
        setRequest(tmpreq);
    }

    const handleTmpClick = () => {
        console.log("test");
      };

      const handleEditToggle = (index: number|null, isEdit: boolean) => {
        let tmpList = [...list];
        if(index===null) {
          tmpList.forEach(t => {
            t.isEdit = false;
          })
        } else {
          tmpList[index].isEdit=isEdit;
          if(isEdit===false){
            tmpList[index].marketType=orgList[index].marketType;
            tmpList[index].year=orgList[index].year;
          }
        }
        setList(tmpList);
    }
    // 選取年度開關
    const handleOpenCheckToggle = () => {
        setOpenCheck(!openCheck);
        setCheckAll(false);
        setCheckCount(0);
        setNewList([]);
        handleEditToggle(null, false);
        setList(list.map((ck, index) => {
            ck.checked = false;
            return ck;
            }
        ));
    };

    const navigate = useNavigate();
    const handleNavigate = (item: MarketYearModel) => {
        if(item.id) {
            navigate(`/admin/industry/${item.id}`);
        }
    }

    const handleDDIndustryChange = (industry: string | null, index: number, isAdd: boolean) => {
        if(isAdd === true) {
            if(newList[index] && industry) {
                setNewList(newList.map((ck, i) => {
                        if(index === i){
                            ck.marketType = parseInt(industry, 10);
                        }
                        return ck;
                    }
                ));
            }
        } else {
            if(list[index] && industry) {
                setList(list.map((ck, i) => {
                    if(index === i){
                        ck.marketType = parseInt(industry, 10);
                    }
                    return ck;
                }));
            }
        }
    }

    function handleDDYearChange(year: string | null, index: number, isAdd: boolean) {
        if(isAdd === true) {
            if(newList[index] && year) {
                setNewList(newList.map((ck, i) => {
                        if(index === i){
                            ck.year = parseInt(year, 10);
                        }
                        return ck;
                    }));
            }
        } else {
            if(list[index] && year) {
                setList(list.map((ck, i) => {
                    if(index === i){
                        ck.year = parseInt(year, 10);
                    }
                    return ck;
                }));
            }
        }
    }


    // #endregion
    return (
        <div className={styles["sector-list"]}>
            <div className="no-nav-content-box">
                <div className="title">行業別管理</div>

                <div className="tool-box mt-20-p">
                    <div className="tool-box">
                        {!openCheck ? (
                            <>
                            <div className="mr-1-m">
                            <Dropdown
                                placeholder={"市場別"}
                                options={options}
                                defaultId={request.marketType === null ? 'null' : request.marketType?.toString()}
                                onChange={handleMarketTypeChange}
                                />
                            </div>
                            <div>
                            <Dropdown
                                placeholder={"年度"}
                                options={filterYears}
                                defaultId={request.year === null ? 'null' : request.year?.toString()}
                                onChange={handleYearChange}
                                />
                            </div>
                            </>
                            ): <>
                            <div className="tool-box mt-10-p">
                                <div className="sel-item">已選取{checkCount}項目</div>
                                {/*<div className="icon-button">
                                    <LinkIconButton
                                        imgName={`${checkCount===1 ? 'copy-icon.svg' :'copy-lock-icon.svg'}`}
                                        text="複製"
                                        className={`${checkCount===1 ? '' :'lock'}`}
                                        onClick={checkCount===1 ? () => handleCopy(null) : ()=>{}}
                                        />
                                </div>*/}
                              {detectEveryActionPermission([
                                IndustryManageActionsPermissionCodes.IndustryYearListDelete,
                              ]) ? (
                                <div className="icon-button">
                                  <LinkIconButton
                                    imgName={`${checkCount > 0 ? 'trash-icon.svg' : 'trash-lock-icon.svg'}`}
                                    text="刪除"
                                    className={`${checkCount > 0 ? '' : 'lock'}`}
                                    onClick={checkCount > 0 ? () => handleRemove(null) : () => { }}
                                  />
                                </div>
                              ) : null}

                            </div>
                            </>
                            }
                        </div>

                        <div className="tool-box">
                        <button className="secondary mr-1-m" onClick={handleOpenCheckToggle}>
                            {!openCheck ? '選取年度': '取消選取'}
                            </button>
                            {/*<button className="default" onClick={()=>{
                                setNewList([
                                    ...newList,
                                    {
                                    id: (newList.length++).toString(),
                                    marketType: userInfo.accType === 1 ? 0 : 1,
                                    marketTypeName: null,
                                    year: null,
                                    checked: false,
                                    isEdit: false
                                    }
                                ]);
                                setOpenCheck(false);
                            }}>
                            +新增
                            </button> */}
                        </div>



                </div>

                {/** 主表 */}
                <div className="result-box scroll">
                {listIsPending === true ? <VisuallLoading /> : <></>}
                    <div className="table-box">

                        <table
                            aria-label="查詢結果table"
                            className="table-container sticky-table"
                        >
                        <thead>
                            <tr>
                            {openCheck ? (
                            <th style={{width: '50px'}} scope="col">
                                <label className="custom-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={checkAll}
                                        onChange={() => handleAllCheckbox()}
                                    />
                                    <span className={`${checkCount>0 ? 'partial' :''}`}>&nbsp;</span>
                                </label>
                            </th>
                            ) : <></>}
                            <th scope="col">市場別
                            <LinkIconButton
                            imgName="sort-icon.svg"
                            text={null}
                            className={'d-inline'}
                            onClick={handleTmpClick}
                            />
                            </th>
                            <th scope="col">年度
                            <LinkIconButton
                            imgName="sort-icon.svg"
                            text={null}
                            className={'d-inline'}
                            onClick={handleTmpClick}
                            />
                            </th>
                            <th scope="col">編輯日期
                            <LinkIconButton
                            imgName="sort-icon.svg"
                            text={null}
                            className={'d-inline'}
                            onClick={handleTmpClick}
                            />
                            </th>
                            <th scope="col" style={{width: '40px'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                        {list.length === 0 ? <tr><td style={{textAlign: 'center'}} colSpan={5}>no data</td></tr> :
                        list.map((p, i) => {
                            return (
                                <tr key={p.id} className="cur-pointer">
                                    {/*開啟checkbox的td*/}
                                    {openCheck ? (
                                    <td>
                                        <label className="custom-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={p.checked}
                                                onChange={()=>handleChkChange(p.id)}
                                            />
                                            <span>&nbsp;</span>
                                        </label>
                                    </td>
                                    ) : <></>}
                                    {/*市場別*/}
                                    { openCheck || p.isEdit!==true ?
                                        (<td onClick={()=>handleNavigate(p)}>{options.find(x=>x.id === p.marketType?.toString())?.text}</td>) :
                                        (<td><Dropdown
                                            placeholder={"市場別"}
                                            isFloatTitle={false}
                                            options={options2}
                                            defaultId={p.marketType?.toString()}
                                            onChange={(e) => handleDDIndustryChange(e, i, false)}
                                            /></td>)
                                    }
                                    {/*年度*/}
                                    { openCheck || p.isEdit!==true ?
                                        (<td onClick={()=>handleNavigate(p)}>{p.year}</td>) :
                                        (<td><Dropdown
                                            isFloatTitle={false}
                                            placeholder={"編輯年度"}
                                            disabled={true}
                                            options={yearOption2}
                                            defaultId={p.year?.toString()}
                                            onChange={(e) => handleDDYearChange(e, i, false)}
                                            /></td>)
                                    }
                                    <td onClick={()=>handleNavigate(p)}>{p.updateTime? normalDate(p.updateTime) : '-'}</td>
                                    {!openCheck ? (
                                    <td>
                                        {
                                            p.isEdit===true ? (
                                                /** 儲存與取消 */
                                        <div className="d-flex">

                                             {/* <LinkIconButton
                                              imgName="check-icon.svg"
                                              text=""
                                              imgStyle={{ width: '24px', height: '24px' }}
                                              onClick={() => handleSave(i)}
                                            />
                                              <LinkIconButton
                                                imgName="cross-icon.svg"
                                                text=""
                                                imgStyle={{ width: '24px', height: '24px' }}
                                                onClick={() => handleEditToggle(i, false)}
                                              /> */}


                                        </div>
                                      ) :

                                            // /* 開啟編輯*/
                                            // <LinkIconButton
                                            //   imgName="right-arrow.svg"
                                            //   text=""
                                            //   onClick={() => handleEditToggle(i, true)}
                                            // />

                                      null

                                    }
                                  </td>
                                ) : (
                                  <td>
                                    { /** 複製與刪除 */}
                                    <div className="d-flex">
                                      {/* <LinkIconButton
                                                    imgName="copy-icon.svg"
                                                    text=""
                                                    className={'hover'}
                                                    onClick={()=>handleCopy(p)}
                                                    />*/}
                                      {detectEveryActionPermission([
                                        IndustryManageActionsPermissionCodes.IndustryYearListDelete,
                                      ]) ? (
                                        <LinkIconButton
                                          imgName="trash-icon.svg"
                                          text=""
                                          className={'hover'}
                                          onClick={() => handleRemove(p.id)}
                                        />
                                      ) : null}

                                    </div>
                                  </td>
                                )}
                              </tr>
                            );
                            })}
                            {newList.map((n, i) => {
                                return (
                                    <tr key={n.id} className="cur-pointer">
                                        <td><Dropdown
                                                placeholder={"市場別"}
                                                isFloatTitle={false}
                                                options={options2}
                                                defaultId={n.marketType?.toString()}
                                                onChange={(e) => handleDDIndustryChange(e, i, true)}
                                                /></td>
                                        <td>
                                        <Dropdown
                                            placeholder={"新增年度"}
                                            isFloatTitle={false}
                                            options={yearOption2}
                                            defaultId={n.year?.toString()}
                                            onChange={(e) => handleDDYearChange(e, i, true)}
                                            />
                                        </td>
                                        <td>-</td>
                                        <td>
                                        <div className="d-flex">

                                          {/* <LinkIconButton
                                            imgName="check-icon.svg"
                                            text=""
                                            imgStyle={{ width: '24px', height: '24px' }}
                                            onClick={() => handleAdd(i)}
                                          /> */}

                                        {detectEveryActionPermission([
                                          IndustryManageActionsPermissionCodes.IndustryYearListDelete,
                                        ]) ? (
                                          <LinkIconButton
                                            imgName="cross-icon.svg"
                                            text=""
                                            imgStyle={{ width: '24px', height: '24px' }}
                                            onClick={() => {
                                              setNewList(
                                                newList.filter(a =>
                                                  a.id !== n.id
                                                )
                                              )
                                            }}
                                          />
                                        ) : null}
                                            </div>
                                        </td>
                                    </tr>
                                );
                                })}
                        </tbody>
                        </table>

                    </div>
                </div>
                <div className="pad-10 page" style={{border: '1px solid #dbe8ef'}}>
                    <TablePagination
                        page={request.page}
                        pageSize={request.pageSize}
                        onChange={(e) => {
                            let tmpreq = {...request};
                            tmpreq.pageSize = e.pageSize;
                            tmpreq.page = e.page;
                            setRequest(tmpreq);
                        }}
                        total={count || 0}
                    />
                </div>
            </div>
            {/* 確認刪除modal */}
            <ConfirmModal {...confirmModal}>
            <div>是否刪除?</div>
            </ConfirmModal>
        </div>
    );
};


export default List;
