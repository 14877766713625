import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useUserInfoStore from "../../../state/useUserInfoStore";
import Navbar from "../../../components/layouts/Header/Header";
import styles from "./Home.module.scss";
import { useNavigate } from "react-router-dom";
import { CommonService } from "services/common/commonService";
import { ApplyHomeModuleModel } from "models/application/home/applyHomeModel";
import { MarketTypeEnum } from "models/baseModel";
import { useMutation } from "@tanstack/react-query";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import ContentModal, { ContentModalProps } from "components/base/ContentModal/ContentModal";
import { redirectEntrance } from "utils/redirectExternal";
import { usePermissionContext } from "context/PermissionProvider";
import { PagePermissionCodes } from "models/auth/permissionModel";

interface HomeProps {
  isActiveGenReport: boolean
}

const Home: FC<HomeProps> = ({isActiveGenReport}) => {
  const navigator = useNavigate();
  const { userInfo, setUserInfo } = useUserInfoStore();
  const [moduleArr, setModuleArr] = useState<ApplyHomeModuleModel[]>([]);
  const [moduleArr2, setModuleArr2] = useState<ApplyHomeModuleModel[]>([]);
  const [noteMsg, setNoteMsg] = useState<string>();
  const [hasGenReport, setHasGenReport] = useState<boolean>(isActiveGenReport);
  const { PermissionService } = usePermissionContext();
  const { detectEveryPagePermission } = PermissionService;
  // 基礎url
  const imgBaseUrl = "/assets/images/sidebar";
  //#region 初始資料
  const initModuleArr: ApplyHomeModuleModel[] = [
    {
      id: "indicator",
      title: "企業ESG資訊\n揭露申報作業",
      router: "/apply/indicator",
      img: `${imgBaseUrl}/sidebar-prefecture-icon.svg`,
      iconImg: `${imgBaseUrl}/sidebar-prefecture-icon.svg`,
      note: `依法規申報(*註1)`,
      revealText: "",
    },
    {
      id: "sustain-report",
      title: "永續報告書\n申報作業",
      router: "/apply/sustain-report",
      img: `${imgBaseUrl}/sidebar-book-icon.svg`,
      iconImg: `${imgBaseUrl}/sidebar-book-icon.svg`,
      note: `依法規申報(*註2)`,
      revealText: "",
    },
    {
      id: "ghg-reduction",
      title: "溫室氣體排放\n及減量資訊\n申報作業",
      router: "/apply/ghg-reduction",
      img: `${imgBaseUrl}/sidebar-ghg-reduction-icon.svg`,
      iconImg: `${imgBaseUrl}/sidebar-ghg-reduction-icon.svg`,
      note: `鼓勵上市櫃公司自願揭露`,
      revealText: "自願揭露",
    },
    {
      id: "sustain-questionnaire",
      title: "永續經濟活動\n問卷申報作業",
      router: "/apply/sustain-questionnaire",
      img: `${imgBaseUrl}/sidebar-questionnaire-icon.svg`,
      iconImg: `${imgBaseUrl}/sidebar-questionnaire-icon.svg`,
      note: `鼓勵上市櫃公司自願揭露`,
      revealText: "自願揭露",
    },
  ];
  const initModuleArr2 = [
    {
      id: "report-generation",
      title: "永續報告書產製",
      router: "/apply/report-generation",
      img: `${imgBaseUrl}/sidebar-report-generation-icon.svg`,
      iconImg: `${imgBaseUrl}/sidebar-white-report-generation-icon.svg`,
      note: ``,
      revealText: ""
    },
  ];
  const logoutSec = useRef(1800);
  const [modal, setModal] = useState<ContentModalProps>({
    show: false,
    handleClose: () => handleCloseModal(),
    title: "通知"
  });
  // 備註
  const initNoteMsgs = [
    "ESG資訊揭露：依據臺灣證券交易所股份有限公司「對有價證券上市公司及境外指數股票型基金上市之境外基金機構資訊申報作業辦法」，請上市公司揭露。",
    "永續報告書申報作業：依據臺灣證券交易所「上市公司編製與申報永續報告書作業辦法」請上市公司揭露。",
    "ESG資訊揭露：依據財團法人中華民國證券櫃檯買賣中心「對有價證券上櫃公司資訊申報作業辦法」，請上櫃公司揭露。",
    "永續報告書申報作業：依據財團法人中華民國證券櫃檯買賣中心「上櫃公司編製與申報永續報告書作業辦法」請上櫃公司揭露。",
  ];
  //#endregion

  useEffect(() => {
    if (userInfo && userInfo.marketType !== null) {
      console.log(userInfo);
      const permissions = CommonService.getApplyMarketPermissions(
        userInfo.marketType
      );
      const mainModule = initModuleArr.filter((p) =>
        permissions.includes(p.id)
      );
      setModuleArr([...mainModule]);
      const otherModule = initModuleArr2.filter((p) =>
        permissions.includes(p.id)
      );
      setModuleArr2([...otherModule]);
      setNoteData(userInfo.marketType);
    }
  }, [userInfo]);

  useEffect(() => {
    setHasGenReport(isActiveGenReport);
    document.addEventListener("mousemove", (e) => {
      logoutSec.current = 1800;
    });
    for (let i = 0; i < 9999; i++) {
      window.clearInterval(i);
    }
    AutoLogout();
  }, []);

  const setNoteData = (type: MarketTypeEnum) => {
    let str = "";
    switch (type) {
      case MarketTypeEnum.Listing:
        str += `1.${initNoteMsgs[0]}\n2.${initNoteMsgs[1]}`;
        break;
      case MarketTypeEnum.OTC:
        str += `1.${initNoteMsgs[2]}\n2.${initNoteMsgs[3]}`;
        break;
      case MarketTypeEnum.PO:
        str += `1.${initNoteMsgs[1]}`;
        break;
      case MarketTypeEnum.Emerging:
        str += `1.${initNoteMsgs[3]}`;
        break;
    }
    setNoteMsg(str);
  };

  // 永續報告書產製
  const gotoTarget = (url: string) => {
    navigator(url);
  };

  const AutoLogout = () => {
    const timer = setInterval((() => {
      //console.log(logoutSec);
      if (logoutSec.current <= 0) {
        console.log('逾時登出');
        setModal((prev) => {
          return {
            ...prev,
            show: true,
          };
        });
        localStorage.clear();
        sessionStorage.clear();
        window.clearInterval(timer);
        return false;
      }
      // console.log(this.logoutSec);
      logoutSec.current-=1;
      /*if(logoutSec.current % 100 === 0) {
        console.log('逾時登出');
      }*/
    }), 1000);
  }
  const handleCloseModal = () => {
    setModal((prev) => {
      handleConfirm();
      return {
        ...prev,
        show: !prev.show,
      };
    });
  };
  const handleConfirm = () => {
    setUserInfo(null);
    redirectEntrance();
    sessionStorage.clear();
    localStorage.clear();
  }

  return (
    <div className={styles["apply-home-box"]}>
      {/* navbar */}
      <div>
        <Navbar />
      </div>
      <div className={`${styles["home-box"]} scroll`}>
        <div className={styles["home-container-box"]}>
          {/* 左邊 */}
          <div className={styles["home-left"]}>
            {/* 四個區塊 */}
            <div className={styles["module-box"]}>
              {moduleArr.map((module) => {
                return (
                  <div key={module.id} className={styles["module-container"]}>
                    {module.revealText && (
                      <div className={styles["reveal-text"]}>
                        {module.revealText}
                      </div>
                    )}
                    <div
                      className={styles["module-item"]}
                      onClick={() => {
                        gotoTarget(module.router);
                      }}
                    >
                      {/* 背景 */}
                      <img
                        className={styles["module-bg"]}
                        alt={module.title}
                        src={module.img}
                      />
                      {/* icon */}
                      <img
                        className={styles["module-icon"]}
                        alt={module.title}
                        src={module.iconImg}
                      />
                      <div className={styles["module-name"]}>
                        {module.title}
                      </div>
                    </div>
                    <div className={styles["module-note"]}>{module.note}</div>
                  </div>
                );
              })}
            </div>
            {/* 備註 */}
            <div className={styles.note}>
              註：<br/>
              {noteMsg}
            </div>
          </div>
          {/* 右邊產製 */}
          {isActiveGenReport && detectEveryPagePermission([PagePermissionCodes.SustainReportProduceModule]) &&
          <div className={styles["home-right"]}>
            {moduleArr2.map((module) => {
              return (
                <div key={module.id} className={styles["module-container"]}>
                  <div
                    className={styles["module-item"]}
                    onClick={() => {
                      gotoTarget(module.router);
                    }}
                  >
                    {/* 背景 */}
                    <img
                      className={styles["module-bg"]}
                      alt={module.title}
                      src={module.img}
                    />
                    {/* icon */}
                    <img
                      className={styles["module-icon"]}
                      alt={module.title}
                      src={module.iconImg}
                    />
                    <div className={styles["module-name"]}>{module.title}</div>
                    <div className={styles["module-btn"]}>
                      <button className="default min-w-fit">前往產製</button>
                    </div>
                  </div>
                  <div className={styles["module-note"]}>{module.note}</div>
                </div>
              );
            })}
          </div>}
        </div>
      </div>
      <ContentModal {...modal}>
        <div>您的操作已逾時，請透過SII系統重新登入。</div>
        <div className="d-flex" style={{justifyContent: 'end'}}>
          <div className="btn-item">
            <button className="default" onClick={handleConfirm}>
              確認
            </button>
          </div>
        </div>
      </ContentModal>
    </div>
  );
};

export default Home;
