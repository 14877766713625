import { MarketTypeEnum, RequestType } from "models/baseModel";
import { EsgMatrixDiagramModel, MopsSingleCompanyDataRequestModel, MopsSummaryDataRequestModel } from "models/inquiry/infoDisclosure/mopsEsgModel";
import { HttpClient } from "services/httpClient";

const httpClient = new HttpClient();

/** 取得全公司代號名稱列表 */
const getAllCompanyCode = () => {
    return httpClient.get(`mopsEsg/allCompanyCode`);
}

/** 個別公司查詢 */
const getSingleCompanyData = (request: MopsSingleCompanyDataRequestModel) => {
    return httpClient.post(`mopsEsg/singleCompanyData`, request);
}

/** 個別公司查詢(2022 年含以前) */
const getSingleCompanyDataOld = (request: MopsSingleCompanyDataRequestModel) => {
    return httpClient.post(`mopsEsg/singleCompanyData/old`, request);
}

/** 取得產業別清單與議題清單 */
const getIndustryAndSample = (marketType: MarketTypeEnum) => {
    return httpClient.get(`mopsEsg/industryAndSample?marketType=${marketType}`);
}

/** 彙總資料查詢 */
const getSummaryData = (model: MopsSummaryDataRequestModel) => {
    return httpClient.post(`mopsEsg/summaryData`, model);
}

/** 彙總資料查詢 (含2022以前) */
const getSummaryDataOld = (model: MopsSummaryDataRequestModel) => {
    return httpClient.post(`mopsEsg/summaryData/old`, model);
}

/** 取得報告(資料)年度清單 */
const getYearList = (type?: RequestType) => {
    let param = type !== null && type !== undefined ? `?type=${type}`:'';
    return httpClient.get(`mopsEsg/yearList${param}`);
}


/** 透過companyCode & 年度 匯出永續準則矩陣圖 */
const getMatrixDiagram = (data: EsgMatrixDiagramModel) => {
    return httpClient.postBlob(`mopsEsg/matrix-diagram/FileStream?companyCode=${data.companyCode}&year=${data.year}`);
}

const mopsEsgService = {
    getAllCompanyCode,
    getSingleCompanyData,
    getIndustryAndSample,
    getSummaryData,
    getYearList,
    getMatrixDiagram,
    getSingleCompanyDataOld,
    getSummaryDataOld
};
export default mopsEsgService;
