import React, { FC } from "react";
import styles from "./LinkIconButton.module.scss";

interface LinkIconButtonProps {
  imgName: string;
  text: string | null;
  imgStyle?: {
    width?: string | "16px";
    height?: string | "16px";
  };
  onClick: () => void | null;
  className?: string | null;
  /** 圖片位置 */
  imgPosition?: "img-right" | "img-left";
}

const LinkIconButton: FC<LinkIconButtonProps> = ({
  imgPosition = "img-left",
  ...props
}) => {
  // 父層事件處理
  const handleEvent = () => {
    props.onClick();
  };
  return (
    <div
      className={
        `${styles["link-icon-button-box"]} ` +
        `${props.className ? props.className : ""} ` +
        `${imgPosition} `
      }
      onClick={handleEvent}
    >
      <img
        alt=""
        style={props.imgStyle}
        src={"/assets/images/buttonIcon/" + props.imgName}
      />
      {props.text !== null ? <div className="text">{props.text}</div> : <></>}
    </div>
  );
};

export default LinkIconButton;
