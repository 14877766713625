import React, { FC, useEffect, useRef, useState } from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useUserInfoStore from "../../../state/useUserInfoStore";
import styles from "./Header.module.scss";
import { ProgressBarModel } from "models/header/progressBarModel";
import AuthService from "services/auth/authService";
import { redirectEntrance } from "utils/redirectExternal";
import { CommonService } from "services/common/commonService";
import EmptyWaitPage from "components/common/EmptyWaitPage/EmptyWaitPage";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";
import useEmptyMessageStore from "state/useEmptyMessageStore";
import { EmptyMessageType } from "models/baseModel";
interface HeaderProps {
  moduleName?: string;
  moduleColor?: string;
}

const Header: FC<HeaderProps> = (props) => {
  const { moduleName } = props;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isAdmin = useUserInfoStore((p) => p.userInfo?.isAdmin);
  const { userInfo, setUserInfo } = useUserInfoStore();
  // 空頁面對應要設置的內容
  const { setMessageType } = useEmptyMessageStore();
  const [platform, setPlatform] = useState("null");
  // const logoutSec = useRef(1800);
  const [modal, setModal] = useState<ContentModalProps>({
    show: false,
    handleClose: () => handleCloseModal(),
    title: "通知",
  });
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    AuthService.setLanguage(lng);
    window.location.reload();
  };

  const handlePlatform = (id: string) => {
    setPlatform(id);
    navigate(`${id}`);
  };

  const [jobData, setJobData] = useState<ProgressBarModel[]>([
    {
      text: "測試批次項目",
      chartCurretValue: 75,
      chartTargetValue: 100,
    },
    {
      text: "測試批次項目2",
      chartCurretValue: 0,
      chartTargetValue: 100,
    },
  ]);

  const handleCloseModal = () => {
    setModal((prev) => {
      handleConfirm();
      return {
        ...prev,
        show: !prev.show,
      };
    });
  };

  const logout = (type: string) => {
    sessionStorage.clear();
    localStorage.clear();
    setTimeout(() => {
      // 要放在清除之後
      setMessageType(type);
      redirectEntrance();
    }, 50);
  };

  // useEffect(() => {
  //   document.addEventListener("mousemove", (e) => {
  //     logoutSec.current = 1800;
  //   });
  //   switch (moduleName) {
  //     case t("MANAGEMENT_BACKGROUND"):
  //     case t("DECLARATION_FRONT"):
  //       for (let i = 0; i < 9999; i++) {
  //         window.clearInterval(i);
  //       }
  //       AutoLogout();
  //       break;
  //     default:
  //       for (let i = 0; i < 9999; i++) {
  //         window.clearInterval(i);
  //       }
  //       break;
  //   }
  // }, [moduleName]);

  // 前往對應模組
  const gotoTarget = () => {
    let url = "";
    switch (moduleName) {
      case t("DECLARATION_FRONT"):
        url = "/apply/home";
        break;
      case t("MANAGEMENT_BACKGROUND"):
        url = "/admin/home";
        break;
      case t("INQUIRY_AT_THE_FRONT"):
      default:
        url = "/inquiry";
        break;
    }
    navigate(url);
  };

  const ProfileComponent: FC = () => {
    return (
      <div className="profile-box">
        <div className="abbr-name">{userInfo.name?.substring(0, 1)}</div>
      </div>
    );
  };

  // const AutoLogout = () => {
  //   const timer = setInterval(() => {
  //     //console.log(logoutSec.current);
  //     if (logoutSec.current <= 0) {
  //       console.log('逾時登出');
  //       setModal((prev) => {
  //         return {
  //           ...prev,
  //           show: true,
  //         };
  //       });
  //       localStorage.clear();
  //       sessionStorage.clear();
  //       setMessageType(EmptyMessageType.Timeout);
  //       window.clearInterval(timer);
  //       return false;
  //     }
  //     logoutSec.current -= 1;
  //     /*if(logoutSec.current === 0) {
  //       console.log('逾時登出');
  //     }*/
  //   }, 1000);
  // };

  const handleConfirm = () => {
    logout(EmptyMessageType.Timeout);
  };

  return (
    <div className={`${styles["header-box"]}`}>
      <Navbar expand="md" className="bg-body-tertiary">
        <div className="header-title-box" onClick={gotoTarget}>
          {/* <Navbar.Brand href="/main/home">
            <img alt="" src="/assets/images/logo_header.svg" />
          </Navbar.Brand> */}
          <div className={`title`}>{t("ESG_DIGITAL_PLATFORM")}</div>
          {!CommonService.isTwseProduction() && (
            <div
              className="module-title"
              style={{
                color: props.moduleColor,
                borderColor: props.moduleColor,
              }}
            >
              測試
            </div>
          )}
        </div>
        <div className="nav-tool-box">
          {/* refresh 資訊 */}
          {/* <div className="position-relative">
            <BackgroundJobBar data={jobData} />
          </div> 
          <div className="split-line"></div>*/}
          {moduleName !== t("INQUIRY_AT_THE_FRONT") && (
            <div className="name">
              <span>{userInfo.companyCode}</span>
              <br />
              <span>{userInfo.name}</span>
            </div>
          )}
          <div className="d-flex align-items-center">
            {/* 證交所專案功能不需要 */}
            {CommonService.isDevMode() && (
              <>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto d-flex justify-content-end w-100">
                    <NavDropdown
                      className="nav-dropdown"
                      title={<ProfileComponent />}
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item
                        onClick={() => handlePlatform("/inquiry")}
                      >
                        {t("INQUIRY_AT_THE_FRONT")}
                      </NavDropdown.Item>
                      {!isAdmin && (
                        <>
                          <NavDropdown.Item
                            onClick={() => handlePlatform("/apply/home")}
                          >
                            {t("DECLARATION_FRONT")}
                          </NavDropdown.Item>
                        </>
                      )}

                      {isAdmin && (
                        <NavDropdown.Item
                          onClick={() => handlePlatform("/admin")}
                        >
                          {t("MANAGEMENT_BACKGROUND")}
                        </NavDropdown.Item>
                      )}
                      <NavDropdown.Item
                        onClick={() => handlePlatform("/development/tools")}
                      >
                        {t("WEBSITE_RESOURCE_MANAGEMENT")}
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => changeLanguage("en")}>
                        English
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => changeLanguage("zh-TW")}>
                        繁體中文
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => logout(EmptyMessageType.Logout)}
                      >
                        {t("LOGOUT")}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </>
            )}

            {/* 以下查詢前台都不需要有 */}
            {moduleName !== t("INQUIRY_AT_THE_FRONT") && (
              <>
                {/* 縮小圖示(證交所用) */}
                {!CommonService.isDevMode() && <ProfileComponent />}

                {/* 登出按鈕 */}
                <div
                  className={
                    `logout-text ` +
                    `${CommonService.isDevMode() ? "test-logout-text " : ""}`
                  }
                  onClick={() => logout(EmptyMessageType.Logout)}
                >
                  {t("LOGOUT")}
                </div>
              </>
            )}
          </div>
        </div>
      </Navbar>
      <ContentModal {...modal}>
        <div>您的操作已逾時，請透過SII系統重新登入。</div>
        <div className="d-flex" style={{ justifyContent: "end" }}>
          <div className="btn-item">
            <button className="default" onClick={handleConfirm}>
              確認
            </button>
          </div>
        </div>
      </ContentModal>
    </div>
  );
};

export default Header;
