//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect, createContext } from "react";
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import { useNavigate, useParams, Outlet } from "react-router-dom";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
//* ---------------------------- local end ---------------------------- *//

const Zh: FC = () => {
  return (
    <CustomErrorBoundary>
      <Outlet />
    </CustomErrorBoundary>
  );
};

export default Zh;
