import React, { FC, useEffect, useState } from "react";
import styles from "./SustainReportRouter.module.scss";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router";
import SustainReportList from "./SustainReportList/SustainReportList";
import SustainReportInfo from "./SustainReportInfo/SustainReportInfo";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";

const SustainReportLayout: FC = () => {
  return (
    <>
      <div className="no-nav-content-box">
        <CustomErrorBoundary>
          <Outlet></Outlet>
        </CustomErrorBoundary>
      </div>
    </>
  );
};

interface SustainReportRouterProps {}

const SustainReportRouter: FC<SustainReportRouterProps> = () => {
  // 創建router
  const routers: RouteObject[] = [
    {
      path: "",
      element: <SustainReportList />,
    },
    {
      path: ":id",
      element: <SustainReportLayout />,
      children: [
        {
          path: "",
          element: <Navigate to={"info"} />,
        },
        {
          path: "info",
          element: <SustainReportInfo />,
        },
      ],
    },
  ];
  // 相當於router
  let elements = useRoutes(routers);
  return <>{elements}</>;
};

export default SustainReportRouter;
