import React, { FC, useEffect, useState } from "react";
import styles from "./GhgReductionList.module.scss";
import { ListRequestModel, RequestType } from "models/baseModel";
import { useNavigate } from "react-router-dom";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { normalDate3 } from "utils/dateTimeFormat";
import TablePagination from "components/base/TablePagination/TablePagination";
import { useMutation } from "@tanstack/react-query";
import GhgDeclareService from "services/application/ghgReductionDeclare/ghgDeclareService";
import {
  GhgDeclareActionModel,
  GhgDeclareShowModel,
} from "models/application/ghgReductionDeclare/ghgDeclareModel";
import { CorrectionRequestModel } from "models/admin/declareDataMangeModel";
import { ResponseCode } from "models/responseCodeModel";
import { error2Alert, success2Alert } from "utils/otherToast";
import UpdateApplyModal, {
  UpdateApplyModalProps,
} from "components/otherModule/UpdateApplyModal/UpdateApplyModal";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import {
  DeclareDataAction,
  DeclareStatus,
} from "models/application/indicators/indicatorModel";
import useUserInfoStore from "state/useUserInfoStore";
import { CommonService } from "services/common/commonService";
import EmptySearchPage from "components/otherModule/EmptySearchPage/EmptySearchPage";
import ProblemReportContent from "components/otherModule/ProblemReportContent/ProblemReportContent";

interface GhgReductionListProps {}

const GhgReductionList: FC<GhgReductionListProps> = () => {
  const { userInfo } = useUserInfoStore();
  const [param, setParam] = useState<ListRequestModel>({
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortKey: null,
  });
  const navigate = useNavigate();
  const [listData, setListData] = useState<GhgDeclareShowModel[]>([]);
  // 有無公司資料
  const [companyDataWord, setCompanyDataWord] = useState<string | null>(null);

  useEffect(() => {
    // 無文字才能正常顯示
    let str = CommonService.getApplyDisplayWord(
      userInfo,
      "組織溫室氣體排放及減量資訊"
    );
    if (str) {
      setCompanyDataWord(str);
    } else {
      getGhgDeclareList.mutate(param);
    }
  }, []);

  const handleNavigate = (id: string) => {
    navigate(`/apply/ghg-reduction/${id}/info`);
  };
  //#region 取得清單資料
  const getGhgDeclareList = useMutation({
    mutationFn: (model: ListRequestModel) =>
      GhgDeclareService.getGhgDeclareList(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: GhgDeclareShowModel[] = res.data;
        setListData([...data]);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  //#endregion

  //#region 確認申報
  // modal 物件(confirm)
  const [applyModal, setApplyModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setApplyModal({
        ...applyModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "確認申報",
  });

  // 確認申報
  const applyMutation = useMutation({
    mutationFn: (model: GhgDeclareActionModel) =>
      GhgDeclareService.editGhgDeclareStatus(model),
    onSuccess: (res) => {
      setApplyModal({
        ...applyModal,
        show: false,
        loading: false,
      });
      if (res.success && res.code === ResponseCode.SuccessOK) {
        getGhgDeclareList.mutate(param);
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // 處理申報事件
  const handleApplyModal = (item: GhgDeclareShowModel) => {
    setApplyModal({
      ...applyModal,
      loading: true,
    });
    applyMutation.mutate({
      action: DeclareDataAction.Declared,
      declareId: item.id,
    });
  };

  //#endregion

  //#region 更正申請
  const updateApplyMutation = useMutation({
    mutationFn: (model: CorrectionRequestModel) =>
      GhgDeclareService.addGhgDeclareDatacCrrectionRequest(model),
    onSuccess: (res) => {
      setUpdateModal({
        ...updateModal,
        loading: false,
        show: false,
      });
      if (res.success && res.code === ResponseCode.SuccessOK) {
        getGhgDeclareList.mutate(param);
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [updateModal, setUpdateModal] = useState<UpdateApplyModalProps>({
    show: false,
    handleClose: () => {
      setUpdateModal({
        ...updateModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    requestType: RequestType.GhgEmissionAndReduction,
  });

  // 更正申請 model
  const [updateInfo, setUpdateInfo] = useState({
    applicantEmailList: [] as string[],
    correctionRequestReason: "",
  });

  // 處理modal更正申報
  const handleUpdateModal = (
    updateInfo: CorrectionRequestModel,
    item: GhgDeclareShowModel
  ) => {
    setUpdateModal({
      ...updateModal,
      loading: true,
    });
    updateApplyMutation.mutate({
      ...updateInfo,
      declareId: item.id,
    });
  };
  //#endregion

  //#region 取得按鈕名稱
  const getBtnNameByStatus = (
    status: DeclareStatus,
    canEdit: boolean
  ): string => {
    if (!canEdit) {
      return "檢視";
    }
    let str = "";
    switch (status) {
      case DeclareStatus.Declared:
      case DeclareStatus.ApplyForCorrection:
      case DeclareStatus.DeclareEnd:
        str = "檢視";
        break;
      case DeclareStatus.Correcting:
      case DeclareStatus.Declaring:
        str = "修改";
        break;
      case DeclareStatus.UnDeclared:
      default:
        str = "開始申報";
        break;
    }
    return str;
  };
  //#endregion

  return (
    <div className="no-nav-content-box">
      {!companyDataWord ? (
        <div className={styles["ghg-reduction-list-box"]}>
          <div className="title mb-20-p">溫室氣體排放及減量資訊申報作業</div>
          <div className="result-box scroll">
            <div className="table-box">
              {(updateApplyMutation.isPending ||
              getGhgDeclareList.isPending ||
              applyMutation.isPending) && <VisuallLoading></VisuallLoading>}
              <table
                aria-label="查詢結果table"
                className="table-container sticky-table"
              >
                <thead>
                  <tr>
                    <th scope="col">年度</th>
                    <th scope="col">最後編輯日期</th>
                    <th scope="col">首次申報日期</th>
                    <th scope="col">更正申報日期</th>
                    <th scope="col">狀態</th>
                    <th scope="col">功能</th>
                  </tr>
                </thead>
                <tbody>
                  {listData?.map((p: GhgDeclareShowModel) => {
                    return (
                      <tr key={p.id}>
                        <td>{p.declareYear}</td>
                        <td>{normalDate3(p.lastEditDate)}</td>
                        <td>{normalDate3(p.firstDeclareDate)}</td>
                        <td>{normalDate3(p.editDeclareDate)}</td>
                        <td>
                          <div
                            className={`status ${
                              p.statusName?.toString() === "未申報"
                                ? "no-declared"
                                : ""
                            }`}
                          >
                            {p.statusName}
                          </div>
                        </td>
                        <td className="func-td">
                          <div className="func-btn-box">
                            <button
                              className="secondary min-w-fit"
                              onClick={() => {
                                handleNavigate(p.id);
                              }}
                            >
                              {getBtnNameByStatus(p.status, p.canEdit)}
                            </button>
                            {(p.status === DeclareStatus.Declaring ||
                              p.status === DeclareStatus.Correcting) &&
                              p.isFinished && (
                                <button
                                  className="secondary min-w-fit"
                                  onClick={() => {
                                    setApplyModal({
                                      ...applyModal,
                                      show: true,
                                      handleConfirm: () => {
                                        handleApplyModal(p);
                                      },
                                    });
                                  }}
                                >
                                  確認申報
                                </button>
                              )}
                            {(p.status === DeclareStatus.Declared ||
                              p.status === DeclareStatus.DeclareEnd) && (
                              <button
                                className="secondary min-w-fit"
                                onClick={() => {
                                  setUpdateInfo({
                                    ...updateInfo,
                                    applicantEmailList: [],
                                    correctionRequestReason: "",
                                  });
                                  setUpdateModal({
                                    ...updateModal,
                                    show: true,
                                    declareStatus: p.status,
                                    handleConfirm: (e) => {
                                      handleUpdateModal(e, p);
                                    },
                                  });
                                }}
                              >
                                {p.status === DeclareStatus.DeclareEnd
                                  ? "申請開放"
                                  : "更正申請"}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="table-pagination mb-20-p">
            <TablePagination
              page={param.page}
              pageSize={param.pageSize}
              onChange={(e) => {
                setParam({
                  ...param,
                  ...e,
                });
              }}
              total={listData?.length}
            />
          </div>
          <div className="desc">
            <ProblemReportContent />
          </div>
        </div>
      ) : (
        <EmptySearchPage
          className={"no-company-data-word"}
          word={companyDataWord}
        />
      )}
      {/* 確認申報modal */}
      <ConfirmModal {...applyModal}>
        <div>
          確認後不得再編輯資料，若申報完成後需修改，請至
          <span style={{ fontWeight: 700 }}>申報資料更正申請。</span>
        </div>
      </ConfirmModal>
      {/* 申報資料更正申請 */}
      <UpdateApplyModal {...updateModal} />
    </div>
  );
};

export default GhgReductionList;
