"use client";
//* ---------------------------- React start ---------------------------- *//
import {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
} from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation, UseMutationResult } from "@tanstack/react-query";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./FollowingControlSettingModal.module.scss";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";
import {
  QuestionnaireSettingDataModel,
  ControlModel,
  ControlOptionModel,
} from "models/admin/questionnaireModel";
import { CommonService } from "services/common/commonService";
//* ---------------------------- local end ---------------------------- *//

export interface FollowingControlSettingModalProps {
  cancelFollowingControlSetting: (newControls: ControlModel[]) => void;
  saveFollowingControlSetting: (newControls: ControlModel[]) => void;
}

export interface FollowingControlSettingModalRefs {
  openModal: (
    handleControls: ControlModel[],
    selectedControl: ControlModel,
    selectedOption: ControlOptionModel
  ) => void;
  closeModal: () => void;
}

const FollowingControlSettingModal = forwardRef(
  (props: FollowingControlSettingModalProps, ref) => {
    //* ---------------------------- state start ---------------------------- *//
    const [modal, setModal] = useState<ContentModalProps>({
      show: false,
      size: "lg",
      customClass: "fllowing-control-modal",
      title: "選擇要接至的題目",
      handleClose: () => {
        setModal((prev) => ({ ...prev, show: false }));
      },
    });
    const [handleGroupsList, setHandleGroupsList] =
      useState<ControlModel[][]>();
    const [handleSelectedControl, setHandleSelectedControl] =
      useState<ControlModel>();
    const [handleSelectedOption, setHandleSelectedOption] =
      useState<ControlOptionModel>();
    //- handleSelectedOptionRef
    const handleSelectedOptionRef = useRef(handleSelectedOption);
    //- newSetControlId
    const newSetControlId: string = "00000000-0000-0000-0000-000000000000";
    //* ---------------------------- state end ---------------------------- *//
    //* ---------------------------- api start ---------------------------- *//
    //* ---------------------------- api end ---------------------------- *//
    //* ---------------------------- function start ---------------------------- *//
    const openModal = (
      handleControls: ControlModel[],
      selectedControl: ControlModel,
      selectedOption: ControlOptionModel
    ) => {
      setHandleGroupsList(
        sectionAndControlGroupsList(CommonService.deepClone(handleControls))
      );
      setHandleSelectedControl(CommonService.deepClone(selectedControl));
      setHandleSelectedOption(CommonService.deepClone(selectedOption));
      setModal((prev) => ({ ...prev, show: true }));
    };

    const closeModal = () => {
      setModal((prev) => ({ ...prev, show: false }));
    };

    //- sectionAndControlGroupsList
    const sectionAndControlGroupsList = (
      data: ControlModel[]
    ): ControlModel[][] => {
      const sectionAndControlGroups: ControlModel[][] = [];
      let tempControls: ControlModel[] = [];
      for (const control of data) {
        if (control.ctrType === "section") {
          if (tempControls.length) {
            sectionAndControlGroups.push(tempControls);
            tempControls = [];
          }
        }
        tempControls.push(control);
      }
      if (tempControls.length) {
        sectionAndControlGroups.push(tempControls);
      }
      return sectionAndControlGroups;
    };

    //- handleControlDisabled
    const handleControlDisabled = (control: ControlModel): boolean => {
      let disabled = false;
      if (control.sortIndex <= handleSelectedControl!?.sortIndex) {
        disabled = true;
      }
      //> 判斷其他control 的 options.followingControlIds 是否有包含這個control.id
      if (!disabled) {
        for (const tempControl of handleGroupsList!.flat()) {
          if (
            tempControl.options?.length &&
            tempControl.id !== handleSelectedControl!.id
          ) {
            for (const option of tempControl.options) {
              if (option.followingControlIds?.includes(control.id)) {
                disabled = true;
                break;
              }
            }
          }
        }
      }
      //> 如果是section 然後底下的control 有一個被其他control 的 options.followingControlIds 包含，就disabled
      if (!disabled && control.ctrType === "section") {
        handleGroupsList!.forEach((group) => {
          if (group[0].id !== control.id) return;
          for (const tempControl of group) {
            if (tempControl.ctrType === "section") continue;
            for (const tempFlatControl of handleGroupsList!.flat()) {
              if (
                tempFlatControl.ctrType !== "section" &&
                tempFlatControl.options?.length &&
                tempFlatControl.id !== handleSelectedControl!.id
              ) {
                const isFollowingControl = tempFlatControl.options.some(
                  (option) =>
                    option.followingControlIds?.includes(tempControl.id)
                );
                if (isFollowingControl) {
                  disabled = true;
                  break;
                }
              }
            }
          }
        });
      }

      return disabled;
    };

    //- handleControlToFollowingSetting
    const handleControlToFollowingSetting = (
      group: ControlModel[],
      control: ControlModel
    ) => {
      if (handleControlDisabled(control)) return;
      const tempOption: ControlOptionModel = CommonService.deepClone(
        handleSelectedOptionRef.current!
      );
      if (!tempOption.followingControlIds) tempOption!.followingControlIds = [];
      let detectIsChecked = false;
      if (tempOption.followingControlIds.includes(control.id)) {
        tempOption.followingControlIds = tempOption.followingControlIds.filter(
          (id) => id !== control.id
        );
      } else {
        tempOption.followingControlIds.push(control.id);
        detectIsChecked = true;
      }
      if (control.ctrType === "section") {
        if (detectIsChecked) {
          for (const item of group) {
            if (item.ctrType === "section") continue;
            if (
              !tempOption.followingControlIds.includes(item.id) &&
              !handleControlDisabled(item)
            ) {
              tempOption.followingControlIds.push(item.id);
            }
          }
        } else {
          for (const item of group) {
            if (item.ctrType === "section" || handleControlDisabled(item))
              continue;
            tempOption.followingControlIds =
              tempOption.followingControlIds.filter((id) => id !== item.id);
          }
        }
      } else if (!handleControlDisabled(group[0]) && !group[0].isBlankSection) {
        const groupActiveControlsId = group
          .filter((e) => e.ctrType !== "section")
          .filter((e) => !handleControlDisabled(e))
          .map((e) => e.id);
        if (detectIsChecked) {
          if (
            !tempOption.followingControlIds.includes(group[0].id) &&
            groupActiveControlsId.every((id) =>
              tempOption.followingControlIds!.includes(id)
            )
          ) {
            tempOption.followingControlIds.push(group[0].id);
          }
        } else {
          if (
            tempOption.followingControlIds.includes(group[0].id) &&
            !groupActiveControlsId.every((id) =>
              tempOption.followingControlIds!.includes(id)
            )
          ) {
            tempOption.followingControlIds =
              tempOption.followingControlIds.filter((id) => id !== group[0].id);
          }
        }
      }
      setHandleSelectedOption(tempOption);
    };

    //- cancelSetting
    const cancelSetting = () => {
      const newControls = CommonService.deepClone(handleGroupsList!).flat();
      //- 把 newControls 裡面 handleSelectedOption.followingControlIds 的 control.id 移除
      const findControl = newControls.find(
        (e) => e.id === handleSelectedControl?.id
      );
      if (!findControl) return;
      findControl.options = findControl.options?.map((option) => {
        if (option.id === handleSelectedOption?.id) {
          option.followingControlIds = [];
        }
        return option;
      });
      props.cancelFollowingControlSetting(newControls);
    };

    //- saveSetting
    const saveSetting = () => {
      const newControls = CommonService.deepClone(handleGroupsList!).flat();
      //- 把 newControls 裡面 handleSelectedOption.followingControlIds 的 control.id 移除
      const findControl = newControls.find(
        (e) => e.id === handleSelectedControl?.id
      );
      if (!findControl) return;
      findControl.options = findControl.options?.map((option) => {
        if (option.id === handleSelectedOption?.id) {
          option.followingControlIds =
            handleSelectedOption?.followingControlIds;
        }
        return option;
      });
      props.saveFollowingControlSetting(newControls);
    };
    //* ---------------------------- function end ---------------------------- *//

    //* ---------------------------- hook start ---------------------------- *//
    //- useImperativeHandle
    useImperativeHandle(
      ref,
      (): FollowingControlSettingModalRefs => ({
        openModal: openModal,
        closeModal: closeModal,
      })
    );
    //- useEffect
    useEffect(() => {
      handleSelectedOptionRef.current = handleSelectedOption;
    }, [modal, handleGroupsList, handleSelectedControl, handleSelectedOption]);
    //* ---------------------------- hook end ---------------------------- *//

    return (
      <ContentModal {...modal}>
        <div className={`${styles["fllowing-control-modal-content"]}`}>
          <div className={`content-box scroll`}>
            {handleGroupsList?.map((group, groupIndex) => {
              return (
                <div key={groupIndex} className={`group-box`}>
                  {group?.map((control, controlIndex) => {
                    if (
                      (control.ctrType === "section" &&
                        control.isBlankSection) ||
                      control.id === newSetControlId
                    )
                      return null;
                    return (
                      <div
                        key={controlIndex}
                        className={`item-box ${
                          control.ctrType === "section" ? "section" : "control"
                        } ${handleControlDisabled(control) ? "disabled" : ""} ${
                          group[0].isBlankSection ? "blank-section" : ""
                        }`}
                      >
                        <div
                          className="setting-checkbox"
                          onClick={() => {
                            handleControlToFollowingSetting(group, control);
                          }}
                        >
                          <label className={`custom-checkbox`}>
                            <input
                              type="checkbox"
                              disabled={handleControlDisabled(control)}
                              checked={
                                handleSelectedOption?.followingControlIds?.includes(
                                  control.id
                                ) || false
                              }
                              onChange={(e) => {}}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                            <span>{control.name || "untitled"}</span>
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className={`footer-box`}>
            <div className="left">
              <button
                className={`secondary`}
                onClick={() => {
                  closeModal();
                }}
              >
                取消
              </button>
            </div>
            <div className="right">
              <button className={`secondary`} onClick={() => cancelSetting()}>
                取消接題
              </button>
              <button className={`default`} onClick={() => saveSetting()}>
                儲存接題
              </button>
            </div>
          </div>
        </div>
      </ContentModal>
    );
  }
);

export default FollowingControlSettingModal;
