//-------------------- interface --------------------
export interface BaseResponse<T> {
  count?: number;
  code: number;
  success: boolean;
  message: string;
  data: T;
}

/** 簡單option model */
export interface SimpleOptionModel {
  id: string;
  text: string | null;
}

/** 基礎option model */
export interface OptionModel {
  id: string;
  text: string;
  /** 選單代碼 */
  code?: string | null;
  /** 列舉key */
  enumKey?: number | null;
}

/** 多選OPTION */
export interface MultiOptionModel extends OptionModel {
  /** 是否已選擇 */
  checked?: boolean;
}

/** 背景執行model */
export interface BackgroundResponseModal {
  /* 模組名稱 */
  type: string;
  /* 專案名稱 */
  projectName: string;
  /* 專案Id */
  projectId: string;
  /* 執行名稱 */
  action: string;
  /* 執行狀態（目前有: success, fail, Processing） */
  status: string;
  /* 回傳Url */
  returnUrl: string;
  /* 顯示訊息 */
  message: string;
  /* signalr job id */
  jobId: string;
  /* 判斷是否為長時程 boolean */
  isLongTermJob: boolean;
}

/** sidebar item model */
export interface SideBarItemModel {
  moduleName: string;
  url: string;
  icon: string;
  /** 切割線 */
  divideLine?: boolean;
  /** 揭露文字 */
  revealText?: string;
  /** 排版(row or column) */
  composition?: string;
  isActive?: boolean;
}

/** Nav item model */
export interface NavBarItemModel {
  name: string;
  url: string;
  key?: string;
  disabled?: boolean;
}

/** CategoryNavBarListModel */
export class CategoryNavBarListModel {
  category: string = '';
  name: string = '';
  id: string = '  ';
  list: NavBarItemModel[] = [];
  isNav?: boolean = false;
  customClassName?: string = '';
  url?: string = '';
  disabled?: boolean = false;
}

export interface TableRequestModel extends ListRequestModel {}

/** 所有傳入參數都要繼承此模組，利用額外參數欄位來判斷正確性 */
export interface BaseRequestModel {}

export interface ListRequestModel {
  page: number;
  pageSize: number;
  searchKey?: string | null;
  sortKey: string | null;
  sortType?: boolean | null;
}

/** 分頁model */
export interface PaginationModel {
  page: number | any;
  pageSize: number;
  total?: number;
}

//-------------------- enum --------------------
/** 市場別 */
export enum MarketTypeEnum {
  /** 上市 */
  Listing,
  /** 上櫃 */
  OTC,
  /** 公發 */
  PO,
  /** 興櫃 */
  Emerging,
}

/** 模組年度請求 */
export enum RequestType {
  /** 永續報告書 */
  SustainReport,
  /** 溫室氣體排放及減量 */
  GhgEmissionAndReduction,
  /** ESG資訊揭露 */
  ESGDisclosure,
}

//-------------------- const --------------------
/** 頁面基本操作*/
export const PageActionTyep = {
  View: 'view',
  Add: 'add',
  Edit: 'edit',
};

/** stream檔案類型*/
export const StreamFileType = {
  Xlsx: 'application/xlsx',
  Pdf: 'application/pdf',
  Docx: 'application/docx',
};

/** 空白頁面訊息 */
export const EmptyMessageType = {
  /** 登出 */
  Logout: 'logout',
  /** 逾時登出 */
  Timeout: 'timeout',
  /** 尚未登入 */
  NotLogin: 'notLogin',
};
