//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect } from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { Doughnut } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  DoughnutController,
  Tooltip,
  Legend,
  ChartData,
  Title,
  ChartOptions,
  Plugin,
  LegendElement,
  LegendItem,
  ChartEvent,
  ChartType,
} from "chart.js";
import { useMutation } from "@tanstack/react-query";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./Home.module.scss";
import Dropdown from "components/base/Dropdown/Dropdown";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { OptionModel } from "models/baseModel";
import HomeService from "services/admin/homeService";
import {
  DashboardModel,
  DashboardDeclareModel,
  DashboardGuaranteedConfidenceChartModel,
  DashboardGuaranteedConfidenceTableModel,
  WebUseGuaranteedConfidenceTableModel,
  QueryDashboardDataRequestModel,
  MarketTypeModel,
} from "models/admin/homeModel";
import { error2Alert } from "utils/otherToast";
//* ---------------------------- local end ---------------------------- *//
Chart.register(ArcElement, DoughnutController, Tooltip, Legend, Title);
// 擴展 Chart 類型來添加 `_legendOnClickInitialized` 屬性
interface ExtendedChart extends Chart {
  _legendOnClickInitialized?: boolean;
}
declare module "chart.js" {
  interface PluginOptionsByType<TType extends ChartType> {
    emptyDoughnut?: {
      color: string;
      width: number;
      radiusDecrease: number;
    };
  }
}

const Home: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const [isPageInit, setIsPageInit] = useState<boolean>(false);
  const [declareYearOptions] = useState<OptionModel[]>(() => {
    const declareYearOptionsList = [];
    const today = new Date();
    for (let i = today.getFullYear(); i >= 2021; i--) {
      declareYearOptionsList.push({
        id: i.toString(),
        text: `${i.toString()} 年度`,
        enumKey: i,
      });
    }
    // console.log("declareYearOptionsList", declareYearOptionsList);
    return [...declareYearOptionsList];
  });
  const [marketTypesList, setMarketTypesList] = useState<OptionModel[]>([]);
  const [selectMarketType, setSelectMarketType] = useState<string>();
  const [queryDashboardDataRequest, setQueryDashboardDataRequest] =
    useState<QueryDashboardDataRequestModel>(() => {
      return {
        year: declareYearOptions[1].enumKey!,
        marketTypes: [],
      };
    });
  const [dashboardData, setDashboardData] = useState<DashboardModel>();
  const [webUseGuaranteedConfidenceTable, setWebUseGuaranteedConfidenceTable] =
    useState<WebUseGuaranteedConfidenceTableModel[] | undefined>();
  const colorList: { key: string; color: string }[] = [
    { key: "未申報", color: "#C3D9E3" },
    { key: "申報中", color: "#74C1E5" },
    { key: "已申報", color: "#7BD1B5" },
    { key: "申請更正", color: "#F8C541" },
    { key: "更正中", color: "#BE93F4" },
    { key: "申報結束", color: "#7398F6" },
  ];
  const sustainReportGuaranteedConfidenceColorList: {
    key: string;
    color: string;
  }[] = [
    { key: "通過保證", color: "#74C1E5" },
    { key: "通過確信", color: "#7BD1B5" },
    { key: "通過保證與確信", color: "#7398F6" },
    { key: "未通過", color: "#F8C541" },
  ];
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getDashboardData
  const {
    mutate: getDashboardDataMutate,
    isPending: getDashboardDataIsPending,
  } = useMutation({
    mutationFn: (query: QueryDashboardDataRequestModel) =>
      HomeService.getDashboardData(query),
    onSuccess: (res) => {
      console.log("getDashboardData", res);
      if (res.code === 200 && res.success) {
        setDashboardData(res.data);

        const originSustainReportGuaranteedConfidenceTable =
          res.data.sustainReportGuaranteedConfidence?.tableModel;
        if (originSustainReportGuaranteedConfidenceTable?.length) {
          const tempData = originSustainReportGuaranteedConfidenceTable
            .map((item: DashboardGuaranteedConfidenceTableModel) => {
              const separateRowTitle = item.rowTitle
                .split("-")
                .map((d) => d.replace(/[\u200B-\u200D\uFEFF]/g, "").trim());
              return { ...item, ...{ separateRowTitle: separateRowTitle } };
            })
            .sort(
              (
                a: WebUseGuaranteedConfidenceTableModel,
                b: WebUseGuaranteedConfidenceTableModel
              ) => {
                if (a.separateRowTitle[0] < b.separateRowTitle[0]) return -1;
                if (a.separateRowTitle[0] > b.separateRowTitle[0]) return 1;
                return b.separateRowTitle.length - a.separateRowTitle.length;
              }
            );
          // console.log(
          // 	Math.max(...tempData!.map((item: WebUseGuaranteedConfidenceTableModel) => item.separateRowTitle.length))
          // );
          // console.log('setWebUseGuaranteedConfidenceTable', tempData);
          setWebUseGuaranteedConfidenceTable(tempData);
        }
      } else {
        setDashboardData(undefined);
        setWebUseGuaranteedConfidenceTable(undefined);
      }
    },
    onError: (err) => {
      console.log("getDashboardData", err);
      setDashboardData(undefined);
      setWebUseGuaranteedConfidenceTable(undefined);
      // error2Alert("執行失敗");
    },
  });
  //- getMarketTypes
  const { mutate: getMarketTypesMutate, isPending: getMarketTypesIsPending } =
    useMutation({
      mutationFn: () => HomeService.getMarketTypes(),
      onSuccess: (res) => {
        // console.log('getMarketTypes', res);
        if (res.code === 200 && res.success) {
          // setMarketTypesList(res.data);
          const allOption: OptionModel = {
            id: "all",
            text: "全部",
          };
          setMarketTypesList([
            ...[allOption],
            ...res.data.map((d: MarketTypeModel) => {
              return { id: d.marketType.toString(), text: d.marketTypeName };
            }),
          ]);
          setSelectMarketType(allOption.id);
          setQueryDashboardDataRequest(
            (prev) =>
              ({
                ...prev,
                marketTypes: res.data.map((r: MarketTypeModel) => r.marketType),
              } as QueryDashboardDataRequestModel)
          );
          setIsPageInit(true);
        } else {
          setMarketTypesList([]);
          setSelectMarketType(undefined);
        }
      },
      onError: (err) => {
        console.log("getMarketTypes", err);
        setMarketTypesList([]);
        setSelectMarketType(undefined);
        error2Alert("取得市場類別清單失敗");
      },
    });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  const declarePieChartJsData = (
    type: string,
    data: DashboardDeclareModel[] | DashboardGuaranteedConfidenceChartModel[]
  ): ChartData<"doughnut"> => {
    let seriesData: { value: number; label: string }[] = [];

    const mapData = (
      d: DashboardDeclareModel | DashboardGuaranteedConfidenceChartModel
    ) => {
      const label =
        "declareStatusText" in d
          ? d.declareStatusText
          : d.guaranteedConfidenceStatusText;
      return {
        value: d.amount,
        label,
      };
    };

    seriesData = data.map(mapData);

    const colors =
      type === "sustainReportGuaranteedConfidence"
        ? sustainReportGuaranteedConfidenceColorList
        : colorList;
    return {
      labels: seriesData.map((d) => d.label),
      datasets: [
        {
          label: type,
          data: seriesData.map((d) => d.value),
          backgroundColor: seriesData.map(
            (d) => colors.find((c) => c.key === d.label)?.color || "#C3D9E3"
          ),
          borderWidth: 0,
        },
      ],
    };
  };

  const declarePieChartJsOptions = (
    type: string,
    data: DashboardDeclareModel[] | DashboardGuaranteedConfidenceChartModel[]
  ): ChartOptions<"doughnut"> => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      radius: "85%",
      cutout: "60%",
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
        },
        emptyDoughnut: {
          color: "#C3D9E3",
          width: 55,
          radiusDecrease: 50,
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const dataset = tooltipItem.dataset;
              const dataIndex = tooltipItem.dataIndex;
              const value = dataset.data[dataIndex] as number;
              const label = tooltipItem.label as string;

              let correspondingData;
              switch (type) {
                case "esgDeclareModel":
                  correspondingData = (data as DashboardDeclareModel[]).find(
                    (d: DashboardDeclareModel) => d.declareStatusText === label
                  );
                  break;
                case "sustainReportDeclareTWModel":
                  correspondingData = (data as DashboardDeclareModel[]).find(
                    (d: DashboardDeclareModel) => d.declareStatusText === label
                  );
                  break;
                case "ghgDeclareModel":
                  correspondingData = (data as DashboardDeclareModel[]).find(
                    (d: DashboardDeclareModel) => d.declareStatusText === label
                  );
                  break;
                case "sustainReportGuaranteedConfidence":
                  correspondingData = (
                    data as DashboardGuaranteedConfidenceChartModel[]
                  ).find(
                    (d: DashboardGuaranteedConfidenceChartModel) =>
                      d.guaranteedConfidenceStatusText === label
                  );
                  break;
              }
              const percentage = correspondingData?.percentage || 0;
              return `${value}(${percentage}%)`;
            },
          },
        },
      },
    };
  };

  const declarePieChartJsPlugins = (
    type: string,
    data: DashboardDeclareModel[] | DashboardGuaranteedConfidenceChartModel[]
  ): Plugin<"doughnut">[] => {
    let title = "";

    switch (type) {
      case "esgDeclareModel":
        title = "ESG指標\n申報進度";
        break;
      case "sustainReportDeclareTWModel":
        title = "永續報告書\n申報進度";
        break;
      case "ghgDeclareModel":
        title = "溫室氣體排放\n及減量資訊\n申報作業進度";
        break;
      case "sustainReportGuaranteedConfidence":
        title = "保證確信";
        break;
    }

    return [
      {
        id: "title",
        beforeDraw: (chart, args, options) => {
          const {
            ctx,
            chartArea: { top, bottom, left, right },
          } = chart;

          const centerX = (left + right) / 2;
          let centerY = (top + bottom) / 2;

          const text = title || "";
          const textArray = text.split("\n");
          const lineHeight = 18;

          ctx.save();
          ctx.font = "18px sans-serif";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = "#000";

          centerY -= ((textArray.length - 1) * lineHeight) / 2;

          textArray.forEach((line, index) => {
            ctx.fillText(line, centerX, centerY + index * lineHeight);
          });

          ctx.restore();
        },
      },
      {
        id: "doughnutLabelsLine",
        afterDraw: (chart, args, options) => {
          const {
            ctx,
            chartArea: { top, bottom, left, right, width, height },
          } = chart;

          const datasets = chart.data.datasets || [];

          const centerX = (left + right) / 2;
          const labelPositions: {
            left: number[];
            right: number[];
          } = {
            left: [],
            right: [],
          };

          const legend = chart?.legend as LegendElement<"doughnut"> | undefined;
          const legendItems = legend?.legendItems || [];

          datasets.forEach((dataset, i) => {
            // console.log(chart.getDatasetMeta(i));
            if (chart.getDatasetMeta(i).hidden) return;
            const backgroundColors = Array.isArray(dataset.backgroundColor)
              ? dataset.backgroundColor
              : [];

            chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
              if (!dataset.data[index] || legendItems[index]?.hidden) return;
              const { x, y } = datapoint.tooltipPosition(true);
              const isRightSide = x >= centerX;

              let xLineEnd =
                x +
                (isRightSide ? 1 : -1) *
                  (30 + (width / 2 - Math.abs(x - centerX)) / 6);
              let yLine = y;

              let initialOffset = 10; // 起始偏移量
              let maxAttempts = 20; // 最大嘗試次數，避免無限迴圈

              for (let j = 0; j < maxAttempts; j++) {
                let direction = j % 2 === 0 ? -1 : 1; // 交替方向，先上後下
                let attemptOffset = initialOffset * Math.floor((j + 1) / 2);

                let newY = yLine + direction * attemptOffset;
                if (newY < top + 10 || newY > bottom - 10) {
                  continue; // 如果超出範圍，則跳過這次調整
                }

                let isOverlap = labelPositions[
                  isRightSide ? "right" : "left"
                ].some((pos) => Math.abs(newY - pos) < 20);
                if (!isOverlap) {
                  yLine = newY; // 更新位置
                  break;
                }
              }

              labelPositions[isRightSide ? "right" : "left"].push(yLine);

              const xExtraLineEnd = xLineEnd + (isRightSide ? 20 : -20);

              // 繪製線條和標籤
              ctx.beginPath();
              ctx.moveTo(x, y);
              ctx.lineTo(xLineEnd, yLine);
              ctx.lineTo(xExtraLineEnd, yLine);
              ctx.lineWidth = 1;
              ctx.strokeStyle = backgroundColors[index] || "#000";
              ctx.stroke();

              // 標籤文字
              ctx.font = "13px sans-serif";
              ctx.fillStyle = "#000";
              ctx.textAlign = isRightSide ? "left" : "right";
              ctx.textBaseline = "middle";
              const value = dataset.data[index];
              const textOffset = isRightSide ? 5 : -5;
              const textX = xExtraLineEnd + textOffset;

              const labels = chart.data.labels as string[];
              const label = labels[index];
              let correspondingData;

              switch (type) {
                case "esgDeclareModel":
                  correspondingData = dashboardData?.esgDeclareModel.find(
                    (d) => d.declareStatusText === label
                  )?.percentage;
                  break;
                case "sustainReportDeclareTWModel":
                  correspondingData =
                    dashboardData?.sustainReportDeclareTWModel.find(
                      (d) => d.declareStatusText === label
                    )?.percentage;
                  break;
                case "ghgDeclareModel":
                  correspondingData = dashboardData?.ghgDeclareModel.find(
                    (d) => d.declareStatusText === label
                  )?.percentage;
                  break;
                case "sustainReportGuaranteedConfidence":
                  correspondingData =
                    dashboardData?.sustainReportGuaranteedConfidence.chartModel.find(
                      (d) => d.guaranteedConfidenceStatusText === label
                    )?.percentage;
                  break;
              }
              const percentage = correspondingData || 0;

              // 確保文字不會被邊緣遮蓋
              const textWidth = ctx.measureText(
                `${value}(${percentage}%)`
              ).width;
              const finalTextX = isRightSide
                ? Math.min(textX, right - textWidth - 5) // 保持文字在右邊界內
                : Math.max(textX, left + textWidth + 5); // 保持文字在左邊界內

              ctx.fillText(`${value}(${percentage}%)`, finalTextX, yLine);
            });
          });
        },
      },
      {
        id: "emptyDoughnut",
        beforeDraw(chart, args, options) {
          const { color, width, radiusDecrease } = options;

          const {
            chartArea: { left, top, right, bottom },
            ctx,
          } = chart;
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const r = Math.min(right - left, bottom - top) / 2;

          ctx.beginPath();
          ctx.lineWidth = width || 2;
          ctx.strokeStyle = color || "rgba(255, 128, 0, 0.5)";
          ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
          ctx.stroke();
        },
      },
      {
        id: "legendOnClick",
        afterUpdate: (
          chart: Chart<"doughnut", number[], unknown>,
          args: {
            mode:
              | "reset"
              | "resize"
              | "none"
              | "hide"
              | "show"
              | "default"
              | "active";
          },
          options: any
        ) => {
          const extendedChart = chart as ExtendedChart;
          const legend = chart.legend as LegendElement<"doughnut"> | undefined;
          if (!legend) return;

          const legendItems = legend.legendItems;
          if (!legendItems?.length) return;

          if (!extendedChart._legendOnClickInitialized) {
            const originalOnClick =
              legend.options.onClick || Chart.defaults.plugins.legend.onClick;

            legend.options.onClick = (
              e: ChartEvent,
              legendItem: LegendItem,
              legend: LegendElement<"doughnut">
            ) => {
              legendItems[legendItem.index!].hidden =
                !legendItems[legendItem.index!].hidden;
              originalOnClick.call(legend, e, legendItem, legend);
              extendedChart.update();
            };


            extendedChart._legendOnClickInitialized = true;
          }
        },
      },
    ];
  };
  const calculateRowSpan = (
    data: WebUseGuaranteedConfidenceTableModel[],
    rowIndex: number,
    colIndex: number
  ) => {
    let rowspan = 1;
    while (
      rowIndex + rowspan < data.length &&
      data[rowIndex].separateRowTitle[colIndex] ===
        data[rowIndex + rowspan].separateRowTitle[colIndex]
    ) {
      rowspan++;
    }
    return rowspan;
  };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init
  useEffect(() => {
    // getDashboardDataMutate(selectDeclareYear.toString());
    getMarketTypesMutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //- queryDashboardDataRequest
  useEffect(() => {
    if (isPageInit && queryDashboardDataRequest) {
      getDashboardDataMutate(queryDashboardDataRequest);
    }
  }, [queryDashboardDataRequest, isPageInit, getDashboardDataMutate]);
  //* ---------------------------- hook end ---------------------------- *//

  return (
    <div className={`${styles["home-box"]}`}>
      <div className={`${styles["operation-header"]}`}>
        <div className={`left`}>
          <div className={`select-item`}>
            <Dropdown
              placeholder={"年度"}
              defaultId={queryDashboardDataRequest.year?.toString() || ""}
              options={declareYearOptions}
              isFloatTitle={false}
              onChange={(e) => {
                setQueryDashboardDataRequest((prev) => ({
                  ...prev,
                  year: declareYearOptions.find((o) => o.id === e)?.enumKey!,
                }));
              }}
            />
          </div>
          <div className={`select-item`}>
            <Dropdown
              placeholder={"市場類別"}
              defaultId={selectMarketType || ""}
              options={marketTypesList}
              isFloatTitle={false}
              onChange={(e) => {
                setSelectMarketType(e!);
                setQueryDashboardDataRequest((prev) => ({
                  ...prev,
                  marketTypes:
                    e === "all"
                      ? marketTypesList
                          .filter((r) => r.id !== "all")
                          .map((r) => parseInt(r.id!))
                      : [parseInt(e!)],
                }));
              }}
            />
          </div>
        </div>
        <div className={`right`}></div>
      </div>
      <div className={`${styles["home-content-box"]} scroll`}>
        <ScrollToTopButton
          targetClassName={`${styles["home-content-box"]}`}
          bottom={90}
          right={35}
        />
        {getDashboardDataIsPending || getMarketTypesIsPending ? (
          <VisuallLoading />
        ) : dashboardData ? (
          <>
            <div className={`${styles["leftBox"]}`}>
              <div className={`${styles["chart-box"]}`}>
                <div style={{ height: "350px", width: "100%" }}>
                  <Doughnut
                    data={declarePieChartJsData(
                      "esgDeclareModel",
                      dashboardData.esgDeclareModel
                    )}
                    options={declarePieChartJsOptions(
                      "esgDeclareModel",
                      dashboardData.esgDeclareModel
                    )}
                    plugins={declarePieChartJsPlugins(
                      "esgDeclareModel",
                      dashboardData.esgDeclareModel
                    )}
                  />
                </div>
              </div>
              <div className={`${styles["chart-box"]} mt-3`}>
                <div style={{ height: "350px", width: "100%" }}>
                  <Doughnut
                    data={declarePieChartJsData(
                      "sustainReportGuaranteedConfidence",
                      dashboardData.sustainReportGuaranteedConfidence.chartModel
                    )}
                    options={declarePieChartJsOptions(
                      "sustainReportGuaranteedConfidence",
                      dashboardData.sustainReportGuaranteedConfidence.chartModel
                    )}
                    plugins={declarePieChartJsPlugins(
                      "sustainReportGuaranteedConfidence",
                      dashboardData.sustainReportGuaranteedConfidence.chartModel
                    )}
                  />
                </div>
              </div>
              <div className={`${styles["data-change-list"]}`}>
                <div className={`${styles["title"]}`}>資料異動申請：</div>
                <div className={`${styles["list"]} scroll`}>
                  {dashboardData.correctionRequestList.length ? (
                    <>
                      {dashboardData.correctionRequestList.map((d, i) => (
                        <div key={i} className={`${styles["item"]}`}>
                          {i + 1} {d}
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className={`${styles["no-data"]}`}>沒有資料</div>
                  )}
                </div>
              </div>
            </div>
            <div className={`${styles["rightBox"]}`}>
              <div className={`${styles["col"]}`}>
                <div className={`${styles["chart-box"]}`}>
                  <div style={{ height: "350px", width: "100%" }}>
                    <Doughnut
                      data={declarePieChartJsData(
                        "sustainReportDeclareTWModel",
                        dashboardData.sustainReportDeclareTWModel
                      )}
                      options={declarePieChartJsOptions(
                        "sustainReportDeclareTWModel",
                        dashboardData.sustainReportDeclareTWModel
                      )}
                      plugins={declarePieChartJsPlugins(
                        "sustainReportDeclareTWModel",
                        dashboardData.sustainReportDeclareTWModel
                      )}
                    />
                  </div>
                </div>
                <div className={`${styles["chart-box"]}`}>
                  <div style={{ height: "350px", width: "100%" }}>
                    <Doughnut
                      data={declarePieChartJsData(
                        "ghgDeclareModel",
                        dashboardData.ghgDeclareModel
                      )}
                      options={declarePieChartJsOptions(
                        "ghgDeclareModel",
                        dashboardData.ghgDeclareModel
                      )}
                      plugins={declarePieChartJsPlugins(
                        "ghgDeclareModel",
                        dashboardData.ghgDeclareModel
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className={`${styles["table-box"]}`}>
                <table
                  aria-label="table"
                  className={`${styles["home-table"]} table-container sticky-table`}
                >
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        colSpan={
                          Math.max(
                            ...webUseGuaranteedConfidenceTable!.map(
                              (item) => item.separateRowTitle.length
                            )
                          ) || 1
                        }
                      >
                        驗證單位及標準​
                      </th>
                      <th scope="col">家數</th>
                      <th scope="col">比例​</th>
                    </tr>
                  </thead>
                  <tbody>
                    {webUseGuaranteedConfidenceTable?.length === 0 ? (
                      <tr>
                        <td colSpan={3}>查無資料</td>
                      </tr>
                    ) : (
                      webUseGuaranteedConfidenceTable!.map(
                        (
                          row: WebUseGuaranteedConfidenceTableModel,
                          rowIndex
                        ) => (
                          <tr key={rowIndex}>
                            {row.separateRowTitle.map((cell, colIndex) => {
                              const rowspan = calculateRowSpan(
                                webUseGuaranteedConfidenceTable!,
                                rowIndex,
                                colIndex
                              );
                              const colspanMax = Math.max(
                                ...webUseGuaranteedConfidenceTable!.map(
                                  (item) => item.separateRowTitle.length
                                )
                              );
                              const isLastCol =
                                colIndex === row.separateRowTitle.length - 1;
                              const isLastColSpan =
                                isLastCol && colIndex !== colspanMax - 1
                                  ? colspanMax - colIndex
                                  : 1;
                              if (
                                rowIndex === 0 ||
                                webUseGuaranteedConfidenceTable![rowIndex - 1]
                                  .separateRowTitle[colIndex] !== cell
                              ) {
                                return (
                                  <td
                                    key={colIndex}
                                    rowSpan={rowspan}
                                    colSpan={isLastColSpan}
                                  >
                                    {cell}
                                  </td>
                                );
                              }
                              return null;
                            })}
                            <td>{row.amount}</td>
                            <td>{row.percentage}%</td>
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <div className={`${styles["no-data"]}`}>查無資料</div>
        )}
      </div>
    </div>
  );
};

export default Home;
