//* ---------------------------- React start ---------------------------- *//
import { FC, useState, useEffect, useRef } from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./Detail.module.scss";
import BackTitle from "components/base/BackTitle/BackTitle";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import IndicatorDeclareService from "services/application/indicatorDeclare/indicatorDeclareService";
import {
  DeclareStatusEnum,
  EsgDisclosureDataModel,
  EsgDisclosureDataRequestModel,
  RequestTypeEnum,
  DeclareSettingDataRequestModel,
} from "models/admin/declarationModel";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import SearchInput from "components/base/SearchInput/SearchInput";
import {
  CtrType,
  DelareItemTreeDetailModel,
  DelareItemTreeModel,
} from "models/admin/indicatorModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { CompanySampleDataFormModel } from "models/samples/companySampleDataModel/companySampleDataModel";
import { CommonService } from "services/common/commonService";
import {
  CompanySampleControlValModel,
  ControlValSectionDetial,
  CustomerSampleDataControlValSectionModel,
} from "models/samples/companySampleDataModel/companySampleControlValModel";
import useUserInfoStore from "state/useUserInfoStore";
import { ActionsPermissionCodes } from "models/auth/permissionModel";
import EmptyWaitPage from "components/common/EmptyWaitPage/EmptyWaitPage";
import {
  DeclareDataAction,
  DeclareStatus,
  EsgDeclareInfoModel,
} from "models/application/indicators/indicatorModel";
import { PrintService } from "services/common/printService";
import { PrintElementType } from "models/common/printElementModel";
import { SampleControlCode } from "models/samples/sampleControlCode";
//* ---------------------------- local end ---------------------------- *//

// 樹狀
interface TreeItem {
  item: DelareItemTreeDetailModel;
}

const Detail: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const params = useParams();
  const id: string = params?.id!;
  const location = useLocation();
  const esgDisclosure_SelectedItemData: EsgDisclosureDataModel =
    location?.state?.esgDisclosure_SelectedItemData;
  const navigate = useNavigate();
  const [realTree, setRealTree] = useState<DelareItemTreeModel[]>([]);
  const [backupRealTree, setBackupRealTree] = useState<DelareItemTreeModel[]>(
    []
  );
  const [selTreeItem, setSelTreeItem] = useState<DelareItemTreeDetailModel>();
  const [detailInfo, setDetailInfo] = useState<
    CustomerSampleDataControlValSectionModel[]
  >([]);
  const infoAllData = useRef<CompanySampleDataFormModel>();
  const selFollowingControlIds = useRef<string[]>([]);
  const allFollowingControlIds = useRef<string[]>([]);
  const formRef = useRef(null);
  // esg 總覽
  const [isOverView, setIsOverView] = useState<boolean>(true);
  const [infoData, setInfoData] = useState<EsgDeclareInfoModel>();
  //* userInfo
  const { userInfo } = useUserInfoStore();
  //* permission
  const { permission } = userInfo;
  //* actionCodes
  const { actionCodes } = permission;
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getDeclareItemTree
  const {
    mutate: getDeclareItemTreeMutate,
    isPending: getDeclareItemTreeIsPending,
  } = useMutation({
    mutationFn: (request: { year: number; companyCode: string }) =>
      IndicatorDeclareService.getDeclareItemTree(request),
    onSuccess: (res) => {
      console.log("getDeclareItemTree", res);
      if (res.code === 200 && res.success) {
        // success2Alert(res.message);
        defaultTreeFlow(res.data);
      } else {
        // error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("getDeclareItemTree", err);
      // error2Alert("執行失敗");
    },
  });
  //- getDeclareItemDetail
  const {
    mutate: getDeclareItemDetailMutate,
    isPending: getDeclareItemDetailIsPending,
  } = useMutation({
    mutationFn: (request: {
      sampleDataId: string;
      sampleId: string;
      companyCode: string;
    }) => IndicatorDeclareService.getDeclareItemDetail(request),
    onSuccess: (res) => {
      console.log("getDeclareItemDetail", res);
      if (res.code === 200 && res.success) {
        const data: CompanySampleDataFormModel = res.data;
        // success2Alert(res.message);
        data.sectionDatas = initSection(data.sectionDatas, false);
        infoAllData.current = data;
        // infoAllData.current = initSection(res.data,);
        setDetailInfo(
          CommonService.deepClone(infoAllData.current.sectionDatas)
        );
      } else {
        // error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("getDeclareItemDetail", err);
      // error2Alert("執行失敗");
    },
  });
  //- getDeclareInfo
  const { mutate: getDeclareInfoMutate, isPending: getDeclareInfoIsPending } =
    useMutation({
      mutationFn: (id: string) => IndicatorDeclareService.getDeclareInfo(id),
      onSuccess: (res) => {
        console.log("getDeclareInfo", res);
        if (res.code === 200 && res.success) {
          setInfoData({
            ...infoData,
            ...res.data,
          });
        } else {
          // error2Alert(res.message);
          console.log(res.message);
        }
      },
      onError: (err) => {
        console.log("getDeclareInfo", err);
        // error2Alert("執行失敗");
      },
    });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  // 預設樹狀相關流程
  const defaultTreeFlow = (copyTree: DelareItemTreeModel[]) => {
    copyTree.forEach((p) => {
      p.items?.forEach((item) => {
        initSection(item.sectionDatas, true);
      });
    });
    // 複製一份給真實的tree
    setRealTree([...copyTree]);
    setBackupRealTree([...copyTree]);
    // 設置第一筆
    if (copyTree.length > 0) {
      let allItems: DelareItemTreeDetailModel[] = [];
      copyTree.forEach((p) => {
        allItems = allItems.concat(p.items!);
      });
      setIsOverView(false);
      if (allItems.length > 0) {
        let defaultItem = allItems[0];
        clickTreeItem(defaultItem);
      }
      console.log("selTreeItem", selTreeItem);
    }
  };
  const filterTree = (key: string) => {
    const filterItem: DelareItemTreeModel[] =
      CommonService.deepClone(backupRealTree);
    if (filterItem) {
      if (key) {
        filterItem.forEach((p) => {
          p.items =
            p.items?.filter((item) => item.declareItemName?.includes(key)) ||
            [];
        });
      }
      setRealTree([...filterItem]);
    }
  };
  // 點選item
  const clickTreeItem = (item: DelareItemTreeDetailModel) => {
    setSelTreeItem({ ...selTreeItem, ...item });
    console.log("clickTreeItem", item);
    getDeclareItemDetailMutate({
      sampleDataId: item.sampleDataId!,
      sampleId: item.sampleId!,
      companyCode: esgDisclosure_SelectedItemData.companyCode,
    });
  };
  // 樹狀資訊
  const TreeComponent = (item: DelareItemTreeModel[]) => {
    const images = ["global", "social", "governance"];
    return (
      <>
        {item?.map((category, index) => (
          <div key={category.category}>
            <div className={"tree-title"}>
              <img
                alt="搜尋"
                src={`/assets/images/declaration/application/${
                  images[category.category]
                }-icon.svg`}
              />
              <div>{category.categoryString}</div>
            </div>
            <ul>
              {category.items?.map((p, itenIndex: number) => {
                return <TreeItem key={p.sampleId} item={p} />;
              })}
            </ul>
          </div>
        ))}
      </>
    );
  };
  // 樹狀item
  const TreeItem = (props: TreeItem) => {
    const { item } = props;
    // 基礎樹狀
    let selImages = "blue";
    if (selTreeItem?.sampleId === item.sampleId) {
      selImages = "white";
    }
    // 都非必揭露
    if (item.isNotNecessary) {
      if (item.isWritten) {
        selImages = "blue-not-necessary";
      } else {
        selImages = "grey-not-necessary";
      }
      if (selTreeItem?.sampleId === item.sampleId) {
        selImages = "white-not-necessary";
      }
    }
    return (
      <li
        className={selTreeItem?.sampleId === item.sampleId ? "active" : ""}
        onClick={($event) => {
          setIsOverView(false);
          // // 編輯情境要跳出資訊
          // if (action === PageActionTyep.Edit && !firstEdit.current) {
          //   setDataConfirmModal({
          //     ...dataConfirmModal,
          //     show: true,
          //     handleConfirm() {
          //       clickTreeItem(item);
          //       setDataConfirmModal({
          //         ...dataConfirmModal,
          //         show: false,
          //       });
          //     },
          //   });
          // } else {
          clickTreeItem(item);
          // }
        }}
      >
        {item.isWritten || item.isNotNecessary ? (
          <img
            className="check-img"
            alt=""
            src={`/assets/images/declaration/application/${selImages}-check-icon.svg`}
          />
        ) : (
          <div style={{ width: "21.6px" }}></div>
        )}
        <div>{item.declareItemName}</div>
      </li>
    );
  };
  // 初始section 資料
  const initSection = (
    sectionDatas: CustomerSampleDataControlValSectionModel[],
    continueNum: boolean
  ): CustomerSampleDataControlValSectionModel[] => {
    // 是否有特殊radio卡控 (放在賦予控制項值之後)
    // let specialRadio = false;
    // 基礎資料
    sectionDatas.forEach((p: CustomerSampleDataControlValSectionModel) => {
      p.sections.forEach((section) => {
        section.hide = false;
        // 置放option
        section.controls?.forEach((control) => {
          // 特殊非必填
          control.isSpecialNotRequired = false;
          // 正數長度
          control.limitIntegerDigits = getPositiveLength(control);
          // 有效性
          control.valid = true;
          // 多選使用
          control.hideOptions = true;
          // 新增前端選單
          control.frontOptions =
            control.optionsVal?.map((op) => {
              return {
                id: op.id,
                text: op.text,
              };
            }) || [];

          control.displayValue = ""; // 勾沒有資料
          if (control.hasNoDataChecked === true) {
            control.displayValue = `無資料：${control.extraValue}`;
          } else {
            // 編輯控制項 value的值
            switch (control.ctrType) {
              case CtrType.Radio:
              case CtrType.RadioLong:
              case CtrType.Select:
                const selItem = control.optionsVal?.find(
                  (p) => p.id === control.value
                );
                if (selItem) {
                  control.displayValue = selItem.extraTextValue
                    ? `${selItem.text}(${selItem.extraTextValue})`
                    : selItem.text;
                  // 選擇的接題題目id存入，重複的不distink
                  if (selItem.followingControlIds) {
                    selFollowingControlIds.current = [
                      ...selFollowingControlIds.current,
                      ...selItem.followingControlIds,
                    ];
                  }
                }
                break;
              case CtrType.Checkbox:
              case CtrType.SelectMulti:
                const selCheckItems = control.optionsVal?.filter(
                  (p) => p.check === true
                );
                if (selCheckItems) {
                  selCheckItems.forEach((item, index) => {
                    control.displayValue += item.extraTextValue
                      ? `${item.text}(${item.extraTextValue})`
                      : item.text;
                    if (index !== selCheckItems.length - 1) {
                      control.displayValue += ",";
                    }
                    if (item.followingControlIds) {
                      selFollowingControlIds.current = [
                        ...selFollowingControlIds.current,
                        ...item.followingControlIds,
                      ];
                    }
                  });
                }
                break;
              default:
                control.displayValue = control.value;
                break;
            }
          }

          if (control.optionsVal) {
            control.optionsVal?.forEach((op) => {
              // 所有的接題id都存起來
              if (op.followingControlIds && op.followingControlIds.length > 0) {
                allFollowingControlIds.current = [
                  ...allFollowingControlIds.current,
                  ...op.followingControlIds,
                ];
              }
            });
          }

          // if (!specialRadio) {
          // 	specialRadio = isSpecialRadioControl(control);
          // }
        });
      });
    });

    let sectionSno = 0;
    // 設置disabled (如有接題) 和sno
    sectionDatas?.forEach((p: CustomerSampleDataControlValSectionModel) => {
      // 不接續section 重0開始
      if (!continueNum) {
        sectionSno = 0;
      }
      p.sections.forEach((section) => {
        sectionSno++;
        section.sectionSno = sectionSno;
        let questionSno = 0;

        section.controls?.forEach((control) => {
          control.disabled = false;
          questionSno++;
          control.questionSno = questionSno;

          // // 在被接題disabled
          // if (allFollowingControlIds.current.includes(control.sampleControlId)) {
          // 	// 但沒有被選過
          // 	if (!selFollowingControlIds.current.includes(control.sampleControlId)) {
          // 		control.disabled = true;
          // 	}
          // }

          // // 特殊disabled
          // setSpecialDisabled(control);

          // 預設無資料disabled
          if (control.hasData && control.hasNoDataChecked) {
          	control.disabled = true;
          }

          // // 特殊radio 卡控是否連動 or 必填
          // if (specialRadio) {
          // 	setSpecialRadioControl(control);
          // }
        });
      });
    });
    return sectionDatas;
  };

  // 卡控正數長度
  const getPositiveLength = (control: CompanySampleControlValModel): number => {
    let len = 9;
    // 邏輯參考issue 23636
    switch (control.code) {
      case SampleControlCode.AnnualCompanyHeldMeetingsNumbers:
        len = 4;
        break;
      case SampleControlCode.BoardOfDirectorsNumbers:
      case SampleControlCode.BoardOfFemaleDirectorsNumbers:
      case SampleControlCode.BoardOfIndependentDirectorsNumbers:
        len = 2;
        break;
      case SampleControlCode.OccupationalInjuryEmployees:
        len = 5;
        break;
      default:
        len = 9;
        break;
    }
    return len;
  };

  //#region 總覽
  const OverViewComponent: FC<{ data: DelareItemTreeModel[] }> = ({
    data = [],
  }) => {
    const images = ["global", "social", "governance"];
    return (
      <div id="esg-overview-form" className={"right-box overview-box"}>
        <div className="content-title-box">
          <div className="main-title">ESG資訊總覽-{infoData?.year}年度</div>
          <div className="content-tool">
            <LinkIconButton
              imgName="print-icon.svg"
              text="列印網頁"
              onClick={() => {
                PrintService.openNewWindow(
                  PrintElementType.EsgOverviewForm,
                  true
                );
              }}
            />
          </div>
        </div>
        {/* 總覽內容 */}
        <div className="overview-all-box scroll">
          {data.map((item) => {
            return (
              <div key={item.category} className="overview-content-box">
                <div className={"overview-title"}>
                  <img
                    alt="類型"
                    src={`/assets/images/declaration/application/${
                      images[item.category]
                    }-icon.svg`}
                  />
                  <div>{item.categoryString}</div>
                </div>
                {item.items?.map((info, index) => {
                  return (
                    <div
                      key={`${item.category}_${index}`}
                      className="overview-content"
                    >
                      <div className="form-name">{info.declareItemName}</div>
                      {info.sectionDatas.map((infoData, sectionIndex) => {
                        return (
                          <div key={sectionIndex} className="overview-item-box">
                            {/* 一般 與 鼓勵 */}
                            {infoData.sections.map((section, sectionIndex) => {
                              return (
                                <div key={section.id}>
                                  {/* 沒section 則不會有control name 而是合併 */}
                                  <div className="overview-section-item">
                                    {getSectionItemName(section)}
                                  </div>
                                  <div className="overview-item">
                                    {section.controls?.map(
                                      (control, controlIndx) => {
                                        return (
                                          <div
                                            key={control.sampleControlId}
                                            className="overview-question-item"
                                          >
                                            {!section.isBlankSection && (
                                              <div className="overview-question">
                                                {section.sectionSno}.
                                                {control.questionSno}{" "}
                                                {control.name}
                                                {control.unit
                                                  ? `(${control.unit})`
                                                  : ""}
                                              </div>
                                            )}
                                            <div className="overview-control">
                                              {control.displayValue
                                                ? control.displayValue
                                                : "-"}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  //#endregion

  //#region 點選總覽
  const clickOverview = () => {
    // 第一次編輯
    // firstEdit.current = true;
    setIsOverView(true);
    // 移除queryParam
    // queryParams.delete('sampleId');
    // setSearchParams(queryParams);
    if (selTreeItem) {
      setSelTreeItem({
        ...selTreeItem,
        sampleId: "all",
      });
    }
  };
  //#endregion

  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init
  useEffect(() => {
    console.log("id", id);
    console.log(
      "esgDisclosure_SelectedItemData",
      esgDisclosure_SelectedItemData
    );
    if (!id || !esgDisclosure_SelectedItemData) {
      navigate("/admin/declaration/esg-disclosure");
      return;
    }
    getDeclareItemTreeMutate({
      year: esgDisclosure_SelectedItemData.declareYear,
      companyCode: esgDisclosure_SelectedItemData.companyCode,
    });
    getDeclareInfoMutate(id);
  }, [
    id,
    esgDisclosure_SelectedItemData,
    navigate,
    getDeclareItemTreeMutate,
    getDeclareInfoMutate,
  ]);
  //* ---------------------------- hook end ---------------------------- *//

  // 取得section item 名稱
  const getSectionItemName = (section: ControlValSectionDetial) => {
    let str = `${section.sectionSno}.`;
    if (section.name) {
      str += section.name;
    } else {
      if (section.controls && section.controls.length > 0) {
        str += section.controls[0].name;
      }
    }
    return str;
  };

  return (
    <div className={`${styles["disclosure-read-box"]}`}>
      {/*上方bar*/}
      <div className="title-box">
        <BackTitle
          title={`${
            esgDisclosure_SelectedItemData
              ? esgDisclosure_SelectedItemData.companyCode +
                " - " +
                esgDisclosure_SelectedItemData.companyName
              : ""
          }`}
          url={`/admin/declaration/esg-disclosure`}
          state={location.state || null}
        />
        <LinkIconButton
          className="operateBtn"
          imgName={"export-icon.svg"}
          text={"匯出PDF"}
          onClick={() => {}}
        />
      </div>
      <div className="content-box  position-relative">
        {getDeclareItemTreeIsPending || getDeclareInfoIsPending ? (
          <VisuallLoading />
        ) : null}
        {/* 左區塊tab */}
        <div className="left-box scroll position-relative">
          <div className="main-title">申報項目</div>
          <div className="mt-20-p">
            <SearchInput
              onClick={(e) => {
                filterTree(e);
              }}
            />
          </div>
          {realTree.length > 0 && (
            <div className={"tree-box scroll mt-20-p"}>
              {/* ESG資訊總覽 */}
              <div
                onClick={() => {
                  // 編輯情境要跳出資訊
                  // if (action === PageActionTyep.Edit && !firstEdit.current) {
                  // 	setDataConfirmModal({
                  // 		...dataConfirmModal,
                  // 		show: true,
                  // 		handleConfirm() {
                  // 			clickOverview();
                  // 			setDataConfirmModal({
                  // 				...dataConfirmModal,
                  // 				show: false,
                  // 			});
                  // 		},
                  // 	});
                  // } else {
                  clickOverview();
                  // }
                }}
                className={`overview-box ` + `${isOverView ? "active" : ""}`}
              >
                <img
                  className=""
                  alt="overview"
                  src={
                    `/assets/images/declaration/application/` +
                    `${
                      isOverView
                        ? "white-overview-icon.svg"
                        : "black-overview-icon.svg"
                    }`
                  }
                />
                <div>ESG資訊總覽</div>
              </div>
              {TreeComponent(realTree)}
            </div>
          )}
        </div>

        {/* 右區塊總覽 */}
        {isOverView && <OverViewComponent data={realTree} />}
        {/* 右區塊普通節點 */}
        {!isOverView && (
          <div className="right-box scroll position-relative">
            <ScrollToTopButton
              targetClassName={"right-box"}
              bottom={90}
              right={35}
            />
            {getDeclareItemDetailIsPending ? (
              <VisuallLoading />
            ) : (
              <>
                {!selTreeItem ? (
                  <div className="no-data">沒有資料</div>
                ) : (
                  <>
                    <div className="content-title-box">
                      <div className="main-title">
                        {selTreeItem?.declareItemName}
                      </div>
                    </div>
                    <div className={"desc"}></div>

                    {/* 動態內容(編輯模式) */}
                    <div ref={formRef} className="form-box scroll">
                      <div className="preview-box">
                        {detailInfo?.map((info, index) => {
                          return (
                            info.sections.length > 0 && (
                              <div key={index} className="preview-item">
                                <div className={"content"}>
                                  {index === 0
                                    ? "必要揭露指標："
                                    : "鼓勵揭露指標："}
                                </div>
                                {info.sections.map((section, sectionIndex) => {
                                  return (
                                    <div key={sectionIndex}>
                                      <div className="section-item">
                                        {getSectionItemName(section)}
                                      </div>
                                      {section.controls?.map(
                                        (control, controlIndx) => {
                                          return (
                                            <div
                                              key={controlIndx}
                                              className="question-item"
                                            >
                                              {/* 沒section 則不會有control name 而是合併 */}
                                              {section.name && (
                                                <div className="question">
                                                  <>
                                                    {section.sectionSno}.
                                                    {control.questionSno}{" "}
                                                    {control.name}
                                                  </>
                                                </div>
                                              )}
                                              <div className="control">
                                                {control.displayValue
                                                  ? control.displayValue
                                                  : "-"}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Detail;
