import { jsEditorOp, jsEditorOptionModel } from "./jsEditorOptiosn";

// 宣告全域取得資料變數
declare let tinymce: any;
/** 編輯器類別 */
export class jsEditor {
  /** 取得該instance */
  private _instance: any;
  /** 編輯器項目資訊 */
  private _option: any;
  constructor(partOption: jsEditorOptionModel) {
    // 設定selector
    partOption.selector = `#${partOption.id}`;
    // 設定語言
    this.setLanguage(partOption);
    // 深拷貝一份設定好的參數
    let baseOption = JSON.parse(JSON.stringify(jsEditorOp));
    // 傳進來的參數覆蓋對應設定
    baseOption = { ...baseOption, ...partOption };
    // 儲存設定
    this._option = baseOption;
    this.init();
  }

  /** 初始化 */
  private init(): void {
    // 避免存在先clear
    this.clear();
    // 初始化
    tinymce.init(this._option);
    this._instance = tinymce.get(this._option.id);
  }

  /** 取得內容 */
  getContent(): any {
    if (this._instance) {
      return this._instance.getContent();
    }
    return null;
  }

  /** 設置內容 */
  setContent(conetent: string | null): void {
    if (conetent && this._instance) {
      try {
        this._instance.setContent(conetent);
      } catch (ex) {
        // 設置錯誤避免影響其他api
        console.log('setContent error:', ex);
      }
    }
  }

  /** 清除 */
  clear(): void {
    if (this._option) {
      // 清空內容
      this.setContent('');
      // 移除掉tiny
      tinymce.remove(this._option.selector);
    }
  }

  /** 編輯器是否存在 */
  exist(): boolean {
    // console.log(this._instance)
    if (this._instance) {
      return !this._instance.destory;
    }
    return false;
  }

  /** 設定語言 */
  private setLanguage(option: jsEditorOptionModel): void {
    let language = option.language || localStorage.getItem('language');
    switch (language) {
      case 'zh-TW':
        option.language = 'zh_TW';
        break;
      case 'en':
        option.language = null;
        break;
      case 'zh-CN':
        option.language = 'zh_CN';
        break;
    }
  }

  //* tinymce instance */
  tinymce(): any {
    return tinymce;
  }
}
