import React, { FC } from "react";
import styles from "./EmptyWaitPage.module.scss";

interface EmptyWaitPageProps {
  text?: string;
  customClassName?: string;
  customStyle?: React.CSSProperties;
}

const EmptyWaitPage: FC<EmptyWaitPageProps> = (props) => {
  return (
    <div
      className={`${styles["empty-page-box"]} ${props.customClassName}`}
      style={props.customStyle}
    >
      {props.text}
    </div>
  );
};

export default EmptyWaitPage;
