import { FC, useState, useEffect, Fragment } from "react";
import styles from "./DisclosureSetting.module.scss";
import { NavBarItemModel } from "models/baseModel";
import { useParams, useLocation } from "react-router-dom";
import BackTitle from "components/base/BackTitle/BackTitle";
import TabVerticalThin from "components/base/TabVerticalThin/TabVerticalThin";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import IndicatorService from "services/admin/indicatorService";
import { useQuery, useMutation } from "@tanstack/react-query";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import {
  NecessaryIndicatorModel,
  NecessaryIndicatorControlModel,
} from "models/admin/indicatorModel";
import { success2Alert, error2Alert } from "utils/otherToast";
import { usePermissionContext } from "context/PermissionProvider";
import { IndicatorManageActionsPermissionCodes } from "models/auth/permissionModel";

const DisclosureSetting: FC = () => {
  const params = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [sampleId] = useState<string>(params.eid!);
  const [tableContent, setTableContent] = useState<React.ReactNode>();
  const [necessaryIndicatorData, setNecessaryIndicatorData] =
    useState<NecessaryIndicatorModel | null>();
  const [editNecessaryIndicatorData, setEditNecessaryIndicatorData] =
    useState<NecessaryIndicatorModel | null>();
  const [operatorsContent, setOperatorsContent] = useState<React.ReactNode>();

  const navItems: NavBarItemModel[] = [
    {
      name: "指標設定",
      key: "edit",
      url: `/admin/indicator/${params.id}/setting/${params.eid}/edit`,
      disabled: false,
    },
    {
      name: "必要揭露指標設定",
      key: "disclosureSetting",
      url: `/admin/indicator/${params.id}/setting/${params.eid}/disclosureSetting`,
      disabled: params.eid === "-1" ? true : false,
    },
  ];
  const location = useLocation();
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;

  //- getNecessaryIndicator
  const {
    data: getNecessaryIndicatorData,
    isLoading: getNecessaryIndicatorDataIsLading,
    refetch: getNecessaryIndicatorRefetch,
    isRefetching: getNecessaryIndicatorIsRefetching,
  } = useQuery({
    queryKey: ["necessaryIndicator", sampleId],
    queryFn: () => IndicatorService.getNecessaryIndicator(sampleId),
    select: (res) => {
      if (res.code === 200 && res.success) {
        return res.data;
      }
      return null;
    },
  });

  //- saveNecessaryIndicatorMutation
  const {
    mutate: saveNecessaryIndicatorMutation,
    isPending: saveNecessaryIndicatorIsPending,
  } = useMutation({
    mutationFn: () =>
      IndicatorService.saveNecessaryIndicator(editNecessaryIndicatorData!),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        setIsEdit(false);
        getNecessaryIndicatorRefetch();
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("saveNecessaryIndicatorMutation", err);
      error2Alert("執行失敗");
    },
  });

  useEffect(() => {
    if (getNecessaryIndicatorData) {
      setNecessaryIndicatorData(getNecessaryIndicatorData);
      setEditNecessaryIndicatorData(
        JSON.parse(JSON.stringify(getNecessaryIndicatorData))
      );
    }
  }, [getNecessaryIndicatorData]);

  useEffect(() => {
    //- handleCheckboxChange
    const handleCheckboxChange = (
      industryIndex: number,
      indicatorControlIndex: number,
      porpertyName: string
    ) => {
      const newNecessaryIndicatorData: NecessaryIndicatorModel = {
        ...editNecessaryIndicatorData!,
      };
      if (newNecessaryIndicatorData?.necessaryIndicatorIndustryDatas) {
        const selectedIndustryData =
          newNecessaryIndicatorData.necessaryIndicatorIndustryDatas[
            industryIndex
          ];
        const selectedIndicatorControl =
          selectedIndustryData.indicatorControls[indicatorControlIndex];
        selectedIndicatorControl[porpertyName] =
          !selectedIndicatorControl[porpertyName];
      }
      setEditNecessaryIndicatorData(newNecessaryIndicatorData);
    };

    //- handleYAxisCheckboxChange
    const handleYAxisCheckboxChange = (industryIndex: number) => {
      const newNecessaryIndicatorData: NecessaryIndicatorModel = {
        ...editNecessaryIndicatorData!,
      };
      if (newNecessaryIndicatorData?.necessaryIndicatorIndustryDatas) {
        const checkDataList =
          newNecessaryIndicatorData.necessaryIndicatorIndustryDatas[
            industryIndex
          ].indicatorControls;
        const allNecessary = checkDataList.every(
          (item) =>
            item.isNecessaryOverTwoBillion && item.isNecessaryUnderTwoBillion
        );
        checkDataList.forEach((item) => {
          item.isNecessaryOverTwoBillion = !allNecessary;
          item.isNecessaryUnderTwoBillion = !allNecessary;
        });
      }
      setEditNecessaryIndicatorData(newNecessaryIndicatorData);
    };

    //- detectYAxisCheckboxDisplay
    const detectYAxisCheckboxDisplay = (industryIndex: number) => {
      const checkDataList =
        editNecessaryIndicatorData?.necessaryIndicatorIndustryDatas[
          industryIndex
        ].indicatorControls;
      const allNecessary = checkDataList?.every(
        (item) =>
          item.isNecessaryOverTwoBillion && item.isNecessaryUnderTwoBillion
      );
      if (allNecessary) return "checked";
      const someNecessary = checkDataList?.some(
        (item) =>
          item.isNecessaryOverTwoBillion || item.isNecessaryUnderTwoBillion
      );
      if (someNecessary) return "partial";
      return "";
    };

    //- handleTwoBillonYAxisCheckboxChange
    const handleTwoBillonYAxisCheckboxChange = (
      industryIndex: number,
      porpertyName: string
    ) => {
      const newNecessaryIndicatorData: NecessaryIndicatorModel = {
        ...editNecessaryIndicatorData!,
      };
      if (newNecessaryIndicatorData?.necessaryIndicatorIndustryDatas) {
        const checkDataList =
          newNecessaryIndicatorData.necessaryIndicatorIndustryDatas[
            industryIndex
          ].indicatorControls;
        const allNecessary = checkDataList.every((item) => item[porpertyName]);
        checkDataList.forEach((item) => {
          item[porpertyName] = !allNecessary;
        });
      }
      setEditNecessaryIndicatorData(newNecessaryIndicatorData);
    };

    //- detectTwoBillonYAxisCheckboxDisplay
    const detectTwoBillonYAxisCheckboxDisplay = (
      industryIndex: number,
      porpertyName: string
    ) => {
      const checkDataList =
        editNecessaryIndicatorData?.necessaryIndicatorIndustryDatas[
          industryIndex
        ].indicatorControls;
      const allNecessary = checkDataList?.every((item) => item[porpertyName]);
      if (allNecessary) return "checked";
      const someNecessary = checkDataList?.some((item) => item[porpertyName]);
      if (someNecessary) return "partial";
      return "";
    };

    //- handleXAxisCheckboxChange
    const handleXAxisCheckboxChange = (indicatorControlIndex: number) => {
      const newNecessaryIndicatorData: NecessaryIndicatorModel = {
        ...editNecessaryIndicatorData!,
      };
      if (newNecessaryIndicatorData?.necessaryIndicatorIndustryDatas) {
        const checkDataList =
          newNecessaryIndicatorData.necessaryIndicatorIndustryDatas.map(
            (e) => e.indicatorControls[indicatorControlIndex]
          );
        const allNecessary = checkDataList.every(
          (item) =>
            item.isNecessaryOverTwoBillion && item.isNecessaryUnderTwoBillion
        );
        checkDataList.forEach((item) => {
          item.isNecessaryOverTwoBillion = !allNecessary;
          item.isNecessaryUnderTwoBillion = !allNecessary;
        });
      }
      setEditNecessaryIndicatorData(newNecessaryIndicatorData);
    };

    //- detectXAxisCheckboxDisplay
    const detectXAxisCheckboxDisplay = (indicatorControlIndex: number) => {
      const checkDataList =
        editNecessaryIndicatorData?.necessaryIndicatorIndustryDatas.map(
          (e) => e.indicatorControls[indicatorControlIndex]
        );
      const allNecessary = checkDataList?.every(
        (item) =>
          item.isNecessaryOverTwoBillion && item.isNecessaryUnderTwoBillion
      );
      if (allNecessary) return "checked";
      const someNecessary = checkDataList?.some(
        (item) =>
          item.isNecessaryOverTwoBillion || item.isNecessaryUnderTwoBillion
      );
      if (someNecessary) return "partial";
      return "";
    };

    //- setTableContentHandler
    const setTableContentHandler = () => {


      if (getNecessaryIndicatorDataIsLading) return;

      if (!necessaryIndicatorData?.necessaryIndicatorIndustryDatas?.length) {
        setTableContent(<>沒有資料</>);
        return;
      }

      //- 如果是編輯狀態，就用editNecessaryIndicatorData, 否則就用necessaryIndicatorData
      const necessaryIndicatorIndustryDatas = isEdit
        ? editNecessaryIndicatorData?.necessaryIndicatorIndustryDatas
        : necessaryIndicatorData?.necessaryIndicatorIndustryDatas;

      // const { necessaryIndicatorIndustryDatas } = necessaryIndicatorData;

      setTableContent(
        <>
          <ScrollToTopButton
            targetClassName={"setting-table-box-test"}
            bottom={90}
            right={35}
          />
          <table
            aria-label="table"
            className={`${styles["setting-table"]} table-container sticky-table`}
          >
            <thead>
              <tr>
                <th scope="col">項目 \ 行業別</th>
                {necessaryIndicatorIndustryDatas &&
                  necessaryIndicatorIndustryDatas.map(
                    (industry, industryIndex) => (
                      <th key={industryIndex} scope="col" colSpan={2}>
                        {isEdit ? (
                          <div>
                            {industry.name}
                            <div className={`${styles["setting-checkbox"]}`}>
                              <label
                                className={`custom-checkbox hideText axit`}
                              >
                                <input
                                  type="checkbox"
                                  className={`custom`}
                                  checked={
                                    detectYAxisCheckboxDisplay(
                                      industryIndex
                                    ) === "checked"
                                  }
                                  onChange={() =>
                                    handleYAxisCheckboxChange(industryIndex)
                                  }
                                />
                                <span
                                  className={`${detectYAxisCheckboxDisplay(
                                    industryIndex
                                  )}`}
                                ></span>
                              </label>
                            </div>
                          </div>
                        ) : (
                          industry.name
                        )}
                      </th>
                    )
                  )}
              </tr>
              <tr>
                <th scope="col">資本額</th>
                {necessaryIndicatorIndustryDatas &&
                  necessaryIndicatorIndustryDatas.map(
                    (industry, industryIndex) => (
                      <Fragment key={industryIndex}>
                        {isEdit ? (
                          <>
                            <th scope="col">
                              <div>
                                二十億以上
                                <div
                                  className={`${styles["setting-checkbox"]}`}
                                >
                                  <label
                                    className={`custom-checkbox hideText axit`}
                                  >
                                    <input
                                      type="checkbox"
                                      className={`custom`}
                                      checked={
                                        detectTwoBillonYAxisCheckboxDisplay(
                                          industryIndex,
                                          "isNecessaryOverTwoBillion"
                                        ) === "checked"
                                      }
                                      onChange={() =>
                                        handleTwoBillonYAxisCheckboxChange(
                                          industryIndex,
                                          "isNecessaryOverTwoBillion"
                                        )
                                      }
                                    />
                                    <span
                                      className={`${detectTwoBillonYAxisCheckboxDisplay(
                                        industryIndex,
                                        "isNecessaryOverTwoBillion"
                                      )}`}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </th>
                            <th scope="col">
                              <div>
                                二十億以下
                                <div
                                  className={`${styles["setting-checkbox"]}`}
                                >
                                  <label
                                    className={`custom-checkbox hideText axit`}
                                  >
                                    <input
                                      type="checkbox"
                                      className={`custom`}
                                      checked={
                                        detectTwoBillonYAxisCheckboxDisplay(
                                          industryIndex,
                                          "isNecessaryUnderTwoBillion"
                                        ) === "checked"
                                      }
                                      onChange={() =>
                                        handleTwoBillonYAxisCheckboxChange(
                                          industryIndex,
                                          "isNecessaryUnderTwoBillion"
                                        )
                                      }
                                    />
                                    <span
                                      className={`${detectTwoBillonYAxisCheckboxDisplay(
                                        industryIndex,
                                        "isNecessaryUnderTwoBillion"
                                      )}`}
                                    ></span>
                                  </label>
                                </div>
                              </div>
                            </th>
                          </>
                        ) : (
                          <>
                            <th scope="col">二十億以上</th>
                            <th scope="col">二十億以下</th>
                          </>
                        )}
                      </Fragment>
                    )
                  )}
              </tr>
            </thead>
            <tbody>
              {/* //- 我想要 是以 indicatorControl 數量為主 然後再去找 industry */}
              {necessaryIndicatorIndustryDatas?.length &&
                necessaryIndicatorIndustryDatas[0].indicatorControls?.map(
                  (indicatorControl, indicatorControlIndex) => (
                    <tr key={indicatorControlIndex}>
                      <td>
                        {isEdit ? (
                          <div>
                            {indicatorControl.controlName}
                            <div className={`${styles["setting-checkbox"]}`}>
                              <label
                                className={`custom-checkbox hideText axit`}
                              >
                                <input
                                  type="checkbox"
                                  className={`custom`}
                                  checked={
                                    detectXAxisCheckboxDisplay(
                                      indicatorControlIndex
                                    ) === "checked"
                                  }
                                  onChange={() =>
                                    handleXAxisCheckboxChange(
                                      indicatorControlIndex
                                    )
                                  }
                                />
                                <span
                                  className={`${detectXAxisCheckboxDisplay(
                                    indicatorControlIndex
                                  )}`}
                                ></span>
                              </label>
                            </div>
                          </div>
                        ) : (
                          indicatorControl.controlName
                        )}
                      </td>
                      {necessaryIndicatorIndustryDatas?.map(
                        (industry, industryIndex) => (
                          <Fragment key={industryIndex}>
                            {isEdit ? (
                              <>
                                <td>
                                  <div
                                    className={`${styles["setting-checkbox"]}`}
                                  >
                                    <label
                                      className={`custom-checkbox hideText`}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={
                                          industry.indicatorControls[
                                            indicatorControlIndex
                                          ].isNecessaryOverTwoBillion
                                        }
                                        onChange={() =>
                                          handleCheckboxChange(
                                            industryIndex,
                                            indicatorControlIndex,
                                            "isNecessaryOverTwoBillion"
                                          )
                                        }
                                      />
                                      <span></span>
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className={`${styles["setting-checkbox"]}`}
                                  >
                                    <label
                                      className={`custom-checkbox hideText`}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={
                                          industry.indicatorControls[
                                            indicatorControlIndex
                                          ].isNecessaryUnderTwoBillion
                                        }
                                        onChange={() =>
                                          handleCheckboxChange(
                                            industryIndex,
                                            indicatorControlIndex,
                                            "isNecessaryUnderTwoBillion"
                                          )
                                        }
                                      />
                                      <span></span>
                                    </label>
                                  </div>
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  {industry.indicatorControls[
                                    indicatorControlIndex
                                  ].isNecessaryOverTwoBillion ? (
                                    <img
                                      src="/assets/images/buttonIcon/gray-check-icon.svg"
                                      alt=""
                                    />
                                  ) : null}
                                </td>
                                <td>
                                  {industry.indicatorControls[
                                    indicatorControlIndex
                                  ].isNecessaryUnderTwoBillion ? (
                                    <img
                                      src="/assets/images/buttonIcon/gray-check-icon.svg"
                                      alt=""
                                    />
                                  ) : null}
                                </td>
                              </>
                            )}
                          </Fragment>
                        )
                      )}
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </>
      );
    };
    setTableContentHandler();

    //- setOperatorsContentHandler
    const setOperatorsContentHandler = () => {


      if (!necessaryIndicatorData?.necessaryIndicatorIndustryDatas?.length) {
        setOperatorsContent(<></>);
        return;
      }

      setOperatorsContent(
        <div className={`${styles["operators"]}`}>
          {isEdit ? (
            <div>
              {detectEveryActionPermission([
                IndicatorManageActionsPermissionCodes.NecessaryIndicatorUpdate,
              ]) ? (
                <>
                  <button
                    className="secondary"
                    onClick={() => {
                      setIsEdit(false);
                      setEditNecessaryIndicatorData(
                        JSON.parse(JSON.stringify(necessaryIndicatorData))
                      );
                    }}
                  >
                    取消
                  </button>
                  <button
                    className="default"
                    onClick={() => saveNecessaryIndicatorMutation()}
                  >
                    儲存
                  </button>
                </>
              ) : null}
            </div>
          ) : (
            <div>
              {detectEveryActionPermission([
                IndicatorManageActionsPermissionCodes.NecessaryIndicatorUpdate,
              ]) ? (
                <button className="default" onClick={() => setIsEdit(true)}>
                  編輯
                </button>
              ) : null}
            </div>
          )}
        </div>
      );
    };

    setOperatorsContentHandler();
  }, [
    getNecessaryIndicatorData,
    getNecessaryIndicatorDataIsLading,
    getNecessaryIndicatorRefetch,
    necessaryIndicatorData,
    isEdit,
    saveNecessaryIndicatorMutation,
    editNecessaryIndicatorData,
    detectEveryActionPermission,
  ]);

  return (
    <div className={`${styles["disclosureSetting-read"]}`}>
      {/*上方bar*/}
      <div className="title-box">
        <BackTitle
          title={"編輯指標"}
          url={`/admin/indicator/${params.id}/setting`}
          state={location.state || null}
        />
      </div>
      <div className="indicator-setting-content-box">
        {/* 左區塊tab */}
        <div className="left-box">
          <TabVerticalThin
            data={navItems}
            tabKey={"disclosureSetting"}
            state={location.state || null}
          />
        </div>

        {/* 右區塊 */}
        <div className="right-box">
          {/* header */}
          <div className={`${styles["right-box-header"]}`}>
            <div className={`${styles["title"]}`}>必要揭露指標設定</div>
            {/* operators */}
            {operatorsContent}
          </div>
          <div
            className={`${styles["setting-table-box"]} setting-table-box-test scroll`}
          >
            {/* loading */}
            {(getNecessaryIndicatorDataIsLading ||
              getNecessaryIndicatorIsRefetching ||
              saveNecessaryIndicatorIsPending) && <VisuallLoading />}
            {/* tableContent */}
            {tableContent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisclosureSetting;
