import { ListRequestModel } from "models/baseModel";

export interface SysFunctionModel {
  id: string | null;
  name: string | null;
  type: number | null;
  status: boolean;
}

export interface SysLogModel {
  sno: number;
  userId: string | null;
  page: string | null;
  ipAddress: string | null;
  method: string | null;
  body: string | null;
  createTime: Date;
}

export interface SystemLogRequestModel extends ListRequestModel {
  startTime?: any | null;
  endTime?: any | null;
}


/** 系統管理功能Type */
export enum SystemManageType {
  /** XBRL匯出按鈕 */
  XBRL,
  /** ESG報告書AI解析按鈕 */
  ESGReportAI,
  /** 永續報告書產製 */
  SustainReport,
  /** 下載永續準則矩陣 */
  MatrixDiagram
}
