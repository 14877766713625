import React, { FC, useRef, useState } from "react";
import styles from "./ToolComponent.module.scss";
import BackTitle from "components/base/BackTitle/BackTitle";
import BatchUploadModal, {
  BatchUploadModalProps,
} from "components/base/BatchUploadModal/BatchUploadModal";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";
import EmailInviteInput from "components/base/EmailInviteInput/EmailInviteInput";
import MoreTool from "components/base/MoreTool/MoreTool";
import MultiSelect from "components/base/MultiSelect/MultiSelect";
import NormalInput from "components/base/NormalInput/NormalInput";
import PaginationSelect from "components/base/PaginationSelect/PaginationSelect";
import SearchInput from "components/base/SearchInput/SearchInput";
import SwitchCheckbox from "components/base/SwitchCheckbox/SwitchCheckbox";
import TablePagination from "components/base/TablePagination/TablePagination";
import Tip from "components/base/Tip/Tip";
import IconButton from "components/button/IconButton/IconButton";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import { OptionModel } from "models/baseModel";
import {
  successAlert,
  errorAlert,
  success2Alert,
  error2Alert,
} from "utils/otherToast";
import Dropdown from "components/base/Dropdown/Dropdown";
import NormalTextarea from "components/base/NormalTextarea/NormalTextarea";
import BasicAccordion from "components/base/BasicAccordion/BasicAccordion";
import NewDropdown from "components/base/NewDropDown/NewDropDown";

interface ToolComponentProps {}

const ToolComponent: FC<ToolComponentProps> = () => {
  const handleSearch = (key: string) => {
    console.log("search", key);
  };

  const handlePrint = () => {
    console.log("print");
  };

  const handleChange = (str: string) => {
    console.log(str);
  };

  const handleDropdownChange = (str: string | null) => {
    console.log(str);
  };

  const handleClick = () => {
    console.log("公開資訊站");
  };

  const handleMultiSelectChange = (ids: string[]) => {
    console.log("MultiSelect", ids);
  };

  const options: OptionModel[] = [
    {
      id: "000014",
      text: "臺銀證券",
    },
    {
      id: "000218",
      text: "亞東證券",
    },
    {
      id: "000511",
      text: "富隆",
    },
  ];

  // modal 物件(confirm)
  const [modal, setModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => handleCloseModal(),
    handleConfirm: () => handleConfirmModal(),
    title: "confirm modal",
  });

  // 處理關閉
  const handleCloseModal = () => {
    console.log("close");
    handleModal();
  };

  // 處理確認
  const handleConfirmModal = () => {
    console.log("confirm");
    handleModal();
  };

  // 處理modal關閉事件
  const handleModal = () => {
    setModal((prev) => {
      return {
        ...prev,
        show: !prev.show,
      };
    });
  };

  // modal 物件(context confirm)
  const [modal2, setModal2] = useState<ContentModalProps>({
    show: false,
    handleClose: () => handleCloseModal2(),
    title: "context confirm",
    size: "lg",
  });

  // 處理關閉
  const handleCloseModal2 = () => {
    console.log("close");
    handleModal2();
  };

  // 處理modal關閉事件
  const handleModal2 = () => {
    setModal2((prev) => {
      return {
        ...prev,
        show: !prev.show,
      };
    });
  };

  // modal 物件(context confirm)
  const [modal3, setModal3] = useState<BatchUploadModalProps>({
    show: false,
    handleClose: () => {
      setModal3((prev) => {
        return {
          ...prev,
          show: !prev.show,
        };
      });
    },
    handleUpload: () => {},
  });

  const [checked, setChecked] = useState<boolean>(false);
  const handleSwitch = () => {
    setChecked(!checked);
  };

  const handleClick2 = (item: OptionModel) => {
    console.log(item);
  };

  const exportRef = useRef(null);
  const [moreShow, setMoreShow] = useState(false);

  const [test, setTest] = useState(10);

  return (
    <div className={`${styles["tools-box"]} scroll scrollToTop`}>
      {/* input */}
      <div className="row">
        <div className="col-12 title">基礎元件</div>
        <div className="col-3">
          <div className="component-title">SearchInput</div>
          <SearchInput onClick={handleSearch} />
        </div>
        <div className="col-9"></div>
        <div className="col-3">
          <div className="component-title"> NormalInput with Unit</div>
          <NormalInput
            placeholder="公司代號/股票代號*"
            unit="單位"
            onChange={handleChange}
          />
        </div>
        <div className="col-3">
          <div className="component-title">NormalInput</div>
          <NormalInput
            isFloatTitle={true}
            placeholder="公司代號/股票代號*"
            onChange={handleChange}
          />
        </div>
        <div className="col-3">
          <div className="component-title"> NormalInput NoTitle</div>
          <NormalInput
            isFloatTitle={false}
            placeholder={"公司代號/股票代號"}
            onChange={handleChange}
          />
        </div>
        <div className="col-3">
          <div className="component-title"> NormalInput Invalid</div>
          <NormalInput
            isFloatTitle={true}
            valid={false}
            defaultValue={"123"}
            placeholder={"公司代號/股票代號"}
            onChange={handleChange}
          />
        </div>
        <div className="col-3">
          <div className="component-title"> Dropdown with Title</div>
          <Dropdown
            placeholder={"公司代號/股票代號*"}
            title={"公發證券"}
            options={options}
            onChange={handleDropdownChange}
          />
        </div>
        <div className="col-3">
          <div className="component-title">Dropdown</div>
          <Dropdown
            placeholder={"公司代號/股票代號*"}
            options={options}
            defaultId={"000014"}
            isFloatTitle={true}
            onChange={handleDropdownChange}
          />
        </div>
        <div className="col-3">
          <div className="component-title">DropDown NoTitle</div>
          <Dropdown
            placeholder={"公司代號/股票代號*"}
            options={options}
            isFloatTitle={false}
            onChange={handleDropdownChange}
          />
        </div>
        <div className="col-3">
          <div className="component-title">DropDown Invalid</div>
          <Dropdown
            placeholder={"公司代號/股票代號*"}
            defaultId="000014"
            options={options}
            isFloatTitle={true}
            valid={false}
            onChange={handleDropdownChange}
          />
        </div>
        <div className="col-3">
          <div className="component-title"> MultiSelect</div>
          <MultiSelect
            placeholder={"年度*"}
            isFloatTitle={true}
            options={[
              { id: "105", text: "105" },
              { id: "106", text: "106" },
            ]}
            defaultIds={["105"]}
            onChange={handleMultiSelectChange}
          />
        </div>
        <div className="col-3">
          <div className="component-title"> MultiSelect NoTitle</div>
          <MultiSelect
            placeholder={"年度*"}
            isFloatTitle={false}
            options={[
              { id: "105", text: "105" },
              { id: "106", text: "106" },
            ]}
            defaultIds={["105"]}
            onChange={handleMultiSelectChange}
          />
        </div>
        <div className="col-3">
          <div className="component-title"> MultiSelect Invalid</div>
          <MultiSelect
            placeholder={"年度*"}
            isFloatTitle={true}
            valid={false}
            options={[
              { id: "105", text: "105" },
              { id: "106", text: "106" },
            ]}
            defaultIds={["105"]}
            onChange={handleMultiSelectChange}
          />
        </div>
        <div className="col-3"></div>
        <div className="col-3">
          <div className="component-title">NormalTextarea</div>
          <NormalTextarea
            isFloatTitle={true}
            placeholder="公司代號/股票代號*"
            onChange={handleChange}
          />
        </div>
        <div className="col-3">
          <div className="component-title"> NormalInput NoTitle</div>
          <NormalTextarea
            isFloatTitle={false}
            placeholder="公司代號/股票代號*"
            onChange={handleChange}
          />
        </div>
        <div className="col-3">
          <div className="component-title"> NormalTextarea Invalid</div>
          <NormalTextarea
            isFloatTitle={true}
            defaultValue="123"
            valid={false}
            placeholder="公司代號/股票代號*"
            onChange={handleChange}
          />
        </div>
        <div className="col-3"></div>

        <div className="col-3">
          <div className="component-title">NewDropdown (舊有搜尋版本)</div>
          <NewDropdown
            placeholder={"舊版搜尋*"}
            options={options}
            defaultId={""}
            isFloatTitle={true}
            onChange={handleDropdownChange}
          />
        </div>
      </div>

      {/* modal */}
      <div className="row mt-5">
        <div className="col-12 title">modal</div>
        <div className="col-3">
          <div className="component-title"> ConfirmModal</div>
          <button className="secondary" onClick={handleModal}>
            confirm modal
          </button>
          <ConfirmModal {...modal}>
            <div>測試內容</div>
          </ConfirmModal>
        </div>
        <div className="col-3">
          <div className="component-title"> ContentModal</div>
          <button className="secondary" onClick={handleModal2}>
            contentModal modal
          </button>
          <ContentModal {...modal2}>
            <div>無敵多內容</div>
          </ContentModal>
        </div>
        <div className="col-3">
          <div className="component-title"> BatchUploadModal</div>
          <button
            className="secondary"
            onClick={() => {
              setModal3((prev) => {
                return {
                  ...prev,
                  show: !prev.show,
                };
              });
            }}
          >
            批次匯入
          </button>
          <BatchUploadModal {...modal3} />
        </div>
        <div className="col-3 position-relative">
          <div className="component-title"> MoreTool</div>
          <MoreTool
            show={moreShow}
            target={
              <button
                ref={exportRef}
                className="secondary "
                onClick={() => {
                  setMoreShow(!moreShow);
                }}
              >
                更多工具
              </button>
            }
            onToggle={(show: boolean) => {
              setMoreShow(show);
            }}
          >
            <button
              onClick={() => {
                console.log("PDF");
              }}
            >
              PDF
            </button>
            <button>EXCEL</button>
            <button>XBRL</button>
          </MoreTool>
        </div>
      </div>

      {/* 基礎按鈕 */}
      <div className="row mt-5">
        <div className="col-12 title">基礎按鈕</div>

        <div className="col-3">
          <div className="component-title"> LinkIconButton</div>
          <LinkIconButton
            imgName="print-icon.svg"
            text="列印網頁"
            onClick={handlePrint}
          />
        </div>
        <div className="col-3">
          <div className="component-title"> default no icon</div>
          <button className="default" onClick={handleClick}>
            公開資訊觀測站
          </button>
        </div>
        <div className="col-3">
          <div className="component-title"> secondary no icon</div>
          <button className="secondary" onClick={handleClick}>
            公開資訊觀測站
          </button>
        </div>
        <div className="col-3">
          <div className="component-title"> disabled</div>
          <button className="secondary disabled" onClick={handleClick}>
            disabled
          </button>
        </div>
        <div className="col-3">
          <div className="component-title"> IconButton default</div>
          <IconButton
            prePath="declaration/application"
            imgName="global-icon.svg"
            text="公開資訊觀測站"
            onClick={handleClick}
            className=""
          />
        </div>
        <div className="col-3">
          <div className="component-title"> IconButton secondary</div>
          <IconButton
            prePath="declaration/application"
            imgName="global-icon.svg"
            text="公開資訊觀測站"
            onClick={handleClick}
            className="secondary"
          />
        </div>
        <div className="col-3">
          <div className="component-title"> Switch checkbox</div>
          <SwitchCheckbox
            checked={checked}
            text={"test"}
            onClick={() => handleSwitch()}
          />
        </div>
        <div className="col-3">
          <div className="component-title"> Scroll To Top Button</div>
          <ScrollToTopButton {...{ targetClassName: "scrollToTop" }} />
        </div>
      </div>

      {/* html控制項元件 */}
      <div className="row mt-5">
        <div className="col-12 title">html控制項元件</div>

        <div className="col-3">
          <div className="component-title"> Radio</div>
          <div className="d-flex">
            <label className="custom-radio">
              <input type="radio" name="radio" />
              <span>選項一</span>
            </label>
            <label className="custom-radio">
              <input type="radio" name="radio" />
              <span>選項二</span>
            </label>
          </div>
        </div>
        <div className="col-3">
          <div className="component-title"> Checkbox</div>
          <div className="d-flex">
            <label className="custom-checkbox ">
              <input type="checkbox" onChange={() => {}} />
              <span>選項一</span>
            </label>
            <label className="custom-checkbox ">
              <input type="checkbox" onChange={() => {}} />
              <span>選項二</span>
            </label>
          </div>
        </div>
        <div className="col-3">
          <div className="component-title"> Textarea</div>
          <div className="d-flex">
            <textarea
              className="custom-textarea"
              disabled
              defaultValue={"disabled"}
            ></textarea>
            <textarea
              className="custom-textarea"
              defaultValue={"no disabled"}
            ></textarea>
          </div>
        </div>

        {/** 返回標頭 */}
        <div className="col-3">
          <div className="component-title"> BackTitle</div>
          <BackTitle title={"返回標頭"} url={"/development/tools"} />
        </div>
        {/* tip */}
        <div className="col-3">
          <div className="component-title"> Tip</div>
          <div>
            <Tip text="允許輸入多個Email" placement="top" />
          </div>
        </div>
        {/* email invite */}
        <div className="col-3">
          <div className="component-title"> Email Invite Input</div>
          <div>
            <EmailInviteInput
              onChange={(e) => {
                console.log(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="component-title"> PaginationSelect (暫無使用)</div>
          <PaginationSelect
            onChange={(e) => {
              console.log(e);
              setTest(e.pageSize);
            }}
            showQuickJumper={true}
            total={450}
            defaultCurrent={1}
          />
        </div>
        <div className="col-12">
          <div className="component-title"> TablePagination</div>
          <div className="table-pagination">
            <TablePagination
              page={null}
              pageSize={null}
              onChange={(e) => {
                console.log(e);
              }}
              total={450}
            />
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12 title">Toast 項目</div>
        <div className="col-12 d-flex">
          <div>
            <IconButton
              imgName="toast-success1-icon.svg"
              text="right Success"
              className="secondary"
              onClick={() => {
                successAlert("ESG報告書解析完成");
              }}
            />
          </div>
          <div>
            <IconButton
              imgName="toast-error1-icon.svg"
              text="right Error"
              className="secondary"
              onClick={() => {
                errorAlert("匯出失敗");
              }}
            />
          </div>
          <div>
            <IconButton
              imgName="toast-success2-icon.svg"
              text="center Success"
              className="secondary"
              onClick={() => {
                success2Alert("已複製至剪貼簿");
              }}
            />
          </div>
          <div>
            <IconButton
              imgName="toast-error2-icon.svg"
              text="center Error"
              className="secondary"
              onClick={() => {
                error2Alert("複製資料失敗");
              }}
            />
          </div>
        </div>
      </div>

      {/* Accordion */}
      <div className="row mt-5">
        <div className="col-12 title">Accordion</div>
        <div className="col-12">
          <div className="col-3">
            <div className="component-title">Basic Accordion</div>
            <BasicAccordion />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ToolComponent;
