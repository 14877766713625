//* ---------------------------- React start ---------------------------- *//
import {
  FC,
  useState,
  useEffect,
  useRef,
  ReactNode,
  memo,
  Fragment,
  createRef,
} from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import Sortable from "sortablejs";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./Edit.module.scss";
import { OptionModel } from "models/baseModel";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import QuestionnaireService from "services/admin/questionnaireService";
import {
  QuestionnaireSettingDataModel,
  ControlModel,
  ControlOptionModel,
  QuestionnaireSettingListRequestModel,
  FileCorrespondModel,
} from "models/admin/questionnaireModel";
import { success2Alert, error2Alert } from "utils/otherToast";
import BackTitle from "components/base/BackTitle/BackTitle";
import Dropdown from "components/base/Dropdown/Dropdown";
import NormalInput from "components/base/NormalInput/NormalInput";
import NormalTextarea from "components/base/NormalTextarea/NormalTextarea";
import { CommonService } from "services/common/commonService";
import IconButton from "components/button/IconButton/IconButton";
import IndicatorService from "services/admin/indicatorService";
import CustDropdown from "components/base/Dropdown/Dropdown";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import BootstrapDropdown from "react-bootstrap/Dropdown";
import FollowingControlSettingModal, {
  FollowingControlSettingModalProps,
  FollowingControlSettingModalRefs,
} from "components/otherModule/FollowingControlSettingModal/FollowingControlSettingModal";
import { HttpClient } from "services/httpClient";
import { ResponseCode } from "models/responseCodeModel";
import { usePermissionContext } from "context/PermissionProvider";
import { QuestionnaireManageActionsPermissionCodes } from "models/auth/permissionModel";
//* ---------------------------- local end ---------------------------- *//
const Edit = () => {
  //* ---------------------------- state start ---------------------------- *//
  //- params
  const params = useParams();
  //- location
  const location = useLocation();
  //- navigate
  const navigate = useNavigate();
  //- httpClient
  const httpClient = new HttpClient();
  //- questionnaireListRequestModel
  const questionnaireListRequestModel: QuestionnaireSettingListRequestModel =
    location?.state?.questionnaireListRequestModel;
  //- questionnaireId
  const questionnaireId = params.questionnaireId;
  const [yearOptions] = useState<OptionModel[]>(() => {
    const yearOptionsList = [];
    const today = new Date();
    for (let i = today.getFullYear(); i >= 2021; i--) {
      yearOptionsList.push({
        id: i.toString(),
        text: `${i.toString()} 年度`,
        enumKey: i,
      });
    }
    return [...yearOptionsList];
  });
  //- questionnaireSettingData
  const [questionnaireSettingData, setQuestionnaireSettingData] =
    useState<QuestionnaireSettingDataModel>(
      new QuestionnaireSettingDataModel()
    );
  //- questionnaireSettingDataRef
  const questionnaireSettingDataRef = useRef(questionnaireSettingData);
  //- hasMultipleData is disabled
  const [hasMultipleDataDisabled, setHasMultipleDataDisabled] = useState<
    boolean | undefined
  >();
  //- controlTypeOptions
  const [controlTypeOptions, setControlTypeOptions] = useState<OptionModel[]>(
    []
  );
  //- useFollowingControlSettingModalRef
  const useFollowingControlSettingModalRef =
    useRef<FollowingControlSettingModalRefs>(null);
  //- hasOptionsControlTypeList
  const hasOptionsControlTypeList: string[] = [
    "radio",
    "checkbox",
    "select",
    "select-multi",
  ];
  //- hasAdditionalFieldsControlTypeList
  const hasAdditionalFieldsControlTypeList: string[] = ["number"];
  //- newSetControlId
  const newSetControlId: string = "00000000-0000-0000-0000-000000000000";
  //- FileCorrespondData
  const [fileCorrespondData, setFileCorrespondData] = useState<
    FileCorrespondModel[]
  >([]);
  //- fileInputRef
  const fileInputRef = useRef<HTMLInputElement>(null);
  //- handleControlUniqueKey
  const [handleControlUniqueKey, setHandleControlUniqueKey] =
    useState<string>("");
  //- fileValue
  const [fileValue, setFileValue] = useState("");
  //- usePermissionContext
  const { PermissionService } = usePermissionContext();
  //- PermissionService
  const { detectEveryActionPermission } = PermissionService;
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getQuestionnaireSettingData
  const {
    mutate: getQuestionnaireSettingDataMutate,
    isPending: getQuestionnaireSettingDataIsPending,
  } = useMutation({
    mutationFn: (id: string) =>
      QuestionnaireService.getQuestionnaireSettingData(id),
    onSuccess: (res) => {
      console.log("getQuestionnaireSettingData", res);
      if (res.code === 200 && res.success) {
        // success2Alert(res.message);
        //- fileCorrespondData init
        setFileCorrespondData([]);
        //- questionnairSetting init
        const data: QuestionnaireSettingDataModel = res.data;
        data.controls.forEach((control) => {
          //- set webuse uniqueKey
          control.uniqueKey = uuid();
          if (control.options) {
            control.options.forEach((option) => {
              option.uniqueKey = uuid();
            });
          }
          //! test set hasImage
          // control.hasImage = control.imageData ? true : false;
        });
        setQuestionnaireSettingData(data);
        if (res.data.hasMultipleData) {
          setHasMultipleDataDisabled(true);
        }
        questionnaireListRequestModel.years = [data.year!];
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("getQuestionnaireSettingData", err);
      error2Alert("執行失敗");
    },
  });

  //- saveQuestionnaireSettingData
  const {
    mutate: saveQuestionnaireSettingDataMutate,
    isPending: saveQuestionnaireSettingDataIsPending,
  } = useMutation({
    mutationFn: (request: { id: string; data: FormData }) =>
      QuestionnaireService.saveQuestionnaireSettingData(
        request.id,
        request.data!
      ),
    onSuccess: (res) => {
      console.log("saveQuestionnaireSettingData", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        getQuestionnaireSettingDataMutate(questionnaireId!);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("saveQuestionnaireSettingData", err);
      error2Alert("執行失敗");
    },
  });

  //- addQuestionnaireSettingData
  const {
    mutate: addQuestionnaireSettingDataMutate,
    isPending: addQuestionnaireSettingDataIsPending,
  } = useMutation({
    mutationFn: (data: FormData) =>
      QuestionnaireService.addQuestionnaireSettingData(data),
    onSuccess: (res) => {
      console.log("addQuestionnaireSettingData", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        navigate(`/admin/questionnaire/setting`, {
          state: {
            questionnaireListRequestModel: {
              ...questionnaireListRequestModel,
              ...{ years: [questionnaireSettingData.year!] },
            },
          },
        });
        // getQuestionnaireListMutate(questionnaireListRequestModel);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("addQuestionnaireSettingData", err);
      error2Alert("執行失敗");
    },
  });

  //- getControlTypeOptions
  const {
    mutate: getControlTypeOptionsMutate,
    isPending: getControlTypeOptionsIsPending,
  } = useMutation({
    mutationFn: () => IndicatorService.indicatorOption(),
    onSuccess: (res) => {
      console.log("getControlTypeOptions", res);
      if (res.code === 200 && res.success) {
        // success2Alert(res.message);
        const controlTypeOptions = res.data?.controls;
        if (controlTypeOptions) {
          setControlTypeOptions(
            controlTypeOptions.map((e: OptionModel) => {
              return { id: e.code, text: e.text };
            })
          );
        }
      } else {
        // error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("getControlTypeOptions", err);
      // error2Alert("執行失敗");
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  //- handleFormChange
  const handleFormChange = (value: string | boolean, key: string) => {
    let formatValue: any = value;
    switch (key) {
      case "year":
        formatValue = yearOptions.find((o) => o.id === value)?.enumKey!;
        console.log(formatValue);
        break;
    }
    setQuestionnaireSettingData((prev) => ({ ...prev, [key]: formatValue }));
  };

  //- sectionAndControlGroupsList
  const sectionAndControlGroupsList = (
    data: ControlModel[] = questionnaireSettingData.controls
  ): ControlModel[][] => {
    const sectionAndControlGroups: ControlModel[][] = [];
    let tempControls: ControlModel[] = [];
    for (const control of data) {
      if (control.ctrType === "section") {
        if (tempControls.length) {
          sectionAndControlGroups.push(tempControls);
          tempControls = [];
        }
      }
      tempControls.push(control);
    }
    if (tempControls.length) {
      sectionAndControlGroups.push(tempControls);
    }
    return sectionAndControlGroups;
  };

  //- addSection
  const addSection = (clickedControl?: ControlModel) => {
    const orginalControls = questionnaireSettingData.controls;
    if (!clickedControl) {
      //> 新增 section 要有一個 section 和一個 control
      const newSection: ControlModel = {
        ...new ControlModel(),
        ...{
          id: uuid(),
          ctrType: "section",
          isBlankSection: false,
          sortIndex: orginalControls.length,
        },
      };
      const newControl: ControlModel = {
        ...new ControlModel(),
        ...{
          id: uuid(),
          sortIndex: orginalControls.length + 1,
        },
      };
      setAndSortControlsInData([...orginalControls, newSection, newControl]);
    } else {
      const groupsList = sectionAndControlGroupsList();
      //> 新增 section 要有一個 section 和一個 control
      //> sortIndex 要在 此 clickedControl 的 section 的下一個
      //> 先計算出要插入的位置
      //> 先找出此 clickedControl 的 section
      let insertIndex = -1;
      for (let i = 0; i < groupsList.length; i++) {
        if (
          groupsList[i].some((e) => e.uniqueKey === clickedControl.uniqueKey)
        ) {
          insertIndex = groupsList[i][groupsList[i].length - 1].sortIndex;
          break;
        }
      }
      if (insertIndex === -1) return;
      const tempControls: ControlModel[] = [];
      for (let i = 0; i < orginalControls.length; i++) {
        if (i < insertIndex) {
          tempControls.push(orginalControls[i]);
        }
        if (i === insertIndex) {
          const newSection: ControlModel = {
            ...new ControlModel(),
            ...{
              id: uuid(),
              ctrType: "section",
              isBlankSection: false,
              sortIndex: insertIndex + 1,
            },
          };
          const newControl: ControlModel = {
            ...new ControlModel(),
            ...{
              id: uuid(),
              sortIndex: insertIndex + 2,
            },
          };
          tempControls.push(orginalControls[i], newSection, newControl);
        }
        if (i > insertIndex) {
          //> sortIndex 要 +2
          tempControls.push({ ...orginalControls[i], sortIndex: i + 2 });
        }
      }
      setAndSortControlsInData(tempControls);
    }
  };

  //- addControl
  const addControl = (clickedControl?: ControlModel) => {
    const orginalControls = questionnaireSettingData.controls;
    if (!clickedControl) {
      //> 新增 section 要有一個 section 和一個 control
      const newSection: ControlModel = {
        ...new ControlModel(),
        ...{
          id: uuid(),
          ctrType: "section",
          isBlankSection: true,
          sortIndex: orginalControls.length,
        },
      };
      const newControl: ControlModel = {
        ...new ControlModel(),
        ...{
          id: uuid(),
          sortIndex: orginalControls.length + 1,
        },
      };
      setAndSortControlsInData([...orginalControls, newSection, newControl]);
    } else {
      const groupsList = sectionAndControlGroupsList();
      //> 新增 section 要有一個 section 和一個 control
      //> sortIndex 要在 此 clickedControl 的 section 的下一個
      //> 先計算出要插入的位置
      //> 先找出此 clickedControl 的 section
      let insertIndex = -1;
      for (let i = 0; i < groupsList.length; i++) {
        if (
          groupsList[i].some((e) => e.uniqueKey === clickedControl.uniqueKey)
        ) {
          insertIndex =
            clickedControl.ctrType === "section"
              ? groupsList[i][groupsList[i].length - 1].sortIndex
              : groupsList[i].find(
                  (e) => e.uniqueKey === clickedControl.uniqueKey
                )!.sortIndex;
          break;
        }
      }
      if (insertIndex === -1) return;
      const tempControls: ControlModel[] = [];
      for (let i = 0; i < orginalControls.length; i++) {
        if (i < insertIndex) {
          tempControls.push(orginalControls[i]);
        }
        if (i === insertIndex) {
          const newControl: ControlModel = {
            ...new ControlModel(),
            ...{
              id: uuid(),
              sortIndex: insertIndex + 1,
            },
          };
          tempControls.push(orginalControls[i], newControl);
        }
        if (i > insertIndex) {
          //> sortIndex 要 +1
          tempControls.push({ ...orginalControls[i], sortIndex: i + 1 });
        }
      }
      setAndSortControlsInData(tempControls);
    }
  };

  //- setAndSortControlsInData
  const setAndSortControlsInData = (data: ControlModel[]) => {
    const sortedData = data.sort((a, b) => a.sortIndex - b.sortIndex);

    //- 去除 isBlankSection true 底下沒有其他 control 的 section
    const groupsList = sectionAndControlGroupsList(sortedData);
    const needFilterSectionIds: string[] = [];
    for (let i = 0; i < groupsList.length; i++) {
      if (groupsList[i].length === 1 && groupsList[i][0].isBlankSection) {
        needFilterSectionIds.push(groupsList[i][0].uniqueKey);
      }
    }
    const filteredData = sortedData.filter(
      (e) => !needFilterSectionIds.includes(e.uniqueKey)
    );
    //- 再重新設定 sortIndex
    const newControls = filteredData.map((control, i) => {
      return { ...control, sortIndex: i };
    });
    setQuestionnaireSettingData((prev) => ({ ...prev, controls: newControls }));
  };

  //- setNewControlsInData
  const setNewControlsInData = (data: ControlModel[]) => {
    //- 去除 isBlankSection true 底下沒有其他 control 的 section
    const groupsList = sectionAndControlGroupsList(data);
    const needFilterSectionIds: string[] = [];
    for (let i = 0; i < groupsList.length; i++) {
      if (groupsList[i].length === 1 && groupsList[i][0].isBlankSection) {
        needFilterSectionIds.push(groupsList[i][0].uniqueKey);
      }
    }
    const filteredData = data.filter(
      (e) => !needFilterSectionIds.includes(e.uniqueKey)
    );
    //- 再重新設定 sortIndex
    const newControls = filteredData.map((control, i) => {
      return { ...control, sortIndex: i };
    });
    // console.warn(newControls);
    setQuestionnaireSettingData((prev) => ({ ...prev, controls: newControls }));
  };

  //- handleSaveQuestionnaireData
  const handleSaveQuestionnaireData = () => {
    if (
      addQuestionnaireSettingDataIsPending ||
      saveQuestionnaireSettingDataIsPending
    )
      return;
    console.log(questionnaireSettingData);
    if (!questionnaireSettingData) return;
    if (!validateQuestionnaireData()) return;
    // console.log("isValid");
    const storageQuestionnaireSettingData = CommonService.deepClone(
      questionnaireSettingData
    );
    storageQuestionnaireSettingData.controls.forEach((control, i) => {
      control.sortIndex = i;
    });
    const storageFormData = new FormData();
    if (fileCorrespondData.length) {
      fileCorrespondData.forEach((item, index) => {
        if (item.file) {
          storageFormData.append(`file[${index}]`, item.file);
        }
      });
      const fileControlIdList = fileCorrespondData.map(
        (item) =>
          storageQuestionnaireSettingData.controls.find(
            (control) => control.uniqueKey === item.controlUniqueKey
          )?.id
      );
      storageFormData.append("fileIds", fileControlIdList.join(","));
    } else {
      storageFormData.append("file", "[]");
      storageFormData.append("fileIds", "[]");
    }

    if (questionnaireId === "-1") {
      console.log("add");
      storageQuestionnaireSettingData.id = uuid();
      storageFormData.append(
        "request",
        JSON.stringify(storageQuestionnaireSettingData)
      );
      addQuestionnaireSettingDataMutate(storageFormData);
    } else {
      console.log("edit");
      storageFormData.append(
        "request",
        JSON.stringify(storageQuestionnaireSettingData)
      );
      saveQuestionnaireSettingDataMutate({
        id: questionnaireId!,
        data: storageFormData,
      });
    }
  };

  //- validateQuestionnaireData
  const validateQuestionnaireData = () => {
    //- state
    console.log(questionnaireSettingData);
    let valid = true;
    let invalidMessage = "";

    //- function
    //> updateInvalidMessage
    const updateInvalidMessage = (message: string) => {
      if (!invalidMessage) {
        valid = false;
        invalidMessage = message;
      }
    };

    //> validateQuestionnaireData
    const validateQuestionnaireData = (field: string, errorMessage: string) => {
      if (!checkedData[field as keyof QuestionnaireSettingDataModel]) {
        updateInvalidMessage(errorMessage);
        (checkedData as any)[`valid_${field}`] = false;
      } else {
        (checkedData as any)[`valid_${field}`] = true;
      }
    };

    //> validateControl
    const validateControl = (
      control: ControlModel,
      field: string,
      errorMessage: string
    ) => {
      const fieldValue = control[field as keyof ControlModel];
      if (
        fieldValue === "" ||
        fieldValue === null ||
        fieldValue === undefined ||
        (typeof fieldValue === "number" && isNaN(fieldValue))
      ) {
        updateInvalidMessage(errorMessage);
        (control as any)[`valid_${field}`] = false;
        if (control.ctrType !== "section") {
          for (let i = 0; i < checkedGroupsList.length; i++) {
            if (
              checkedGroupsList[i].some(
                (e) => e.uniqueKey === control.uniqueKey
              )
            ) {
              checkedGroupsList[i][0].isSectionCollapse = false;
              break;
            }
          }
        }
      } else {
        (control as any)[`valid_${field}`] = true;
      }
    };

    //> validateOptions
    const validateOptions = (control: ControlModel) => {
      if (!control.options?.length) {
        updateInvalidMessage("請新增選項");
        control.valid_control = false;
        for (let i = 0; i < checkedGroupsList.length; i++) {
          if (
            checkedGroupsList[i].some((e) => e.uniqueKey === control.uniqueKey)
          ) {
            checkedGroupsList[i][0].isSectionCollapse = false;
            break;
          }
        }
      } else {
        control.valid_control = true;
        control.options?.forEach((option) => {
          if (!option.text) {
            updateInvalidMessage("請輸入選項名稱");
            option.valid_text = false;
            for (let i = 0; i < checkedGroupsList.length; i++) {
              if (
                checkedGroupsList[i].some(
                  (e) => e.uniqueKey === control.uniqueKey
                )
              ) {
                checkedGroupsList[i][0].isSectionCollapse = false;
                break;
              }
            }
          } else {
            option.valid_text = true;
          }
        });
      }
    };

    //- process
    //> validate basic setting
    const checkedData: QuestionnaireSettingDataModel = CommonService.deepClone(
      questionnaireSettingData
    );
    const checkedGroupsList = sectionAndControlGroupsList(checkedData.controls);

    validateQuestionnaireData("year", "請選擇年度");
    validateQuestionnaireData("name", "請輸入名稱");

    if (checkedData.controls?.length) {
      checkedData.controls.forEach((control) => {
        if (control.ctrType === "section") {
          if (!control.isBlankSection) {
            validateControl(control, "name", "請輸入標題名稱");
          }
        } else {
          validateControl(control, "name", "請輸入項目名稱");
          validateControl(control, "ctrType", "請選擇欄位類別");
          if (hasAdditionalFieldsControlTypeList.includes(control.ctrType!)) {
            if (control.ctrType === "number") {
              validateControl(control, "digitNumber", "請輸入小數點後位數");
            }
          }
          if (hasOptionsControlTypeList.includes(control.ctrType!)) {
            validateOptions(control);
          }
        }
      });
    }

    setQuestionnaireSettingData(checkedData);

    console.log(
      "validateQuestionnaireData",
      valid,
      invalidMessage,
      checkedData
    );

    //> alert invalidMessage
    if (!valid && invalidMessage) {
      error2Alert(invalidMessage);
      setTimeout(() => {
        const invalidNode = document.querySelector(".invalid");
        if (invalidNode) {
          //> 滑到致中間
          invalidNode.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 100);
    }

    return valid;
  };

  //- closeAllOptionsDropdown
  const closeAllOptionsDropdown = () => {
    //- 關閉所有的 dropdown (用 dropdownOpen 控制)
    const controls = questionnaireSettingDataRef.current.controls;
    const newControls = controls.map((control) => {
      if (control.options) {
        control.options = control.options.map((option) => {
          return { ...option, dropdownOpen: false };
        });
      }
      return control;
    });
    setAndSortControlsInData(newControls);
  };

  //- cancelFollowingControlSetting
  const cancelFollowingControlSetting = (newControls: ControlModel[]) => {
    setAndSortControlsInData(newControls);
    useFollowingControlSettingModalRef.current?.closeModal();
  };

  //- saveFollowingControlSetting
  const saveFollowingControlSetting = (newControls: ControlModel[]) => {
    setAndSortControlsInData(newControls);
    useFollowingControlSettingModalRef.current?.closeModal();
  };

  //- handleFileChange
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(
      "handleFileChange",
      event.target.files,
      "handleControlUniqueKey",
      handleControlUniqueKey
    );
    if (
      event.target.files &&
      event.target.files.length &&
      event.target.files![0]
    ) {
      setFileCorrespondData((prev) => {
        if (
          prev.find((item) => item.controlUniqueKey === handleControlUniqueKey)
        ) {
          return prev.map((item) => {
            if (item.controlUniqueKey === handleControlUniqueKey) {
              return {
                controlUniqueKey: item.controlUniqueKey,
                file: event.target.files![0],
              };
            }
            return item;
          });
        }
        return [
          ...prev,
          {
            controlUniqueKey: handleControlUniqueKey,
            file: event.target.files![0],
          },
        ];
      });
      const newControls = questionnaireSettingDataRef.current?.controls.map(
        (control: ControlModel) => {
          if (control.uniqueKey === handleControlUniqueKey) {
            return {
              ...control,
              isUploadImage: true,
              hasImage: false,
              imageData: undefined,
            };
          }
          return control;
        }
      );
      setAndSortControlsInData(newControls);
      // //- reset file input
      // event.target.value = "";
      setFileValue("");
    }
    // // 更新文件输入的 value 状态
    // setFileValue(event.target.value);
  };

  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  //- basicSettingNode
  const basicSettingNode = (): ReactNode => {
    return (
      <div className="basic box">
        <div className="title">基本設定：</div>
        <div className="basicItem">
          <Dropdown
            placeholder={"年度*"}
            valid={questionnaireSettingData?.valid_year}
            defaultId={questionnaireSettingData?.year?.toString() || null}
            options={yearOptions}
            isFloatTitle={true}
            onChange={(e) => handleFormChange(e!, "year")}
          />
        </div>
        <div className="basicItem">
          <NormalInput
            isFloatTitle={true}
            valid={questionnaireSettingData?.valid_name}
            defaultValue={questionnaireSettingData?.name || ""}
            placeholder={"名稱*"}
            onChange={(e) => handleFormChange(e, "name")}
          />
        </div>
        <div className="basicItem">
          <NormalTextarea
            isFloatTitle={true}
            defaultValue={questionnaireSettingData?.comment || ""}
            valid={true}
            placeholder="備註"
            onChange={(e) => handleFormChange(e, "comment")}
          />
        </div>
        <div className="basicItem">
          <div className="setting-checkbox">
            <label className={`custom-checkbox`}>
              <input
                type="checkbox"
                disabled={hasMultipleDataDisabled}
                checked={questionnaireSettingData?.hasMultipleData || false}
                onChange={(e) =>
                  handleFormChange(e.target.checked, "hasMultipleData")
                }
              />
              <span>可建立多筆資料</span>
            </label>
          </div>
        </div>
      </div>
    );
  };

  //- advancedSettingNode
  const advancedSettingNode = (): ReactNode => {
    return (
      <div className="advanced box">
        <div className="title">進階設定：</div>
        {/* {addControlNode()} */}
        <div className="control-list handle-sort-list-container">
          {questionnaireSettingData.controls.length
            ? settingQuestionnaireDataNode()
            : addControlNode()}
        </div>
      </div>
    );
  };

  //- addControlNode
  const addControlNode = (control?: ControlModel): ReactNode => {
    //* ---------------------------- component-function start ---------------------------- *//
    //* ---------------------------- component-function start ---------------------------- *//
    return (
      <div className="add-control-outblock">
        <div className={`add-control-block`}>
          <div className="button-block">
            <IconButton
              imgName="add-icon.svg"
              text="新增群組標題"
              className="secondary"
              onClick={() => addSection(control)}
            />
            <IconButton
              imgName="add-bg-icon.svg"
              text="新增欄位"
              className="default"
              onClick={() => addControl(control)}
            />
          </div>
        </div>
      </div>
    );
  };

  //- settingQuestionnaireDataNode
  const settingQuestionnaireDataNode = (): ReactNode => {
    //* ---------------------------- component-function start ---------------------------- *//
    // console.log(sectionAndControlGroupsList());
    //* ---------------------------- component-function end ---------------------------- *//
    return (
      <>
        {sectionAndControlGroupsList().map((group, index) => {
          return <Fragment key={`${index}`}>{sectionNode(group)}</Fragment>;
        })}
      </>
    );
  };

  //- controlOperatorNode
  const controlOperatorNode = (control: ControlModel): ReactNode => {
    //* ---------------------------- component-function start ---------------------------- *//
    //- handleDeleteControl
    const handleDeleteControl = () => {
      if (control.ctrType === "section") {
        const groupsList = sectionAndControlGroupsList();
        for (let i = 0; i < groupsList.length; i++) {
          if (groupsList[i].some((e) => e.uniqueKey === control.uniqueKey)) {
            setAndSortControlsInData(
              questionnaireSettingData.controls.filter(
                (e) =>
                  !groupsList[i].map((r) => r.uniqueKey).includes(e.uniqueKey)
              )
            );
            break;
          }
        }
      } else {
        setAndSortControlsInData(
          questionnaireSettingData.controls.filter(
            (e) => e.uniqueKey !== control.uniqueKey
          )
        );
      }
    };

    //- handleCopyControl
    const handleCopyControl = () => {
      const orginalControls = questionnaireSettingData.controls;
      const tempControls: ControlModel[] = [];
      if (control.ctrType === "section") {
        const groupsList = sectionAndControlGroupsList();
        let insertIndex = -1;
        for (let i = 0; i < groupsList.length; i++) {
          if (groupsList[i].some((e) => e.uniqueKey === control.uniqueKey)) {
            insertIndex = groupsList[i][groupsList[i].length - 1].sortIndex;
            break;
          }
        }
        if (insertIndex === -1) return;
        const copySection: ControlModel[] = CommonService.deepClone(
          groupsList.find((e) => e.some((r) => r.sortIndex === insertIndex))!
        ).map((e, i) => {
          return {
            ...e,
            id: uuid(),
            sortIndex: insertIndex + i + 1,
            uniqueKey: uuid(),
            isUploadImage: false,
          };
        });
        for (let i = 0; i < orginalControls.length; i++) {
          if (i < insertIndex) {
            tempControls.push(orginalControls[i]);
          }
          if (i === insertIndex) {
            tempControls.push(orginalControls[i], ...copySection);
          }
          if (i > insertIndex) {
            //> sortIndex 要 copySection.length
            tempControls.push({
              ...orginalControls[i],
              sortIndex: i + copySection.length,
            });
          }
        }
      } else {
        for (let i = 0; i < orginalControls.length; i++) {
          if (i < control.sortIndex) {
            tempControls.push(orginalControls[i]);
          }
          if (i === control.sortIndex) {
            const newControl: ControlModel = {
              ...CommonService.deepClone(control),
              ...{
                id: uuid(),
                sortIndex: control.sortIndex + 1,
                uniqueKey: uuid(),
                isUploadImage: false,
              },
            };
            tempControls.push(orginalControls[i], newControl);
          }
          if (i > control.sortIndex) {
            //> sortIndex 要 +1
            tempControls.push({ ...orginalControls[i], sortIndex: i + 1 });
          }
        }
      }
      setAndSortControlsInData(tempControls);
    };

    //- moveBtnStyle
    const moveBtnStyle = (direction: "up" | "down"): boolean => {
      const groupsList = sectionAndControlGroupsList();
      let isMove: boolean = true;
      for (let i = 0; i < groupsList.length; i++) {
        if (groupsList[i].some((e) => e.uniqueKey === control.uniqueKey)) {
          if (direction === "up") {
            isMove =
              control.ctrType === "section"
                ? i !== 0
                : groupsList[i].findIndex(
                    (e) => e.uniqueKey === control.uniqueKey
                  ) !== 1;
          }
          if (direction === "down") {
            isMove =
              control.ctrType === "section"
                ? i !== groupsList.length - 1
                : groupsList[i].findIndex(
                    (e) => e.uniqueKey === control.uniqueKey
                  ) !==
                  groupsList[i].length - 1;
          }
          break;
        }
      }
      return isMove;
    };

    //- handleMoveControl
    const handleMoveControl = (direction: "up" | "down") => {
      if (!moveBtnStyle(direction)) return;
      const groupsList = CommonService.deepClone(sectionAndControlGroupsList());
      let insertIndex = -1;
      let needMoveIndex = -1;
      let handleGroupIndex = -1;
      for (let i = 0; i < groupsList.length; i++) {
        if (groupsList[i].some((e) => e.uniqueKey === control.uniqueKey)) {
          if (control.ctrType === "section") {
            needMoveIndex = i;
            insertIndex = direction === "up" ? i - 1 : i + 1;
          }
          if (control.ctrType !== "section") {
            handleGroupIndex = i;
            needMoveIndex = groupsList[i].findIndex(
              (e) => e.uniqueKey === control.uniqueKey
            );
            insertIndex =
              direction === "up" ? needMoveIndex - 1 : needMoveIndex + 1;
          }
          break;
        }
      }
      if (insertIndex === -1 || needMoveIndex === -1) return;
      if (control.ctrType === "section") {
        const moveSection = groupsList.splice(needMoveIndex, 1, []);
        const insertSection = groupsList.splice(insertIndex, 1, ...moveSection);
        groupsList.splice(needMoveIndex, 1, ...insertSection);
        console.log(groupsList);
      }
      if (control.ctrType !== "section") {
        if (handleGroupIndex === -1) return;
        const moveControl = groupsList[handleGroupIndex].splice(
          needMoveIndex,
          1,
          null as any
        );
        const insertControl = groupsList[handleGroupIndex].splice(
          insertIndex,
          1,
          ...moveControl
        );
        groupsList[handleGroupIndex].splice(needMoveIndex, 1, ...insertControl);
      }
      const newControls = groupsList.flat();
      setNewControlsInData(newControls);
    };
    //* ---------------------------- component-function end ---------------------------- *//
    return (
      <div className="operator">
        <div className="left">
          <img
            className="drag glyphicon-move"
            src="/assets/images/buttonIcon/hambur-icon.svg"
            alt="hambur-icon.svg"
          />
          <span className="typeName">
            {control.ctrType === "section" ? "標題" : "項目"}
          </span>
        </div>
        <div className="right">
          <img
            src="/assets/images/buttonIcon/top-arrow-icon.svg"
            alt="top-arrow-icon.svg"
            className={`moveBtn ${!moveBtnStyle("up") ? "disabled" : ""}`}
            onClick={() => handleMoveControl("up")}
          />
          <img
            src="/assets/images/buttonIcon/down-arrow-icon.svg"
            alt="down-arrow-icon.svg"
            className={`moveBtn ${!moveBtnStyle("down") ? "disabled" : ""}`}
            onClick={() => handleMoveControl("down")}
          />
          <div className="divideLine" />
          <img
            src="/assets/images/buttonIcon/copy-icon.svg"
            alt="copy-icon.svg"
            onClick={() => handleCopyControl()}
          />
          <img
            src="/assets/images/buttonIcon/trash-icon.svg"
            alt="trash-icon.svg"
            onClick={() => handleDeleteControl()}
          />
        </div>
      </div>
    );
  };

  //- sectionNode
  const sectionNode = (group: ControlModel[]): ReactNode => {
    //* ---------------------------- component-function start ---------------------------- *//
    //- handleSectionFieldChange
    const handleSectionFieldChange = (
      value: string | number | boolean,
      key: string,
      selectedControl: ControlModel
    ) => {
      const newControls = questionnaireSettingDataRef.current?.controls.map(
        (control) => {
          if (control.uniqueKey === selectedControl.uniqueKey) {
            return { ...control, [key]: value };
          }
          return control;
        }
      );
      setAndSortControlsInData(newControls);
    };

    //- getGroupIndex
    const getGroupIndex = (): number => {
      let groupIndex = -1;
      const groupsList = sectionAndControlGroupsList();
      for (let i = 0; i < groupsList.length; i++) {
        if (groupsList[i].some((e) => e.uniqueKey === group[0].uniqueKey)) {
          groupIndex = i;
          break;
        }
      }
      return groupIndex;
    };
    //* ---------------------------- component-function end ---------------------------- *//
    if (group[0].isBlankSection) {
      return (
        <>
          <div
            className={`control-child-box handle-sort-child-container group-index-${getGroupIndex()}`}
          >
            {group.map((control, controlIndex) => {
              if (!controlIndex) return null;
              return (
                <Fragment key={control.uniqueKey}>
                  {controlNode(control)}
                </Fragment>
              );
            })}
          </div>
        </>
      );
    }
    return (
      <>
        <div className="control-box">
          <div className="section-outbox">
            <div className="section-inbox">
              {controlOperatorNode(group[0])}
              <div className="section-content-box">
                <div className="item-box">
                  <NormalInput
                    isFloatTitle={false}
                    placeholder={"標題名稱*"}
                    valid={group[0].valid_name}
                    defaultValue={group[0].name || ""}
                    onChange={(e) =>
                      handleSectionFieldChange(e, "name", group[0])
                    }
                  />
                </div>
                <div className="item-box">
                  <NormalInput
                    isFloatTitle={false}
                    placeholder={"欄位說明"}
                    defaultValue={group[0].comment || ""}
                    onChange={(e) =>
                      handleSectionFieldChange(e, "comment", group[0])
                    }
                  />
                </div>
              </div>
              <div
                className="collapse-section-btn"
                onClick={() =>
                  handleSectionFieldChange(
                    !group[0].isSectionCollapse,
                    "isSectionCollapse",
                    group[0]
                  )
                }
              >
                <img
                  alt="cancel"
                  src={`/assets/images/buttonIcon/${
                    group[0].isSectionCollapse
                      ? "down-arrow-icon.svg"
                      : "top-arrow-icon.svg"
                  }`}
                />
                {`${group[0].isSectionCollapse ? "展開" : "縮合"} ${
                  group.length - 1
                } 個項目`}
              </div>
            </div>
            {addControlNode(group[0])}
          </div>
          <div
            className={`control-child-box handle-sort-child-container group-index-${getGroupIndex()}`}
          >
            {group.map((control, controlIndex) => {
              if (!controlIndex) return null;
              return (
                <Fragment key={control.uniqueKey}>
                  {controlNode(control)}
                </Fragment>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  //- controlNode
  const controlNode = (control: ControlModel): ReactNode => {
    //* ---------------------------- component-state start ---------------------------- *//
    //* ---------------------------- component-state start ---------------------------- *//
    //* ---------------------------- component-function start ---------------------------- *//
    //- isParentSectionCollapse
    const isParentSectionCollapse = (control: ControlModel): boolean => {
      let isCollapse = false;
      //> 判斷方式是看該 control 順序的上一個 section 是否是縮合的
      const controls = questionnaireSettingData.controls;
      for (let i = control.sortIndex - 1; i >= 0; i--) {
        if (controls[i]?.ctrType === "section") {
          if (controls[i].isBlankSection) break;
          isCollapse = controls[i].isSectionCollapse;
          break;
        }
      }
      return isCollapse;
    };

    //- handleControlFieldChange
    const handleControlFieldChange = (
      value: string | number | boolean,
      key: string,
      selectedControl: ControlModel
    ) => {
      const newControls = questionnaireSettingDataRef.current?.controls.map(
        (control: ControlModel) => {
          if (control.uniqueKey === selectedControl.uniqueKey) {
            //> 如果key是ctrType，要讓control model 重新初始化
            if (key === "ctrType") {
              //> new ControlModel() 裡面不是undefined的 要從舊的 control 取
              const newControl: any = new ControlModel();
              Object.keys(newControl).forEach((k) => {
                const key = k as keyof ControlModel;
                if (newControl[key] !== undefined) {
                  newControl[key] = control[key];
                }
              });
              if (hasOptionsControlTypeList.includes(value as string)) {
                //> 如果是有選項的控制項，要初始化選項陣列
                newControl.options = [];
              }
              return { ...newControl, [key]: value };
            }
            //> other
            // 自訂單位
            if(key === 'isCustomUnit' && value !== true){
              control.unit = '';
            }
            return { ...control, [key]: value };
          }
          return control;
        }
      );
      setAndSortControlsInData(newControls);
    };

    //- handleControlOptionsFieldChange
    const handleControlOptionsFieldChange = (
      value: string | number | boolean | null,
      key: string,
      selectedControl: ControlModel,
      selectedOption: ControlOptionModel | null
    ) => {
      console.log(value, key, selectedControl, selectedOption);
      //> add option
      if (key === "addOption") {
        const newControls = questionnaireSettingDataRef.current?.controls.map(
          (control: ControlModel) => {
            if (control.uniqueKey === selectedControl.uniqueKey) {
              const newOption = {
                ...new ControlOptionModel(),
                ...{ id: uuid() },
              };
              return {
                ...control,
                options: [...control.options!, ...[newOption]],
              };
            }
            return control;
          }
        );
        setAndSortControlsInData(newControls);
        return;
      }
      //> remove option
      if (key === "removeOption") {
        const newControls = questionnaireSettingDataRef.current?.controls.map(
          (control: ControlModel) => {
            if (control.uniqueKey === selectedControl.uniqueKey) {
              const newOptions = control.options!.filter(
                (option) => option.uniqueKey !== selectedOption!.uniqueKey
              );
              return { ...control, options: newOptions };
            }
            return control;
          }
        );
        setAndSortControlsInData(newControls);
        return;
      }
      const newControls = questionnaireSettingDataRef.current?.controls.map(
        (control: ControlModel) => {
          if (control.uniqueKey === selectedControl.uniqueKey) {
            const newOptions = control.options!.map((option) => {
              if (option.uniqueKey === selectedOption!.uniqueKey) {
                return { ...option, [key]: value };
              }
              //- 預設選項(radio,select,checkbox,select-multi) radio,select 只能有一個
              if (key === "check") {
                if (
                  ["radio", "select"].includes(control.ctrType!) &&
                  option.uniqueKey !== selectedOption!.uniqueKey &&
                  value
                ) {
                  return { ...option, [key]: !value };
                }
              }
              return option;
            });
            return { ...control, options: newOptions };
          }
          return control;
        }
      );
      setAndSortControlsInData(newControls);
    };

    //- toggleFollowingControlSetting
    const toggleFollowingControlSetting = (option: ControlOptionModel) => {
      closeAllOptionsDropdown();
      useFollowingControlSettingModalRef.current?.openModal(
        questionnaireSettingDataRef.current.controls,
        control,
        option
      );
    };

    //* ---------------------------- component-function end ---------------------------- *//
    //* ---------------------------- component-subComponent start ---------------------------- *//
    //- insertControlBtn
    const insertControlBtn = (): ReactNode => {
      const groupsList = sectionAndControlGroupsList();
      let isInsert: boolean | undefined;
      let isbeforeSectionAllBlankSection = true;
      for (let i = 0; i < groupsList.length; i++) {
        if (groupsList[i].some((e) => e.uniqueKey === control.uniqueKey)) {
          // 前面全部的 section 是否全是 blankSection
          isbeforeSectionAllBlankSection = groupsList
            .map((o, p) => {
              if (p <= i) {
                return o[0].isBlankSection;
              }
              return true;
            })
            .every((e) => e);
          isInsert = groupsList[i][0].isBlankSection;
          break;
        }
      }
      if (isbeforeSectionAllBlankSection) return null;
      //- handleInsertControl
      const handleInsertControl = (isInsert: boolean) => {
        if (isInsert === undefined) return;
        const handleGroupsList = sectionAndControlGroupsList();
        const orginalControls = questionnaireSettingData.controls;
        const tempControls: ControlModel[] = [];
        if (isInsert) {
          let insertIndex = -1;
          for (let i = 0; i < handleGroupsList.length; i++) {
            if (
              handleGroupsList[i].some((e) => e.uniqueKey === control.uniqueKey)
            ) {
              //- 尋找前面是 isBlankSection false 的 section group
              for (let p = i - 1; p >= 0; p--) {
                if (!handleGroupsList[p][0].isBlankSection) {
                  insertIndex =
                    handleGroupsList[p][handleGroupsList[p].length - 1]
                      .sortIndex;
                  break;
                }
              }
              break;
            }
          }
          if (insertIndex === -1) return;
          for (let i = 0; i < orginalControls.length; i++) {
            if (orginalControls[i].uniqueKey === control.uniqueKey) {
              orginalControls[i].needRemove = true;
            }
            if (i < insertIndex) {
              tempControls.push(orginalControls[i]);
            }
            if (i === insertIndex) {
              const copyControl: ControlModel = {
                ...CommonService.deepClone(control),
                ...{
                  needRemove: false,
                  sortIndex: insertIndex + 1,
                },
              };
              tempControls.push(orginalControls[i], copyControl);
            }
            if (i > insertIndex) {
              //> sortIndex 要 +1
              tempControls.push({ ...orginalControls[i], sortIndex: i + 1 });
            }
          }
        } else {
          let insertIndex = -1;
          let isNeedNewSection = false;
          for (let i = 0; i < handleGroupsList.length; i++) {
            if (
              handleGroupsList[i].some((e) => e.uniqueKey === control.uniqueKey)
            ) {
              isNeedNewSection = handleGroupsList[i + 1]
                ? !handleGroupsList[i + 1][0].isBlankSection
                : true;
              insertIndex = isNeedNewSection
                ? handleGroupsList[i][handleGroupsList[i].length - 1].sortIndex
                : handleGroupsList[i + 1][handleGroupsList[i + 1].length - 1]
                    .sortIndex;
              break;
            }
          }
          if (insertIndex === -1) return;
          if (!isNeedNewSection) {
            for (let i = 0; i < orginalControls.length; i++) {
              if (orginalControls[i].uniqueKey === control.uniqueKey) {
                orginalControls[i].needRemove = true;
              }
              if (i < insertIndex) {
                tempControls.push(orginalControls[i]);
              }
              if (i === insertIndex) {
                const copyControl: ControlModel = {
                  ...CommonService.deepClone(control),
                  ...{
                    needRemove: false,
                    sortIndex: insertIndex + 1,
                  },
                };
                tempControls.push(orginalControls[i], copyControl);
              }
              if (i > insertIndex) {
                //> sortIndex 要 +1
                tempControls.push({ ...orginalControls[i], sortIndex: i + 1 });
              }
            }
          }
          if (isNeedNewSection) {
            for (let i = 0; i < orginalControls.length; i++) {
              if (orginalControls[i].uniqueKey === control.uniqueKey) {
                orginalControls[i].needRemove = true;
              }
              if (i < insertIndex) {
                tempControls.push(orginalControls[i]);
              }
              if (i === insertIndex) {
                const newSection: ControlModel = {
                  ...new ControlModel(),
                  ...{
                    id: uuid(),
                    ctrType: "section",
                    isBlankSection: true,
                    sortIndex: insertIndex + 1,
                  },
                };
                const copyControl: ControlModel = {
                  ...CommonService.deepClone(control),
                  ...{
                    needRemove: false,
                    sortIndex: insertIndex + 2,
                  },
                };
                tempControls.push(orginalControls[i], newSection, copyControl);
              }
              if (i > insertIndex) {
                //> sortIndex 要 +2
                tempControls.push({ ...orginalControls[i], sortIndex: i + 2 });
              }
            }
          }
        }
        if (!tempControls.length) return;
        const filterOldControlControls = tempControls.filter(
          (e) => !e.needRemove
        );
        setAndSortControlsInData(filterOldControlControls);
      };
      return (
        <div
          className={`insert-control-btn ${isInsert ? "insert" : ""}`}
          onClick={() => handleInsertControl(isInsert!)}
        >
          <img
            alt="white-arrow-icon.svg"
            src={`/assets/images/buttonIcon/white-arrow-icon.svg`}
          />
        </div>
      );
    };

    //- controlTypeDetailBoxNode
    const controlTypeDetailBoxNode = (control: ControlModel): ReactNode => {
      const noDetailControlTypeList: string[] = [
        "text",
        "text-long",
        "textarea",
        "date",
        "time",
        "code-table",
        "project-info",
        "customer-info",
        "email",
        "number",
      ];
      if (!control.ctrType || noDetailControlTypeList.includes(control.ctrType))
        return null;
      if (
        control.ctrType &&
        hasOptionsControlTypeList.includes(control.ctrType) &&
        control.options
      ) {
        //- 預設選項 check (radio, select, checkbox, select-multi)
        const hasCheckOfOptionsControlTypeList: string[] = [
          "radio",
          "select",
          "checkbox",
          "select-multi",
        ];
        //- 接文字區塊 isExtraText (radio, select, checkbox)
        const hasExtraTextOfOptionsControlTypeList: string[] = [
          "radio",
          "select",
          "checkbox",
        ];
        //- 接題 followingControl (radio, select, checkbox, select-multi)
        const hasFollowingControlOfOptionsControlTypeList: string[] = [
          "radio",
          "select",
          "checkbox",
          "select-multi",
        ];
        //- 目前沒 跳題 skipControl ()
        const hasSkipControlOfOptionsControlTypeList: string[] = [];
        //- optionSettingDetailNode
        const optionSettingDetailNode = (
          option: ControlOptionModel
        ): ReactNode => {
          const { check, isExtraText, followingControlIds } = option;
          const check_SettingText = "預設選項";
          const isExtraText_SettingText = "接文字區塊";
          const followingControl_SettingText = "接題";
          if (
            !check &&
            !isExtraText &&
            !(followingControlIds && followingControlIds.length)
          ) {
            return null;
          }
          let displayText = "";
          if (check) {
            displayText += check_SettingText;
          }
          if (isExtraText) {
            displayText += displayText
              ? `、${isExtraText_SettingText}`
              : isExtraText_SettingText;
          }
          if (followingControlIds && followingControlIds.length) {
            displayText += displayText
              ? `、${followingControl_SettingText}：`
              : `${followingControl_SettingText}：`;
            const followingControlNames = followingControlIds.map((id) => {
              const control = questionnaireSettingData.controls.find(
                (e) => e.id === id
              );
              return control?.name || "untitled";
            });
            displayText += followingControlNames.join(" / ");
          }
          return (
            <div className="option-setting-detail">
              <span>{displayText}</span>
            </div>
          );
        };
        return (
          <div className="control-type-deatil-box">
            <div
              className={`handle-sort-options-container control-sortIndex-${control.sortIndex}`}
            >
              {control.options.map((option, index) => {
                return (
                  <div className="item-box" key={option.uniqueKey}>
                    <div className="main-box">
                      <img
                        className="drag glyphicon-move mr-img"
                        src="/assets/images/buttonIcon/hambur-icon.svg"
                        alt="hambur-icon.svg"
                      />
                      <div className="input-box">
                        <NormalInput
                          isFloatTitle={true}
                          placeholder={"選項名稱*"}
                          defaultValue={option.text || ""}
                          valid={option.valid_text}
                          onChange={(e) =>
                            handleControlOptionsFieldChange(
                              e,
                              "text",
                              control,
                              option
                            )
                          }
                        />
                      </div>
                      <img
                        className="ml-img"
                        alt="job-close-icon.svg"
                        src="/assets/images/buttonIcon/job-close-icon.svg"
                        onClick={() =>
                          handleControlOptionsFieldChange(
                            null,
                            "removeOption",
                            control,
                            option
                          )
                        }
                      />
                      <BootstrapDropdown
                        show={option.dropdownOpen}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <BootstrapDropdown.Toggle
                          as="div"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleControlOptionsFieldChange(
                              !option.dropdownOpen,
                              "dropdownOpen",
                              control,
                              option
                            );
                          }}
                        >
                          <img
                            className="ml-img"
                            alt="more-icon.svg"
                            src="/assets/images/buttonIcon/more-icon.svg"
                          />
                        </BootstrapDropdown.Toggle>
                        <BootstrapDropdown.Menu className="option-dropdown-menu">
                          {/* 預設選項 check (radio, select) */}
                          {hasCheckOfOptionsControlTypeList.includes(
                            control.ctrType
                          ) ? (
                            <BootstrapDropdown.Item
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div
                                className="setting-checkbox"
                                onClick={() => {
                                  handleControlOptionsFieldChange(
                                    !option.check,
                                    "check",
                                    control,
                                    option
                                  );
                                }}
                              >
                                <label className={`custom-checkbox`}>
                                  <input
                                    type="checkbox"
                                    checked={option?.check || false}
                                    onChange={(e) => {}}
                                  />
                                  <span>預設選項</span>
                                </label>
                              </div>
                            </BootstrapDropdown.Item>
                          ) : null}
                          {/* 接文字區塊 isExtraText (radio, select, checkbox) */}
                          {hasExtraTextOfOptionsControlTypeList.includes(
                            control.ctrType
                          ) ? (
                            <BootstrapDropdown.Item
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div
                                className="setting-checkbox"
                                onClick={() => {
                                  handleControlOptionsFieldChange(
                                    !option.isExtraText,
                                    "isExtraText",
                                    control,
                                    option
                                  );
                                }}
                              >
                                <label className={`custom-checkbox`}>
                                  <input
                                    type="checkbox"
                                    checked={option?.isExtraText || false}
                                    onChange={(e) => {}}
                                  />
                                  <span>接文字區塊</span>
                                </label>
                              </div>
                            </BootstrapDropdown.Item>
                          ) : null}
                          {/* 接題 followingControlIds (radio, select, checkbox) */}
                          {hasFollowingControlOfOptionsControlTypeList.includes(
                            control.ctrType
                          ) ? (
                            <BootstrapDropdown.Item
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div
                                className="setting-checkbox"
                                onClick={() => {
                                  toggleFollowingControlSetting(option);
                                }}
                              >
                                <label className={`custom-checkbox`}>
                                  <input
                                    type="checkbox"
                                    checked={
                                      option.followingControlIds?.length
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {}}
                                  />
                                  <span>接題</span>
                                </label>
                              </div>
                            </BootstrapDropdown.Item>
                          ) : null}
                          {/* 跳題 skipControl */}
                          {hasSkipControlOfOptionsControlTypeList.includes(
                            control.ctrType
                          ) ? (
                            <BootstrapDropdown.Item
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div
                                className="setting-checkbox"
                                onClick={() => {}}
                              >
                                <label className={`custom-checkbox`}>
                                  <input
                                    type="checkbox"
                                    checked={option?.isExtraText || false}
                                    onChange={(e) => {}}
                                  />
                                  <span>跳題</span>
                                </label>
                              </div>
                            </BootstrapDropdown.Item>
                          ) : null}
                        </BootstrapDropdown.Menu>
                      </BootstrapDropdown>
                    </div>
                    {optionSettingDetailNode(option)}
                  </div>
                );
              })}
            </div>
            <div className="item-box add-item-box">
              <LinkIconButton
                imgName="add-icon.svg"
                text="新增項目"
                onClick={() => {
                  handleControlOptionsFieldChange(
                    null,
                    "addOption",
                    control,
                    null
                  );
                }}
              />
            </div>
          </div>
        );
      }
    };

    //- numberControlTypeDetailBoxNode
    const numberControlTypeDetailBoxNode = (
      control: ControlModel
    ): ReactNode => {
      if (control.ctrType !== "number") return null;
      return (
        <div className="number-control-type-deatil-box">
          <div className="digitNumber-box">
            <span>小數點後</span>
            <NormalInput
              placeholder="小數點後"
              type="number"
              unit="位"
              valid={control.valid_digitNumber}
              defaultValue={control.digitNumber || ""}
              onChange={(e) =>
                handleControlFieldChange(e, "digitNumber", control)
              }
            />
          </div>
          <div className="setting-checkbox">
            <label className={`custom-checkbox min-w110-p`}>
              <input
                type="checkbox"
                checked={control.excludeNegative || false}
                onChange={(e) =>
                  handleControlFieldChange(
                    e.target.checked,
                    "excludeNegative",
                    control
                  )
                }
              />
              <span>不可為負數</span>
            </label>
          </div>
        </div>
      );
    };

    //- uploadControlCommentImageNode
    const uploadControlCommentImageNode = (
      control: ControlModel
    ): ReactNode => {
      const downloadFileImage = () => {
        const file = fileCorrespondData.find(
          (e) => e.controlUniqueKey === control.uniqueKey
        )?.file;
        if (!file) return;
        //TODO 先用另開視窗下載
        // window.open(URL.createObjectURL(file));
        const url = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = url;
        a.download = file.name;
        a.click();
        URL.revokeObjectURL(url);
      };

      const downloadUrlImage = () => {
        const { imageData } = control;
        if (!imageData) return;
        //TODO 先用另開視窗下載
        // window.open(imageData.url);
        //- url下載回來 轉成 blob 讓user下載
        httpClient.getBlob(imageData.url).then((res) => {
          console.log(res);
          if (res) {
            if (res.status !== ResponseCode.ServerErrorInternal && res.data) {
              const blob = new Blob([res.data], { type: res.data.type });
              const url = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = imageData.fileName;
              a.click();
            } else {
              error2Alert(res.data.message);
            }
          }
        });
      };

      const clearImageData = () => {
        if (
          fileCorrespondData.find(
            (e) => e.controlUniqueKey === control.uniqueKey
          )
        ) {
          const newFileCorrespondData = fileCorrespondData.filter(
            (e) => e.controlUniqueKey !== control.uniqueKey
          );
          setFileCorrespondData(newFileCorrespondData);
        }
        const newControls = questionnaireSettingDataRef.current?.controls.map(
          (e) => {
            if (e.uniqueKey === control.uniqueKey) {
              return {
                ...e,
                isUploadImage: false,
                imageData: undefined,
                hasImage: false,
              };
            }
            return e;
          }
        );
        setAndSortControlsInData(newControls);
      };

      const uploadFileInfoNode = () => {
        if (control.hasImage && control.imageData) {
          return (
            <div className="upload-file-info">
              <span className="name" onClick={() => downloadUrlImage()}>
                {control.imageData.fileName}
              </span>
              <img
                className="clear-btn"
                alt="input-clear-icon.svg"
                src="/assets/images/buttonIcon/input-clear-icon.svg"
                onClick={() => clearImageData()}
              />
            </div>
          );
        }

        if (!control.isUploadImage) return null;
        const file = fileCorrespondData.find(
          (e) => e.controlUniqueKey === control.uniqueKey
        )?.file;
        // console.log(file);
        if (!file) return null;
        return (
          <div className="upload-file-info">
            <span className="name" onClick={() => downloadFileImage()}>
              {file.name}
            </span>
            <img
              className="clear-btn"
              alt="input-clear-icon.svg"
              src="/assets/images/buttonIcon/input-clear-icon.svg"
              onClick={() => clearImageData()}
            />
          </div>
        );
      };

      return (
        <div className="upload-control-comment-image-box">
          <div className="comment-image-text">上傳說明圖片(限一張)</div>
          <div className="upload-box">
            <button
              className="secondary"
              onClick={() => {
                fileInputRef.current?.click();
                setHandleControlUniqueKey(control.uniqueKey);
              }}
            >
              上傳
            </button>
            {uploadFileInfoNode()}
          </div>
        </div>
      );
    };

    //* ---------------------------- component-subComponent end ---------------------------- *//

    if (isParentSectionCollapse(control)) return null;
    return (
      <div className="control-box">
        <div className="control-outbox">
          <div
            className={`control-inbox ${
              control.valid_control === undefined || control.valid_control
                ? ""
                : "invalid"
            }`}
          >
            {controlOperatorNode(control)}
            {insertControlBtn()}
            <div className="control-content-box">
              <div className="left">
                <div className="item-box">
                  <NormalInput
                    isFloatTitle={true}
                    placeholder={"項目名稱*"}
                    valid={control.valid_name}
                    defaultValue={control.name || ""}
                    onChange={(e) =>
                      handleControlFieldChange(e, "name", control)
                    }
                  />
                </div>
                <div className="item-box">
                  <NormalInput
                    isFloatTitle={true}
                    placeholder={"查詢前台顯示名稱"}
                    defaultValue={control.nameInMops || ""}
                    onChange={(e) =>
                      handleControlFieldChange(e, "nameInMops", control)
                    }
                  />
                </div>
                <div className="item-box">
                  <NormalInput
                    isFloatTitle={true}
                    placeholder={"欄位說明"}
                    defaultValue={control.comment || ""}
                    onChange={(e) =>
                      handleControlFieldChange(e, "comment", control)
                    }
                  />
                </div>
                <div className="item-box">
                  {uploadControlCommentImageNode(control)}
                </div>
                <div className="item-box controlType">
                  <div className="controlTypeSelectBox">
                    <CustDropdown
                      placeholder={"欄位類別*"}
                      isFloatTitle={true}
                      options={controlTypeOptions}
                      defaultId={control.ctrType || ""}
                      valid={control.valid_ctrType}
                      onChange={(e) =>
                        handleControlFieldChange(e!, "ctrType", control)
                      }
                    />
                  </div>
                  {numberControlTypeDetailBoxNode(control)}
                </div>
              </div>
              <div className="right">
                <div className="item-box">
                  <div className="setting-checkbox setting-item-box">
                    <label className={`custom-checkbox`}>
                      <input
                        type="checkbox"
                        checked={control?.required || false}
                        onChange={(e) =>
                          handleControlFieldChange(
                            !control.required,
                            "required",
                            control
                          )
                        }
                      />
                      <span>必填欄位</span>
                    </label>
                  </div>
                  <div className="setting-checkbox setting-item-box">
                    <label className={`custom-checkbox`}>
                      <input
                        type="checkbox"
                        checked={control?.isNotShowInMops || false}
                        onChange={(e) =>
                          handleControlFieldChange(
                            !control.isNotShowInMops,
                            "isNotShowInMops",
                            control
                          )
                        }
                      />
                      <span>查詢前台不顯示</span>
                    </label>
                  </div>
                  <div className="setting-checkbox setting-item-box d-block">
                    <div>
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        checked={control?.isCustomUnit || false}
                        onChange={() =>
                          handleControlFieldChange(!control.isCustomUnit, "isCustomUnit", control)
                        }
                      />
                      <span>定義單位</span>
                      <span style={{ color: "#79A0B4" }}>
                        &nbsp;&nbsp;*若未勾選即自訂單位
                      </span>
                    </label>
                    </div>
                    <div className="mt-15-p">
                      <NormalInput
                        isFloatTitle={false}
                        placeholder={"定義單位"}
                        disabled={control.isCustomUnit === false}
                        defaultValue={control.unit}
                        onChange={(e) => handleControlFieldChange(e, "unit", control)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {controlTypeDetailBoxNode(control)}
          </div>
          {addControlNode(control)}
        </div>
      </div>
    );
  };

  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init questionnaireId
  useEffect(() => {
    // categoriesList();
    console.log(questionnaireId);
    if (!questionnaireId) return;
    getControlTypeOptionsMutate();
    if (questionnaireId !== "-1") {
      getQuestionnaireSettingDataMutate(questionnaireId);
    }
  }, [
    questionnaireId,
    getQuestionnaireSettingDataMutate,
    getControlTypeOptionsMutate,
  ]);

  //- handleClickOutside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // console.log(event.target);
      //- 判斷是不是點擊到 option-dropdown-menu 或 option-dropdown-menu 內層
      const target = event.target as HTMLElement;
      const isDropdownMenu = target.classList.contains("option-dropdown-menu");
      const isDropdownMenuChild = target.closest(".option-dropdown-menu");
      // console.log(isDropdownMenu, isDropdownMenuChild);
      if (isDropdownMenu || isDropdownMenuChild) return;
      //- 先判斷有沒有 dropdownOpen 是 true 的
      const hasOpen = questionnaireSettingDataRef.current.controls.some(
        (control) =>
          control.options?.some((option) => option.dropdownOpen === true)
      );
      if (!hasOpen) return;
      //- 關閉所有的 dropdown (用 dropdownOpen 控制)
      closeAllOptionsDropdown();
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //- questionnaireSettingData
  useEffect(() => {
    console.log("questionnaireSettingData", questionnaireSettingData);
    questionnaireSettingDataRef.current = questionnaireSettingData;
    console.log(
      "sectionAndControlGroupsList",
      sectionAndControlGroupsList(questionnaireSettingDataRef.current.controls)
    );
    console.log("fileCorrespondData", fileCorrespondData);
    //* ---------------------------- hook-function start ---------------------------- *//
    //- handleDeleteControlFile
    setFileCorrespondData((prev) =>
      prev.filter((e) =>
        questionnaireSettingDataRef.current.controls
          .filter((o) => o.isUploadImage)
          .map((o) => o.uniqueKey)
          .includes(e.controlUniqueKey)
      )
    );
    //- sort
    const preHandleInserBefore = (evt: Sortable.SortableEvent) => {
      evt.item.remove();
      if (evt.oldIndex !== undefined) {
        evt.from.insertBefore(evt.item, evt.from.children[evt.oldIndex]);
      }
    };
    const sortableInit = () => {
      // console.log("sortableInit");
      // handle-sort-list-container
      // handle-sort-child-container
      try {
        //- list
        const listContainer = document.querySelector(
          `.handle-sort-list-container`
        );
        new Sortable(listContainer as HTMLElement, {
          group: {
            name: "list",
            pull: true,
            put: true,
          },
          handle: ".glyphicon-move",
          animation: 150,
          fallbackOnBody: true,
          swapThreshold: 0.65,
          invertSwap: true,
          ghostClass: "sort-ghost-background-class",
          direction: "vertical",
          onMove: (evt) => {
            //> style
            const items = listContainer!.querySelectorAll(".control-box");
            items.forEach((item) => {
              item.classList.remove("custom-hover");
            });
            const hoveredItem = evt.related;
            hoveredItem.classList.add("custom-hover");
          },
          onEnd: (evt) => {
            //> style
            const items = listContainer!.querySelectorAll(".control-box");
            items.forEach((item) => {
              item.classList.remove("custom-hover");
            });
            console.log("list", evt);
            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;
            //> 這邊就真的只是換順序
            if (oldIndex === newIndex) return;
            preHandleInserBefore(evt);
            const groupsList = CommonService.deepClone(
              sectionAndControlGroupsList(
                questionnaireSettingDataRef.current.controls
              )
            );
            const moveSection = groupsList.splice(oldIndex!, 1);
            groupsList.splice(newIndex!, 0, ...moveSection);
            // console.log(groupsList);
            setNewControlsInData(groupsList.flat());
          },
        });
        //- child
        const childContainers = document.querySelectorAll(
          `.handle-sort-child-container`
        );
        for (let i = 0; i < childContainers.length; i++) {
          new Sortable(childContainers[i] as HTMLElement, {
            group: {
              name: "child",
              pull: true,
              put: (to, from, dragEl, event) => {
                const nextElement = dragEl?.children[0];
                return !nextElement?.classList.contains("section-outbox");
              },
            },
            handle: ".glyphicon-move",
            animation: 150,
            fallbackOnBody: true,
            swapThreshold: 0.65,
            invertSwap: true,
            ghostClass: "sort-ghost-background-class",
            direction: "vertical",
            onMove: (evt) => {
              //> style
              const items = listContainer!.querySelectorAll(".control-box");
              items.forEach((item) => {
                item.classList.remove("custom-hover");
              });
              const hoveredItem = evt.related;
              hoveredItem.classList.add("custom-hover");
            },
            onEnd: (evt) => {
              //> style
              const items = listContainer!.querySelectorAll(".control-box");
              items.forEach((item) => {
                item.classList.remove("custom-hover");
              });
              //> function
              console.log("child", evt);
              const oldIndex = evt.oldIndex;
              const newIndex = evt.newIndex;
              const groupsList = CommonService.deepClone(
                sectionAndControlGroupsList(
                  questionnaireSettingDataRef.current.controls
                )
              );
              //> evt.to 的 classList 有一個 group-index-0 class {0} 就是他的 groupIndex
              const oldGroupIndex = Number(
                Array.from(evt.from.classList)
                  .find((className) => className.includes("group-index-"))
                  ?.split("-")[2]
              );
              const newGroupIndex = Number(
                Array.from(evt.to.classList)
                  .find((className) => className.includes("group-index-"))
                  ?.split("-")[2]
              );
              // console.log(oldGroupIndex, newGroupIndex);
              const newControls: ControlModel[] = [];
              //> 是有元件的section
              if (!isNaN(oldGroupIndex) && !isNaN(newGroupIndex)) {
                //> 同一層
                if (oldGroupIndex === newGroupIndex) {
                  if (oldIndex === newIndex) return;
                  // console.log(oldIndex, newIndex);
                  //> 因為是在group裡面 第一個是section 所以control的index要+1
                  const oldControlIndex = oldIndex! + 1;
                  const newControlIndex = newIndex! + 1;
                  const handleGroup = groupsList[oldGroupIndex];
                  const moveControl = handleGroup.splice(oldControlIndex, 1);
                  handleGroup.splice(newControlIndex, 0, ...moveControl);
                  groupsList.splice(oldGroupIndex, 1, handleGroup);
                  newControls.push(...groupsList.flat());
                  // console.log(newControls);
                }
                //> 不同層
                if (oldGroupIndex !== newGroupIndex) {
                  // console.log(oldIndex, newIndex);
                  //> pre
                  preHandleInserBefore(evt);
                  //> 因為是在group裡面 第一個是section 所以control的index要+1
                  const oldControlIndex = oldIndex! + 1;
                  const newControlIndex = newIndex! + 1;
                  const handleOldGroup = groupsList[oldGroupIndex];
                  const handleNewGroup = groupsList[newGroupIndex];
                  const moveControl = handleOldGroup.splice(oldControlIndex, 1);
                  handleNewGroup.splice(newControlIndex, 0, ...moveControl);
                  groupsList.splice(oldGroupIndex, 1, handleOldGroup);
                  groupsList.splice(newGroupIndex, 1, handleNewGroup);
                  newControls.push(...groupsList.flat());
                  // console.log(newControls);
                }
              } else {
                //> 是移動到沒有元件的section, 這裡的 oldIndex 沒有用, newIndex 是在外層的 index
                console.log(oldIndex, newIndex);
                //> pre
                preHandleInserBefore(evt);
                //> handle
                const oldControlIndex = oldIndex! + 1;
                const handleOldGroup = groupsList[oldGroupIndex];
                const moveControl = handleOldGroup.splice(oldControlIndex, 1);
                //> 先判斷 newIndex 的 section 是不是 blankSection, isInsert 是 true 就是要插入到這個 section, 不是的話要再包一個 isBlankSection section
                const isInsert =
                  questionnaireSettingDataRef.current.controls[newIndex!]
                    ?.isBlankSection;
                // console.log("isInsert", isInsert);
                if (isInsert) {
                  const insertIndex = groupsList.findIndex(
                    (e) =>
                      e[0].uniqueKey ===
                      questionnaireSettingDataRef.current.controls[newIndex!]
                        .uniqueKey
                  );
                  if (insertIndex === -1) return;
                  const handleNewGroup = groupsList[insertIndex];
                  handleNewGroup.push(...moveControl);
                  groupsList.splice(insertIndex, 1, handleNewGroup);
                  groupsList.splice(oldGroupIndex, 1, handleOldGroup);
                  newControls.push(...groupsList.flat());
                } else {
                  // groupsList.splice(oldGroupIndex, 1, handleOldGroup);
                  const newSection: ControlModel = {
                    ...new ControlModel(),
                    ...{
                      id: uuid(),
                      ctrType: "section",
                      isBlankSection: true,
                    },
                  };
                  groupsList.splice(newIndex!, 0, [newSection, ...moveControl]);
                  newControls.push(...groupsList.flat());
                }
              }
              setNewControlsInData(newControls);
            },
          });
        }
        //- options
        const optionsContainers = document.querySelectorAll(
          `.handle-sort-options-container`
        );
        for (let i = 0; i < optionsContainers.length; i++) {
          new Sortable(optionsContainers[i] as HTMLElement, {
            group: {
              name: "options",
              pull: false,
              put: false,
            },
            handle: ".glyphicon-move",
            animation: 150,
            fallbackOnBody: true,
            swapThreshold: 0.65,
            invertSwap: true,
            ghostClass: "sort-ghost-background-class",
            direction: "vertical",
            onEnd: (evt) => {
              console.log("options", evt);
              const oldIndex = evt.oldIndex;
              const newIndex = evt.newIndex;
              if (oldIndex === newIndex) return;
              // console.log(oldIndex, newIndex);
              const handleControlIndex = Number(
                Array.from(evt.from.classList)
                  .find((className) => className.includes("control-sortIndex-"))
                  ?.split("-")[2]
              );
              // console.log(handleControlIndex);
              const control = questionnaireSettingDataRef.current.controls.find(
                (e) => e.sortIndex === handleControlIndex
              );
              if (!control) return;
              const newOptions = CommonService.deepClone(control.options);
              const moveOption = newOptions!.splice(oldIndex!, 1);
              newOptions!.splice(newIndex!, 0, ...moveOption);
              const newControls =
                questionnaireSettingDataRef.current.controls.map((e) => {
                  if (e.uniqueKey === control.uniqueKey) {
                    return { ...e, options: newOptions?.filter((e) => e) };
                  }
                  return e;
                });
              setNewControlsInData(newControls);
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    //* ---------------------------- hook-function end ---------------------------- *//
    sortableInit();
  }, [questionnaireSettingData]);
  //* ---------------------------- hook end ---------------------------- *//
  return (
    <div className={`${styles["questionnaire-edit"]}`}>
      {/*上方bar*/}
      <div className="title-box">
        <BackTitle
          title={"問卷設定"}
          url={`/admin/questionnaire/setting`}
          state={{
            questionnaireListRequestModel: questionnaireListRequestModel,
          }}
        />
        {(questionnaireId === "-1" &&
          detectEveryActionPermission([
            QuestionnaireManageActionsPermissionCodes.QuestionnaireSettingCreate,
          ])) ||
        (questionnaireId !== "-1" &&
          detectEveryActionPermission([
            QuestionnaireManageActionsPermissionCodes.QuestionnaireSettingUpdate,
          ])) ? (
          <button
            className="default saveBtn"
            onClick={() => handleSaveQuestionnaireData()}
          >
            儲存
          </button>
        ) : null}
      </div>
      {/* edit-box */}
      <div className="edit-box scroll">
        <ScrollToTopButton
          targetClassName={"edit-box"}
          bottom={90}
          right={35}
        />
        {getQuestionnaireSettingDataIsPending ||
        saveQuestionnaireSettingDataIsPending ||
        addQuestionnaireSettingDataIsPending ||
        getControlTypeOptionsIsPending ? (
          <VisuallLoading />
        ) : null}
        {/* basic setting */}
        {basicSettingNode()}
        {/* advanced setting */}
        {advancedSettingNode()}
        {/* FollowingControlSettingModal */}
        <FollowingControlSettingModal
          ref={useFollowingControlSettingModalRef}
          cancelFollowingControlSetting={cancelFollowingControlSetting}
          saveFollowingControlSetting={saveFollowingControlSetting}
        />
        {/* upload-input */}
        <input
          className="upload-input"
          type="file"
          accept="image/png, image/jpeg"
          onChange={(e) => handleFileChange(e)}
          value={fileValue}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
};

export default Edit;
