import { HttpClient } from 'services/httpClient';
import { QueryDashboardDataRequestModel } from 'models/admin/homeModel';

const httpClient = new HttpClient();

/** 取得問卷範本列表 */
const getDashboardData = (query: QueryDashboardDataRequestModel) => {
  return httpClient.post(`Dashboard/dashboard`, query);
};

/** getMarketTypes */
const getMarketTypes = () => {
  return httpClient.get(`Dashboard/marketTypes`);
};

const HomeService = {
  getDashboardData,
  getMarketTypes,
};

export default HomeService;
