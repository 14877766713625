import React, { FC, useEffect, useState } from "react";
import styles from "./SustainReportList.module.scss";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { useMutation } from "@tanstack/react-query";
import TablePagination from "components/base/TablePagination/TablePagination";
import { ListRequestModel, RequestType } from "models/baseModel";
import { useNavigate } from "react-router-dom";
import { normalDate3 } from "utils/dateTimeFormat";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import {
  SustainCorrectionRequestModel,
  SustainReportDeclareActionModel,
  SustainReportDeclareShowModel,
} from "models/application/sustainReportDeclare/sustainReportDeclareModel";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import UpdateApplyModal, {
  UpdateApplyModalProps,
} from "components/otherModule/UpdateApplyModal/UpdateApplyModal";
import {
  DeclareDataAction,
  DeclareStatus,
} from "models/application/indicators/indicatorModel";
import { error2Alert, success2Alert } from "utils/otherToast";
import { CorrectionRequestModel } from "models/admin/declareDataMangeModel";
import { ResponseCode } from "models/responseCodeModel";
import { Multilingual } from "models/admin/declarationModel";
import { CommonService } from "services/common/commonService";
import useUserInfoStore from "state/useUserInfoStore";
import EmptySearchPage from "components/otherModule/EmptySearchPage/EmptySearchPage";
import ProblemReportContent from "components/otherModule/ProblemReportContent/ProblemReportContent";

//#region  ----------------- 申報按鈕 -----------------
interface FuncBtnComponentProps {
  handleStarApply: (item: SustainReportDeclareShowModel) => void;
  handleConfirmApply: (item: SustainReportDeclareShowModel) => void;
  handleUpdateApply: (item: SustainReportDeclareShowModel) => void;
  item: SustainReportDeclareShowModel;
  isFinished: boolean;
  status: DeclareStatus;
}

const FuncBtnComponent: FC<FuncBtnComponentProps> = (props) => {
  const {
    handleStarApply,
    handleConfirmApply,
    handleUpdateApply,
    item,
    isFinished,
    status,
  } = props;

  //#region 取得按鈕名稱
  const getBtnNameByStatus = (
    status: DeclareStatus,
    canEdit: boolean
  ): string => {
    if (!canEdit) {
      return "檢視";
    }
    let str = "";
    switch (status) {
      case DeclareStatus.Declared:
      case DeclareStatus.ApplyForCorrection:
      case DeclareStatus.DeclareEnd:
        str = "檢視";
        break;
      case DeclareStatus.Correcting:
      case DeclareStatus.Declaring:
        str = "修改";
        break;
      case DeclareStatus.UnDeclared:
      default:
        str = "開始申報";
        break;
    }
    return str;
  };
  //#endregion

  return (
    <div className="func-btn-box">
      <button
        className="secondary min-w-fit"
        onClick={() => {
          handleStarApply(item);
        }}
      >
        {getBtnNameByStatus(status, item.canEdit)}
      </button>
      {(status === DeclareStatus.Correcting ||
        status === DeclareStatus.Declaring) &&
        isFinished && (
          <button
            className="secondary min-w-fit"
            onClick={() => {
              handleConfirmApply(item);
            }}
          >
            確認申報
          </button>
        )}
      {(status === DeclareStatus.Declared ||
        status === DeclareStatus.DeclareEnd) && (
        <button
          className="secondary min-w-fit"
          onClick={() => {
            handleUpdateApply(item);
          }}
        >
          {status === DeclareStatus.DeclareEnd ? "申請開放" : "更正申請"}
        </button>
      )}
    </div>
  );
};
//#endregion

interface SustainReportListProps {}

const SustainReportList: FC<SustainReportListProps> = () => {
  const { userInfo } = useUserInfoStore();
  const [param, setParam] = useState<ListRequestModel>({
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortKey: null,
  });
  const navigate = useNavigate();
  const [listData, setListData] = useState<SustainReportDeclareShowModel[]>([]);
  // 有無公司資料
  const [companyDataWord, setCompanyDataWord] = useState<string | null>(null);

  const tableTitles = [
    "首次申報日期",
    "最後編輯日期",
    "報告書修正版上傳日期",
    "更正申報日期",
    "狀態",
    "功能",
  ];
  const handleNavigate = (id: string, version: string = "tw") => {
    navigate(`/apply/sustain-report/${id}/info?version=${version}`);
  };

  useEffect(() => {
    // 無文字才能正常顯示
    let str = CommonService.getApplyDisplayWord(userInfo, "永續報告書");
    if (str) {
      setCompanyDataWord(str);
    } else {
      getSustainReportDeclareList.mutate(param);
    }
  }, []);

  //#region 取得清單資料
  const getSustainReportDeclareList = useMutation({
    mutationFn: (model: ListRequestModel) =>
      SustainReportDeclareService.getSustainReportDeclareList(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportDeclareShowModel[] = res.data;
        setListData([...data]);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  //#endregion

  //#region 確認申報
  // modal 物件(confirm)
  const [applyModal, setApplyModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setApplyModal({
        ...applyModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "確認申報",
  });

  // 確認申報
  const applyMutation = useMutation({
    mutationFn: (model: SustainReportDeclareActionModel) =>
      SustainReportDeclareService.editSustainReportDeclareStatus(model),
    onSuccess: (res) => {
      setApplyModal({
        ...applyModal,
        show: false,
        loading: false,
      });
      if (res.success && res.code === ResponseCode.SuccessOK) {
        getSustainReportDeclareList.mutate(param);
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // 處理申報事件
  const handleApplyModal = (
    item: SustainReportDeclareShowModel,
    multilingual: Multilingual
  ) => {
    setApplyModal({
      ...applyModal,
      loading: true,
    });
    applyMutation.mutate({
      action: DeclareDataAction.Declared,
      declareId: item.id,
      multilingual: multilingual,
    });
  };

  //#endregion

  //#region 更正申請
  const updateApplyMutation = useMutation({
    mutationFn: (model: SustainCorrectionRequestModel) =>
      SustainReportDeclareService.addSustainReportDeclareDatacCrrectionRequest(
        model
      ),
    onSuccess: (res) => {
      setUpdateModal({
        ...updateModal,
        loading: false,
        show: false,
      });
      if (res.success && res.code === ResponseCode.SuccessOK) {
        getSustainReportDeclareList.mutate(param);
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [updateModal, setUpdateModal] = useState<UpdateApplyModalProps>({
    show: false,
    handleClose: () => {
      setUpdateModal({
        ...updateModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    requestType: RequestType.SustainReport,
  });

  // 更正申請 model
  const [updateInfo, setUpdateInfo] = useState({
    applicantEmailList: [] as string[],
    correctionRequestReason: "",
  });

  // 處理modal更正申報
  const handleUpdateModal = (
    updateInfo: CorrectionRequestModel,
    item: SustainReportDeclareShowModel,
    type: Multilingual
  ) => {
    setUpdateModal({
      ...updateModal,
      loading: true,
    });
    updateApplyMutation.mutate({
      ...updateInfo,
      declareId: item.id,
      multilingual: type,
    });
  };
  //#endregion

  return (
    <div className="no-nav-content-box">
      {!companyDataWord ? (
        <div className={styles["sustain-report-list-box"]}>
          <div className="title mb-20-p">永續報告書申報作業</div>
          <div className="result-box scroll">
            <div className="table-box">
              {(getSustainReportDeclareList.isPending ||
                updateApplyMutation.isPending ||
                applyMutation.isPending) && (
                <VisuallLoading></VisuallLoading>
              )}
              <table
                aria-label="查詢結果table"
                className="table-container sticky-table"
              >
                <thead>
                  <tr>
                    <th scope="rowgroup" rowSpan={2}>
                      年度
                    </th>
                    <th scope="colgroup" colSpan={6}>
                      中文版
                    </th>
                    <th scope="colgroup" colSpan={6}>
                      英文版
                    </th>
                  </tr>
                  <tr>
                    {[0, 1].map((p, index) => {
                      return tableTitles.map((title, titleIndex) => {
                        return <th key={`${index}_${titleIndex}`}>{title}</th>;
                      });
                    })}
                  </tr>
                </thead>
                <tbody>
                  {listData?.map(
                    (p: SustainReportDeclareShowModel, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{p.declareYear}</td>

                          <td>{normalDate3(p.twFirstDeclareDate)}</td>
                          <td>{normalDate3(p.twLastEditDate)}</td>
                          <td>{normalDate3(p.twReportRevisionUploadTime)}</td>
                          <td>{normalDate3(p.twEditDeclareDate)}</td>
                          <td>
                            <div
                              className={`status ${
                                p.twStatusName === "未申報" ? "no-declared" : ""
                              }`}
                            >
                              {p.twStatusName}
                            </div>
                          </td>
                          <td className="func-td">
                            <FuncBtnComponent
                              handleConfirmApply={() => {
                                setApplyModal({
                                  ...applyModal,
                                  show: true,
                                  handleConfirm: () => {
                                    handleApplyModal(p, Multilingual.Chinese);
                                  },
                                });
                              }}
                              handleUpdateApply={() => {
                                setUpdateInfo({
                                  ...updateInfo,
                                  applicantEmailList: [],
                                  correctionRequestReason: "",
                                });
                                setUpdateModal({
                                  ...updateModal,
                                  show: true,
                                  declareStatus: p.twStatus,
                                  handleConfirm: (e) => {
                                    handleUpdateModal(
                                      e,
                                      p,
                                      Multilingual.Chinese
                                    );
                                  },
                                });
                              }}
                              handleStarApply={() => {
                                handleNavigate(p.id, "tw");
                              }}
                              item={p}
                              isFinished={p.twIsFinished}
                              status={p.twStatus}
                            />
                          </td>
                          <td>{normalDate3(p.enFirstDeclareDate)}</td>
                          <td>{normalDate3(p.enLastEditDate)}</td>
                          <td>{normalDate3(p.enReportRevisionUploadTime)}</td>
                          <td>{normalDate3(p.enEditDeclareDate)}</td>
                          <td>
                            <div
                              className={`status ${
                                p.enStatusName === "未申報" ? "no-declared" : ""
                              }`}
                            >
                              {p.enStatusName}
                            </div>
                          </td>
                          <td className="func-td">
                            <FuncBtnComponent
                              handleConfirmApply={() => {
                                setApplyModal({
                                  ...applyModal,
                                  show: true,
                                  handleConfirm: () => {
                                    handleApplyModal(p, Multilingual.English);
                                  },
                                });
                              }}
                              handleUpdateApply={() => {
                                setUpdateInfo({
                                  ...updateInfo,
                                  applicantEmailList: [],
                                  correctionRequestReason: "",
                                });
                                setUpdateModal({
                                  ...updateModal,
                                  show: true,
                                  declareStatus: p.enStatus,
                                  handleConfirm: (e) => {
                                    handleUpdateModal(
                                      e,
                                      p,
                                      Multilingual.English
                                    );
                                  },
                                });
                              }}
                              handleStarApply={() => {
                                handleNavigate(p.id, "en");
                              }}
                              item={p}
                              isFinished={p.enIsFinished}
                              status={p.enStatus}
                            />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="table-pagination mb-20-p">
            <TablePagination
              page={null}
              pageSize={null}
              onChange={(e) => {
                setParam((prev) => {
                  return {
                    ...prev,
                    ...e,
                  };
                });
              }}
              total={0}
            />
          </div>
          <div className="desc">
            說明：
            <br />
            報告年度請寫報告涵蓋期間最後一日之年度
            (例如：若報告書蓋期間為2022年1月1日至2023年12月31日，報告年度填寫2023年)
            <div className="mt-2">
              <ProblemReportContent />
            </div>
          </div>
        </div>
      ) : (
        <EmptySearchPage
          className={"no-company-data-word"}
          word={companyDataWord}
        />
      )}
      {/* 確認申報modal */}
      <ConfirmModal {...applyModal}>
        <div>
          確認後不得再編輯資料，若申報完成後需修改，請至
          <span style={{ fontWeight: 700 }}>申報資料更正申請。</span>
        </div>
      </ConfirmModal>
      {/* 申報資料更正申請 */}
      <UpdateApplyModal {...updateModal} />
    </div>
  );
};

export default SustainReportList;
