//* ---------------------------- React start ---------------------------- *//
import { FC } from "react";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./QuestionnaireRouter.module.scss";
import Navbar from "components/layouts/Navbar/Navbar";
import { NavBarItemModel } from "models/baseModel";
import Setting from "./Setting/Setting";
import Edit from "./Setting/Edit/Edit";
import Response from "./Response/Response";
import Detail from "./Response/Detail/Detail";
import CustomErrorBoundary from "components/common/CustomErrorBoundary/CustomErrorBoundary";
import {
  FeaturePermissionCodes,
  PermissionRouteObjectModel,
  PermissionNavBarItemModel,
  ActionsPermissionCodes,
} from "models/auth/permissionModel";
import EmptyWaitPage from "components/common/EmptyWaitPage/EmptyWaitPage";
import useUserInfoStore from "state/useUserInfoStore";
import { usePermissionContext } from "context/PermissionProvider";
//* ---------------------------- local end ---------------------------- *//

const QuestionnaireRouterLayout: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const { PermissionState, PermissionService } =
    usePermissionContext();
  const { noPermissionPage } = PermissionState;
  const { detectEveryFeatruePermission } = PermissionService;
  const navItems: PermissionNavBarItemModel[] = [
    {
      name: "問卷設定",
      url: "setting",
      permission:
        FeaturePermissionCodes.QuestionnaireManage.QuestionnaireSetting,
    },
    {
      name: "問卷回覆",
      url: "response",
      permission: FeaturePermissionCodes.QuestionnaireManage.QuestionnaireReply,
    },
  ];
  const filterNavItems = navItems.filter((e) =>
    detectEveryFeatruePermission([e.permission])
  );
  //* ---------------------------- state end ---------------------------- *//
  return (
    <>
      <CustomErrorBoundary>
        {filterNavItems?.length ? (
          <>
            {/* tab 資訊 */}
            <Navbar items={filterNavItems} />
            <div className="nav-content-box">
              <Outlet></Outlet>
            </div>
          </>
        ) : (
          noPermissionPage()
        )}
      </CustomErrorBoundary>
    </>
  );
};

const QuestionnaireRouter: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  const { PermissionService } = usePermissionContext();
  const { permissionRouter } = PermissionService;

  const routers: PermissionRouteObjectModel[] = [
    {
      path: "",
      element: <QuestionnaireRouterLayout />,
      children: [
        {
          path: "setting",
          element: <Setting />,
          featurePermission:
            FeaturePermissionCodes.QuestionnaireManage.QuestionnaireSetting,
          actionReadPermission:
            ActionsPermissionCodes.QuestionnaireManage.QuestionnaireSettingRead,
        },
        {
          path: "setting/:questionnaireId/edit",
          element: <Edit />,
          featurePermission:
            FeaturePermissionCodes.QuestionnaireManage.QuestionnaireSetting,
          actionReadPermission:
            ActionsPermissionCodes.QuestionnaireManage.QuestionnaireSettingRead,
        },
        {
          path: "response",
          element: <Response />,
          featurePermission:
            FeaturePermissionCodes.QuestionnaireManage.QuestionnaireReply,
          actionReadPermission:
            ActionsPermissionCodes.QuestionnaireManage.QuestionnaireReplyRead,
        },
        {
          path: "response/:companyCode/:questionnaireSampleId/detail",
          element: <Detail />,
          featurePermission:
            FeaturePermissionCodes.QuestionnaireManage.QuestionnaireReply,
          actionReadPermission:
            ActionsPermissionCodes.QuestionnaireManage.QuestionnaireReplyRead,
        },
      ],
    },
  ];

  //* ---------------------------- state end ---------------------------- *//
  return useRoutes(permissionRouter(routers));
};

export default QuestionnaireRouter;
